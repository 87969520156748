import React, { useState, useRef, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useNavigate, useParams } from "react-router";
import {
  getClient,
  deleteUser,
  userDetails,
  activeStatus,
  permission,
  formatDate1,
  withUidApi,
} from "../services/api.service";
import Select from "react-select";
import { clearForm } from "../services/api.service";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";

const AppointCD = () => {

  //        ---------- CLOSE POPUP VARIABLE --------

  const [saveButton, setSaveButton] = useState(true);
  const { id } = useParams();
  const closeButton = document.querySelector(".btn_popup");
  const closeEdit = document.querySelector(".e_popup");
  const closeView = document.querySelector(".ab");
  const closeDelete = document.querySelector(".mm");
  const ViewCDData = new FormData();
  const [optionsCD, setOptionsCD] = useState("");
  const getCaseHistory = async () => {
    ViewCDData.append("ID", id);
    ViewCDData.append("AdminID", localStorage.getItem("id"));

    try {
      const response = await fetch(
        `${baseUrl}CaseHistory/ViewCaseHistoryById`,
        {
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
          body: ViewCDData,
        }
      );

      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }

      setCaseData(data && data.result[0].case[0]);
      setOptionsCD(data && data.result[0]);
    } catch (error) {
      console.error(error);
    }
  };
 
  //        ----------  VARIABLE --------

  const currentDate = new Date().toISOString().split("T")[0];
  const baseUrl = useContext(url);
  const navigate = useNavigate();
  const [dCaseID, setDCaseID] = useState("");
  const [dCaseName, setDCaseName] = useState("");
  const [dDate, setDDate] = useState("");
  const [dClientID, setDClientID] = useState("");
  const [dSummary, setDSummary] = useState("");
  const [dTime, setDTime] = useState("");
  const [dAssignTo, setDAssignTo] = useState("");
  const [dAppointmentWith, setDAppointmentWith] = useState("");
  const [dIsPending, setDIsPending] = useState("");
  const [dDeatils, setDDeatils] = useState("");
  const [caseData, setCaseData] = useState("");
  const [fClientID, setFClientID] = useState("");
  const [fClientOption, setFClientOption] = useState([]);
  const [isAdd, setIsAdd] = useState("0");
  const [isEdit, setIsEdit] = useState("0");
  const [isView, setIsView] = useState("0");
  const [isDelete, setIsDelete] = useState("0");
  const [isStatus, setIsStatus] = useState("0");
  const [excel, setExcel] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const [loading, setLoading] = useState(true);

  //        ---------- ADD VARIABLE --------

  const [letterTemplatesData, setLetterTemplatesData] = useState([]);
  const [CaseID, setCaseID] = useState("");
  const [ClientID, setClientID] = useState("");
  const [Summary, setSummary] = useState("");
  const [Time, setTime] = useState("");
  const [AssignTo, setAssignTo] = useState("");
  const [AppointmentWith, setAppointmentWith] = useState("");
  const [IsPending, setIsPending] = useState("");
  const [Details, setDetails] = useState("");
  const [Dates, setDates] = useState(currentDate);
  const [delId, setDelId] = useState("");
  const [optionsEvent, setOptionsEvent] = useState([]);
  const [ClientId, setClientId] = useState([]);
  //        ---------- EDIT VARIABLE --------

  const [editId, setEditId] = useState("");
  const [EditCaseID, setEditCaseID] = useState("");
  const [EditDate, setEditDate] = useState("");
  const [EditClientID, setEditClientID] = useState("");
  const [EditSummary, setEditSummary] = useState("");
  const [EditTime, setEditTime] = useState("");
  const [EditAssignTo, setEditAssignTo] = useState("");
  const [EditAppointmentWith, setEditAppointmentWith] = useState("");
  const [EditIsPending, setEditIsPending] = useState("");
  const [EditDetails, setEditDetails] = useState("");
  const formData = new FormData();
  const editAppointment = new FormData();

  const userById = new FormData();
  const updateUser = async (id) => {
    userById.append("AdminID", `${localStorage.getItem("id")}`);
    userById.append("ID", id);

    try {
      const response = await fetch(`${baseUrl}Client/ViewClientById`, {
        method: "POST",
        body: userById,

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data5 = await response.json();
      if (data5 && data5.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
     
     
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getFileNo();
    getCaseHistory();
    getClientByCase(id);
    getUser();
  }, []);
  //        ---------- VIEW  --------

  const getViewLetterTemplate = async () => {
    const [view, data, cdata] = await Promise.all([
      withUidApi("Appoinment/ViewAppoinment"),
      permission(11),
      getClient("Client/ViewClient"),
      getUser(),
      getCountries(),
      getFileNo(),
      getAppoint(),
      setLoading(true),
    ]);

    if (view === null) {
      setLoading(false);
      setLetterTemplatesData([]);
    } else {
      if (view === "token") {
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        setLoading(false);
        setLetterTemplatesData(view);
      }
    }

    const clientData = cdata && cdata.filter((item) => item.Status === "1");

    const Mapped =
      clientData &&
      clientData.map((item) => ({
        value: item.Id,
        label: `${item.FirstName} ${item.LastName}`,
      }));
    setFClientOption(Mapped);

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }

    setIsAdd(data && data.result[0].IsAdd);
    setIsEdit(data && data.result[0].IsEdit);
    setIsView(data && data.result[0].IsView);
    setIsStatus(data && data.result[0].IsStatus);
    setIsDelete(data && data.result[0].IsDelete);
  };

  //        ---------- DELETE   -------

  const DeleteAppoinment = async () => {
    const data = await deleteUser(delId, "Appoinment/DeleteAppoinment");
    if (data === "token") {
      setTimeout(() => {
        closeDelete.click();
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      closeDelete.click();
      getViewLetterTemplate();
      getCaseHistory();
    }
  };

  //        ---------- ACTIVE DEACTIVE  --------

  const deActive = async (row) => {
    const data = await activeStatus(row, "Appoinment/AppoinmentStatus");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      getViewLetterTemplate();
      getCaseHistory();
    }
  };

  //        ---------- DETAILS --------

  const details = async (id) => {
    const data = await userDetails(id, "Appoinment/ViewAppoinmentById");
    if (data === "token") {
      setTimeout(() => {
        closeView.click();
        navigate("/");
      }, 3000);
    } else {
      setDDeatils(data.result[0].Detail);
      setDIsPending(data.result[0].IsPending);
      setDDate(data.result[0].Date);
      setDCaseID(data.result[0].FileNo);
      setDCaseName(data.result[0].CaseName);
      setDAppointmentWith(data.result[0].AppointmentWith);
      setDAssignTo(data.result[0].Assignto);
      setDClientID(data.result[0].ClientName);
      setDSummary(data.result[0].Summary);
      setDTime(data.result[0].Time);
    }
  };

  //        ---------- DETAILS --------

  const getById = async (id) => {
    const data = await userDetails(id, "Appoinment/ViewAppoinmentById");
    if (data === "token") {
      setTimeout(() => {
        closeEdit.click();
        navigate("/");
      }, 3000);
    } else {
      getClientByCase(data && data.result[0].CaseID);
      setEditId(data && data.result[0].Id);
      setEditDate(data && data.result[0].Date);
      setEditCaseID(data && data.result[0].CaseID);
      setEditAppointmentWith(data && data.result[0].AppointmentWith);
      setEditAssignTo(data && data.result[0].AssignTo);
      setEditClientID(data && data.result[0].ClientID);
      setEditDate(data && data.result[0].Date);
      setEditSummary(data && data.result[0].Summary);
      setEditTime(data && data.result[0].Time);
      setEditIsPending(data && data.result[0].IsPending);
      setEditDetails(data && data.result[0].Detail);
    }
  };

  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  /** Get Appoinment  */
  const CaseAppoint = new FormData();
  const [Appoint, setAppoint] = useState([]);
  const getAppoint = async () => {
    CaseAppoint.append("AdminID", localStorage.getItem("id"));
    try {
      const response = await fetch(`${baseUrl}Appoinment/ViewAppoinment`, {
        method: "POST",
        body: CaseAppoint,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].code == 200) {
        setAppoint(data.result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /** Get File Name  */
  const Case = new FormData();
  const [optionsCase, setOptionsCase] = useState([]);

  const [FileNo, setFileNo] = useState([]);
  const getFileNo = async () => {
    Case.append("AdminID", localStorage.getItem("id"));
    Case.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: Case,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result[0].message === "Success") {
        setFileNo(data.result);

        const Mapped = data.result.map((item) => ({
          value: item.CaseID,
          label: `${item.FileNo} / ${item.CaseName} / ${item.CaseNo}`,
        }));

        setOptionsCase(Mapped);
        setOptionsEvent(Mapped);
      }
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Get Client
  const ViewData = new FormData();
  const [countries, setCountries] = useState([]);

  const getClientByCase = async (id) => {
    ViewData.append("AdminID", localStorage.getItem("id"));
    ViewData.append("CaseID", id);
    try {
      const response = await fetch(`${baseUrl}Appoinment/ViewClientByCase`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: ViewData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
      }
      setClientId(data.result[0].Id);
      setCountries(data && data.result);
    } catch (error) {
      console.error(error);
    }
  };

  ViewData.append("AdminID", localStorage.getItem("id"));

  const getCountries = async () => {
    try {
      const response = await fetch(`${baseUrl}Client/ViewClient`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: ViewData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
      }

      const clientData =
        data && data.result.filter((item) => item.Status === "1");
      setCountries(clientData);

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /** Get User  */
  const Userform = new FormData();
  const [User, setUser] = useState([]);
  const getUser = async () => {
    Userform.append("AdminID", localStorage.getItem("id"));
    try {
      const response = await fetch(`${baseUrl}Task/ViewUserList`, {
        method: "POST",
        body: Userform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].code == 200) {
        setUser(data.result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        ---------- ADD --------

  const submitHandler = async (event) => {
    event.preventDefault();
    setSaveButton(false);
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    if (CaseID && CaseID.length > 0) {
      formData.append("CaseID", id);
    } else {
      formData.append("CaseID", id);
    }

    formData.append("Date", Dates);
    formData.append("ClientID", ClientId);
    formData.append("Summary", Summary.toUpperCase());
    formData.append("Time", Time);
    formData.append("IsPending", IsPending);
    formData.append("Detail", Details.toUpperCase());
    formData.append("AssignTo", AssignTo);
    formData.append("AppointmentWith", AppointmentWith.toUpperCase());

    try {
      const response = await fetch(`${baseUrl}Appoinment/AddAppoinment`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].message === "Success") {
        toast.success("Appointment added successfully");
        closeButton.click();
        handleClose();
        getViewLetterTemplate();
        getCaseHistory();
        clearForm();
        setDates(currentDate);
        setClientID("");
        setCaseID("");
        setSummary("");
        setAssignTo("");
        setAppointmentWith("");
        setIsPending("");
        setDetails("");
        setTime("");
        setSaveButton(true);
      } else {
        toast.error(data.result.message);
        setSaveButton(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        ---------- EDIT --------

  const submitEditHandler = async (event) => {
    event.preventDefault();
    editAppointment.append("ID", editId);
    editAppointment.append("AdminID", localStorage.getItem("id"));
    editAppointment.append("UpdatedBy", localStorage.getItem("UserId"));
    if (EditCaseID && EditCaseID.length > 0) {
      editAppointment.append("CaseID", id);
    }
    editAppointment.append("Date", EditDate);
    editAppointment.append("ClientID", EditClientID);
    editAppointment.append("Summary", EditSummary.toUpperCase());
    editAppointment.append("Time", EditTime);
    editAppointment.append("IsPending", EditIsPending);
    editAppointment.append("Detail", EditDetails.toUpperCase());
    editAppointment.append("AssignTo", EditAssignTo);
    editAppointment.append("AppointmentWith", EditAppointmentWith.toUpperCase());
    try {
      const response = await fetch(`${baseUrl}Appoinment/EditAppoinment`, {
        method: "POST",
        body: editAppointment,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeEdit.click();
          navigate("/");
        }, 3000);
      }
      if (data.result.length && data.result[0].code == 200) {
        toast.success("Appointment Updated successfully");
        getViewLetterTemplate();
        getCaseHistory();
        closeEdit.click();
        clearForm();
        setEditCaseID("");
        setEditAssignTo("");
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const convertTo12HourFormat = (time) => {
    if (!time || typeof time !== "string" || !time.includes(":")) {
      return "";
    }

    const [hours, minutes] = time.split(":");
    let period = "AM";
    let hour = parseInt(hours, 10);

    if (hour >= 12) {
      period = "PM";
      if (hour > 12) {
        hour -= 12;
      }
    }

    return `${hour} : ${minutes} ${period}`;
  };

  return (
    <>
      <DocumentTitle title="APPOINTMENT | LEGAL CRM">
      <div className="wraper">
    <Header />
<div className="mainWraper">
<Sidebar />
  <div className="pageBody">
    <div className="pageContent">
      <div className="PageTitle">
        <h3>Case Detail</h3>
        <a  title="Case Details"
           href={`#/view-casedetails/${id}`}
                        type="button"
                        className="btn btn-primary btn_client"
                      >
                        Back
                      </a>
      
      </div>
<br/>
      <div className="caseDetail">
        <div className="form-group Details">
          <h4>Case Name : </h4>
          <p>
            {caseData.CaseName === "" ? "-" : caseData.CaseName}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Case No. : </h4>
          <p>
            {caseData.CaseNo === "" || caseData.CaseNo === null
              ? "-"
              : caseData.CaseNo}
          </p>
        </div>

        <div className="form-group Details">
          <h4>Case Year : </h4>
          <p>
            {caseData.CaseYear === "" ||
            caseData.CaseYear === null
              ? "-"
              : caseData.CaseYear}
          </p>
        </div>

        <div className="form-group Details">
          <h4>Court Name : </h4>
          <p>
            {caseData.CourtName === "" ||
            caseData.CourtName === null
              ? "-"
              : caseData.CourtName}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Case Type :</h4>
          <p>
            {caseData.CaseType === "" ||
            caseData.CaseType === null
              ? "-"
              : caseData.CaseType}
          </p>
        </div>
        <div className="form-group Details">
          <h4>File No. : </h4>
          <p>
            {caseData.FileNo === "" || caseData.FileNo === null
              ? "-"
              : caseData.FileNo}
          </p>
        </div>

        <div className="form-group Details">
          <h4>Diary No. : </h4>
          <p>
            {caseData.DiaryNo === "" || caseData.DiaryNo === null
              ? "-"
              : caseData.DiaryNo}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Diary Year: </h4>
          <p>
            {caseData.DiaryYear === "" ||
            caseData.DiaryYear === null
              ? "-"
              : caseData.DiaryYear}
          </p>
        </div>
       

        <div className="form-group Details">
          <h4>Client Name : </h4>
          <p>
            {caseData.ClientName === "" ||
            caseData.ClientName === null
              ? "-"
              : caseData.ClientName}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Client Last Name : </h4>
          <p>
            {caseData.ClientLastName === "" ||
            caseData.ClientLastName === null
              ? "-"
              : caseData.ClientLastName}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Company Name : </h4>
          <p>
            {caseData.CompanyName === "" ||
            caseData.CompanyName === null
              ? "-"
              : caseData.CompanyName}
          </p>
        </div>

        <div className="form-group Details">
          <h4>Client Mobile No. : </h4>

          <p>
            {caseData.MobileNo === "" ||
            caseData.MobileNo === null
              ? "-"
              : caseData.MobileNo}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Phone : </h4>
          <p>
            {caseData.Phone === "" || caseData.Phone === null
              ? "-"
              : caseData.Phone}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Email :</h4>
          <p>
            {caseData.Email === "" || caseData.Email === null
              ? "-"
              : caseData.Email}
          </p>
        </div>
        </div>
        <br/>
          <div className="report_crmgraph">
            <div className="row">
              <div className="col-md-12">
                <div className="myCard">
                  <div className="cardHeader">
                    <h4>Appointment</h4>
                    <a
                      // style={{
                      //     display: isAdd === "0" ? "none" : "block",
                      // }}
                      type="button"
                      className="btn btn-primary btn_client headbtn"
                      data-toggle="modal"
                      data-target="#exampleModalLong"
                    >
                      Add Appointment
                      <img src="assets/img/icons/add.svg" />
                    </a>
                    <a href="/#/view-appointments" className="rytContent">
                      <img
                        src="assets/img/icons/dots.png"
                        alt=""
                        title="All Appointment"
                      />
                    </a>
                  </div>
                  {optionsCD.Appoinment &&
                  optionsCD.Appoinment[0].message === "Success" ? (
                    <div className="cardBody">
                      <table className="myTable" style={{tableLayout:"fixed"}}>
                        <thead>
                          <th>Action</th>
                          <th>Date</th>
                          <th>Client Name</th>
                          <th>Appointment With </th>
                        </thead>

                        {optionsCD.Appoinment &&
                        optionsCD.Appoinment.length > 0 ? (
                          optionsCD.Appoinment.map((val, index) => (
                            <tr
                              key={index}
                              className={
                                index % 2 === 0 ? "row-even" : "row-odd"
                              }
                            >
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "15px",
                                    width: "10px",
                                  }}
                                >
                                  {/* {isEdit !== "0" && ( */}
                                  <span
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#exampleModalLongEdit"
                                    onClick={() => getById(val.Id)}
                                  >
                                    <img
                                      src="assets/img/icons/edit.svg"
                                      alt="Edit"
                                      title="Edit"
                                    />
                                  </span>
                                  {/* )} */}

                                  <span
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#exampleModalLongDetail"
                                    onClick={() => details(val.Id)}
                                  >
                                    <img
                                      src="assets/img/icons/eye.svg"
                                      alt="Details"
                                      title="Details"
                                    />
                                  </span>
                                  <span>
                                    <a
                                      style={{
                                        cursor: "pointer",
                                        //     display:
                                        //         isStatus === "0" ? "none" : "block",
                                      }}
                                      onClick={() =>
                                        deActive(
                                          val,
                                          "Appoinment/AppoinmentStatus"
                                        )
                                      }
                                    >
                                      {val.Status === "1" ? (
                                        <img
                                          className="thumb_icon"
                                          src="assets/img/icons/Up.svg"
                                          title="Status"
                                        />
                                      ) : (
                                        <img
                                          className="thumb_icon"
                                          src="assets/img/icons/Down.svg"
                                          title="Status"
                                        />
                                      )}
                                    </a>
                                  </span>
                                  {/* {isDelete !== "0" && ( */}
                                  <span
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModalDelete"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setDelId(val.Id)}
                                  >
                                    <img
                                      src="assets/img/icons/delete.svg"
                                      alt="Delete"
                                      title="Delete"
                                    />
                                  </span>
                                  {/* )} */}
                                </div>
                              </td>
                              <td>{formatDate1(val.Date)}</td>
                              <td>
                                {val.ClientName === "" ||
                                val.ClientName === null
                                  ? "-"
                                  : val.ClientName}
                              </td>
                              <td>
                                {val.AppointmentWith === "" ||
                                val.AppointmentWith === null
                                  ? "-"
                                  : val.AppointmentWith}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <div></div>
                        )}
                      </table>
                    </div>
                  ) : (
                    <p className="case_detail_table">Data not found</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
        <Footer/>

        </div>
      </DocumentTitle>
      {/*-------- ADD FORM ---------*/}

      <div
        className="modal fade"
        id="exampleModalLong"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
        show={show}
      >
        <div
          className="modal-dialog modal-xl"
          role="document"
          // style={{ maxWidth: "65%" }}
        >
          <div className="modal-content">
            <div className="add_wrap">
              <div className="main_heading">
                <h2>Add Appointment</h2>
              </div>
            </div>
            <div className="popup_modal ">
              <button
                type="button"
                className="close btn_popup"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="dashed_popup">
                <form role="form" onSubmit={submitHandler}>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="name">File No. <text className="m_star">*</text></label>
                      <select disabled id="city" required>
                        {optionsCase &&
                          optionsCase.map((val, index) => (
                            <option
                              key={index}
                              value={val.value}
                              selected={val.value === id}
                            >
                              {val.label}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Client Name<text className="m_star">*</text></label>
                      <select className="name" required id="cars">
                        <option value="">--Please Select--</option>
                        {countries && countries.length > 0 ? (
                          countries.map((val) => (
                            <option key={val.Id} value={val.Id}>
                              {val.FirstName}
                            </option>
                          ))
                        ) : (
                          <option value="">Loading...</option>
                        )}
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="name">Appointment With </label>
                      <input
                        value={AppointmentWith}
                        type="text"
                        className="name"
                        placeholder="Enter Appointment With"
                        onChange={(e) => setAppointmentWith(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="name">
                        Date <text className="m_star">*</text>
                      </label>
                      <input
                        required
                        value={Dates}
                        type="Date"
                        className="name"
                        placeholder="Enter Date"
                        onChange={(e) => setDates(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Time </label>
                      <input
                        value={Time}
                        type="Time"
                        className="name"
                        placeholder="Enter Time"
                        onChange={(e) => setTime(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="name">
                        Assigned to User <text className="m_star">*</text>
                      </label>
                      <select
                        required
                        className="name"
                        id="city"
                        value={AssignTo}
                        onChange={(e) => setAssignTo(e.target.value)}
                      >
                        <option value="">--Please Select--</option>
                        {User && User.length > 0 ? (
                          User.map((val) => (
                            <option key={val.id} value={val.Id}>
                              {val.ContactPersonName}
                            </option>
                          ))
                        ) : (
                          <option value="">Loading...</option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="city">Is Pending</label>
                      <select
                        className="name"
                        value={IsPending}
                        onChange={(e) => setIsPending(e.target.value)}
                      >
                        <option value="">--Please Select--</option>
                        <option value="0">PENDING</option>
                        <option value="1">DONE</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="name">Summary </label>
                      <input
                        value={Summary}
                        type="text"
                        className="name"
                        placeholder="Enter Summary"
                        onChange={(e) => setSummary(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="name">Details </label>
                      <textarea
                        value={Details}
                        type="text"
                        className="name"
                        placeholder="Enter Details"
                        onChange={(e) => setDetails(e.target.value)}
                      />
                    </div>
                  </div>
                  {
                    saveButton ? (
                      <div className="btn_main caseMobile">
                      <button type="submit" className="btn_save">
                        Save
                      </button> 
                      <button
                        type="button"
                        data-dismiss="modal"
                        className="btn_save btn_cancle"
                      >
                        Cancel
                      </button>
                    </div>
                    ) : ""
                  }
                
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*-------- Edit FORM ---------*/}

      <div
        className="modal fade"
        id="exampleModalLongEdit"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
        show={show}
      >
        <div
          className="modal-dialog modal-xl"
          role="document"
          // style={{ maxWidth: "65%" }}
        >
          <div className="modal-content">
            <div className="add_wrap">
              <div className="main_heading">
                <h2>Edit Appointment</h2>
              </div>
            </div>
            <div className="popup_modal ">
              <button
                type="button"
                className="close e_popup btn_popup"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="dashed_popup">
                <form role="form" onSubmit={submitEditHandler}>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="name">File No.<text className="m_star">*</text></label>
                      <select 
                      required
                        disabled
                        className="name"
                        id="cars"
                      >
                        <option value="">--Please Select--</option>
                        {FileNo && FileNo.length ? (
                          FileNo.map((val, index) => {
                            return (
                              <option
                                key={index}
                                value={val.CaseID}
                                selected={val.CaseID === EditCaseID}
                              >
                                {val.FileNo && `${val.FileNo} /`}
                                {val.CaseName && `${val.CaseName} /`}
                                {val.CaseYear}
                              </option>
                            );
                          })
                        ) : (
                          <option value="">Loading...</option>
                        )}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">
                        Client Name<text className="m_star">*</text>
                      </label>
                      <select
                      required
                        className="name"
                        id="cars"
                        onChange={(e) => setEditClientID(e.target.value)}
                      >
                        <option value="">--Please Select--</option>
                        {countries && countries.length > 0 ? (
                          countries.map((val) => (
                            <option
                              key={val.Id}
                              value={val.Id}
                              selected={val.Id === EditClientID}
                            >
                              {val.FirstName}
                            </option>
                          ))
                        ) : (
                          <option value="">Loading...</option>
                        )}
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="name">Appointment With </label>
                      <input
                        type="text"
                        value={EditAppointmentWith}
                        className="name"
                        placeholder="Enter Appointment With (if case not selected)"
                        onChange={(e) => setEditAppointmentWith(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="name">
                        Date <text className="m_star">*</text>
                      </label>
                      <input
                        required
                        value={EditDate}
                        type="Date"
                        className="name"
                        placeholder="Enter Date"
                        onChange={(e) => setEditDate(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Time </label>
                      <input
                        type="Time"
                        value={EditTime}
                        className="name"
                        placeholder="Enter Time"
                        onChange={(e) => setEditTime(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="name">
                        Assigned to User <text className="m_star">*</text>
                      </label>
                      <select
                        id="city"
                        required
                        onChange={(e) => setEditAssignTo(e.target.value)}
                      >
                        <option>--Please Select--</option>
                        {User && User.length > 0 ? (
                          User.map((val) => (
                            <option
                              key={val.id}
                              value={val.Id}
                              selected={val.Id === EditAssignTo}
                            >
                              {val.ContactPersonName}
                            </option>
                          ))
                        ) : (
                          <option value="">Loading...</option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="city">Is Pending</label>
                      <select
                        value={EditIsPending}
                        onChange={(e) => setEditIsPending(e.target.value)}
                      >
                        <option value="">--Please Select--</option>
                        <option value="0">PENDING</option>
                        <option value="1">DONE</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="name">Summary </label>
                      <input
                        value={EditSummary}
                        type="text"
                        className="name"
                        placeholder="Enter Summary"
                        onChange={(e) => setEditSummary(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="name">Details </label>
                      <textarea
                        value={EditDetails}
                        type="text"
                        className="name"
                        placeholder="Enter Details"
                        onChange={(e) => setEditDetails(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="btn_main caseMobile">
                    <button type="submit" className="btn_save">
                      Save
                    </button>
                    <button
                      type="button"
                      data-dismiss="modal"
                      className="btn_save btn_cancle"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*-------- DELETE FORM -------*/}

      <div
        className="modal fade sc_modal"
        id="exampleModalDelete"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close mm"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <h4>Delete Row</h4>
              <p>Are you sure you want to delete this Appointment ?</p>
              <ul>
                <li>
                  <a className="btn btn-secondary" data-bs-dismiss="modal">
                    Cancel
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-danger"
                    onClick={() => DeleteAppoinment(delId)}
                  >
                    Delete
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/*-------- Details FORM ---------*/}

      <div
        className="modal fade"
        id="exampleModalLongDetail"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
        show={show}
      >
        <div
          className="modal-dialog modal-xl"
          role="document"
          // style={{ maxWidth: "50%" }}
        >
          <div className="modal-content">
            <div className="add_wrap">
              <div className="main_heading">
                <h2>View Appointment</h2>
              </div>
            </div>
            <div className="popup_modal ">
              <button
                type="button"
                className="close ab btn_popup"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="dashed_popup">
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>
                      Case Name &nbsp;: &nbsp;
                      {`${dCaseID} / ${dCaseName}`}
                    </h4>
                  </div>

                  <div className="form-group">
                    <h4>
                      Client &nbsp;: &nbsp;
                      {dClientID == null ? "-" : dClientID}
                    </h4>
                  </div>
                </div>
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>
                      Date &nbsp;: &nbsp;
                      {dDate === null || dDate === "0000-00-00"
                        ? "-"
                        : formatDate1(dDate)}
                    </h4>
                  </div>
                  <div className="form-group">
                    <h4>
                      Time &nbsp;: &nbsp;
                      {dTime == null ? "-" : convertTo12HourFormat(dTime)}
                    </h4>
                  </div>
                </div>
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>
                      Assigned to User&nbsp;: &nbsp;
                      {dAssignTo == null ? "-" : dAssignTo}
                    </h4>
                  </div>
                  <div className="form-group">
                    <div>
                      <h4>
                        Is Pending &nbsp;: &nbsp;
                        {dIsPending === "1" ? "Done" : "Pending"}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>
                      Appointment With &nbsp;: &nbsp;
                      {dAppointmentWith == null ? "-" : dAppointmentWith}
                    </h4>
                  </div>
                  <div className="form-group Comment_break">
                    <h4>
                      Summary &nbsp;: &nbsp;
                      {dSummary == null ? "-" : dSummary}
                    </h4>
                  </div>
                </div>
                <div className="form_flex caseMobile">
                  <div className="form-group Comment_break">
                    <h4>
                      Details&nbsp;: &nbsp;
                      {dDeatils == null ? "-" : dDeatils}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppointCD;
