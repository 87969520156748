import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import Preloader from "./Preloader";
import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { activeStatus, getCurrentDate } from "../services/api.service";
import DocumentTitle from "react-document-title";
import { getClient, permission } from "../services/api.service";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Footer from "./Footer";

const ViewClient = () => {
  const [saveButton, setSaveButton] = useState(true);
  const baseUrl = useContext(url);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const [isValid, setIsValid] = useState(true);
  const handleInputChange = (e) => {
    const value = e.target.value;
    const isValidInput = /^\d{10}$/.test(value);

    setMobileNo(value);
    setIsValid(isValidInput);
  };
  const handleInputChange1 = (e) => {
    const value = e.target.value;
    const isValidInput = /^\d{10}$/.test(value);

    setEditMobileNo(value);
    setIsValid(isValidInput);
  };
  const handleInputChange2 = (e) => {
    const value = e.target.value;
    const isValidInput = /^\d{10}$/.test(value);

    setPhone(value);
    setIsValid(isValidInput);
  };
  const handleInputChange3 = (e) => {
    const value = e.target.value;
    const isValidInput = /^\d{10}$/.test(value);

    setEditPhone(value);
    setIsValid(isValidInput);
  };
  const handleKeyPressalpha = (e) => {
    const charCode = e.which || e.keyCode;

    if (
      (charCode < 65 || charCode > 90) &&
      (charCode < 97 || charCode > 122) &&
      charCode !== 8 &&
      charCode !== 32 &&
      charCode !== 44
    ) {
      e.preventDefault();
    }
  };

  const handleKeyPress = (e) => {
    const charCode = e.which || e.keyCode;
    if (
      charCode < 48 ||
      charCode > 57 ||
      (charCode !== 8 && e.target.value.length >= 10)
    ) {
      e.preventDefault();
    }
  };
  const handleKeyPress1 = (e) => {
    const charCode = e.which || e.keyCode;
    if (
      charCode < 48 ||
      charCode > 57 ||
      (charCode !== 8 && charCode !== 47 && e.target.value.length >= 6)
    ) {
      e.preventDefault();
    }
  };

  //  ---------- Add Client
  const [excel, setExcel] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [isAdd, setIsAdd] = useState("0");
  const [isEdit, setIsEdit] = useState("0");
  const [isView, setIsView] = useState("0");
  const [isDelete, setIsDelete] = useState("0");
  const [pStatus, setPStatus] = useState("0");
  const [delId, setDelId] = useState("");
  const formData = new FormData();
  const filterform = new FormData();
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [Designation, setDesignation] = useState("");
  const [HouseNo, setHouseNo] = useState("");
  const [MobileNo, setMobileNo] = useState("");
  const [Phone, setPhone] = useState("");
  const [Email, setEmail] = useState("");
  const [CityID, setCityID] = useState("");
  const [Address, setAddress] = useState("");
  const [PinCode, setPinCode] = useState("");
  const [Source, setSource] = useState("");
  const [ReferredByClientID, setReferredByClientID] = useState("");
  const [ReferredByCounselID, setReferredByCounselID] = useState("");
  const [CountryID, setCountryID] = useState("1");
  const [loading, setLoading] = useState(true);
  const [StateID, setStateID] = useState("");
  const [cityData, setCityData] = useState("");
  const [villageID, setVillageID] = useState("");
  const [village, setVillage] = useState([]);
  const [UserList, setUserList] = useState([]);
  const ViewData = new FormData();
  const villegeForm = new FormData();
  const [filteredcountries, setFilteredCountries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [referredByClientData, setReferredByClientData] = useState([]);
  const [referredByCounselData, setReferredByCounselData] = useState([]);
  const [options, setOptions] = useState([]);

  const [open, setOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const handleExportButtonClick = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const handleToggleColumn = (columnName) => {
    const currentIndex = selectedColumns.indexOf(columnName);
    const newSelectedColumns = [...selectedColumns];

    if (currentIndex === -1) {
      newSelectedColumns.push(columnName);
    } else {
      newSelectedColumns.splice(currentIndex, 1);
    }

    setSelectedColumns(newSelectedColumns);
  };

  const handlePinCodeChange = (event) => {
    const rawPinCode = event.target.value.replace(/\D/g, "");

    if (rawPinCode.length <= 6) {
      setPinCode(rawPinCode);
    } else {
      toast.error("Invalid PinCode, It should be 6 digits");
    }
  };

  const closeButton = document.querySelector(".abs");
  const closeEdit = document.querySelector(".acd");
  const closeDelete = document.querySelector(".d_client");
  const navigate = useNavigate();

  const getClientView = async () => {
    const [data, cdata] = await Promise.all([
      permission(2),
      getClient("Task/ViewUserList"),
      viewClientApi(),
    ]);

    setUserList(cdata);
    setReferredByCounselData(cdata);

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }

    setIsAdd(data && data.result[0].IsAdd);
    setIsEdit(data && data.result[0].IsEdit);
    setIsView(data && data.result[0].IsView);
    setPStatus(data && data.result[0].IsStatus);
    setIsDelete(data && data.result[0].IsDelete);
  };

  //------------ VIEW VILEGE ----------

  const getVillege = async () => {
    villegeForm.append("city_id", cityData);
    try {
      const response = await fetch(`${baseUrl}Master/ViewVillageByCityId`, {
        method: "POST",
        body: villegeForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      setVillage(data.result);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getDistrict();
    setCity(null);
  }, [StateID]);

  useEffect(() => {
    getCity();
    setVillage(null);
  }, [CityID]);

  useEffect(() => {
    getVillege();
  }, [cityData]);

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setSaveButton(false);
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("FirstName", FirstName.toUpperCase());
    formData.append("LastName", LastName.toUpperCase());
    formData.append("CompanyName", CompanyName.toUpperCase());
    formData.append("Designation", Designation.toUpperCase());
    formData.append("HouseNo", HouseNo);
    if (MobileNo.length === 10) {
      formData.append("MobileNo", MobileNo);
    } else {
      toast.error("Invalid Mobile No");
    }
    formData.append("Phone", Phone);
    formData.append("Email", Email);
    formData.append("IsLead", selectedOption);
    formData.append("CityID", cityData);
    formData.append("Address", Address.toUpperCase());
    formData.append("PinCode", PinCode);
    formData.append("Source", Source);
    formData.append("ReferredByClientID", ReferredByClientID);
    formData.append("ReferredByCounselID", ReferredByCounselID);
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    formData.append("CountryID", CountryID);
    formData.append("StateID", StateID);
    formData.append("VillageID", villageID);
    formData.append("DistrictID", CityID);
    formData.append("Comments", text.toUpperCase());

    for (let pair of formData.entries()) {
      let fieldName = pair[0];
      let fieldValue = pair[1];

      if (fieldValue === "undefined" || fieldValue === "") {
        formData.set(fieldName, "");
      }
    }

    try {
      const response = await fetch(`${baseUrl}Client/AddClient`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: formData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      if (data.result[0].message === "Success") {
        filterActive ? filterApi() : getClientView();
        closeButton.click();
        clearForm();
        toast.success("Client Added !");
        setSaveButton(true);
      } else {
        toast.error(data.result[0].message);
        setSaveButton(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Edit Client
  const EditformData = new FormData();
  const [EditId, setEditId] = useState("");
  const [EditFirstName, setEditFirstName] = useState("");
  const [EditLastName, setEditLastName] = useState("");
  const [EditCompanyName, setEditCompanyName] = useState("");
  const [EditDesignation, setEditDesignation] = useState("");
  const [EditHouseNo, setEditHouseNo] = useState("");
  const [EditMobileNo, setEditMobileNo] = useState("");
  const [EditPhone, setEditPhone] = useState("");
  const [EditEmail, setEditEmail] = useState("");
  const [EditIsLead, setEditIsLead] = useState("");
  const [EditAddress, setEditAddress] = useState("");
  const [EditPinCode, setEditPinCode] = useState("");
  const [EditSource, setEditSource] = useState("");
  const [EditReferredByClientID, setEditReferredByClientID] = useState("");
  const [EditReferredByCounselID, setEditReferredByCounselID] = useState("");
  const [Edittext, setEditText] = useState("");
  const [EditCountryID, setEditCountryID] = useState("");
  const [EditselectedOption, setEditSelectedOption] = useState("");
  const [ReferredByClientName, setReferredByClientName] = useState("");
  const [ReferredByCounselName, setReferredByCounselName] = useState("");
  const [city_title, setcity_title] = useState("");
  const [district_title, setdistrict_title] = useState("");
  const [state_title, setstate_title] = useState("");
  const [country_title, setcountry_title] = useState("");

  const HandleEditSubmit = async (e) => {
    e.preventDefault();
    EditformData.append("ID", EditId);
    EditformData.append("AdminID", localStorage.getItem("id"));
    EditformData.append("FirstName", EditFirstName.toUpperCase());
    EditformData.append("LastName", EditLastName.toUpperCase());
    EditformData.append("CompanyName", EditCompanyName.toUpperCase());
    EditformData.append("Designation", EditDesignation.toUpperCase());
    EditformData.append("HouseNo", EditHouseNo.toUpperCase());
    EditformData.append("MobileNo", EditMobileNo);
    EditformData.append("Phone", EditPhone);
    EditformData.append("Email", EditEmail);

    EditformData.append("IsLead", EditselectedOption);

    EditformData.append("CityID", cityData);

    EditformData.append("Address", EditAddress.toUpperCase());
    EditformData.append("PinCode", EditPinCode);
    EditformData.append("Source", EditSource);
    EditformData.append("ReferredByClientID", EditReferredByClientID);
    EditformData.append("ReferredByCounselID", EditReferredByCounselID);

    if (EditCountryID && EditCountryID.length > 0) {
      EditformData.append("CountryID", EditCountryID);
    } else {
      EditformData.append("CountryID", "");
    }

    EditformData.append("StateID", StateID);
    if (villageID && villageID.length > 0) {
      EditformData.append("VillageID", villageID);
    } else {
      EditformData.append("VillageID", " ");
    }
    EditformData.append("DistrictID", CityID);
    EditformData.append("Comments", Edittext.toUpperCase());
    EditformData.append("UpdatedBy", localStorage.getItem("UserId"));

    try {
      const response = await fetch(`${baseUrl}Client/EditClient`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: EditformData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }

      if (data.result[0].code === "200") {
        filterActive ? filterApi() : getClientView();
        closeEdit.click();
        clearForm();
        toast.success("Client Updated !");
      } else {
        toast.error(data.result[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Delete Client
  const handleDeleteClient = async () => {
    const access_token = localStorage.getItem("access_token");
    formData.append("ID", delId);
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("DeletedBy", localStorage.getItem("UserId"));

    try {
      const response = await fetch(`${baseUrl}Client/DeleteClient`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${access_token}`,
          Accept: "*/*",
          ContentType: "multipart/form-data",
        },
      });
      if (!response.ok) {
        throw new Error("Request failed with status " + response.status);
      }
      // Handle the response from the server
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      if (data.result.code === 200) {
        filterActive ? filterApi() : getClientView();
        closeDelete.click();
        toast.success("Data Deleted !");
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // View Client by ID
  const userById = new FormData();
  const updateUser = async (id) => {
    userById.append("AdminID", `${localStorage.getItem("id")}`);
    userById.append("ID", id);

    try {
      const response = await fetch(`${baseUrl}Client/ViewClientById`, {
        method: "POST",
        body: userById,

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data5 = await response.json();
      if (data5 && data5.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      setEditId(data5.result[0].Id);
      setEditFirstName(data5.result[0].FirstName);
      setEditLastName(data5.result[0].LastName);
      setEditCompanyName(data5.result[0].CompanyName);
      setEditDesignation(data5.result[0].Designation);
      setEditHouseNo(data5.result[0].HouseNo);
      setEditMobileNo(data5.result[0].MobileNo);
      setEditPhone(data5.result[0].Phone);
      setEditEmail(data5.result[0].Email);
      setEditIsLead(data5.result[0].IsLead);
      setEditSelectedOption(data5.result[0].IsLead);
      setCityData(
        data5.result[0].CityID === "0" ? "" : data5 && data5.result[0].CityID
      );
      setEditAddress(data5.result[0].Address);
      setEditPinCode(data5.result[0].PinCode);
      setVillageID(data5.result[0].VillageID);
      setEditSource(data5.result[0].Source);
      setEditReferredByClientID(
        data5.result[0].ReferredByClientID === "0"
          ? ""
          : data5 && data5.result[0].ReferredByClientID
      );
      setEditReferredByCounselID(
        data5.result[0].ReferredByCounselID === "0"
          ? ""
          : data5 && data5.result[0].ReferredByCounselID
      );
      setEditText(data5.result[0].Comments);
      setEditCountryID(
        data5.result[0].CountryID === "0"
          ? ""
          : data5 && data5.result[0].CountryID
      );
      setStateID(
        data5 && data5.result[0].StateID === "0"
          ? ""
          : data5 && data5.result[0].StateID
      );
      setCityID(
        data5.result[0].DistrictID === "0"
          ? ""
          : data5 && data5.result[0].DistrictID
      );
      setIsStatus(data5.result[0].Status);
      setReferredByClientName(
        data5.result[0].ReferredByClientName === "0"
          ? ""
          : data5 && data5.result[0].ReferredByClientName
      );
      setReferredByCounselName(
        data5.result[0].ReferredByCounselName === "0"
          ? ""
          : data5 && data5.result[0].ReferredByCounselName
      );
      setcity_title(data5.result[0].city_title);
      setdistrict_title(data5.result[0].district_title);
      setstate_title(data5.result[0].state_title);
      setcountry_title(data5.result[0].state_title);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClientSelectChange = (e) => {
    if (e.target.value === "") {
      setReferredByClientID("");
      setReferredByCounselData(UserList);
    } else {
      setReferredByClientID(e.target.value);
      setReferredByCounselID("");
      setReferredByCounselData([]);
    }
  };

  const handleEditReferredByClientID = (value) => {
    if (value === "") {
      setEditReferredByClientID("");
      setReferredByCounselData(UserList);
    } else {
      setEditReferredByClientID(value);
      setEditReferredByCounselID("");
      setReferredByCounselData([]);
    }
  };

  const handleCounselSelectChange = (e) => {
    if (e.target.value === "") {
      setReferredByCounselID("");
      setReferredByClientData(countries);
    } else {
      setReferredByCounselID(e.target.value);
      setReferredByClientID("");
      setReferredByClientData([]);
    }
  };

  const handleEditReferredByCounselID = (value) => {
    if (value === "") {
      setEditReferredByCounselID("");
      setReferredByClientData(countries);
    } else {
      setEditReferredByCounselID(value);
      setEditReferredByClientID("");
      setReferredByClientData([]);
    }
  };

  // --------------- VIEW Client----------------

  const viewClientApi = async () => {
    setLoading(true);
    ViewData.append("AdminID", localStorage.getItem("id"));
    ViewData.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}Client/ViewClient`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: ViewData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
      }

      if (data && data.result[0].message === "Success") {
        setLoading(false);

        const clientData =
          data && data.result.filter((item) => item.Status === "1");

        if (clientData) {
          const modifiedClientData = clientData.map((item) => ({
            ...item,
            // Add or replace the properties that you want to convert to uppercase
            FirstName: item.FirstName,
            LastName: item.LastName,
            // Add more properties as needed
          }));

          setReferredByClientData(modifiedClientData);
        } else {
          setReferredByClientData([]);
        }
        setCountries(data && data.result);

        const Mapped = data.result.map((item) => ({
          value: item.Id,
          label: item.FirstName,
        }));
        setOptions(Mapped);
        setFilteredCountries(data && data.result);
      } else {
        setLoading(false);
      }

      if (data.response_code === 400) {
        toast.error("Token expired please login again");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // ---------- FILTER API ---------

  const filterApi = async () => {
    filterform.append("AdminID", localStorage.getItem("id"));
    filterform.append("UserID", localStorage.getItem("uId"));
    filterform.append("Client", filterValue);

    try {
      const response = await fetch(`${baseUrl}Client/ViewClientFilter`, {
        method: "POST",
        body: filterform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }

      if (
        data &&
        data.result.length &&
        data &&
        data.result[0].message === "Success"
      ) {
        setFilterActive(true);
        setFilteredCountries(data.result);
      } else {
        setFilteredCountries([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterCancel = () => {
    setFilterValue("");
    getClientView();
    setFilterActive(false);
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getClientView();
    }
  }, []);

  function clearForm() {
    var fields = document.getElementsByClassName("name");
    for (var i = 0; i < fields.length; i++) {
      fields[i].value = "";
    }
  }

  const [selectedOption, setSelectedOption] = useState("0");

  const handleOptionChange2 = (event) => {
    setEditSelectedOption(event.target.value);
  };
  const handleOptionChange1 = (event) => {
    setSource(event.target.value);
  };
  const handleOptionChange3 = (event) => {
    setEditSource(event.target.value);
  };
  const [text, setText] = useState("");

  const handleTextChange = (event) => {
    setText(event.target.value);
  };
  const handleTextChange1 = (event) => {
    setEditText(event.target.value);
  };
  // dropdown

  const [State, setState] = useState([]);
  const getState = async () => {
    try {
      const response = await fetch(`${baseUrl}Master/ViewState`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data2 = await response.json();
      if (data2 && data2.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      setState(data2.result);
    } catch (error) {
      console.error(error);
    }
  };
  const district = new FormData();
  const c = new FormData();
  const [District, setDistrict] = useState([]);
  const getDistrict = async () => {
    district.append("state_id", StateID);
    try {
      const response = await fetch(`${baseUrl}Master/ViewDistricByStateID`, {
        method: "POST",
        body: district,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data3 = await response.json();
      if (data3 && data3.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      setDistrict(data3.result);
    } catch (error) {
      console.error(error);
    }
  };

  const [City, setCity] = useState([]);
  const getCity = async () => {
    c.append("district_id", CityID);

    try {
      const response = await fetch(`${baseUrl}Master/ViewCityByDistrictid`, {
        method: "POST",
        body: c,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data1 = await response.json();
      if (data1 && data1.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      setCity(data1.result);
    } catch (error) {
      console.error(error);
    }
  };
  const handleStateChange = (e) => {
    setStateID(e.target.value);
    setCityData("");
    setCityID("");

    getDistrict();
  };

  const handleDistrictChange = (e) => {
    setCityID(e.target.value);
    getCity();
    setCityData("");
  };
  const handleCityChange = (e) => {
    setCityData(e.target.value);
  };
  useEffect(() => {
    getState();
    getCity();
    getDistrict();
  }, [StateID, CityID]);

  //        ---------- ACTIVE DEACTIVE  --------

  const deActive = async (row) => {
    const data = await activeStatus(row, "Client/ClientStatus");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      filterActive ? filterApi() : getClientView();
    }
  };
  const [isStatus, setIsStatus] = useState();

  // ------- TABLE --------

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      header: "Action",
      Cell: (row) => {
        return (
          <div style={{ display: "flex", gap: "15px" }}>
            <span>
              {isEdit !== "0" && (
                <a
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalLongEdit"
                  onClick={() => updateUser(row.row.original.Id)}
                >
                  <img src="assets/img/icons/edit.svg" title="Edit" />
                </a>
              )}
            </span>

            <span>
              <a
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongView"
                onClick={() => updateUser(row.row.original.Id)}
              >
                <img src="assets/img/icons/eye.svg" title="Details" />
              </a>
            </span>
            <span>
              <a
                style={{
                  cursor: "pointer",
                  display: isStatus === "0" ? "none" : "block",
                }}
                onClick={() => deActive(row.row.original)}
              >
                {row.row.original.Status === "1" ? (
                  <img
                    className="thumb_icon"
                    src="assets/img/icons/Up.svg"
                    title="Status"
                  />
                ) : (
                  <img
                    className="thumb_icon"
                    src="assets/img/icons/Down.svg"
                    title="Status"
                  />
                )}
              </a>
            </span>

            <span>
              {isDelete !== "0" && (
                <a
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() => setDelId(row.row.original.Id)}
                >
                  <img src="assets/img/icons/delete.svg" title="Delete" />
                </a>
              )}
            </span>
          </div>
        );
      },
    }),

    columnHelper.accessor("FirstName", {
      header: "First Name",
      Cell: (row) => {
        return (
          <div className="upperCase">
            <a
              title="Client Details"
              href={`#/view-clientdetails/${row.row.original.Id}`}
              className="visuald_case"
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              {row.row.original.FirstName}
            </a>
          </div>
        );
      },
    }),

    columnHelper.accessor("LastName", {
      header: "Last Name & S/W/D of",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.LastName === null ||
            row.row.original.LastName === ""
              ? "-"
              : row.row.original.LastName}
          </div>
        );
      },
    }),

    columnHelper.accessor("MobileNo", {
      header: "Mobile No",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.MobileNo === null ||
            row.row.original.MobileNo === "0000"
              ? "-"
              : row.row.original.MobileNo.replace(/,/g, "")}
          </div>
        );
      },
    }),

    columnHelper.accessor("Email", {
      header: "Email",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Email === null || row.row.original.Email === ""
              ? "-"
              : row.row.original.Email}
          </div>
        );
      },
    }),

    columnHelper.accessor("Company", {
      header: "Company",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Company === null ||
            row.row.original.Company === ""
              ? "-"
              : row.row.original.Company}
          </div>
        );
      },
    }),
    columnHelper.accessor("state", {
      header: "State",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.state === null || row.row.original.state === ""
              ? "-"
              : row.row.original.state}
          </div>
        );
      },
    }),

    columnHelper.accessor("district", {
      header: "District",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.district === null ||
            row.row.original.district === ""
              ? "-"
              : row.row.original.district}
          </div>
        );
      },
    }),
    columnHelper.accessor("city", {
      header: "Tehsil",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.city === null || row.row.original.city === ""
              ? "-"
              : row.row.original.city}
          </div>
        );
      },
    }),
    columnHelper.accessor("village", {
      header: "Village",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.village === null ||
            row.row.original.village === ""
              ? "-"
              : row.row.original.village}
          </div>
        );
      },
    }),
    columnHelper.accessor("Address", {
      header: "Address",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div style={{ minWidth: "280px" }} className="upperCase">
            {row.row.original.Address === null ||
            row.row.original.Address === ""
              ? "-"
              : row.row.original.Address}
          </div>
        );
      },
    }),

    columnHelper.accessor("Comments", {
      header: "Comments",
      Cell: (row) => {
        return (
          <div style={{ minWidth: "280px" }} className="upperCase">
            {row.row.original.Comments === null ||
            row.row.original.Comments === ""
              ? "-"
              : row.row.original.Comments}
          </div>
        );
      },
    }),
    columnHelper.accessor("IsLead", {
      header: "IsLead",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.IsLead === null || row.row.original.IsLead === ""
              ? "-"
              : row.row.original.IsLead}
          </div>
        );
      },
    }),
    columnHelper.accessor("Source", {
      header: "Source",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Source === null || row.row.original.Source === ""
              ? "-"
              : row.row.original.Source}
          </div>
        );
      },
    }),
    columnHelper.accessor("ReferredByClientName", {
      header: "Referred By Client Name",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.ReferredByClientName === null ||
            row.row.original.ReferredByClientName === ""
              ? "-"
              : row.row.original.ReferredByClientName}
          </div>
        );
      },
    }),
    columnHelper.accessor("ReferredByCounselName", {
      header: "Referred By Counsel Name",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.ReferredByCounselName === null ||
            row.row.original.ReferredByCounselName === ""
              ? "-"
              : row.row.original.ReferredByCounselName}
          </div>
        );
      },
    }),
    columnHelper.accessor("Phone", {
      header: "Phone",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.Phone === null || row.row.original.Phone === ""
              ? "-"
              : row.row.original.Phone}
          </div>
        );
      },
    }),
    columnHelper.accessor("Designation", {
      header: "Designation",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Designation === null ||
            row.row.original.Designation === ""
              ? "-"
              : row.row.original.Designation}
          </div>
        );
      },
    }),
    columnHelper.accessor("Status", {
      minSize: "10px",
      header: "Status",
      Cell: (row) => {
        return (
          <div>{row.row.original.Status === "1" ? "ACTIVE" : "DEACTIVE"}</div>
        );
      },
    }),

    columnHelper.accessor("AddedBy", {
      header: "Added By",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.AddedBy === null ||
            row.row.original.AddedBy === ""
              ? "-"
              : row.row.original.AddedBy}
          </div>
        );
      },
    }),
  ];

  const excelData =
    filteredcountries &&
    filteredcountries.map((obj) => {
      const { Id, code, message, Status, AdminID, ...rest } = obj;
      return rest;
    });

  const handleExportData = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });

    const formattedDate = new Date().toISOString().slice(0, 10);
    const filename = `Client|LegalCRM__${formattedDate}.csv`;

    const csv = generateCsv(csvConfig)(excelData);
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const columnsToExport = [
      { id: "serialNo", header: "S No." },
      ...columns.filter((column) => selectedColumns.includes(column.id)),
    ];

    const tableHeaders = columnsToExport.map((c) => c.header);

    const formattedDate = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const filename = `Client|LegalCRM__${formattedDate}.pdf`;
    const addWatermarkToFirstPage = () => {
      const watermarkHeight = 20;

      // Calculate positioning to center the watermark on each page

      doc.setFontSize(10);
      doc.text(
        10,
        watermarkHeight + -10,
        `Generated by : ${localStorage.getItem("name")} `
      );
      doc.text(172, watermarkHeight + -10, `Date: ${formattedDate}`);
      doc.setFontSize(16);
      doc.text("Client Table", 84, 20);
    };
    const watermarkHeight = 20;

    addWatermarkToFirstPage();
    const tableData = rows.map((row, index) => {
      return [
        { content: (index + 1).toString(), styles: { fontStyle: "bold" } }, // Serial number
        ...columnsToExport.slice(1).map((column) => {
          // Exclude the first column (serial number) when mapping
          const value =
            column.id in row.original ? row.original[column.id] : "";
          return value !== null && value !== undefined ? value : "";
        }),
      ];
    });

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY: watermarkHeight + 5,
    });

    // Add a heading to the table

    // Save the PDF to a Blob
    const pdfBlob = doc.output("blob");

    // Create a blob URL
    const blobUrl = URL.createObjectURL(pdfBlob);

    // Open the URL in a new tab
    window.open(blobUrl, "_blank");

    // Optionally revoke the object URL after opening the new tab
    URL.revokeObjectURL(blobUrl);
    // doc.save(filename);
  };
  const table = useMaterialReactTable({
    columns,
    data: filteredcountries,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    initialState: {
      columnVisibility: {
        ReferredByCounselName: false,
        ReferredByClientName: false,
        Source: false,
        state: false,
        district: false,
        city: false,
        village: false,
        Designation: false,
        IsLead: false,
        Source: false,
        AddedBy: false,
        Status: false,
      },
    },
    enableStickyHeader: true,
    enableRowNumbers: true,
    state: {
      isLoading: loading,
    },
    muiCircularProgressProps: {
      color: "primary",
      thickness: 5,
      size: 55,
      style: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000,
      },
    },
    muiSkeletonProps: {
      animation: "pulse",
      height: 20,
    },
    muiPaginationProps: {
      rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export Excel
        </Button>

        <Button
          disabled={
            !table || table.getPrePaginationRowModel().rows.length === 0
          }
          onClick={handleExportButtonClick}
          startIcon={<FileDownloadIcon />}
        >
          Export PDF
        </Button>
        <Dialog open={open} onClose={handleClose1}>
          <DialogTitle>Select Columns To Export</DialogTitle>
          <DialogContent>
            {columns.map((column) =>
              column.header !== "Action" ? (
                <div key={column.id}>
                  <Checkbox
                    checked={selectedColumns.includes(column.id)}
                    onChange={() => handleToggleColumn(column.id)}
                  />
                  {column.header}
                </div>
              ) : (
                ""
              )
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose1}>Cancel</Button>
            <Button
              onClick={() =>
                handleExportRows(table.getPrePaginationRowModel().rows)
              }
            >
              Export
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    ),
  });

  const searchFilter = (value) => {
    if (value.length < 1) {
      getClientView();
      setFilterValue(value);
    } else {
      setFilterValue(value);
    }
  };

  return (
    <>
      <DocumentTitle title="CLIENT | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  <div className="pageContent">
                    <div className="PageTitle">
                      <h3>View Clients</h3>
                      <a
                        style={{
                          display: isAdd === "0" ? "none" : "block",
                        }}
                        type="button"
                        className="btn btn-primary btn_client headbtn"
                        data-toggle="modal"
                        data-target="#exampleModalLong"
                      >
                        Add Clients
                        <img src="assets/img/icons/add.svg" />
                      </a>
                    </div>

                    <div
                      className="filter_type add_wrap"
                      style={{
                        display: isView === "0" ? "none" : "block",
                      }}
                    >
                      <div className="form_flex1 flex_wrap  caseMobile">
                        <div className="form-group1">
                          <label>Search For</label>
                          <input
                            type="text"
                            placeholder="Search By Name or Mobile No. or Address"
                            autoComplete="off"
                            className="name"
                            value={filterValue}
                            onChange={(e) => searchFilter(e.target.value)}
                          />
                        </div>
                        <div className="form-group1 caseMobile btnGroup">
                          <div className="btn_main casebtn">
                            <button
                              type="submit"
                              className="btn btn_client"
                              onClick={filterApi}
                            >
                              Search
                            </button>
                            <button
                              type="button"
                              className="btn btn_client"
                              onClick={filterCancel}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {countries &&
                    countries.length > 0 &&
                    countries[0].message === "Data not found" ? (
                      <h3 className="visuald">
                        There are no records to display
                      </h3>
                    ) : (
                      <>
                        <div
                          className="searDesign"
                          style={{
                            display: isView === "0" ? "none" : "block",
                          }}
                        >
                          <MaterialReactTable table={table} />
                        </div>
                      </>
                    )}

                    {/* Add Client Form */}
                    <div
                      className="modal fade"
                      id="exampleModalLong"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLongTitle"
                      aria-hidden="true"
                    >
                      <div
                        className="modal-dialog modal-xl"
                        role="document"
                        // style={{ maxWidth: "75%" }}
                      >
                        <div className="modal-content">
                          <div className="add_wrap">
                            <div className="main_heading">
                              <h2>Add Client</h2>
                            </div>
                          </div>
                          <div className="popup_modal ">
                            <button
                              type="button"
                              className="close btn_popup abs"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>

                            <div className="dashed_popup">
                              <form role="form " onSubmit={HandleSubmit}>
                                <div className="main_heading">
                                  <h2>Personal Details</h2>
                                </div>
                                <hr />
                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label for="uname">
                                      Full Name
                                      <text className="m_star">*</text>
                                    </label>
                                    <input
                                      autoComplete="off"
                                      className="name"
                                      type="text"
                                      placeholder="Enter Full Name"
                                      onChange={(e) =>
                                        setFirstName(e.target.value)
                                      }
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label for="uname">
                                      Mobile No
                                      <text className="m_star">*</text>
                                    </label>
                                    <input
                                      onKeyPress={handleKeyPress}
                                      maxLength={10}
                                      className="name"
                                      autoComplete="off"
                                      type="text"
                                      placeholder="Enter Mobile No"
                                      onChange={handleInputChange}
                                      required
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label for="uname">
                                      Last Name & Son/Wife/Daughter of
                                    </label>
                                    <input
                                      onKeyPress={handleKeyPressalpha}
                                      autoComplete="off"
                                      className="name"
                                      type="text"
                                      placeholder="Enter Name of Father/Husband"
                                      onChange={(e) =>
                                        setLastName(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label for="uname">Designation </label>
                                    <input
                                      onKeyPress={handleKeyPressalpha}
                                      autoComplete="off"
                                      className="name"
                                      type="text"
                                      placeholder="Enter Designation "
                                      onChange={(e) =>
                                        setDesignation(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label for="uname">Company Name</label>
                                    <input
                                      autoComplete="off"
                                      className="name"
                                      type="text"
                                      placeholder="Enter Company Name"
                                      onChange={(e) =>
                                        setCompanyName(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label for="uname">Email</label>
                                    <input
                                      className="name"
                                      autoComplete="off"
                                      type="Email"
                                      placeholder="Enter Email"
                                      onChange={(e) => setEmail(e.target.value)}
                                    />
                                  </div>
                                </div>
                                <div className="main_heading">
                                  <h2>Address </h2>
                                </div>
                                <hr />
                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label for="uname">Telephone No</label>
                                    <input
                                      onKeyPress={handleKeyPress}
                                      maxLength={10}
                                      className="name"
                                      autoComplete="off"
                                      type="text"
                                      onChange={handleInputChange2}
                                      placeholder="Enter Telephone No"
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label for="uname">House No</label>
                                    <input
                                      className="name"
                                      autoComplete="off"
                                      type="text"
                                      onKeyPress={handleKeyPress1}
                                      placeholder="Enter House No"
                                      onChange={(e) =>
                                        setHouseNo(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label for="uname">Address</label>
                                    <input
                                      onKeyPress={handleKeyPressalpha}
                                      autoComplete="off"
                                      className="name"
                                      type="text"
                                      placeholder="Enter Address"
                                      onChange={(e) =>
                                        setAddress(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label for="uname">Pin Code </label>
                                    <input
                                      maxLength={6}
                                      onKeyPress={handleKeyPress1}
                                      autoComplete="off"
                                      className="name"
                                      type="text"
                                      placeholder="Enter PinCode "
                                      onChange={handlePinCodeChange}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="city">Country</label>

                                    <select
                                      autoComplete="off"
                                      className="name"
                                      value={CountryID}
                                      onChange={(e) =>
                                        setCountryID(e.target.value)
                                      }
                                    >
                                      <option value="null">
                                        --Please Select--
                                      </option>
                                      <option value="1">INDIA</option>
                                    </select>
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="city">
                                      State
                                      <text className="m_star">*</text>
                                    </label>

                                    <select
                                      required
                                      className="name"
                                      id="city"
                                      onChange={(e) =>
                                        setStateID(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        --Please Select--
                                      </option>
                                      {State && State.length > 0 ? (
                                        State.map((val) => (
                                          <option
                                            key={val.state_id}
                                            value={val.state_id}
                                          >
                                            {val.state_title}
                                          </option>
                                        ))
                                      ) : (
                                        <option value="">Loading...</option>
                                      )}
                                    </select>
                                  </div>
                                </div>
                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label htmlFor="city">
                                      District
                                      <text className="m_star">*</text>
                                    </label>
                                    <select
                                      required
                                      className="name"
                                      id="citySelect"
                                      onChange={(e) =>
                                        setCityID(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        --Please Select--
                                      </option>
                                      {District && District.length > 0 ? (
                                        District.map((val) => (
                                          <option
                                            key={val.district_id}
                                            value={val.district_id}
                                          >
                                            {val.district_title}
                                          </option>
                                        ))
                                      ) : (
                                        <option value="">Loading...</option>
                                      )}
                                    </select>
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="city">
                                      Tehsil
                                      <text className="m_star">*</text>
                                    </label>
                                    <select
                                      required
                                      className="name"
                                      id="citySelect"
                                      onChange={(e) =>
                                        setCityData(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        --Please Select--
                                      </option>
                                      {City && City.length > 0 ? (
                                        City.map((val) => (
                                          <option
                                            key={val.city_id}
                                            value={val.city_id}
                                          >
                                            {val.city_title}
                                          </option>
                                        ))
                                      ) : (
                                        <option value="">Loading...</option>
                                      )}
                                    </select>
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="username">Village</label>
                                    <select
                                      name="cars"
                                      id="cars"
                                      onChange={(e) =>
                                        setVillageID(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        --Select Village--
                                      </option>
                                      {village && village.length ? (
                                        village.map((val, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={val.village_id}
                                            >
                                              {val.village_name}
                                            </option>
                                          );
                                        })
                                      ) : (
                                        <option value="">Loading...</option>
                                      )}
                                    </select>
                                  </div>
                                </div>
                                <div className="main_heading">
                                  <h2>Reference </h2>
                                </div>
                                <hr />
                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <div>
                                      <label htmlFor="city">Is Lead ?</label>

                                      <select
                                        value={selectedOption}
                                        className="name"
                                        onChange={(e) =>
                                          setSelectedOption(e.target.value)
                                        }
                                      >
                                        <option value="1">YES</option>
                                        <option value="0">NO</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="city">Source</label>

                                    <select
                                      className="name"
                                      onChange={handleOptionChange1}
                                    >
                                      <option value="">
                                        --Please Select--
                                      </option>
                                      <option value="Walkin">WALKIN</option>
                                      <option value="Reffered">REFFERED</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label for="uname">
                                      Referred By Client
                                    </label>

                                    <select
                                      className="name"
                                      id="clientSelect"
                                      value={ReferredByClientID}
                                      onChange={handleClientSelectChange}
                                    >
                                      <option value="">
                                        --Please Select--
                                      </option>
                                      {referredByClientData &&
                                      referredByClientData.length > 0
                                        ? referredByClientData.map((val) => (
                                            <option value={val.Id}>
                                              {val.FirstName} {val.LastName}
                                            </option>
                                          ))
                                        : ""}
                                    </select>
                                  </div>
                                  <div className="form-group">
                                    <label for="uname">
                                      Referred By Counsel
                                    </label>
                                    <select
                                      className="name"
                                      id="clientSelect"
                                      value={ReferredByCounselID}
                                      onChange={handleCounselSelectChange}
                                    >
                                      <option value="">
                                        --Please Select--
                                      </option>
                                      {referredByCounselData &&
                                      referredByCounselData.length > 0
                                        ? referredByCounselData.map((val) => (
                                            <option value={val.Id}>
                                              {val.ContactPersonName}
                                            </option>
                                          ))
                                        : ""}
                                    </select>
                                  </div>
                                </div>

                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label for="uname">Comments</label>
                                    <textarea
                                      className="name"
                                      onChange={handleTextChange}
                                      rows={4}
                                      cols={50}
                                      placeholder="Enter your text here..."
                                    />
                                  </div>
                                </div>
                                {saveButton ? (
                                  <div className="btn_main caseMobile">
                                    <button type="submit" className="btn_save">
                                      Save
                                    </button>
                                    <button
                                      type="button"
                                      onClick={clearForm}
                                      className="btn_save btn_cancle"
                                      data-dismiss="modal"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*-------------------- Edit Client Form-------------------- */}
                    <div
                      className="modal fade"
                      id="exampleModalLongEdit"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLongTitle"
                      aria-hidden="true"
                    >
                      <div
                        className="modal-dialog modal-xl"
                        role="document"
                        // style={{ maxWidth: "75%" }}
                      >
                        <div className="modal-content">
                          <div className="add_wrap">
                            <div className="main_heading">
                              <h2>Edit Client</h2>
                            </div>
                          </div>
                          <div className="popup_modal ">
                            <button
                              type="button"
                              className="close btn_popup acd"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>

                            <div className="dashed_popup">
                              <form role="form " onSubmit={HandleEditSubmit}>
                                <div className="main_heading">
                                  <h2>Personal Details </h2>
                                </div>
                                <hr />
                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label for="uname">
                                      Full Name
                                      <text className="m_star">*</text>
                                    </label>
                                    <input
                                      autoComplete="off"
                                      className="name"
                                      value={EditFirstName}
                                      type="text"
                                      placeholder="Enter Full Name"
                                      onChange={(e) =>
                                        setEditFirstName(e.target.value)
                                      }
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label for="uname">
                                      Mobile No
                                      <text className="m_star">*</text>
                                    </label>
                                    <input
                                      value={EditMobileNo}
                                      className="name"
                                      onKeyPress={handleKeyPress}
                                      maxLength={10}
                                      autoComplete="off"
                                      type="text"
                                      placeholder="Enter Mobile No"
                                      onChange={handleInputChange1}
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label for="uname">
                                      Last Name & Son/Wife/Daughter of
                                    </label>
                                    <input
                                      onKeyPress={handleKeyPressalpha}
                                      autoComplete="off"
                                      className="name"
                                      value={EditLastName}
                                      type="text"
                                      placeholder="Enter Name of Father/Husband"
                                      onChange={(e) =>
                                        setEditLastName(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label for="uname">Designation </label>
                                    <input
                                      onKeyPress={handleKeyPressalpha}
                                      value={EditDesignation}
                                      autoComplete="off"
                                      className="name"
                                      type="text"
                                      placeholder="Enter Designation "
                                      onChange={(e) =>
                                        setEditDesignation(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label for="uname">Company Name</label>
                                    <input
                                      autoComplete="off"
                                      className="name"
                                      value={EditCompanyName}
                                      type="text"
                                      placeholder="Enter Company Name"
                                      onChange={(e) =>
                                        setEditCompanyName(e.target.value)
                                      }
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label for="uname">Email</label>
                                    <input
                                      value={EditEmail}
                                      className="name"
                                      autoComplete="off"
                                      type="Email"
                                      placeholder="Enter Email"
                                      onChange={(e) =>
                                        setEditEmail(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="main_heading">
                                  <h2> Address </h2>
                                </div>
                                <hr />
                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label for="uname">Telephone No</label>
                                    <input
                                      value={EditPhone}
                                      onKeyPress={handleKeyPress}
                                      maxLength={10}
                                      className="name"
                                      autoComplete="off"
                                      type="text"
                                      onChange={handleInputChange3}
                                      placeholder="Enter Telephone No"
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label for="uname">House No</label>
                                    <input
                                      value={EditHouseNo}
                                      onKeyPress={handleKeyPress1}
                                      className="name"
                                      autoComplete="off"
                                      type="text"
                                      placeholder="Enter House No"
                                      onChange={(e) =>
                                        setEditHouseNo(e.target.value)
                                      }
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label for="uname">Address</label>
                                    <input
                                      onKeyPress={handleKeyPressalpha}
                                      value={EditAddress}
                                      autoComplete="off"
                                      className="name"
                                      type="text"
                                      placeholder="Enter Address"
                                      onChange={(e) =>
                                        setEditAddress(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label for="uname">Pin Code </label>
                                    <input
                                      onKeyPress={handleKeyPress1}
                                      value={EditPinCode}
                                      autoComplete="off"
                                      className="name"
                                      type="text"
                                      placeholder="Enter PinCode "
                                      onChange={(e) =>
                                        setEditPinCode(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="city">Country</label>

                                    <select
                                      value={CountryID}
                                      onChange={(e) =>
                                        setEditCountryID(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        --Please Select--
                                      </option>
                                      <option value="1">INDIA</option>
                                    </select>
                                  </div>
                                  <div className="form-group">
                                    <div>
                                      <label htmlFor="city">
                                        State<text className="m_star">*</text>
                                      </label>

                                      <select
                                        id="city"
                                        onChange={handleStateChange}
                                        required
                                      >
                                        <option value="1">
                                          --Please Select--
                                        </option>
                                        {State && State.length > 0 ? (
                                          State.map((val) => (
                                            <option
                                              key={val.state_id}
                                              value={val.state_id}
                                              selected={
                                                val.state_id === StateID
                                              }
                                            >
                                              {val.state_title}
                                            </option>
                                          ))
                                        ) : (
                                          <option value="">Loading...</option>
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label htmlFor="city">
                                      District<text className="m_star">*</text>
                                    </label>
                                    <select
                                      required
                                      id="citySelect"
                                      onChange={handleDistrictChange}
                                    >
                                      <option value="1">
                                        --Please Select--
                                      </option>

                                      {District && District.length > 0 ? (
                                        District.map((val) => (
                                          <option
                                            key={val.district_id}
                                            value={val.district_id}
                                            selected={
                                              val.district_id === CityID
                                            }
                                          >
                                            {val.district_title}
                                            {/* {district_title} */}
                                          </option>
                                        ))
                                      ) : (
                                        <option value="">Loading...</option>
                                      )}
                                    </select>
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="city">
                                      Tehsil<text className="m_star">*</text>
                                    </label>
                                    <select
                                      required
                                      id="citySelect"
                                      onChange={handleCityChange}
                                    >
                                      <option value="">
                                        --Please Select--
                                      </option>
                                      {City && City.length > 0 ? (
                                        City.map((val) => (
                                          <option
                                            key={val.city_id}
                                            value={val.city_id}
                                            // value={EditcityData}
                                            selected={val.city_id === cityData}
                                          >
                                            {val.city_title}
                                            {/* {city_title} */}
                                          </option>
                                        ))
                                      ) : (
                                        <option value="">Loading...</option>
                                      )}
                                    </select>
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="username">Village</label>
                                    <select
                                      name="cars"
                                      id="cars"
                                      onChange={(e) =>
                                        setVillageID(e.target.value)
                                      }
                                    >
                                      <option value="1">
                                        --Select Village--
                                      </option>
                                      {village && village.length ? (
                                        village.map((val, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={val.village_id}
                                            >
                                              {val.village_name}
                                            </option>
                                          );
                                        })
                                      ) : (
                                        <option value="">Loading...</option>
                                      )}
                                    </select>
                                  </div>
                                </div>
                                <div className="main_heading">
                                  <h2> Reference </h2>
                                </div>
                                <hr />
                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <div>
                                      <label htmlFor="city">Is Lead ?</label>
                                      <select
                                        value={EditselectedOption}
                                        onChange={handleOptionChange2}
                                      >
                                        <option value="1">YES</option>
                                        <option value="0">NO</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <div>
                                      <label htmlFor="city">Source</label>
                                      <select
                                        value={EditSource}
                                        onChange={handleOptionChange3}
                                      >
                                        <option value="">
                                          --Please Select--
                                        </option>
                                        <option value="Walkin">WALKIN</option>
                                        <option value="Reffered">
                                          REFFERED
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label for="uname">
                                      Referred By Client
                                    </label>
                                    <select
                                      className="name"
                                      id="city"
                                      m
                                      onChange={(e) =>
                                        handleEditReferredByClientID(
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">
                                        --Please Select--
                                      </option>
                                      {referredByClientData &&
                                      referredByClientData.length > 0
                                        ? referredByClientData.map((val) => (
                                            <option
                                              value={val.Id}
                                              selected={
                                                val.Id ===
                                                EditReferredByClientID
                                              }
                                            >
                                              {val.FirstName} {val.LastName}
                                            </option>
                                          ))
                                        : ""}
                                    </select>
                                  </div>
                                  <div className="form-group">
                                    <label for="uname">
                                      Referred By Counsel
                                    </label>
                                    <select
                                      className="name"
                                      id="city"
                                      onChange={(e) =>
                                        handleEditReferredByCounselID(
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">
                                        --Please Select--
                                      </option>
                                      {referredByCounselData &&
                                      referredByCounselData.length > 0
                                        ? referredByCounselData.map((val) => (
                                            <option
                                              value={val.Id}
                                              selected={
                                                val.Id ===
                                                EditReferredByCounselID
                                              }
                                            >
                                              {val.ContactPersonName}
                                            </option>
                                          ))
                                        : ""}
                                    </select>
                                  </div>
                                </div>

                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label for="uname">Comments</label>
                                    <textarea
                                      value={Edittext}
                                      onChange={handleTextChange1}
                                      rows={4}
                                      cols={50}
                                      placeholder="Enter your text here..."
                                    />
                                  </div>
                                </div>
                                <div className="btn_main caseMobile">
                                  <button type="submit" className="btn_save">
                                    Save
                                  </button>
                                  <button
                                    type="button"
                                    onClick={clearForm}
                                    className="btn_save btn_cancle"
                                    data-dismiss="modal"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Delete Client  */}

                    <div
                      className="modal fade sc_modal"
                      id="exampleModal"
                      tabIndex={-1}
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              type="button"
                              className="btn-close d_client"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            />
                          </div>
                          <div className="modal-body">
                            <h4>Delete Row</h4>
                            <p>Are you sure you want to delete this account?</p>
                            <ul>
                              <li>
                                <a
                                  className="btn btn-secondary"
                                  data-bs-dismiss="modal"
                                >
                                  Cancel
                                </a>
                              </li>
                              <li>
                                <a
                                  className="btn btn-danger"
                                  onClick={() => handleDeleteClient()}
                                >
                                  Delete
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* View Popup */}
                    <div
                      className="modal fade"
                      id="exampleModalLongView"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLongTitle"
                      aria-hidden="true"
                    >
                      <div
                        className="modal-dialog modal-xl"
                        role="document"
                        // style={{ maxWidth: "65%" }}
                      >
                        <div className="modal-content">
                          <div className="add_wrap">
                            <div className="main_heading">
                              <h2>View Client</h2>
                            </div>
                          </div>
                          <div className="popup_modal ">
                            <button
                              type="button"
                              className="close btn_popup acd"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>

                            <div className="dashed_popup">
                              <div className="main_heading">
                                <h2>Personal Details </h2>
                              </div>
                              <hr />
                              <div className="form_flex view_grid caseMobile">
                                <div>
                                  <h5 for="uname">Full Name :</h5>
                                  <h6>
                                    {EditFirstName == "" ? "-" : EditFirstName}
                                  </h6>
                                </div>
                                <div>
                                  <h5 for="uname">Mobile No :</h5>
                                  <h6>
                                    {EditMobileNo == "" ? "-" : EditMobileNo}
                                  </h6>
                                </div>
                                <div>
                                  <h5 for="uname">Company Name :</h5>
                                  <h6>
                                    {EditCompanyName == ""
                                      ? "-"
                                      : EditCompanyName}
                                  </h6>
                                </div>
                              </div>
                              <div className="form_flex view_grid caseMobile">
                                <div>
                                  <h5 for="uname">Designation :</h5>
                                  <h6>
                                    {EditDesignation == ""
                                      ? "-"
                                      : EditDesignation}
                                  </h6>
                                </div>
                                <div>
                                  <h5 for="uname">Son/Wife/Daughter of :</h5>
                                  <h6>
                                    {EditLastName == "" ? "-" : EditLastName}
                                  </h6>
                                </div>
                                <div>
                                  <h5 for="uname">Email :</h5>
                                  <h6>{EditEmail == "" ? "-" : EditEmail}</h6>
                                </div>
                              </div>

                              <div className="form_flex view_grid caseMobile">
                                <div>
                                  <h5 for="uname">Telephone No :</h5>
                                  <h6>{EditPhone == "" ? "-" : EditPhone}</h6>
                                </div>
                                <div>
                                  <h5 for="uname">House No :</h5>
                                  <h6>
                                    {EditHouseNo == "" ? "-" : EditHouseNo}
                                  </h6>
                                </div>
                                <div>
                                  <h5 for="uname">Address</h5>
                                  <h6>
                                    {EditAddress == "" ? "-" : EditAddress}
                                  </h6>
                                </div>
                              </div>
                              <div className="form_flex view_grid caseMobile">
                                <div>
                                  <h5 for="uname">PinCode :</h5>
                                  <h6>
                                    {EditPinCode == "" ? "-" : EditPinCode}
                                  </h6>
                                </div>
                                <div>
                                  <h5 htmlFor="city">Country :</h5>

                                  <h6>
                                    {country_title == null
                                      ? "-"
                                      : country_title}
                                  </h6>
                                </div>
                                <div>
                                  <h5 htmlFor="city">State :</h5>
                                  <h6>
                                    {state_title == null ? "-" : state_title}
                                  </h6>
                                </div>
                              </div>
                              <div className="form_flex view_grid caseMobile">
                                <div>
                                  <h5 htmlFor="city">District :</h5>
                                  <h6>
                                    {district_title == null
                                      ? "-"
                                      : district_title}
                                  </h6>
                                </div>
                                <div>
                                  <h5 htmlFor="city">Tehsil :</h5>
                                  <h6>
                                    {city_title == null ? "-" : city_title}
                                  </h6>
                                </div>
                                <div>
                                  <h5 for="uname">Referred By Client :</h5>
                                  <h6>
                                    {ReferredByClientName == null
                                      ? "-"
                                      : ReferredByClientName}
                                  </h6>
                                </div>
                              </div>
                              <div className="form_flex view_grid caseMobile">
                                <div>
                                  <h5 for="uname">Referred By Counsel :</h5>
                                  <h6>
                                    {ReferredByCounselName == null
                                      ? "-"
                                      : ReferredByCounselName}
                                  </h6>
                                </div>

                                <div>
                                  <div>
                                    <h5 htmlFor="city">Is Lead ? :</h5>
                                    <h6>{EditIsLead === "1" ? "YES" : "NO"}</h6>
                                  </div>
                                </div>
                                <div>
                                  <div>
                                    <h5 htmlFor="city">Source :</h5>
                                    <h6 className="upperCase">
                                      {EditSource === "" || EditSource === null
                                        ? "-"
                                        : EditSource}
                                    </h6>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <h5 for="uname">Comments :</h5>
                                <h6 className="comments">
                                  {Edittext == "" ? "-" : Edittext}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <Footer />
            </div>
            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        )}
      </DocumentTitle>
    </>
  );
};

export default ViewClient;
