import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import numberToWords from "number-to-words";
import "react-toastify/dist/ReactToastify.css";
import { formatDate1, userDetails } from "../services/api.service";
import DocumentTitle from "react-document-title";
import html2pdf from "html2pdf.js";
import { data } from "jquery";

function EventPdf() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [eventData, setEventData] = useState([]);
  const [eventFileNo, setEventFileNo] = useState("");

  const getById = async (id) => {
    const data = await userDetails(id, "Event/ViewEventById");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } else {
      if (data && data.result[0].message === "Success") {
        setEventData(data && data.result);
        setEventFileNo(data && data.result[0].FileNo);
      }
    }
  };

  useEffect(() => {
    getById(id);
  }, [id]);

  const formattedDate = new Date().toLocaleDateString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  
  const handlePrint = () => {
    const pdfContent = document.getElementById("pdf-content");
    const pdfOptions = {
      filename: `${eventFileNo}/Event/${formattedDate}.pdf`,
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf(pdfContent, pdfOptions);
  };

  useEffect(() => {
    handlePrint();
  }, [eventData]);

  return (
    <DocumentTitle title="PDF | LEGAL CRM">
      <div>
      {
        eventData && eventData.map((val, index) =>(
      <div className="printWrapper a4" id="pdf-content" key={index}>
        <div className="pagePrint">
        <div className="pageHeader">
              <div className="logoPrint col-md-4">
                    <img
                      src={`https://api.lawyerhunt.in/uploaded/logo/${val.CompanyLogo}`}
                      alt="LOGO"
                    />
                  </div>
                </div>
          <br />
          <div className="printHeading">
            <h3>EVENT</h3>
          </div>
        </div>

        <div className="invoiceNum">
          <div className="invoiceNumValue">
            <strong>
              Ref # :{" "}
              <span>
                {val.FileNo}
              </span>
            </strong>
          </div>
          <div className="invoiceNumValue">
            <strong>
              
              Date : <span>{formatDate1(val.Date)}</span>
            </strong>
          </div>
        </div>

        <br />

        <div className="bankDetailBox">
          <div className="leftDet">
            <h3>To,</h3>
            <div className="printNames">
              <strong>Client Name:</strong>
              <span>{val.ClientName} {val.ClientLastName}</span>
            </div>
            <div className="printNames">
              <strong>Mobile No:</strong>
              <span>{val.MobileNo}</span>
            </div>
            <div className="printNames">
              <strong>Address:</strong>
              <span>{val.ClientAddress}</span>
            </div>
            <div className="printNames">
              <strong>Company Name:</strong>
              <span>{val.CompanyName}</span>
            </div>
          </div>
        </div>


        <div className="bankDetailBox">
          <div className="leftDet">
            <h3>IN THE MATTER OF :</h3>
            <div className="printNames">
              <strong>Case No:</strong>
              <span>{val.CaseNo}</span>
            </div>
            <div className="printNames">
              <strong>Case Year:</strong>
              <span>{val.CaseYear}</span>
            </div>
            <div className="printNames">
              <strong>Case Name:</strong>
              <span>{val.CaseName}</span>
            </div>
          </div>
        </div>


        <div className="bankDetailBox">
          <div className="leftDet">
            <h3>Subject : Case Update.</h3>
            <p>{val.EventDetails}</p>
          </div>
        </div>
        <div className="sign">
          <h5>
            {val.Counsel}
          </h5>
        </div>
       
       <br/><br/>
<div
                style={{
                  display:
                    (val.CompanyFooter1 === null ||
                      val.CompanyFooter1 === "") &&
                    (val.CompanyFooter2 === null ||
                      val.CompanyFooter2 === "") &&
                    (val.CompanyFooter3 === null || val.CompanyFooter3 === "")
                      ? "none"
                      : "block",
                }}
              >
                <hr />
                <div className="footer">
                  {val.CompanyFooter1 === null || val.CompanyFooter1 === "" ? (
                    ""
                  ) : (
                    <div className="footer_invoice">
                      <h4>{val.CompanyName}</h4>
                      <p>{val.CompanyFooter1}</p>
                    </div>
                  )}

                  {val.CompanyFooter2 === null || val.CompanyFooter2 === "" ? (
                    ""
                  ) : (
                    <div className="footer_invoice">
                      <h4>{val.CompanyName}</h4>
                      <p>{val.CompanyFooter2}</p>
                    </div>
                  )}

                  {val.CompanyFooter3 === null || val.CompanyFooter3 === "" ? (
                    ""
                  ) : (
                    <div className="footer_invoice">
                      <h4>{val.CompanyName}</h4>
                      <p>{val.CompanyFooter3}</p>
                    </div>
                  )}
                </div>
              </div>
      </div>
            ))
          }
          </div>
    </DocumentTitle>

  );
}

export default EventPdf;