import React, { useState, useRef } from "react";
import autoTable from "jspdf-autotable";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  viewData,
  deleteUser,
  userDetails,
  activeStatus,
  permission,
  withUidApi,
  customStyles,
  getClient,
  getCurrentDate,
  clearForm,
  formateDateTime,
} from "../services/api.service";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";

import Preloader from "./Preloader";
import { formatDate1 } from "../services/api.service";
import DocumentTitle from "react-document-title";
import { format } from "date-fns";
import jsPDF from "jspdf";
import Footer from "./Footer";
const TaskStage = () => {
  /********************************preloader****************************************************/
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    /***********************Simulate an asynchronous operation (e.g., API call) with setTimeout****************************************/
    setTimeout(() => {
      setIsLoading(false);
      /**********************************Simulating a 1-second delay*********************************************/
    }, 500);
  }, []);
  //        -------- POPUP CLOSE VARIABLE ---------

  const closeButton = document.querySelector(".btn_popup");
  const closeView = document.querySelector(".ab");
  const closeEdit = document.querySelector(".e_popup");
  const closeDelete = document.querySelector(".mm");

  //        -------- TASK VARIABLE ---------

  const baseUrl = useContext(url);
  const navigate = useNavigate();

  const [TaskName, setTaskName] = useState("");
  const [Order, setOrder] = useState("");
  const [data, setData] = useState([]);

  //        -------- ADD TASK EDIT TASK VARIABLE ---------

  const [EditTaskName, setEditTaskName] = useState("");
  const [excel, setExcel] = useState([]);
  const [EditOrder, setEditOrder] = useState("");
  const [dTaskName, setDTaskName] = useState("");
  const [dOrder, setDOrder] = useState("");
  const [EditID, setEditID] = useState("");
  const [user, setuser] = useState([]);
  const [isAdd, setIsAdd] = useState("0");
  const [isEdit, setIsEdit] = useState("0");
  const [isView, setIsView] = useState("0");
  const [isDelete, setIsDelete] = useState("0");
  const [isStatus, setIsStatus] = useState("0");
  const [caseLeadData, setCaseLeadData] = useState([]);
  const [filterUser, setFilterUser] = useState("");
  const [delId, setDelId] = useState("");
  const [active, setActive] = useState(false);
  const formData = new FormData();
  const formEditData = new FormData();

  const [open, setOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const handleExportButtonClick = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const handleToggleColumn = (columnName) => {
    const currentIndex = selectedColumns.indexOf(columnName);
    const newSelectedColumns = [...selectedColumns];

    if (currentIndex === -1) {
      newSelectedColumns.push(columnName);
    } else {
      newSelectedColumns.splice(currentIndex, 1);
    }

    setSelectedColumns(newSelectedColumns);
  };

  //        -------- VIEW TASK DATA ---------

  const getCaseView = async () => {
    const [view, data, userData] = await Promise.all([
      withUidApi("Master/ViewTaskStage"),
      permission(18),
      getClient("Admin/ViewUser"),
    ]);

    if (view !== null) {
      setCaseLeadData(view);
    }

    if (view === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } else {
      setuser(userData);
    }

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }

    setIsAdd(data && data.result[0].IsAdd);
    setIsEdit(data && data.result[0].IsEdit);
    setIsView(data && data.result[0].IsView);
    setIsStatus(data && data.result[0].IsStatus);
    setIsDelete(data && data.result[0].IsDelete);
  };

  //        -------- DELETE TASK API ---------

  const deleteCaseLead = async () => {
    const data = await deleteUser(delId, "Master/DeleteTaskStage");
    if (data === "token") {
      setTimeout(() => {
        closeDelete.click();
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      closeDelete.click();
      getCaseView();
    }
  };

  //        -------- ACTIVE DEACTIVE ---------

  const deActive = async (row) => {
    const data = await activeStatus(row, "Master/TaskStageStatus");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      getCaseView();
    }
  };

  //        -------- TASK DETAILS DATA ---------

  const details = async (id) => {
    const data = await userDetails(id, "Master/ViewTaskStageById");
    if (data === "token") {
      setTimeout(() => {
        closeView.click();
        navigate("/");
      }, 3000);
    } else {
      setDOrder(data.result[0].Order);
      setDTaskName(data.result[0].TaskStage);
    }
  };

  //        -------- TASK DATA ---------

  const getById = async (id) => {
    const data = await userDetails(id, "Master/ViewTaskStageById");
    if (data === "token") {
      setTimeout(() => {
        closeEdit.click();
        navigate("/");
      }, 3000);
    } else {
      setEditID(data.result[0].Id);
      setEditTaskName(data.result[0].TaskStage);
      setEditOrder(data.result[0].Order);
    }
  };

  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  //        -------- SUBMIT TASK DATA ---------

  const submitHandler = async (event) => {
    event.preventDefault();
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("TaskStage", TaskName.toUpperCase());
    formData.append("Order", Order);
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    try {
      const response = await fetch(`${baseUrl}Master/AddTaskStage`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 3000);
      }
      if (data.result.length && data.result[0].code == 200) {
        toast.success("Task added successfully");
        handleClose();
        getCaseView();
        setTaskName("");
        setOrder("");

        closeButton.click();
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        -------- EDIT TASK API ---------

  const submitEditHandler = async (event) => {
    event.preventDefault();
    formEditData.append("ID", EditID);
    formEditData.append("AdminID", localStorage.getItem("id"));
    formEditData.append("TaskStage", EditTaskName.toUpperCase());
    formEditData.append("Order", EditOrder);
    formEditData.append("UpdatedBy", localStorage.getItem("UserId"));
    try {
      const response = await fetch(`${baseUrl}Master/EditTaskStage`, {
        method: "POST",
        body: formEditData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeEdit.click();
          navigate("/");
        }, 3000);
      }
      if (data.result.length && data.result[0].code == 200) {
        toast.success(" Updated successfully");
        closeEdit.click();
        getCaseView();
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        -------- TABLE ---------

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      header: "Action",
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      Cell: (row) => {
        return (
          <>
            <div style={{ display: "flex", gap: "15px" }}>
              {isEdit !== "0" && (
                <span
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalLongEdit"
                  onClick={() => getById(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/edit.svg"
                    alt="Edit"
                    title="Edit"
                  />
                </span>
              )}

              <span
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongDetail"
                onClick={() => details(row.row.original.Id)}
              >
                <img
                  src="assets/img/icons/eye.svg"
                  alt="Details"
                  title="Details"
                />
              </span>
              <span>
                <a
                  style={{
                    cursor: "pointer",
                    display: isStatus === "0" ? "none" : "block",
                  }}
                  onClick={() => deActive(row.row.original)}
                >
                  {row.row.original.Status === "1" ? (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Up.svg"
                      title="Status"
                    />
                  ) : (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Down.svg"
                      title="Status"
                    />
                  )}
                </a>
              </span>
              {isDelete !== "0" && (
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style={{ cursor: "pointer" }}
                  onClick={() => setDelId(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/delete.svg"
                    alt="Delete"
                    title="Delete"
                  />
                </span>
              )}
            </div>
          </>
        );
      },
    }),

    columnHelper.accessor("TaskStage", {
      header: "Task Stage",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.TaskStage === null ||
            row.row.original.TaskStage === ""
              ? "-"
              : row.row.original.TaskStage}
          </div>
        );
      },
    }),

    columnHelper.accessor("Order", {
      header: "Order",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.Order === null || row.row.original.Order === ""
              ? "-"
              : row.row.original.Order}
          </div>
        );
      },
    }),

    columnHelper.accessor("Status", {
      header: "Status",
      Cell: (row) => {
        return <div>{row.row.original.Status === "1" ? "ACTIVE" : "DEACTIVE"}</div>;
      },
    }),

    columnHelper.accessor("AddedBy", {
      header: "Added By",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.AddedBy === null ||
            row.row.original.AddedBy === ""
              ? "-"
              : row.row.original.AddedBy}<br/>
              {formateDateTime(row.row.original.CreatedDate)}
          </div>
        );
      },
    }),
  ];
  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  //        -------- SEARCH ---------

  const searchInput = (e) => {
    if (e.length > 0) {
      const res = caseLeadData.filter(
        (f) =>
          f.TaskStage.toLowerCase().includes(e.toLowerCase()) ||
          f.Order.toLowerCase().includes(e.toLowerCase()) ||
          f.AddedBy.toLowerCase().includes(e.toLowerCase())
      );
      setActive(true);
      setFilterUser(res);
    } else {
      setActive(false);
    }
  };
  useEffect(() => {
    viewData();
  });
  const handleKeyPress = (e) => {
    const charCode = e.which || e.keyCode;
    if (
      charCode < 48 ||
      charCode > 57 ||
      (charCode !== 8 && charCode !== 47 && e.target.value.length >= 10)
    ) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getCaseView();
    }
  }, []);

  // ---------DataTable-----

  const excelData =
    caseLeadData &&
    caseLeadData.map((obj) => {
      const { Id, code, message, Status, AdminID, ...rest } = obj;
      return rest;
    });

  const handleExportData = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });

    const formattedDate = new Date().toISOString().slice(0, 10); // Get today's date
    const filename = `TaskStage|LegalCRM_${formattedDate}.csv`;

    const csv = generateCsv(csvConfig)(excelData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const columnsToExport = [
      { id: "serialNo", header: "S No." },
      ...columns.filter((column) => selectedColumns.includes(column.id)),
    ];

    const tableHeaders = columnsToExport.map((c) => c.header);

    const formattedDate = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const filename = `TaskStage|LegalCRM_${formattedDate}.pdf`;
    const addWatermarkToFirstPage = () => {
      const watermarkHeight = 20;

      // Calculate positioning to center the watermark on each page

      doc.setFontSize(10);
      doc.text(
        10,
        watermarkHeight + -10,
        `Generated by : ${localStorage.getItem("name")} `
      );
      doc.text(172, watermarkHeight + -10, `Date: ${formattedDate}`);
      doc.setFontSize(16);
      doc.text("Task Stage Table", 79, 20);
    };
    const watermarkHeight = 20;

    addWatermarkToFirstPage();
    const tableData = rows.map((row, index) => {
      const formattedDateInPdf = row.original["Date"]
        ? format(new Date(row.original["Date"]), "dd/MM/yyyy")
        : "";

      return [
        { content: (index + 1).toString(), styles: { fontStyle: "bold" } }, // Serial number
        ...columnsToExport.slice(1).map((column) => {
          // Exclude the first column (serial number) when mapping
          if (column.id === "Date") {
            return formattedDateInPdf;
          }

          const value =
            column.id in row.original ? row.original[column.id] : "";
          return value !== null && value !== undefined ? value : "";
        }),
      ];
    });
    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY: watermarkHeight + 5,
    });

    // Add a heading to the table

    // Save the PDF to a Blob
    const pdfBlob = doc.output("blob");

    // Create a blob URL
    const blobUrl = URL.createObjectURL(pdfBlob);

    // Open the URL in a new tab
    window.open(blobUrl, "_blank");

    // Optionally revoke the object URL after opening the new tab
    URL.revokeObjectURL(blobUrl);
    // doc.save(filename);
  };

  const table = useMaterialReactTable({
    columns,
    data: caseLeadData,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    initialState: {
      columnVisibility: {
        Status: false,
        AddedBy: false,
        UpdatedBy: false,
        UpdatedDate: false,
        CreatedDate: false,
      },
    },
    enableStickyHeader: true,
    enableRowNumbers: true,
    rowNumberDisplayMode: "original",
    muiPaginationProps: {
      rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
 disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export Excel
        </Button>

        <Button
        disabled={!table || table.getPrePaginationRowModel().rows.length === 0}
        onClick={handleExportButtonClick}
        startIcon={<FileDownloadIcon />}
      >
        Export PDF
      </Button>
      <Dialog open={open} onClose={handleClose1}>
        <DialogTitle>Select Columns To Export</DialogTitle>
        <DialogContent>
            {columns.map((column) =>
              column.header !== "Action" ? (
                <div key={column.id}>
                  <Checkbox
                    checked={selectedColumns.includes(column.id)}
                    onChange={() => handleToggleColumn(column.id)}
                  />
                  {column.header}
                </div>
              ) : (
                ""
              )
            )}
          </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1}>Cancel</Button>
          <Button onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}>Export</Button>
        </DialogActions>
      </Dialog>
      </Box>
    ),
  });

  return (
    <>
      <DocumentTitle title="TASK STAGE | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  {/* <div className="accountContainer userListContainer"> */}
                  <div className="pageContent">
                    <div className="PageTitle">
                      <h3>Task Stage List</h3>

                      <a
                        style={{
                          display: isAdd === "0" ? "none" : "block",
                        }}
                        type="button"
                        className="btn btn-primary btn_client headbtn"
                        data-toggle="modal"
                        data-target="#exampleModalLong"
                      >
                        Add Task Stage
                        <img src="assets/img/icons/add.svg" />
                      </a>
                    </div>

                    <div
                      style={{
                        display: isView === "0" ? "none" : "block",
                      }}
                      className="searDesign"
                    >
                      <MaterialReactTable table={table} />
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <Footer/>
            </div>

            {/*-------- DELETE TASK FORM -------*/}

            <div
              className="modal fade sc_modal"
              id="exampleModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close mm"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <h4>Delete Row</h4>
                    <p>Are you sure you want to delete Task ?</p>
                    <ul>
                      <li>
                        <a
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </a>
                      </li>
                      <li>
                        <a
                          className="btn btn-danger"
                          onClick={() => deleteCaseLead(delId)}
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- ADD TASK FORM ---------*/}

            <div
              className="modal fade"
              id="exampleModalLong"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
              show={show}
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Add Task Stage</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Task Stage <text className="m_star">*</text>
                            </label>
                            <input
                              className="name"
                              type="text"
                              placeholder="Enter Task Stage"
                              name="uname"
                              value={TaskName}
                              onChange={(e) => setTaskName(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">Order </label>
                            <input
                              className="name"
                              onKeyPress={handleKeyPress}
                              type="text"
                              name="uname"
                              placeholder="Enter Order"
                              value={Order}
                              onChange={(e) => setOrder(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_save btn_cancle"
                            onClick={clearForm}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- EDIT TASK FORM ---------*/}

            <div
              className="modal fade"
              id="exampleModalLongEdit"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Edit Task Stage</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close e_popup btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitEditHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Task Stage <text className="m_star">*</text>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Task Stage"
                              name="uname"
                              value={EditTaskName}
                              onChange={(e) => setEditTaskName(e.target.value)}
                              required
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label htmlFor="uname">Order </label>
                          <input
                            type="text"
                            name="uname"
                            onKeyPress={handleKeyPress}
                            placeholder="Enter Order"
                            value={EditOrder}
                            onChange={(e) => setEditOrder(e.target.value)}
                          />
                        </div>

                        <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_save btn_cancle"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- DETAILS ---------*/}

            <div
              className="modal fade"
              id="exampleModalLongDetail"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Task Stage Details</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close ab btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Task Stage &nbsp;: &nbsp;{" "}
                            {dTaskName == null ? "-" : dTaskName}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Order &nbsp;:&nbsp; {dOrder == null ? "-" : dOrder}{" "}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- TOASTER ---------*/}

            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        )}
      </DocumentTitle>
    </>
  );
};

export default TaskStage;
