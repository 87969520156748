import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { url } from "../index";
import { useNavigate } from "react-router";
import DocumentTitle from "react-document-title";
import { permission, formatDate1, clearForm } from "../services/api.service";
import Footer from "./Footer";

const Profile = () => {
  //        -------- POPUP CLOSE VARIABLE ---------
  const closeButton = document.querySelector(".btn_popup");

  const baseUrl = useContext(url);
  const navigate = useNavigate();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showPassword, setShowPassword] = useState("password,");
  const [showPassword1, setShowPassword1] = useState("password1,");
  const [showPassword2, setShowPassword2] = useState("password2,");
  const Passform = new FormData();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    Passform.append("AdminID", localStorage.getItem("id"));
    Passform.append("ID", localStorage.getItem("uId"));
    Passform.append("OldPassword", oldPassword);
    Passform.append("NewPassword", newPassword);

    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    const access_token = localStorage.getItem("access_token");

    try {
      const response = await fetch(
        `${baseUrl}Admin/ChangePasswordUser`,
        {
          method: "POST",
          headers: {
            Authorization: `${access_token}`,
            ContentType: "multipart/form-data",
          },
          body: Passform,
        }
      );

      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      if (data.result[0].code == 200) {
        closeButton.click();
        clearForm();
        toast.success("Password changed successfully");
      } else {
        toast.error("Password change failed");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    }
  };
  //        -------- VARIABLE ---------

  const [companyPer, setCompanyPer] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyUrl, setCompanyUrl] = useState("");
  const [gst, setGst] = useState("");
  const [dCompanyCode, setDCompanyCode] = useState("");
  const [dActivateFrom, setDActivateFrom] = useState("");
  const [dActivateTo, setDActivateTo] = useState("");
  const [dCompanyPer, setDCompanyPer] = useState("");
  const [dCompanyName, setDCompanyName] = useState("");
  const [dUserName, setDUserName] = useState("");
  const [dRoleName, setDRoleName] = useState("");
  const [dMobileNo, setDMobileNo] = useState("");
  const [dReceiptPrefix, setDReceiptPrefix] = useState("");
  const [dInvoicePrefix, setDInvoicePrefix] = useState("");
  const [dAltContact, setDAltContact] = useState("");
  const [isView, setIsView] = useState("0");
  const formEditData = new FormData();
  const configForm = new FormData();

  // -----------CONFIG API ---------

  const configApi = async () => {
    configForm.append("AdminID", localStorage.getItem("id"));
    configForm.append("ID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}Admin/ViewUserById`, {
        method: "POST",
        body: configForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }

      if (data && data.result[0].message === "Success") {
        setDReceiptPrefix(data && data.result[0].ReceiptPrefix);
        setDInvoicePrefix(data && data.result[0].InvoicePrefix);
        setDAltContact(data && data.result[0].AlternateContact);
        setDUserName(data && data.result[0].UserName);
        setDRoleName(data && data.result[0].RoleName);
        setDMobileNo(data && data.result[0].MobileNo);
        setDCompanyPer(data && data.result[0].CompanyPer);
        setDActivateFrom(data && data.result[0].ActivateFrom);
        setDActivateTo(data && data.result[0].ActivateTo);
        setDCompanyName(data && data.result[0].CompanyName);
        // setDCompanyCode(data && data.result[0].CompanyCode);
        setGst(data && data.result[0].GST);
      } else {
        toast.error(data && data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const permissionData = async () => {
    const data = await permission(23);
    setIsView(data && data.result[0].IsView);
  };

  const handleLogoChange = (event) => {
    const selectedFile = event.target.value;
    alert(selectedFile);
    setCompanyLogo(URL.createObjectURL(selectedFile));
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      configApi();
      permissionData();
    }
  }, []);

  return (
    <>
      <DocumentTitle title="PROFILE | LEGAL CRM">
        <div className="wraper">
          <Header />
          <div className="mainWraper">
            <Sidebar />
            <div className="pageBody">
              <div className="pageContent">
                <div className="PageTitle">
                  <h3>Profile Setting</h3>
                  <button
                    className="btn btn-primary btn_client headbtn"
                    data-target="#exampleModalLongpass"
                    type="button"
                    data-toggle="modal"
                  >
                    Change Password
                  </button>
                </div>

                <div
                  // className="popup_modal"
                  style={{ display: isView === "0" ? "none" : "block" }}
                >
                  <div className="dashed_popup">
                    <div className="form_flex">
                      <div className="form-group">
                        <h4>Company Code &nbsp;- &nbsp; {localStorage.getItem("CompanyCode")}</h4>
                      </div>
                      <div className="form-group">
                        <h4>User Name &nbsp;- &nbsp; {dUserName}</h4>
                      </div>

                      <div className="form-group">
                        <h4>Contact &nbsp;- &nbsp; {dMobileNo}</h4>
                      </div>
                    </div>

                    <div className="form_flex">
                      <div className="form-group">
                        <h4>Company Name &nbsp;- &nbsp; {dCompanyName}</h4>
                      </div>

                      <div className="form-group">
                        <h4>Role &nbsp;- &nbsp; {dRoleName}</h4>
                      </div>
                      <div className="form-group">
                        <h4>Gst &nbsp;- &nbsp; {gst}</h4>
                      </div>
                    </div>

                    <div className="form_flex">
                      <div className="form-group">
                        <h4>
                          Company Percentage &nbsp;- &nbsp; {dCompanyPer} %
                        </h4>
                      </div>
                      <div className="form-group">
                        <h4>
                          Activate From &nbsp;- &nbsp;{" "}
                          {formatDate1(dActivateFrom)}
                        </h4>
                      </div>

                      <div className="form-group">
                        <h4>
                          Activate To &nbsp;- &nbsp; {formatDate1(dActivateTo)}
                        </h4>
                      </div>
                    </div>

                    <div className="form_flex">
                      <div className="form-group">
                        <h4>
                          Alternate Mobile No. &nbsp;- &nbsp; {dAltContact}
                        </h4>
                      </div>

                      <div className="form-group">
                        <h4>Receipt Prefix &nbsp;- &nbsp; {dReceiptPrefix}</h4>
                      </div>
                      <div className="form-group">
                        <h4>Invoice Prefix &nbsp;- &nbsp; {dInvoicePrefix}</h4>
                      </div>
                    </div>

                    <div
                      className="modal fade"
                      id="exampleModalLongpass"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLongTitle"
                      aria-hidden="true"
                    >
                      <div
                        className="modal-dialog modal-sm"
                        role="document"
                        // style={{ maxWidth: "35%" }}
                      >
                        <div className="modal-content">
                          <div className="popup_modal ">
                            <button
                              type="button"
                              className="close btn_popup"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                            {/*****************************************Change password***************************/}

                            <div className="main_heading">
                              <h2>Change Password</h2>
                            </div>
                            <div className="dashed_popup">
                              <form role="form " onSubmit={handleSubmit}>
                                <div className="form_flex">
                                  <div className="form-group">
                                    <label for="uname">Old Password </label>
                                    <input
                                      autoComplete="off"
                                      className="name"
                                      id="#password"
                                      type={showPassword ? "password" : "text"}
                                      placeholder="Enter Password"
                                      onChange={(e) =>
                                        setOldPassword(e.target.value)
                                      }
                                      required
                                    />
                                    <FontAwesomeIcon
                                      icon={showPassword ? faEyeSlash : faEye}
                                      onClick={togglePasswordVisibility}
                                      className="password-icon"
                                    />
                                  </div>
                                </div>
                                <div className="form_flex">
                                  <div className="form-group">
                                    <label for="uname">New Password </label>
                                    <input
                                      autoComplete="off"
                                      className="name"
                                      id="#password1"
                                      type={showPassword1 ? "password" : "text"}
                                      placeholder="Enter Password"
                                      onChange={(e) =>
                                        setNewPassword(e.target.value)
                                      }
                                      required
                                    />
                                    <FontAwesomeIcon
                                      icon={showPassword1 ? faEyeSlash : faEye}
                                      onClick={togglePasswordVisibility1}
                                      className="password-icon"
                                    />
                                  </div>
                                </div>
                                <div className="form_flex">
                                  <div className="form-group">
                                    <label for="uname">Confirm Password </label>
                                    <input
                                      autoComplete="off"
                                      className="name"
                                      id="#password2"
                                      type={showPassword2 ? "password" : "text"}
                                      placeholder="Enter Password"
                                      onChange={(e) =>
                                        setConfirmPassword(e.target.value)
                                      }
                                      required
                                    />
                                    <FontAwesomeIcon
                                      icon={showPassword2 ? faEyeSlash : faEye}
                                      onClick={togglePasswordVisibility2}
                                      className="password-icon"
                                    />
                                  </div>
                                </div>

                                <div className="btn_main">
                                  <button type="submit" className="btn_save">
                                    Save
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
          <Footer/>
        </div>
      </DocumentTitle>
      {/*-------- TOASTER ---------*/}

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Profile;
