import React, { useEffect, useState, useRef, useContext } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import Select from "react-select";
import { Line } from "react-chartjs-2";
import { url } from "../index";
import {
  viewData,
  getClient,
  permission,
  formatDate1,
  formateDateTime,
} from "../services/api.service";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Preloader from "./Preloader";
import DocumentTitle from "react-document-title";
import Footer from "./Footer";
import Chart from "chart.js/auto";
import CanvasJSReact from '@canvasjs/react-charts';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Hometest = (props) => {
  const [invoicegrap, setInvoicegrap] = useState([]);
  const [invoicegrapPrivious, setInvoicegrapPrivious] = useState([]);
  const [receiptGraph, setReceiptGraph] = useState([]);
  const [receiptGraphPrivious, setReceiptGraphPrivious] = useState([]);

  const invoicegrap1 = invoicegrap && invoicegrap.map(obj => ({
    ...obj,
    y: parseFloat(obj.y)
}));

const invoicegrapPrivious2 = invoicegrapPrivious && invoicegrapPrivious.map(obj =>({
  ...obj,
  y: parseFloat(obj.y)
}));

const receiptGraph1 = receiptGraph && receiptGraph.map(obj => ({
  ...obj,
  y: parseFloat(obj.y)
}));

const receiptGraphPrivious2 = receiptGraphPrivious && receiptGraphPrivious.map(obj =>({
...obj,
y: parseFloat(obj.y)
}));

const cyear = invoicegrap && invoicegrap[0]?.year
const pyear = invoicegrapPrivious && invoicegrapPrivious[0]?.year

const r_cyear = receiptGraph && receiptGraph[0]?.year
const r_pyear = receiptGraphPrivious && receiptGraphPrivious[0]?.year

const chartDataInvoice = {
  labels: invoicegrap1.map(data => data.label),
  datasets: [
    {
      label: cyear,
      data: invoicegrap1.map(data => data.y),
      borderColor: "	rgba(0, 77, 0, 1)",
      backgroundColor: "rgba(0, 77, 0, 0.2)",
      fill: true,
    },
    {
      label: pyear,
      data: invoicegrapPrivious2.map(data => data.y),
      borderColor: "rgba(255, 204, 255, 1)",
      backgroundColor: "rgba(255, 204, 255, 0.2)",
      fill: true,
    },
  ],
};

const chartDataReceipt = {
  labels: receiptGraph1.map(data => data.label),
  datasets: [
    {
      label: r_cyear,
      data: receiptGraph1.map(data => data.y),
      borderColor: "	rgba(0, 77, 0, 1)",
      backgroundColor: "rgba(0, 77, 0, 0.2)",
      fill: true,
    },
    {
      label: r_pyear,
      data: receiptGraphPrivious2.map(data => data.y),
      borderColor: "rgba(255, 204, 255, 1)",
      backgroundColor: "rgba(255, 204, 255, 0.2)",
      fill: true,
    },
  ],
};

const redirectToInvoice = (event, elements) => {
  if (elements.length > 0) {
    elements.forEach((element) => {
      const datasetIndex = element.datasetIndex;
      const dataset = chartDataInvoice.datasets[datasetIndex];
      const clickedElementIndex = element.index;
      const clickedLabel = chartDataInvoice.labels[clickedElementIndex];
      const url = `/#/view-invoice-counsel/${clickedLabel}_${dataset.label}`;
      window.open(url, "_blank");
    });
  }
};

const redirectToReceipt = (event, elements) => {
  if (elements.length > 0) {
    elements.forEach((element) => {
      const datasetIndex = element.datasetIndex;
      const dataset = chartDataReceipt.datasets[datasetIndex];
      const clickedElementIndex = element.index;
      const clickedLabel = chartDataReceipt.labels[clickedElementIndex];
      const url = `/#/view-receipt-counsel/${clickedLabel}_${dataset.label}`;
      window.open(url, "_blank");
    });
  }
};

  const chartRef = useRef(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const baseUrl = useContext(url);
  const [caseLeadData, setCaseLeadData] = useState([]);
  const [isAdd, setIsAdd] = useState("0");
  const [isEdit, setIsEdit] = useState("0");
  const [isView, setIsView] = useState("0");
  const [isDelete, setIsDelete] = useState("0");
  const [isStatus, setIsStatus] = useState("0");
  const [caseCountData, setCaseCountData] = useState([]);
  const [reportAmount, setReportAmount] = useState([]);
  const [fCaseToDate, setFCaseToDate] = useState("");
  const [fCaseFromDate, setFCaseFromDate] = useState("");
  const currentDate = new Date().toISOString().split("T")[0];
  const [fFilingDate, setFFilingDate] = useState("");
  const [isFieldRequired, setIsFieldRequired] = useState(false);
  const [borderLeftCaseFlow, setBorderLeftCaseFlow] = useState(false);
  const [borderLeftCases, setBorderLeftCases] = useState(false);

  const [fReceiptClient, setFReceiptClient] = useState("");
  const [fReceiptCaseId, setFReceiptCaseId] = useState("");
  const [fReceiptFromDate, setFReceiptFromDate] = useState("");
  const [fReceiptToDate, setFReceiptToDate] = useState("");

  const [fTaskFromDate, setFTaskFromDate] = useState("");
  const [fTaskToDate, setFTaskToDate] = useState("");
  const [fTaskClient, setFTaskClient] = useState("");
  const [fTaskCaseId, setFTaskCaseId] = useState("");

  const [fNoteClient, setFNoteClient] = useState("");
  const [fNoteCaseId, setFNoteCaseId] = useState("");
  const [fNoteToDate, setFNoteToDate] = useState("");
  const [fNoteFromDate, setFNoteFromDate] = useState("");

  const [fCasesFromDate, setFCasesFromDate] = useState("");
  const [fCasesToDate, setFCasesToDate] = useState("");
  const [fCasesPeriod, setFCasesPeriod] = useState("1");
  const [fCasesGroupBy, setFCasesGroupBy] = useState("");

  const [fCaseFlowFromDate, setFCaseFlowFromDate] = useState("");
  const [fCaseFlowToDate, setFCaseFlowToDate] = useState("");
  const [period, setPeriod] = useState("1");
  const [groupBy, setGroupBy] = useState("");

  const [fEarningReportGroupBy, setFEarningReportGroupBy] = useState("");
  const [fEarningReportFromDate, setFEarningReportFromDate] = useState("");
  const [fEarningReportPeriod, setFEarningReportPeriod] = useState("1");
  const [fEarningReportToDate, setFEarningReportToDate] = useState("");

  const [fExpensesFromDate, setFExpensesFromDate] = useState("");
  const [fExpensesToDate, setFExpensesToDate] = useState("");
  const [fExpenseCaseID, setFExpenseCaseID] = useState("");
  const [fExpenseClientID, setFExpenseClientID] = useState("");

  const [fInvoiceFromDate, setFInvoiceFromDate] = useState("");
  const [fInvoiceToDate, setFInvoiceToDate] = useState("");
  const [fInvoiceClient, setFInvoiceClient] = useState("");

  const [fAppointmentFromDate, setFAppointmentFromDate] = useState("");
  const [fAppointmentToDate, setFAppointmentToDate] = useState("");
  const [fAppointCase, setFAppointCase] = useState("");
  const [fAppointClient, setFAppointClient] = useState("");

  const [fEventFromDate, setFEventFromDate] = useState("");
  const [fEventCase, setFEventCase] = useState("");
  const [fEventClient, setFEventClient] = useState("");
  const [fEventToDate, setFEventToDate] = useState("");
  const [fEventIsOpen, setFEventIsOpen] = useState("");
  
  const handleSelectSlot = (slotInfo) => {
    setSelectedEvent({
      title: "New Event",
      start: slotInfo.start,
      end: slotInfo.end,
      backgroundColor: "#7a0000",
    });
  };

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    }

    if (localStorage.getItem("login") == "success") {
      toast.success("Successfully User Login");
      localStorage.removeItem("login");
    }
  }, []);

  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  const [eventDetails, setEventDetails] = useState("");
  const [isEffective, setIsEffective] = useState("");
  const [isUpcoming, setIsUpcoming] = useState("");
  const [nextDate, setNextDate] = useState("");
  const [caseID, setCaseID] = useState("");
  const [date, setDate] = useState(currentDate);
  const [HeaderF, setHeader] = useState("");
  const [CaseF, setCaseF] = useState([]);
  const [Appointment, setAppointment] = useState([]);
  const [Event, setEvent] = useState([]);
  const [Task, setTask] = useState([]);
  const [caseFlow, setCaseFlow] = useState([]);
  const [Notes, setNotes] = useState([]);
  const [Expense, setExpense] = useState([]);
  const [Invoice, setInvoice] = useState([]);
  const [Receipt, setReceipt] = useState([]);
  const [casesData, setCasesData] = useState([]);
  const formData = new FormData();
  const closeButton = document.querySelector(".btn_popup");

  //-------------Filter Variable--------------------------

  const [fCaseId, setFCaseId] = useState("");
  const [fInvoiceCaseId, setFInvoiceCaseId] = useState("");
  const [fFromDate, setFFromDate] = useState("");
  const filterform = new FormData();
  const [fToDate, setFToDate] = useState("");
  const [fClientID, setFClientID] = useState("");
  const [fClientOption, setFClientOption] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const [receiptFilter, setReceiptFilter] = useState(false);
  const [taskFilter, setTaskFilter] = useState(false);
  const [eventFilter, setEventFilter] = useState(false);
  const [noteFilter, setNoteFilter] = useState(false);
  const [appointmentFilter, setAppointmentFilter] = useState(false);
  const [invoiceFilter, setInvoiceFilter] = useState(false);
  const [expensesFilter, setExpensesFilter] = useState(false);
  const [caseFilter, setCaseFilter] = useState(false);
  const [caseFlowFilter, setCaseFlowFilter] = useState(false);
  const [earningReportFilter, setEarningReportFilter] = useState(false);
  const [casesFilter, setCasesFilter] = useState(false);
  const [fCaseIsOpen, setFCaseIsOpen] = useState("");

  //---------- ADD EVENT ----------

  const submitHandler = async (event) => {
    event.preventDefault();
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    formData.append("Date", date);
    formData.append("EventDetails", eventDetails.toUpperCase());
    formData.append("IsEffective", isEffective);
    formData.append("IsUpcoming", isUpcoming);
    formData.append("NextDate", nextDate);
    formData.append("CaseID", caseID);
    try {
      const response = await fetch(`${baseUrl}Event/AddEvent`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].code == 200) {
        toast.success(" added successfully");
        closeButton.click();
        handleClose();
        getEventView();
        getCountries();
        setIsEffective("");
        setCaseID("");
        setDate("");
        setNextDate("");
        setEventDetails("");
        setIsUpcoming("");
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleReceiptFilterCase = (selectedOption) => {
    if (selectedOption === null) {
      setFReceiptCaseId("");
    } else {
      setFReceiptCaseId(selectedOption.value);
    }
  };

  const handleReceiptFilterClient = (selectedOption) => {
    if (selectedOption === null) {
      setFReceiptClient("");
    } else {
      setFReceiptClient(selectedOption.value);
    }
  };

  const handleInvoiceFilterClient = (selectedOption) => {
    if (selectedOption === null) {
      setFInvoiceClient("");
    } else {
      setFInvoiceClient(selectedOption.value);
    }
  };

  const handleFilingDate = (e) => {
    setFFilingDate(e.target.value);
    if (e.target.value !== "") {
      setIsFieldRequired(true);
    } else {
      setIsFieldRequired(false);
    }
  };

  // ---------- FILTER API ---------

  const filterCaseCancel = () => {
    setFCaseId("");
    setFCaseIsOpen("");
    setFFilingDate("");
    setFCaseFromDate("");
    setFCaseToDate("");
    getEventView();
    setCaseFilter(false);
  };

  const handleFilterCase = (selectedOption) => {
    if (selectedOption === null) {
      setFCaseId("");
    } else {
      setFCaseId(selectedOption.value);
    }
  };

  const handleFilterInvoiceCaseId = (selectedOption) => {
    if (selectedOption === null) {
      setFInvoiceCaseId("");
    } else {
      setFInvoiceCaseId(selectedOption.value);
    }
  };
  const handleFilterTaskCaseId = (selectedOption) => {
    if (selectedOption === null) {
      setFTaskCaseId("");
    } else {
      setFTaskCaseId(selectedOption.value);
    }
  };
  const handleFilterTaskClient = (selectedOption) => {
    if (selectedOption === null) {
      setFTaskClient("");
    } else {
      setFTaskClient(selectedOption.value);
    }
  };
  const handleFilterClient = (selectedOption) => {
    if (selectedOption === null) {
      setFClientID("");
    } else {
      setFClientID(selectedOption.value);
    }
  };
  const handleFilterEventClient = (selectedOption) => {
    if (selectedOption === null) {
      setFEventClient("");
    } else {
      setFEventClient(selectedOption.value);
    }
  };
  const handleFilterEventCaseId = (selectedOption) => {
    if (selectedOption === null) {
      setFEventCase("");
    } else {
      setFEventCase(selectedOption.value);
    }
  };
  const handleFilterNoteClient = (selectedOption) => {
    if (selectedOption === null) {
      setFNoteClient("");
    } else {
      setFNoteClient(selectedOption.value);
    }
  };
  const handleFilterNoteCaseId = (selectedOption) => {
    if (selectedOption === null) {
      setFNoteCaseId("");
    } else {
      setFNoteCaseId(selectedOption.value);
    }
  };

  const handleFilterExpenseCaseId = (selectedOption) => {
    if (selectedOption === null) {
      setFExpenseCaseID("");
    } else {
      setFExpenseCaseID(selectedOption.value);
    }
  };
  const handleFilterExpenseClient = (selectedOption) => {
    if (selectedOption === null) {
      setFExpenseClientID("");
    } else {
      setFExpenseClientID(selectedOption.value);
    }
  };

  const handleFilterAppointCaseId = (selectedOption) => {
    if (selectedOption === null) {
      setFAppointCase("");
    } else {
      setFAppointCase(selectedOption.value);
    }
  };
  const handleFilterAppointClient = (selectedOption) => {
    if (selectedOption === null) {
      setFAppointClient("");
    } else {
      setFAppointClient(selectedOption.value);
    }
  };

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);

    if (isNaN(date)) {
      return "";
    }

    const day = date.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      date
    );
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  /** Get File Name  */
  const Case = new FormData();
  const [optionsEvent, setOptionsEvent] = useState([]);
  const getFileNo = async () => {
    Case.append("AdminID", localStorage.getItem("id"));
    Case.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: Case,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }

      if (data && data.result[0].message === "Success") {
        const Mapped = data.result.map((item) => ({
          value: item.CaseID,
          label: `${item.FileNo} / ${item.CaseNo} / ${item.CaseName} / ${item.CaseYear}`,
        }));
        setOptionsEvent(Mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setCaseID(null);
    } else {
      setCaseID(selectedOption.value);
    }
  };

  //---------- VIEW EVENT DATA ---------

  function formatCustomDate(customDate) {
    return moment(customDate, "YYYY-MM-DD").format("MM/DD/YYYY");
  }
  const getEventView = async () => {
    const [view, data, cdata] = await Promise.all([
      viewData("Event/ViewEvent"),
      permission(6),
      getClient("Client/ViewClient"),
      getCountries(),
    ]);

    if (view === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } else {
      if (view === null) {
        setCaseLeadData([]);
      } else {
        setCaseLeadData(view);
      }
    }

    const clientData = cdata && cdata.filter((item) => item.Status === "1");

    const Mapped =
      clientData &&
      clientData.map((item) => ({
        value: item.Id,
        label: item.FirstName,
      }));
    setFClientOption(Mapped);

    setIsAdd(data && data.result[0].IsAdd);
    setIsEdit(data && data.result[0].IsEdit);
    setIsView(data && data.result[0].IsView);
    setIsStatus(data && data.result[0].IsStatus);
    setIsDelete(data && data.result[0].IsDelete);
  };

  function formatTime(inputDate) {
    const date = new Date(inputDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  // ---------- FILTER API --------- MANISH PRAJAPAT DATE 21 - 11 - 2023 ---------

  const filterCaseApi = async (event) => {
    event.preventDefault();
    filterform.append("AdminID", localStorage.getItem("id"));
    filterform.append("UserID", localStorage.getItem("uId"));
    filterform.append("CaseID", fCaseId);
    filterform.append("ClientID", fClientID);
    filterform.append("From", fCaseFromDate);
    filterform.append("To", fCaseToDate);
    filterform.append("IsOpen", fCaseIsOpen);
    filterform.append("Dates", fFilingDate);

    try {
      const response = await fetch(`${baseUrl}Cases/ViewCasefilter`, {
        method: "POST",
        body: filterform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }

      if (
        data &&
        data.result.length &&
        data &&
        data.result[0].message === "Success"
      ) {
        setCaseF(data.result);
      } else {
        setCaseF([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterReceiptApi = async () => {
    filterform.append("AdminID", localStorage.getItem("id"));
    filterform.append("UserID", localStorage.getItem("uId"));
    filterform.append("CaseID", fReceiptCaseId);
    filterform.append("ClientID", fReceiptClient);
    filterform.append("From", fReceiptFromDate);
    filterform.append("To", fReceiptToDate);
    try {
      const response = await fetch(`${baseUrl}Receipt/ViewReceiptfilter`, {
        method: "POST",
        body: filterform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 2000);
      }

      if (
        data &&
        data.result.length &&
        data &&
        data.result[0].message === "Success"
      ) {
        setReceipt(data.result);
      } else {
        setReceipt([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterTaskApi = async () => {
    filterform.append("AdminID", localStorage.getItem("id"));
    filterform.append("UserID", localStorage.getItem("uId"));
    filterform.append("CaseID", fTaskCaseId);
    filterform.append("ClientID", fTaskClient);
    filterform.append("From", fTaskFromDate);
    filterform.append("To", fTaskToDate);
    try {
      const response = await fetch(`${baseUrl}Task/ViewTaskfilter`, {
        method: "POST",
        body: filterform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }

      if (
        data &&
        data.result.length &&
        data &&
        data.result[0].message === "Success"
      ) {
        setTask(data.result);
      } else {
        setTask([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterEventApi = async () => {
    filterform.append("AdminID", localStorage.getItem("id"));
    filterform.append("UserID", localStorage.getItem("uId"));
    filterform.append("CaseID", fEventCase);
    filterform.append("ClientID", fEventClient);
    filterform.append("From", fEventFromDate);
    filterform.append("To", fEventToDate);
    filterform.append("IsOpen", fEventIsOpen);
    try {
      const response = await fetch(`${baseUrl}Event/ViewEventfilter`, {
        method: "POST",
        body: filterform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 2000);
      }

      if (
        data &&
        data.result.length &&
        data &&
        data.result[0].message === "Success"
      ) {
        setEvent(data.result);
      } else {
        setEvent([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterCasesApi = async () => {
    filterform.append("AdminID", localStorage.getItem("id"));
    filterform.append("UserID", localStorage.getItem("uId"));
    filterform.append("Period", fCasesPeriod);
    filterform.append("GroupBy", fCasesGroupBy);
    filterform.append("From", fCasesFromDate);
    filterform.append("To", fCasesToDate);
    try {
      const response = await fetch(`${baseUrl}Cases/TotalOpenCloseCases`, {
        method: "POST",
        body: filterform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 2000);
      }

      if (fCasesGroupBy === "1") {
        setBorderLeftCases(true);
        document.getElementById("dynamicColumnCases").innerHTML = "City";
      } else {
        if (fCasesGroupBy === "2") {
          setBorderLeftCases(true);
          document.getElementById("dynamicColumnCases").innerHTML = "Incharge";
        } else {
          if (fCasesGroupBy === "3") {
            setBorderLeftCases(true);
            document.getElementById("dynamicColumnCases").innerHTML =
              "Co-counsel";
          } else {
            if (fCasesGroupBy === "4") {
              setBorderLeftCases(true);
              document.getElementById("dynamicColumnCases").innerHTML =
                "Case-Type";
            } else {
              setBorderLeftCases(false);
              document.getElementById("dynamicColumnCases").innerHTML = "";
              document.getElementById("dynamicCases").innerHTML = "";
            }
          }
        }
      }

      if (data && data.result[0].CaseDetailsFlow.length) {
        setCasesData(data.result[0].CaseDetailsFlow);
      } else {
        setCasesData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterCaseFlowApi = async () => {
    filterform.append("AdminID", localStorage.getItem("id"));
    filterform.append("UserID", localStorage.getItem("uId"));
    filterform.append("Period", period);
    filterform.append("GroupBy", groupBy);
    filterform.append("From", fCaseFlowFromDate);
    filterform.append("To", fCaseFlowToDate);
    try {
      const response = await fetch(`${baseUrl}Cases/CaseFlowFillter`, {
        method: "POST",
        body: filterform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 2000);
      }

      if (groupBy === "1") {
        setBorderLeftCaseFlow(true);
        document.getElementById("dynamicColumn").innerHTML = "City";
      } else {
        setBorderLeftCaseFlow(true);
        if (groupBy === "2") {
          document.getElementById("dynamicColumn").innerHTML = "Incharge";
        } else {
          if (groupBy === "3") {
            setBorderLeftCaseFlow(true);
            document.getElementById("dynamicColumn").innerHTML = "Co-counsel";
          } else {
            if (groupBy === "4") {
              setBorderLeftCaseFlow(true);
              document.getElementById("dynamicColumn").innerHTML = "Case-Type";
            } else {
              setBorderLeftCaseFlow(false);
              document.getElementById("dynamicColumn").innerHTML = "";
              document.getElementById("dynamic").innerHTML = "";
            }
          }
        }
      }

      if (data && data.result.length) {
        setCaseFlow(data.result[0]);
      } else {
        setCaseFlow([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterNotetApi = async () => {
    filterform.append("AdminID", localStorage.getItem("id"));
    filterform.append("UserID", localStorage.getItem("uId"));
    filterform.append("CaseID", fNoteCaseId);
    filterform.append("ClientID", fNoteClient);
    filterform.append("From", fNoteFromDate);
    filterform.append("To", fNoteToDate);
    try {
      const response = await fetch(`${baseUrl}Notes/ViewNotesfilter`, {
        method: "POST",
        body: filterform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 2000);
      }

      if (
        data &&
        data.result.length &&
        data &&
        data.result[0].message === "Success"
      ) {
        setNotes(data.result);
      } else {
        setNotes([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterAppointmentApi = async () => {
    filterform.append("AdminID", localStorage.getItem("id"));
    filterform.append("UserID", localStorage.getItem("uId"));
    filterform.append("CaseID", fAppointCase);
    filterform.append("ClientID", fAppointClient);
    filterform.append("From", fAppointmentFromDate);
    filterform.append("To", fAppointmentToDate);
    try {
      const response = await fetch(
        `${baseUrl}Appoinment/ViewAppointmentfilter`,
        {
          method: "POST",
          body: filterform,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        }
      );
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 2000);
      }

      if (
        data &&
        data.result.length &&
        data &&
        data.result[0].message === "Success"
      ) {
        setAppointment(data.result);
      } else {
        setAppointment([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterInvoiceApi = async () => {
    filterform.append("AdminID", localStorage.getItem("id"));
    filterform.append("UserID", localStorage.getItem("uId"));
    filterform.append("CaseID", fInvoiceCaseId);
    filterform.append("ClientID", fInvoiceClient);
    filterform.append("From", fInvoiceFromDate);
    filterform.append("To", fInvoiceToDate);
    try {
      const response = await fetch(`${baseUrl}Invoice/ViewInvoicefilter`, {
        method: "POST",
        body: filterform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 2000);
      }

      if (
        data &&
        data.result.length &&
        data &&
        data.result[0].message === "Success"
      ) {
        setInvoice(data.result);
      } else {
        setInvoice([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterExpensesApi = async () => {
    filterform.append("AdminID", localStorage.getItem("id"));
    filterform.append("UserID", localStorage.getItem("uId"));
    filterform.append("CaseID", fExpenseCaseID);
    filterform.append("ClientID", fExpenseClientID);
    filterform.append("From", fExpensesFromDate);
    filterform.append("To", fExpensesToDate);
    try {
      const response = await fetch(`${baseUrl}Expenses/ViewExpensesfilter`, {
        method: "POST",
        body: filterform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }

      if (
        data &&
        data.result.length &&
        data &&
        data.result[0].message === "Success"
      ) {
        setExpense(data.result);
      } else {
        setExpense([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const receiptFilterShow = () => {
    setReceiptFilter(true);
  };

  const taskFilterShow = () => {
    setTaskFilter(true);
  };

  const eventFilterShow = () => {
    setEventFilter(true);
  };

  const noteFilterShow = () => {
    setNoteFilter(true);
  };

  const appointmentFilterShow = () => {
    setAppointmentFilter(true);
  };

  const invoiceFilterShow = () => {
    setInvoiceFilter(true);
  };

  const expensesFilterShow = () => {
    setExpensesFilter(true);
  };

  const caseFilterShow = () => {
    setCaseFilter(true);
  };

  const caseFlowFilterShow = () => {
    setCaseFlowFilter(true);
  };

  const earningReportFilterShow = () => {
    setEarningReportFilter(true);
  };

  const casesFilterShow = () => {
    setCasesFilter(true);
  };

  const filterReceiptCancel = () => {
    setFReceiptCaseId("");
    setFReceiptClient("");
    getEventView();
    setFReceiptFromDate("");
    setFReceiptToDate("");
    setReceiptFilter(false);
  };

  const filterTaskCancel = () => {
    setFTaskCaseId("");
    setFTaskClient("");
    getEventView();
    setFTaskFromDate("");
    setFTaskToDate("");
    setTaskFilter(false);
  };

  const filterEventCancel = () => {
    getEventView();
    setFEventFromDate("");
    setFEventToDate("");
    setFEventIsOpen("");
    setEventFilter(false);
  };

  const filterNoteCancel = () => {
    setFNoteCaseId("");
    setFNoteClient("");
    getEventView();
    setFNoteFromDate("");
    setFNoteToDate("");
    setNoteFilter(false);
  };

  const filterCasesCancel = () => {
    document.getElementById("dynamicColumnCases").innerHTML = "";
    setBorderLeftCases(false);
    // document.getElementById("dynamicCases").innerHTML = "";
    getEventView();
    setFCasesPeriod("1");
    setFCasesGroupBy("");
    setFCasesFromDate("");
    setFCasesToDate("");
    setCasesFilter(false);
  };

  const filterCaseFlowCancel = () => {
    setBorderLeftCaseFlow(false);
    document.getElementById("dynamicColumn").innerHTML = "";
    // document.getElementById("dynamic").innerHTML = "";
    getEventView();
    setPeriod("1");
    setGroupBy("");
    setFCaseFlowFromDate("");
    setFCaseFlowToDate("");
    setCaseFlowFilter(false);
  };

  const filterEarningReportCancel = () => {
    document.getElementById("dynamicColumnEarning").innerHTML = "";
    // document.getElementById("dynamicEarning").innerHTML = "";
    getEventView();
    setFEarningReportPeriod("1");
    setFEarningReportGroupBy("");
    setFEarningReportFromDate("");
    setFEarningReportToDate("");
    setEarningReportFilter(false);
  };

  const filterAppointmentCancel = () => {
    getEventView();
    setFAppointmentFromDate("");
    setFAppointmentToDate("");
    setAppointmentFilter(false);
  };

  const filterExpensesCancel = () => {
    getEventView();
    setFExpensesFromDate("");
    setFExpensesToDate("");
    setExpensesFilter(false);
  };

  const filterInvoiceCancel = () => {
    getEventView();
    setFInvoiceCaseId("");
    setFInvoiceClient("");
    setFInvoiceFromDate("");
    setFInvoiceToDate("");
    setInvoiceFilter(false);
  };

  // ------------------------  View Dashboard Data   -----------------------

  const ViewData = new FormData();

  const getAllApi = async (path) => {
    ViewData.append("AdminID", localStorage.getItem("id"));
    ViewData.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}${path}`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: ViewData,
      });

      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
      }

      if (data && data.status === "Success") {
        return data && data.result;
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const getCaseData = async () => {
      const [
        caseData,
        caseFlowData,
        getDashboardCaseCountData,
      ] = await Promise.all([
        getAllApi("Admin/getCaseData"),
        getAllApi("Admin/getCaseFlowData"),
        getAllApi("Admin/getDashboardCaseCountData"),
      ]);

      setCaseFlow(caseFlowData && caseFlowData.caseFlow);
      setCasesData(caseFlowData && caseFlowData.caseFlowDetails);
      setCaseF(caseData && caseData.case);
      setCaseCountData(
        getDashboardCaseCountData && getDashboardCaseCountData.CaseCount
      );
    };

    getCaseData();
  }, [HeaderF]);

  const getCountries = async () => {
    getFileNo();

    const [
      invoiceData,
      taskData,
      appoinmentData,
      eventData,
      adminDashBoardData,
      getReceiptData,
      getNotesData,
      getExpensesData,
      reportAmount,
      invoicegrapData,
      receiptgraphData,
    ] = await Promise.all([
      getAllApi("Admin/getInvoiceData"),
      getAllApi("Admin/gettaskData"),
      getAllApi("Admin/getAppoinmentData"),
      getAllApi("Admin/getEventData"),
      getAllApi("Admin/getAdminDashBoardData"),
      getAllApi("Admin/getReceiptData"),
      getAllApi("Admin/getNotesData"),
      getAllApi("Admin/getExpensesData"),
      getAllApi("Admin/getReportAmount "),
      getAllApi("Invoice/Invoicegrap"),
      getAllApi("Receipt/PaidAmountgrap"),
    ]);

    setInvoicegrap(invoicegrapData && invoicegrapData.current);
    setInvoicegrapPrivious(invoicegrapData && invoicegrapData.privious);

    setReceiptGraph(receiptgraphData && receiptgraphData.current);
    setReceiptGraphPrivious(receiptgraphData && receiptgraphData.privious);

    setReportAmount(reportAmount && reportAmount.Report);
    setHeader(adminDashBoardData && adminDashBoardData.header);
    setInvoice(invoiceData && invoiceData.Invoice);
    setReceipt(getReceiptData && getReceiptData.receipt);
    setEvent(eventData && eventData.event);
    setTask(taskData && taskData.task);
    setNotes(getNotesData && getNotesData.note);
    setExpense(getExpensesData && getExpensesData.expenses);
    setAppointment(appoinmentData && appoinmentData.Appoinment);
  };

  const hasEvent = (date) => {
    return Event.some((event) => {
      const eventDate = new Date(event.Date);
      return (
        eventDate.getDate() === date.getDate() &&
        eventDate.getMonth() === date.getMonth() &&
        eventDate.getFullYear() === date.getFullYear()
      );
    });
  };

  const tileClass = (date) => (hasEvent(date) ? "event-tile" : "");

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getEventView();
    }
  }, []);

  const [dCaseName, setDCaseName] = useState("");
  const [dCaseType, setDCaseType] = useState("");
  const [dCaseYear, setDCaseYear] = useState("");
  const [dcity_title, setDcity_title] = useState("");
  const [dClientCompany, setDClientCompany] = useState("");
  const [dClientLastName, setDClientLastName] = useState("");
  const [dClientName, setDClientName] = useState("");
  const [dCourtName, setDCourtName] = useState("");
  const [dAddedBy, setDAddedBy] = useState("");
  const [ddiaryNo, setDDiaryNo] = useState("");
  const [ddiaryYear, setDDiaryYear] = useState("");
  const [dcaseNo, setDCaseNo] = useState("");
  const [detailData, setDetailData] = useState([]);
  const [dsummary, setDSummary] = useState("");
  const [dfixedFees, setDFixedFees] = useState("");
  const [dexpenses, setDExpenses] = useState("");
  const [dappearanceFee, setDAppearanceFee] = useState("");
  const [dnonAppearanceFee, setDNonAppearanceFee] = useState("");
  const [daor, setDAor] = useState("");
  const [dretaineed, setDRetaineed] = useState("");
  const [dpatitioners, setDPatitioners] = useState("");
  const [drespondants, setDRespondants] = useState("");
  const [dintervenors, setDIntervenors] = useState("");
  const [ddistrict_title, setDdistrict_title] = useState("");
  const [dFileNo, setDFileNo] = useState("");
  const [dstate_title, setDstate_title] = useState("");

  //------------ SEARCH DETAILS ----------
  const detailsForm = new FormData();
  const details = async (id) => {
    detailsForm.append("AdminID", `${localStorage.getItem("id")}`);
    detailsForm.append("ID", id);
    try {
      const response = await fetch(`${baseUrl}Cases/ViewCaseByIdOLD`, {
        method: "POST",
        body: detailsForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message === "Session Time Expire.") {
        toast.error("Token expired please login again");
        return "token";
      }

      if (data && data.result.Case[0].message === "Success") {
        setDetailData(data && data.result.CaseDetail);
        setDCourtName(data && data.result.Case[0].CourtName);
        setDstate_title(data && data.result.Case[0].state_title);
        setDdistrict_title(data && data.result.Case[0].district_title);
        setDcity_title(data && data.result.Case[0].city_title);
        setDCaseType(data && data.result.Case[0].CaseType);
        setDFileNo(data && data.result.Case[0].FileNo);
        setDCaseYear(data && data.result.Case[0].CaseYear);
        setDClientName(data && data.result.Case[0].ClientName);
        setDClientLastName(data && data.result.Case[0].ClientLastName);
        setDClientCompany(data && data.result.Case[0].ClientCompany);
        setDCaseName(data && data.result.Case[0].CaseName);
        setDAddedBy(data && data.result.Case[0].AddedBy);
        setDAor(data && data.result.Case[0].AOR);
        setDIntervenors(data && data.result.Case[0].Intervenors);
        setDRespondants(data && data.result.Case[0].Respondants);
        setDRetaineed(data && data.result.Case[0].RetainedFor);
        setDPatitioners(data && data.result.Case[0].Petitioners);
        setDNonAppearanceFee(data && data.result.Case[0].NonAppearanceFee);
        setDAppearanceFee(data && data.result.Case[0].AppearanceFee);
        setDExpenses(data && data.result.Case[0].Expenses);
        setDFixedFees(data && data.result.Case[0].FixedFees);
        setDSummary(data && data.result.Case[0].CaseSummary);
        setDCaseNo(data && data.result.Case[0].CaseNo);
        setDDiaryNo(data && data.result.Case[0].DiaryNo);
        setDDiaryYear(data && data.result.Case[0].DiaryYear);
      } else {
        toast.error(data && data.result.Case[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [showField, setShowField] = useState(false);
  const handleClick = () => {
    setShowField(true);
    if (showField === true) {
      setShowField(false);
    }
  };

  const handleClickback = () => {
    setShowField(false);
  };

  let myChart = null;

  useEffect(() => {
    if (chartRef && chartRef.current) {
      if (myChart) {
        myChart.destroy(); // Destroy previous chart instance
      }

      myChart = new Chart(chartRef.current, {
        type: "line",
        data: {
          labels: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
          ],
          datasets: [
            {
              label: "First Dataset",
              data: [5, 59, 80, 81, 56, 55, 40],
              fill: true,
              borderColor: "rgb(75, 192, 192)",
              tension: 0.1,
            },
            {
              label: "Second Dataset",
              data: [30, 50, 40, 60, 45, 70, 30],
              fill: true,
              borderColor: "rgb(255, 99, 132)",
              tension: 0.1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    }

    // Cleanup function
    return () => {
      if (myChart) {
        myChart.destroy(); // Destroy chart instance on component unmount
      }
    };
  }, [chartRef]);

  const areaDataReport = {
    labels: reportAmount.map(data => data.MonthYear),
    datasets: [
      {
        label: "EXPENSES",
        data: reportAmount.map(data => parseInt(data.Expenses)),
        fill: true,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 2,
      },
      {
        label: "RECEIVED",
        data: reportAmount.map(data => parseInt(data.Received)),
        fill: true,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 2,
      },
    ],
  };

  const areaDataCaseCount = {
    labels: caseCountData.map(data => data.CombinedDate),
    datasets: [
      {
        label: "CASE COUNT",
        data: caseCountData.map(data => parseInt(data.CaseCount)),
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
      },
    ],
  };

  return (
    <>
      <DocumentTitle title="HOME | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div className="wraper">
            <Header aName={props.aName} />
            <div className="mainWraper">
              <Sidebar />
              <div className="pageBody">
                <div className="pageContent">
                  <div className="userListTable">
                    <div className="tableBox">
                      <div className="tableShortby">
                        {/* Dashboard Start */}

                        <div>
                          {/* ------------------Cards------------------------- */}
                          {HeaderF && HeaderF.length > 0 ? (
                            HeaderF.map((val, index) => (
                              <div key={index}>
                                <div className="lawyer_card row">
                                  <div className="col-md-3 ">
                                    <a
                                      href="/#/view-case"
                                      title="View All Case"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <div className="crm_card">
                                        <div className="crm_card_flex">
                                          <figure>
                                            <img
                                              src="assets/img/icons/case.svg"
                                              alt=""
                                            />
                                          </figure>
                                          <div className="card_crmtext d-flex">
                                            <div className="lt_box">
                                              <h5>{val.cases}</h5>
                                              <a
                                                href="/#/view-case"
                                                title="View All Case"
                                              >
                                                TOTAL CASE
                                              </a>
                                            </div>

                                            <div className="rtBox">
                                              <div className="in_data">
                                                <h4>{val.Opencase}</h4>
                                                <p>OPEN</p>
                                              </div>

                                              <div className="in_data">
                                                <h4>{val.Closecase}</h4>
                                                <p>CLOSED</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                  <div className="col-md-3">
                                    <a
                                      href="/#/view-client"
                                      title="View All Client"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <div className="crm_card">
                                        <div className="crm_card_flex">
                                          <figure>
                                            <img
                                              src="assets/img/icons/client.svg"
                                              alt=""
                                            />
                                          </figure>
                                          <div className="card_crmtext">
                                            <h5>{val.cleint}</h5>
                                            <a
                                              href="/#/view-client"
                                              title="View All Client"
                                            >
                                              TOTAL CLIENT
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                  <div className="col-md-3">
                                    <a
                                      href="/#/view-task"
                                      title="View All Task"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <div className="crm_card">
                                        <div className="crm_card_flex">
                                          <figure>
                                            <img
                                              src="assets/img/icons/event.svg"
                                              alt=""
                                            />
                                          </figure>
                                          <div className="card_crmtext">
                                            <h5>{val.Todaytask}</h5>
                                            <a
                                              href="/#/view-task"
                                              title="View All Task"
                                            >
                                              TOTAL TASK
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                  <div className="col-md-3">
                                    <a
                                      href="/#/view-user"
                                      title="View All User"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <div className="crm_card">
                                        <div className="crm_card_flex">
                                          <figure>
                                            <img
                                              src="assets/img/icons/user1.svg"
                                              alt=""
                                            />
                                          </figure>
                                          <div className="card_crmtext">
                                            <h5>{val.user}</h5>
                                            <a
                                              href="/#/view-user"
                                              title="View All User"
                                            >
                                              TOTAL USER
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                  <div className="col-md-3">
                                    <a
                                      href="/#/view-invoice"
                                      title="View All Invoice"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <div className="crm_card">
                                        <div className="crm_card_flex inv">
                                          <figure>
                                            <img
                                              src="assets/img/icons/G6.svg"
                                              alt=""
                                            />
                                          </figure>
                                          <div className="card_crmtext">
                                            <h5>
                                              {val.todayInvoiceAmount} Rs.
                                            </h5>
                                            <a
                                              href="/#/view-invoice"
                                              title="View All Invoice"
                                            >
                                              TODAY'S INVOICE AMOUNT
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                  <div className="col-md-3">
                                    <a
                                      href="/#/view-expenses"
                                      title="View All Expenses"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <div className="crm_card">
                                        <div className="crm_card_flex Expense">
                                          <figure>
                                            <img
                                              src="assets/img/icons/G5.svg"
                                              alt=""
                                            />
                                          </figure>
                                          <div className="card_crmtext">
                                            <h5>
                                              {val.todayExpensesAmount} Rs.
                                            </h5>
                                            <a
                                              href="/#/view-expenses"
                                              title="View All Expenses"
                                            >
                                              TODAY'S EXPENSE AMOUNT
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>

                                  <div className="col-md-3">
                                    <a
                                      href="/#/view-receipt"
                                      title="View All Receipt"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <div className="crm_card">
                                        <div className="crm_card_flex Amt">
                                          <figure>
                                            <img
                                              src="assets/img/icons/G4.svg"
                                              alt=""
                                            />
                                          </figure>
                                          <div className="card_crmtext">
                                            <h5>
                                              {val.todayReceiptAmount} Rs.
                                            </h5>
                                            <a
                                              href="/#/view-receipt"
                                              title="View All Received Amount"
                                            >
                                              TODAY'S RECEIVED AMOUNT
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                  <div className="col-md-3">
                                    <a
                                      href="/#/view-appointments"
                                      title="View All Appointments"
                                      style={{ textDecoration: "none" }}
                                    >
                                      <div className="crm_card">
                                        <div className="crm_card_flex appoint">
                                          <figure>
                                            <img
                                              src="assets/img/icons/G7.svg"
                                              alt=""
                                            />
                                          </figure>
                                          <div className="card_crmtext">
                                            <h5>{val.todayAppoinment}</h5>
                                            <a
                                              href="/#/view-appointments"
                                              title="View All Appointment"
                                            >
                                              TODAY'S APPOINTMENT
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div>Loading...</div>
                          )}
                          {/* <!--EXPENSE AND RECEIVED AMOUNT  Reports  --> */}
                          <div>
                            <div className="row">
                              <div className="col-md-7">
                                <div className="myCard">
                                  <div className="cardHeader">
                                    <h4>EXPENSE AND RECEIVED AMOUNT REPORTS</h4>
                                    <div class="rytContent"></div>
                                  </div>
                                  <div className="cardBody">
                                  <Line
                                  width={700}
                                  height={300}
                                  data={areaDataReport}
                                  options={{
                                    plugins: {
                                      legend: {
                                        display: true,
                                        position: 'bottom',
                                      },
                                    },
                                    fill: true,
                                    tension: 0.3,
                                  }}
                                />
                                  </div>
                                </div>
                              </div>
                              {/* <!--Case  Reports  --> */}
                              <div className="col-md-5">
                                <div className="myCard">
                                  <div className="cardHeader">
                                    <div className="rp">
                                      <h4>CASE REPORTS</h4>
                                      <p style={{ color: "white" }}>
                                        (ON FILING DATE BASIS)
                                      </p>
                                    </div>
                                    <div className="rytContent">
                                      <a href="/#/view-case">
                                        <img
                                          src="assets/img/icons/dots.png"
                                          alt=""
                                          title="All Case"
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  <div className="cardBody">
                                  <Line
                                  data={areaDataCaseCount}
                                  options={{
                                    plugins: {
                                      legend: {
                                        display: true,
                                        position: 'bottom'
                                      }
                                    },
                                  }}
                                />
                                  </div>
                                </div>
                              </div>
                              {/* <!--Case  Reports  --> */}
                            </div>
                          </div>

                          {/* ------------Invoice multiline graph ------------ */}

                          <div>
                            <div className="row">
                              <div className="col-md">
                                <div className="myCard">
                                  <div className="cardHeader">
                                  <h4>ANALYSING 2 YEARS INVOICE AMOUNT</h4>
                                  </div>
                                  <Line
                                    ref={chartRef}
                                    width={100}
                                    height={30}
                                    data={chartDataInvoice}
                                    options={{
                                      plugins: {
                                        legend: {
                                          display: true,
                                          position: 'bottom',
                                        },
                                      },
                                      onClick: redirectToInvoice,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                            {/* ------------Received multiline graph ------------ */}

                            <div>
                            <div className="row">
                              <div className="col-md">
                                <div className="myCard">
                                  <div className="cardHeader">
                                    <h4>ANALYSING 2 YEARS RECEIVED AMOUNT </h4>
                                  </div>
                                  <Line
                                  width="100%"
                                  height={30} 
                                  data={chartDataReceipt}
                                  options={{
                                    plugins: {
                                      legend: {
                                        display: true,
                                        position: "bottom"
                                      }
                                    },
                                    onClick: redirectToReceipt,
                                  }}
                                />
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* --------------------- event start -------------- */}

                          <div className="row">
                            <div className="col-md-6 col-sm-12 col-12 col-lg-4">
                              <div className="myCard">
                                <div className="cardHeader">
                                  <h4>EVENT CALENDAR</h4>
                                </div>
                                {Event && Event ? (
                                  <div className="cardBody">
                                    <Calendar
                                      tileClassName={({ date }) =>
                                        tileClass(date)
                                      }
                                      tileContent={({ date }) => {
                                        const event = Event.find((event) => {
                                          const eventDate = new Date(
                                            event.Date
                                          );
                                          return (
                                            eventDate.getDate() ===
                                              date.getDate() &&
                                            eventDate.getMonth() ===
                                              date.getMonth() &&
                                            eventDate.getFullYear() ===
                                              date.getFullYear()
                                          );
                                        });
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div>Loading...</div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-lg-12">
                              <div className="myCard">
                                <div className="cardHeader">
                                  <div style={{ display: "flex" }}>
                                    <h4>EVENTS </h4>&nbsp;&nbsp;
                                    <div className="filter_button">
                                      <button>
                                        <a
                                          data-toggle="modal"
                                          data-target="#exampleModalLong"
                                        >
                                          Add Event
                                        </a>
                                      </button>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "20px",
                                    }}
                                  >
                                    {eventFilter && (
                                      <div className="form_flex1 form_flex_home caseMobile">
                                        <div className="form-group1">
                                          <Select
                                            className="basic-single"
                                            placeholder="Select"
                                            classNamePrefix="select"
                                            onChange={handleFilterEventCaseId}
                                            isClearable={true}
                                            isSearchable={true}
                                            name="color"
                                            options={optionsEvent}
                                          />
                                        </div>
                                        <div className="form-group1">
                                          <Select
                                            className="basic-single"
                                            placeholder="Select"
                                            classNamePrefix="select"
                                            onChange={handleFilterEventClient}
                                            isClearable={true}
                                            isSearchable={true}
                                            name="color"
                                            options={fClientOption}
                                          />
                                        </div>
                                        <div className="form-group1">
                                          <select
                                            name="cars"
                                            id="cars"
                                            value={fEventIsOpen}
                                            onChange={(e) =>
                                              setFEventIsOpen(e.target.value)
                                            }
                                          >
                                            <option value="">All</option>
                                            <option value="1">Open</option>
                                            <option value="0">Close</option>
                                          </select>
                                        </div>

                                        <div className="form-group1">
                                          <input
                                            placeholder="Date"
                                            max={currentDate}
                                            autoComplete="off"
                                            type="date"
                                            value={fEventFromDate}
                                            onChange={(e) =>
                                              setFEventFromDate(e.target.value)
                                            }
                                          />
                                        </div>
                                        <div className="form-group1">
                                          <input
                                            placeholder="Date"
                                            autoComplete="off"
                                            type="date"
                                            value={fEventToDate}
                                            onChange={(e) =>
                                              setFEventToDate(e.target.value)
                                            }
                                          />
                                        </div>
                                        <div>
                                          <div className="filter_button form-group1">
                                            <button
                                              type="submit"
                                              onClick={filterEventApi}
                                            >
                                              Search
                                            </button>
                                            <button
                                              type="button"
                                              onClick={filterEventCancel}
                                            >
                                              Cancel
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "20px",
                                      }}
                                    >
                                      <img
                                        type="button"
                                        style={{
                                          display:
                                            eventFilter === true
                                              ? "none"
                                              : "block",
                                        }}
                                        onClick={eventFilterShow}
                                        src="assets/img/icons/Vector.svg"
                                        alt=""
                                        title="Filter"
                                      />
                                      <div class="rytContent">
                                        <a href="/#/view-events">
                                          <img
                                            src="assets/img/icons/dots.png"
                                            alt=""
                                            title="All Events"
                                          />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="cardBody">
                                  {Event && Event.length > 0 ? (
                                    Event.map((val, index) => (
                                      <ul className="myUl">
                                        <li key={index}>
                                          <div className="ulRow">
                                            <label>File No.: </label>
                                            <span>{val.FileNo}</span>
                                          </div>
                                          <div className="ulRow">
                                            <label>Date: </label>
                                            <span>{formatDate1(val.Date)}</span>
                                          </div>
                                          <div className="ulRow">
                                            <label>Case Name:</label>
                                            <span>
                                              <a
                                                title="Case Details"
                                                href={`#/view-casedetails/${val.CaseID}`}
                                                className="visuald_case upperCase"
                                                target="_blank"
                                                style={{ cursor: "pointer" }}
                                              >
                                                {val.CaseName}
                                              </a>
                                            </span>
                                          </div>
                                          <div className="ulRow">
                                            <label>Event Details: </label>
                                            <span className="lineLimit upperCase">
                                              {val.EventDetails}
                                            </span>
                                          </div>
                                        </li>
                                      </ul>
                                    ))
                                  ) : (
                                    <div>THERE ARE NO UPCOMING EVENTS...</div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* --------------------- event endd -------------- */}

                          <div>
                            <div className="row">
                              {/* <!-- Case Start  --> */}

                              <div className="col-md-12">
                                <div className="myCard">
                                  <div className="cardHeader">
                                    <div style={{ display: "flex" }}>
                                      <h4>LAST 5 CASE</h4>&nbsp;&nbsp;
                                      <div className="filter_button">
                                        <button>
                                          <a
                                            target="_blank"
                                            style={{
                                              color: "white",
                                              textDecoration: "none",
                                            }}
                                            href="/#/add-case"
                                          >
                                            Add Case
                                          </a>
                                        </button>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "20px",
                                      }}
                                    >
                                      {caseFilter && (
                                        <form
                                          role="form "
                                          onSubmit={filterCaseApi}
                                        >
                                          <div className="form_flex1 form_flex_home caseMobile">
                                            <div className="form-group1">
                                              <select
                                                name="cars"
                                                id="cars"
                                                value={fCaseIsOpen}
                                                onChange={(e) =>
                                                  setFCaseIsOpen(e.target.value)
                                                }
                                              >
                                                <option value="">All</option>
                                                <option value="1">Open</option>
                                                <option value="0">Close</option>
                                              </select>
                                            </div>

                                            <div className="form-group1">
                                              <Select
                                                className="basic-single"
                                                placeholder="Select"
                                                classNamePrefix="select"
                                                onChange={handleFilterCase}
                                                isClearable={true}
                                                isSearchable={true}
                                                name="color"
                                                options={optionsEvent}
                                              />
                                            </div>
                                            <div className="form-group1">
                                              <Select
                                                className="basic-single"
                                                placeholder="Select"
                                                classNamePrefix="select"
                                                onChange={handleFilterClient}
                                                isClearable={true}
                                                isSearchable={true}
                                                name="color"
                                                options={fClientOption}
                                              />
                                            </div>
                                            <div className="form-group1">
                                              <select
                                                name="cars"
                                                id="cars"
                                                value={fFilingDate}
                                                onChange={handleFilingDate}
                                              >
                                                <option value="">Select</option>
                                                <option value="1">
                                                  Filing Date
                                                </option>
                                                <option value="2">
                                                  Created On
                                                </option>
                                                <option value="3">
                                                  Closing Date
                                                </option>
                                              </select>
                                            </div>

                                            <div className="form-group1">
                                              <input
                                                required={isFieldRequired}
                                                placeholder="Date"
                                                max={currentDate}
                                                autoComplete="off"
                                                className="name"
                                                type="date"
                                                value={fCaseFromDate}
                                                onChange={(e) =>
                                                  setFCaseFromDate(
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                            <div className="form-group1">
                                              <input
                                                required={isFieldRequired}
                                                autoComplete="off"
                                                placeholder="Date"
                                                className="name"
                                                type="date"
                                                value={fCaseToDate}
                                                onChange={(e) =>
                                                  setFCaseToDate(e.target.value)
                                                }
                                              />
                                            </div>
                                            <div>
                                              <div className="filter_button form-group1">
                                                <button type="submit">
                                                  Search
                                                </button>
                                                <button
                                                  type="button"
                                                  onClick={filterCaseCancel}
                                                >
                                                  Cancel
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                      )}
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "20px",
                                        }}
                                      >
                                        <img
                                          type="button"
                                          style={{
                                            display:
                                              caseFilter === true
                                                ? "none"
                                                : "block",
                                          }}
                                          onClick={caseFilterShow}
                                          src="assets/img/icons/Vector.svg"
                                          alt=""
                                          title="Filter"
                                        />
                                        <div class="rytContent">
                                          <a href="/#/view-case">
                                            <img
                                              src="assets/img/icons/dots.png"
                                              alt=""
                                              title="All Case"
                                            />
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="cardBody">
                                    <table className="myTable">
                                      <thead>
                                        <tr className="top_table">
                                          <th>FILE NO.</th>
                                          <th>CASE NO.</th>
                                          <th>Case Type</th>
                                          <th>CASE NAME</th>
                                          <th>COUNSEL</th>
                                          <th>CLIENT</th>
                                          <th>CREATED BY</th>
                                        </tr>
                                      </thead>

                                      {CaseF && CaseF.length > 0 ? (
                                        CaseF.map((val, index) => (
                                          <tr
                                            key={index}
                                            className={
                                              index % 2 === 0
                                                ? "row-even"
                                                : "row-odd"
                                            }
                                          >
                                            <td>
                                              <p>{val.FileNo}</p>
                                            </td>
                                            <td>
                                              {val.CaseNo === "" ||
                                              val.CaseNo === null
                                                ? ""
                                                : val.CaseNo}
                                              {""}
                                              {val.CaseYear === "" ||
                                              val.CaseYear === null
                                                ? ""
                                                : ` / ${val.CaseYear}`}
                                            </td>
                                            <td>
                                              {val.CaseType === null ||
                                              val.CaseType === ""
                                                ? "-"
                                                : val.CaseType}
                                            </td>
                                            <td>
                                              {" "}
                                              <a
                                                href={`#/view-casedetails/${val.Id}`}
                                                className="visuald_case upperCase"
                                                target="_blank"
                                                style={{ cursor: "pointer" }}
                                                title="Case Details"
                                              >
                                                {val.CaseName}
                                              </a>
                                            </td>
                                            <td>{val.Counsel}</td>
                                            <td>
                                              <a
                                                href={`#/view-clientdetails/${val.ClientID}`}
                                                className="visuald_case upperCase"
                                                target="_blank"
                                                style={{ cursor: "pointer" }}
                                              >
                                                {val.ClientName}
                                                <br />
                                                {val.MobileNo}
                                              </a>
                                            </td>
                                            <td className="nowrap upperCase">
                                              {val.AddedBy}
                                              <br />
                                              {formateDateTime(val.CreatedDate)}
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <tr>
                                          <td colSpan="5">
                                            THERE ARE NO CASE...
                                          </td>
                                        </tr>
                                      )}
                                    </table>
                                  </div>
                                </div>
                              </div>
                              {/* <!--Case  Ends  --> */}
                            </div>
                          </div>

                          <div>
                            {/* <!-- Case Start  --> */}
                            <div className="row">
                              <div className="col-md-12">
                                <div className="myCard">
                                  <div className="cardHeader">
                                    <h4>CASES</h4>

                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "20px",
                                      }}
                                    >
                                      {casesFilter && (
                                        <div className="form_flex1 form_flex_home caseMobile">
                                          <div className="form-group1">
                                            <select
                                              onChange={(e) =>
                                                setFCasesPeriod(e.target.value)
                                              }
                                            >
                                              <option value="1">Daily</option>
                                              <option value="2">Monthly</option>
                                              <option value="3">Yearly</option>
                                            </select>
                                          </div>
                                          <div className="form-group1">
                                            <select
                                              onChange={(e) =>
                                                setFCasesGroupBy(e.target.value)
                                              }
                                            >
                                              <option value="">Group By</option>
                                              <option value="1">City</option>
                                              <option value="2">
                                                Case Counsel
                                              </option>
                                              <option value="3">
                                                Co-Counsel
                                              </option>
                                              <option value="4">
                                                Case Type
                                              </option>
                                            </select>
                                          </div>
                                          <div className="form-group1">
                                            <input
                                              autoComplete="off"
                                              className="name"
                                              type="date"
                                              value={fCasesFromDate}
                                              onChange={(e) =>
                                                setFCasesFromDate(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="form-group1">
                                            <input
                                              autoComplete="off"
                                              className="name"
                                              type="date"
                                              value={fCasesToDate}
                                              onChange={(e) =>
                                                setFCasesToDate(e.target.value)
                                              }
                                            />
                                          </div>
                                          <div>
                                            <div className="filter_button form-group1">
                                              <button
                                                type="button"
                                                onClick={filterCasesApi}
                                              >
                                                Search
                                              </button>
                                              <button
                                                type="button"
                                                onClick={filterCasesCancel}
                                              >
                                                Cancel
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "20px",
                                        }}
                                      >
                                        <img
                                          type="button"
                                          style={{
                                            display:
                                              casesFilter === true
                                                ? "none"
                                                : "block",
                                          }}
                                          onClick={casesFilterShow}
                                          src="assets/img/icons/Vector.svg"
                                          alt=""
                                          title="Filter"
                                        />
                                        <div class="rytContent">
                                          <a href="/#/view-case">
                                            <img
                                              src="assets/img/icons/dots.png"
                                              alt=""
                                              title="All Case"
                                            />
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="cardBody">
                                    <table className="myTable">
                                      <thead>
                                        <tr>
                                          <th>PERIOD</th>
                                          <th
                                            id="dynamicColumnCases"
                                            style={{
                                              borderLeft: borderLeftCases
                                                ? ""
                                                : "none",
                                            }}
                                          ></th>
                                          <th>BOOKED CASE</th>
                                          <th>CLOSE CASE</th>
                                          <th>FILED CASE</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {casesData &&
                                          casesData.map((val, index) => {
                                            return (
                                              <tr
                                                key={index}
                                                className="upperCase"
                                              >
                                                <td className="nowrap">
                                                  {val.Period}
                                                </td>
                                                <td id="dynamicCases">
                                                  {val.GroupField === "null"
                                                    ? ""
                                                    : val.GroupField}
                                                </td>
                                                <td>
                                                  <a
                                                    href={`/#/view-bookedCases/1_${fCasesGroupBy}_${
                                                      val.Period
                                                    }_${
                                                      fCasesGroupBy === "1"
                                                        ? val.CityID
                                                        : fCasesGroupBy ===
                                                            "2" ||
                                                          fCasesGroupBy === "3"
                                                        ? val.CounselID
                                                        : fCasesGroupBy === "4"
                                                        ? val.CaseTypeID
                                                        : ""
                                                    }`}
                                                    className="visuald_case"
                                                    target="_blank"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {val.OpenCaseCount}
                                                  </a>
                                                </td>
                                                <td>
                                                  <a
                                                    href={`/#/view-bookedCases/2_${fCasesGroupBy}_${
                                                      val.Period
                                                    }_${
                                                      fCasesGroupBy === "1"
                                                        ? val.CityID
                                                        : fCasesGroupBy ===
                                                            "2" ||
                                                          fCasesGroupBy === "3"
                                                        ? val.CounselID
                                                        : fCasesGroupBy === "4"
                                                        ? val.CaseTypeID
                                                        : ""
                                                    }`}
                                                    className="visuald_case"
                                                    target="_blank"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {val.ClosedCaseCount}
                                                  </a>
                                                </td>
                                                <td>
                                                  <a
                                                    href={`/#/view-bookedCases/""_${fCasesGroupBy}_${
                                                      val.Period
                                                    }_${
                                                      fCasesGroupBy === "1"
                                                        ? val.CityID
                                                        : fCasesGroupBy ===
                                                            "2" ||
                                                          fCasesGroupBy === "3"
                                                        ? val.CounselID
                                                        : fCasesGroupBy === "4"
                                                        ? val.CaseTypeID
                                                        : ""
                                                    }`}
                                                    className="visuald_case"
                                                    target="_blank"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {val.FilingCaseCount}
                                                  </a>
                                                </td>
                                              </tr>
                                            );
                                          })}

                                        <tr></tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              {/* </>
                                )} */}
                            </div>
                            {/* <!--Case  Ends  --> */}
                          </div>

                          <div>
                            {/* <!--Case  Ends  --> */}
                            <div className="row">
                              <div className="col-md-12">
                                <div className="myCard">
                                  <div className="cardHeader">
                                    <h4>CASH FLOW</h4>
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "20px",
                                      }}
                                    >
                                      {caseFlowFilter && (
                                        <div className="form_flex1 form_flex_home caseMobile">
                                          <div className="form-group1">
                                            <select
                                              onChange={(e) =>
                                                setPeriod(e.target.value)
                                              }
                                            >
                                              <option value="1">Daily</option>
                                              <option value="2">Monthly</option>
                                              <option value="3">Yearly</option>
                                            </select>
                                          </div>
                                          <div className="form-group1">
                                            <select
                                              onChange={(e) =>
                                                setGroupBy(e.target.value)
                                              }
                                            >
                                              <option value="">Group By</option>
                                              <option value="1">City</option>
                                              <option value="2">
                                                Case Counsel
                                              </option>
                                              <option value="3">
                                                Co-Counsel
                                              </option>
                                              <option value="4">
                                                Case Type
                                              </option>
                                            </select>
                                          </div>
                                          <div className="form-group1">
                                            <input
                                              autoComplete="off"
                                              className="name"
                                              type="date"
                                              value={fCaseFlowFromDate}
                                              onChange={(e) =>
                                                setFCaseFlowFromDate(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="form-group1">
                                            <input
                                              autoComplete="off"
                                              className="name"
                                              type="date"
                                              value={fCaseFlowToDate}
                                              onChange={(e) =>
                                                setFCaseFlowToDate(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                          <div>
                                            <div className="filter_button form-group1">
                                              <button
                                                type="button"
                                                onClick={filterCaseFlowApi}
                                              >
                                                Search
                                              </button>
                                              <button
                                                type="button"
                                                onClick={filterCaseFlowCancel}
                                              >
                                                Cancel
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "20px",
                                        }}
                                      >
                                        <img
                                          type="button"
                                          style={{
                                            display:
                                              caseFlowFilter === true
                                                ? "none"
                                                : "block",
                                          }}
                                          onClick={caseFlowFilterShow}
                                          src="assets/img/icons/Vector.svg"
                                          alt=""
                                          title="Filter"
                                        />
                                        <div class="rytContent">
                                          <a href="/#/view-case">
                                            <img
                                              src="assets/img/icons/dots.png"
                                              alt=""
                                              title="All Case"
                                            />
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="cardBody">
                                    <table className="myTable">
                                      <thead>
                                        <tr>
                                          <th rowspan="2">PERIOD</th>
                                          <th
                                            style={{
                                              borderLeft: borderLeftCaseFlow
                                                ? ""
                                                : "none",
                                            }}
                                            rowspan="2"
                                            id="dynamicColumn"
                                          ></th>
                                          <th
                                            style={{ textAlign: "center" }}
                                            colspan="3"
                                          >
                                            INVOICES
                                          </th>
                                          <th
                                            style={{ textAlign: "center" }}
                                            colspan="2"
                                          >
                                            RECEIPTS
                                          </th>
                                          <th
                                            style={{ textAlign: "center" }}
                                            colspan="2"
                                          >
                                            EXPENSES
                                          </th>
                                        </tr>
                                        <tr
                                          style={{ border: "1px solid  white" }}
                                        >
                                          <th>NO.</th>
                                          <th>AMOUNT</th>
                                          <th>PAID</th>
                                          <th>NO.</th>
                                          <th>AMOUNT</th>
                                          <th>NO.</th>
                                          <th>AMOUNT</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {caseFlow &&
                                          caseFlow.map((val, index) => {
                                            return (
                                              <tr key={index}>
                                                <td className="nowrap">
                                                  {val.Period}
                                                </td>
                                                <td id="dynamic">
                                                  {val.GroupField === "NULL" || val.GroupField === null
                                                    ? ""
                                                    : val.GroupField}
                                                </td>
                                                <td>
                                                  <a
                                                    href={`/#/view-cash-flow/1_${groupBy}_${
                                                      val.Period
                                                    }_${
                                                     val.Id
                                                   
                                                    }`}
                                                    className="visuald_case upperCase"
                                                    target="_blank"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {val.TotalInvoice}
                                                  </a>
                                                </td>
                                                <td>{val.InvoiceAmount}</td>
                                                <td>{val.InvoicePaidAmount}</td>
                                                <td>
                                                  <a
                                                    href={`/#/view-cash-flow/2_${groupBy}_${
                                                      val.Period
                                                    }_${
                                                      val.Id
                                                    }`}
                                                    className="visuald_case"
                                                    target="_blank"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {val.TotalReceipt}
                                                  </a>
                                                </td>
                                                <td>
                                                  {val.TotalReceiptAmount}
                                                </td>
                                                <td>
                                                  <a
                                                    href={`/#/view-cash-flow/""_${groupBy}_${
                                                      val.Period
                                                    }_${
                                                      val.Id
                                                    }`}
                                                    className="visuald_case"
                                                    target="_blank"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {val.TotalExpenses}
                                                  </a>
                                                </td>
                                                <td>{val.ExpensesAmount}</td>
                                              </tr>
                                            );
                                          })}
                                        <tr></tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <!--Case  Ends  --> */}
                          </div>

                          {/* <!--Calendar Start  --> */}

                          {/* <!-- Calendar Ends --> */}
                          <div>
                            <div className="row">
                              {/* <!-- Task  Starts--> */}
                              <div className="col-md-12">
                                <div className="myCard">
                                  <div className="cardHeader">
                                    <div style={{ display: "flex" }}>
                                      <h4>TASK </h4>&nbsp;&nbsp;
                                      <div className="filter_button">
                                        <button>
                                          <a
                                            target="_blank"
                                            style={{
                                              color: "white",
                                              textDecoration: "none",
                                            }}
                                            href="/#/add-task"
                                          >
                                            Add Task
                                          </a>
                                        </button>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "20px",
                                      }}
                                    >
                                      {taskFilter && (
                                        <div className="form_flex1 form_flex_home caseMobile">
                                          <div className="form-group1">
                                            <Select
                                              className="basic-single"
                                              placeholder="Select"
                                              classNamePrefix="select"
                                              onChange={handleFilterTaskCaseId}
                                              isClearable={true}
                                              isSearchable={true}
                                              name="color"
                                              options={optionsEvent}
                                            />
                                          </div>
                                          <div className="form-group1">
                                            <Select
                                              className="basic-single"
                                              placeholder="Select"
                                              classNamePrefix="select"
                                              onChange={handleFilterTaskClient}
                                              isClearable={true}
                                              isSearchable={true}
                                              name="color"
                                              options={fClientOption}
                                            />
                                          </div>
                                          <div className="form-group1">
                                            <input
                                              placeholder="Date"
                                              max={currentDate}
                                              autoComplete="off"
                                              // className="name"
                                              type="date"
                                              value={fTaskFromDate}
                                              onChange={(e) =>
                                                setFTaskFromDate(e.target.value)
                                              }
                                            />
                                          </div>
                                          <div className="form-group1">
                                            <input
                                              placeholder="Date"
                                              autoComplete="off"
                                              // className="name"
                                              type="date"
                                              value={fTaskToDate}
                                              onChange={(e) =>
                                                setFTaskToDate(e.target.value)
                                              }
                                            />
                                          </div>
                                          <div>
                                            <div className="filter_button form-group1">
                                              <button
                                                type="submit"
                                                onClick={filterTaskApi}
                                              >
                                                Search
                                              </button>
                                              <button
                                                type="button"
                                                onClick={filterTaskCancel}
                                              >
                                                Cancel
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "20px",
                                        }}
                                      >
                                        <img
                                          type="button"
                                          style={{
                                            display:
                                              taskFilter === true
                                                ? "none"
                                                : "block",
                                          }}
                                          onClick={taskFilterShow}
                                          src="assets/img/icons/Vector.svg"
                                          alt=""
                                          title="Filter"
                                        />
                                        <div class="rytContent">
                                          <a href="/#/view-task">
                                            <img
                                              src="assets/img/icons/dots.png"
                                              alt=""
                                              title="All Task"
                                            />
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="cardBody">
                                    {Task && Task.length > 0 ? (
                                      Task.map((val, index) => (
                                        <ul key={index} className="myUl">
                                          <li>
                                            <div className="ulRow">
                                              <label>Task Name:</label>
                                              <span>{val.TaskName}</span>
                                            </div>
                                            <div className="ulRow">
                                              <label>Case Name:</label>
                                              <span>
                                                <a
                                                  title="Case Details"
                                                  href={`#/view-casedetails/${val.CaseID}`}
                                                  className="visuald_case upperCase"
                                                  target="_blank"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  {val.FileNo}
                                                  {val.CaseName === null ||
                                                  val.CaseName === ""
                                                    ? ""
                                                    : ` / ${val.CaseName}`}
                                                </a>
                                              </span>
                                            </div>
                                            <div className="ulRow">
                                              <label>Assign To: </label>
                                              <span className="upperCase">
                                                {val.AssignTo}
                                              </span>
                                            </div>
                                            <div className="ulRow">
                                              <label>Dead line:</label>
                                              <span>
                                                {formatDate(val.DeadLine)}
                                              </span>
                                            </div>
                                          </li>
                                        </ul>
                                      ))
                                    ) : (
                                      <div>THERE ARE NO UPCOMING TASK...</div>
                                    )}
                                  </div>
                                </div>
                              </div>

                              {/* <!-- Task  Ends--> */}
                              {/* <!-- Notes  Starts--> */}
                              <div className="col-md-12">
                                <div className="myCard">
                                  <div className="cardHeader">
                                    <div style={{ display: "flex" }}>
                                      <h4>NOTES </h4>&nbsp;&nbsp;
                                      <div className="filter_button">
                                        <button>
                                          <a
                                            target="_blank"
                                            style={{
                                              color: "white",
                                              textDecoration: "none",
                                            }}
                                            href="/#/add-note"
                                          >
                                            Add Notes
                                          </a>
                                        </button>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "20px",
                                      }}
                                    >
                                      {noteFilter && (
                                        <div className="form_flex1 form_flex_home caseMobile">
                                          <div className="form-group1">
                                            <Select
                                              className="basic-single"
                                              placeholder="Select"
                                              classNamePrefix="select"
                                              onChange={handleFilterNoteCaseId}
                                              isClearable={true}
                                              isSearchable={true}
                                              name="color"
                                              options={optionsEvent}
                                            />
                                          </div>
                                          <div className="form-group1">
                                            <Select
                                              className="basic-single"
                                              placeholder="Select"
                                              classNamePrefix="select"
                                              onChange={handleFilterNoteClient}
                                              isClearable={true}
                                              isSearchable={true}
                                              name="color"
                                              options={fClientOption}
                                            />
                                          </div>
                                          <div className="form-group1">
                                            <input
                                              placeholder="Date"
                                              max={currentDate}
                                              autoComplete="off"
                                              // className="name"
                                              type="date"
                                              value={fNoteFromDate}
                                              onChange={(e) =>
                                                setFNoteFromDate(e.target.value)
                                              }
                                            />
                                          </div>
                                          <div className="form-group1">
                                            <input
                                              placeholder="Date"
                                              autoComplete="off"
                                              // className="name"
                                              type="date"
                                              value={fNoteToDate}
                                              onChange={(e) =>
                                                setFNoteToDate(e.target.value)
                                              }
                                            />
                                          </div>
                                          <div>
                                            <div className="filter_button form-group1">
                                              <button
                                                type="submit"
                                                onClick={filterNotetApi}
                                              >
                                                Search
                                              </button>
                                              <button
                                                type="button"
                                                onClick={filterNoteCancel}
                                              >
                                                Cancel
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "20px",
                                        }}
                                      >
                                        <img
                                          type="button"
                                          style={{
                                            display:
                                              noteFilter === true
                                                ? "none"
                                                : "block",
                                          }}
                                          onClick={noteFilterShow}
                                          src="assets/img/icons/Vector.svg"
                                          alt=""
                                          title="Filter"
                                        />
                                        <div class="rytContent">
                                          <a href="/#/view-note">
                                            <img
                                              src="assets/img/icons/dots.png"
                                              alt=""
                                              title="All Notes"
                                            />
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="cardBody">
                                    {Notes && Notes.length > 0 ? (
                                      Notes.map((val, index) => (
                                        <ul key={index} className="myUl">
                                          <li>
                                            <div className="ulRow">
                                              <label>Case Name :</label>
                                              <span>
                                                <a
                                                  title="Case Details"
                                                  href={`#/view-casedetails/${val.CaseID}`}
                                                  className="visuald_case upperCase"
                                                  target="_blank"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  {val.FileNo}
                                                  {val.CaseName === null ||
                                                  val.CaseName === ""
                                                    ? ""
                                                    : ` / ${val.CaseName}`}
                                                </a>
                                              </span>
                                            </div>
                                            <div className="ulRow">
                                              <label>Added By : </label>
                                              <span className="upperCase">
                                                {val.AddedBy}
                                              </span>
                                            </div>
                                            <div className="ulRow">
                                              <label>Summary : </label>
                                              <span className="lineLimit upperCase">
                                                {val.Summary}
                                              </span>
                                            </div>
                                            <div className="ulRow">
                                              <label>Details : </label>
                                              <span className="lineLimit upperCase">
                                                {val.Details}
                                              </span>
                                            </div>
                                          </li>
                                        </ul>
                                      ))
                                    ) : (
                                      <div>THERE ARE NO UPCOMING NOTES...</div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {/* <!--Notes  Ends  --> */}
                            </div>
                          </div>

                          <div>
                            {/* <!--Expense  Starts  --> */}
                            <div className="row">
                              <div className="col-md-12">
                                <div className="myCard">
                                  <div className="cardHeader">
                                    <div style={{ display: "flex" }}>
                                      <h4>EXPENSES </h4>&nbsp;&nbsp;
                                      <div className="filter_button">
                                        <button>
                                          <a
                                            target="_blank"
                                            style={{
                                              color: "white",
                                              textDecoration: "none",
                                            }}
                                            href="/#/add-expenses"
                                          >
                                            Add Expenses
                                          </a>
                                        </button>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "20px",
                                      }}
                                    >
                                      {expensesFilter && (
                                        <div className="form_flex1 form_flex_home caseMobile">
                                          <div className="form-group1">
                                            <Select
                                              style={{ color: "black" }}
                                              placeholder="Select"
                                              className="basic-single"
                                              classNamePrefix="select"
                                              onChange={
                                                handleFilterExpenseCaseId
                                              }
                                              isClearable={true}
                                              isSearchable={true}
                                              name="color"
                                              options={optionsEvent}
                                            />
                                          </div>
                                          <div className="form-group1">
                                            <Select
                                              style={{ color: "black" }}
                                              className="basic-single"
                                              placeholder="Select"
                                              classNamePrefix="select"
                                              onChange={
                                                handleFilterExpenseClient
                                              }
                                              isClearable={true}
                                              isSearchable={true}
                                              name="color"
                                              options={fClientOption}
                                            />
                                          </div>
                                          <div className="form-group1">
                                            <input
                                              max={currentDate}
                                              autoComplete="off"
                                              // className="name"
                                              type="date"
                                              placeholder="Date"
                                              value={fExpensesFromDate}
                                              onChange={(e) =>
                                                setFExpensesFromDate(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="form-group1">
                                            <input
                                              placeholder="Date"
                                              autoComplete="off"
                                              // className="name"
                                              type="date"
                                              value={fExpensesToDate}
                                              onChange={(e) =>
                                                setFExpensesToDate(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                          <div>
                                            <div className="filter_button form-group1">
                                              <button
                                                type="submit"
                                                onClick={filterExpensesApi}
                                              >
                                                Search
                                              </button>
                                              <button
                                                type="button"
                                                onClick={filterExpensesCancel}
                                              >
                                                Cancel
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "20px",
                                        }}
                                      >
                                        <img
                                          type="button"
                                          style={{
                                            display:
                                              expensesFilter === true
                                                ? "none"
                                                : "block",
                                          }}
                                          onClick={expensesFilterShow}
                                          src="assets/img/icons/Vector.svg"
                                          alt=""
                                          title="Filter"
                                        />
                                        <div class="rytContent">
                                          <a href="/#/view-expenses">
                                            <img
                                              src="assets/img/icons/dots.png"
                                              alt=""
                                              title="All Expenses"
                                            />
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="cardBody">
                                    {Expense && Expense.length > 0 ? (
                                      Expense.map((val, index) => (
                                        <ul key={index} className="myUl">
                                          <li>
                                            <div className="ulRow">
                                              <label>Case Name:</label>
                                              <span>
                                                <td>
                                                  <a
                                                    title="Case Details"
                                                    href={`#/view-casedetails/${val.CaseID}`}
                                                    className="visuald_case upperCase"
                                                    target="_blank"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {val.FileNo}
                                                    {val.CaseName === null ||
                                                    val.CaseName === ""
                                                      ? ""
                                                      : ` / ${val.CaseName}`}
                                                  </a>
                                                </td>
                                              </span>
                                            </div>
                                            <div className="ulRow">
                                              <label>Amount : </label>
                                              <span>{val.Amount} Rs</span>
                                            </div>
                                            <div className="ulRow">
                                              <label>Date : </label>
                                              <span>
                                                {formatDate(val.Date)}
                                              </span>
                                            </div>
                                            <div className="ulRow">
                                              <label>Expenses Details: </label>
                                              <span className="lineLimit upperCase">
                                                {val.ExpensesDetails}
                                              </span>
                                            </div>
                                          </li>
                                        </ul>
                                      ))
                                    ) : (
                                      <div>
                                        THERE ARE NO UPCOMING EXPENSES...
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {/* <!--Expense  Ends  --> */}
                              {/* <!-- Appointment Start  --> */}
                              <div className="col-md-12">
                                <div className="myCard">
                                  <div className="cardHeader">
                                    <div style={{ display: "flex" }}>
                                      <h4>APPOINTMENT </h4>&nbsp;&nbsp;
                                      <div className="filter_button">
                                        <button>
                                          <a
                                            target="_blank"
                                            style={{
                                              color: "white",
                                              textDecoration: "none",
                                            }}
                                            href="/#/add-appointment"
                                          >
                                            Add Appointments
                                          </a>
                                        </button>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "20px",
                                      }}
                                    >
                                      {appointmentFilter && (
                                        <div className="form_flex1 form_flex_home caseMobile">
                                          <div className="form-group1">
                                            <Select
                                              className="basic-single"
                                              placeholder="Select"
                                              classNamePrefix="select"
                                              onChange={
                                                handleFilterAppointCaseId
                                              }
                                              isClearable={true}
                                              isSearchable={true}
                                              name="color"
                                              options={optionsEvent}
                                            />
                                          </div>
                                          <div className="form-group1">
                                            <Select
                                              className="basic-single"
                                              placeholder="Select"
                                              classNamePrefix="select"
                                              onChange={
                                                handleFilterAppointClient
                                              }
                                              isClearable={true}
                                              isSearchable={true}
                                              name="color"
                                              options={fClientOption}
                                            />
                                          </div>
                                          <div className="form-group1">
                                            <input
                                              max={currentDate}
                                              autoComplete="off"
                                              placeholder="Date"
                                              type="date"
                                              value={fAppointmentFromDate}
                                              onChange={(e) =>
                                                setFAppointmentFromDate(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="form-group1">
                                            <input
                                              placeholder="Date"
                                              autoComplete="off"
                                              type="date"
                                              value={fAppointmentToDate}
                                              onChange={(e) =>
                                                setFAppointmentToDate(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                          <div>
                                       
                                          </div>
                                        </div>
                                      )}
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "20px",
                                        }}
                                      >
                                        <img
                                          type="button"
                                          style={{
                                            display:
                                              appointmentFilter === true
                                                ? "none"
                                                : "block",
                                          }}
                                          onClick={appointmentFilterShow}
                                          src="assets/img/icons/Vector.svg"
                                          alt=""
                                          title="Filter"
                                        />
                                        <div class="rytContent">
                                          <a href="/#/view-appointments">
                                            <img
                                              src="assets/img/icons/dots.png"
                                              alt=""
                                              title="All Appointment"
                                            />
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="cardBody cardBodypadd">
                                    {Appointment && Appointment.length > 0 ? (
                                      Appointment.map((val, index) => (
                                        <ul key={index} className="myUl">
                                          <li>
                                            <div className="ulRow">
                                              <label>File No:</label>
                                              <span>
                                                <a
                                                  title="Case Details"
                                                  href={`#/view-casedetails/${val.CaseID}`}
                                                  className="visuald_case upperCase"
                                                  target="_blank"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  {val.FileNo}
                                                  {val.CaseName === null ||
                                                  val.CaseName === ""
                                                    ? ""
                                                    : ` / ${val.CaseName}`}
                                                </a>
                                              </span>
                                            </div>
                                            <div className="ulRow">
                                              <label>Date: </label>
                                              <span>
                                                {formatDate1(val.Date)}
                                              </span>
                                            </div>
                                            <div className="ulRow">
                                              <label>Appointment With: </label>
                                              <span className="upperCase">
                                                {val.AppointmentWith}
                                              </span>
                                            </div>
                                          </li>
                                        </ul>
                                      ))
                                    ) : (
                                      <div>
                                        THERE ARE NO UPCOMING APPOINTMENT...
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {/* <!-- Appointment Ends--> */}
                            </div>
                          </div>

                          {/* Invoice Start */}

                          <div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="myCard">
                                  <div className="cardHeader ">
                                    <div style={{ display: "flex" }}>
                                      <h4>INVOICE </h4>&nbsp;&nbsp;
                                      <div className="filter_button">
                                        <button>
                                          <a
                                            target="_blank"
                                            style={{
                                              color: "white",
                                              textDecoration: "none",
                                            }}
                                            href="/#/add-invoice"
                                          >
                                            Add Invoice
                                          </a>
                                        </button>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "20px",
                                      }}
                                    >
                                      {invoiceFilter && (
                                        <div className="form_flex1 form_flex_home caseMobile">
                                          <div className="form-group1">
                                            <Select
                                              className="basic-single"
                                              placeholder="Select"
                                              classNamePrefix="select"
                                              onChange={
                                                handleFilterInvoiceCaseId
                                              }
                                              isClearable={true}
                                              isSearchable={true}
                                              name="color"
                                              options={optionsEvent}
                                            />
                                          </div>
                                          <div className="form-group1">
                                            <Select
                                              className="basic-single"
                                              placeholder="Select"
                                              classNamePrefix="select"
                                              onChange={
                                                handleInvoiceFilterClient
                                              }
                                              isClearable={true}
                                              isSearchable={true}
                                              name="color"
                                              options={fClientOption}
                                            />
                                          </div>

                                          <div className="form-group1">
                                            <input
                                              max={currentDate}
                                              autoComplete="off"
                                              placeholder="Date"
                                              type="date"
                                              value={fInvoiceFromDate}
                                              onChange={(e) =>
                                                setFInvoiceFromDate(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="form-group1">
                                            <input
                                              autoComplete="off"
                                              type="date"
                                              placeholder="Date"
                                              value={fInvoiceToDate}
                                              onChange={(e) =>
                                                setFInvoiceToDate(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                          <div>
                                            <div className="filter_button form-group1">
                                              <button
                                                type="submit"
                                                onClick={filterInvoiceApi}
                                              >
                                                Search
                                              </button>
                                              <button
                                                type="button"
                                                onClick={filterInvoiceCancel}
                                              >
                                                Cancel
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "20px",
                                        }}
                                      >
                                        <img
                                          type="button"
                                          style={{
                                            display:
                                              invoiceFilter === true
                                                ? "none"
                                                : "block",
                                          }}
                                          onClick={invoiceFilterShow}
                                          src="assets/img/icons/Vector.svg"
                                          alt=""
                                          title="Filter"
                                        />
                                        <div class="rytContent">
                                          <a href="/#/view-invoice">
                                            <img
                                              src="assets/img/icons/dots.png"
                                              alt=""
                                              title="All Invoice"
                                            />
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="cardBody">
                                    <table className="myTable">
                                      <thead>
                                        <tr className="top_table">
                                          <th>FILE NO. </th>
                                          <th>CASE NAME </th>
                                          <th>INVOICE NO.</th>
                                          <th>CLIENT NAME</th>
                                          <th>PAID / UNPAID </th>
                                          <th>INVOICE AMOUNT</th>
                                          <th>BALANCE</th>
                                          <th>CREATED BY</th>
                                        </tr>
                                      </thead>

                                      {Invoice && Invoice.length > 0 ? (
                                        Invoice.map((val, index) => (
                                          <tr
                                            key={index}
                                            className={
                                              index % 2 === 0
                                                ? "row-even"
                                                : "row-odd"
                                            }
                                          >
                                            <td>
                                              <b>{val.FileNo}</b>
                                            </td>
                                            <td>
                                              <a
                                                title="Case Details"
                                                href={`#/view-casedetails/${val.CaseID}`}
                                                className="visuald_case upperCase"
                                                target="_blank"
                                                style={{ cursor: "pointer" }}
                                              >
                                                {val.CaseName}
                                              </a>
                                            </td>
                                            <td>{val.InvoiceNo}</td>
                                            <td>
                                              <a
                                                title="Client Details"
                                                href={`#/view-clientdetails/${val.ClientID}`}
                                                className="visuald_case upperCase"
                                                target="_blank"
                                                style={{ cursor: "pointer" }}
                                              >
                                                {val.ClientName}
                                                <br />
                                                {val.MobileNo}
                                              </a>
                                            </td>
                                            {val.InvoiceAmount ===
                                            val.PaidAmount ? (
                                              <td className="paid">
                                                <span></span>PAID
                                                <label className="PA">
                                                  {val.PaidAmount}
                                                </label>
                                              </td>
                                            ) : val.PaidAmount > 0 ? (
                                              <td className="overdue">
                                                <span></span>PARTIAL PAYMENT
                                                &nbsp;
                                                <label className="PA">
                                                  {val.PaidAmount}
                                                </label>
                                              </td>
                                            ) : (
                                              <td className="pending">
                                                <span></span>UNPAID
                                                <label className="PA">
                                                  {val.PaidAmount}
                                                </label>
                                              </td>
                                            )}

                                            <td>{val.InvoiceAmount}</td>
                                            <td>
                                              {val.InvoiceAmount -
                                                val.PaidAmount}
                                            </td>
                                            <td className="nowrap upperCase">
                                              {val.CreatedBy}
                                              <br />
                                              {formatTime(val.CreatedDate)}
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <div>
                                          THERE ARE NO UPCOMING INVOICE..
                                        </div>
                                      )}
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!-- Invoice Ends--> */}
                          {/* Receipt Start */}
                          <div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="myCard">
                                  <div className="cardHeader">
                                    <div style={{ display: "flex" }}>
                                      <h4>RECEIPT </h4>&nbsp;&nbsp;
                                      <div className="filter_button">
                                        <button>
                                          <a
                                            target="_blank"
                                            style={{
                                              color: "white",
                                              textDecoration: "none",
                                            }}
                                            href="/#/add-receipt"
                                          >
                                            Add Receipt
                                          </a>
                                        </button>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "20px",
                                      }}
                                    >
                                      {receiptFilter && (
                                        <div className="form_flex1 form_flex_home caseMobile">
                                          <div className="form-group1">
                                            <Select
                                              className="basic-single"
                                              placeholder="Select"
                                              classNamePrefix="select"
                                              onChange={handleReceiptFilterCase}
                                              isClearable={true}
                                              isSearchable={true}
                                              name="color"
                                              options={optionsEvent}
                                            />
                                          </div>
                                          <div className="form-group1">
                                            <Select
                                              className="basic-single"
                                              placeholder="Select"
                                              classNamePrefix="select"
                                              onChange={
                                                handleReceiptFilterClient
                                              }
                                              isClearable={true}
                                              isSearchable={true}
                                              name="color"
                                              options={fClientOption}
                                            />
                                          </div>
                                          <div className="form-group1">
                                            <input
                                              placeholder="Date"
                                              max={currentDate}
                                              autoComplete="off"
                                              className="name"
                                              type="date"
                                              value={fReceiptFromDate}
                                              onChange={(e) =>
                                                setFReceiptFromDate(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="form-group1">
                                            <input
                                              autoComplete="off"
                                              placeholder="Date"
                                              className="name"
                                              type="date"
                                              value={fReceiptToDate}
                                              onChange={(e) =>
                                                setFReceiptToDate(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                          <div>
                                            <div className="filter_button form-group1">
                                              <button
                                                type="submit"
                                                onClick={filterReceiptApi}
                                              >
                                                Search
                                              </button>
                                              <button
                                                type="button"
                                                onClick={filterReceiptCancel}
                                              >
                                                Cancel
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "20px",
                                        }}
                                      >
                                        <img
                                          type="button"
                                          style={{
                                            display:
                                              receiptFilter === true
                                                ? "none"
                                                : "block",
                                          }}
                                          onClick={receiptFilterShow}
                                          src="assets/img/icons/Vector.svg"
                                          alt=""
                                          title="Filter"
                                        />
                                        <div class="rytContent">
                                          <a href="/#/view-receipt">
                                            <img
                                              src="assets/img/icons/dots.png"
                                              alt=""
                                              title="All Receipt"
                                            />
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="cardBody">
                                    <table className="myTable">
                                      <thead>
                                        <tr className="top_table">
                                          <th>FILE NO. </th>
                                          <th>CASE NAME </th>
                                          <th>RECEIPT NO. </th>
                                          <th>INVOICE NO.</th>
                                          <th>TRANSACTION TYPE</th>
                                          <th>PAID AMOUNT</th>
                                          <th>TDS AMOUNT</th>
                                          <th>TOTAL AMOUNT </th>
                                          <th>CREATED BY</th>
                                        </tr>
                                      </thead>
                                      {Receipt && Receipt.length > 0 ? (
                                        Receipt.map((val, index) => (
                                          <tr
                                            key={index}
                                            className={
                                              index % 2 === 0
                                                ? "row-even"
                                                : "row-odd"
                                            }
                                          >
                                            <td>
                                              <b>{val.FileNo}</b>
                                            </td>

                                            <td>
                                              <a
                                                title="Case Details"
                                                href={`#/view-casedetails/${val.CaseID}`}
                                                className="visuald_case upperCase"
                                                target="_blank"
                                                style={{ cursor: "pointer" }}
                                              >
                                                {val.CaseName}
                                              </a>
                                            </td>

                                            <td>{val.ReceiptNo}</td>
                                            <td>{val.InvoiceNo}</td>
                                            <td>{val.TransactionType}</td>
                                            <td>{val.PaidAmount}</td>
                                            <td>{val.TDSAmount}</td>
                                            <td>{val.TotalAmount}</td>
                                            <td className="nowrap upperCase">
                                              {val.CreatedBy}
                                              <br />
                                              {formatTime(val.CreatedDate)}
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <div>
                                          THERE ARE NO UPCOMING RECEIPT...
                                        </div>
                                      )}
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- Receipt Ends--> */}
                        {/* Dashboard end */}

                        {/*-------- ADD EVENT FORM ---------*/}

                        <div
                          className="modal fade"
                          id="exampleModalLong"
                          tabIndex={-1}
                          role="dialog"
                          aria-labelledby="exampleModalLongTitle"
                          aria-hidden="true"
                          show={show}
                        >
                          <div
                            className="modal-dialog"
                            role="document"
                            style={{ maxWidth: "50%" }}
                          >
                            <div className="modal-content">
                              <div className="add_wrap">
                                <div className="main_heading">
                                  <h2>Add Event</h2>
                                </div>
                              </div>
                              <div className="popup_modal ">
                                <button
                                  type="button"
                                  className="close btn_popup"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">×</span>
                                </button>
                                <div className="dashed_popup">
                                  <form role="form " onSubmit={submitHandler}>
                                    <div className="form_flex">
                                      <div className="form-group">
                                        <label for="uname">
                                          Date <text className="m_star">*</text>
                                        </label>
                                        <input
                                          required
                                          autoComplete="off"
                                          className="name"
                                          type="date"
                                          value={date}
                                          onChange={(e) =>
                                            setDate(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label for="uname">File No.</label>
                                        <text className="m_star">*</text>
                                        <Select
                                          required
                                          className="basic-single"
                                          placeholder="Please Select "
                                          classNamePrefix="select"
                                          onChange={handleSelectChange}
                                          isClearable={true}
                                          isSearchable={true}
                                          name="color"
                                          options={optionsEvent}
                                        />
                                      </div>
                                    </div>
                                    <div className="form_flex">
                                      <div className="form-group">
                                        <div>
                                          <label for="uname">
                                            Is Effective
                                          </label>
                                          <select
                                            value={isEffective}
                                            onChange={(e) =>
                                              setIsEffective(e.target.value)
                                            }
                                          >
                                            <option>--Please Select--</option>
                                            <option value="1">YES</option>
                                            <option value="0">NO</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div>
                                          <label for="uname">Is Upcoming</label>
                                          <select
                                            value={isUpcoming}
                                            onChange={(e) =>
                                              setIsUpcoming(e.target.value)
                                            }
                                          >
                                            <option>--Please Select--</option>
                                            <option value="Upcoming">
                                              UPCOMING
                                            </option>
                                            <option value="Completed">
                                              COMPLETED
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form_flex">
                                      <div className="form-group">
                                        <label for="uname">Next Date</label>
                                        <input
                                          autoComplete="off"
                                          className="name"
                                          type="date"
                                          value={nextDate}
                                          onChange={(e) =>
                                            setNextDate(e.target.value)
                                          }
                                        />
                                      </div>

                                      <div className="form-group">
                                        <label for="uname">Event Details</label>
                                        <input
                                          value={eventDetails}
                                          onChange={(e) =>
                                            setEventDetails(e.target.value)
                                          }
                                          rows={2}
                                          cols={40}
                                          placeholder="Enter your text here..."
                                        />
                                      </div>
                                    </div>
                                    <div className="btn_main">
                                      <button
                                        type="submit"
                                        className="btn_save"
                                      >
                                        Save
                                      </button>
                                      <button
                                        type="button"
                                        className="btn_save btn_cancle"
                                        data-dismiss="modal"
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        )}
      </DocumentTitle>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Hometest;
