import React, { useEffect, useMemo, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useContext } from "react";
import { url } from "../index";
import Select from "react-select";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "jspdf-autotable";
import {
  getClient,
} from "../services/api.service";
import DocumentTitle from "react-document-title";
import Footer from "./Footer";

const AddInvoice = () => {

  // Add Invoice
  const [saveButton, setSaveButton] = useState(true);
  const currentDate = new Date().toISOString().split("T")[0];
  const baseUrl = useContext(url);
  const navigate = useNavigate();
  const [BankID, setBankID] = useState("");
  const [CaseID, setCaseID] = useState("");
  const [invoiceTem, setInvoiceTem] = useState([]);
  const [invoiceTemId, setInvoiceTemId] = useState([]);
  const [Dates, setDates] = useState(currentDate);
  const [remark, setRemark] = useState("");
  const [invoiceTotal, setInvoiceTotal] = useState("0");
  const [Discount, setDiscount] = useState("0");
  const [InvoiceAmount, setInvoiceAmount] = useState("0");
  const [formData1, setFormData1] = useState([{ Description: "", Amount: "" }]);

  const getInvoiceView = async () => {
    const [iTemp] = await Promise.all([
      getClient("Master/ViewInvoiceTemplate"),
      getFileNo(),
      getBank(),
    ]);
    setInvoiceTem(iTemp);
  };

  const handleKeyPress = (e) => {
    const charCode = e.which || e.keyCode;
    if (
      charCode < 48 ||
      charCode > 57 ||
      (charCode !== 8 && e.target.value.length >= 10)
    ) {
      e.preventDefault();
    }
  };

  // ---------- FILTER API ---------

  const CaseB = new FormData();
  const [Bank, setBank] = useState([]); 

  const getBank = async () => {
    CaseB.append("AdminID", localStorage.getItem("id"));
    try {
      const response = await fetch(`${baseUrl}Master/ViewBank`, {
        method: "POST",
        body: CaseB,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].message === "Success") {
        const Mapped = data.result.map((item) => ({
          value: item.Id,
          label: `${item.Beneficiary} / ${item.Bank} / ${item.Branch}`,
        }));
        setBank(Mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /** Get File Name  */
  // By Disha 16-09-23

  const Case = new FormData();
  const [optionsCase, setOptionsCase] = useState([]);

  const getFileNo = async () => {
    Case.append("AdminID", localStorage.getItem("id"));
    Case.append("UserID", localStorage.getItem("uId"));
    
    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: Case,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].message === "Success") {
        const Mapped = data.result.map((item) => ({
          value: item.CaseID,
          label: `${item.FileNo} / ${item.CaseNo} / ${item.CaseName} / ${item.CaseYear}`,
        }));
        setOptionsCase(Mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setCaseID("");
    } else {
      setCaseID(selectedOption.value);
    }
  };
  const handleSelectBank = (selectedOption) => {
    if (selectedOption === null) {
      setBankID("");
    } else {
      setBankID(selectedOption.value);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getInvoiceView();
    }
  }, []);

  const handleDescriptionChange = (index, value) => {
    const updatedFormData = [...formData1];
    updatedFormData[index].Description = value;
    setFormData1(updatedFormData);
  };

  const handleAmountChange = (index, value) => {
    const updatedFormData = [...formData1];
    updatedFormData[index].Amount = value;

    let sum = updatedFormData.reduce(function (prev, current) {
      return prev + +current.Amount;
    }, 0);

    setInvoiceTotal(sum);
    setInvoiceAmount(sum - Discount);
    setFormData1(updatedFormData);
  };

  const addRow = () => {
    setFormData1([...formData1, { Description: "", Amount: "" }]);
  };

  const removeRow = (indexToRemove) => {
    if (formData1.length === 1) {
      return;
    }
    let updatedFormData = formData1.filter(
      (_, index) => index !== indexToRemove
    );

    setInvoiceTotal(invoiceTotal - formData1[indexToRemove].Amount);
    setInvoiceAmount(InvoiceAmount - formData1[indexToRemove].Amount);
    setFormData1(updatedFormData);
  };


  const handelDiscount = () => {
    let sum = formData1.reduce(function (prev, current) {
      return prev + +current.Amount;
    }, 0);

    let dis = sum - Discount;
    setInvoiceAmount(dis);
  };

  useEffect(() => {
    handelDiscount();
  }, [Discount]);

  function clearForm() {
    var fields = document.getElementsByClassName("name");
    for (var i = 0; i < fields.length; i++) {
      fields[i].value = " ";
    }
  }

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setSaveButton(false);
    const formData = new FormData();
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CaseID", CaseID);
    formData.append("BankID", BankID);
    formData.append("InvoiceTemplateID", invoiceTemId);
    formData.append("TotalInvoice", invoiceTotal);
    formData.append("Remark", remark.toUpperCase());
    formData.append("Discount", Discount);
    formData.append("InvoiceAmount", InvoiceAmount);
    formData.append("Date", Dates);
    formData.append("CreatedBy", localStorage.getItem("UserId"));

    try {
      const response = await fetch(`${baseUrl}Invoice/AddInvoice`, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("access_token"),
          Accept: "*/*",
        },
        body: formData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data.result.length && data.result[0].code === "200") {
        const InvoiceID = data.result[0].InvoiceID;
        const rowsData = [];
        formData1.forEach((item) => {
          rowsData.push({
            Description: item.Description,
            Amount: item.Amount,
          });
        });

        try {
     
          for (const row of rowsData) {
            const formData2 = new FormData();
            formData2.append("AdminID", localStorage.getItem("id"));
            formData2.append("InvoiceID", InvoiceID);
            formData2.append("Description", row.Description.toUpperCase());
            formData2.append("Amount", row.Amount);
            formData2.append("CreatedBy", localStorage.getItem("UserId"));
            const response2 = await fetch(
              `${baseUrl}Invoice/AddInvoiceDetail`,
              {
                method: "POST",
                headers: {
                  Authorization: localStorage.getItem("access_token"),
                  Accept: "*/*",
                },
                body: formData2,
              }
            );

            const data2 = await response2.json();
            if (data2 && data2.result.message == "Session Time Expire.") {
              toast.error("Token expired please login again");
              setTimeout(() => {
                navigate("/");
              }, 3000);
            }
            if (data2.result.length && data2.result[0].message === "Success") {
              setCaseID("");
              setInvoiceAmount("");
              setInvoiceTotal("");
              setInvoiceTemId("");
              setRemark("");
              setDates("");
              setDiscount("");
              clearForm();
              toast.success("Data Added !");
              setSaveButton(true);
              setFormData1([{ Description: "", Amount: "" }]);
              setTimeout(() => {
                navigate("/home");
              }, 2000);
            } else {
              toast.error(data2.result.message);
              setSaveButton(true);
            }
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        // Handle error for the first request
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handelDiscount();
  }, [Discount]);

  return (
    <>
      <DocumentTitle title="ADD INVOICE | LEGAL CRM">
   
          <div className="wraper">
            <Header />
            <div className="mainWraper">
              <div className="menuOverlay"></div>
              <Sidebar />

              <div className="pageBody">
                <div className="pageContent">
                  <div className="PageTitle">
                 <h3>Add Invoice</h3>
                  </div>
                  <div>
                    
                        <div className="popup_modal ">
                          <button
                            type="button"
                            className="close btn_popup"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                          <div className="dashed_popup">
                            <form role="form " onSubmit={HandleSubmit}>
                              <div className="form_flex caseMobile">
                                <div className="form-group">
                                  <label for="uname">
                                    File No.
                                    <text className="m_star">*</text>
                                  </label>
                                  <Select
                                    required
                                    className="basic-single"
                                    placeholder="Please Select "
                                    classNamePrefix="select"
                                    onChange={handleSelectChange}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={optionsCase}
                                  />
                                </div>
                                <div className="form-group">
                                  <label for="uname">Invoice Tempalte</label>

                                  <a
                                    className="visuald"
                                    href="/#/view-invoicetemplate"
                                  >
                                    +Add Template
                                  </a>
                                  <select
                                    className="name"
                                    id="city"
                                    onChange={(e) =>
                                      setInvoiceTemId(e.target.value)
                                    }
                                  >
                                    <option value="">
                                      ------ Please Select -------
                                    </option>
                                    {invoiceTem &&
                                      invoiceTem.map((val, index) => {
                                        return (
                                          <option key={index} value={val.Id}>
                                            {val.Name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                                <div className="form-group">
                                  <label for="uname">
                                    Bank
                                    <text className="m_star">*</text>
                                  </label>
                                  <Select
                                    required
                                    className="basic-single"
                                    placeholder="Please Select"
                                    classNamePrefix="select"
                                    onChange={handleSelectBank}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={Bank}
                                  />
                                </div>
                                <div className="form-group">
                                  <label for="uname">
                                    Date
                                    <text className="m_star">*</text>
                                  </label>
                                  <input
                                    required
                                    autoComplete="off"
                                    value={Dates}
                                    className="name"
                                    max={currentDate}
                                    type="date"
                                    onChange={(e) => setDates(e.target.value)}
                                  />
                                </div>
                              </div>

                              <div className="main_heading">
                                <h2>Invoice Details</h2>
                              </div>

                              <div>
                                {formData1 &&
                                  formData1.map((row, index) => (
                                    <div key={index}>
                                      <div className="form_flex caseMobile">
                                        <div className="form-group">
                                          <label
                                            htmlFor={`description-${index}`}
                                          >
                                            Description
                                          </label>
                                          <input
                                            value={row.Description}
                                            type="text"
                                            className="name"
                                            placeholder="Enter Description"
                                            onChange={(e) =>
                                              handleDescriptionChange(
                                                index,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label htmlFor={`amount-${index}`}>
                                            Amount
                                            <text className="m_star">*</text>
                                          </label>
                                          <input
                                            required
                                            onKeyPress={handleKeyPress}
                                            value={row.Amount}
                                            type="text"
                                            className="name"
                                            placeholder="Enter Amount"
                                            onChange={(e) =>
                                              handleAmountChange(
                                                index,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                        {index === 0 && (
                                          <a
                                            onClick={addRow}
                                            style={{
                                              color: "white",
                                              marginTop: "25px",
                                              cursor: "pointer",
                                              margin: "10px",
                                              padding: "6px 10px",
                                              background: "green",
                                            }}
                                        
                                          >
                                            +
                                          </a>
                                        )}

                                        {index !== 0 && (
                                          <a
                                            onClick={() => removeRow(index)}
                                            style={{
                                              color: "white",
                                              marginTop: "25px",
                                              cursor: "pointer",
                                              margin: "10px",
                                              padding: "6px 10px",
                                            }}
                                
                                          >
                                            ❌
                                          </a>
                                        )}
                                      </div>
                                    </div>
                                  ))}

                                <div className="form_flex test">
                                  <div className="form-group">
                                    <label htmlFor="remark">Remark</label>
                                    <textarea
                                      className="name"
                                      type="text"
                                      rows={10}
                                      placeholder="Enter Remark"
                                      name="remark"
                                      value={remark}
                                      onChange={(e) =>
                                        setRemark(e.target.value)
                                      }
                                    />
                                  </div>

                                  <div className="form-group">
                                    <div>
                                      <label htmlFor="discount">
                                        Invoice Total
                                      </label>
                                      <input
                                        readOnly
                                        type="text"
                                        placeholder=""
                                        name="discount"
                                        value={invoiceTotal}
                                      />
                                    </div>

                                    <div>
                                      <label htmlFor="discount">
                                        Discount ₹
                                      </label>
                                      <input
                                        className="name"
                                        type="text"
                                        placeholder=""
                                        name="discount"
                                        value={Discount}
                                        onChange={(e) =>
                                          setDiscount(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div>
                                      <label htmlFor="invoice_amount">
                                        Final Amount
                                      </label>
                                      <input
                                        className="name"
                                        type="text"
                                        name="invoice_amount"
                                        value={InvoiceAmount}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            {
                              saveButton ? (
                                <div className="btn_main caseMobile">
                                <button type="submit" className="btn_save">
                                  Save
                                </button>
                              </div>
                              ) : ""
                            }
                            </form>
                          </div>
                        </div>
                      </div>
                </div>

              </div>
            </div>
            <Footer />
          </div>

      </DocumentTitle>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default AddInvoice;
