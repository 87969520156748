import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const ViewCaseType = createApi({
  reducerPath: "ViewCaseType",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.lawyerhunt.in/api/Master/ViewCaseType",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("access_token");
      if (token) {
        headers.set("Authorization", `${token}`);
        headers.set("Accept", "*/*");
      }
    },
  }),
  endpoints: (builder) => ({
    getViewCaseType: builder.query({
      query: (credentials) => {
        return {
          method: "POST",
          body: credentials,
        };
      },
    }),
  }),
});

export const { useGetViewCaseTypeQuery } = ViewCaseType;
export default ViewCaseType;
