import React, { useState } from "react";
import autoTable from "jspdf-autotable";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { format } from "date-fns";
import { useNavigate } from "react-router";
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  permission,
  withUidApi,
} from "../services/api.service";
import Preloader from "./Preloader";
import DocumentTitle from "react-document-title";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv } from "export-to-csv";
import jsPDF from "jspdf";
import Footer from "./Footer";

const UserAttendance = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  //        -------- TASK VARIABLE ---------

  const baseUrl = useContext(url);
  const navigate = useNavigate();
  const [fFromDate, setFFromDate] = useState("");
  const filterform = new FormData();
  const [fToDate, setFToDate] = useState("");
  const [isView, setIsView] = useState("0");
  const [caseLeadData, setCaseLeadData] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const handleExportButtonClick = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const handleToggleColumn = (columnName) => {
    const currentIndex = selectedColumns.indexOf(columnName);
    const newSelectedColumns = [...selectedColumns];

    if (currentIndex === -1) {
      newSelectedColumns.push(columnName);
    } else {
      newSelectedColumns.splice(currentIndex, 1);
    }

    setSelectedColumns(newSelectedColumns);
  };

  // ---------- FILTER API --------- MANISH PRAJAPAT DATE 06 - 10 - 2023 ---------

  const filterApi = async () => {
    setLoading(true);
    filterform.append("AdminID", localStorage.getItem("id"));
    filterform.append("UserID", localStorage.getItem("uId"));
    filterform.append("From", fFromDate);
    filterform.append("To", fToDate);
    try {
      const response = await fetch(`${baseUrl}Task/ViewTaskfilter`, {
        method: "POST",
        body: filterform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }

      if (
        data &&
        data.result.length &&
        data &&
        data.result[0].message === "Success"
      ) {
   
        setLoading(false);
        setCaseLeadData(data.result);
        setData(data.result);
      } else {
        setCaseLeadData([]);
        setLoading(false);
        setData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterCancel = () => {
    setFFromDate("");
    setFToDate("");
    getCaseView();
  };

  //        -------- VIEW TASK DATA ---------


  const getCaseView = async () => {
    const [view, data] = await Promise.all([
      withUidApi("Task/ViewTask"),
      permission(7),
    ]);

    if (view === null) {
      setLoading(false);
      setCaseLeadData([]);
      setData([]);
    } else {
      if (view === "token") {
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        setLoading(false);
        setCaseLeadData(view);
        setData(view && view);
      }
    }

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }
    setIsView(data && data.result[0].IsView);
};

  //        -------- TABLE ---------

  const columnHelper = createMRTColumnHelper();
  const columns = [
    columnHelper.accessor("Date", {
      header: "Date",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.DeadLine === null ||
            row.row.original.DeadLine === "0000-00-00"
              ? "-"
              : new Date(row.row.original.DeadLine).toLocaleDateString("en-GB")}
          </div>
        );
      },
    }),

    columnHelper.accessor("Absent", {
      header: "Absent / Present",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.FileNo === "0" ? "ABSENT" : "PRESENT"}
          </div>
        );
      },
    }),
  ];

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getCaseView();
    }
  }, []);

  const excelData =
    data &&
    data.map((obj) => {
      const { Id, code, message, Status, AdminID, ...rest } = obj;
      return rest;
    });

  const handleExportData = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });

    const formattedDate = new Date().toISOString().slice(0, 10);
    const filename = `Task_${formattedDate}.csv`;

    const csv = generateCsv(csvConfig)(excelData);
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const columnsToExport = [
      { id: "serialNo", header: "S No." },
      ...columns.filter((column) => selectedColumns.includes(column.id)),
    ];

    const tableHeaders = columnsToExport.map((c) => c.header);

    const formattedDate = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const filename = `Task_${formattedDate}.pdf`;
    const addWatermarkToFirstPage = () => {
      const watermarkHeight = 20;

      // Calculate positioning to center the watermark on each page

      doc.setFontSize(10);
      doc.text(
        10,
        watermarkHeight + -10,
        `Generated by : ${localStorage.getItem("name")} `
      );
      doc.text(172, watermarkHeight + -10, `Date: ${formattedDate}`);
      doc.setFontSize(16);
      doc.text("Task Table", 84, 20);
    };
    const watermarkHeight = 20;

    addWatermarkToFirstPage();
    const tableData = rows.map((row, index) => {
      const formattedDateInPdf = row.original["DeadLine"]
        ? format(new Date(row.original["DeadLine"]), "dd/MM/yyyy")
        : "";

      return [
        { content: (index + 1).toString(), styles: { fontStyle: "bold" } },
        ...columnsToExport.slice(1).map((column) => {

          if (column.id === "Date") {
            return formattedDateInPdf;
          }

          const value =
            column.id in row.original ? row.original[column.id] : "";
          return value !== null && value !== undefined ? value : "";
        }),
      ];
    });

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY: watermarkHeight + 5,
    });
    const pdfBlob = doc.output("blob");
    const blobUrl = URL.createObjectURL(pdfBlob);
    window.open(blobUrl, "_blank");
    URL.revokeObjectURL(blobUrl);
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    enableStickyHeader: true,
    enableRowNumbers: true,
    state: {
      isLoading: loading,
    },
    muiCircularProgressProps: {
      color: "primary",
      thickness: 5,
      size: 55,
      style: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000,
      },
    },
    muiSkeletonProps: {
      animation: "pulse",
      height: 20,
    },
    muiPaginationProps: {
      rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export Excel
        </Button>

        <Button
        disabled={!table || table.getPrePaginationRowModel().rows.length === 0}
        onClick={handleExportButtonClick}
        startIcon={<FileDownloadIcon />}
      >
        Export PDF
      </Button>
      <Dialog open={open} onClose={handleClose1}>
        <DialogTitle>Select Columns To Export</DialogTitle>
        <DialogContent>
            {columns.map((column) =>
              column.header !== "Action" ? (
                <div key={column.id}>
                  <Checkbox
                    checked={selectedColumns.includes(column.id)}
                    onChange={() => handleToggleColumn(column.id)}
                  />
                  {column.header}
                </div>
              ) : (
                ""
              )
            )}
          </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1}>Cancel</Button>
          <Button onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}>Export</Button>
        </DialogActions>
      </Dialog>
      </Box>
    ),
  });
  return (
    <>
      <DocumentTitle title="TASK | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  <div className="pageContent">
                    <div className="PageTitle">
                     <h3>Attendance</h3>
                    </div>

                    <div
                      className="filter_type add_wrap"
                      style={{
                        display: isView === "0" ? "none" : "block",
                      }}
                    >
                      <div className="form_flex1 flex_wrap  caseMobile">
                        <div className="form-group1">
                          <label>From Date</label>
                          <input
                            // max={currentDate}
                            autoComplete="off"
                            className="name"
                            type="date"
                            value={fFromDate}
                            onChange={(e) => setFFromDate(e.target.value)}
                          />
                        </div>
                        <div className="form-group1">
                          <label>To Date</label>
                          <input
                            autoComplete="off"
                            className="name"
                            type="date"
                            value={fToDate}
                            onChange={(e) => setFToDate(e.target.value)}
                          />
                        </div>
                        <div className="form-group1 caseMobile btnGroup">
                          <div className="btn_main casebtn">
                            <button
                              type="submit"
                              className="btn btn_client"
                              onClick={filterApi}
                            >
                              Search
                            </button>
                            <button
                              type="button"
                              className="btn btn_client"
                              onClick={filterCancel}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: isView === "0" ? "none" : "block",
                      }}
                    >
                      <MaterialReactTable table={table} />
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>

          </div>
        )}
      </DocumentTitle>
    </>
  );
};

export default UserAttendance;
