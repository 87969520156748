import React from "react";

const Footer = () => {
  return (
    <div className="myFooter">
      <div className="mcs_main_wrap mcs_footer_wrap">
        <div className="container">
          <div className="row">
            <div>
              <div className="mcs_copyrigth_text">
                <p>
                  Copyright <a>@LegalCRM</a>
                </p>
              </div>
              <div>
                <a
                  href="/#/view-terms-and-condition"
                  style={{
                    textDecoration: "none",
                    margin: "5px",
                    color: "white",
                  }}
                >
                  Terms and Conditions
                </a>

                <a
                  href="/#/view-privacy-policy"
                  style={{
                    textDecoration: "none",
                    margin: "5px",
                    color: "white",
                  }}
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
