import React, { useState, useRef } from "react";
import autoTable from "jspdf-autotable";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { CSVLink } from "react-csv";
import { SiMicrosoftexcel } from "react-icons/si";
import Swal from "sweetalert";
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";
import {
  deleteUser,
  userDetails,
  activeStatus,
  permission,
  getFileNo,
  customStyles,
  getClient,
  clearForm,
  withUidApi,
  formatDate1,
  formateDateTime,
} from "../services/api.service";
import Select from "react-select";
import DocumentTitle from "react-document-title";
import Preloader from "./Preloader";
import { format } from "date-fns";
import jsPDF from "jspdf";
import Footer from "./Footer";

const SettleAmount = () => {
  //        -------- POPUP CLOSE VARIABLE ---------

  const currentDate = new Date().toISOString().split("T")[0];
  const closeButton = document.querySelector(".btn_popup");
  const closeView = document.querySelector(".ab");
  const saveData = document.querySelector(".saveData");
  const closeDelete = document.querySelector(".mm");
  const [FileNo, setFileNo] = useState([]);
  const [estimateAmount, setEstimateAmount] = useState("");
  const [Discount, setDiscount] = useState(""); 
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState("0"); 
  const [totalAmount, setTotalAmount] = useState("0"); 


  //        -------- VARIABLE ---------

  const [userData, setUserData] = useState([]);
  const [Invoice1, setInvoice1] = useState([]);
  const [InvoiceID, setInvoiceID] = useState("");
  const [formData2, setFormData2] = useState([]);
  const baseUrl = useContext(url);
  const navigate = useNavigate();
  const [CaseID, setCaseID] = useState("");
  const [Dates, setDates] = useState(currentDate);
  const [PaymentMode, setPaymentMode] = useState("");
  const [CounselID, setCounselID] = useState("");
  const [CocounselID, setCocounselID] = useState("");
  const [CounselShare, setCounselShare] = useState("");
  const [CoCounselShare, setCoCounselShare] = useState("");
  const [CounselName, setCounselName] = useState("");
  const [CoCounselName, setCoCounselName] = useState("");
  const [remark, setRemark] = useState("");
  const [ReceivedAmount, setReceivedAmount] = useState("0");
  const [receiptID, setReceiptID] = useState("");
  const [coCounselAmount, setCoCounselAmount] = useState("");
  const [counselAmount, setCounselAmount] = useState("");
  const [receiptData, setReceiptData] = useState([]);
  const [counselData, setCounselData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [companyPer, setCompanyPer] = useState("");
  const [remaining, setRemaining] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  //        --------  EDIT  VARIABLE ---------

  const [EditCaseID, setEditCaseID] = useState("");
  const [EditDate, setEditDate] = useState("");
  const [EditIsFinal, setEditIsFinal] = useState("");
  const [detailData, setDetailData] = useState([]);

  //        --------  View  VARIABLE ---------

  const [dCaseID, setDCaseID] = useState("");
  const [dDate, setDDate] = useState("");
  const [dRemark, setDRemark] = useState("");
  const [dAddedBy, setDAddedBy] = useState("");
  const [dTotalAmount, setDTotalAmount] = useState("");
  const [dCaseYear, setDCaseYear] = useState("");
  const [dCounselShare, setDCounselShare] = useState("");
  const [dCoCounselShare, setDCoCounselShare] = useState("");
  const [dCocounselAmount, setDCocounselAmount] = useState("");
  const [dCounselAmount, setDCounselAmount] = useState("");
  const [dCounselName, setDCounselName] = useState([]);
  const [dCoCounselName, setDCoCounselName] = useState("");
  const [dPaymentMode, setDPaymentMode] = useState("");
  const [dInvoiceNo, setDInvoiceNo] = useState("");
  const [dReceiptNo, setDReceiptNo] = useState("");

  const [EditID, setEditID] = useState("");
  const [isAdd, setIsAdd] = useState("0");
  const [editDiscount, setEditDiscount] = useState("");
  const [editRemark, setEditRemark] = useState("");
  const [editEstimateTotal, setEditEstimateTotal] = useState("");
  const [editEstimateAmount, setEditEstimateAmount] = useState("");
  const [isEdit, setIsEdit] = useState("0");
  const [isView, setIsView] = useState("0");
  const [isDelete, setIsDelete] = useState("0");
  const [isStatus, setIsStatus] = useState("0");
  const [settleAmountData, setsettleAmountData] = useState([]);
  const [filterUser, setFilterUser] = useState("");
  const [delId, setDelId] = useState("");
  const [active, setActive] = useState(false);
  const formData = new FormData();
  const userDetailsForm = new FormData();
  const formEditData = new FormData();

  const [open, setOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const handleExportButtonClick = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const handleToggleColumn = (columnName) => {
    const currentIndex = selectedColumns.indexOf(columnName);
    const newSelectedColumns = [...selectedColumns];

    if (currentIndex === -1) {
      newSelectedColumns.push(columnName);
    } else {
      newSelectedColumns.splice(currentIndex, 1);
    }

    setSelectedColumns(newSelectedColumns);
  };

  // Excel
  const [excel, setExcel] = useState([]);
  const removekey = () => {
    if (settleAmountData && settleAmountData.length > 0) {
      const excelData =
        settleAmountData &&
        settleAmountData.map((obj) => {
          const { Id, code, message, Status, AdminID, ...rest } = obj;
          return rest;
        });
      setExcel(excelData);
    } else {
      toast.error("Data Not Found");
    }
  };

  // ----------- VIEW Client---------
  const ViewData = new FormData();
  const [options, setOptions] = useState([]); // Initialize options state

  const getFileNo = async () => {
    ViewData.append("AdminID", localStorage.getItem("id"));
    ViewData.append("UserID", localStorage.getItem("uId"));

    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: ViewData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }

      if (data && data.result[0].message === "Success") {
        const filterData = data.result.filter(
          (item) => item.CoCounselID !== "0" && item.CounselID !== "0"
        );

        const Mapped = filterData.map((item) => ({
          value: item.CaseID,
          label: `${item.FileNo} / ${item.CaseNo} / ${item.CaseName} / ${item.CaseYear}`,
        }));
        setOptions(Mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        -------- VIEW TASK DATA ---------

  const getSettleAmount = async () => {
    const [view, data, user, fileData] = await Promise.all([
      withUidApi("SettleAmount/ViewSettleAmount"),
      permission(24),
      getClient("Admin/ViewUser"),
      getFileNo(),
      setLoading(true),
    ]);

    if (view === null) {
      setLoading(false);
      setTotalInvoiceAmount("0");
      setTotalAmount("0")
    } else {
      if (view === "token") {
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        setLoading(false);
        setsettleAmountData(view);
        setData(view);

        
        let sum = view && view.reduce(function (prev, current) {
          return prev + +current.CounselAmount;
        }, 0);
        setTotalInvoiceAmount(sum);
      

      let TotalAmount = view && view.reduce(function (prev, current) {
        return prev + +current.TotalAmount;
      }, 0);
      setTotalAmount(TotalAmount);
      }
    
    }

    setFileNo(fileData);
    setUserData(user);

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }

    setIsAdd(data && data.result[0].IsAdd);
    setIsEdit(data && data.result[0].IsEdit);
    setIsView(data && data.result[0].IsView);
    setIsStatus(data && data.result[0].IsStatus);
    setIsDelete(data && data.result[0].IsDelete);
  };

  //        -------- DELETE TASK API ---------

  const deleteCaseLead = async () => {
    const data = await deleteUser(delId, "SettleAmount/DeleteSettleAmount");
    if (data === "token") {
      setTimeout(() => {
        closeDelete.click();
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      closeDelete.click();
      getSettleAmount();
    }
  };

  //        -------- ACTIVE DEACTIVE ---------

  const deActive = async (row) => {
    const data = await activeStatus(row, "SettleAmount/SettleAmountStatus");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      getSettleAmount();
    }
  };

  //        -------- TASK DETAILS DATA ---------

  const details = async (id) => {
    userDetailsForm.append("AdminID", `${localStorage.getItem("id")}`);
    userDetailsForm.append("ID", id);
    try {
      const response = await fetch(
        `${baseUrl}SettleAmount/ViewSettleAmountById`,
        {
          method: "POST",
          body: userDetailsForm,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        }
      );
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }

      if (data && data.result[0].message == "Success") {
        setDCaseID(data && data.result[0].CaseName);
        setDDate(data && data.result[0].Date);
        setDInvoiceNo(data && data.result[0].InvoiceNo);
        setDRemark(data && data.result[0].Remark);
        setDReceiptNo(data && data.result[0].ReceiptNo);
        setDPaymentMode(data && data.result[0].PaymentMode);
        setDCounselName(data && data.result);
        setDAddedBy(data && data.result[0].AddedBy);
        setDTotalAmount(data && data.result[0].TotalAmount);
        setDCaseYear(data && data.result[0].CaseYear);
      } else {
        toast.error(data.result[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        -------- Viwe by ID DATA --------

  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  //   Invoice

  const formInvoice = new FormData();
  const formReceipt = new FormData();
  const detailsForm = new FormData();

  const counselApi = async (id) => {
    detailsForm.append("AdminID", `${localStorage.getItem("id")}`);
    detailsForm.append("ID", id);
    try {
      const response = await fetch(`${baseUrl}Cases/ViewCaseByIdOLD`, {
        method: "POST",
        body: detailsForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message === "Session Time Expire.") {
        toast.error("Token expired please login again");
        return "token";
      }

      if (data && data.result.Case[0].message === "Success") {
        setCounselData(data && data.result.CaseDetail);
        setCompanyPer(data && data.result.CaseDetail[0].CompanyPer);
      } else {
        toast.error(data && data.result.Case[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getInvoice = async (id) => {
    counselApi(id);
    formInvoice.append("AdminID", localStorage.getItem("id"));
    formInvoice.append("CaseID", id);
    try {
      const response = await fetch(`${baseUrl}SettleAmount/ViewInvoiceByCase`, {
        method: "POST",
        body: formInvoice,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }

      if (data && data.result[0].message === "Success") {
        setInvoice1(data.result);
        setCounselID(data && data.result[0].CounselID);
        setCocounselID(data && data.result[0].CoCounselID);
        setCounselShare(Math.floor(data && data.result[0].CounselShare));
        setCoCounselShare(data && data.result[0].CoCounselShare);
      } else {
        setInvoice1([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getReceipt = async (id) => {
    formReceipt.append("AdminID", localStorage.getItem("id"));
    formReceipt.append("CaseID", CaseID);
    formReceipt.append("InvoiceID", id);

    try {
      const response = await fetch(
        `${baseUrl}SettleAmount/ViewReceipyByInvoice`,
        {
          method: "POST",
          body: formReceipt,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        }
      );
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].message === "Success") {
        setReceiptData(data.result);
      } else {
        setReceiptData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const receiptApi = async (id) => {
    const data = await userDetails(id, "Receipt/ViewReceiptById");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } else {
      setCounselName(
        data && data.result[0].CounselName === null
          ? "-"
          : data.result[0].CounselName
      );
      setCoCounselName(
        data && data.result[0].CoCounselName === null
          ? "-"
          : data.result[0].CoCounselName
      );

      setReceivedAmount(Math.floor(data && data.result[0].PaidAmount));
      const paid = Math.floor(data && data.result[0].PaidAmount);

      const percentage = 100 - companyPer;
      setRemaining((percentage / 100) * paid);
    }
  };

  //        -------- SUBMIT DATA ---------

  const dataSave = async () => {
    const amountData =
      updateData &&
      updateData.map((coCounsel) =>
        coCounsel.amount === "" || coCounsel.amount === "0"
          ? "0"
          : coCounsel.amount
      );

    const counselId =
      updateData && updateData.map((coCounsel) => coCounsel.CounselID);

    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CaseID", CaseID);
    formData.append("CounselID", counselId);
    formData.append("CounselAmount", amountData);
    formData.append("Date", Dates);
    formData.append("InvoiceID", InvoiceID);
    formData.append("TotalAmount", ReceivedAmount);
    formData.append("ReceiptID", receiptID);
    formData.append("Remark", remark.toUpperCase());
    formData.append("PaymentMode", PaymentMode);
    formData.append("CreatedBy", localStorage.getItem("UserId"));

    try {
      const response = await fetch(`${baseUrl}SettleAmount/AddSettleAmount`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].code === "200") {
        toast.success("SettleAmount added successfully");
        handleClose();
        getFileNo();
        getSettleAmount();
        clearForm();
        setCaseID("");
        setDates(currentDate);
        setPaymentMode("");
        setCounselAmount("");
        setCoCounselAmount("");
        setCounselName("");
        setCoCounselName("");
        setInvoice1([]);
        setReceiptData([]);
        setUpdateData([]);
        setCounselID("");
        setCocounselID("");
        setRemark("");
        setReceivedAmount("0");
        setReceiptID("");
        setInvoiceID("");
        setCounselShare("");
        setCoCounselAmount("");
        closeButton.click();
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();

    Swal({
      title: "Warning Message !",
      text: "Once you add data, you cannot edit it.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        Swal("Done", {
          icon: "success",
        });
        dataSave();
      } else {
        Swal("Settle Amount Is Not Add !");
      }
    });
  };

  //        -------- TABLE ---------

  const columnHelper = createMRTColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      header: "Action",
      // minSize: "10px",
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      Cell: (row) => {
        return (
          <>
            <div style={{ display: "flex", gap: "15px" }}>
              <span
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongDetail"
                onClick={() => details(row.row.original.Id)}
              >
                <img
                  src="assets/img/icons/eye.svg"
                  alt="Details"
                  title="Details"
                />
              </span>
              <span>
                <a
                  style={{
                    cursor: "pointer",
                    display: isStatus === "0" ? "none" : "block",
                  }}
                  onClick={() => deActive(row.row.original)}
                >
                  {row.row.original.Status === "1" ? (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Up.svg"
                      title="Status"
                    />
                  ) : (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Down.svg"
                      title="Status"
                    />
                  )}
                </a>
              </span>
              <span
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongDetail"
                onClick={() => details(row.row.original.Id)}
              >
                <img
                  src="assets/img/icons/send.svg"
                  alt="Email"
                  title="Send Notification"
                />
              </span>
              {isDelete !== "0" && (
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style={{ cursor: "pointer" }}
                  onClick={() => setDelId(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/delete.svg"
                    alt="Delete"
                    title="Delete"
                  />
                </span>
              )}
            </div>
          </>
        );
      },
    }),

    columnHelper.accessor("Date", {
      header: "Date",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.Date === null || row.row.original.Date === "0000-00-00"
              ? "-"
              : new Date(row.row.original.Date).toLocaleDateString("en-GB")}
          </div>
        );
      },
    }),

    columnHelper.accessor("FileNo", {
      header: "File No",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.FileNo === null || row.row.original.FileNo === ""
              ? "-"
              : row.row.original.FileNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseNo", {
      header: "Case No",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CaseNo === null || row.row.original.CaseNo === ""
              ? "-"
              : row.row.original.CaseNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseName", {
      header: "Case Name",
      Cell: (row) => {
        return (
          <div style={{ minWidth: "350px" }} className="upperCase">
            <a title="Case Details"
              href={`#/view-casedetails/${row.row.original.CaseID}`}
              className="visuald_case"
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              {row.row.original.CaseName === null ||
              row.row.original.CaseName === ""
                ? "-"
                : row.row.original.CaseName}
            </a>
          </div>
        );
      },
    }),
    columnHelper.accessor("InvoiceNo", {
      header: "Invoice No",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.InvoiceNo === null ||
            row.row.original.InvoiceNo === ""
              ? "-"
              : row.row.original.InvoiceNo}
          </div>
        );
      },
    }),
    columnHelper.accessor("ReceiptNo", {
      header: "Receipt No",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.ReceiptNo === null ||
            row.row.original.ReceiptNo === ""
              ? "-"
              : row.row.original.ReceiptNo}
          </div>
        );
      },
    }),
    columnHelper.accessor("Counsel", {
      header: "Counsel",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Counsel === null ||
            row.row.original.Counsel === ""
              ? "-"
              : row.row.original.Counsel}
          </div>
        );
      },
    }),
    columnHelper.accessor("CounselAmount", {
      header: "Counsel Amount",
      footer: `${totalInvoiceAmount} Rs.`,
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CounselAmount === null ||
            row.row.original.CounselAmount === ""
              ? "-"
              : row.row.original.CounselAmount}
          </div>
        );
      },
    }),
    columnHelper.accessor("TotalAmount", {
      header: "Total Amount",
      footer: `${totalAmount} Rs.`,
      Cell: (row) => {
        return (
          <div>
            {row.row.original.TotalAmount === null ||
            row.row.original.TotalAmount === ""
              ? "-"
              : row.row.original.TotalAmount}
          </div>
        );
      },
    }),

    columnHelper.accessor("ClientName", {
      header: "Client",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.ClientName === null ||
            row.row.original.ClientName === ""
              ? "-"
              : row.row.original.ClientName}
<br/>
            {row.row.original.MobileNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("Status", {
      header: "Status",
      Cell: (row) => {
        return <div>{row.row.original.Status === "1" ? "ACTIVE" : "DEACTIVE"}</div>;
      },
    }),

    columnHelper.accessor("AddedBy", {
      header: "Added By",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.AddedBy === null ||
            row.row.original.AddedBy === ""
              ? "-"
              : row.row.original.AddedBy}<br/>
                   {formateDateTime(row.row.original.CreatedDate)}
          </div>
        );
      },
    }),
  ];

  const handleCounselAmount = (index, value) => {
    const updatedFormData = [...updateData];

    updatedFormData[index].amount = value;

    let sum = updatedFormData.reduce(function (prev, current) {
      return prev + +current.amount;
    }, 0);

    if (sum <= remaining) {
      setUpdateData(updatedFormData);
    } else {
      alert(
        `Company Percentage Is ${companyPer} And Remaining Amount Is ${remaining}`
      );
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setInvoice1([]);
      setCounselData([]);
      setReceiptData([]);
      setReceivedAmount("0");
      setCounselName("");
      setCoCounselName("");
      setCounselAmount("");
      setCoCounselAmount("");
    } else {
      setCaseID(selectedOption.value);
      getInvoice(selectedOption.value);
    }
  };

  const filterCancel = () => {
    clearForm();
    setCaseID("");
  };

  useEffect(() => {
    if (InvoiceID === null || InvoiceID === "") {
      setReceiptData([]);
      setReceivedAmount("0");
      setCounselName("");
      setCoCounselName("");
      setCounselAmount("");
      setCoCounselAmount("");
    } else {
      getReceipt(InvoiceID);
    }
  }, [InvoiceID]);

  useEffect(() => {
    if (receiptID === null || receiptID === "") {
      setReceivedAmount("0");
      setCounselName("");
      setCoCounselName("");
      setCounselAmount("");
      setCoCounselAmount("");
    } else {
      receiptApi(receiptID);
    }
  }, [receiptID]);

  useEffect(() => {
    const data =
      updateData &&
      updateData.map((val, index) => {
        return { ...val, amount: (val.CounselPer / 100) * ReceivedAmount };
      });
    setUpdateData(data);
  }, [ReceivedAmount]);

  useEffect(() => {
    const data =
      counselData &&
      counselData.map((val, index) => {
        return { ...val, amount: (val.CounselPer / 100) * ReceivedAmount };
      });

    setUpdateData(data);
  }, [counselData]);

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getSettleAmount();
    }
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  // ---------DataTable-----

  const excelData =
    data &&
    data.map((obj) => {
      const { Id, code, message, Status, AdminID, ...rest } = obj;
      return rest;
    });

  const handleExportData = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });

    const formattedDate = new Date().toISOString().slice(0, 10); // Get today's date
    const filename = `Amount|LegalCRM_${formattedDate}.csv`;

    const csv = generateCsv(csvConfig)(excelData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const columnsToExport = [
      { id: "serialNo", header: "S No." },
      ...columns.filter((column) => selectedColumns.includes(column.id)),
    ];

    const tableHeaders = columnsToExport.map((c) => c.header);

    const formattedDate = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const filename = `Amount|LegalCRM_${formattedDate}.pdf`;
    const addWatermarkToFirstPage = () => {
      const watermarkHeight = 20;

      // Calculate positioning to center the watermark on each page

      doc.setFontSize(10);
      doc.text(
        10,
        watermarkHeight + -10,
        `Generated by : ${localStorage.getItem("name")} `
      );
      doc.text(172, watermarkHeight + -10, `Date: ${formattedDate}`);
      doc.setFontSize(16);
      doc.text("Amount Table", 84, 20);
      };
      
    const watermarkHeight = 20;

    addWatermarkToFirstPage();
    const tableData = rows.map((row, index) => {
      const formattedDateInPdf = row.original["Date"]
        ? format(new Date(row.original["Date"]), "dd/MM/yyyy")
        : "";

      return [
        { content: (index + 1).toString(), styles: { fontStyle: "bold" } },
        ...columnsToExport.slice(1).map((column) => {
          if (column.id === "Date") {
            return formattedDateInPdf;
          }

          const value =
            column.id in row.original ? row.original[column.id] : "";
          return value !== null && value !== undefined ? value : "";
        }),
      ];
    });

    tableData.push([]);
    const extraRow = ["", "", "", "", "", "Total", `${totalInvoiceAmount}/-`, `${totalAmount}/-`];
    tableData.push(extraRow);

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY: watermarkHeight + 5,
    });

    const pdfBlob = doc.output("blob");

    // Create a blob URL
    const blobUrl = URL.createObjectURL(pdfBlob);

    // Open the URL in a new tab
    window.open(blobUrl, "_blank");

    // Optionally revoke the object URL after opening the new tab
    URL.revokeObjectURL(blobUrl);
    // doc.save(filename);
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    initialState: {
      columnVisibility: {
        Status: false,
        AddedBy: false,
      
        CreatedDate: false,
      },
    },
    state: {
      isLoading: loading,
    },
    muiCircularProgressProps: {
      color: "primary",
      thickness: 5,
      size: 55,
      style: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000,
      },
    },
    muiSkeletonProps: {
      animation: "pulse",
      height: 20,
    },
    enableStickyHeader: true,
    enableRowNumbers: true,
    muiPaginationProps: {
      rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
            disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export Excel
        </Button>

        <Button
        disabled={!table || table.getPrePaginationRowModel().rows.length === 0}
        onClick={handleExportButtonClick}
        startIcon={<FileDownloadIcon />}
      >
        Export PDF
      </Button>
      <Dialog open={open} onClose={handleClose1}>
        <DialogTitle>Select Columns To Export</DialogTitle>
        <DialogContent>
            {columns.map((column) =>
              column.header !== "Action" ? (
                <div key={column.id}>
                  <Checkbox
                    checked={selectedColumns.includes(column.id)}
                    onChange={() => handleToggleColumn(column.id)}
                  />
                  {column.header}
                </div>
              ) : (
                ""
              )
            )}
          </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1}>Cancel</Button>
          <Button onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}>Export</Button>
        </DialogActions>
      </Dialog>
      </Box>
    ),
  });

  return (
    <>
      <DocumentTitle title="SETTLE AMOUNT | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div className="wraper">
            <Header />
            <div className="mainWraper">
              <Sidebar />
              <div className="pageBody">
                {/* <div className="accountContainer userListContainer"> */}
                <div className="pageContent">
                  <div className="PageTitle">
                    <h3>Settle Amount List</h3>

                    <a
                      style={{
                        display: isAdd === "0" ? "none" : "block",
                      }}
                      type="button"
                      className="btn btn-primary btn_client"
                      data-toggle="modal"
                      data-target="#exampleModalLong"
                    >
                      Add Settle Amount
                      <img src="assets/img/icons/add.svg" />
                    </a>
                  </div>

                  <div
                    style={{
                      display: isView === "0" ? "none" : "block",
                    }}
                    className="searDesign"
                  >
                    <MaterialReactTable table={table} />
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
            <Footer/>
          </div>
        )}
      </DocumentTitle>
      {/*-------- DELETE FORM -------*/}

      <div
        className="modal fade sc_modal"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close mm"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <h4>Delete Row</h4>
              <p>Are you sure you want to delete this SettleAmount ?</p>
              <ul>
                <li>
                  <a className="btn btn-secondary" data-bs-dismiss="modal">
                    Cancel
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-danger"
                    onClick={() => deleteCaseLead(delId)}
                  >
                    Delete
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/*-------- ADD FORM ---------*/}

      <div
        className="modal fade"
        id="exampleModalLong"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
        show={show}
      >
        <div
          className="modal-dialog modal-xl"
          role="document"
          // style={{ maxWidth: "70%" }}
        >
          <div className="modal-content">
            <div className="add_wrap">
              <div className="main_heading">
                <h2>Add Settle Amount </h2>
              </div>
            </div>
            <div className="popup_modal ">
              <button
                type="button"
                className="close btn_popup"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="dashed_popup">
                <form role="form" onSubmit={submitHandler}>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="date">
                        Date <text className="m_star">*</text>
                      </label>
                      <input
                        className="name"
                        type="date"
                        id="date"
                        value={Dates}
                        onChange={(e) => setDates(e.target.value)}
                        max={currentDate}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">
                        File No.<text className="m_star">*</text>
                      </label>
                      <Select
                        className="basic-single name"
                        placeholder="Please Select "
                        classNamePrefix="select"
                        onChange={handleSelectChange}
                        isClearable={true}
                        isSearchable={true}
                        name="color"
                        options={options}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="invoice">
                        Invoice <text className="m_star">*</text>
                      </label>
                      <select
                        className="name"
                        id="cars"
                        onChange={(e) => setInvoiceID(e.target.value)}
                        required
                      >
                        <option value="">--Please Select--</option>
                        {Invoice1 && Invoice1.length ? (
                          Invoice1.map((val, index) => {
                            return (
                              <option key={index} value={val.InvoiceID}>
                                {` Invoice No. - ${val.InvoiceNo} / Amount - ${val.InvoiceAmount}`}
                              </option>
                            );
                          })
                        ) : (
                          <option value="">Loading...</option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="Receipt">
                        Receipt <text className="m_star">*</text>
                      </label>

                      <select
                        className="name"
                        id="cars"
                        onChange={(e) => setReceiptID(e.target.value)}
                        required
                      >
                        <option value="">--Please Select--</option>
                        {receiptData && receiptData.length ? (
                          receiptData.map((val, index) => {
                            return (
                              <option key={index} value={val.Id}>
                                {` Receipt No - ${val.ReceiptNo} / Paid - ${val.PaidAmount} / Total Amount - ${val.TotalAmount}`}
                              </option>
                            );
                          })
                        ) : (
                          <option value="">Loading...</option>
                        )}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="uname">Received Amount</label>
                      <input
                        readOnly
                        type="text"
                        className="name"
                        value={ReceivedAmount}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="uname">Payment Type</label>
                      <select
                        className="name"
                        value={PaymentMode}
                        onChange={(e) => setPaymentMode(e.target.value)}
                      >
                        <option value="">----- Please select -----</option>
                        <option value="CASH">CASH</option>
                        <option value="ONLINE">ONLINE</option>
                        <option value="CHEQUE">CHEQUE</option>
                      </select>
                    </div>
                  </div>
                  <hr />
                  {updateData &&
                    updateData.map((val, index) => {
                      return (
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              {val.CounselType === "1"
                                ? "Counsel Name"
                                : "Co Counsel Name"}
              
                            </label>
                            <input
                              className="name"
                              readOnly
                              type="text"
                              placeholder=""
                              value={val.counsel}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              {val.CounselType === "1"
                                ? "Counsel ( % )"
                                : "Co Counsel ( % )"}
         
                            </label>
                            <input
                              className="name"
                              type="text"
                              placeholder="%"
                              value={`${val.CounselPer} %`}
                              readOnly
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              {val.CounselType === "1"
                                ? "Counsel Amount"
                                : "Co Counsel Amount"}
              
                            </label>
                            <input
                              className="name"
                              type="text"
                              placeholder="0"
                              value={val.amount}
                              onChange={(e) =>
                                handleCounselAmount(index, e.target.value)
                              }
                            />
                          </div>
                        </div>
                      );
                    })}

                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="uname">Remark</label>
                      <textarea
                        type="text"
                        className="name"
                        placeholder="Enter Remark"
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="btn_main caseMobile">
                    <button type="submit" className="btn_save">
                      Save
                    </button>

                    <button
                      type="button"
                      data-dismiss="modal"
                      className="btn_save btn_cancle"
                      onClick={filterCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*-------- DETAILS ---------*/}

      <div
        className="modal fade"
        id="exampleModalLongDetail"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg"
          role="document"
          // style={{ maxWidth: "50%" }}
        >
          <div className="modal-content">
            <div className="add_wrap">
              <div className="main_heading">
                <h2>Settle Amount Details</h2>
              </div>
            </div>
            <div className="popup_modal ">
              <button
                type="button"
                className="close ab btn_popup"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="dashed_popup">
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>Invoice No. &nbsp;: &nbsp; {dInvoiceNo} </h4>
                  </div>
                  <div className="form-group">
                    <h4>Receipt No. &nbsp;: &nbsp; {dReceiptNo} </h4>
                  </div>
                </div>

                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>Added By &nbsp;: &nbsp; {dAddedBy} </h4>
                  </div>
                  <div className="form-group">
                    <h4>
                      Date &nbsp;: &nbsp; {dDate === "0000-00-00" ? "-" : formatDate1(dDate)}{" "}
                    </h4>
                  </div>
                </div>

                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>Total Amount &nbsp;: &nbsp; {dTotalAmount} </h4>
                  </div>

                  <div className="form-group">
                    <h4>
                      Payment Mode &nbsp;: &nbsp;{" "}
                      {dPaymentMode === "" ? "-" : dPaymentMode}
                    </h4>
                  </div>
                </div>

                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>Case Year &nbsp;: &nbsp; {dCaseYear} </h4>
                  </div>

                  <div className="form-group">
                    <h4>Case Name &nbsp;: &nbsp; {dCaseID} </h4>
                  </div>
                </div>

                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>
                      Remark &nbsp;: &nbsp; {dRemark === "" ? "-" : dRemark}{" "}
                    </h4>
                  </div>
                </div>

                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <table className="table detail_table">
                      <tr>
                        <th>S. No</th>
                        <th>Counsel Name</th>
                        <th>Counsel Amount</th>
                      </tr>

                      {dCounselName &&
                        dCounselName.map((val, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{val.Counsel}</td>
                              <td>{val.CounselAmount} </td>
                            </tr>
                          );
                        })}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*-------- TOASTER ---------*/}

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default SettleAmount;
