import React from "react";
import ReactDOM from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./index.css";
import App from "./App";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Store from "./Reduxstor/Reduxstor";
import { createContext } from "react";
import ErrorBoundary from "./ErrorBoundary";

export const url = createContext();

const baseUrl = "https://api.lawyerhunt.in/api/";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HashRouter>
      <ErrorBoundary>
        <Provider store={Store}>
          <url.Provider value={baseUrl}>
            <GoogleOAuthProvider clientId="751271004213-jmndtf4iqh94ekt7n4li3od337bsu5ah.apps.googleusercontent.com">
              <App />
            </GoogleOAuthProvider>
          </url.Provider>
        </Provider>
      </ErrorBoundary>
    </HashRouter>
  </React.StrictMode>
);
