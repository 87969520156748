import React, { useState, useRef } from "react";
import autoTable from "jspdf-autotable";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { formatDate1, formateDateTime, withUidApi } from "../services/api.service";
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Select from "react-select";
import striptags from "striptags";
import html2pdf from "html2pdf.js";
import {
  deleteUser,
  getClient,
  userDetails,
  activeStatus,
  permission,
} from "../services/api.service";
import Preloader from "./Preloader";
import "react-quill/dist/quill.snow.css";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { clearForm } from "../services/api.service";
import DocumentTitle from "react-document-title";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";
import jsPDF from "jspdf";
import { format } from "date-fns";
import Footer from "./Footer";
const Letter = () => {

  const [saveButton, setSaveButton] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  var modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],

      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ["blockquote", "code-block"],
      ["link", "image", "video"],
      [{ direction: "rtl" }],
      [{ font: [] }],

      ["clean"][{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
    ],
  };

  var formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size",
    "background",
    "script",
    "video",
  ];
  //        ---------- CLOSE POPUP VARIABLE --------

  const closeButton = document.querySelector(".btn_popup");
  const closeEdit = document.querySelector(".e_popup");
  const closeView = document.querySelector(".ab");
  const closeDelete = document.querySelector(".d_lettertemplate");

  //        ---------- LETTER TEMPLATE VARIABLE --------

  const baseUrl = useContext(url);
  const currentDate = new Date().toISOString().split("T")[0];
  const [optionsEvent, setOptionsEvent] = useState([]);
  const [fCaseId, setFCaseId] = useState("");
  const [fFromDate, setFFromDate] = useState("");
  const filterform = new FormData();
  const [fToDate, setFToDate] = useState("");
  const [fClientID, setFClientID] = useState("");
  const [fClientOption, setFClientOption] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const navigate = useNavigate();
  const [dCaseID, setDCaseID] = useState("");
  const [dDate, setDDate] = useState("");
  const [dFileName, setDFileName] = useState("");
  const [ddescription, setDDescription] = useState("");
  const [dletterTemplateID, setDletterTemplateID] = useState("");
  const [loading, setLoading] = useState(true);

  const [isAdd, setIsAdd] = useState("0");
  const [isEdit, setIsEdit] = useState("0");
  const [isView, setIsView] = useState("0");
  const [isDelete, setIsDelete] = useState("0");
  const [isStatus, setIsStatus] = useState("0");
  
  const [open, setOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const handleExportButtonClick = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const handleToggleColumn = (columnName) => {
    const currentIndex = selectedColumns.indexOf(columnName);
    const newSelectedColumns = [...selectedColumns];

    if (currentIndex === -1) {
      newSelectedColumns.push(columnName);
    } else {
      newSelectedColumns.splice(currentIndex, 1);
    }

    setSelectedColumns(newSelectedColumns);
  };

  const handleProcedureContentChange = (content) => {
    setDescription(content);
  };

  const handleProcedureContentChange1 = (content) => {
    setEditDescription(content);
  };

  //        ---------- Letter Template Details --------
  const LetterTempf = new FormData();

  const handleLettertemp = (e) => {
    setletterTemplateID(e.target.value);
    setEditletterTemplateID(e.target.value);
    const getTempDetails = async () => {
      LetterTempf.append("AdminID", localStorage.getItem("id"));
      LetterTempf.append("ID", e.target.value);

      try {
        const response = await fetch(
          `${baseUrl}Master/ViewLetterTemplateById`,
          {
            method: "POST",
            body: LetterTempf,
            headers: {
              Authorization: `${localStorage.getItem("access_token")}`,
              Accept: "*/*",
            },
          }
        );
        const data = await response.json();
        if (data && data.result.message == "Session Time Expire.") {
          toast.error("Token expired please login again");
          setTimeout(() => {
            navigate("/");
          }, 3000);
        }
        if (data && data.result[0].code == 200) {
          setEditDescription(data.result[0].Description);
          setDescription(data.result[0].Description);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getTempDetails();
  };

  //        ---------- ADD VARIABLE --------

  const [letterTemplatesData, setLetterTemplatesData] = useState([]);
  const [letterTemplate, setLetterTemplate] = useState("");
  const [Template, setTemplate] = useState([]);
  const [description, setDescription] = useState("");
  const [filterUser, setFilterUser] = useState("");
  const [CaseID, setCaseID] = useState("");
  const [Dates, setDates] = useState(currentDate);
  const [FileName, setFileName] = useState("");
  const [letterTemplateID, setletterTemplateID] = useState("");
  const [delId, setDelId] = useState("");
  const [active, setActive] = useState(false);

  //        ---------- EDIT VARIABLE --------

  const [editId, setEditId] = useState("");
  const [EditCaseID, setEditCaseID] = useState("");
  const [EditDate, setEditDate] = useState("");
  const [EditFileName, setEditFileName] = useState("");
  const [Editdescription, setEditDescription] = useState("");
  const [EditletterTemplateID, setEditletterTemplateID] = useState("");
  const formData = new FormData();
  const editLetterTemplates = new FormData();

  //        ---------- VIEW LETTER TEMPLATE --------

  const getViewLetterTemplate = async () => {
    const [view, data, cdata] = await Promise.all([
      withUidApi("Letter/ViewLetter"),
      permission(21),
      getClient("Client/ViewClient"),
      getLetterTemp1(),
      getFileNo(),
      setLoading(true),
    ]);

    if (view === null) {
      setLoading(false);
      setLetterTemplatesData([]);
    } else {
      if (view === "token") {
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        setLoading(false);
        setLetterTemplatesData(view);
      }
    }

    const clientData = cdata && cdata.filter((item) => item.Status === "1");

    const Mapped =
      clientData &&
      clientData.map((item) => ({
        value: item.Id,
        label: `${item.FirstName} ${item.LastName}`,
      }));

    setFClientOption(Mapped);

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }

    setIsAdd(data && data.result[0].IsAdd);
    setIsEdit(data && data.result[0].IsEdit);
    setIsView(data && data.result[0].IsView);
    setIsStatus(data && data.result[0].IsStatus);
    setIsDelete(data && data.result[0].IsDelete);
  };

  //        ---------- DELETE LETTER  -------

  const deleteLetterTemplates = async () => {
    const data = await deleteUser(delId, "Letter/DeleteLetter");
    if (data === "token") {
      setTimeout(() => {
        closeDelete.click();
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      closeDelete.click();
      filterActive ? filterApi() : getViewLetterTemplate();
    }
  };

  //        ---------- ACTIVE DEACTIVE LETTER TEMPLATE --------

  const deActive = async (row) => {
    const data = await activeStatus(row, "Letter/LetterStatus");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      filterActive ? filterApi() : getViewLetterTemplate();
    }
  };

  //        ---------- DETAILS LETTER TEMPLATE --------

  const details = async (id) => {
    const data = await userDetails(id, "Letter/ViewLetterById");
    if (data === "token") {
      setTimeout(() => {
        closeView.click();
        navigate("/");
      }, 3000);
    } else {
      setDletterTemplateID(data.result[0].LetterTemplate);
      setDFileName(data.result[0].FileName);
      setDDate(data.result[0].Date);
      setDCaseID(data.result[0].FileNo);
      setDDescription(data.result[0].Description);
    }
  };

  //        ---------- DETAILS LETTER TEMPLATE --------

  const getById = async (id) => {
    const data = await userDetails(id, "Letter/ViewLetterById");
    if (data === "token") {
      setTimeout(() => {
        closeEdit.click();
        navigate("/");
      }, 3000);
    } else {
      setEditId(data.result[0].Id);
      setEditletterTemplateID(data.result[0].LetterTemplateID);
      setEditFileName(data.result[0].FileName);
      setEditDate(data.result[0].Date);
      setEditCaseID(data.result[0].CaseID);
      setEditDescription(data.result[0].Description);
    }
  };

  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };
  /** Get File Name  */
  const Case = new FormData();
  const [optionsCase, setOptionsCase] = useState([]); // Initialize options state

  const [FileNo, setFileNo] = useState([]);
  const getFileNo = async () => {
    Case.append("AdminID", localStorage.getItem("id"));
    Case.append("UserID", localStorage.getItem("uId"));
    
    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: Case,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }

      if (data && data.result[0].message === "Success") {
        setFileNo(data.result);
        const Mapped = data.result.map((item) => ({
          value: item.CaseID,
          label: `${item.FileNo} / ${item.CaseNo} / ${item.CaseName} / ${item.CaseYear}`,
        }));
        setOptionsCase(Mapped);
        setOptionsEvent(Mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setCaseID("");
    } else {
      setCaseID(selectedOption.value);
    }
  };

  const Case2 = new FormData();
  const [LetterTemp, setLetterTemp] = useState([]);
  const getLetterTemp1 = async () => {
    Case2.append("AdminID", localStorage.getItem("id"));
    try {
      const response = await fetch(`${baseUrl}Master/ViewLetterTemplate`, {
        method: "POST",
        body: Case2,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].code == 200) {
        setLetterTemp(data.result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        ---------- ADD LETTER --------

  const submitHandler = async (event) => {
    event.preventDefault();
    setSaveButton(false);
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    formData.append("CaseID", CaseID);
    formData.append("Date", Dates);
    formData.append("LetterTemplateID", letterTemplateID);
    formData.append("Description", description);
    try {
      const response = await fetch(`${baseUrl}Letter/AddLetter`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].message === "Success") {
        toast.success("Letter added successfully");
        closeButton.click();
        handleClose();
        setletterTemplateID("");
        setDates(currentDate);
        setCaseID("")
        setDescription("");
        filterActive ? filterApi() : getViewLetterTemplate();
        clearForm();
        setSaveButton(true);
      } else {
        toast.error(data.result[0].message);
        setSaveButton(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        ---------- EDIT LETTER TEMPLATE --------

  const submitEditHandler = async (event) => {
    event.preventDefault();
    editLetterTemplates.append("ID", editId);
    editLetterTemplates.append("AdminID", localStorage.getItem("id"));
    editLetterTemplates.append("UpdatedBy", localStorage.getItem("UserId"));
    editLetterTemplates.append("CaseID", EditCaseID);
    editLetterTemplates.append("Date", EditDate);
    // editLetterTemplates.append("FileName", EditFileName);
    editLetterTemplates.append("LetterTemplateID", EditletterTemplateID);
    editLetterTemplates.append("Description", Editdescription);
    try {
      const response = await fetch(`${baseUrl}Letter/EditLetter`, {
        method: "POST",
        body: editLetterTemplates,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeEdit.click();
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].message === 'Success') {
        toast.success(" Updated successfully");
        filterActive ? filterApi() : getViewLetterTemplate();
        closeEdit.click();
        setDescription("");
        clearForm();
      } else {
        toast.error(data.result[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // ---------- FILTER API ---------

  const filterApi = async () => {
    setLoading(true);
    filterform.append("AdminID", localStorage.getItem("id"));
    filterform.append("UserID", localStorage.getItem("uId"));
    filterform.append("CaseID", fCaseId);
    filterform.append("ClientID", fClientID);
    filterform.append("From", fFromDate);
    filterform.append("To", fToDate);
    try {
      const response = await fetch(`${baseUrl}Letter/ViewLetterfilter`, {
        method: "POST",
        body: filterform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 2000);
      }

      if (
        data &&
        data.result.length &&
        data &&
        data.result[0].message === "Success"
      ) {
        setFilterActive(true);
        setLoading(false);
        setLetterTemplatesData(data.result);
      } else {
        setLoading(false);
        setLetterTemplatesData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterCancel = () => {
    clearForm();
    setCaseID("");
    setFCaseId("");
    setFClientID("");
    setFFromDate("");
    setFToDate("");
    getViewLetterTemplate();
    setFilterActive(false);
  };

  const handleFilterCase = (selectedOption) => {
    if (selectedOption === null) {
      setFCaseId("");
    } else {
      setFCaseId(selectedOption.value);
    }
  };

  const handleFilterClient = (selectedOption) => {
    if (selectedOption === null) {
      setFClientID("");
    } else {
      setFClientID(selectedOption.value);
    }
  };

  //        ---------- TABLE --------
  const columnHelper = createMRTColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      header: "Action",
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      Cell: (row) => {
        return (
          <>
            <div style={{ display: "flex", gap: "15px" }}>
              {isEdit !== "0" && (
                <span
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalLongEdit"
                  onClick={() => getById(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/edit.svg"
                    alt="Edit"
                    title="Edit"
                  />
                </span>
              )}

              <span
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongDetail"
                onClick={() => details(row.row.original.Id)}
              >
                <img
                  src="assets/img/icons/eye.svg"
                  alt="Details"
                  title="Details"
                />
              </span>
              <span>
                <a
                  style={{
                    cursor: "pointer",
                    display: isStatus === "0" ? "none" : "block",
                  }}
                  onClick={() => deActive(row.row.original)}
                >
                  {row.row.original.Status === "1" ? (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Up.svg"
                      title="Status"
                    />
                  ) : (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Down.svg"
                      title="Status"
                    />
                  )}
                </a>
              </span>
              <span title="PDF">
                <img
                  onClick={() => handleGeneratePDF(row.row.original.Id)}
                  src="assets/img/icons/pdf.svg"
                  className="pdf"
                  title="PDF"
                />
              </span>
              <span title="Word File">
                <img
                  onClick={() => downloadWordFile(row.row.original.Id)}
                  src="assets/img/icons/word.svg"
                  className="pdf"
                  title="Word File"
                />
              </span>
              {isDelete !== "0" && (
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style={{ cursor: "pointer" }}
                  onClick={() => setDelId(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/delete.svg"
                    alt="Delete"
                    title="Delete"
                  />
                </span>
              )}
            </div>
          </>
        );
      },
    }),

    columnHelper.accessor("Date", {
      header: "Date",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.Date === null || row.row.original.Date === ""
              ? "-"
              : new Date(row.row.original.Date).toLocaleDateString("en-GB")}
          </div>
        );
      },
    }),
    columnHelper.accessor("FileNo", {
      header: "File No",
    }),

    columnHelper.accessor("CaseNo", {
      header: "Case No",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CaseNo === null || row.row.original.CaseNo === ""
              ? "-"
              : row.row.original.CaseNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseName", {
      header: "Case Name",
      Cell: (row) => {
        return (
          <div style={{ minWidth: "350px" }} className="upperCase">
            <a title="Case Details"
              href={`#/view-casedetails/${row.row.original.CaseID}`}
              className="visuald_case"
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              {row.row.original.CaseName}
            </a>
          </div>
        );
      },
    }),

    columnHelper.accessor("ClientName", {
      header: "Client",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.ClientName}
            <br />
            {row.row.original.MobileNo}
          </div>
        );
      },
    }),


    columnHelper.accessor("UpdatedBy", {
      header: "Last-Modified",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.UpdatedBy === null ||
            row.row.original.UpdatedBy === ""
              ? "-"
              : row.row.original.UpdatedBy}<br/>
                      {formateDateTime(row.row.original.UpdatedDate)}
          </div>
        );
      },
    }),

    columnHelper.accessor("Status", {
      header: "Status",
      Cell: (row) => {
        return <div>{row.row.original.Status === "1" ? "ACTIVE" : "DEACTIVE"}</div>;
      },
    }),

    columnHelper.accessor("AddedBy", {
      header: "Added By",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.AddedBy === null ||
            row.row.original.AddedBy === ""
              ? "-"
              : row.row.original.AddedBy}
              <br/>
              {formateDateTime(row.row.original.CreatedDate)}
          </div>
        );
      },
    }),
  ];
  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  //          ---------- SEARCH LETTER TEMPLATE --------

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getViewLetterTemplate();
    }
  }, []);

  const [wordDescriptionData, setWordDescriptionData] = useState(null);
  const getByIdWord = async (id) => {
    const data = await userDetails(id, "Letter/ViewLetterById");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } else {
      return data.result[0];
    }
  };

  // WORD
  const downloadWordFile = async (id) => {
    // console.log(wordDescriptionData);
    try {
      // Assuming getById is an asynchronous function that fetches data
      //setWordDescriptionData(null);Description
      const data = await getByIdWord(id);

      // console.log(id);
      // console.log(wordDescriptionData);
      // Check if data and data.editDescription are defined
      // if (data && data !== undefined) {
      // Sample HTML content

      var htmlContent = data;

      // Convert the HTML content to a Blob with MIME type "application/msword"
      const blob = new Blob(
        [
          `<!DOCTYPE html><html><head><meta charset="UTF-8"></head>
          <body>${htmlContent.Description}</body></html>`,
        ],
        { type: "application/msword;charset=utf-8" } // Specify character encoding
      );

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element and simulate a click to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.download = `${wordDescriptionData}/Letter.doc`; // Set the desired file name
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the temporary link and URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      // } else {
      //   console.error("Data or editDescription is undefined or empty");
      // }
    } catch (error) {
      console.error("Error fetching data or processing the file:", error);
    }
  };

  // pdf

  const wrapText = (text, font, fontSize, maxWidth) => {
    const words = text.split(" ");
    const lines = [];
    let currentLine = "";

    for (const word of words) {
      const testLine = currentLine + (currentLine === "" ? "" : " ") + word;
      const lineWidth = font.widthOfTextAtSize(testLine, fontSize);

      if (lineWidth <= maxWidth) {
        currentLine = testLine;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }

    if (currentLine) {
      lines.push(currentLine);
    }

    return lines;
  };

  const handlePrint = (data) => {
    const pdfContent = `<div style="padding: 15mm">${data.Description}</div>`;

    const pdfOptions = {
      filename: `${data.FileNo}/Letter.pdf`,
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf(pdfContent, pdfOptions);
  };

  const handleGeneratePDF = async (id) => {

    const data =  await getByIdWord(id);

    handlePrint(data);
  };

  // ---------DataTable-----

  const excelData =
    letterTemplatesData &&
    letterTemplatesData.map((obj) => {
      const { Id, code, message, Status, AdminID, ...rest } = obj;
      return rest;
    });

  const handleExportData = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });

    const formattedDate = new Date().toISOString().slice(0, 10);
    
    const filename = `Document|LegalCRM_${formattedDate}.csv`;

    const csv = generateCsv(csvConfig)(excelData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const columnsToExport = [
      { id: "serialNo", header: "S No." },
      ...columns.filter((column) => selectedColumns.includes(column.id)),
    ];

    const tableHeaders = columnsToExport.map((c) => c.header);

    const formattedDate = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const filename = `Letter_${formattedDate}.pdf`;
    const addWatermarkToFirstPage = () => {
      const watermarkHeight = 20;

      // Calculate positioning to center the watermark on each page

      doc.setFontSize(10);
      doc.text(
        10,
        watermarkHeight + -10,
        `Generated by : ${localStorage.getItem("name")} `
      );
      doc.text(172, watermarkHeight + -10, `Date: ${formattedDate}`);
      doc.setFontSize(16);
      doc.text("Letter Table", 84, 20);
    };
    const watermarkHeight = 20;

    addWatermarkToFirstPage();
    const tableData = rows.map((row, index) => {
      const formattedDateInPdf = row.original["DeadLine"]
        ? format(new Date(row.original["DeadLine"]), "dd/MM/yyyy")
        : "";

      return [
        { content: (index + 1).toString(), styles: { fontStyle: "bold" } }, // Serial number
        ...columnsToExport.slice(1).map((column) => {
          // Exclude the first column (serial number) when mapping
          if (column.id === "Date") {
            return formattedDateInPdf;
          }

          const value =
            column.id in row.original ? row.original[column.id] : "";
          return value !== null && value !== undefined ? value : "";
        }),
      ];
    });
    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY: watermarkHeight + 5,
    });

    // Add a heading to the table

    // Save the PDF to a Blob
    const pdfBlob = doc.output("blob");

    // Create a blob URL
    const blobUrl = URL.createObjectURL(pdfBlob);

    // Open the URL in a new tab
    window.open(blobUrl, "_blank");

    // Optionally revoke the object URL after opening the new tab
    URL.revokeObjectURL(blobUrl);
    // doc.save(filename);
  };

  const table = useMaterialReactTable({
    columns,
    data: letterTemplatesData,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    initialState: {
      columnVisibility: {
        Status: false,
        AddedBy: false,
        UpdatedBy: false,
        UpdatedDate: false,
        CreatedDate: false,
      },
    },
    enableStickyHeader: true,
    enableRowNumbers: true,
    state: {
      isLoading: loading,
    },
    muiCircularProgressProps: {
      color: "primary",
      thickness: 5,
      size: 55,
      style: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000,
      },
    },
    muiSkeletonProps: {
      animation: "pulse",
      height: 20,
    },
    muiPaginationProps: {
      rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export Excel
        </Button>

        <Button
        disabled={!table || table.getPrePaginationRowModel().rows.length === 0}
        onClick={handleExportButtonClick}
        startIcon={<FileDownloadIcon />}
      >
        Export PDF
      </Button>
      <Dialog open={open} onClose={handleClose1}>
        <DialogTitle>Select Columns To Export</DialogTitle>
        <DialogContent>
            {columns.map((column) =>
              column.header !== "Action" ? (
                <div key={column.id}>
                  <Checkbox
                    checked={selectedColumns.includes(column.id)}
                    onChange={() => handleToggleColumn(column.id)}
                  />
                  {column.header}
                </div>
              ) : (
                ""
              )
            )}
          </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1}>Cancel</Button>
          <Button onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}>Export</Button>
        </DialogActions>
      </Dialog>
      </Box>
    ),
  });

  return (
    <>
      <DocumentTitle title="LETTER | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  {/* <div className="accountContainer userListContainer"> */}
                  <div className="pageContent">
                    <div className="PageTitle">
                      <h3>Letter</h3>

                      <a
                        style={{
                          display: isAdd === "0" ? "none" : "block",
                        }}
                        type="button"
                        className="btn btn-primary btn_client headbtn"
                        data-toggle="modal"
                        data-target="#exampleModalLong"
                      >
                        Add Letter <img src="assets/img/icons/add.svg" />
                      </a>
                    </div>

                    <div
                      className="filter_type add_wrap"
                      style={{
                        display: isView === "0" ? "none" : "block",
                      }}
                    >
                      <div className="form_flex1 flex_wrap  caseMobile">
                        <div className="form-group1">
                          <label>File No.</label>

                          <Select
                            className="basic-single"
                            placeholder="Please Select "
                            classNamePrefix="select"
                            onChange={handleFilterCase}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={optionsEvent}
                          />
                        </div>
                        <div className="form-group1">
                          <label>Client Name</label>

                          <Select
                            className="basic-single"
                            placeholder="Please Select "
                            classNamePrefix="select"
                            onChange={handleFilterClient}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={fClientOption}
                          />
                        </div>
                        <div className="form-group1">
                          <label>From Date</label>
                          <input
                            // max={currentDate}
                            autoComplete="off"
                            className="name"
                            type="date"
                            value={fFromDate}
                            onChange={(e) => setFFromDate(e.target.value)}
                          />
                        </div>
                        <div className="form-group1">
                          <label>To Date</label>
                          <input
                            autoComplete="off"
                            className="name"
                            type="date"
                            value={fToDate}
                            onChange={(e) => setFToDate(e.target.value)}
                          />
                        </div>
                        <div className="form-group1 caseMobile btnGroup">
                          <div className="btn_main casebtn">
                            <button
                              type="submit"
                              className="btn btn_client"
                              onClick={filterApi}
                            >
                              Search
                            </button>
                            <button
                              type="button"
                              className="btn btn_client"
                              onClick={filterCancel}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: isView === "0" ? "none" : "block",
                      }}
                      className="searDesign"
                    >
                      <MaterialReactTable table={table} />
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <Footer/>
            </div>

            {/*--------- DELETE LETTER TEMPLATE --------*/}

            <div
              className="modal fade sc_modal"
              id="exampleModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close d_lettertemplate"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <h4>Delete Row</h4>
                    <p>Are you sure you want to delete letter ?</p>
                    <ul>
                      <li>
                        <a
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </a>
                      </li>
                      <li>
                        <a
                          className="btn btn-danger"
                          onClick={() => deleteLetterTemplates(delId)}
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/*---------- ADD LETTER TEMPLATE FORM --------*/}

            <div
              className="modal fade"
              id="exampleModalLong"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
              show={show}
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Add Letter</h2>
                    </div>
                  </div>

                  {/* form */}
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>

                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitHandler} ref={formRef}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">
                              File No. <text className="m_star">*</text>
                            </label>
                            <Select
                              required
                              className="basic-single"
                              placeholder="Please Select "
                              classNamePrefix="select"
                              onChange={handleSelectChange}
                              isClearable={true}
                              isSearchable={true}
                              name="color"
                              options={optionsCase}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="username">
                              Date <text className="m_star">*</text>
                            </label>
                            <input
                              className="name"
                              type="date"
                              name="templateName"
                              value={Dates}
                              onChange={(e) => setDates(e.target.value)}
                              required
                            />
                          </div>{" "}
                        </div>

                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">Use Template</label>

                            <a
                              className="visuald"
                              href="/#/view-letter-template"
                            >
                              +Add Template
                            </a>
                            <select
                              className="name"
                              placeholder="-- Please select template --"
                              name="cars"
                              id="cars"
                              onChange={handleLettertemp}
                            >
                              <option>
                                --Please Select only when you want to change
                                template--
                              </option>
                              {LetterTemp && LetterTemp.length > 0 ? (
                                LetterTemp.map((val, index) => (
                                  <option key={index} value={val.Id}>
                                    {val.LetterTemplate}
                                  </option>
                                ))
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <label htmlFor="username">Details</label>
                        </div>

                        <ReactQuill
                          value={description}
                          theme="snow"
                          name="name"
                          modules={modules}
                          formats={formats}
                          placeholder="write your content ...."
                          onChange={handleProcedureContentChange}
                          style={{ height: "320px" }}
                        ></ReactQuill>
                        <br />
                        <br />
                        {
                          saveButton ? (
                            <div className="btn_main caseMobile">
                            <button type="submit" className="btn_save">
                              Save
                            </button>
                            <button
                              type="button"
                              data-dismiss="modal"
                              className="btn_save btn_cancle"
                            >
                              Cancel
                            </button>
                          </div>
                          ) : ""
                        }
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*---------- EDIT LETTER TEMPLATE FORM --------*/}

            <div
              className="modal fade"
              id="exampleModalLongEdit"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Edit Letter</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="e_popup close btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitEditHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">
                              File No. <text className="m_star">*</text>
                            </label>
                            <select
                              id="city"
                              onChange={(e) => setEditCaseID(e.target.value)}
                              required
                            >
                              <option value="">--Please Select--</option>

                              {optionsCase &&
                                optionsCase.map((val, index) => (
                                  <option
                                    key={index}
                                    value={val.value}
                                    selected={val.value == EditCaseID}
                                  >
                                    {val.label}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="username">
                              Date <text className="m_star">*</text>
                            </label>
                            <input
                              type="date"
                              value={EditDate}
                              name="templateName"
                              onChange={(e) => setEditDate(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">Use Template</label>

                            <select
                              placeholder="-- Please select template --"
                              name="cars"
                              id="cars"
                              onChange={handleLettertemp}
                            >
                              <option>
                                --Please Select only when you want to change
                                template--
                              </option>
                              {LetterTemp && LetterTemp.length > 0 ? (
                                LetterTemp.map((val, index) => (
                                  <option
                                    key={index}
                                    value={val.Id}
                                    selected={val.Id == EditletterTemplateID}
                                  >
                                    {val.LetterTemplate}
                                  </option>
                                ))
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <label htmlFor="username">Details</label>
                        </div>

                        <ReactQuill
                          value={Editdescription}
                          theme="snow"
                          name="name"
                          modules={modules}
                          formats={formats}
                          placeholder="write your content ...."
                          onChange={handleProcedureContentChange1}
                          style={{ height: "320px" }}
                        ></ReactQuill>

                        <br />
                        <br />
                        <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_save btn_cancle"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*---------- LETTER TEMPLATE DETAILS  --------*/}

            <div
              className="modal fade"
              id="exampleModalLongDetail"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Letter Details</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close ab btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            File No. &nbsp;: &nbsp;{" "}
                            {dCaseID == null ? "-" : dCaseID}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Date &nbsp;: &nbsp;{" "}
                            {dDate == null ? "-" : formatDate1(dDate)}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                       
                        <div className="form-group">
                          <h4>
                            Letter Template &nbsp;:&nbsp;{" "}
                            {dletterTemplateID == null
                              ? "-"
                              : dletterTemplateID}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <h4>Details :</h4>
                      </div>

                      <ReactQuill
                        value={ddescription}
                        theme="snow"
                        className="editorReact"
                        modules={modules}
                        formats={formats}
                        readOnly
                        style={{ height: "320px" }}
                      ></ReactQuill>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*---------- Toster --------*/}

            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        )}
      </DocumentTitle>
    </>
  );
};
export default Letter;
