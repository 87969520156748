import React, { useState, useRef } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { permission } from "../services/api.service";
import DocumentTitle from "react-document-title";
import Preloader from "./Preloader";
import Footer from "./Footer";

const CaseFlowReport = () => {
  //        -------- POPUP CLOSE VARIABLE ---------
  const [caseFlowFilter, setCaseFlowFilter] = useState(false);
  const [fCaseFlowFromDate, setFCaseFlowFromDate] = useState("");
  const [fCaseFlowToDate, setFCaseFlowToDate] = useState("");
  const [period, setPeriod] = useState("1");
  const [groupBy, setGroupBy] = useState("");
  const [caseFlow, setCaseFlow] = useState([]);
  const [borderLeftCaseFlow, setBorderLeftCaseFlow] = useState(false);

  // Amount And Description

  const baseUrl = useContext(url);
  const navigate = useNavigate();

  // ----------- VIEW Client---------

  const filterform = new FormData();
  const form = new FormData();
  const [isView, setIsView] = useState(false);
  const [loading, setLoading] = useState(true);

  const filterCaseFlowCancel = () => {
    document.getElementById("dynamicColumn").innerHTML = "";
    setBorderLeftCaseFlow(false);
    // document.getElementById("dynamic").innerHTML = "";
    setPeriod("1");
    setGroupBy("");
    setFCaseFlowFromDate("");
    setFCaseFlowToDate("");
    setCaseFlowFilter(false);
    getCaseView();
  };

  //        -------- VIEW TASK DATA ---------

const viewData = async ( path ) => {
    form.append("AdminID", localStorage.getItem("id"));
    form.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(
        `${baseUrl}${path}`,
        {
          method: "POST",
          body : form,
          headers: {
            "Authorization": `${localStorage.getItem("access_token")}`,
            'Accept': '*/*'
          }
        }
      );
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error('Token expired please login again');
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
      
      if (data && data.result[0].CaseDetailsFlow.length) {
        setCaseFlow(data && data.result[0].CaseDetailsFlow)
      } else {
        setCaseFlow([]);
      }

    }
    catch (error) {
      console.error(error);
    }
  };

  const getCaseView = async () => {
    const [data] = await Promise.all([
      permission(29),
      viewData("Cases/ViewCaseDetailsFlow "),
    ]);

    setIsView(data && data.result[0].IsView === "1" ? true : false);
  };

  const filterCaseFlowApi = async () => {
    filterform.append("AdminID", localStorage.getItem("id"));
    filterform.append("UserID", localStorage.getItem("uId"));
    filterform.append("Period", period);
    filterform.append("GroupBy", groupBy);
    filterform.append("From", fCaseFlowFromDate);
    filterform.append("To", fCaseFlowToDate);
    try {
      const response = await fetch(`${baseUrl}Cases/CaseFlowFillter`, {
        method: "POST",
        body: filterform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }

      if (data && data.result[0].length) {
        setCaseFlow(data.result[0]);
      } else {
        setCaseFlow([]);
      }
      
      if (groupBy === "1") {
        setBorderLeftCaseFlow(true);
        document.getElementById("dynamicColumn").innerHTML = "City";
      } else {
        setBorderLeftCaseFlow(true);
        if (groupBy === "2") {
          document.getElementById("dynamicColumn").innerHTML = "Incharge";
        } else {
          setBorderLeftCaseFlow(true);
          if (groupBy === "3") {
            document.getElementById("dynamicColumn").innerHTML = "Co-counsel";
          } else {
            if (groupBy === "4") {
              setBorderLeftCaseFlow(true);
              document.getElementById("dynamicColumn").innerHTML = "Case-Type";
            } else {
              setBorderLeftCaseFlow(false);
              document.getElementById("dynamicColumn").innerHTML = "";
              document.getElementById("dynamic").innerHTML = "";
            }
          }
        }
      }

    } catch (error) {
      console.error(error);
    }
  };

  const caseFlowFilterShow = () => {
    setCaseFlowFilter(true);
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getCaseView();
    }
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <>
      <DocumentTitle title="CASH_FLOW_REPORT | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div className="wraper">
            <Header />
            <div className="mainWraper">
              <Sidebar />
              <div className="pageBody">
                <div className="pageContent">
                  <div className="PageTitle">
                    <h3>Cash Flow Report List</h3>
                  </div>
                  {isView && (
                    <div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="myCard">
                            <div className="cardHeader">
                              <h4>Cash Flow</h4>

                              <div
                                style={{
                                  display: "flex",
                                  gap: "20px",
                                }}
                              >
                                {caseFlowFilter && (
                                  <div className="form_flex_home">
                                    <div className="form-g">
                                      <select
                                        onChange={(e) =>
                                          setPeriod(e.target.value)
                                        }
                                      >
                                        <option value="1">Daily</option>
                                        <option value="2">Monthly</option>
                                        <option value="3">Yearly</option>
                                      </select>
                                    </div>
                                    <div className="form-g">
                                      <select
                                        onChange={(e) =>
                                          setGroupBy(e.target.value)
                                        }
                                      >
                                        <option value="">Group By</option>
                                        <option value="1">City</option>
                                        <option value="2">Case Counsel</option>
                                        <option value="3">Co-Counsel</option>
                                        <option value="4">Case Type</option>
                                      </select>
                                    </div>
                                    <div className="form-g">
                                      <input
                                        autoComplete="off"
                                        className="name"
                                        type="date"
                                        value={fCaseFlowFromDate}
                                        onChange={(e) =>
                                          setFCaseFlowFromDate(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div className="form-g">
                                      <input
                                        autoComplete="off"
                                        className="name"
                                        type="date"
                                        value={fCaseFlowToDate}
                                        onChange={(e) =>
                                          setFCaseFlowToDate(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div>
                                      <div className="filter_button">
                                        <button
                                          type="button"
                                          onClick={filterCaseFlowApi}
                                        >
                                          Search
                                        </button>
                                        <button
                                          type="button"
                                          onClick={filterCaseFlowCancel}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "20px",
                                  }}
                                >
                                  <img
                                    type="button"
                                    style={{
                                      display:
                                        caseFlowFilter === true
                                          ? "none"
                                          : "block",
                                    }}
                                    onClick={caseFlowFilterShow}
                                    src="assets/img/icons/Vector.svg"
                                    alt=""
                                    title="Filter"
                                  />
                                  <div class="rytContent">
                                    <a href="/#/view-case">
                                      <img
                                        src="assets/img/icons/dots.png"
                                        alt=""
                                        title="All Case"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {caseFlow && caseFlow.length ? (
                            <div className="cardBody">
                              <table className="myTable">
                                <thead>
                                  <tr>
                                    <th rowspan="2">Period</th>
                                    <th rowspan="2" id="dynamicColumn" style={{borderLeft: borderLeftCaseFlow ? "" : "none"}}></th>
                                    <th
                                      style={{ textAlign: "center" }}
                                      colspan="3"
                                    >
                                      Invoices
                                    </th>
                                    <th
                                      style={{ textAlign: "center" }}
                                      colspan="2"
                                    >
                                      Receipts
                                    </th>
                                    <th
                                      style={{ textAlign: "center" }}
                                      colspan="2"
                                    >
                                      Expenses
                                    </th>
                                  </tr>
                                  <tr style={{border: "1px solid  white"}}>
                                    <th>Nos.</th>
                                    <th>Amt</th>
                                    <th>Paid</th>
                                    <th>Nos.</th>
                                    <th>Amt</th>
                                    <th>Nos.</th>
                                    <th>Amt</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {caseFlow &&
                                    caseFlow.map((val, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>{val.Period}</td>
                                          <td id="dynamic">
                                            {val.GroupField === null || val.GroupField === "NULL"
                                              ? ""
                                              : val.GroupField}
                                          </td>
                                          <td>
                                                  <a
                                                    href={`/#/view-cash-flow/1_${groupBy}_${
                                                      val.Period
                                                    }_${
                                                      val.Id
                                                    }`}
                                                    className="visuald_case"
                                                    target="_blank"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {val.TotalInvoice}
                                                  </a>
                                                </td>
                                          <td>{val.InvoiceAmount}</td>
                                          <td>{val.InvoicePaidAmount}</td>
                                          <td>
                                                  <a
                                                    href={`/#/view-cash-flow/2_${groupBy}_${
                                                      val.Period
                                                    }_${
                                                      val.Id
                                                    }`}
                                                    className="visuald_case"
                                                    target="_blank"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {val.TotalReceipt}
                                                  </a>
                                                </td>
                                          <td>{val.TotalReceiptAmount}</td>
                                          <td>
                                                  <a
                                                    href={`/#/view-cash-flow/""_${groupBy}_${
                                                      val.Period
                                                    }_${
                                                      val.Id
                                                    }`}
                                                    className="visuald_case"
                                                    target="_blank"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {val.TotalExpenses}
                                                  </a>
                                                </td>
                                          <td>{val.ExpensesAmount}</td>
                                        </tr>
                                      );
                                    })}
                                  <tr></tr>
                                </tbody>
                              </table>
                            </div>
                               ) : <p className="case_detail_table">Data not found</p>}

                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Footer/>
          </div>
        )}
      </DocumentTitle>

      {/*-------- TOASTER ---------*/}

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default CaseFlowReport;
