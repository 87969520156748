import React, { useState, useRef } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import Select from "react-select";
import DocumentTitle from "react-document-title";
import Footer from "./Footer";

const AddExpenses = () => {

  const [saveButton, setSaveButton] = useState(true);
  const baseUrl = useContext(url);
  const navigate = useNavigate();

  const handleKeyPress = (e) => {
    const charCode = e.which || e.keyCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };
  
  //-------- ADD EXPENSES VARIABLE -------

  const currentDate = new Date().toISOString().split("T")[0];
  const [caseID, setCaseID] = useState("");
  const [expensesDetails, setExpensesDetails] = useState("");
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState(currentDate);
  const formData = new FormData()
  const Case = new FormData();
  const [optionsCase, setOptionsCase] = useState([]); 

  const getFileNo = async () => {
    Case.append("AdminID", localStorage.getItem("id"));
    Case.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: Case,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }

 if (data && data.result[0].message === "Success") {
        const Mapped = data.result.map((item) => ({
          value: item.CaseID,
          label: `${item.FileNo} / ${item.CaseNo} / ${item.CaseName} / ${item.CaseYear}`,

        }));
        setOptionsCase(Mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setCaseID("");
    } else {
      setCaseID(selectedOption.value);
    }
  };

  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  //-------- ADD EXPENSES -------

  const submitHandler = async (event) => {
    event.preventDefault();
    setSaveButton(false);
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CaseID", caseID);
    formData.append("ExpensesDetails", expensesDetails.toUpperCase());
    formData.append("Amount", amount);
    formData.append("Date", date);
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    try {
      const response = await fetch(`${baseUrl}Expenses/AddExpenses`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].message === "Success") {
        toast.success("Expenses added successfully");
        setSaveButton(true);
        handleClose();
        setDate(currentDate);
        setAmount("");
        setExpensesDetails("");
        setCaseID("");
        setTimeout(() => {
            navigate("/home")
        }, 2000);
      } else {
        toast.error(data.result.message);
        setSaveButton(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getFileNo()
    }
  }, []);
  
  return (
    <>
      <DocumentTitle title="ADD EXPENSES | LEGAL CRM">
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">

                  <div className="pageContent">
                    <div className="PageTitle">
                      <h3>Add Expenses</h3>
                    </div>
                    <div >
                  <div>
                 
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitHandler} ref={formRef}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">
                              File No. <text className="m_star">*</text>
                            </label>
                            <Select
                   
                              required
                              className="basic-single"
                              placeholder="Please Select "
                              classNamePrefix="select"
                              onChange={handleSelectChange}
                              isClearable={true} 
                              isSearchable={true} 
                              name="color"
                              options={optionsCase} 
          
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">Date</label>
                            <input
                              className="name"
                              type="date"
                              placeholder=""
                              name="uname"
                              value={date}
                              onChange={(e) => setDate(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="username">Amount</label>
                            <input
                              className="name"
                              type="text"
                              onKeyPress={handleKeyPress}
                              placeholder="Enter Amount"
                              name="uname"
                              value={amount}
                              onChange={(e) => setAmount(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile col-md-4">
                          <div className="form-group">
                            <label htmlFor="username">Expenses Details</label>
                            <textarea
                              className="name"
                              type="text"
                              placeholder="Enter Details"
                              name="uname"
                              value={expensesDetails}
                              onChange={(e) =>
                                setExpensesDetails(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        {
                          saveButton ? (
                            <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                        </div>
                          ) : ""
                        }
                      </form>
                    </div>
                  </div>
                </div>


                  </div>
            
                </div>
              </div>
              <Footer/>
            </div>

            {/*-------- Toster ----------*/}

            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
      </DocumentTitle>
    </>
  );
};

export default AddExpenses;
