import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const access_token = localStorage.getItem('access_token');
const baseUrl = 'https://api.lawyerhunt.in/api/';
const EditClientLead = createApi({
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    authenticate: builder.mutation({
      query: (credentials) => ({
        url: 'Client/EditClientLead',
        method: 'POST',
        headers: {
            Authorization: ` ${access_token}`,
            Accept:'application/json',
          'Content-Type': 'multipart/form-data',
        },
      
        body: credentials,
      }),
    }),
  }),
});
export const { useAuthenticateMutation } = EditClientLead;
export default EditClientLead;
