import React, { useState, useRef } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import Select from "react-select";
import DocumentTitle from "react-document-title";
import Footer from "./Footer";

const AddNote = () => {

  //----------  NOTE VARIABLE ----------
  const [saveButton, setSaveButton] = useState(true);
  const baseUrl = useContext(url);
  const navigate = useNavigate();
  const [fileNo, setFileNo] = useState("");
  const [summary, setSummary] = useState("");
  const [detail, setDetail] = useState("");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const formData = new FormData();
  
  /** Get File Name  */

  const Case = new FormData();
  const [optionsCase, setOptionsCase] = useState([]);
  const getFileNo = async () => {
    Case.append("AdminID", localStorage.getItem("id"));
    Case.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: Case,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }

      if (data && data.result[0].message === "Success") {
        const Mapped = data.result.map((item) => ({
          value: item.CaseID,
          label: `${item.FileNo} / ${item.CaseNo} / ${item.CaseName} / ${item.CaseYear}`,
        }));
        setOptionsCase(Mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setFileNo("");
    } else {
      setFileNo(selectedOption.value);
    }
  };

  //---------- NOTE DETAILS ----------


  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
    };

  //---------- SUBMIT NOTE DATA ----------

  const submitHandler = async (event) => {
    event.preventDefault();
    setSaveButton(false);
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    formData.append("Summary", summary.toUpperCase());
    formData.append("Details", detail.toUpperCase());
    formData.append("Date", date);
    formData.append("CaseID", fileNo);
    try {
      const response = await fetch(`${baseUrl}Notes/AddNotes`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].message === "Success") {
        toast.success("Note added successfully");
        setSaveButton(true);
        handleClose();
        setSummary("");
        setDetail("");
        setDate("");
        setTimeout(() => {
            navigate("/home");
        }, 2000);

      } else {
        toast.error(data.result.message);
        setSaveButton(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getFileNo();
    }
  }, []);

  return (
    <>
      <DocumentTitle title=" ADD NOTES | LEGAL CRM">

          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  <div className="pageContent">
                    <div className="PageTitle">
                      <h3>Add Notes</h3>
                    </div>
                    <div>
                  <div>
               
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitHandler} ref={formRef}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">
                              File No. <text className="m_star">*</text>
                            </label>
                            <Select
                              required
                              className="basic-single"
                              placeholder="Please Select "
                              classNamePrefix="select"
                              onChange={handleSelectChange}
                              name="color"
                              options={optionsCase}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              Date <text className="m_star">*</text>
                            </label>
                            <input
                              className="name"
                              type="date"
                              placeholder=""
                              name="uname"
                              value={date}
                              onChange={(e) => setDate(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">Summary</label>
                            <textarea
                              className="name"
                              type="text"
                              placeholder="Enter Summary"
                              name="uname"
                              value={summary}
                              onChange={(e) => setSummary(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="username">Details</label>
                            <textarea
                              className="name"
                              type="text"
                              placeholder="Enter Details"
                              name="uname"
                              value={detail}
                              onChange={(e) => setDetail(e.target.value)}
                            />
                          </div>
                        </div>
                    {
                      saveButton ? (
                        <div className="btn_main caseMobile">
                        <button type="submit" className="btn_save">
                          Save
                        </button>
                      </div>
                      ) : ""
                      }
                      </form>
                    </div>
                  </div>
                </div>
                  </div>         
                </div>
              </div>
              <Footer/>
            </div>

            {/*-------- Toster -----------*/}

            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
  
      </DocumentTitle>
    </>
  );
};
export default AddNote;
