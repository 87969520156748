import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { GoogleLogin } from "react-google-login";

const GoogleAuthComponent = () => {
  const responseGoogle = (response) => {
    console.log(response);
  };

  const [open, setOpen] = useState(true);
  const handleClose1 = () => {
    setOpen(false);
  };
  return (
    <div>
      {/* <GoogleLogin
        clientId="751271004213-jmndtf4iqh94ekt7n4li3od337bsu5ah.apps.googleusercontent.com"
        buttonText="Login with Google"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={'single_host_origin'}
        redirectUri='751271004213-jmndtf4iqh94ekt7n4li3od337bsu5ah.apps.googleusercontent.com'
      /> */}


<iframe height={500} width={1000} src="https://api.lawyerhunt.in/GoogleCalendarController/authenticates"/>
      {/* <Dialog open={open} onClose={handleClose1}>
        <DialogContent>
           </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1}>Cancel</Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
};

export default GoogleAuthComponent;
