import React, { useState, useRef } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import Select from "react-select";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import "jspdf-autotable";
import {
  formatDate1,
  clearForm,
} from "../services/api.service";
import DocumentTitle from "react-document-title";
import Footer from "./Footer";

const AddReceipt = () => {

  //        -------- POPUP CLOSE VARIABLE ---------
  const [saveButton, setSaveButton] = useState(true);
  const currentDate = new Date().toISOString().split("T")[0];
  const baseUrl = useContext(url);
  const navigate = useNavigate();
  const [CaseID, setCaseID] = useState("");
  const [Dates, setDates] = useState(currentDate);
  const [TransactionType, setTransactionType] = useState("");
  const [Details, setDetails] = useState("");
  const [TDSAmount, setTDSAmount] = useState("0");
  const [PaidAmount, setPaidAmount] = useState("0");
  const [totalAmount, setTotalAmount] = useState("0");
  const [InvoiceID, setInvoiceID] = useState("");
  const [Invoice1, setInvoice1] = useState([]);
  const formData = new FormData();
  const formInvoice = new FormData();

  //        -------- VIEW RECEIPT DATA ---------
    
  /** Get File Name  */

  const Case = new FormData();
  const [optionsEvent, setOptionsEvent] = useState([]);
  const getFileNo = async () => {
    Case.append("AdminID", localStorage.getItem("id"));
    Case.append("UserID", localStorage.getItem("uId"));
    
    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: Case,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      
      if (data && data.result[0].message === "Success") {
        const Mapped = data.result.map((item) => ({
          value: item.CaseID,
          label: `${item.FileNo} / ${item.CaseNo} / ${item.CaseName} / ${item.CaseYear}`,
        }));
        setOptionsEvent(Mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setCaseID("");
      getInvoice("");
      setInvoice1([]);

    } else {
      setCaseID(selectedOption.value);
      getInvoice(selectedOption.value);

    }
  };

  const getInvoice = async (id) => {
    formInvoice.append("AdminID", localStorage.getItem("id"));
    formInvoice.append("CaseID", id);
    try {
      const response = await fetch(`${baseUrl}Receipt/ViewnvoiceById`, {
        method: "POST",
        body: formInvoice,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].message === "Success") {
        setInvoice1(data.result);
      } else {

        setInvoice1([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        -------- TASK DETAILS DATA ---------

  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  //        -------- SUBMIT DATA ---------

  const submitHandler = async (event) => {
    event.preventDefault();

    const amount = Invoice1 && Invoice1.filter((val) => val.Id === InvoiceID);
    const invoiceAmount = parseInt(amount && amount[0]?.InvoiceAmount) - parseInt(amount && amount[0]?.PaidAmount);

    if (totalAmount <= invoiceAmount) {
      setSaveButton(false);
      formData.append("AdminID", localStorage.getItem("id"));
      formData.append("CaseID", CaseID);
      formData.append("Date", Dates);
      formData.append("TransactionType", TransactionType);
      formData.append("TDSAmount", TDSAmount);
      formData.append("PaidAmount", PaidAmount);
      formData.append("InvoiceID", InvoiceID);
      formData.append("TotalAmount", totalAmount);
      formData.append("Details", Details.toUpperCase());
      formData.append("CreatedBy", localStorage.getItem("UserId"));
  
      try {
        const response = await fetch(`${baseUrl}Receipt/AddReceipt`, {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        });
  
        const data = await response.json();
  
        if (data && data.result.message == "Session Time Expire.") {
          toast.error("Token expired please login again");
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
  
        if (data.result.length && data.result[0].message === "Success") {
          toast.success("Receipt added successfully");
          setSaveButton(true);
          handleClose();
          clearForm();
          setCaseID("");
          setDates(currentDate);
          setTransactionType("");
          setDetails("");
          setTDSAmount("");
          setTotalAmount("0");
          setPaidAmount("");
          setInvoiceID("");
          setInvoice1([]);
          setTimeout(() => {
            navigate("/home");
          }, 2000);
        } else {
          toast.error(data.result.message);
          setSaveButton(true);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.error(`Total Invoice Amount Is ${invoiceAmount}`);
    }

  };

  const handleTdsAmount = (e) => {
    if (e.target.value === "") {
      setTDSAmount(0);
      setTotalAmount(parseInt(PaidAmount));
    } else {
      const inputAmount = parseInt(e.target.value);
      const newTotalAmount = parseInt(PaidAmount) + inputAmount;
      setTotalAmount(newTotalAmount);
      setTDSAmount(inputAmount);
    }
  };

  const handleAmount = (e) => {
    if (e.target.value === "") {
      setPaidAmount(0);
      setTotalAmount(parseInt(TDSAmount));
    } else {
      const inputAmount = parseInt(e.target.value);
      const newTotalAmount = parseInt(TDSAmount) + inputAmount;
      setTotalAmount(newTotalAmount);
      setPaidAmount(inputAmount);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getFileNo();
    }
  }, []);

  return (
    <>
      <DocumentTitle title="ADD RECEIPT | LEGAL CRM">
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  <div className="pageContent">
                    <div className="PageTitle">
                     <h3>Add Receipt</h3>
                    </div>
                    <div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="name">
                              File No. <text className="m_star">*</text>
                            </label>
                            <Select
                              required
                              className="basic-single"
                              placeholder="Please Select "
                              classNamePrefix="select"
                              onChange={handleSelectChange}
                              isClearable={true}
                              isSearchable={true} 
                              name="color"
                              options={optionsEvent}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="invoice">
                              Invoice <text className="m_star">*</text>
                            </label>

                            <select
                              className="name"
                              id="cars"
                              onChange={(e) => setInvoiceID(e.target.value)}
                              required
                            >
                              <option value="">--Please Select--</option>
                              {Invoice1 && Invoice1.length ? (
                                Invoice1.map((val, index) => {
                                  return (
                                    <option key={index} value={val.Id}>
                                      {`InvoiceNo - ${
                                        val.InvoiceNo
                                      } / Amount - ${
                                        val.InvoiceAmount - val.PaidAmount
                                      } / Date - ${formatDate1(val.Date)}`}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              Date <text className="m_star">*</text>
                            </label>
                            <input
                              required
                              value={Dates}
                              type="Date"
                              max={currentDate}
                              className="name"
                              onChange={(e) => setDates(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Transaction Type <text className="m_star">*</text>
                            </label>
                            <select
                              required
                              className="name"
                              onChange={(e) =>
                                setTransactionType(e.target.value)
                              }
                            >
                              <option value="">--Please Select--</option>
                              <option value="CASH">CASH</option>
                              <option value="CHEQUE">CHEQUE</option>
                              <option value="NEFT/RTGS/IMPS">
                                NEFT/RTGS/IMPS
                              </option>
                              <option value="CARD PAYMENT">CARD PAYMENT</option>
                              <option value="PAYMENT GATEWAY">
                                PAYMENT GATEWAY
                              </option>
                              <option value="OTHERS">OTHERS</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label for="uname">Payment Detail</label>
                            <input
                              autoComplete="off"
                              className="name"
                              type="text"
                              placeholder="Enter Payment Detail"
                              onChange={(e) => setDetails(e.target.value)}
                            />
                          </div>
                        </div>

                        <hr />
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="amount">Amount</label>
                            <input
                            value={PaidAmount}
                              type="text"
                              className="name"
                              placeholder="Enter Amount"
                              onChange={handleAmount}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="tds">TDS Amount</label>
                            <input
                            value={TDSAmount}
                              type="text"
                              className="name"
                              placeholder="Enter TDS"
                              onChange={handleTdsAmount}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="amount">Total Amount</label>
                            <input
                              type="text"
                              readOnly
                              className="name"
                              placeholder="Enter Amount"
                              value={totalAmount}
                            />
                          </div>
                        </div>
                       {
                        saveButton ? (
                          <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                        </div>
                        ) : ""
                       }
                      </form>
                    </div>
                  </div>
                </div>
                  

               
        
               

                 
                  </div>
              
                </div>
              </div>
              <Footer/>
            </div>




            {/*-------- TOASTER ---------*/}

            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
    
      </DocumentTitle>
    </>
  );
};

export default AddReceipt;
