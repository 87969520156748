import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import html2pdf from "html2pdf.js";
import PDF from "./PDF";
import { useContext } from "react";
import { url } from "../index";

function PDFWP() {

  const baseUrl = useContext(url);
  const [base64Data, setBase64Data] = useState("");
  const { id } = useParams();
  const IDRef = useRef(id);
  const handlePrint = async () => {
    const pdfContent = document.getElementById("pdf-content");
    const pdfOptions = {
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    try {
      const pdfBlob = await html2pdf(pdfContent, pdfOptions).output("blob");
      const arrayBuffer = await pdfBlob.arrayBuffer();
      setBase64Data(
        btoa(
          new Uint8Array(arrayBuffer).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        )
      );
    } catch (error) {
      console.error("Error generating or converting PDF:", error);
    }
  };

  const navigate = useNavigate();
  const ViewCDData = new FormData();
  ViewCDData.append("ID", id);
  ViewCDData.append("Type", "Invoice");
  ViewCDData.append("PDF", base64Data);
  ViewCDData.append("AdminID", localStorage.getItem("id"));
  const getCountriesCD = async () => {
    try {
      const response = await fetch(
        `${baseUrl}Invoice/WhatsappInvoice`,
        {
          method: "POST",

          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
          body: ViewCDData,
        }
      );

      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      if (data.response_code === 400) {
        toast.error("Token expired please login again");
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    IDRef.current = id;
    getCountriesCD();
    handlePrint();
  }, [id]);
  return (
    <div style={{ display: "none" }}>
      <PDF id={id} />
    </div>
  );
}

export default PDFWP;
