import React, { useState, useRef } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useGetViewUserQuery } from "../Reduxslice/ViewUserslice";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  viewData,
  withUidApi,
  deleteUser,
  getFileNo,
  activeStatus,
  getClient,
  permission,
  getcourtView,
  formatDate1,
  formateDateTime
} from "../services/api.service";

import Select from "react-select";
import Preloader from "./Preloader";
import DocumentTitle from "react-document-title";
import Footer from "./Footer";
const CaseLead = () => {

  const [saveButton, setSaveButton] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);
  //---------- CLOSE POPUP VARIABLE -----------

  const currentDate = new Date().toISOString().split("T")[0];
  const closeButton = document.querySelector(".btn_popup");
  const closeEdit = document.querySelector(".e_popup");
  const closeDelete = document.querySelector(".d_caselead");
  const closeView = document.querySelector(".ab");

  //---------- CASE LEAD VARIABLE -----------
  const navigate = useNavigate();
  const baseUrl = useContext(url);
  const [excel, setExcel] = useState([]);
  const [dCaseName, setDCaseName] = useState("");
  const [dCaseType, setDCaseType] = useState("");
  const [dCaseYear, setDCaseYear] = useState("");
  const [dcity_title, setDcity_title] = useState("");
  const [dDiaryNo, setDDiaryNo] = useState("");
  const [dCourtName, setDCourtName] = useState("");
  const [ddistrict_title, setDdistrict_title] = useState("");
  const [dstate_title, setDstate_title] = useState("");
  const [dCaseNo, setDCaseNo] = useState("");
  const [dclient, setDClient] = useState("");
  const [dAddedBy, setDAddedBy] = useState("");
  const [dDiaryYear, setDDiaryYear] = useState("");
  const [dSummary, setDSummary] = useState("");
  const [dStatus, setDStatus] = useState("");
  const [dassignee, setDAssignee] = useState("");
  const [dnextDate, setDNextDate] = useState("");
  const [dactivity, setDActivity] = useState("");
  const [dprobablity, setDProbablity] = useState("");
  const [dconverted, setDConverted] = useState("");
  const [dremarks, setDRemarks] = useState("");

  //---------- ADD CASE LEAD VARIABLE -----------

  const { data: viewUser } = useGetViewUserQuery();
  const [viewUserData, setViewUserData] = useState([]);
  const [data, setData] = useState([]);
  const [logData, setLogData] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [clientLead, setClientLead] = useState([]);
  const [year, setYear] = useState([]);
  const [caseType, setCaseType] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [stateId, setStateId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [cityId, setCityId] = useState("");
  const [courtData, setCourtData] = useState([]);
  const [court, setCourt] = useState("");
  const [city, setCity] = useState([]);
  const [type, setType] = useState("");
  const [diaryNo, setDiaryNo] = useState("");
  const [diaryYear, setDiaryYear] = useState("");
  const [caseNo, setCaseNo] = useState("");
  const [caseYear, setCaseYear] = useState("");
  const [client, setClient] = useState("");
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [assignee, setAssignee] = useState("");
  const [nextDate, setNextDate] = useState(currentDate);
  const [activity, setActivity] = useState("");
  const [probablity, setProbablity] = useState("");
  const [converted, setConverted] = useState("");
  const [remarks, setRemarks] = useState("");
  const [filterUser, setFilterUser] = useState("");
  const [delId, setDelId] = useState("");
  const [active, setActive] = useState(false);
  const [fileNo, setFileNo] = useState();
  const [loading, setLoading] = useState(true);

  //---------- EDIT CASE LEAD VARIABLE -----------


  const [editId, setEditId] = useState("");
  const [editNewOld, setEditNewOld] = useState("");
  const [editDiaryNo, setEditDiaryNo] = useState("");
  const [editDiaryYear, setEditDiaryYear] = useState("");
  const [editCaseNo, setEditCaseNo] = useState("");
  const [editCaseYear, setEditCaseYear] = useState("");
  const [editClient, setEditClient] = useState("");
  const [editTitle, setEditTitle] = useState("");
  const [editSummary, setEditSummary] = useState("");
  const [editAssignee, setEditAssignee] = useState("");
  const [editNextDate, setEditNextDate] = useState("");
  const [editActivity, setEditActivity] = useState("");
  const [editLead, setEditLead] = useState("");
  const [editProbablity, setEditProbablity] = useState("");
  const [editConverted, setEditConverted] = useState("");
  const [editRemarks, setEditRemarks] = useState("");

  //---------- CASE LEAD VARIABLE -----------

  const [isAdd, setIsAdd] = useState("0");
  const [isEdit, setIsEdit] = useState("0");
  const [isView, setIsView] = useState("0");
  const [isDelete, setIsDelete] = useState("0");
  const [isStatus, setIsStatus] = useState("0");
  const formData = new FormData();
  const editCaseLead = new FormData();
  const districtForm = new FormData();
  const cityForm = new FormData();
  const viewCaseLeadId = new FormData();

  const [open, setOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const handleExportButtonClick = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const handleToggleColumn = (columnName) => {
    const currentIndex = selectedColumns.indexOf(columnName);
    const newSelectedColumns = [...selectedColumns];

    if (currentIndex === -1) {
      newSelectedColumns.push(columnName);
    } else {
      newSelectedColumns.splice(currentIndex, 1);
    }

    setSelectedColumns(newSelectedColumns);
  };

  //---------- VIEW  CASE LEAD -----------

  const getCaseView = async () => {
    const [view, fileData, client, data, coData] = await Promise.all([
      withUidApi("Cases/ViewCaseLead"),
      getFileNo(),
      getClient("Client/ViewClientLead"),
      permission(5),
      getcourtView("Master/ViewCourt"),
      setLoading(true),
    ]);

    if (view === null) {
      setLoading(false);
    } else {
      if (view === "token") {
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        setLoading(false);
        setData(view);
      }
    }

    setFileNo(fileData);
    setClientLead(client);
    const c_data = coData && coData.filter((item) => item.Status === "1");
    setCourtData(c_data);

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }

    setIsAdd(data && data.result[0].IsAdd);
    setIsEdit(data && data.result[0].IsEdit);
    setIsView(data && data.result[0].IsView);
    setIsStatus(data && data.result[0].IsStatus);
    setIsDelete(data && data.result[0].IsDelete);
  };
  const getCaseType = async () => {
    const data = await getcourtView("Master/ViewCaseTypeByCourtId", court);
    setCaseType(data);
  };

  //---------- DELETE CASE LEAD -----------

  const deleteCaseLead = async () => {
    const data = await deleteUser(delId, "Cases/DeleteCaseLead");
    if (data === "token") {
      setTimeout(() => {
        closeDelete.click();
        navigate("/");
      }, 3000);
    }

    if (data === true) {
      closeDelete.click();
      getCaseView();
    }
  };

  const handleStateChange = (e) => {
    setStateId(e.target.value);
    setDistrictId("");
    setCityId("");
  };

  const handleDistrictChange = (e) => {
    setDistrictId(e.target.value);
    setCityId("");
  };

  //---------- CASE LEAD DETAIL ----------

  const details = async (id) => {
    viewCaseLeadId.append("AdminID", `${localStorage.getItem("id")}`);
    viewCaseLeadId.append("ID", id);
    try {
      const response = await fetch(`${baseUrl}Cases/ViewCaseLeadById`, {
        method: "POST",
        body: viewCaseLeadId,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeView.click();
          navigate("/");
        }, 3000);
      }

      if (data && data.result.case_lead_data[0].code === "200") {
        setLogData(data.result.log_data);
        setDCourtName(data.result.case_lead_data[0].CourtName);
        setDstate_title(data.result.case_lead_data[0].state_title);
        setDdistrict_title(data.result.case_lead_data[0].district_title);
        setDcity_title(data.result.case_lead_data[0].city_title);
        setDCaseType(data.result.case_lead_data[0].CaseType);
        setDDiaryNo(data.result.case_lead_data[0].DiaryNo);
        setDCaseYear(data.result.case_lead_data[0].CaseYear);
        setDCaseName(data.result.case_lead_data[0].Title);
        setDCaseNo(data.result.case_lead_data[0].CaseNo);
        setDDiaryYear(data.result.case_lead_data[0].DiaryYear);
        setDSummary(data.result.case_lead_data[0].Summary);
        setDStatus(data.result.case_lead_data[0].Status);
        setDClient(data.result.case_lead_data[0].Client);
        setDRemarks(data.result.case_lead_data[0].Remarks);
        setDConverted(data.result.case_lead_data[0].Converted);
        setDProbablity(data.result.case_lead_data[0].Probablity);
        setDActivity(data.result.case_lead_data[0].Activity);
        setDNextDate(data.result.case_lead_data[0].NextDate);
        setDAssignee(data.result.case_lead_data[0].Assignee);
        setDAddedBy(data.result.case_lead_data[0].AddedBy);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //---------- ACTIVE DEACTIVE CASE LEAD ----------

  const deActive = async (row) => {
    const data = await activeStatus(row, "Cases/CaseLeadStatus");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }

    if (data === true) {
      getCaseView();
    }
  };

  const formRef = useRef(null);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  useEffect(() =>{
    const clientData = viewUser && viewUser.result.UserList.filter((item) => item.Status === "1");
    setViewUserData(clientData)
  }, [viewUser])

  //---------- SUBMIT CASE LEAD ----------

  const submitHandler = async (event) => {
    event.preventDefault();
    setSaveButton(false);
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    formData.append("CourtID", court);
    formData.append("StateID", stateId);
    formData.append("DistrictID", districtId);
    formData.append("CityID", cityId);
    formData.append("LeadType", type);
    formData.append("DiaryNo", diaryNo);
    formData.append("DiaryYear", diaryYear);
    formData.append("CaseNo", caseNo);
    formData.append("CaseYear", caseYear);
    formData.append("ClientID", client);
    formData.append("Title", title.toUpperCase());
    formData.append("Summary", summary.toUpperCase());
    formData.append("AssigneeID", assignee);
    formData.append("NextDate", nextDate);
    formData.append("Activity", activity);
    formData.append("Probablity", probablity);
    formData.append("Converted", converted);
    formData.append("Remarks", remarks.toUpperCase());
    try {
      const response = await fetch(`${baseUrl}Cases/AddCaseLead`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].message === "Success") {
        toast.success("Case Lead added successfully");
        closeButton.click();
        handleClose();
        getCaseView();
        setCourt("");
        setStateId("");
        setDistrictId("");
        setCityId("");
        setType("");
        setDiaryNo("");
        setDiaryYear("");
        setCaseNo("");
        setCaseYear("");
        setClient("");
        setTitle("");
        setSummary("");
        setAssignee("");
        setNextDate(currentDate);
        setActivity("");
        setProbablity("");
        setConverted("");
        setRemarks("");
        setSaveButton(true);
      } else {
        toast.error(data.result.message);
        setSaveButton(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //---------- EDIT CASE LEAD ----------

  const submitEditHandler = async (event) => {
    event.preventDefault();
    editCaseLead.append("ID", editId);
    editCaseLead.append("CourtID", court);
    editCaseLead.append("AdminID", localStorage.getItem("id"));
    editCaseLead.append("StateID", stateId);
    editCaseLead.append("DistrictID", districtId);
    editCaseLead.append("UpdatedBy", localStorage.getItem("UserId"));
    editCaseLead.append("CityID", cityId);
    editCaseLead.append("LeadType", type);
    editCaseLead.append("NewOld", editNewOld);
    editCaseLead.append("DiaryNo", editDiaryNo);
    editCaseLead.append("DiaryYear", editDiaryYear);
    editCaseLead.append("CaseNo", editCaseNo);
    editCaseLead.append("CaseYear", editCaseYear);
    editCaseLead.append("ClientID", editClient);
    editCaseLead.append("Title", editTitle.toUpperCase());
    editCaseLead.append("Summary", editSummary.toUpperCase());
    editCaseLead.append("AssigneeID", editAssignee == 0 ? "" : editAssignee);
    editCaseLead.append("NextDate", editNextDate);
    editCaseLead.append("Activity", editActivity);
    editCaseLead.append("Probablity", editProbablity);
    editCaseLead.append("Converted", editConverted == 0 ? "" : editConverted);
    editCaseLead.append("Remarks", editRemarks.toUpperCase());

    try {
      const response = await fetch(`${baseUrl}Cases/EditCaseLead`, {
        method: "POST",
        body: editCaseLead,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeEdit.click();
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].code == 200) {
        toast.success(" Updated successfully");
        closeEdit.click();
        getCaseView();
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //---------- EDIT CASE LEAD DETAIL ----------

  const updateCaseLead = async (id) => {
    viewCaseLeadId.append("AdminID", `${localStorage.getItem("id")}`);
    viewCaseLeadId.append("ID", id);
    try {
      const response = await fetch(`${baseUrl}Cases/ViewCaseLeadById`, {
        method: "POST",
        body: viewCaseLeadId,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeEdit.click();
          navigate("/");
        }, 3000);
      }

      if (data && data.result.case_lead_data[0].code === "200") {
        setLogData(data.result.log_data);
        const logDataLast =
          data.result.log_data[data.result.log_data.length - 1];
        setEditAssignee(logDataLast && logDataLast.AssigneeID);
        setEditActivity(logDataLast && logDataLast.ActivityID);
        setEditNextDate(logDataLast && logDataLast.NextDate);
        setEditProbablity(logDataLast && logDataLast.Probablity);
        setEditConverted(logDataLast && logDataLast.Converted);
        setEditRemarks(logDataLast && logDataLast.Remarks);
        setEditId(data.result.case_lead_data[0].Id);
        setCourt(
          data.result.case_lead_data[0].CourtID === "0"
            ? ""
            : data.result.case_lead_data[0].CourtID
        );
        setCityId(
          data.result.case_lead_data[0].CityID === "0"
            ? ""
            : data.result.case_lead_data[0].CityID
        );
        setStateId(
          data.result.case_lead_data[0].StateID === "0"
            ? ""
            : data.result.case_lead_data[0].StateID
        );
        setDistrictId(
          data.result.case_lead_data[0].DistrictID === "0"
            ? ""
            : data.result.case_lead_data[0].DistrictID
        );
        setType(
          data.result.case_lead_data[0].LeadType === "0"
            ? ""
            : data.result.case_lead_data[0].LeadType
        );
        setEditNewOld(data && data.result.case_lead_data[0].NewOld);
        setEditDiaryNo(data && data.result.case_lead_data[0].DiaryNo);
        setEditDiaryYear(data && data.result.case_lead_data[0].DiaryYear);
        setEditCaseNo(data && data.result.case_lead_data[0].CaseNo);
        setEditCaseYear(data && data.result.case_lead_data[0].CaseYear);
        setEditClient(data && data.result.case_lead_data[0].ClientID);
        setEditTitle(data && data.result.case_lead_data[0].Title);
        setEditSummary(data && data.result.case_lead_data[0].Summary);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [showInputField, setShowInputField] = useState(false);

  const handleCourtChange = (event) => {
    const selectedValue = event.target.value;
    setCourt(selectedValue);
    setType("");
    if (selectedValue === "1") {
      setShowInputField(true);
    } else {
      setShowInputField(false);
    }
  };

  //--------- CASE LEAD TABLE ----------

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      minSize: "20px",
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      header: "Action",
      Cell: (row) => {
        return (
          <div style={{ display: "flex", gap: "15px" }}>
            <span>
              {isEdit !== "0" && (
                <a
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalLongEdit"
                  onClick={() => updateCaseLead(row.row.original.Id)}
                >
                  <img src="assets/img/icons/edit.svg" title="Edit" />
                </a>
              )}
            </span>

            <span>
              <a
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongDetail"
                onClick={() => details(row.row.original.Id)}
              >
                <img src="assets/img/icons/eye.svg" title="Details" />
              </a>
            </span>
            <span>
              <a
                style={{
                  cursor: "pointer",
                  display: isStatus === "0" ? "none" : "block",
                }}
                onClick={() => deActive(row.row.original)}
              >
                {row.row.original.Status === "1" ? (
                  <img
                    className="thumb_icon"
                    src="assets/img/icons/Up.svg"
                    title="Status"
                  />
                ) : (
                  <img
                    className="thumb_icon"
                    src="assets/img/icons/Down.svg"
                    title="Status"
                  />
                )}
              </a>
            </span>
            <span>
              {isDelete !== "0" && (
                <a
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() => setDelId(row.row.original.Id)}
                >
                  <img src="assets/img/icons/delete.svg" title="Delete" />
                </a>
              )}
            </span>
          </div>
        );
      },
    }),

    columnHelper.accessor("NextDate", {
      header: "Next Date",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.NextDate === null ||
            row.row.original.NextDate === "0000-00-00"
              ? "-"
              : formatDate1(row.row.original.NextDate)}
          </div>
        );
      },
    }),

    columnHelper.accessor("Activity", {
      header: "Activity",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Activity === null ||
            row.row.original.Activity === ""
              ? "-"
              : row.row.original.Activity}
          </div>
        );
      },
    }),

    columnHelper.accessor("Assignee", {
      header: "Assignee",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Assignee === null ||
            row.row.original.Assignee === ""
              ? "-"
              : row.row.original.Assignee}
          </div>
        );
      },
    }),

    columnHelper.accessor("Client", {
      header: "Client",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Client === null ||
            row.row.original.Client === ""
              ? "-"
              : row.row.original.Client}
            <br/>
            {row.row.original.LastName}
            <br/>
            {row.row.original.MobileNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseNo", {
      header: "Case No",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CaseNo === null || row.row.original.CaseNo === ""
              ? "-"
              : row.row.original.CaseNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseName", {
      header: "Case Name",
      Cell: (row) => {
        return (
          <div style={{ minWidth: "350px" }} className="upperCase">
          {row.row.original.CaseName === null ||
          row.row.original.CaseName === ""
            ? "-"
            : row.row.original.CaseName}
        </div>
       
        );
      },
    }),

    columnHelper.accessor("Summary", {
      header: "Summary",
      Cell: (row) => {
        return (
          <div style={{ minWidth: "300px" }} className="upperCase">
            {row.row.original.Summary === null ||
            row.row.original.Summary === ""
              ? "-"
              : row.row.original.Summary}
          </div>
        );
      },
    }),
    columnHelper.accessor("CaseType", {
      header: "Case Type",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.CaseType === null ||
            row.row.original.CaseType === ""
              ? "-"
              : row.row.original.CaseType}
          </div>
        );
      },
    }),
    columnHelper.accessor("Converted", {
      header: "Converted",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Converted === null ||
            row.row.original.Converted === ""
              ? "-"
              : row.row.original.Converted === "1" ? "YES" : "NO"}
          </div>
        );
      },
    }),
    columnHelper.accessor("CaseYear", {
      header: "Case Year",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CaseYear === null ||
            row.row.original.CaseYear === ""
              ? "-"
              : row.row.original.CaseYear}
          </div>
        );
      },
    }),
    columnHelper.accessor("Probablity", {
      header: "Probablity",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.Probablity === null ||
            row.row.original.Probablity === ""
              ? "-"
              : row.row.original.Probablity}
          </div>
        );
      },
    }),
    columnHelper.accessor("court", {
      header: "Case Court",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.court === null || row.row.original.court === ""
              ? "-"
              : row.row.original.court}
          </div>
        );
      },
    }),
    columnHelper.accessor("Company", {
      header: "Company",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Company === null ||
            row.row.original.Company === ""
              ? "-"
              : row.row.original.Company}
          </div>
        );
      },
    }),

    columnHelper.accessor("Status", {
      header: "Status",
      Cell: (row) => {
        return <div>{row.row.original.Status === "1" ? "ACTIVE" : "DEACTIVE"}</div>;
      },
    }),

    columnHelper.accessor("AddedBy", {
      header: "Added By",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.AddedBy === null ||
            row.row.original.AddedBy === ""
              ? "-"
              : row.row.original.AddedBy}
          </div>
        );
      },
    }),
  ];
  //---------- SEARCH CASE LEAD ----------

  const searchInput = (e) => {
    if (e.length > 0) {
      const res = data.filter((f) =>
        f.FirstName.toLowerCase().includes(e.toLowerCase())
      );
      setActive(true);
      setFilterUser(res);
    } else {
      setActive(false);
    }
  };

  //---------- VIEW ACTIVITY ----------

  const getActivity = async () => {
    try {
      const response = await fetch(`${baseUrl}Master/ViewActivity`, {
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].message == "Suceess") {
        const y_data = data && data.result.filter((item) => item.Status === "1")
        setActivityData(y_data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //---------- VIEW STATE ----------

  const getState = async () => {
    try {
      const response = await fetch(`${baseUrl}Master/ViewState`, {
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      setState(data.result);
    } catch (error) {
      console.error(error);
    }
  };

  //---------- VIEW DITRICT ----------

  const getDistrict = async () => {
    districtForm.append("state_id", stateId);
    try {
      const response = await fetch(`${baseUrl}Master/ViewDistricByStateID`, {
        method: "POST",
        body: districtForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      setDistrict(data.result);
    } catch (error) {
      console.error(error);
    }
  };

  //---------- VIEW CITY ----------

  const getCity = async () => {
    cityForm.append("district_id", districtId);
    try {
      const response = await fetch(`${baseUrl}Master/ViewCityByDistrictid`, {
        method: "POST",
        body: cityForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      setCity(data.result);
    } catch (error) {
      console.error(error);
    }
  };

  //---------- VIEW YEAR ----------

  const getYear = async () => {
    try {
      const response = await fetch(`${baseUrl}Master/ViewYear`, {
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].code === "200") {
        const y_data = data && data.result.filter((item) => item.Status === "1")

        setYear(y_data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // ---------- TABLE --------

  const excelData =
    data &&
    data.map((obj) => {
      const { Id, code, message, Status, AdminID, ...rest } = obj;
      return rest;
    });

  const handleExportData = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });
  const formattedDate = new Date().toISOString().slice(0, 10); // Get today's date
    const filename = `Case_Lead|LegalCRM__${formattedDate}.csv`;

    const csv = generateCsv(csvConfig)(excelData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const columnsToExport = [
      { id: "serialNo", header: "S No." },
      ...columns.filter((column) => selectedColumns.includes(column.id)),
    ];

    const tableHeaders = columnsToExport.map((c) => c.header);

    const formattedDate = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const filename = `Case_Lead|LegalCRM__${formattedDate}.pdf`;
    const addWatermarkToFirstPage = () => {
      const watermarkHeight = 20;

      // Calculate positioning to center the watermark on each page

      doc.setFontSize(10);
      doc.text(
        10,
        watermarkHeight + -10,
        `Generated by : ${localStorage.getItem("name")} `
      );
      doc.text(172, watermarkHeight + -10, `Date: ${formattedDate}`);
      doc.setFontSize(16);
      doc.text("Case Lead Table", 74, 20);
    };
    const watermarkHeight = 20;

    addWatermarkToFirstPage();
    const tableData = rows.map((row, index) => {
      return [
        { content: (index + 1).toString(), styles: { fontStyle: "bold" } }, // Serial number
        ...columnsToExport.slice(1).map((column) => {
          // Exclude the first column (serial number) when mapping
          const value =
            column.id in row.original ? row.original[column.id] : "";
          return value !== null && value !== undefined ? value : "";
        }),
      ];
    });

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY: watermarkHeight + 5,
    });

    // Save the PDF to a Blob
    const pdfBlob = doc.output("blob");

    // Create a blob URL
    const blobUrl = URL.createObjectURL(pdfBlob);

    // Open the URL in a new tab
    window.open(blobUrl, "_blank");

    // Optionally revoke the object URL after opening the new tab
    URL.revokeObjectURL(blobUrl);
    // doc.save(filename);
  };
  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    initialState: {
      columnVisibility: {
        Probablity: false,
        NewOld: false,
        court: false,
        AddedBy: false,
        Status: false,
      },
    },
    enableStickyHeader: true,
    enableRowNumbers: true,
    state: {
      isLoading: loading,
    },
    muiCircularProgressProps: {
      color: "primary",
      thickness: 5,
      size: 55,
      style: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000,
      },
    },
    muiSkeletonProps: {
      animation: "pulse",
      height: 20,
    },
    muiPaginationProps: {
      rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
 disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export Excel
        </Button>

        <Button
        disabled={!table || table.getPrePaginationRowModel().rows.length === 0}
        onClick={handleExportButtonClick}
        startIcon={<FileDownloadIcon />}
      >
        Export PDF
      </Button>
      <Dialog open={open} onClose={handleClose1}>
        <DialogTitle>Select Columns To Export</DialogTitle>
        <DialogContent>
            {columns.map((column) =>
              column.header !== "Action" ? (
                <div key={column.id}>
                  <Checkbox
                    checked={selectedColumns.includes(column.id)}
                    onChange={() => handleToggleColumn(column.id)}
                  />
                  {column.header}
                </div>
              ) : (
                ""
              )
            )}
          </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1}>Cancel</Button>
          <Button onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}>Export</Button>
        </DialogActions>
      </Dialog>
      </Box>
    ),
  });

  useEffect(() => {
    getDistrict(stateId);
    setCity(null);
  }, [stateId]);

  useEffect(() => {
    getCity(districtId);
  }, [districtId]);

  useEffect(() => {
    getCaseType();
  }, [court]);

  // ----------- VIEW Client---------
  const ViewData = new FormData();
  const [options, setOptions] = useState([]); // Initialize options state
  const [selectedOptionC, setSelectedOptionC] = useState(null);

  ViewData.append("AdminID", localStorage.getItem("id"));
  const getCountries = async () => {
    try {
      const response = await fetch(
        `${baseUrl}Client/ViewClientLead`,
        {
          method: "POST",

          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
          body: ViewData,
        }
      );

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      const Mapped = data.result.map((item) => ({
        value: item.Id,
        label: `${item.FirstName} ${item.LastName}`,
      }));
      setOptions(Mapped);
      if (data.response_code === 400) {
        toast.error("Token expired please login again");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setClient("");
    } else {
      setClient(selectedOption.value);
    }
  };
  useEffect(() => {
    getCountries();
  }, []);
  const removekey = () => {
    if (data && data.length > 0) {
      const excelData =
        data &&
        data.map((obj) => {
          const { Id, code, Status, message, AdminID, ...rest } = obj;
          return rest;
        });
      setExcel(
        excelData.map((obj, index) => {
          return { "S. No.": `${index + 1}`, ...obj };
        })
      );
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getCaseView();
      getState();
      getYear();
      getActivity();
    }
  }, []);
  function formatDate(inputDate) {
    const parsedDate = new Date(inputDate);
    const formattedDate = parsedDate.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    return formattedDate;
  }

  return (
    <>
      <DocumentTitle title=" CASE LEAD | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  {/* <div className="accountContainer userListContainer"> */}
                  <div className="pageContent">
                    <div className="PageTitle">
                      <h3>Case Leads</h3>
                      
                      <a
                        style={{
                          display: isAdd === "0" ? "none" : "block",
                        }}
                        type="button"
                        className="btn btn-primary btn_client headbtn"
                        data-toggle="modal"
                        data-target="#exampleModalLong"
                      >
                        Add Case Lead <img src="assets/img/icons/add.svg" />
                      </a>
                    </div>
                 

                    <div
                      style={{
                        display: isView === "0" ? "none" : "block",
                      }}
                      className="searDesign"
                    >
                      <MaterialReactTable table={table} />
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <Footer/>
            </div>

            {/*---------- DELETE CASE LEAD ----------*/}

            <div
              className="modal fade sc_modal"
              id="exampleModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close d_caselead"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <h4>Delete Row</h4>
                    <p>Are you sure you want to delete case lead ?</p>
                    <ul>
                      <li>
                        <a
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </a>
                      </li>
                      <li>
                        <a
                          className="btn btn-danger"
                          onClick={() => deleteCaseLead(delId)}
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/*--------- ADD CASE LEAD FORM ---------*/}

            <div
              className="modal fade"
              id="exampleModalLong"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
              show={show}
            >
              <div
                className="modal-dialog modal-xl"
                role="document"
                // style={{ maxWidth: "80%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Add Case Lead</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="main_heading">
                      <h2>Case Lead</h2>
                    </div>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitHandler} ref={formRef}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Title <text className="m_star">*</text>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Case Name"
                              name="uname"
                              onChange={(e) => setTitle(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              Client<text className="m_star">*</text>
                            </label>
                            <Select
                              className="basic-single"
                              placeholder="Please Select "
                              classNamePrefix="select"
                              onChange={handleSelectChange}
                              isClearable={true}
                              isSearchable={true} 
                              name="color"
                              options={options}
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="uname">Case Year</label>
                            <select
                              name="cars"
                              id="cars"
                              onChange={(e) => setCaseYear(e.target.value)}
                            >
                              <option value="">--Please Select--</option>
                              {year &&
                                year.map((val, index) => {
                                  return (
                                    <option key={index} value={val.Year}>
                                      {val.Year}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">Case No.</label>
                            <input
                              type="text"
                              placeholder="Enter Case No."
                              name="uname"
                              onChange={(e) => setCaseNo(e.target.value)}
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="username">Court </label>
                            <select
                              name="cars"
                              id="cars"
                              onChange={handleCourtChange}
                            >
                              <option value="">--Please Select--</option>
                              {courtData &&
                                courtData.map((val, index) => {
                                  return (
                                    <option key={index} value={val.Id}>
                                      {val.CourtName}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="username">Court Case Type </label>
                            <select
                              name="cars"
                              id="cars"
                              onChange={(e) => setType(e.target.value)}
                            >
                              <option value="">--Please Select--</option>
                              {caseType && caseType.length ? (
                                caseType.map((val, index) => {
                                  return (
                                    <option key={index} value={val.Id}>
                                      {val.CaseType}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">Court State <text className="m_star">*</text></label>
                            <select
                            required
                            value={stateId}
                              name="cars"
                              id="cars"
                              onChange={(e) => setStateId(e.target.value)}
                            >
                              <option value="">--Please Select--</option>
                              {state && state.length ? (
                                state.map((val, index) => {
                                  return (
                                    <option key={index} value={val.state_id}>
                                      {val.state_title}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="username">Court District <text className="m_star">*</text></label>
                            <select
                            required
                            value={districtId}
                              name="cars"
                              id="cars"
                              onChange={(e) => setDistrictId(e.target.value)}
                            >
                              <option value="">--Please Select--</option>
                              {district && district.length ? (
                                district.map((val, index) => {
                                  return (
                                    <option key={index} value={val.district_id}>
                                      {val.district_title}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="username">Court City </label>
                            <select
                              name="cars"
                              id="cars"
                              onChange={(e) => setCityId(e.target.value)}
                            >
                              <option value="">--Please Select--</option>
                              {city && city.length ? (
                                city.map((val, index) => {
                                  return (
                                    <option key={index} value={val.city_id}>
                                      {val.city_title}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                        </div>

                        <hr />

                        {showInputField && (
                          <>
                            <div className="main_heading">
                              <h2>Diary</h2>
                            </div>
                            
                            <div className="col-md-8">
                              <div className="form_flex caseMobile">
                                <div className="form-group">
                                  <label htmlFor="uname">Diary No.</label>
                                  <input
                                    type="text"
                                    placeholder="Enter Diart No."
                                    name="uname"
                                    onChange={(e) => setDiaryNo(e.target.value)}
                                  />
                                </div>
                                <div className="form-group">
                                  <label htmlFor="uname">Diary Year</label>
                                  <select
                                    name="cars"
                                    id="cars"
                                    onChange={(e) =>
                                      setDiaryYear(e.target.value)
                                    }
                                  >
                                    <option value="">--Please Select--</option>
                                    {year &&
                                      year.map((val, index) => {
                                        return (
                                          <option key={index} value={val.Year}>
                                            {val.Year}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </>
                        )}

                        <div className="main_heading">
                          <h2>Case Summary</h2>
                        </div>
                        
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">Summary</label>
                            <textarea
                              type="text"
                              placeholder="Enter Case Summary"
                              name="uname"
                              onChange={(e) => setSummary(e.target.value)}
                            />
                          </div>
                        </div>

                        <hr />
                        <div className="main_heading">
                          <h2>Case Lead Specific Details</h2>
                        </div>
                        
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">Assignee</label>
                            <select
                              name="cars"
                              id="cars"
                              onChange={(e) => setAssignee(e.target.value)}
                            >
                              <option value="">--Please Select--</option>
                              {viewUserData &&
                                viewUserData.map((val, index) => {
                                  return (
                                    <option key={index} value={val.Id}>
                                      {val.Name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">Next Date</label>
                            <input
                              min={currentDate}
                              type="date"
                              value={nextDate}
                              placeholder=""
                              name="uname"
                              onChange={(e) => setNextDate(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">Activity</label>
                            <select
                              name="cars"
                              id="cars"
                              onChange={(e) => setActivity(e.target.value)}
                            >
                              <option value="">--Please Select--</option>
                              {activityData &&
                                activityData.map((val, index) => {
                                  return (
                                    <option key={index} value={val.Id}>
                                      {val.Activity}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="form_flex caseMobile">
                            <div className="form-group">
                              <label htmlFor="uname">Probablity</label>
                              <input
                                type="text"
                                placeholder="%"
                                name="uname"
                                onChange={(e) => setProbablity(e.target.value)}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="uname">Converted ?</label>
                              <select
                                name="cars"
                                id="cars"
                                onChange={(e) => setConverted(e.target.value)}
                              >
                                <option value="">--Please Select--</option>
                                <option value="1">YES</option>
                                <option value="0">NO</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">Remarks</label>
                            <textarea
                              type="text"
                              placeholder="Stage Remarks"
                              name="uname"
                              onChange={(e) => setRemarks(e.target.value)}
                            />
                          </div>
                        </div>
                      {
                        saveButton ? (
                          <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_save btn_cancle"
                          >
                            Cancel
                          </button>
                        </div>
                        ) : ""
                      }
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*--------- EDIT CASE LEAD FORM ---------*/}

            <div
              className="modal fade"
              id="exampleModalLongEdit"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-xl"
                role="document"
                // style={{ maxWidth: "80%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Edit Case Lead</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close e_popup btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="main_heading">
                      <h2>Case Lead</h2>
                    </div>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitEditHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Title <text className="m_star">*</text>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Case Name"
                              name="uname"
                              value={editTitle}
                              onChange={(e) => setEditTitle(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              Client <text className="m_star">*</text>
                            </label>
                            <select
                              name="cars"
                              id="cars"
                              onChange={(e) => setEditClient(e.target.value)}
                              required
                            >
                              <option value="">--Please Select--</option>
                              {clientLead &&
                                clientLead.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.Id}
                                      selected={val.Id === editClient}
                                    >
                                      {val.FirstName}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">Case No.</label>
                            <input
                              type="text"
                              placeholder="Enter Case No."
                              name="uname"
                              value={editCaseNo}
                              onChange={(e) => setEditCaseNo(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">Case Year</label>
                            <select
                              name="cars"
                              id="cars"
                              onChange={(e) => setEditCaseYear(e.target.value)}
                            >
                              <option value="">--Please Select--</option>
                              {year &&
                                year.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.Year}
                                      selected={val.Year === editCaseYear}
                                    >
                                      {val.Year}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">Court </label>
                            <select
                              name="cars"
                              id="cars"
                              onChange={handleCourtChange}
                            >
                              <option value="">--Please Select--</option>
                              {courtData &&
                                courtData.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.Id}
                                      selected={val.Id === court}
                                    >
                                      {val.CourtName}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="username">Case Type </label>
                            <select
                              name="cars"
                              id="cars"
                              onChange={(e) => setType(e.target.value)}
                            >
                              <option value="">--Please Select--</option>
                              {caseType && caseType.length ? (
                                caseType.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.Id}
                                      selected={val.Id === type}
                                    >
                                      {val.CaseType}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value="">
                                  Please first select Court...
                                </option>
                              )}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="username">Court State <text className="m_star">*</text></label>
                            <select
                            required
                            value={stateId}
                              name="cars"
                              id="cars"
                              onChange={handleStateChange}
                            >
                              <option value="">--Please Select--</option>
                              {state && state.length ? (
                                state.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.state_id}
                                      selected={val.state_id === stateId}
                                    >
                                      {val.state_title}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="username">Court District <text className="m_star">*</text></label>
                            <select
                            required
                            value={districtId}
                              name="cars"
                              id="cars"
                              onChange={handleDistrictChange}
                            >
                              <option value="">--Please Select--</option>
                              {district && district.length ? (
                                district.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.district_id}
                                      selected={val.district_id === districtId}
                                    >
                                      {val.district_title}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div
                            className="form-group"
                            style={{ width: "300px" }}
                          >
                            <label htmlFor="username">Court City </label>
                            <select
                              name="cars"
                              id="cars"
                              onChange={(e) => setCityId(e.target.value)}
                            >
                              <option value="">--Please Select--</option>
                              {city && city.length ? (
                                city.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.city_id}
                                      selected={val.city_id === cityId}
                                    >
                                      {val.city_title}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                        </div>
                        <hr />
                        {showInputField && (
                          <>
                            <div className="main_heading">
                              <h2>Diary</h2>
                            </div>{" "}
                            
                            <div className="form_flex caseMobile">
                              <div className="form-group">
                                <label htmlFor="uname">Diary No.</label>
                                <input
                                  type="text"
                                  placeholder="Enter Diart No."
                                  name="uname"
                                  value={editDiaryNo}
                                  onChange={(e) =>
                                    setEditDiaryNo(e.target.value)
                                  }
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="uname">Diary Year</label>
                                <select
                                  name="cars"
                                  id="cars"
                                  onChange={(e) =>
                                    setEditDiaryYear(e.target.value)
                                  }
                                >
                                  <option value="">Please select</option>
                                  {year &&
                                    year.map((val, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={val.Year}
                                          selected={val.Year === editDiaryYear}
                                        >
                                          {val.Year}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                            <hr />
                          </>
                        )}
                        <div className="main_heading">
                          <h2>Case Summary</h2>
                        </div>{" "}
                        
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">Summary</label>
                            <textarea
                              type="text"
                              placeholder="Enter Case Summary"
                              name="uname"
                              value={editSummary}
                              onChange={(e) => setEditSummary(e.target.value)}
                            />
                          </div>
                        </div>
                        <hr />
                        <div className="main_heading">
                          <h2>Case Lead Specific Details</h2>
                        </div>
                        
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">Assignee </label>
                            <select
                              name="cars"
                              id="cars"
                              onChange={(e) => setEditAssignee(e.target.value)}
                            >
                              <option value="">-- Please select --</option>
                              {viewUserData &&
                                viewUserData.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.Id}
                                      selected={val.Id === editAssignee}
                                    >
                                      {val.Name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">Next Date </label>
                            <input
                              type="date"
                              value={editNextDate}
                              name="uname"
                              onChange={(e) => setEditNextDate(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">Activity </label>
                            <select
                              name="cars"
                              id="cars"
                              onChange={(e) => setEditActivity(e.target.value)}
                            >
                              <option value="">-- Please Select --</option>
                              {activityData &&
                                activityData.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.Id}
                                      selected={val.Id === editActivity}
                                    >
                                      {val.Activity}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">Probablity</label>
                            <input
                              type="text"
                              placeholder="%"
                              name="uname"
                              value={editProbablity}
                              onChange={(e) =>
                                setEditProbablity(e.target.value)
                              }
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">Converted ?</label>
                            <select
                              value={editConverted}
                              name="cars"
                              id="cars"
                              onChange={(e) => setEditConverted(e.target.value)}
                            >
                              <option value="">--Please Select--</option>
                              <option value="1">YES</option>
                              <option value="0">NO</option>
                            </select>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">Remarks</label>
                            <textarea
                              type="text"
                              placeholder="Stage Remarks"
                              name="uname"
                              value={editRemarks}
                              onChange={(e) => setEditRemarks(e.target.value)}
                            />
                          </div>
                        </div>
                        <hr />
                        <div className="main_heading">
                          <h2>Case Lead Logs</h2>
                        </div>
                        <br/>
                        <table className="table detail_table">
                          <tr>
                            <th>S. No</th>
                            <th>Updated On</th>
                            <th>Next Date</th>
                            <th>Stage</th>
                            <th>Assigned By</th>
                            <th>Assigned To</th>
                            <th>Remarks</th>
                          </tr>

                          {logData &&
                            logData.map((val, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{formateDateTime(val.UpdatedDate)}</td>
                                  <td>
                                    {val.NextDate === "00-00-0000" ||
                                    val.NextDate === "0000-00-00"
                                      ? "-"
                                      : formatDate1(val.NextDate)}
                                  </td>
                                  <td className="upperCase">{val.Activity === null || val.Activity === "" ? "-" : val.Activity}</td>
                                  <td className="upperCase">{val.AssignBy === null || val.AssignBy === "" ? "-" : val.AssignBy}</td>
                                  <td className="upperCase">{val.AssignTo === null || val.AssignTo === "" ? "-" : val.AssignTo}</td>
                                  <td className="upperCase">{val.Remarks === null || val.Remarks === "" ? "-" : val.Remarks}</td>
                                </tr>
                              );
                            })}
                        </table>
                        <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_save btn_cancle"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*--------- CASE LEAD DETAIL ---------*/}

            <div
              className="modal fade"
              id="exampleModalLongDetail"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-xl"
                role="document"
                // style={{ maxWidth: "75%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Case Lead Details</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close ab btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Diary No. &nbsp;:&nbsp;{" "}
                            {dDiaryNo === null || dDiaryNo === "" ? "-" : dDiaryNo}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Diary Year &nbsp;:&nbsp;{" "}
                            {dDiaryYear === null || dDiaryYear === "0000" ? "-" : dDiaryYear}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Case No. &nbsp;:&nbsp;{" "}
                            {dCaseNo === null || dCaseNo === "" ? "-" : dCaseNo}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Case Year &nbsp;:&nbsp;{" "}
                            {dCaseYear === null || dCaseYear === "0000" ? "-" : dCaseYear}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Court &nbsp;: &nbsp;{" "}
                            {dCourtName === null || dCourtName === "" ? "-" : dCourtName}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Case Type &nbsp;: &nbsp;{" "}
                            {dCaseType === null || dCaseType === "" ? "-" : dCaseType}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            State &nbsp;:&nbsp;{" "}
                            {dstate_title === null || dstate_title === "" ? "-" : dstate_title}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            District &nbsp;: &nbsp;{" "}
                            {ddistrict_title === null || ddistrict_title === "" ? "-" : ddistrict_title}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            City &nbsp;:&nbsp;{" "}
                            {dcity_title === null || dcity_title === "" ? "-" : dcity_title}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Title &nbsp;:&nbsp;{" "}
                            {dCaseName === null || dCaseName === "" ? "-" : dCaseName}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Status &nbsp;:&nbsp;{" "}
                            {dStatus === "1" ? "ACTIVE" : "DEACTIVE"}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Summary &nbsp;:&nbsp;{" "}
                            {dSummary === null || dSummary === "" ? "-" : dSummary}{" "}
                          </h4>
                        </div>
                      </div>
                      <br/>
                      <div className="main_heading">
                        <h2>Case Lead Logs</h2>
                      </div>
                      <br/>
                      <table className="table detail_table">
                        <tr>
                          <th>S. No</th>
                          <th>Updated On</th>
                          <th>Next Date</th>
                          <th>Stage</th>
                          <th>Assigned By</th>
                          <th>Assigned To</th>
                          <th>Remarks</th>
                        </tr>

                        {logData &&
                          logData.map((val, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{formateDateTime(val.UpdatedDate)}</td>
                                <td>
                                  {val.NextDate === "00-00-0000" ||
                                  val.NextDate === "0000-00-00"
                                    ? "-"
                                    : formatDate1(val.NextDate)}
                                </td>
                                <td>
                                  {val.Activity === null ? "-" : val.Activity}
                                </td>
                                <td>{val.AssignBy}</td>
                                <td>{val.AssignTo}</td>
                                <td>{val.Remarks}</td>
                              </tr>
                            );
                          })}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*--------- Toster ---------*/}

            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        )}
      </DocumentTitle>
    </>
  );
};

export default CaseLead;
