import React, { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { Line } from "react-chartjs-2";
import Preloader from "./Preloader";
import { viewData, permission } from "../services/api.service";
import { CanvasJS } from "canvasjs-react-charts";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import DocumentTitle from "react-document-title";
import Footer from "./Footer";
import CanvasJSReact from "@canvasjs/react-charts";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Analysis = () => {
  //----------  NOTE VARIABLE ----------
  const [countriesC, setCountriesC] = useState([]);
  const [supremeData, setSupremeData] = useState([]);
  const [totalCaseType, setTotalCaseType] = useState("0");
  const [invoiceCounsel, setInvoiceCounsel] = useState([]);
  const [invoiceCounselName, setInvoiceCounselName] = useState([]);
  const [receiptCounselName, setReceiptCounselName] = useState([]);
  const [receiptCounsel, setReceiptCounsel] = useState([]);
  const ViewData = new FormData();
  const clientForm = new FormData();
  const [clientData, setclientData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [invoicegrap, setInvoicegrap] = useState([]);
  const [invoicegrapPrivious, setInvoicegrapPrivious] = useState([]);
  const [receiptGraph, setReceiptGraph] = useState([]);
  const [receiptGraphPrivious, setReceiptGraphPrivious] = useState([]);
  const [formDate, setFormDate] = useState("");
  const [toDate, setToDate] = useState([]);
  const [reFormDate, setReFormDate] = useState("");
  const [reToDate, setReToDate] = useState([]);

  const sum =
    invoiceCounsel &&
    invoiceCounsel.reduce(function (prev, current) {
      return prev + +current.value;
    }, 0);

  const receiptSum =
    receiptCounsel &&
    receiptCounsel.reduce(function (prev, current) {
      return prev + +current.value;
    }, 0);

  const invoicegrap1 =
    invoicegrap &&
    invoicegrap.map((obj) => ({
      ...obj,
      y: parseFloat(obj.y),
    }));

  const invoicegrapPrivious2 =
    invoicegrapPrivious &&
    invoicegrapPrivious.map((obj) => ({
      ...obj,
      y: parseFloat(obj.y),
    }));

  const receiptGraph1 =
    receiptGraph &&
    receiptGraph.map((obj) => ({
      ...obj,
      y: parseFloat(obj.y),
    }));

  const receiptGraphPrivious2 =
    receiptGraphPrivious &&
    receiptGraphPrivious.map((obj) => ({
      ...obj,
      y: parseFloat(obj.y),
    }));

  const cyear = invoicegrap && invoicegrap[0]?.year;
  const pyear = invoicegrapPrivious2 && invoicegrapPrivious2[0]?.year;

  const r_cyear = receiptGraph && receiptGraph[0]?.year;
  const r_pyear = receiptGraphPrivious && receiptGraphPrivious[0]?.year;

  const chartDataInvoice = {
    labels: invoicegrap1.map(data => data.label),
    datasets: [
      {
        label: cyear,
        data: invoicegrap1.map(data => data.y),
        borderColor: "rgba(0, 77, 0, 1)",
        backgroundColor: "rgba(0, 77, 0, 0.2)",
        fill: true,
      },
      {
        label: pyear,
        data: invoicegrapPrivious2.map(data => data.y),
        borderColor: "rgba(255, 204, 255, 1)",
        backgroundColor: "rgba(255, 204, 255, 0.2)",
        fill: true,
      },
    ],
  };
  
  const chartDataReceipt = {
    labels: receiptGraph1.map(data => data.label),
    datasets: [
      {
        label: r_cyear,
        data: receiptGraph1.map(data => data.y),
        borderColor: "	rgba(0, 77, 0, 1)",
        backgroundColor: "rgba(0, 77, 0, 0.2)",
        fill: true,
      },
      {
        label: r_pyear,
        data: receiptGraphPrivious2.map(data => data.y),
        borderColor: "rgba(255, 204, 255, 1)",
        backgroundColor: "rgba(255, 204, 255, 0.2)",
        fill: true,
      },
    ],
  };
  
  const redirectToInvoice = (event, elements) => {
    if (elements.length > 0) {
      elements.forEach((element) => {
        const datasetIndex = element.datasetIndex;
        const dataset = chartDataInvoice.datasets[datasetIndex];
        const clickedElementIndex = element.index;
        const clickedLabel = chartDataInvoice.labels[clickedElementIndex];
        const url = `/#/view-invoice-counsel/${clickedLabel}_${dataset.label}`;
        window.open(url, "_blank");
      });
    }
  };
  
  const redirectToReceipt = (event, elements) => {
    if (elements.length > 0) {
      elements.forEach((element) => {
        const datasetIndex = element.datasetIndex;
        const dataset = chartDataReceipt.datasets[datasetIndex];
        const clickedElementIndex = element.index;
        const clickedLabel = chartDataReceipt.labels[clickedElementIndex];
        const url = `/#/view-receipt-counsel/${clickedLabel}_${dataset.label}`;
        window.open(url, "_blank");
      });
    }
  };

  const baseUrl = useContext(url);
  const navigate = useNavigate();
  const [isView, setIsView] = useState("0");

  //---------- ADD NOTE VARIABLE ----------

  const [caseLeadData, setCaseLeadData] = useState([]);

  //---------- VIEW NOTE API ----------

  const getAllApi = async (path) => {
    ViewData.append("AdminID", localStorage.getItem("id"));
    ViewData.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}${path}`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: ViewData,
      });

      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
      }

      if (data && data.status === "Success") {
        return data && data.result;
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };
  function handleRedirect(districtName) {
    window.open(`/#/view-area-case/${districtName}`, "_blank");
  }

  function handleRedirectClient(districtName) {
    window.open(`/#/view-area-client/${districtName}`, "_blank");
  }

  const getNoteView = async () => {
    const [
      view,
      data,
      invoicegrapData,
      receiptgraphData,
      clientData,
      countries,
      counselData,
    ] = await Promise.all([
      viewData("Notes/ViewNotes"),
      permission(27),
      getAllApi("Invoice/Invoicegrap"),
      getAllApi("Receipt/PaidAmountgrap"),
      getClientData("Analysis/ViewAnalysisByclient"),
      getClientData("Analysis/ViewAnalysisByArea"),
      getClientData("Analysis/ViewAnalysisBycounsil"),
      getCountriesC(),
    ]);

    const newData =
      counselData &&
      counselData.invoice.map((obj) => {
        if (typeof obj.value === "string") {
          return { ...obj, value: parseInt(obj.value), id: parseInt(obj.id) };
        }
        return obj;
      });

    const newDataReciept =
      counselData &&
      counselData.reciept.map((obj) => {
        if (typeof obj.value === "string") {
          return { ...obj, value: parseInt(obj.value), id: parseInt(obj.id) };
        }
        return obj;
      });

    setclientData(clientData);
    setCountries(countries);
    setInvoiceCounsel(newData);
    setReceiptCounsel(newDataReciept);

    setInvoicegrap(invoicegrapData && invoicegrapData.current);
    setInvoicegrapPrivious(invoicegrapData && invoicegrapData.privious);

    setInvoiceCounselName(invoicegrapData && invoicegrapData.counselname);
    setReceiptCounselName(receiptgraphData && receiptgraphData.counselname);

    setReceiptGraph(receiptgraphData && receiptgraphData.current);
    setReceiptGraphPrivious(receiptgraphData && receiptgraphData.privious);

    if (view === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } else {
      setCaseLeadData(view);
    }

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }

    setIsView(data && data.result[0].IsView);
  };

  //        -------- TABLE ---------

  const columnHelper = createMRTColumnHelper();
  const columns = [
    columnHelper.accessor("CaseType", {
      footer: "Total",
      header: "Case Type",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CaseType === null ||
            row.row.original.CaseType === ""
              ? "-"
              : row.row.original.CaseType}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseTypeCount", {
      footer: `${totalCaseType}`,
      header: (
        <>
          Case Type Count
          <br />
          Total Case (Open + Close Case)
        </>
      ),
      Cell: (row) => {
        return (
          <div style={{ minWidth: "350px" }} className="upperCase">
            <a
              title="Total Case"
              href={`#/view-casetype/${row.row.original.CaseTypeID}`}
              className="visuald_case"
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              {row.row.original.TotalCases === null ||
              row.row.original.TotalCases === ""
                ? "-"
                : row.row.original.TotalCases}
            </a>{" "}
            ({" "}
            <a
              title="Open Case"
              href={`#/view-casetype/${row.row.original.CaseTypeID}_1`}
              className="visuald_case"
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              {row.row.original.OpenCase === null ||
              row.row.original.OpenCase === ""
                ? "-"
                : row.row.original.OpenCase}
            </a>{" "}
            +{" "}
            <a
              title="Close Case"
              href={`#/view-casetype/${row.row.original.CaseTypeID}_0`}
              className="visuald_case"
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              {row.row.original.CloseCase === null ||
              row.row.original.CloseCase === ""
                ? "-"
                : row.row.original.CloseCase}
            </a>{" "}
            )
          </div>
        );
      },
    }),
  ];

  const table = useMaterialReactTable({
    columns,
    data: countriesC && countriesC,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    enableStickyHeader: true,
    enableRowNumbers: true,
    muiCircularProgressProps: {
      color: "primary",
      thickness: 5,
      size: 55,
      style: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000,
      },
    },
    muiSkeletonProps: {
      animation: "pulse",
      height: 20,
    },
    muiPaginationProps: {
      rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      ></Box>
    ),
  });

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getNoteView();
    }
  }, []);

  const ViewCData = new FormData();

  const getCountriesC = async () => {
    ViewCData.append("AdminID", localStorage.getItem("id"));
    ViewCData.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(
        `${baseUrl}Analysis/ViewAnalysisByCaseType`,
        {
          method: "POST",

          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
          body: ViewCData,
        }
      );

      const data = await response.json();

      setCountriesC(data.result);
      setSupremeData(data.result1);

      let sum =
        data &&
        data.result.reduce(function (prev, current) {
          return prev + +current.TotalCases;
        }, 0);
      setTotalCaseType(sum);

      toast.info(data.result.message);
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const numDataPoints = clientData.length;
  const countrieslength = countries.length;

  // ------------- client data ------------

  const getClientData = async (url) => {
    clientForm.append("AdminID", localStorage.getItem("id"));
    clientForm.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}${url}`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: clientForm,
      });

      const data = await response.json();
      return data && data.result;
    } catch (error) {
      console.error(error);
    }
  };

  const filterApiInvoice = async () => {
    clientForm.append("AdminID", localStorage.getItem("id"));
    clientForm.append("UserID", localStorage.getItem("uId"));
    clientForm.append("Froms", formDate);
    clientForm.append("Tos", toDate);
    // clientForm.append("Month", month);
    // Case1.append("Year", year);
    try {
      const response = await fetch(
        `${baseUrl}Analysis/FilterAnalysisBycounsil`,
        {
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
          body: clientForm,
        }
      );

      const data = await response.json();
      setInvoiceCounsel(data && data.result.invoice);
    } catch (error) {
      console.error(error);
    }
  };

  const filterApiReceipt = async () => {
    clientForm.append("AdminID", localStorage.getItem("id"));
    clientForm.append("UserID", localStorage.getItem("uId"));
    clientForm.append("Froms", reFormDate);
    clientForm.append("Tos", reToDate);
    try {
      const response = await fetch(
        `${baseUrl}Analysis/FilterAnalysisBycounsilrec`,
        {
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
          body: clientForm,
        }
      );

      const data = await response.json();
      setReceiptCounsel(data && data.result.invoice);
    } catch (error) {
      console.error(error);
    }
  };

  const xAxisDomain = [0, 10000];

  const colors = [
    "#ff0080",
    "#8B008B",
    "#3333cc",
    "#EE82EE",
    "#00802b",
    "#b37700",
    "#20B2AA",
    "#483D8B",
    "#FFA07A",
    "#8B4513",
    "#9932CC",
    "#556B2F",
    "#FFB6C1",
    "#2F4F4F",
    "#8B4513",
    "#800000",
    "#483D8B",
    "#800080",
    "#8A2BE2",
    "#008080",
    "#2E8B57",
    "#8B008B",
    "#483D8B",
    "#A52A2A",
    "#4B0082",
    "#696969",
  ];

  const color2 = [
    "#b37700",
    "#004d4d",
    "#8A2BE2",
    "#4d004d",
    "#FF69B4",
    "#20B2AA",
    "#ff0080",
    "#00802b",
    "#FFA07A",
    "#20B2AA",
    "#87CEFA",
    "#483D8B",
    "#800080",
    "#556B2F",
    "#EE82EE",
    "#D2B48C",
    "#008080",
    "#D8BFD8",
    "#40E0D0",
    "#EE82EE",
    "#9ACD32",
  ];

  useEffect(() => {
    const chartContainers = document.querySelectorAll(".chartContainer");

    chartContainers.forEach((container) => {
      const chart = new CanvasJS.Chart(container, {
        data: [
          {
            type: "pie",
            cursor: "pointer",
            dataPoints:
              invoiceCounsel &&
              invoiceCounsel.map((point, index) => ({
                label: point.name,
                y: point.value,
                color: colors[index % colors.length],
              })),
          },
        ],
      });

      chart.options.data[0].click = function (e) {
        const dataPoint = e.dataPoint;
 
        const selectedPoint1 =
          invoiceCounsel &&
          invoiceCounsel.find((point) => point.name === dataPoint.label);
        if (formDate) {
          window.open(
            `/#/view-invoice-filter/${selectedPoint1.id}/${formDate}/${toDate}`,
            "_blank"
          );
        } else {
          const selectedPoint =
            invoiceCounsel &&
            invoiceCounsel.find((point) => point.name === dataPoint.label);
          if (selectedPoint) {
            window.open(
              `/#/view-invoice/${
                selectedPoint.id
              }/_${new Date().getFullYear()}`,
              "_blank"
            );
          }
        }
      };

      chart.render();
      return () => {
        chart.destroy();
      };
    });
  }, [invoiceCounsel]);

  useEffect(() => {
    const chartContainers = document.querySelectorAll(
      ".chartContainer_receipt"
    );

    chartContainers.forEach((container) => {
      const chart = new CanvasJS.Chart(container, {
        data: [
          {
            type: "pie",
            cursor: "pointer",
            dataPoints:
              receiptCounsel &&
              receiptCounsel.map((point, index) => ({
                label: point.name,
                y: point.value,
                color: color2[index % color2.length],
              })),
          },
        ],
      });

      chart.options.data[0].click = function (e) {
        const dataPoint = e.dataPoint;
        const selectedPoint1 =
          receiptCounsel &&
          receiptCounsel.find((point) => point.name === dataPoint.label);
        if (reFormDate) {
          window.open(
            `/#/view-receipt-filter/${selectedPoint1.id}/${reFormDate}/${reToDate}`,
            "_blank"
          );
        } else {
          const selectedPoint =
            receiptCounsel &&
            receiptCounsel.find((point) => point.name === dataPoint.label);
          if (selectedPoint) {
            window.open(
              `/#/view-receipt/${
                selectedPoint.id
              }/_${new Date().getFullYear()}`,
              "_blank"
            );
          }
        }
      };

      chart.render();
      return () => {
        chart.destroy();
      };
    });
  }, [receiptCounsel]);

  const filterApiinvoiceCancel = () => {
    setFormDate("");
    setToDate("");
    getNoteView();
  };

  const filterApiReceiptCancel = () => {
    setReFormDate("");
    setReToDate("");
    getNoteView();
  };

  return (
    <DocumentTitle title="ANALYSIS | LEGAL CRM">
      <div>
        <div className="wraper">
          <Header />
          <div className="mainWraper">
            <Sidebar />
            <div className="pageBody">
              <div className="pageContent">
                <div className="PageTitle">
                  <h3>Case Analysis</h3>
                </div>

                <div
                  style={{
                    display: isView === "0" ? "none" : "block",
                  }}
                  className="report_crmgraph"
                >
                  {/* ------------ Pie Charts ------------ */}

                  <div className="row">
                    <div className="col-md-6">
                      <div className="myCard">
                        <div className="cardHeader">
                          <h4>COUNSEL REVENUE INSIGHTS (INVOICE) </h4>
                          <div
                            className="input_field_analysis"
                            style={{ display: "flex", gap: "5px" }}
                          >
                            <input
                              required
                              value={formDate}
                              onChange={(e) => setFormDate(e.target.value)}
                              type="date"
                            />
                            <input
                              required
                              value={toDate}
                              onChange={(e) => setToDate(e.target.value)}
                              type="date"
                            />
                            <div className="filter_button form-group1">
                              <button type="submit" onClick={filterApiInvoice}>
                                Search
                              </button>
                              <button
                                type="button"
                                onClick={filterApiinvoiceCancel}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="piecharts row">
                          <div
                            className="chartContainer"
                            style={{ width: "100%", height: "400px" }}
                          ></div>
                          <div>
                            <div className="counsel">
                              <ul>
                                {invoiceCounsel &&
                                  invoiceCounsel.map((val, index) => (
                                    <li
                                      key={index}
                                      className="circle"
                                      style={{
                                        color: colors[index % colors.length],
                                      }}
                                    >
                                      <p
                                        style={{
                                          backgroundColor:
                                            colors[index % colors.length],
                                        }}
                                      ></p>
                                      &nbsp;
                                      {`${
                                        val.name.charAt(0).toLocaleUpperCase() +
                                        val.name.slice(1)
                                      } - ${parseFloat(
                                        (val.value / sum) * 100
                                      ).toFixed(1)} %`}
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* ------------ Pie Charts For Receipt -----------  */}

                    <div className="col-md-6">
                      <div className="myCard">
                        <div className="cardHeader">
                          <h4>COUNSEL REVENUE INSIGHTS (RECEIPT)</h4>
                          <div
                            className="input_field_analysis"
                            style={{ display: "flex", gap: "5px" }}
                          >
                            <input
                              value={reFormDate}
                              required
                              onChange={(e) => setReFormDate(e.target.value)}
                              type="date"
                            />
                            <input
                              required
                              value={reToDate}
                              onChange={(e) => setReToDate(e.target.value)}
                              type="date"
                            />
                            <div className="filter_button form-group1">
                              <button type="submit" onClick={filterApiReceipt}>
                                Search
                              </button>
                              <button
                                type="button"
                                onClick={filterApiReceiptCancel}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="piecharts row">
                          <div
                            className="chartContainer_receipt"
                            style={{ width: "100%", height: "400px" }}
                          ></div>
                          <div>
                            <div className="counsel">
                              <ul>
                                {receiptCounsel &&
                                  receiptCounsel.map((val, index) => (
                                    <li
                                      key={index}
                                      className="circle"
                                      style={{
                                        color: color2[index % color2.length],
                                      }}
                                    >
                                      <p
                                        style={{
                                          backgroundColor:
                                            color2[index % color2.length],
                                        }}
                                      ></p>
                                      &nbsp;
                                      {`${
                                        val.name.charAt(0).toLocaleUpperCase() +
                                        val.name.slice(1)
                                      } - ${parseFloat(
                                        (val.value / receiptSum) * 100
                                      ).toFixed(1)} %`}
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ------------Invoice multiline graph ------------ */}

                  <div className="row">
                    <div className="col-md">
                      <div className="myCard">
                        <div className="cardHeader">
                          <h4>ANALYSING 2 YEARS INVOICE AMOUNT </h4>
                        </div>
                             <Line
                                  width="100%"
                                  height={30} 
                                  data={chartDataInvoice}
                                  options={{
                                    plugins: {
                                      legend: {
                                        display: true,
                                        position: "bottom"
                                      }
                                    },
                          onClick: redirectToInvoice,
                                  }}
                                />
                      </div>
                    </div>
                  </div>

                  {/* ------------Received multiline graph ------------ */}

                  <div>
                    <div className="row">
                      <div className="col-md">
                        <div className="myCard">
                          <div className="cardHeader">
                            <h4>ANALYSING 2 YEARS RECEIVED AMOUNT </h4>
                          </div>
                                <Line
                                  width="100%"
                                  height={30} 
                                  data={chartDataReceipt}
                                  options={{
                                    plugins: {
                                      legend: {
                                        display: true,
                                        position: "bottom"
                                      }
                                    },
                                  onClick: redirectToReceipt,
                                  }}
                                />
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />

                  {/* -------------------------- case count ------------------------ */}

                  <div className="row">
                    <div className="col-md-12">
                      <div className="graph_chartwrap event_wrappad1">
                        <div
                          className="main_dashboardhead"
                          style={{ background: "#7a0000" }}
                        >
                          <h4>AREA AND CASE COUNTS REPORTS</h4>
                          <a href="/#/view-case">
                            <img
                              src="assets/img/icons/dots.png"
                              title="All Case"
                            />
                          </a>
                        </div>
                        <div className="dash_wrapgraph">
                          <AreaChart
                            width={countrieslength * 100}
                            height={300}
                            data={countries}
                            margin={{
                              top: 10,
                              right: 30,
                              left: 0,
                              bottom: 0,
                            }}
                          >
                            <defs>
                              <linearGradient
                                id="colorUv"
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1"
                              >
                                <stop
                                  offset="5%"
                                  stopColor="#8884d8"
                                  stopOpacity={0.8}
                                />
                                <stop
                                  offset="95%"
                                  stopColor="#8884d8"
                                  stopOpacity={0}
                                />
                              </linearGradient>
                              <linearGradient
                                id="colorPv"
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1"
                              >
                                <stop
                                  offset="5%"
                                  stopColor="#82ca9d"
                                  stopOpacity={0.8}
                                />
                                <stop
                                  offset="95%"
                                  stopColor="#82ca9d"
                                  stopOpacity={0}
                                />
                              </linearGradient>
                            </defs>
                            <XAxis dataKey="District" />
                            <YAxis domain={xAxisDomain} />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip position={{ x: 250 }} />
                            <Area
                              type="monotone"
                              dataKey="District"
                              stroke="#8884d8"
                              fillOpacity={1}
                              fill="url(#colorUv)"
                            />
                            <Area
                              type="monotone"
                              dataKey="CaseCount"
                              stroke="#82ca9d"
                              fillOpacity={1}
                              fill="url(#colorPv)"
                            />
                            {countries.map((entry, index) => (
                              <rect
                                key={`rect-${index}`}
                                x={
                                  index *
                                  ((countrieslength * 100) / countries.length)
                                }
                                y={0}
                                width={
                                  (countrieslength * 100) / countries.length
                                }
                                height={300}
                                fill="transparent"
                                onClick={() => handleRedirect(entry.DistrictID)}
                                style={{ cursor: "pointer" }}
                              />
                            ))}
                          </AreaChart>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  {/* -------------------------- client count ------------------------ */}

                  <div className="row">
                    <div className="col-md-12">
                      <div className="graph_chartwrap event_wrappad1">
                        <div
                          className="main_dashboardhead"
                          style={{ background: "#7a0000" }}
                        >
                          <h4>AREA AND CUSTOMER COUNTS REPORTS</h4>
                          <a href="/#/view-case">
                            <img
                              src="assets/img/icons/dots.png"
                              title="All Case"
                            />
                          </a>
                        </div>
                        <div className="dash_wrapgraph">
                          <AreaChart
                            width={numDataPoints * 100}
                            height={300}
                            data={clientData}
                            margin={{
                              top: 10,
                              right: 30,
                              left: 0,
                              bottom: 0,
                            }}
                          >
                            <defs>
                              <linearGradient
                                id="colorUv"
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1"
                              >
                                <stop
                                  offset="5%"
                                  stopColor="#8884d8"
                                  stopOpacity={0.8}
                                />
                                <stop
                                  offset="95%"
                                  stopColor="#8884d8"
                                  stopOpacity={0}
                                />
                              </linearGradient>
                              <linearGradient
                                id="colorPv"
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1"
                              >
                                <stop
                                  offset="5%"
                                  stopColor="#82ca9d"
                                  stopOpacity={0.8}
                                />
                                <stop
                                  offset="95%"
                                  stopColor="#82ca9d"
                                  stopOpacity={0}
                                />
                              </linearGradient>
                            </defs>
                            <XAxis dataKey="District" />
                            <YAxis domain={xAxisDomain} />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip position={{ x: 250 }} />
                            <Area
                              type="monotone"
                              dataKey="District"
                              stroke="#8884d8"
                              fillOpacity={1}
                              fill="url(#colorUv)"
                            />
                            <Area
                              type="monotone"
                              dataKey="ClientCount"
                              stroke="#82ca9d"
                              fillOpacity={1}
                              fill="url(#colorPv)"
                            />
                            {clientData.map((entry, index) => (
                              <rect
                                key={`rect-${index}`}
                                x={
                                  index *
                                  ((numDataPoints * 100) / clientData.length)
                                }
                                y={0}
                                width={
                                  (numDataPoints * 100) / clientData.length
                                }
                                height={300}
                                fill="transparent"
                                onClick={() =>
                                  handleRedirectClient(entry.DistrictID)
                                }
                                style={{ cursor: "pointer" }}
                              />
                            ))}
                          </AreaChart>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <div>
                  <div className="row">
                    {/* <!-- Case Start  --> */}
                    
                    <div className="col-md-12">
                      <div className="myCard">
                        <div className="cardHeader">
                          <div style={{ display: "flex" }}>
                            <h4>Dairy Allotted</h4>
                          </div>
                        </div>

                        <div className="cardBody">
                          <table className="myTable">
                            <thead>
                              <tr className="top_table">
                                <th>S No.</th>
                                <th>Court Name.</th>
                                <th>Dairy Count</th>
                                <th>Dairy Allotted</th>
                                <th>Dairy Not Allotted</th>
                              </tr>
                            </thead>
                            <tbody>
                              {supremeData &&
                                supremeData.map((val, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{val.CourtName}</td>
                                      <td>   <a
                                                      href="#/view-diary-count/3"
                                          
                                                target="_blank"
                                                style={{ cursor: "pointer" }}
                                                title="Dairy Count"
                                              >
                                                {val.DairyCount}
                                              </a></td>
                                               <td>   <a
                                            href="#/view-diary-count/1"
                                          
                                                target="_blank"
                                                style={{ cursor: "pointer" }}
                                                title="Dairy Allow"
                                              >
                                                {val.DairyAllow}
                                              </a></td>
                                               <td>   <a
                                                href="#/view-diary-count/2"
                                
                                                target="_blank"
                                                style={{ cursor: "pointer" }}
                                                title="Dairy Not Allow"
                                              >
                                                {val.DairyNotAllow}
                                              </a></td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* <!--Case  Ends  --> */}
                  </div>
                </div>
                <br />
                <div
                  className="row"
                  style={{
                    display: isView === "0" ? "none" : "block",
                  }}
                >
                  <div className="col-md-12">
                    <div className="myCard">
                      <div className="cardHeader">
                        <h4>CASE COUNTS AND CASE TYPE REPORTS</h4>
                        <a href="/#/view-case">
                          <img
                            src="assets/img/icons/dots.png"
                            title="All Case"
                          />
                        </a>
                      </div>
                      <MaterialReactTable table={table} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>

        {/*-------- Toster -----------*/}

        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </DocumentTitle>
  );
};
export default Analysis;
