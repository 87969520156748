import React from 'react';

const Preloader = () => {
  return (
    <div className="preloader">
      <span><img src="assets/img/preloader.gif" alt="" /></span>
    </div>
  );
  };

export default Preloader;
