import React, { useState, useRef } from "react";
import autoTable from "jspdf-autotable";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import Preloader from "./Preloader";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv } from "export-to-csv";
import {
  deleteUser,
  userDetails,
  activeStatus,
  permission,
  getClient,
  withUidApi,
  clearForm,
  formateDateTime,
} from "../services/api.service";
import Select from "react-select";
import DocumentTitle from "react-document-title";
import { format } from "date-fns";
import jsPDF from "jspdf";
import Footer from "./Footer";

const Notes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [saveButton, setSaveButton] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);
  //---------- CLOSE POPUP VARIABLE ----------

  const currentDate = new Date().toISOString().split("T")[0];
  const closeButton = document.querySelector(".btn_popup");
  const closeEdit = document.querySelector(".e_popup");
  const closeDelete = document.querySelector(".dnote");
  const closeView = document.querySelector(".ab");

  //----------  NOTE VARIABLE ----------

  const baseUrl = useContext(url);
  const navigate = useNavigate();
  const [dSummary, setDSummary] = useState("");
  const [dDetails, setDDetails] = useState("");
  const [dFileNo, setDFileNo] = useState("");
  const [dCaseNo, setDCaseNo] = useState("");
  const [dCaseYear, setDCaseYear] = useState("");
  const [dCaseName, setDCaseName] = useState("");
  const [dClientName, setDClientName] = useState("");
  const [dClientLastName, setDClientLastName] = useState("");
  const [dClientCompany, setDClientCompany] = useState("");
  const [dCounselName, setDCounselName] = useState("");
  const [dCoCounselName, setDCoCounselName] = useState("");
  const [isAdd, setIsAdd] = useState("0");
  const [isEdit, setIsEdit] = useState("0");
  const [isView, setIsView] = useState("0");
  const [isDelete, setIsDelete] = useState("0");
  const [isStatus, setIsStatus] = useState("0");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  //---------- ADD NOTE VARIABLE ----------

  const [caseLeadData, setCaseLeadData] = useState([]);
  const [excel, setExcel] = useState([]);
  const [fileNo, setFileNo] = useState("");
  const [summary, setSummary] = useState("");
  const [status, setStatus] = useState("");
  const [detail, setDetail] = useState("");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [filterUser, setFilterUser] = useState("");
  const [delId, setDelId] = useState("");
  const [active, setActive] = useState(false);
  const [fCaseId, setFCaseId] = useState("");
  const [fFromDate, setFFromDate] = useState("");
  const filterform = new FormData();
  const [fToDate, setFToDate] = useState("");
  const [fClientID, setFClientID] = useState("");
  const [fClientOption, setFClientOption] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const [optionsEvent, setOptionsEvent] = useState([]);

  //---------- EDIT NOTE VARIABLE ----------

  const [editId, setEditId] = useState("");
  const [editDate, setEditDate] = useState("");
  const [editfileNo, setEditFileNo] = useState("");
  const [editDetail, setEditDetail] = useState("");
  const [editsummary, setEditSummary] = useState("");
  const formData = new FormData();
  const editCaseLead = new FormData();

  const [open, setOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const handleExportButtonClick = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const handleToggleColumn = (columnName) => {
    const currentIndex = selectedColumns.indexOf(columnName);
    const newSelectedColumns = [...selectedColumns];

    if (currentIndex === -1) {
      newSelectedColumns.push(columnName);
    } else {
      newSelectedColumns.splice(currentIndex, 1);
    }

    setSelectedColumns(newSelectedColumns);
  };

  //---------- VIEW NOTE API ----------

  const getNoteView = async () => {
    const [view, data, cdata] = await Promise.all([
      withUidApi("Notes/ViewNotes"),
      permission(8),
      getClient("Client/ViewClient"),
      getFileNo(),
      setLoading(true),
    ]);

    if (view === null) {
      setLoading(false);
      setCaseLeadData([]);
    } else {
      if (view === "token") {
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        setLoading(false);
        setCaseLeadData(view);
      }
    }

    const clientData = cdata && cdata.filter((item) => item.Status === "1");

    const Mapped =
      clientData &&
      clientData.map((item) => ({
        value: item.Id,
        label: `${item.FirstName} ${item.LastName}`,
      }));
    setFClientOption(Mapped);

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }

    setIsAdd(data && data.result[0].IsAdd);
    setIsEdit(data && data.result[0].IsEdit);
    setIsView(data && data.result[0].IsView);
    setIsStatus(data && data.result[0].IsStatus);
    setIsDelete(data && data.result[0].IsDelete);
  };

  //---------- DELETE NOTE ----------

  const deleteCaseLead = async () => {
    const data = await deleteUser(delId, "Notes/DeleteNotes");
    if (data === "token") {
      setTimeout(() => {
        closeDelete.click();
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      closeDelete.click();
      filterActive ? filterApi() : getNoteView();
    }
  };

  //---------- ACTIVE DEACTIVE NOTE ----------

  const deActive = async (row) => {
    const data = await activeStatus(row, "Notes/NotesStatus");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      filterActive ? filterApi() : getNoteView();
    }
  };

  //---------- NOTE DETAIL ----------

  const details = async (id) => {
    const data = await userDetails(id, "Notes/ViewNotesById");
    if (data === "token") {
      setTimeout(() => {
        closeView.click();
        navigate("/");
      }, 3000);
    } else {
      setStatus(data && data.result[0].Status);
      setDSummary(data && data.result[0].Summary);
      setDDetails(data && data.result[0].Details);
      setDFileNo(data && data.result[0].FileNo);
      setDCaseNo(data && data.result[0].CaseNo);
      setDCaseYear(data && data.result[0].CaseYear);
      setDCaseName(data && data.result[0].CaseName);
      setDClientName(data && data.result[0].ClientName);
      setDClientLastName(data && data.result[0].ClientLastName);
      setDClientCompany(data && data.result[0].ClientCompany);
      setDCounselName(data && data.result[0].Counsel);
      setDCoCounselName(data && data.result[0].Cocounsel);
    }
  };

  /** Get File Name  */
  const Case = new FormData();
  const [optionsCase, setOptionsCase] = useState([]);
  const getFileNo = async () => {
    Case.append("AdminID", localStorage.getItem("id"));
    Case.append("UserID", localStorage.getItem("uId"));

    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: Case,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }

      if (data && data.result[0].message === "Success") {
        const Mapped = data.result.map((item) => ({
          value: item.CaseID,
          label: `${item.FileNo} / ${item.CaseNo} / ${item.CaseName} / ${item.CaseYear}`,
        }));
        setOptionsCase(Mapped);
        setOptionsEvent(Mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setFileNo("");
    } else {
      setFileNo(selectedOption.value);
    }
  };

  //---------- NOTE DETAILS ----------

  const getById = async (id) => {
    const data = await userDetails(id, "Notes/ViewNotesById");
    if (data === "token") {
      setTimeout(() => {
        closeEdit.click();
        navigate("/");
      }, 3000);
    } else {
      setEditId(data && data.result[0].Id);
      setEditSummary(data && data.result[0].Summary);
      setEditDetail(data && data.result[0].Details);
      setEditFileNo(data && data.result[0].CaseID);
      setEditDate(data && data.result[0].FormattedDate);
    }
  };

  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  // ---------- FILTER API ---------

  const filterApi = async () => {
    setLoading(true);
    filterform.append("AdminID", localStorage.getItem("id"));
    filterform.append("UserID", localStorage.getItem("uId"));
    filterform.append("CaseID", fCaseId);
    filterform.append("ClientID", fClientID);
    filterform.append("From", fFromDate);
    filterform.append("To", fToDate);
    try {
      const response = await fetch(`${baseUrl}Notes/ViewNotesfilter`, {
        method: "POST",
        body: filterform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 2000);
      }

      if (
        data &&
        data.result.length &&
        data &&
        data.result[0].message === "Success"
      ) {
        setLoading(false);
        setCaseLeadData(data.result);
      } else {
        setLoading(false);
        setCaseLeadData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterCancel = () => {
    clearForm();
    setFileNo("");
    setFCaseId("");
    setFClientID("");
    setFFromDate("");
    setFToDate("");
    getNoteView();
    setFilterActive(false);
  };

  const handleFilterCase = (selectedOption) => {
    if (selectedOption === null) {
      setFCaseId("");
    } else {
      setFCaseId(selectedOption.value);
    }
  };

  const handleFilterClient = (selectedOption) => {
    if (selectedOption === null) {
      setFClientID("");
    } else {
      setFClientID(selectedOption.value);
    }
  };

  //---------- SUBMIT NOTE DATA ----------

  const submitHandler = async (event) => {
    event.preventDefault();
    setSaveButton(false);
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    formData.append("Summary", summary.toUpperCase());
    formData.append("Details", detail.toUpperCase());
    formData.append("Date", date);
    formData.append("CaseID", fileNo);
    try {
      const response = await fetch(`${baseUrl}Notes/AddNotes`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].message === "Success") {
        toast.success("Note added successfully");
        closeButton.click();
        handleClose();
        filterActive ? filterApi() : getNoteView();
        setSummary("");
        setDetail("");
        setDate("");
        setFileNo("");
        setSaveButton(true);
      } else {
        toast.error(data.result.message);
        setSaveButton(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //---------- EDIT NOTE ----------

  const submitEditHandler = async (event) => {
    event.preventDefault();
    editCaseLead.append("ID", editId);
    editCaseLead.append("AdminID", localStorage.getItem("id"));
    editCaseLead.append("UpdatedBy", localStorage.getItem("UserId"));
    editCaseLead.append("CaseID", editfileNo);
    editCaseLead.append("Date", editDate);
    editCaseLead.append("Details", editDetail.toUpperCase());
    editCaseLead.append("Summary", editsummary.toUpperCase());
    try {
      const response = await fetch(`${baseUrl}Notes/EditNotes`, {
        method: "POST",
        body: editCaseLead,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeEdit.click();
          navigate("/");
        }, 3000);
      }
      if (data.result.length && data.result[0].code === "200") {
        toast.success(" Updated successfully");
        closeEdit.click();
        filterActive ? filterApi() : getNoteView();
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //---------- TABLE ----------
  const columnHelper = createMRTColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      header: "Action",
      Cell: (row) => {
        return (
          <>
            <div style={{ display: "flex", gap: "15px" }}>
              {isEdit !== "0" && (
                <span
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalLongEdit"
                  onClick={() => getById(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/edit.svg"
                    alt="Edit"
                    title="Edit"
                  />
                </span>
              )}

              <span
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongDetail"
                onClick={() => details(row.row.original.Id)}
              >
                <img
                  src="assets/img/icons/eye.svg"
                  alt="Details"
                  title="Details"
                />
              </span>
              <span>
                <a
                  style={{
                    cursor: "pointer",
                    display: isStatus === "0" ? "none" : "block",
                  }}
                  onClick={() => deActive(row.row.original)}
                >
                  {row.row.original.Status === "1" ? (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Up.svg"
                      title="Status"
                    />
                  ) : (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Down.svg"
                      title="Status"
                    />
                  )}
                </a>
              </span>
              {isDelete !== "0" && (
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style={{ cursor: "pointer" }}
                  onClick={() => setDelId(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/delete.svg"
                    alt="Delete"
                    title="Delete"
                  />
                </span>
              )}
            </div>
          </>
        );
      },
    }),

    columnHelper.accessor("Date", {
      header: "Date",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.Date === null ||
            row.row.original.Date === "0000-00-00"
              ? "-"
              : new Date(row.row.original.Date).toLocaleDateString("en-GB")}
          </div>
        );
      },
    }),

    columnHelper.accessor("FileNo", {
      header: "File No",
    }),

    columnHelper.accessor("CaseNo", {
      header: "Case No",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CaseNo === null || row.row.original.CaseNo === ""
              ? "-"
              : row.row.original.CaseNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseName", {
      header: "Case Name",
      Cell: (row) => {
        return (
          <div style={{ minWidth: "350px" }} className="upperCase">
            <a
              title="Case Details"
              href={`#/view-casedetails/${row.row.original.CaseID}`}
              className="visuald_case"
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              {row.row.original.CaseName}
            </a>
          </div>
        );
      },
    }),

    columnHelper.accessor("ClientName", {
      header: "Client",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.ClientName}
            <br />
            {row.row.original.ClientlastName}
            <br />
            {row.row.original.MobileNo}
          </div>
        );
      },
    }),
    columnHelper.accessor("Details", {
      header: "Details",
      Cell: (row) => {
        return (
          <div
            className="upperCase"
            style={{
              display: " -webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}
          >
            {row.row.original.Details === null ||
            row.row.original.Details === ""
              ? "-"
              : row.row.original.Details}
          </div>
        );
      },
    }),
    columnHelper.accessor("Summary", {
      header: "Summary",
      Cell: (row) => {
        return (
          <div
            className="upperCase"
            style={{
              display: " -webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}
          >
            {row.row.original.Summary === null ||
            row.row.original.Summary === ""
              ? "-"
              : row.row.original.Summary}
          </div>
        );
      },
    }),

    columnHelper.accessor("UpdatedBy", {
      header: "Last-Modified",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.UpdatedBy === null ||
            row.row.original.UpdatedBy === ""
              ? "-"
              : row.row.original.UpdatedBy}
            <br />
            {formateDateTime(row.row.original.UpdatedDate)}
          </div>
        );
      },
    }),

    columnHelper.accessor("Status", {
      header: "Status",
      Cell: (row) => {
        return (
          <div>{row.row.original.Status === "1" ? "ACTIVE" : "DEACTIVE"}</div>
        );
      },
    }),

    columnHelper.accessor("AddedBy", {
      header: "Added By",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.AddedBy === null ||
            row.row.original.AddedBy === ""
              ? "-"
              : row.row.original.AddedBy}
            <br />
            {formateDateTime(row.row.original.CreatedDate)}
          </div>
        );
      },
    }),
  ];

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getNoteView();
    }
  }, []);

  // ---------DataTable-----

  const excelData =
    caseLeadData &&
    caseLeadData.map((obj) => {
      const { Id, code, message, Status, AdminID, ...rest } = obj;
      return rest;
    });

  const handleExportData = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });

    const formattedDate = new Date().toISOString().slice(0, 10);
    const filename = `Note_${formattedDate}.csv`;

    const csv = generateCsv(csvConfig)(excelData);
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const columnsToExport = [
      { id: "serialNo", header: "S No." },
      ...columns.filter((column) => selectedColumns.includes(column.id)),
    ];

    const tableHeaders = columnsToExport.map((c) => c.header);

    const formattedDate = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const filename = `Notes|LegalCRM_${formattedDate}.pdf`;
    const addWatermarkToFirstPage = () => {
      const watermarkHeight = 20;

      doc.setFontSize(10);
      doc.text(
        10,
        watermarkHeight + -10,
        `Generated by : ${localStorage.getItem("name")} `
      );
      doc.text(172, watermarkHeight + -10, `Date: ${formattedDate}`);
      doc.setFontSize(16);
      doc.text("Notes Table", 84, 20);
    };
    const watermarkHeight = 20;

    addWatermarkToFirstPage();
    const tableData = rows.map((row, index) => {
      const formattedDateInPdf = row.original["Date"]
        ? format(new Date(row.original["Date"]), "dd/MM/yyyy")
        : "";

      return [
        { content: (index + 1).toString(), styles: { fontStyle: "bold" } }, // Serial number
        ...columnsToExport.slice(1).map((column) => {
          // Exclude the first column (serial number) when mapping
          if (column.id === "Date") {
            return formattedDateInPdf;
          }

          const value =
            column.id in row.original ? row.original[column.id] : "";
          return value !== null && value !== undefined ? value : "";
        }),
      ];
    });
    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY: watermarkHeight + 5,
    });

    // Add a heading to the table

    // Save the PDF to a Blob
    const pdfBlob = doc.output("blob");

    // Create a blob URL
    const blobUrl = URL.createObjectURL(pdfBlob);

    // Open the URL in a new tab
    window.open(blobUrl, "_blank");

    // Optionally revoke the object URL after opening the new tab
    URL.revokeObjectURL(blobUrl);
    // doc.save(filename);
  };

  const table = useMaterialReactTable({
    columns,
    data: caseLeadData,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    initialState: {
      columnVisibility: {
        Status: false,
        AddedBy: false,
        UpdatedBy: false,
        UpdatedDate: false,
        CreatedDate: false,
      },
    },
    enableStickyHeader: true,
    enableRowNumbers: true,
    muiPaginationProps: {
      rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },
    state: {
      isLoading: loading,
    },
    muiCircularProgressProps: {
      color: "primary",
      thickness: 5,
      size: 55,
      style: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000,
      },
    },
    muiSkeletonProps: {
      animation: "pulse",
      height: 20,
    },
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export Excel
        </Button>

        <Button
          disabled={
            !table || table.getPrePaginationRowModel().rows.length === 0
          }
          onClick={handleExportButtonClick}
          startIcon={<FileDownloadIcon />}
        >
          Export PDF
        </Button>
        <Dialog open={open} onClose={handleClose1}>
          <DialogTitle>Select Columns To Export</DialogTitle>
          <DialogContent>
            {columns.map((column) =>
              column.header !== "Action" ? (
                <div key={column.id}>
                  <Checkbox
                    checked={selectedColumns.includes(column.id)}
                    onChange={() => handleToggleColumn(column.id)}
                  />
                  {column.header}
                </div>
              ) : (
                ""
              )
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose1}>Cancel</Button>
            <Button
              onClick={() =>
                handleExportRows(table.getPrePaginationRowModel().rows)
              }
            >
              Export
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    ),
  });

  return (
    <>
      <DocumentTitle title=" NOTES | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  {/* <div className="accountContainer userListContainer"> */}
                  <div className="pageContent">
                    <div className="PageTitle">
                      <h3>Notes List</h3>

                      <a
                        style={{
                          display: isAdd === "0" ? "none" : "block",
                        }}
                        type="button"
                        className="btn btn-primary btn_client headbtn"
                        data-toggle="modal"
                        data-target="#exampleModalLong"
                      >
                        Add Note <img src="assets/img/icons/add.svg" />
                      </a>
                    </div>

                    <div
                      className="filter_type add_wrap"
                      style={{
                        display: isView === "0" ? "none" : "block",
                      }}
                    >
                      <div className="form_flex1 flex_wrap  caseMobile">
                        <div className="form-group1 zIndex1">
                          <label>File No.</label>

                          <Select
                            className="basic-single"
                            placeholder="Please Select "
                            classNamePrefix="select"
                            onChange={handleFilterCase}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={optionsEvent}
                          />
                        </div>
                        <div className="form-group1 zIndex2">
                          <label>Client Name</label>

                          <Select
                            className="basic-single"
                            placeholder="Please Select "
                            classNamePrefix="select"
                            onChange={handleFilterClient}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={fClientOption}
                          />
                        </div>
                        <div className="form-group1">
                          <label>From Date</label>
                          <input
                            autoComplete="off"
                            className="name"
                            type="date"
                            value={fFromDate}
                            onChange={(e) => setFFromDate(e.target.value)}
                          />
                        </div>
                        <div className="form-group1">
                          <label>To Date</label>
                          <input
                            autoComplete="off"
                            className="name"
                            type="date"
                            value={fToDate}
                            onChange={(e) => setFToDate(e.target.value)}
                          />
                        </div>
                        <div className="form-group1 caseMobile btnGroup">
                          <div className="btn_main casebtn">
                            <button
                              type="submit"
                              className="btn btn_client"
                              onClick={filterApi}
                            >
                              Search
                            </button>
                            <button
                              type="button"
                              className="btn btn_client"
                              onClick={filterCancel}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: isView === "0" ? "none" : "block",
                      }}
                      className="searDesign"
                    >
                      <MaterialReactTable table={table} />
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <Footer />
            </div>

            {/*-------- DELETE NOTE  --------*/}

            <div
              className="modal fade sc_modal"
              id="exampleModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close dnote"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <h4>Delete Row</h4>
                    <p>Are you sure you want to delete note ?</p>
                    <ul>
                      <li>
                        <a
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </a>
                      </li>
                      <li>
                        <a
                          className="btn btn-danger"
                          onClick={() => deleteCaseLead(delId)}
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- ADD NOTE FORM --------*/}

            <div
              className="modal fade"
              id="exampleModalLong"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
              show={show}
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Add Note</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitHandler} ref={formRef}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">
                              File No. <text className="m_star">*</text>
                            </label>
                            <Select
                              required
                              className="basic-single"
                              placeholder="Please Select "
                              classNamePrefix="select"
                              onChange={handleSelectChange}
                              name="color"
                              options={optionsCase}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              Date <text className="m_star">*</text>
                            </label>
                            <input
                              className="name"
                              type="date"
                              placeholder=""
                              name="uname"
                              value={date}
                              onChange={(e) => setDate(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">Summary</label>
                            <textarea
                              className="name"
                              type="text"
                              placeholder="Enter Summary"
                              name="uname"
                              value={summary}
                              onChange={(e) => setSummary(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">Details</label>
                            <textarea
                              className="name"
                              type="text"
                              rows={10}
                              placeholder="Enter Details"
                              name="uname"
                              value={detail}
                              onChange={(e) => setDetail(e.target.value)}
                            />
                          </div>
                        </div>
                        {saveButton ? (
                          <div className="btn_main caseMobile">
                            <button type="submit" className="btn_save">
                              Save
                            </button>
                            <button
                              type="button"
                              data-dismiss="modal"
                              className="btn_save btn_cancle"
                            >
                              Cancel
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- EDIT NOTE FORM --------*/}

            <div
              className="modal fade"
              id="exampleModalLongEdit"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Edit Note</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close e_popup btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitEditHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">
                              File No.<text className="m_star">*</text>
                            </label>
                            <select
                              name="cars"
                              id="cars"
                              onChange={(e) => setEditFileNo(e.target.value)}
                              required
                            >
                              {optionsCase && optionsCase.length ? (
                                optionsCase.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.CaseID}
                                      selected={val.value == editfileNo}
                                    >
                                      {val.label}
                                      {/* {val.FileNo && `${val.FileNo} /`}{" "}
                                    {val.CaseName && `${val.CaseName} /`}{" "}
                                    {val.CaseYear} */}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              Date<text className="m_star">*</text>
                            </label>
                            <input
                              type="date"
                              placeholder=""
                              name="uname"
                              value={editDate}
                              onChange={(e) => setEditDate(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">Summary</label>
                            <textarea
                              type="text"
                              placeholder="Enter Summary"
                              name="uname"
                              value={editsummary}
                              onChange={(e) => setEditSummary(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">Details</label>
                            <textarea
                              type="text"
                              rows={10}
                              placeholder="Enter Details"
                              name="uname"
                              value={editDetail}
                              onChange={(e) => setEditDetail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_save btn_cancle"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- NOTE DETAILS --------*/}

            <div
              className="modal fade"
              id="exampleModalLongDetail"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "60%", height: "700px" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Note Details</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close ab btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            File No. &nbsp;: &nbsp;{" "}
                            {dFileNo == null ? "-" : dFileNo}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Status &nbsp;:&nbsp;{" "}
                            {status === "1" ? "ACTIVE" : "DEACTIVE"}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Case No &nbsp;: &nbsp;{" "}
                            {dCaseNo === "" ? "-" : dCaseNo}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Case Year &nbsp;:&nbsp;{" "}
                            {dCaseYear === "" ? "-" : dCaseYear}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Case Name &nbsp;: &nbsp;{" "}
                            {dCaseName === "" ? "-" : dCaseName}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Client Name &nbsp;:&nbsp;{" "}
                            {dClientName == null ? "-" : dClientName}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Client Last Name &nbsp;: &nbsp;{" "}
                            {dClientLastName == null ? "-" : dClientLastName}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Client Company &nbsp;:&nbsp;{" "}
                            {dClientCompany == null ? "-" : dClientCompany}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Counsel Name &nbsp;: &nbsp;{" "}
                            {dCounselName == null ? "-" : dCounselName}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Co Counsel Name &nbsp;:&nbsp;{" "}
                            {dCoCounselName === null ? "-" : dCoCounselName}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Summary &nbsp;: &nbsp;{" "}
                            {dSummary == null ? "-" : dSummary}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Details &nbsp;:&nbsp;{" "}
                            {dDetails == null ? "-" : dDetails}{" "}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- Toster -----------*/}

            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        )}
      </DocumentTitle>
    </>
  );
};
export default Notes;
