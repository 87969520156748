import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import html2pdf from "html2pdf.js";
import DocumentTitle from "react-document-title";
import { url } from "../index";
import { useContext } from "react";
import {formateDateTime} from "../services/api.service";

function CasePdf() {
  const { id } = useParams();
  const IDRef = useRef(id);

  useEffect(() => {
    IDRef.current = id;
    getById(id);
  }, [id]);

  const navigate = useNavigate();
  const ViewCDData = new FormData();
  const baseUrl = useContext(url);
  const [data, setData] = useState([]);
  const [FileNo, setFileNo] = useState([]);
  const formattedDate = new Date().toLocaleDateString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const handlePrint = () => {
    const pdfContent = document.getElementById("pdf-content");
    const pdfOptions = {
      filename: `${FileNo}/Case/${formattedDate}.pdf`,
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf(pdfContent, pdfOptions);
  };

  const getById = async (id) => {
    ViewCDData.append("AdminID", `${localStorage.getItem("id")}`);
    ViewCDData.append("ID", id);
    try {
      const response = await fetch(`${baseUrl}Cases/ViewCaseByIdOLD`, {
        method: "POST",
        body: ViewCDData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message === "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }

      if (data && data.result.Case[0].message === "Success") {
        setData(data && data.result.Case);
        setFileNo(data && data.result.Case[0].FileNo);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handlePrint();
  }, [data]);

  return (
    <DocumentTitle title="PDF | LEGAL CRM">
      <div>
        {data &&
          data.map((val, index) => (
            <div className="printWrapper a4" id="pdf-content">
            <div className="pagePrint">
            <div className="pageHeader">

              <div className="logoPrint col-md-4">
                    <img
                      src={`https://api.lawyerhunt.in/uploaded/logo/${val.CompanyLogo}`}
                      alt="LOGO"
                    />
                  </div>
                </div>
              <br />
              <div className="printHeading">
                <h3>CASE</h3>
              </div>
            </div>
    
            <div className="invoiceNum">
              <div className="invoiceNumValue">
                <strong>
                File No: <span>{val.FileNo} </span>
                </strong>
              </div>
              <div className="invoiceNumValue">
                <strong>
                  
                  Date : <span>{val.Date === "0000-00-00" ? "" : formateDateTime(val.Date)}</span>
                </strong>
              </div>
            </div>
            <br/>
            <div className="bankDetailBox">
              <div className="leftDet">
                <h3>CLIENT DETAILS</h3>
                <div className="printNames">
                  <strong>Client Name:</strong>
                  <span>{val.ClientName} {val.ClientLastName}</span>
                </div>
                <div className="printNames">
                  <strong>Email:</strong>
                  <span>{val.Email}</span>
                </div>
                <div className="printNames">
                  <strong>Mobile No:</strong>
                  <span>{val.MobileNo}</span>
                </div>
                <div className="printNames">
                  <strong>Address:</strong>
                  <span>{val.Address}</span>
                </div>
                <div className="printNames">
                  <strong>Client Company:</strong>
                  <span>{val.ClientCompany}</span>
                </div>
              </div>
            </div>    
            <div className="bankDetailBox">
              <div className="leftDet">
                <h3>LEGAL MATTER :</h3>
                <div className="printNames">
                  <strong>Case No:</strong>
                  <span>{val.CaseNo}</span>
                </div>
                <div className="printNames">
                  <strong>Case Name:</strong>
                  <span>{val.CaseName}</span>
                </div>
                <div className="printNames">
                  <strong>Court Name:</strong>
                  <span className="upperCase">{val.CourtName === null || val.CourtName === "" ? "" : val.CourtName} {val.CityCourtName === null || val.CityCourtName === "" ? "" : val.CityCourtName}</span>
                </div>
              </div>
            </div>
    

    
            <div className="bankDetailBox">
              <div className="leftDet">
                <h3>CASE INCHARGE DETAILS :</h3>
                <div className="printNames">
                  <strong>Name:</strong>
                  <span>{val.Counsel}</span>
                </div>
                <div className="printNames">
                  <strong>Court Name:</strong>
                  <span className="upperCase">{val.CourtName === null || val.CourtName === "" ? "" : val.CourtName}</span>
                </div>
              </div>
            </div>
    
      
            <div className="sign">
              <h5>
                {val.Counsel === "" || val.Counsel === null ? "-" : val.Counsel}
              </h5>
            </div>
          <br/>
          <br/>
            <div
                style={{
                  display:
                    (val.CompanyFooter1 === null ||
                      val.CompanyFooter1 === "") &&
                    (val.CompanyFooter2 === null ||
                      val.CompanyFooter2 === "") &&
                    (val.CompanyFooter3 === null || val.CompanyFooter3 === "")
                      ? "none"
                      : "block",
                }}
              >
                <hr />
                <div className="footer">
                  {val.CompanyFooter1 === null || val.CompanyFooter1 === "" ? (
                    ""
                  ) : (
                    <div className="footer_invoice">
                      <h4>{val.Name}</h4>
                      <p>{val.CompanyFooter1}</p>
                    </div>
                  )}

                  {val.CompanyFooter2 === null || val.CompanyFooter2 === "" ? (
                    ""
                  ) : (
                    <div className="footer_invoice">
                      <h4>{val.Name}</h4>
                      <p>{val.CompanyFooter2}</p>
                    </div>
                  )}

                  {val.CompanyFooter3 === null || val.CompanyFooter3 === "" ? (
                    ""
                  ) : (
                    <div className="footer_invoice">
                      <h4>{val.Name}</h4>
                      <p>{val.CompanyFooter3}</p>
                    </div>
                  )}
                </div>
              </div>
          </div>
          ))}
      </div>
    </DocumentTitle>
  );
}

export default CasePdf;
