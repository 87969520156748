import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useNavigate } from "react-router";
import DocumentTitle from "react-document-title";
import { permission, formatDate1 } from "../services/api.service";
import Preloader from "./Preloader";
import Footer from "./Footer";

const Config = () => {
  //        -------- POPUP CLOSE VARIABLE ---------

  const baseUrl = useContext(url);
  const navigate = useNavigate();

  //        -------- VARIABLE ---------

  const [editEmail, seteditEmail] = useState("");
  const [editReceiptPrefix, setEditReceiptPrefix] = useState("");
  const [editInvoicePrefix, seteditInvoicePrefix] = useState("");
  const [editWhatsapp, seteditWhatsapp] = useState("");
  const [invoiceTerms, setInvoiceTerms] = useState("");
  const [recieptTerms, setRecieptTerms] = useState("");
  const [Address, setAddress] = useState("");
  const [companyPer, setCompanyPer] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyUrl, setCompanyUrl] = useState("");
  const [gst, setGst] = useState("");
  const [dCompanyCode, setDCompanyCode] = useState("");
  const [dActivateFrom, setDActivateFrom] = useState("");
  const [dActivateTo, setDActivateTo] = useState("");
  const [dContactPersonName, setDContactPersonName] = useState("");
  const [dCompanyPer, setDCompanyPer] = useState("");
  const [dCompanyName, setDCompanyName] = useState("");
  const [isView, setIsView] = useState("0");
  const [companyFooter1, setCompanyFooter1] = useState("");
  const [companyFooter2, setCompanyFooter2] = useState("");
  const [companyFooter3, setCompanyFooter3] = useState("");
  const formEditData = new FormData();
  const configForm = new FormData();

  // -----------CONFIG API ---------

  const configApi = async () => {
    configForm.append("AdminID", localStorage.getItem("id"));
    
    try {
      const response = await fetch(`${baseUrl}Config/ViewConfigById`, {
        method: "POST",
        body: configForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }

      if (data && data.result[0].message === "Success") {
        setDCompanyPer(data && data.result[0].CompanyPer);
        setCompanyFooter1(data && data.result[0].CompanyFooter1 === null ? "" : data.result[0].CompanyFooter1);
        setCompanyFooter2(data && data.result[0].CompanyFooter2 === null ? "" : data.result[0].CompanyFooter2);
        setCompanyFooter3(data && data.result[0].CompanyFooter3 === null ? "" : data.result[0].CompanyFooter3);
        setCompanyPer(data && data.result[0].CompanyPer);
        setDActivateFrom(data && data.result[0].ActivateFrom);
        setDActivateTo(data && data.result[0].ActivateTo);
        setDCompanyName(data && data.result[0].CompanyName);
        setDCompanyCode(data && data.result[0].CompanyCode);
        setDContactPersonName(data && data.result[0].ContactPersonName);
        seteditInvoicePrefix(
          data && data.result[0].InvoicePrefix === null
            ? ""
            : data && data.result[0].InvoicePrefix
        );
        setEditReceiptPrefix(
          data && data.result[0].ReceiptPrefix === null
            ? ""
            : data && data.result[0].ReceiptPrefix
        );
        seteditEmail(
          data && data.result[0].CompanyEmail === null
            ? ""
            : data && data.result[0].CompanyEmail
        );
        setCompanyUrl(
          data && data.result[0].CompanyUrl === null
            ? ""
            : data && data.result[0].CompanyUrl
        );
        setBase64Data(
          data && data.result[0].CompanyLogo === null
            ? ""
            : data && data.result[0].CompanyLogo
        );
        setCompanyLogo(
          data && data.result[0].CompanyLogo === null
            ? ""
            : data && data.result[0].CompanyLogo
        );
        setInvoiceTerms(
          data && data.result[0].InvoiceTerms === null
            ? ""
            : data && data.result[0].InvoiceTerms
        );
        setRecieptTerms(
          data && data.result[0].RecieptTerms === null
            ? ""
            : data && data.result[0].RecieptTerms
        );

        setAddress(
          data && data.result[0].Address === null
            ? ""
            : data && data.result[0].Address
        );
        setGst(
          data && data.result[0].GST === null ? "" : data && data.result[0].GST
        );
        seteditWhatsapp(data && data.result[0].whatsapp);
      } else {
        toast.error(data && data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        -------- EDIT CONFIG ---------

  const submitEditHandler = async (event) => {
    event.preventDefault();
    formEditData.append("AdminID", localStorage.getItem("id"));
    formEditData.append("UpdatedBy", localStorage.getItem("UserId"));
    formEditData.append("ID", localStorage.getItem("UserId"));
    formEditData.append("ReceiptPrefix", editReceiptPrefix.toUpperCase());
    formEditData.append("InvoicePrefiex", editInvoicePrefix.toUpperCase());
    formEditData.append("CompanyEmail", editEmail);
    formEditData.append("Whatsapp", editWhatsapp);
    formEditData.append("CompanyPer", companyPer);
    formEditData.append("CompanyLogo", base64Data);
    formEditData.append("CompanyUrl", companyUrl);
    formEditData.append("GST", gst);
    formEditData.append("Address", Address.toUpperCase());
    formEditData.append("InvoiceTerms", invoiceTerms.toUpperCase());
    formEditData.append("RecieptTerms", recieptTerms.toUpperCase());
    formEditData.append("CompanyFooter1", companyFooter1.toUpperCase());
    formEditData.append("CompanyFooter2", companyFooter2.toUpperCase());
    formEditData.append("CompanyFooter3", companyFooter3.toUpperCase());

    try {
      const response = await fetch(`${baseUrl}Config/EditConfig`, {
        method: "POST",
        body: formEditData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
          // 'content-type': 'multipart/form-data',
        },
      });

      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }

      if (data.result.length && data.result[0].code === "200") {
        toast.success("Config Update successfully");
        setEditReceiptPrefix("");
        seteditInvoicePrefix("");
        seteditEmail("");
        seteditWhatsapp("");
        setCompanyLogo("");
        setCompanyUrl("");
        setGst("");
        setCompanyPer("");
        setInvoiceTerms("");
        setRecieptTerms("");
        setAddress("");
        setCompanyFooter1("");
        setCompanyFooter2("");
        setCompanyFooter3("");
        configApi();
      } else {
        toast.error(data.result.message);
        toast.error(data.result[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const permissionData = async () => {
    const data = await permission(23);
    setIsView(data && data.result[0].IsView);
  };



  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      configApi();
      permissionData();
    }
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  // File Upload By Disha (25-11-23)
  const [base64Data, setBase64Data] = useState("");
  const handleFileChange = (event) => {
    // Access the selected file from the input
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Set the selected file and its base64-encoded data
        // setCompanyLogo(reader.result);
        setBase64Data(reader.result);
      };
      console.log("Base64-encoded data:", base64Data);
      // Read the file as a data URL (base64)
      reader.readAsDataURL(file);
    }
    // setCompanyLogo(file);
  };
  
  const handleKeyPress1 = (e) => {
    const charCode = e.which || e.keyCode;
    if (
      charCode < 48 ||
      charCode > 57 ||
      (charCode !== 8 && charCode !== 47 && e.target.value.length >= 14)
    ) {
      e.preventDefault();
    }
  };

  const handleGSTchange = (e) => {
    const newValue = e.target.value.toUpperCase(); // Convert input value to uppercase
    setGst(newValue);
  };

  const fileUrl = `https://api.lawyerhunt.in/uploaded/logo/${companyLogo}`;

  return (
    <>
      <DocumentTitle title="CONFIG | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  <div className="pageContent">
                    <div className="PageTitle">
                      <h3>Company Setting</h3>
                    </div>
                    <div
                      className="popup_modal"
                      style={{ display: isView === "0" ? "none" : "block" }}
                    >
                      <div className="dashed_popup">
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <h4>Company Code &nbsp;- &nbsp; {dCompanyCode}</h4>
                          </div>
                          <div className="form-group">
                            <h4>Company Name &nbsp;- &nbsp; {dCompanyName}</h4>
                          </div>

                          <div className="form-group">
                            <h4>
                              Contact Person Name &nbsp;- &nbsp;{" "}
                              {dContactPersonName}
                            </h4>
                          </div>
                        </div>

                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <h4>Company Per &nbsp;- &nbsp; {dCompanyPer} %</h4>
                          </div>
                          <div className="form-group">
                            <h4>
                              Activate From &nbsp;- &nbsp;{" "}
                              {formatDate1(dActivateFrom)}
                            </h4>
                          </div>

                          <div className="form-group">
                            <h4>
                              Activate To &nbsp;- &nbsp;{" "}
                              {formatDate1(dActivateTo)}
                            </h4>
                          </div>
                        </div>

                        <form role="form" onSubmit={submitEditHandler}>
                          <div className="form_flex caseMobile">
                            <div className="form-group">
                              <label htmlFor="uname">
                                Company Percentage{" "}
                                <text className="m_star">*</text>
                              </label>
                              <input
                                required
                                type="text"
                                placeholder="Enter Company Percentage"
                                value={companyPer}
                                className="name"
                                onChange={(e) => setCompanyPer(e.target.value)}
                              />
                            </div>

                            <div className="form-group">
                              <label htmlFor="uname">Invoice Prefix</label>
                              <input
                                type="text"
                                placeholder="Enter Invoice  Prefix"
                                className="name"
                                value={editInvoicePrefix}
                                onChange={(e) =>
                                  seteditInvoicePrefix(e.target.value)
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="uname">Receipt Prefix</label>
                              <input
                                type="text"
                                placeholder="Enter Receipt Prefix"
                                className="name"
                                value={editReceiptPrefix}
                                onChange={(e) =>
                                  setEditReceiptPrefix(e.target.value)
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="uname">Company URL</label>
                              <input
                                type="text"
                                placeholder="Enter Company URL"
                                className="name"
                                value={companyUrl}
                                onChange={(e) => setCompanyUrl(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="form_flex caseMobile">
                            <div className="form-group">
                              <label htmlFor="uname">Email</label>
                              <input
                                type="text"
                                placeholder="Enter Email"
                                className="name"
                                value={editEmail}
                                onChange={(e) => seteditEmail(e.target.value)}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="uname">GST</label>
                              <input
                                type="text"
                                placeholder="Enter GST"
                                className="name"
                                value={gst}
                                onChange={handleGSTchange}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="uname">Address</label>
                              <input
                                type="text"
                                placeholder="Enter Address"
                                className="name"
                                value={Address}
                                onChange={(e) => setAddress(e.target.value)}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="uname">Whatsapp</label>
                              <input
                               maxlength={10}
                               onKeyPress={handleKeyPress1}
                                type="text"
                                placeholder="Enter Whatsapp"
                                className="name"
                                value={editWhatsapp}
                                onChange={(e) =>
                                  seteditWhatsapp(e.target.value)
                                }
                              />
                            </div>
                          </div>

                          <div className="form_flex caseMobile">
                            <div className="form-group">
                              <label htmlFor="uname">Invoice Terms</label>
                              <textarea
                                type="text"
                                placeholder="Enter Invoice Terms"
                                className="name"
                                value={invoiceTerms}
                                onChange={(e) =>
                                  setInvoiceTerms(e.target.value)
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="uname">Reciept Terms</label>
                              <textarea
                                type="text"
                                placeholder="Enter Reciept Terms"
                                className="name"
                                value={recieptTerms}
                                onChange={(e) =>
                                  setRecieptTerms(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="form_flex caseMobile">
                            <div className="form-group">
                              <label htmlFor="uname">Company Logo</label>
                              <input
                                type="file"
                                placeholder="Enter File"
                                onChange={handleFileChange}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="uname">Doc. Footer Section 1</label>
                              <textarea
                                type="text"
                                placeholder="Enter Doc. Footer Section 1"
                                className="name"
                                value={companyFooter1}
                                onChange={(e) =>
                                  setCompanyFooter1(e.target.value)
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="uname">Doc. Footer Section 2</label>
                              <textarea
                                type="text"
                                placeholder="Enter Doc. Footer Section 2"
                                className="name"
                                value={companyFooter2}
                                onChange={(e) =>
                                  setCompanyFooter2(e.target.value)
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="uname">Doc. Footer Section 3</label>
                              <textarea
                                type="text"
                                placeholder="Enter Doc. Footer Section 3"
                                className="name"
                                value={companyFooter3}
                                onChange={(e) =>
                                  setCompanyFooter3(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <h4>Logo :</h4>
                          {companyLogo === "" ? (
                            ""
                          ) : (
                            <div className="imageDiv">
                              <img src={fileUrl} className="logo-image" />
                            </div>
                          )}

                          <div
                            className="btn_main caseMobile"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button type="submit" className="btn_save">
                              UPDATE
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer/>
            </div>

            {/*-------- Toster -----------*/}

            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        )}
      </DocumentTitle>
      {/*-------- TOASTER ---------*/}

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Config;
