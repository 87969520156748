import React, { useState, useRef } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { permission, viewData } from "../services/api.service";
import DocumentTitle from "react-document-title";
import Preloader from "./Preloader";
import Footer from "./Footer";

const CaseReport = () => {
  //        -------- POPUP CLOSE VARIABLE ---------
  const [casesFilter, setCasesFilter] = useState(false);
  const [fCasesFromDate, setFCasesFromDate] = useState("");
  const [fCasesToDate, setFCasesToDate] = useState("");
  const [fCasesPeriod, setFCasesPeriod] = useState("1");
  const [fCasesGroupBy, setFCasesGroupBy] = useState("");
  const [casesData, setCasesData] = useState([]);
  const [borderLeftCaseFlow, setBorderLeftCaseFlow] = useState(false);

  // Amount And Description

  const baseUrl = useContext(url);
  const navigate = useNavigate();

  // ----------- VIEW Client---------

  const filterform = new FormData();
  const form = new FormData();
  const [isView, setIsView] = useState(false);
  const [caseLeadData, setCaseLeadData] = useState([]);
  const [loading, setLoading] = useState(true);

  //        -------- VIEW TASK DATA ---------

  const viewData = async (path) => {
    form.append("AdminID", localStorage.getItem("id"));
    form.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}${path}`, {
        method: "POST",
        body: form,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }

      if (data && data.result[0].EarningReport.length) {
        setCasesData(data && data.result[0].EarningReport);
      } else {
        setCasesData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCaseView = async () => {
    const [data] = await Promise.all([
      permission(28),
      viewData("Cases/ViewTotalOpenCloseCases"),
    ]);

    setIsView(data && data.result[0].IsView === "1" ? true : false);
  };

  const filterCasesCancel = () => {
    setBorderLeftCaseFlow(false);
    document.getElementById("dynamicColumnCases").innerHTML = "";
    setFCasesPeriod("1");
    setFCasesGroupBy("");
    setFCasesFromDate("");
    setFCasesToDate("");
    setCasesFilter(false);
    getCaseView();
  };

  const filterCasesApi = async () => {
    filterform.append("AdminID", localStorage.getItem("id"));
    filterform.append("UserID", localStorage.getItem("uId"));
    filterform.append("Period", fCasesPeriod);
    filterform.append("GroupBy", fCasesGroupBy);
    filterform.append("From", fCasesFromDate);
    filterform.append("To", fCasesToDate);
    try {
      const response = await fetch(`${baseUrl}Cases/TotalOpenCloseCases`, {
        method: "POST",
        body: filterform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }

      if (fCasesGroupBy === "1") {
        setBorderLeftCaseFlow(true);
        document.getElementById("dynamicColumnCases").innerHTML = "City";
      } else {
        if (fCasesGroupBy === "2") {
          setBorderLeftCaseFlow(true);
          document.getElementById("dynamicColumnCases").innerHTML = "Incharge";
        } else {
          if (fCasesGroupBy === "3") {
            setBorderLeftCaseFlow(true);
            document.getElementById("dynamicColumnCases").innerHTML =
              "Co-counsel";
          } else {
            if (fCasesGroupBy === "4") {
              setBorderLeftCaseFlow(true);
              document.getElementById("dynamicColumnCases").innerHTML =
                "Case-Type";
            } else {
              setBorderLeftCaseFlow(false);
              document.getElementById("dynamicColumnCases").innerHTML = "";
              document.getElementById("dynamicCases").innerHTML = "";
            }
          }
        }
      }

      if (data && data.result[0].CaseDetailsFlow.length) {
        setCasesData(data.result[0].CaseDetailsFlow);
      } else {
        setCasesData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const casesFilterShow = () => {
    setCasesFilter(true);
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getCaseView();
    }
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <>
      <DocumentTitle title="CASEREPORT | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div className="wraper">
            <Header />
            <div className="mainWraper">
              <Sidebar />
              <div className="pageBody">
                <div className="pageContent">
                  <div className="PageTitle">
                    <h3>Case Report List</h3>
                  </div>
                  {isView && (
                    <div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="myCard">
                            <div className="cardHeader">
                              <h4>Case Report</h4>

                              <div
                                style={{
                                  display: "flex",
                                  gap: "20px",
                                }}
                              >
                                {casesFilter && (
                                  <div className="form_flex_home">
                                    <div className="form-g">
                                      <select
                                        onChange={(e) =>
                                          setFCasesPeriod(e.target.value)
                                        }
                                      >
                                        <option value="1">Daily</option>
                                        <option value="2">Monthly</option>
                                        <option value="3">Yearly</option>
                                      </select>
                                    </div>
                                    <div className="form-g">
                                      <select
                                        onChange={(e) =>
                                          setFCasesGroupBy(e.target.value)
                                        }
                                      >
                                        <option value="">Group By</option>
                                        <option value="1">City</option>
                                        <option value="2">Case Counsel</option>
                                        <option value="3">Co-Counsel</option>
                                        <option value="4">Case Type</option>
                                      </select>
                                    </div>
                                    <div className="form-g">
                                      <input
                                        autoComplete="off"
                                        className="name"
                                        type="date"
                                        value={fCasesFromDate}
                                        onChange={(e) =>
                                          setFCasesFromDate(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div className="form-g">
                                      <input
                                        autoComplete="off"
                                        className="name"
                                        type="date"
                                        value={fCasesToDate}
                                        onChange={(e) =>
                                          setFCasesToDate(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div>
                                      <div className="filter_button">
                                        <button
                                          type="button"
                                          onClick={filterCasesApi}
                                        >
                                          Search
                                        </button>
                                        <button
                                          type="button"
                                          onClick={filterCasesCancel}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "20px",
                                  }}
                                >
                                  <img
                                    type="button"
                                    style={{
                                      display:
                                        casesFilter === true ? "none" : "block",
                                    }}
                                    onClick={casesFilterShow}
                                    src="assets/img/icons/Vector.svg"
                                    alt=""
                                    title="Filter"
                                  />
                                  <div class="rytContent">
                                    <a href="/#/view-case">
                                      <img
                                        src="assets/img/icons/dots.png"
                                        alt=""
                                        title="All Case"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {casesData && casesData.length ? (
                              <div className="cardBody">
                                <table className="myTable">
                                  <thead>
                                    <tr>
                                      <th>Period</th>
                                      <th
                                        id="dynamicColumnCases"
                                        style={{
                                          borderLeft: borderLeftCaseFlow
                                            ? ""
                                            : "none",
                                        }}
                                      ></th>
                                      <th>Booked Cases</th>
                                      <th>Close Cases</th>
                                      <th>Filed Cases</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {casesData &&
                                      casesData.map((val, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>{val.Period}</td>
                                            <td id="dynamicCases">
                                              {val.GroupField === null
                                                ? ""
                                                : val.GroupField}
                                            </td>
                                            <td>
                                              <a
                                                href={`/#/view-bookedCases/1_${fCasesGroupBy}_${
                                                  val.Period
                                                }_${
                                                  fCasesGroupBy === "1"
                                                    ? val.CityID
                                                    : fCasesGroupBy === "2" ||
                                                      fCasesGroupBy === "3"
                                                    ? val.CounselID
                                                    : fCasesGroupBy === "4"
                                                    ? val.CaseTypeID
                                                    : ""
                                                }`}
                                                className="visuald_case"
                                                target="_blank"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {val.OpenCaseCount}
                                              </a>
                                            </td>
                                            <td>
                                              <a
                                                href={`/#/view-bookedCases/2_${fCasesGroupBy}_${
                                                  val.Period
                                                }_${
                                                  fCasesGroupBy === "1"
                                                    ? val.CityID
                                                    : fCasesGroupBy === "2" ||
                                                      fCasesGroupBy === "3"
                                                    ? val.CounselID
                                                    : fCasesGroupBy === "4"
                                                    ? val.CaseTypeID
                                                    : ""
                                                }`}
                                                className="visuald_case"
                                                target="_blank"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {val.ClosedCaseCount}
                                              </a>
                                            </td>
                                            <td>
                                              <a
                                                href={`/#/view-bookedCases/""_${fCasesGroupBy}_${
                                                  val.Period
                                                }_${
                                                  fCasesGroupBy === "1"
                                                    ? val.CityID
                                                    : fCasesGroupBy === "2" ||
                                                      fCasesGroupBy === "3"
                                                    ? val.CounselID
                                                    : fCasesGroupBy === "4"
                                                    ? val.CaseTypeID
                                                    : ""
                                                }`}
                                                className="visuald_case"
                                                target="_blank"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {val.FilingCaseCount}
                                              </a>
                                            </td>
                                          </tr>
                                        );
                                      })}

                                    <tr></tr>
                                  </tbody>
                                </table>
                              </div>
                            ) : (
                              <p className="case_detail_table">
                                Data not found
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Footer />
          </div>
        )}
      </DocumentTitle>

      {/*-------- TOASTER ---------*/}

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default CaseReport;
