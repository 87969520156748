import React, { useEffect, useMemo, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useContext } from "react";
import { url } from "../index";
import Select from "react-select";
import { useNavigate, useParams } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import "jspdf-autotable";
import jsPDF from "jspdf";
import {
  formatDate1,
  getClient,
  userDetails,
  activeStatus,
  permission,
  formateDateTime,
} from "../services/api.service";
import { Link } from "react-router-dom";
import DocumentTitle from "react-document-title";
import Preloader from "./Preloader";
import { format } from "date-fns";
import { generateCsv, mkConfig } from "export-to-csv";
import autoTable from "jspdf-autotable";
import Footer from "./Footer";

const UserInvoice = () => {
  let { id, month_year } = useParams();

  const [counselName, setCounselName] = useState("");
  const [years, setYears] = useState("");
  const [saveButton, setSaveButton] = useState(true);
  const closeDelete = document.querySelector(".dInvoice");
  const closeButton = document.querySelector(".btn_popup");
  const closeEdit = document.querySelector(".e_popup");

  const getParamsData = (month_year) => {
    const data = month_year.split("_");

    const month = data[0];
    const year = data[1];
    setYears(year);


    getCaseData(month, year);
  };

  useEffect(() => {
    getParamsData(month_year);
  }, [month_year]);

  // Add Invoice
  const currentDate = new Date().toISOString().split("T")[0];
  const baseUrl = useContext(url);
  const navigate = useNavigate();
  const [dynamicRows, setDynamicRows] = useState([
    { Description: "", Amount: "" },
  ]);
  const [BankID, setBankID] = useState("");
  const [EditBankID, setEditBankID] = useState("");
  const [CaseID, setCaseID] = useState("");
  const [invoiceTem, setInvoiceTem] = useState([]);
  const [invoiceTemId, setInvoiceTemId] = useState([]);
  const [Dates, setDates] = useState(currentDate);
  const [remark, setRemark] = useState("");
  const [invoiceTotal, setInvoiceTotal] = useState("0");
  const [Discount, setDiscount] = useState("0");
  const [InvoiceAmount, setInvoiceAmount] = useState("0");
  const [fCaseId, setFCaseId] = useState("");
  const [filterActive, setFilterActive] = useState(false);
  const [fFromDate, setFFromDate] = useState("");
  const filterform = new FormData();
  const form = new FormData();
  const [fToDate, setFToDate] = useState("");
  const [fClientID, setFClientID] = useState("");
  const [fClientOption, setFClientOption] = useState([]);
  const [optionsEvent, setOptionsEvent] = useState([]);
  const [dAddedBy, setDAddedBy] = useState("");
  const [dCaseName, setDCaseName] = useState("");
  const [dCaseYear, setDCaseYear] = useState("");
  const [dClientName, setDClientName] = useState("");
  const [dRemark, setDRemark] = useState("");
  const [dDate, setDDate] = useState("");
  const [dDiscount, setDDiscount] = useState("");
  const [dInvoiceAmount, setDInvoiceAmount] = useState("");
  const [dInvoiceNo, setDInvoiceNo] = useState("");
  const [dTotalAmount, setDTotalAmount] = useState("");
  const [invoiceDetailData, setInvoiceDetailData] = useState([]);
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState("0");
  const [totalPaidAmount, setTotalPaidAmount] = useState("0");
  const [loading, setLoading] = useState(true);

  const [delId, setDelId] = useState("");
  const [isAdd, setIsAdd] = useState("0");
  const [isEdit, setIsEdit] = useState("0");
  const [isView, setIsView] = useState("0");
  const [isDelete, setIsDelete] = useState("0");
  const [isStatus, setIsStatus] = useState("0");
  const [data, setData] = useState([]);

  const [open, setOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const handleExportButtonClick = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const handleToggleColumn = (columnName) => {
    const currentIndex = selectedColumns.indexOf(columnName);
    const newSelectedColumns = [...selectedColumns];

    if (currentIndex === -1) {
      newSelectedColumns.push(columnName);
    } else {
      newSelectedColumns.splice(currentIndex, 1);
    }

    setSelectedColumns(newSelectedColumns);
  };
  const [totalAmount, setTotalAmount] = useState("0");
  const [PaidAmount, setPaidAmount] = useState("0");
  const [formData1, setFormData1] = useState([{ Description: "", Amount: "" }]);
  const [casesData, setCasesData] = useState([]);
  const Case1 = new FormData();

  const getCaseData = async (month, year) => {
    Case1.append("AdminID", localStorage.getItem("id"));
    Case1.append("UserID", id);
    Case1.append("Month", month);
    Case1.append("Year", year);
    // Case1.append("Froms", "");
    // Case1.append("Tos", "");

    try {
      const response = await fetch(`${baseUrl}Invoice/ViewInvoiceByCounsel`, {
        method: "POST",
        body: Case1,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      setCasesData(data.result);
      setCounselName(data && data.result[0].Counsel)

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (casesData && casesData.length > 0) {
      // Calculate total amount when casesData exists and is not empty
      const sum = casesData.reduce((acc, invoice) => {
        // Extract numeric values from the InvoiceAmount string and sum them
        const amounts = invoice.InvoiceAmount.match(/\d+\.\d+/g);
        if (amounts) {
          return (
            acc +
            amounts.reduce(
              (subtotal, amount) => subtotal + parseFloat(amount),
              0
            )
          );
        } else {
          return acc;
        }
      }, 0);
      setTotalAmount(sum);
    } else {
      // If casesData is empty or undefined, set total amount to 0
      setTotalAmount(0);
    }
  }, [casesData]);

  useEffect(() => {
    if (casesData && casesData.length > 0) {
      // Calculate total amount when invoices change
      const sum = casesData.reduce((acc, invoice) => {
        // Extract numeric values from the InvoiceAmount string and sum them
        const amounts = invoice.PaidAmount.match(/\d+\.\d+/g);
        if (amounts) {
          return (
            acc +
            amounts.reduce(
              (subtotal, amount) => subtotal + parseFloat(amount),
              0
            )
          );
        } else {
          return acc;
        }
      }, 0);
      setPaidAmount(sum);
    } else {
      setPaidAmount(0);
    }
  }, [casesData]);
  // View Invoice

  const getInvoiceView = async () => {
    const [view, data, iTemp, cdata] = await Promise.all([
      // withUidApi("Invoice/ViewInvoice"),
      permission(14),
      getClient("Master/ViewInvoiceTemplate"),
      getClient("Client/ViewClient"),
      getFileNo(),
      getBank(),
      setLoading(true),
    ]);

    if (view === null) {
      setLoading(false);

      setTotalInvoiceAmount("0");
      setTotalPaidAmount("0");
    } else {
      if (view === "token") {
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        setLoading(false);
      }
    }
  };

  // Edit Invoice

  const [EditID, setEditID] = useState("");
  const [EditCaseID, setEditCaseID] = useState("");
  const [editInvoiceTemId, setEditInvoiceTemId] = useState("");
  const [EditDate, setEditDate] = useState("");
  const [editRemark, setEditRemark] = useState("");
  const [editInvoiceTotal, setEditInvoiceTotal] = useState("");
  const [EditDiscount, setEditDiscount] = useState("");
  const [EditInvoiceAmount, setEditInvoiceAmount] = useState("");

  const EditformData = new FormData();
  const editInvoiceDelete = new FormData();
  const formData3 = new FormData();

  // for Only Number

  const handleKeyPress = (e) => {
    const charCode = e.which || e.keyCode;
    // Allow only numeric characters and backspace
    if (
      charCode < 48 ||
      charCode > 57 ||
      (charCode !== 8 && e.target.value.length >= 10)
    ) {
      e.preventDefault();
    }
  };

  const HandleEditSubmit = async (event) => {
    event.preventDefault();
    EditformData.append("ID", EditID);
    EditformData.append("AdminID", localStorage.getItem("id"));
    EditformData.append("CaseID", EditCaseID);
    EditformData.append("BankID", EditBankID);
    EditformData.append("InvoiceTemplateID", editInvoiceTemId);
    EditformData.append("Remark", editRemark.toUpperCase());
    EditformData.append("Discount", EditDiscount);
    EditformData.append("InvoiceAmount", EditInvoiceAmount);
    EditformData.append("TotalInvoice", editInvoiceTotal);
    EditformData.append("Date", EditDate);
    EditformData.append("UpdatedBy", localStorage.getItem("UserId"));

    try {
      const response = await fetch(`${baseUrl}Invoice/EditInvoice`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: EditformData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data.result.length && data.result[0].code === "200") {
        const InvoiceId = data && data.result[0].Id;

        editInvoiceDelete.append("AdminID", localStorage.getItem("id"));
        editInvoiceDelete.append("InvoiceID", InvoiceId);
        editInvoiceDelete.append("DeletedBy", localStorage.getItem("UserId"));

        try {
          const response3 = await fetch(
            `${baseUrl}Invoice/DeleteInvoiceDeatil`,
            {
              method: "POST",
              body: editInvoiceDelete,
              headers: {
                Authorization: `${localStorage.getItem("access_token")}`,
                Accept: "*/*",
              },
            }
          );

          const data3 = await response3.json();
          if (data3 && data3.result.message == "Session Time Expire.") {
            toast.error("Token expired please login again");
            setTimeout(() => {
              navigate("/");
            }, 3000);
          }
          if (data3 && data3.result.code === 200) {
            const rowsData = [];

            dynamicRows.forEach((item) => {
              rowsData.push({
                Description: item.Description,
                Amount: item.Amount,
              });
            });

            try {
              for (const rows of rowsData) {
                formData3.append("AdminID", localStorage.getItem("id"));
                formData3.append("InvoiceID", InvoiceId);
                formData3.append("Description", rows.Description.toUpperCase());
                formData3.append("Amount", rows.Amount);
                formData3.append("CreatedBy", localStorage.getItem("UserId"));

                const response2 = await fetch(
                  `${baseUrl}Invoice/AddInvoiceDetail`,
                  {
                    method: "POST",
                    body: formData3,
                    headers: {
                      Authorization: `${localStorage.getItem("access_token")}`,
                      Accept: "*/*",
                    },
                  }
                );

                const data2 = await response2.json();
                if (data2 && data2.result.message == "Session Time Expire.") {
                  toast.error("Token expired please login again");
                  setTimeout(() => {
                    navigate("/");
                  }, 3000);
                }
                if (data2.result.length && data2.result[0].code === "200") {
                  toast.success(" Updated successfully");
                  closeEdit.click();
                  getInvoiceView();
                  clearForm();
                } else {
                  toast.error(data2 && data2.result.message);
                }
              }
            } catch (error) {
              console.error(error);
            }
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //  Delete Invoice

  const deleteUser = async (id, path) => {
    form.append("AdminID", localStorage.getItem("id"));
    form.append("DeletedBy", localStorage.getItem("UserId"));
    form.append("ID", id);
    form.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}${path}`, {
        method: "POST",
        body: form,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        return "token";
      }

      if (data.result.code === 200) {
        toast.success(data.result.message);
        return true;
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteInvoice = async () => {
    const data = await deleteUser(delId, "Invoice/DeleteInvoice");
    if (data === true) {
      closeDelete.click();
      getInvoiceView();
    }
  };

  /** Get Bank  */
  // By Disha 9-01-24
  const CaseB = new FormData();
  const [Bank, setBank] = useState([]); // Initialize options state

  const getBank = async () => {
    CaseB.append("AdminID", localStorage.getItem("id"));
    try {
      const response = await fetch(`${baseUrl}Master/ViewBank`, {
        method: "POST",
        body: CaseB,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].message === "Success") {
        const Mapped = data.result.map((item) => ({
          value: item.Id,
          label: `${item.Beneficiary} / ${item.Bank} / ${item.Branch}`,
        }));
        setBank(Mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };
  /** Get File Name  */
  // By Disha 16-09-23
  const Case = new FormData();
  const [optionsCase, setOptionsCase] = useState([]); // Initialize options state

  const getFileNo = async () => {
    Case.append("AdminID", localStorage.getItem("id"));
    Case.append("UserID", localStorage.getItem("uId"));

    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: Case,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].message === "Success") {
        const Mapped = data.result.map((item) => ({
          value: item.CaseID,
          label: `${item.FileNo} / ${item.CaseNo} / ${item.CaseName} / ${item.CaseYear}`,
        }));
        setOptionsCase(Mapped);
        setOptionsEvent(Mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setCaseID("");
    } else {
      setCaseID(selectedOption.value);
    }
  };
  const handleSelectBank = (selectedOption) => {
    if (selectedOption === null) {
      setBankID("");
    } else {
      setBankID(selectedOption.value);
    }
  };
  // Active / Deactive Status

  const deActive = async (row) => {
    const data = await activeStatus(row, "Invoice/InvoiceStatus");
    if (data === true) {
      getInvoiceView();
    }
  };

  const getById = async (id) => {
    const data = await userDetails(id, "Invoice/ViewInvoiceById");
    const Invoicedata = await userDetails(id, "Invoice/ViewInvoiceDetailById");

    if (data === "token") {
      setTimeout(() => {
        closeEdit.click();
        navigate("/");
      }, 1000);
    } else {
      setDynamicRows(Invoicedata && Invoicedata.result);
      setEditID(data && data.result[0].Id);
      setEditBankID(data && data.result[0].BankID);
      setEditCaseID(data && data.result[0].CaseID);
      setEditRemark(data && data.result[0].Remark);
      setEditDate(data && data.result[0].Date);
      setEditDiscount(data && data.result[0].Discount);
      setEditInvoiceAmount(data && data.result[0].InvoiceAmount);
      setEditInvoiceTemId(
        data && data.result[0].InvoiceTemplateID === "0"
          ? ""
          : data.result[0].InvoiceTemplateID
      );
      setEditInvoiceTotal(data && data.result[0].TotalAmount);
    }
  };

  const details = async (id) => {
    const data = await userDetails(id, "Invoice/ViewInvoiceById");
    const Invoicedata = await userDetails(id, "Invoice/ViewInvoiceDetailById");

    if (data === "token") {
      setTimeout(() => {
        closeEdit.click();
        navigate("/");
      }, 1000);
    } else {
      setInvoiceDetailData(Invoicedata && Invoicedata.result);
      setDInvoiceNo(data && data.result[0].InvoiceNo);
      setDDate(data && data.result[0].Date);
      setDInvoiceAmount(data && data.result[0].InvoiceAmount);
      setDClientName(data && data.result[0].ClientName);
      setDCaseName(data && data.result[0].CaseName);
      setDCaseYear(data && data.result[0].CaseYear);
      setDRemark(data && data.result[0].Remark);
      setDDiscount(data && data.result[0].Discount);
      setDTotalAmount(data && data.result[0].TotalAmount);
      setDAddedBy(data && data.result[0].AddedBy);
    }
  };

  const customStyles = {
    cells: {
      style: {
        fontSize: "14px",
      },
    },

    headRow: {
      style: {
        color: "white",
        fontSize: "13px",
        backgroundColor: "#7a0103",
        borderRadius: "10px 10px 0px 0px",
      },
    },
  };

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      header: "Action",
      Cell: (row) => {
        return (
          <div style={{ display: "flex", gap: "15px" }}>
            <span>
              <a
                // style={{ display: isEdit === "0" ? "none" : "block" }}
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongEdit"
                onClick={() => getById(row.row.original.Id)}
              >
                <img src="assets/img/icons/edit.svg" title="Edit" />
              </a>
            </span>
            <span>
              <a
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongDetail"
                onClick={() => details(row.row.original.Id)}
              >
                <img src="assets/img/icons/eye.svg" title="Details" />
              </a>
            </span>
            <span>
              <Link
                target="_blank"
                to={`/View_Invoice_PDF/${row.row.original.Id}`}
                title="PDF"
              >
                <img
                  src="assets/img/icons/pdf.svg"
                  className="pdf"
                  title="PDF"
                />
              </Link>
            </span>
            <span>
              <Link
                target="_blank"
                to={`/View_Invoice_WP/${row.row.original.Id}`}
                title="PDF"
              >
                <img src="assets/img/icons/send.svg" title="Whatsapp PDF" />
              </Link>
            </span>
            <span>
              <a
                style={{
                  cursor: "pointer",
                  // display: isStatus === "0" ? "none" : "block",
                }}
                onClick={() => deActive(row.row.original)}
              >
                {row.row.original.Status === "1" ? (
                  <img
                    className="thumb_icon"
                    src="assets/img/icons/Up.svg"
                    title="Status"
                  />
                ) : (
                  <img
                    className="thumb_icon"
                    src="assets/img/icons/Down.svg"
                    title="Status"
                  />
                )}
              </a>
            </span>
            {isDelete !== "0" && (
              <span
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                style={{ cursor: "pointer" }}
                onClick={() => setDelId(row.row.original.Id)}
              >
                <img
                  src="assets/img/icons/delete.svg"
                  alt="Delete"
                  title="Delete"
                />
              </span>
            )}
          </div>
        );
      },
    }),

    columnHelper.accessor("Date", {
      header: "Date",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.Date === null ||
              row.row.original.Date === "0000-00-00"
              ? "-"
              : formatDate1(row.row.original.Date)}
          </div>
        );
      },
    }),

    columnHelper.accessor("InvoiceNo", {
      header: "Invoice No",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.InvoiceNo === null ||
              row.row.original.InvoiceNo === ""
              ? "-"
              : row.row.original.InvoiceNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("FileNo", {
      header: "File No",
      minSize: "10px",
    }),

    columnHelper.accessor("CaseNo", {
      header: "Case No",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CaseNo === null || row.row.original.CaseNo === ""
              ? "-"
              : row.row.original.CaseNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseName", {
      header: "Case Name",
      minSize: "4000px",
      Cell: (row) => {
        return (
          <div style={{ minWidth: "350px" }} className="upperCase">
            <a
              title="Case Details"
              href={`#/view-casedetails/${row.row.original.CaseID}`}
              className="visuald_case"
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              {row.row.original.CaseName}
            </a>
          </div>
        );
      },
    }),

    columnHelper.accessor("InvoiceAmount", {
      header: "Invoice Amount",
      footer: `${totalAmount} Rs.`,
      minSize: "10px",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.InvoiceAmount === null ||
              row.row.original.InvoiceAmount === ""
              ? "-"
              : row.row.original.InvoiceAmount}
          </div>
        );
      },
    }),

    columnHelper.accessor("PaidAmount", {
      footer: `${PaidAmount} Rs.`,
      header: "Paid Amount",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.PaidAmount === null ||
              row.row.original.PaidAmount === "0000"
              ? "-"
              : row.row.original.PaidAmount}
          </div>
        );
      },
    }),

    columnHelper.accessor("Balance", {
      footer: `${totalAmount - PaidAmount} Rs.`,
      header: "Balance",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.InvoiceAmount - row.row.original.PaidAmount}
          </div>
        );
      },
    }),

    columnHelper.accessor("Discount", {
      header: "Discount",
      minSize: "10px",
      Cell: (row) => {
        return <div>{row.row.original.Discount}</div>;
      },
    }),

    columnHelper.accessor("Cleared", {
      header: "Cleared ?",
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      Cell: (row) => {
        return (
          <p
            style={{
              backgroundColor:
                parseFloat(row.row.original.PaidAmount) >=
                  parseFloat(row.row.original.InvoiceAmount)
                  ? "#008e5a"
                  : "#f39c12 ",
              color: "white",
              fontSize: "12px",
              padding: "7px 15px",
              borderRadius: "6px",
              fontWeight: "bold",
              width: "4.6rem",
              textAlign: "center",
            }}
          >
            <span>
              {parseFloat(row.row.original.PaidAmount) >=
                parseFloat(row.row.original.InvoiceAmount)
                ? "PAID"
                : "UNPAID"}
            </span>
          </p>
        );
      },
    }),

    columnHelper.accessor("InvoiceTemplate", {
      header: "Template",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.InvoiceTemplate === null ||
              row.row.original.InvoiceTemplate === ""
              ? "-"
              : row.row.original.InvoiceTemplate}
          </div>
        );
      },
    }),

    columnHelper.accessor("Bank", {
      header: "Bank",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Bank === null || row.row.original.Bank === ""
              ? "-"
              : row.row.original.Bank}
          </div>
        );
      },
    }),
    columnHelper.accessor("Beneficiary", {
      header: "Beneficiary",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Beneficiary === null ||
              row.row.original.Beneficiary === ""
              ? "-"
              : row.row.original.Beneficiary}
          </div>
        );
      },
    }),
    columnHelper.accessor("Branch", {
      header: "Branch",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Branch === null || row.row.original.Branch === ""
              ? "-"
              : row.row.original.Branch}
          </div>
        );
      },
    }),
    columnHelper.accessor("AccountNo", {
      header: "Account No",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.AccountNo === null ||
              row.row.original.AccountNo === ""
              ? "-"
              : row.row.original.AccountNo}
          </div>
        );
      },
    }),
    columnHelper.accessor("Counsel", {
      header: "Counsel",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Counsel === null ||
              row.row.original.Counsel === ""
              ? "-"
              : row.row.original.Counsel}
          </div>
        );
      },
    }),

    columnHelper.accessor("Cocounsel", {
      header: "Co-counsel",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Cocounsel === null ||
              row.row.original.Cocounsel === ""
              ? "-"
              : row.row.original.Cocounsel}
          </div>
        );
      },
    }),

    columnHelper.accessor("ClientName", {
      header: "Client",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.ClientName}
            <br />
            {row.row.original.MobileNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("UpdatedBy", {
      header: "Last-Modified",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.UpdatedBy === null ||
              row.row.original.UpdatedBy === ""
              ? "-"
              : row.row.original.UpdatedBy}
            <br />
            {formateDateTime(row.row.original.UpdatedDate)}
          </div>
        );
      },
    }),

    columnHelper.accessor("Status", {
      header: "Status",
      Cell: (row) => {
        return (
          <div>{row.row.original.Status === "1" ? "ACTIVE" : "DEACTIVE"}</div>
        );
      },
    }),

    columnHelper.accessor("CreatedBy", {
      header: "Added By",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.CreatedBy === null ||
              row.row.original.CreatedBy === ""
              ? "-"
              : row.row.original.CreatedBy}
            <br />
            {formateDateTime(row.row.original.CreatedDate)}
          </div>
        );
      },
    }),
  ];

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getInvoiceView();
      // getInvoice();
    }
  }, []);

  const handleDescriptionChange = (index, value) => {
    const updatedFormData = [...formData1];
    updatedFormData[index].Description = value;
    setFormData1(updatedFormData);
  };

  const handleAmountChange = (index, value) => {
    const updatedFormData = [...formData1];
    updatedFormData[index].Amount = value;

    let sum = updatedFormData.reduce(function (prev, current) {
      return prev + +current.Amount;
    }, 0);

    setInvoiceTotal(sum);
    setInvoiceAmount(sum - Discount);
    setFormData1(updatedFormData);
  };

  const addRow = () => {
    setFormData1([...formData1, { Description: "", Amount: "" }]);
  };

  const removeRow = (indexToRemove) => {
    if (formData1.length === 1) {
      return;
    }
    let updatedFormData = formData1.filter(
      (_, index) => index !== indexToRemove
    );

    setInvoiceTotal(invoiceTotal - formData1[indexToRemove].Amount);
    setInvoiceAmount(InvoiceAmount - formData1[indexToRemove].Amount);
    setFormData1(updatedFormData);
  };

  const editRremoveRow = (indexToRemove) => {
    if (dynamicRows.length === 1) {
      return;
    }
    let updatedFormData = dynamicRows.filter(
      (_, index) => index !== indexToRemove
    );

    setEditInvoiceTotal(editInvoiceTotal - dynamicRows[indexToRemove].Amount);
    setEditInvoiceAmount(EditInvoiceAmount - dynamicRows[indexToRemove].Amount);
    setDynamicRows(updatedFormData);
  };

  const handelDiscount = () => {
    let sum = formData1.reduce(function (prev, current) {
      return prev + +current.Amount;
    }, 0);

    let dis = sum - Discount;
    setInvoiceAmount(dis);
  };

  useEffect(() => {
    handelDiscount();
  }, [Discount]);

  function clearForm() {
    var fields = document.getElementsByClassName("name");
    for (var i = 0; i < fields.length; i++) {
      fields[i].value = " ";
    }
  }

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setSaveButton(false);
    const formData = new FormData();
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CaseID", CaseID);
    formData.append("BankID", BankID);
    formData.append("InvoiceTemplateID", invoiceTemId);
    formData.append("TotalInvoice", invoiceTotal);
    formData.append("Remark", remark.toUpperCase());
    formData.append("Discount", Discount);
    formData.append("InvoiceAmount", InvoiceAmount);
    formData.append("Date", Dates);
    formData.append("CreatedBy", localStorage.getItem("UserId"));

    try {
      const response = await fetch(`${baseUrl}Invoice/AddInvoice`, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("access_token"),
          Accept: "*/*",
        },
        body: formData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data.result.length && data.result[0].code === "200") {
        const InvoiceID = data.result[0].InvoiceID;
        const rowsData = [];
        formData1.forEach((item) => {
          rowsData.push({
            Description: item.Description,
            Amount: item.Amount,
          });
        });

        try {
          for (const row of rowsData) {
            const formData2 = new FormData();
            formData2.append("AdminID", localStorage.getItem("id"));
            formData2.append("InvoiceID", InvoiceID);
            formData2.append("Description", row.Description.toUpperCase());
            formData2.append("Amount", row.Amount);
            formData2.append("CreatedBy", localStorage.getItem("UserId"));

            // Send the second POST request for each row in 'rowsData'
            const response2 = await fetch(
              `${baseUrl}Invoice/AddInvoiceDetail`,
              {
                method: "POST",
                headers: {
                  Authorization: localStorage.getItem("access_token"),
                  Accept: "*/*",
                },
                body: formData2,
              }
            );

            const data2 = await response2.json();
            if (data2 && data2.result.message == "Session Time Expire.") {
              toast.error("Token expired please login again");
              setTimeout(() => {
                navigate("/");
              }, 3000);
            }
            if (data2.result.length && data2.result[0].message === "Success") {
              closeButton.click();
              getInvoiceView();
              setCaseID("");
              setInvoiceAmount("");
              setInvoiceTotal("");
              setInvoiceTemId("");
              setRemark("");
              setDates("");
              setDiscount("");
              clearForm();
              toast.success("Data Added !");
              setFormData1([{ Description: "", Amount: "" }]);
              setSaveButton(true);
            } else {
              setSaveButton(true);
              toast.error(data2.result.message);
            }
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        // Handle error for the first request
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const editHandleDescriptionChange = (index, value) => {
    const updatedFormData = [...dynamicRows];
    updatedFormData[index].Description = value;
    setDynamicRows(updatedFormData);
  };

  const editAddRow = () => {
    setDynamicRows([...dynamicRows, { Description: "", Amount: "" }]);
  };

  const editHandleAmountChange = (index, value) => {
    const updatedFormData = [...dynamicRows];
    updatedFormData[index].Amount = value;

    let esti =
      updatedFormData &&
      updatedFormData.reduce(function (prev, current) {
        return prev + +current.Amount;
      }, 0);

    setEditInvoiceTotal(esti);
    setEditInvoiceAmount(esti - EditDiscount);
    setDynamicRows(updatedFormData);
  };

  const editHandelDiscount = () => {
    let sum =
      dynamicRows &&
      dynamicRows.reduce(function (prev, current) {
        return prev + +current.Amount;
      }, 0);

    setEditInvoiceAmount(sum - EditDiscount);
  };

  const excelData =
    data &&
    data.map((obj) => {
      const { Id, code, message, Status, AdminID, ...rest } = obj;
      return rest;
    });

  const handleExportData = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });

    const formattedDate = new Date().toISOString().slice(0, 10); // Get today's date
    const filename = `Invoice|LegalCRM_${formattedDate}.csv`;

    const csv = generateCsv(csvConfig)(excelData);
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const columnsToExport = [
      { id: "serialNo", header: "S No." },
      ...columns.filter((column) => selectedColumns.includes(column.id)),
    ];

    const tableHeaders = columnsToExport.map((c) => c.header);

    const formattedDate = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const filename = `Invoice|LegalCRM_${formattedDate}.pdf`;
    const addWatermarkToFirstPage = () => {
      const watermarkHeight = 20;

      doc.setFontSize(10);
      doc.text(
        10,
        watermarkHeight + -10,
        `Generated by : ${localStorage.getItem("name")} `
      );
      doc.text(172, watermarkHeight + -10, `Date: ${formattedDate}`);
      doc.setFontSize(16);
      doc.text("Invoice Table", 84, 20);
    };

    const watermarkHeight = 20;

    addWatermarkToFirstPage();
    const tableData = rows.map((row, index) => {
      const formattedDateInPdf = row.original["Date"]
        ? format(new Date(row.original["Date"]), "dd/MM/yyyy")
        : "";

      return [
        { content: (index + 1).toString(), styles: { fontStyle: "bold" } }, // Serial number
        ...columnsToExport.slice(1).map((column) => {
          // Exclude the first column (serial number) when mapping
          if (column.id === "Date") {
            return formattedDateInPdf;
          }

          const value =
            column.id in row.original ? row.original[column.id] : "";
          return value !== null && value !== undefined ? value : "";
        }),
      ];
    });

    tableData.push([]);
    const extraRow = [
      "",
      "",
      "",
      "",
      "Total",
      `${totalInvoiceAmount}/-`,
      `${totalPaidAmount}/-`,
    ];
    tableData.push(extraRow);

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY: watermarkHeight + 5,
    });

    const pdfBlob = doc.output("blob");

    // Create a blob URL
    const blobUrl = URL.createObjectURL(pdfBlob);

    window.open(blobUrl, "_blank");

    URL.revokeObjectURL(blobUrl);
    // doc.save(filename);
  };

  const table = useMaterialReactTable({
    columns,
    data: casesData,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    initialState: {
      columnVisibility: {
        Status: false,
        CreatedBy: false,
        UpdatedBy: false,
        UpdatedDate: false,
        CreatedDate: false,
        Discount: false,
      },
    },
    state: {
      isLoading: loading,
    },
    muiCircularProgressProps: {
      color: "primary",
      thickness: 5,
      size: 55,
      style: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000,
      },
    },
    muiSkeletonProps: {
      animation: "pulse",
      height: 20,
    },
    enableStickyHeader: true,
    enableRowNumbers: true,

    muiPaginationProps: {
      rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export Excel
        </Button>

        <Button
          disabled={
            !table || table.getPrePaginationRowModel().rows.length === 0
          }
          onClick={handleExportButtonClick}
          startIcon={<FileDownloadIcon />}
        >
          Export PDF
        </Button>
        <Dialog open={open} onClose={handleClose1}>
          <DialogTitle>Select Columns To Export</DialogTitle>
          <DialogContent>
            {columns.map((column) =>
              column.header !== "Action" ? (
                <div key={column.id}>
                  <Checkbox
                    checked={selectedColumns.includes(column.id)}
                    onChange={() => handleToggleColumn(column.id)}
                  />
                  {column.header}
                </div>
              ) : (
                ""
              )
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose1}>Cancel</Button>
            <Button
              onClick={() =>
                handleExportRows(table.getPrePaginationRowModel().rows)
              }
            >
              Export
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    ),
  });

  useEffect(() => {
    editHandelDiscount();
  }, [EditDiscount]);

  useEffect(() => {
    handelDiscount();
  }, [Discount]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <>
      <DocumentTitle title="INVOICE | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div className="wraper">
            <Header />
            <div className="mainWraper">
              <div className="menuOverlay"></div>
              <Sidebar />

              <div className="pageBody">
                <div className="pageContent">
                  <div className="PageTitle">
                    <h3>Invoice List</h3>
                    <h3>( {counselName} - {years} )</h3>
                    <a
                      type="button"
                      className="btn btn-primary btn_client headbtn"
                      data-toggle="modal"
                      data-target="#exampleModalLong"
                    >
                      Add Invoice <img src="assets/img/icons/add.svg" />
                    </a>
                  </div>
                  <div
                    className="searDesign"
                  >
                    <MaterialReactTable table={table} />
                  </div>
                  <div
                    className="modal fade sc_modal"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button
                            type="button"
                            className="btn-close dInvoice"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          />
                        </div>
                        <div className="modal-body">
                          <h4>Delete invoice</h4>
                          <p>
                            if you delete the invoice then receipt related to
                            this invoice will be deleted
                          </p>
                          <ul>
                            <li>
                              <a
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Cancel
                              </a>
                            </li>
                            <li>
                              <a
                                className="btn btn-danger"
                                onClick={() => deleteInvoice(delId)}
                              >
                                Delete
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Add */}

                  <div
                    className="modal fade"
                    id="exampleModalLong"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLongTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-xl"
                      role="document"
                    // style={{ maxWidth: "70%" }}
                    >
                      <div className="modal-content">
                        <div className="add_wrap">
                          <div className="main_heading">
                            <h2>Add Invoice</h2>
                          </div>
                        </div>
                        <div className="popup_modal ">
                          <button
                            type="button"
                            className="close btn_popup"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                          <div className="dashed_popup">
                            <form role="form " onSubmit={HandleSubmit}>
                              <div className="form_flex caseMobile">
                                <div className="form-group">
                                  <label for="uname">
                                    File No.
                                    <text className="m_star">*</text>
                                  </label>
                                  <Select
                                    required
                                    className="basic-single"
                                    placeholder="Please Select "
                                    classNamePrefix="select"
                                    onChange={handleSelectChange}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={optionsCase}
                                  />
                                </div>
                                <div className="form-group">
                                  <label for="uname">Invoice Tempalte</label>

                                  <a
                                    className="visuald"
                                    href="/#/view-invoicetemplate"
                                  >
                                    +Add Template
                                  </a>
                                  <select
                                    className="name"
                                    id="city"
                                    onChange={(e) =>
                                      setInvoiceTemId(e.target.value)
                                    }
                                  >
                                    <option value="">
                                      ------ Please Select -------
                                    </option>
                                    {invoiceTem &&
                                      invoiceTem.map((val, index) => {
                                        return (
                                          <option key={index} value={val.Id}>
                                            {val.Name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                                <div className="form-group">
                                  <label for="uname">
                                    Bank
                                    <text className="m_star">*</text>
                                  </label>
                                  <Select
                                    required
                                    className="basic-single"
                                    placeholder="Please Select"
                                    classNamePrefix="select"
                                    onChange={handleSelectBank}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={Bank}
                                  />
                                </div>
                                <div className="form-group">
                                  <label for="uname">
                                    Date
                                    <text className="m_star">*</text>
                                  </label>
                                  <input
                                    required
                                    autoComplete="off"
                                    value={Dates}
                                    className="name"
                                    max={currentDate}
                                    type="date"
                                    onChange={(e) => setDates(e.target.value)}
                                  />
                                </div>
                              </div>

                              <div className="main_heading">
                                <h2>Invoice Details</h2>
                              </div>

                              <div>
                                {formData1 &&
                                  formData1.map((row, index) => (
                                    <div key={index}>
                                      <div className="form_flex caseMobile">
                                        <div className="form-group">
                                          <label
                                            htmlFor={`description-${index}`}
                                          >
                                            Description
                                          </label>
                                          <input
                                            value={row.Description}
                                            type="text"
                                            className="name"
                                            placeholder="Enter Description"
                                            onChange={(e) =>
                                              handleDescriptionChange(
                                                index,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label htmlFor={`amount-${index}`}>
                                            Amount
                                            <text className="m_star">*</text>
                                          </label>
                                          <input
                                            required
                                            onKeyPress={handleKeyPress}
                                            value={row.Amount}
                                            type="text"
                                            className="name"
                                            placeholder="Enter Amount"
                                            onChange={(e) =>
                                              handleAmountChange(
                                                index,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                        {index === 0 && (
                                          <a
                                            onClick={addRow}
                                            className="addRowBtn"
                                          >
                                            +
                                          </a>
                                        )}

                                        {index !== 0 && (
                                          <a
                                            onClick={() => removeRow(index)}
                                            className="removeBtn"
                                          >
                                            ❌
                                          </a>
                                        )}
                                      </div>
                                    </div>
                                  ))}

                                <div className="form_flex test">
                                  <div className="form-group">
                                    <label htmlFor="remark">Remark</label>
                                    <textarea
                                      className="name"
                                      type="text"
                                      rows={10}
                                      placeholder="Enter Remark"
                                      name="remark"
                                      value={remark}
                                      onChange={(e) =>
                                        setRemark(e.target.value)
                                      }
                                    />
                                  </div>

                                  <div className="form-group">
                                    <div>
                                      <label htmlFor="discount">
                                        Invoice Total
                                      </label>
                                      <input
                                        readOnly
                                        type="text"
                                        placeholder=""
                                        name="discount"
                                        value={invoiceTotal}
                                      />
                                    </div>

                                    <div>
                                      <label htmlFor="discount">
                                        Discount ₹
                                      </label>
                                      <input
                                        className="name"
                                        type="text"
                                        placeholder=""
                                        name="discount"
                                        value={Discount}
                                        onChange={(e) =>
                                          setDiscount(e.target.value)
                                        }
                                      />
                                    </div>

                                    <div>
                                      <label htmlFor="invoice_amount">
                                        Final Amount
                                      </label>
                                      <input
                                        className="name"
                                        type="text"
                                        name="invoice_amount"
                                        value={InvoiceAmount}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="btn_main caseMobile">
                                <button type="submit" className="btn_save">
                                  Save
                                </button>
                                <button
                                  type="button"
                                  // onClick={clearForm}
                                  className="btn_save btn_cancle"
                                  data-dismiss="modal"
                                >
                                  Cancel
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Edit */}

                  <div
                    className="modal fade"
                    id="exampleModalLongEdit"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLongTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-xl"
                      role="document"
                    // style={{ maxWidth: "70%" }}
                    >
                      <div className="modal-content">
                        <div className="add_wrap">
                          <div className="main_heading">
                            <h2>Edit Invoice</h2>
                          </div>
                        </div>
                        <div className="popup_modal ">
                          <button
                            type="button"
                            className="close btn_popup e_popup"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>

                          <div className="dashed_popup">
                            <form role="form " onSubmit={HandleEditSubmit}>
                              <div className="form_flex caseMobile">
                                <div className="form-group">
                                  <div>
                                    <label for="uname">
                                      File No.
                                      <text className="m_star">*</text>
                                    </label>
                                    <select
                                      value={EditCaseID}
                                      id="city"
                                      onChange={(e) =>
                                        setEditCaseID(e.target.value)
                                      }
                                      required
                                    >
                                      <option>--Please Select--</option>
                                      {optionsCase && optionsCase.length > 0 ? (
                                        optionsCase.map((val) => (
                                          <option
                                            key={val.FileNo}
                                            value={val.value}
                                            selected={val.value === EditBankID}
                                          >
                                            {val.label}
                                            {/* {val.FileNo}/{val.CaseNo}/
                                                  {val.CaseType}/{val.CaseYear}/
                                                  {val.CaseName} */}
                                          </option>
                                        ))
                                      ) : (
                                        <option value="">Loading...</option>
                                      )}
                                    </select>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label for="uname">Invoice Tempalte</label>
                                  <select
                                    className="name"
                                    id="city"
                                    value={editInvoiceTemId}
                                    onChange={(e) =>
                                      setEditInvoiceTemId(e.target.value)
                                    }
                                  >
                                    <option value="">
                                      ------ Please Select -------
                                    </option>
                                    {invoiceTem &&
                                      invoiceTem.map((val, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={val.Id}
                                            selected={
                                              val.Id === editInvoiceTemId
                                            }
                                          >
                                            {val.Name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                                <div className="form-group">
                                  <label for="uname">
                                    Bank
                                    <text className="m_star">*</text>
                                  </label>
                                  <select
                                    value={EditBankID}
                                    id="city"
                                    onChange={(e) =>
                                      setEditBankID(e.target.value)
                                    }
                                    required
                                  >
                                    <option>--Please Select--</option>
                                    {Bank && Bank.length > 0 ? (
                                      Bank.map((val) => (
                                        <option
                                          key={val.Id}
                                          value={val.value}
                                          selected={val.value === EditBankID}
                                        >
                                          {val.label}
                                        </option>
                                      ))
                                    ) : (
                                      <option value="">Loading...</option>
                                    )}
                                  </select>
                                </div>
                                <div className="form-group">
                                  <label for="uname">
                                    Date
                                    <text className="m_star">*</text>
                                  </label>
                                  <input
                                    required
                                    autoComplete="off"
                                    max={currentDate}
                                    className="name"
                                    type="date"
                                    placeholder="Enter Expenses Details "
                                    onChange={(e) =>
                                      setEditDate(e.target.value)
                                    }
                                    value={EditDate}
                                  />
                                </div>
                              </div>

                              <div className="main_heading">
                                <h2>Invoice Details</h2>
                              </div>

                              {dynamicRows &&
                                dynamicRows.map((item, index) => (
                                  <div key={index}>
                                    <div className="form_flex caseMobile">
                                      <div className="form-group">
                                        <label htmlFor={`description-${index}`}>
                                          Description
                                        </label>
                                        <input
                                          type="text"
                                          className="name"
                                          placeholder="Enter Description"
                                          value={item.Description}
                                          onChange={(e) =>
                                            editHandleDescriptionChange(
                                              index,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label htmlFor={`amount-${index}`}>
                                          Amount
                                          <text className="m_star">*</text>
                                        </label>
                                        <input
                                          required
                                          onKeyPress={handleKeyPress}
                                          value={
                                            item.Amount === "0.00"
                                              ? ""
                                              : item.Amount
                                          }
                                          type="text"
                                          className="name"
                                          placeholder="Enter Amount"
                                          onChange={(e) =>
                                            editHandleAmountChange(
                                              index,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                      {index === 0 && (
                                        <a
                                          onClick={editAddRow}
                                          className="addRowBtn"
                                        >
                                          +
                                        </a>
                                      )}

                                      {index !== 0 && (
                                        <a
                                          onClick={() => editRremoveRow(index)}
                                          className="removeBtn"
                                        >
                                          ❌
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                ))}

                              <div className="form_flex test">
                                <div className="form-group">
                                  <label htmlFor="remark">Remark</label>
                                  <textarea
                                    type="text"
                                    rows={10}
                                    placeholder="Enter Remark"
                                    name="remark"
                                    value={editRemark}
                                    onChange={(e) =>
                                      setEditRemark(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="form-group">
                                  <div>
                                    <label htmlFor="discount">
                                      Invoice Total
                                    </label>
                                    <input
                                      readOnly
                                      type="text"
                                      placeholder=""
                                      name="discount"
                                      value={editInvoiceTotal}
                                    />
                                  </div>

                                  <div>
                                    <label htmlFor="discount">Discount ₹</label>
                                    <input
                                      type="text"
                                      placeholder="Enter Discount"
                                      name="discount"
                                      onChange={(e) =>
                                        setEditDiscount(e.target.value)
                                      }
                                      value={EditDiscount}
                                    />
                                  </div>

                                  <div>
                                    <label htmlFor="invoice_amount">
                                      Final Amount
                                    </label>
                                    <input
                                      readOnly
                                      type="text"
                                      placeholder="Enter Invoice Amount"
                                      name="invoice_amount"
                                      value={EditInvoiceAmount}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="btn_main caseMobile">
                                <button type="submit" className="btn_save">
                                  Update
                                </button>
                                <button
                                  type="button"
                                  onClick={clearForm}
                                  className="btn_save btn_cancle"
                                  data-dismiss="modal"
                                >
                                  Cancel
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* DETAILS */}

                  <div
                    className="modal fade"
                    id="exampleModalLongDetail"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="exampleModalLongTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-lg"
                      role="document"
                    // style={{ maxWidth: "50%" }}
                    >
                      <div className="modal-content">
                        <div className="add_wrap">
                          <div className="main_heading">
                            <h2>Invoice Details</h2>
                          </div>
                        </div>
                        <div className="popup_modal ">
                          <button
                            type="button"
                            className="close ab btn_popup"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                          <div className="dashed_popup">
                            <div className="form_flex caseMobile">
                              <div className="form-group">
                                <h4>Case Name &nbsp;: &nbsp; {dCaseName} </h4>
                              </div>
                              <div className="form-group">
                                <h4>Case Year &nbsp;: &nbsp; {dCaseYear} </h4>
                              </div>
                            </div>
                            <div className="form_flex caseMobile">
                              <div className="form-group">
                                <h4>
                                  Client Name &nbsp;: &nbsp; {dClientName}
                                </h4>
                              </div>
                              <div className="form-group">
                                <h4>
                                  Date &nbsp;: &nbsp;{" "}
                                  {dDate === "0000-00-00" || dDate === ""
                                    ? "-"
                                    : formatDate1(dDate)}
                                </h4>
                              </div>
                            </div>
                            <div className="form_flex caseMobile">
                              <div className="form-group">
                                <h4>
                                  Invoice No. &nbsp;: &nbsp;{" "}
                                  {dInvoiceNo === null ? "-" : dInvoiceNo}{" "}
                                </h4>
                              </div>
                              <div className="form-group">
                                <h4>
                                  Invoice Amount &nbsp;: &nbsp;₹{" "}
                                  {dInvoiceAmount}{" "}
                                </h4>
                              </div>
                            </div>
                            <div className="form_flex caseMobile">
                              <div className="form-group">
                                <h4>
                                  Total Amount &nbsp;: &nbsp; {dTotalAmount}{" "}
                                </h4>
                              </div>
                              <div className="form-group">
                                <h4>Discount &nbsp;: &nbsp;₹ {dDiscount} </h4>
                              </div>
                            </div>
                            <div className="form_flex caseMobile">
                              <div className="form-group">
                                <h4>Added By &nbsp;: &nbsp; {dAddedBy} </h4>
                              </div>
                              <div className="form-group">
                                <h4>
                                  Remark &nbsp;: &nbsp;{" "}
                                  {dRemark === "" ? "-" : dRemark}{" "}
                                </h4>
                              </div>
                            </div>

                            <div className="form_flex caseMobile">
                              <div className="form-group">
                                <table className="table detail_table">
                                  <tr>
                                    <th>S. No</th>
                                    <th>Amount</th>
                                    <th>Description</th>
                                  </tr>

                                  {invoiceDetailData &&
                                    invoiceDetailData.map((val, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>{val.Amount}</td>
                                          <td>
                                            {val.Description === ""
                                              ? "-"
                                              : val.Description}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                  {/* <div
                          // className="btn"
                          style={{
                            padding: "8px",
                            marginLeft: "330px",
                            marginTop: "20px",
                            fontSize: "17px",
                            fontWeight: "600",
                            width: "max-content",
                            color: "white",
                            background: "#7a0000",
                            borderRadius: "10px",
                          }}
                        >
                          Total Invoice Amount: {sum} Rs
                        </div> */}
                </div>
                {/* </div> */}
              </div>
            </div>
            <Footer />
          </div>
        )}
      </DocumentTitle>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
export default UserInvoice;
