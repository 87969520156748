import React, { useState, useRef } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import "react-toastify/dist/ReactToastify.css";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useContext } from "react";
import { url } from "../index";
import Preloader from "./Preloader";
import DocumentTitle from "react-document-title";
import { formatDate1 } from "../services/api.service";
import Footer from "./Footer";
const Payment = () => {

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const [paymentData, setPaymentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalAmount, setTotalAmount] = useState("0");
  const [counselAmount, setCounselAmount] = useState("0");
  const navigate = useNavigate();
  const baseUrl = useContext(url);
  const form = new FormData();


  //        -------- VIEW TASK DATA ---------

   const withUidApi = async ( path ) => {
    form.append("AdminID", localStorage.getItem("id"));
    form.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(
        `${baseUrl}${path}`,
        {
          method: "POST",
          body : form,
          headers: {
            "Authorization": `${localStorage.getItem("access_token")}`,
            'Accept': '*/*'
          }
        }
      );
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
          return "token";  
      }
      
      if (data && data.result[0].message === "Success") {
        return data.result
      } else{
        return null
      }
  
    }
    catch (error) {
      console.error(error);
    }
  };

  const getCaseView = async () => {
    const view = await  withUidApi("User/ViewUserAmount")

    if (view === null) {
      setLoading(false);
      setPaymentData([]);
    } else {
      if (view === "token") {
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        setLoading(false);
        setPaymentData(view);

        let totalAmount = view && view.reduce(function (prev, current) {
          return prev + +current.TotalAmount;
        }, 0);
        setTotalAmount(totalAmount);

        let counselAmount = view && view.reduce(function (prev, current) {
          return prev + +current.CounselAmount;
        }, 0);
        setCounselAmount(counselAmount);
      }
    }
  };

  //        -------- TABLE ---------

  const columnHelper = createMRTColumnHelper();
  const columns = [

    columnHelper.accessor("Date", {
      header: "Date",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.Date === null || row.row.original.Date === "0000-00-00"
              ? "-"
              : formatDate1(row.row.original.Date)}
          </div>
        );
      },
    }),

    columnHelper.accessor("File No.", {
      header: "File No",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.FileNo === null || row.row.original.FileNo === ""
              ? "-"
              : row.row.original.FileNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseNo", {
      header: "Case No.",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CaseNo === null || row.row.original.CaseNo === ""
              ? "-"
              : row.row.original.CaseNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseName", {
      header: "Case Name",
      Cell: (row) => {
        return (
          <div style={{ minWidth: "350px" }} className="upperCase">
            <a title="Case Details"
              href={`#/view-casedetails/${row.row.original.CaseID}`}
              className="visuald_case"
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              {row.row.original.CaseName}
            </a>
          </div>
        );
      },
    }),

    columnHelper.accessor("TotalAmount", {
      header: "Total Amount",
      footer : `${totalAmount} Rs.`,
      Cell: (row) => {
        return (
          <div>
            {row.row.original.TotalAmount === null || row.row.original.TotalAmount === ""
              ? "-"
              : row.row.original.TotalAmount}
          </div>
        );
      },
    }),

    columnHelper.accessor("CounselAmount", {
      header: "Counsel Amount",
      footer : `${counselAmount} Rs.`,
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CounselAmount === null ||
            row.row.original.CounselAmount === ""
              ? "-"
              : row.row.original.CounselAmount}
          </div>
        );
      },
    }),

    columnHelper.accessor("CounselPer", {
      header: "Counsel Percent",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CounselPer === null || row.row.original.CounselPer === ""
              ? "-"
              : row.row.original.CounselPer}
          </div>
        );
      },
    }),
    
    columnHelper.accessor("PaymentMode", {
      header: "Payment Mode",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.PaymentMode === null ||
            row.row.original.PaymentMode === ""
              ? "-"
              : row.row.original.PaymentMode}
          </div>
        );
      },
    }),

    columnHelper.accessor("AddedBy", {
      header: "Added By",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.AddedBy === null ||
            row.row.original.AddedBy === ""
              ? "-"
              : row.row.original.AddedBy}
          </div>
        );
      },
    }),
  ];

  const table = useMaterialReactTable({
    columns,
    data : paymentData && paymentData,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    initialState: {
      columnVisibility: {
        AddedBy: false,
      },
    },
    state: {
      isLoading: loading,
    },
    muiCircularProgressProps: {
      color: "primary",
      thickness: 5,
      size: 55,
      style: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000,
      },
    },
    muiSkeletonProps: {
      animation: "pulse",
      height: 20,
    },
    enableStickyHeader: true,
    enableRowNumbers: true,
    muiPaginationProps: {
      rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
      </Box>
    ),
  });

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      getCaseView();
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <DocumentTitle title="PAYMENT | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div className="wraper">
          <Header />
          <div className="mainWraper">
            <Sidebar />
            <div className="pageBody">
              <div className="pageContent">
                <div className="PageTitle">
                <h3>Payment Detail</h3>
                </div>
          
                      <MaterialReactTable table={table} />
            
              </div>
      
            </div>
          </div>
          <Footer/>
        </div>
        )}
      </DocumentTitle>
    </>
  );
};

export default Payment;
