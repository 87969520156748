import React, { useState } from "react";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import "react-quill/dist/quill.snow.css";
import html2pdf from "html2pdf.js";
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { clearForm, formatDate1, formateDateTime } from "../services/api.service";
import {
  withUidApi,
  deleteUser,
  userDetails,
  activeStatus,
  permission,
} from "../services/api.service";
import Preloader from "./Preloader";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";
import Footer from "./Footer";

const DocumentTemp = () => {
  /********************************preloader****************************************************/
  const [isLoading1, setIsLoading1] = useState(true);
  useEffect(() => {
    /***********************Simulate an asynchronous operation (e.g., API call) with setTimeout****************************************/
    setTimeout(() => {
      setIsLoading1(false);
      /**********************************Simulating a 1-second delay*********************************************/
    }, 500);
  }, []);
  var modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],

      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ["blockquote", "code-block"],
      ["link", "image", "video"],
      [{ direction: "rtl" }],
      [{ font: [] }],

      ["clean"][{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
    ],
  };

  var formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size",
    "background",
    "script",
    "video",
  ];

  const handleProcedureContentChange = (content) => {
    setDDescription(content);
  };

  const handleEdit = (content) => {
    setEditDescription(content);
  };

  const formattedDate = new Date().toLocaleDateString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const closeButton = document.querySelector(".btn_popup");
  const closeEdit = document.querySelector(".e_popup");
  const closeDelete = document.querySelector(".d_document");
  const closeView = document.querySelector(".ab");
  const baseUrl = useContext(url);
  const navigate = useNavigate();
  const [isAdd, setIsAdd] = useState("0");
  const [isEdit, setIsEdit] = useState("0");
  const [isView, setIsView] = useState("0");
  const [isDelete, setIsDelete] = useState("0");
  const [isStatus, setIsStatus] = useState("0");

  // Add Document State
  const [DocumetData, setDocumetData] = useState([]);
  const [excel, setExcel] = useState([]);
  const [dFileName, setDFileName] = useState("");
  const [dDocTemplate, setDDocTemplate] = useState("");
  const [dDescription, setDDescription] = useState("");
  const [data, setData] = useState([]);
  // edit Document State

  const [editId, setEditId] = useState("");

  const [editDocTemplate, setEditDocTemplate] = useState("");
  const [editDescription, setEditDescription] = useState("");

  // View Document State
  const [filterUser, setFilterUser] = useState("");
  const [delId, setDelId] = useState("");
  const [active, setActive] = useState(false);

  // Document Detail state

  const [DocTemplate, setDocTemplate] = useState("");
  const [Descriptions, setDescriptions] = useState("");
  const formData = new FormData();
  const CaseID = new FormData();
  const ViewDocTemplate = new FormData();
  const editDocument = new FormData();

  const [open, setOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const handleExportButtonClick = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const handleToggleColumn = (columnName) => {
    const currentIndex = selectedColumns.indexOf(columnName);
    const newSelectedColumns = [...selectedColumns];

    if (currentIndex === -1) {
      newSelectedColumns.push(columnName);
    } else {
      newSelectedColumns.splice(currentIndex, 1);
    }

    setSelectedColumns(newSelectedColumns);
  };

  const getByIdWord = async (id) => {
    const data = await userDetails(id, "Master/ViewDocTemplateById");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } else {
      return data.result[0].Description;
    }
  };


  // WORD
  const downloadWordFile = async (id) => {
    try {
      const data = await getByIdWord(id);
                var htmlContent = data;
        const blob = new Blob(
          [
            `<!DOCTYPE html><html><head><meta charset="UTF-8"></head>
          <body>${htmlContent}</body></html>`,
          ],
          { type: "application/msword;charset=utf-8"}
        );

        const url = window.URL.createObjectURL(blob);
      
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.download = `DocumentTemplate${formattedDate}.doc`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data or processing the file:", error);
    }
  };

  function htmlToPlainText(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const body = doc.body;
    const plainText = body.textContent;
    return plainText.trim();
  }
  // pdf

  const handlePrint = (data) => {
    const pdfContent = `<div style="padding: 15mm">${data}</div>`;

    const pdfOptions = {
      filename: `DocumentTemplate${formattedDate}.pdf`,
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf(pdfContent, pdfOptions);
  };


  const wrapText = (text, font, fontSize, maxWidth) => {
    const words = text.split(" ");
    const lines = [];
    let currentLine = "";

    for (const word of words) {
      const testLine = currentLine + (currentLine === "" ? "" : " ") + word;
      const lineWidth = font.widthOfTextAtSize(testLine, fontSize);

      if (lineWidth <= maxWidth) {
        currentLine = testLine;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }

    if (currentLine) {
      lines.push(currentLine);
    }

    return lines;
  };

  const handleGeneratePDF = async (id) => {
    const data = await getByIdWord(id);
    
    handlePrint(data);
  };
  
  /** Add Document */

  const getDocumentView = async () => {
    const [view, data] = await Promise.all([
      withUidApi("Master/ViewDocTemplate"),
      permission(10),
    ]);

    if (view === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } else {
      if (view === null) {
        setDocumetData([]);
        setData([]);
      } else {
        setDocumetData(view);
        setData(view);
      }
    }

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }
    
    setIsAdd(data && data.result[0].IsAdd);
    setIsEdit(data && data.result[0].IsEdit);
    setIsView(data && data.result[0].IsView);
    setIsStatus(data && data.result[0].IsStatus);
    setIsDelete(data && data.result[0].IsDelete);
  };

  const deleteDocument = async () => {
    const data = await deleteUser(delId, "Master/DeleteDocTemplate");
    if (data === "token") {
      setTimeout(() => {
        closeDelete.click();
        navigate("/");
      }, 3000);
    }

    if (data === true) {
      closeDelete.click();
      getDocumentView();
    }
  };

  const deActive = async (row) => {
    const data = await activeStatus(row, "Master/DocTemplateStatus");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }

    if (data === true) {
      getDocumentView();
    }
  };

  const detailsDocument = async (id) => {
    const data = await userDetails(id, "Master/ViewDocTemplateById");
    if (data === "token") {
      setTimeout(() => {
        closeView.click();
        navigate("/");
      }, 3000);
    } else {
      setDocTemplate(data.result[0].DocTemplate);

      setDescriptions(data.result[0].Description);
    }
  };

  const getById = async (id) => {
    const data = await userDetails(id, "Master/ViewDocTemplateById");
    if (data === "token") {
      setTimeout(() => {
        closeEdit.click();
        navigate("/");
      }, 3000);
    } else {
      setEditId(data.result[0].Id);
      setEditDocTemplate(data.result[0].DocTemplate);
      setEditDescription(data.result[0].Description);
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    formData.append("DocTemplate", dFileName.toUpperCase());
    formData.append("Description", dDescription);
    try {
      const response = await fetch(`${baseUrl}Master/AddDocTemplate`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].code === "200") {
        toast.success("Document added successfully");
        getDocumentView();
        closeButton.click();
        setDDocTemplate("");
        setDFileName("");
      } else {
        toast.error(data.result[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  /** Edit Document */

  const submitEditHandler = async (event) => {
    event.preventDefault();
    editDocument.append("ID", editId);
    editDocument.append("AdminID", localStorage.getItem("id"));
    editDocument.append("UpdatedBy", localStorage.getItem("UserId"));
    editDocument.append("DocTemplate", editDocTemplate.toUpperCase());
    editDocument.append("Description", editDescription);
    try {
      const response = await fetch(`${baseUrl}Master/EditDocTemplate`, {
        method: "POST",
        body: editDocument,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeEdit.click();
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].code == 200) {
        toast.success(" Updated successfully");
        getDocumentView();
        closeEdit.click();
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /** Get File Name  */

  const [FileNo, setFileNo] = useState([]);
  const getFileNo = async () => {
    CaseID.append("AdminID", localStorage.getItem("id"));
    CaseID.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: CaseID,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].message === "Success") {
        setFileNo(data.result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /** Ajax calling For load template Detail  */

  const [selectedValue, setSelectedValue] = useState("");
  const [textareaValue, setTextareaValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (selectedValue) {
      setIsLoading(true);

      const requestData = new FormData();
      requestData.append("AdminID", localStorage.getItem("id"));
      requestData.append("ID", 1); //

      fetch(`${baseUrl}Master/ViewDocTemplateById`, {
        method: "POST", 
        body: requestData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setTextareaValue(data.result[0].Description);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        });
    } else {
      setTextareaValue("");
    }
  }, [selectedValue]);

  const columnHelper = createMRTColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      header: "Action",
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      Cell: (row) => {
        return (
          <>
            <div style={{ display: "flex", gap: "15px" }}>
              {isEdit !== "0" && (
                <span
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalLongEdit"
                  onClick={() => getById(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/edit.svg"
                    alt="Edit"
                    title="Edit"
                  />
                </span>
              )}

              <span
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongDetail"
                onClick={() => detailsDocument(row.row.original.Id)}
              >
                <img
                  src="assets/img/icons/eye.svg"
                  alt="Details"
                  title="Details"
                />
              </span>
              <span>
                <a
                  style={{
                    cursor: "pointer",
                    display: isStatus === "0" ? "none" : "block",
                  }}
                  onClick={() => deActive(row.row.original)}
                >
                  {row.row.original.Status === "1" ? (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Up.svg"
                      title="Status"
                    />
                  ) : (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Down.svg"
                      title="Status"
                    />
                  )}
                </a>
              </span>
              <span  type="button" title="PDF">
                <img
                  onClick={() => handleGeneratePDF(row.row.original.Id)}
                  src="assets/img/icons/pdf.svg"
                  className="pdf"
                  title="PDF"
                />
              </span>
              <span  type="button" title="Word File">
                <img
                  onClick={() => downloadWordFile(row.row.original.Id)}
                  src="assets/img/icons/word.svg"
                  className="pdf"
                  title="Word File"
                />
              </span>
              {isDelete !== "0" && (
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style={{ cursor: "pointer" }}
                  onClick={() => setDelId(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/delete.svg"
                    alt="Delete"
                    title="Delete"
                  />
                </span>
              )}
            </div>
          </>
        );
      },
    }),

    columnHelper.accessor("DocTemplate", {
      header: "Template Name",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.DocTemplate === null ||
            row.row.original.DocTemplate === ""
              ? "-"
              : row.row.original.DocTemplate}
          </div>
        );
      },
    }),
    
    columnHelper.accessor("UpdatedBy", {
      header: "Last-Modified",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.UpdatedBy === null ||
            row.row.original.UpdatedBy === ""
              ? "-"
              : row.row.original.UpdatedBy}<br/>
              {formateDateTime(row.row.original.UpdatedDate)}
          </div>
        );
      },
    }),

    columnHelper.accessor("Status", {
      header: "Status",
      Cell: (row) => {
        return <div>{row.row.original.Status === "1" ? "ACTIVE" : "DEACTIVE"}</div>;
      },
    }),

    columnHelper.accessor("AddedBy", {
      header: "Added By",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.AddedBy === null ||
            row.row.original.AddedBy === ""
              ? "-"
              : row.row.original.AddedBy}<br/>
                    {formateDateTime(row.row.original.CreatedDate)}
          </div>
        );
      },
    }),
  ];
  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  const searchInput = (e) => {
    if (e.length > 0) {
      const res = DocumetData.filter((f) =>
        f.DocTemplate.toLowerCase().includes(e.toLowerCase())
      );
      setActive(true);
      setFilterUser(res);
    } else {
      setActive(false);
    }
  };

  const removekey = () => {
    if (DocumetData && DocumetData.length > 0) {
      const excelData =
        DocumetData &&
        DocumetData.map((obj) => {
          const { Id, code, Status, message, AdminID, ...rest } = obj;
          return rest;
        });
      setExcel(
        excelData.map((obj, index) => {
          return { "S. No.": `${index + 1}`, ...obj };
        })
      );
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getDocumentView();
      getFileNo();
    }
  }, []);

  const excelData =
    data &&
    data.map((obj) => {
      const { Id, code, IsArchive, message, Status, AdminID, ...rest } = obj;
      return rest;
    });

  const handleExportData = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });
   const formattedDate = new Date().toISOString().slice(0, 10); // Get today's date
    const filename = `Document_Template|LegalCRM__${formattedDate}.csv`;

    const csv = generateCsv(csvConfig)(excelData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const columnsToExport = [
      { id: "serialNo", header: "S No." },
      ...columns.filter((column) => selectedColumns.includes(column.id)),
    ];

    const tableHeaders = columnsToExport.map((c) => c.header);

    const formattedDate = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const filename = `Document_Template|LegalCRM__${formattedDate}.pdf`;
    const addWatermarkToFirstPage = () => {
      const watermarkHeight = 20;
      doc.setFontSize(10);
      doc.text(
        10,
        watermarkHeight + -10,
        `Generated by : ${localStorage.getItem("name")} `
      );
      doc.text(172, watermarkHeight + -10, `Date: ${formattedDate}`);
      doc.setFontSize(16);
      doc.text("Document Template Table", 74, 20);
    };
    const watermarkHeight = 20;

    addWatermarkToFirstPage();
    const tableData = rows.map((row, index) => {
      return [
        { content: (index + 1).toString(), styles: { fontStyle: "bold" } },
        ...columnsToExport.slice(1).map((column) => {

          const value =
            column.id in row.original ? row.original[column.id] : "";
          return value !== null && value !== undefined ? value : "";
        }),
      ];
    });

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY: watermarkHeight + 5,
    });

    const pdfBlob = doc.output("blob");
    const blobUrl = URL.createObjectURL(pdfBlob);
    window.open(blobUrl, "_blank");
    URL.revokeObjectURL(blobUrl);
  };

  // ---------DataTable-----
  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    initialState: {
      columnVisibility: {
        Status: false,
        AddedBy: false,
        UpdatedBy: false,
        UpdatedDate: false,
        CreatedDate: false,
      },
    },
    enableStickyHeader: true,
    enableRowNumbers: true,
    rowNumberDisplayMode: "original",
    muiPaginationProps: {
      rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
    disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export Excel
        </Button>

        <Button
        disabled={!table || table.getPrePaginationRowModel().rows.length === 0}
        onClick={handleExportButtonClick}
        startIcon={<FileDownloadIcon />}
      >
        Export PDF
      </Button>
      <Dialog open={open} onClose={handleClose1}>
        <DialogTitle>Select Columns To Export</DialogTitle>
        <DialogContent>
            {columns.map((column) =>
              column.header !== "Action" ? (
                <div key={column.id}>
                  <Checkbox
                    checked={selectedColumns.includes(column.id)}
                    onChange={() => handleToggleColumn(column.id)}
                  />
                  {column.header}
                </div>
              ) : (
                ""
              )
            )}
          </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1}>Cancel</Button>
          <Button onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}>Export</Button>
        </DialogActions>
      </Dialog>
      </Box>
    ),
  });
  return (
    <>
      <DocumentTitle title="DOCUMENT TEMPLATE | LEGAL CRM">
        {isLoading1 ? (
          <Preloader />
        ) : (
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  <div className="pageContent">
                    <div className="PageTitle">
                      <h3>Document Template</h3>

                      <a
                        style={{
                          display: isAdd === "0" ? "none" : "block",
                        }}
                        type="button"
                        className="btn btn-primary btn_client headbtn"
                        data-toggle="modal"
                        data-target="#exampleModalLong"
                      >
                        Add Document Template
                        <img src="assets/img/icons/add.svg" />
                      </a>
                    </div>
                        <div
                          style={{
                            display: isView === "0" ? "none" : "block",
                          }}
                          className="searDesign"
                        >
                          <MaterialReactTable table={table} />
                        </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <Footer/>
            </div>

            {/* Delete */}

            <div
              className="modal fade sc_modal"
              id="exampleModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close d_document"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <h4>Delete Row</h4>
                    <p>
                      Are you sure you want to delete this document template ?
                    </p>
                    <ul>
                      <li>
                        <a
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </a>
                      </li>
                      <li>
                        <a
                          className="btn btn-danger"
                          onClick={() => deleteDocument(delId)}
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Add User Form */}
            <div
              className="modal fade"
              id="exampleModalLong"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Add Document Template</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">Template Name</label>
                            <input
                              className="name"
                              type="text"
                              placeholder="Enter Template Name"
                              name="uname"
                              value={dFileName}
                              onChange={(e) => setDFileName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <label htmlFor="username">Details</label>
                        </div>
                       
                        <ReactQuill
                          theme="snow"
                          modules={modules}
                          formats={formats}
                          placeholder="write your content ...."
                          onChange={handleProcedureContentChange}
                          style={{ height: "320px" }}
                        ></ReactQuill>
                       
                       

                        <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_save btn_cancle"
                            onClick={clearForm}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Edit Document Form */}

            <div
              className="modal fade"
              id="exampleModalLongEdit"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Edit Document Template</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close e_popup btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitEditHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Template Name <text className="m_star">*</text>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Template Name"
                              name="uname"
                              value={editDocTemplate}
                              onChange={(e) =>
                                setEditDocTemplate(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <label htmlFor="username">
                            Details <text className="m_star">*</text>
                          </label>
                        </div>
                       
                        <ReactQuill
                          value={editDescription}
                          theme="snow"
                          modules={modules}
                          formats={formats}
                          placeholder="write your content ...."
                          onChange={handleEdit}
                          style={{ height: "320px" }}
                        ></ReactQuill>
                       
                       

                        <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_save btn_cancle"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* View Document Form */}
            <div
              className="modal fade"
              id="exampleModalLongDetail"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>View Document Template</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close e_popup btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <label htmlFor="uname">
                            Template Name
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Template Name"
                            name="uname"
                            value={DocTemplate}
                          />
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <label htmlFor="username">
                          Details 
                        </label>
                      </div>
                     
                      <ReactQuill
                        value={Descriptions}
                        theme="snow"
                        className="editorReact"
                        modules={modules}
                        readOnly
                        formats={formats}
                        placeholder="write your content ...."
                        onChange={handleEdit}
                        style={{ height: "320px" }}
                      ></ReactQuill>
                     
                  
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Toster */}
            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        )}
      </DocumentTitle>
    </>
  );
};
export default DocumentTemp;
