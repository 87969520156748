import React, { useState, useRef } from "react";
import autoTable from "jspdf-autotable";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { format } from "date-fns";
import Select from "react-select";
import { useNavigate } from "react-router";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {
  deleteUser,
  userDetails,
  activeStatus,
  permission,
  getClient,
  withUidApi,
  clearForm,
  formateDateTime,
} from "../services/api.service";
import { formatDate1 } from "../services/api.service";
import Preloader from "./Preloader";
import DocumentTitle from "react-document-title";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv } from "export-to-csv";
import jsPDF from "jspdf";
import Footer from "./Footer";

const Task = () => {
  const [saveButton, setSaveButton] = useState(true);
  const [historyShow, setHistoryShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);
  //        -------- POPUP CLOSE VARIABLE ---------

  const closeButton = document.querySelector(".btn_popup");
  const closeView = document.querySelector(".ab");
  const closeEdit = document.querySelector(".e_popup");
  const closeEditHistory = document.querySelector(".e_popup_h");
  const closeDelete = document.querySelector(".mm");

  //        -------- TASK VARIABLE ---------

  const currentDate = new Date().toISOString().split("T")[0];
  const [taskRelated, setTaskRelated] = useState("");
  const [editTaskRelated, setEditTaskRelated] = useState("");
  const [taskOther, setTaskOther] = useState("");
  const [editTaskOther, setEditTaskOther] = useState("");
  const baseUrl = useContext(url);
  const navigate = useNavigate();
  const [DeadLine, setDeadLine] = useState(currentDate);
  const [filterActive, setFilterActive] = useState(false);
  const [fileNoShow, setFileNoShow] = useState(true);
  const [fCaseId, setFCaseId] = useState("");
  const [fFromDate, setFFromDate] = useState("");
  const filterform = new FormData();
  const form = new FormData();
  const [fToDate, setFToDate] = useState("");
  const [fClientID, setFClientID] = useState("");
  const [fClientOption, setFClientOption] = useState([]);
  const [taskDescription, setTaskDescription] = useState("");
  const [hNextStage, setHNextStage] = useState("");
  const [hTaskId, setHTaskId] = useState("");
  const [TaskName, setTaskName] = useState("");
  const [Details, setDetails] = useState("");
  const [addCalender, setAddCalender] = useState(false);
  const [StageRemarks, setStageRemarks] = useState("");
  const [NextStage, setNextStage] = useState("");
  const [CaseID, setCaseID] = useState("");
  const [UserID, setUserID] = useState("");
  const [user, setuser] = useState([]);

  //        -------- ADD TASK EDIT TASK VARIABLE ---------

  const [EditID, setEditID] = useState("");
  const [EditUserID, setEditUserID] = useState("");
  const [EditDeadLine, setEditDeadLine] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [EditTaskName, setEditTaskName] = useState("");
  const [EditDetails, setEditDetails] = useState("");
  const [EditStageRemarks, setEditStageRemarks] = useState("");
  const [EditNextStage, setEditNextStage] = useState("");
  const [EditCaseID, setEditCaseID] = useState("");
  const [dAssignedTo, setDAssignedTo] = useState("");
  const [dCounselName, setDCounselName] = useState("");
  const [dCoCounselName, setDCoCounselName] = useState("");
  const [dCaseName, setDCaseName] = useState("");
  const [dCaseNo, setDCaseNo] = useState("");
  const [dFileNo, setDFileNo] = useState("");
  const [dStatus, setDStatus] = useState("");
  const [dClient, setDClient] = useState("");
  const [dClientCompany, setDClientCompany] = useState("");
  const [dClientlastName, setDClientlastName] = useState("");
  const [dStageRemarks, setDStageRemarks] = useState("");
  const [dNextStage, setDNextStage] = useState("");
  const [dTaskName, setDTaskName] = useState("");
  const [dDetails, setDDetails] = useState("");
  const [dDeadLine, setDDeadLine] = useState("");
  const [isAdd, setIsAdd] = useState("0");
  const [isEdit, setIsEdit] = useState("0");
  const [isView, setIsView] = useState("0");
  const [isDelete, setIsDelete] = useState("0");
  const [isStatus, setIsStatus] = useState("0");
  const [dTaskStage, setDTaskStage] = useState("");
  const [caseLeadData, setCaseLeadData] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [delId, setDelId] = useState("");
  const formData = new FormData();
  const formEditData = new FormData();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const handleExportButtonClick = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const handleToggleColumn = (columnName) => {
    const currentIndex = selectedColumns.indexOf(columnName);
    const newSelectedColumns = [...selectedColumns];

    if (currentIndex === -1) {
      newSelectedColumns.push(columnName);
    } else {
      newSelectedColumns.splice(currentIndex, 1);
    }

    setSelectedColumns(newSelectedColumns);
  };

  // ---------- FILTER API --------- MANISH PRAJAPAT DATE 06 - 10 - 2023 ---------

  const filterApi = async () => {
    setLoading(true);
    filterform.append("AdminID", localStorage.getItem("id"));
    filterform.append("UserID", localStorage.getItem("uId"));
    filterform.append("CaseID", fCaseId);
    filterform.append("ClientID", fClientID);
    filterform.append("From", fFromDate);
    filterform.append("To", fToDate);
    try {
      const response = await fetch(`${baseUrl}Task/ViewTaskfilter`, {
        method: "POST",
        body: filterform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }

      if (
        data &&
        data.result.length &&
        data &&
        data.result[0].message === "Success"
      ) {
        setFilterActive(true);
        setLoading(false);
        setCaseLeadData(data.result);
        setData(data.result);
      } else {
        setCaseLeadData([]);
        setLoading(false);
        setData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const filterCancel2 = () => {
    clearForm();
    setCaseID("");
  };
  const filterCancel = () => {
    clearForm();
    setFCaseId("");
    setFClientID("");
    setFFromDate("");
    setFToDate("");
    getCaseView();
    setFilterActive(false);
  };

  const handleFilterCase = (selectedOption) => {
    if (selectedOption === null) {
      setFCaseId("");
    } else {
      setFCaseId(selectedOption.value);
    }
  };

  const handleFilterClient = (selectedOption) => {
    if (selectedOption === null) {
      setFClientID("");
    } else {
      setFClientID(selectedOption.value);
    }
  };

  //        -------- VIEW TASK DATA ---------

  const getUser = async (path) => {
    form.append("AdminID", localStorage.getItem("id"));
    form.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}${path}`, {
        method: "POST",

        body: form,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.UserList[0].message == "Success") {
        return data.result.UserList;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCaseView = async () => {
    const [view, data, userData, cdata] = await Promise.all([
      withUidApi("Task/ViewTask"),
      permission(7),
      getUser("Admin/ViewUser"),
      getClient("Client/ViewClient"),
      getFileNo(),
      setLoading(true),
    ]);

    if (view === null) {
      setLoading(false);
      setCaseLeadData([]);
      setData([]);
    } else {
      if (view === "token") {
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        setLoading(false);
        setCaseLeadData(view);
        setData(view && view);
      }
    }

    const counselData =
      userData && userData.filter((item) => item.Status === "1");
    setuser(counselData);

    const clientData = cdata && cdata.filter((item) => item.Status === "1");

    const Mapped =
      clientData &&
      clientData.map((item) => ({
        value: item.Id,
        label: `${item.FirstName} ${item.LastName}`,
      }));
    setFClientOption(Mapped);

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }

    setIsAdd(data && data.result[0].IsAdd);
    setIsEdit(data && data.result[0].IsEdit);
    setIsView(data && data.result[0].IsView);
    setIsStatus(data && data.result[0].IsStatus);
    setIsDelete(data && data.result[0].IsDelete);
  };

  //        -------- DELETE TASK API ---------

  const deleteCaseLead = async () => {
    const data = await deleteUser(delId, "Task/DeleteTask");
    if (data === "token") {
      setTimeout(() => {
        closeDelete.click();
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      closeDelete.click();
      filterActive ? filterApi() : getCaseView();
    }
  };

  //        -------- Task Stage ---------
  const TaskStagef = new FormData();
  const [TaskStage, setTaskStage] = useState([]);
  const [activeTaskStage, setActiveTaskStage] = useState([]);

  const getTaskStage = async () => {
    TaskStagef.append("AdminID", localStorage.getItem("id"));
    try {
      const response = await fetch(`${baseUrl}Master/ViewTaskStage`, {
        method: "POST",
        body: TaskStagef,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].code == 200) {
        setTaskStage(data.result);
        setActiveTaskStage(data.result.filter((court) => court.Status === "1"));
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        -------- ACTIVE DEACTIVE ---------

  const deActive = async (row) => {
    const data = await activeStatus(row, "Task/TaskStatus");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      filterActive ? filterApi() : getCaseView();
    }
  };

  //        -------- TASK DETAILS DATA ---------

  const details = async (id) => {
    const data = await userDetails(id, "Task/ViewTaskById");
    if (data === "token") {
      setTimeout(() => {
        closeView.click();
        navigate("/");
      }, 3000);
    } else {
      setDNextStage(data && data.result[0].NextStageName);
      setDStatus(data && data.result[0].Status);
      setDDeadLine(data && data.result[0].DeadLine);
      setDStageRemarks(data && data.result[0].StageRemarks);
      setDDetails(data && data.result[0].Details);
      setDTaskName(data && data.result[0].TaskName);
      setDAssignedTo(data && data.result[0].AssignedTo);
      setDCaseName(data && data.result[0].CaseName);
      setDClient(data && data.result[0].Client);
      setDFileNo(data && data.result[0].FileNo);
      setDCaseNo(data && data.result[0].CaseNo);
      setDClientCompany(data && data.result[0].ClientCompany);
      setDClientlastName(data && data.result[0].ClientlastName);
      setDCounselName(data && data.result[0].Counsel);
      setDCoCounselName(data && data.result[0].Cocounsel);
    }
  };

  //        -------- TASK DATA ---------

  const historyUpdate = async (id) => {
    TaskStagef.append("AdminID", localStorage.getItem("id"));
    TaskStagef.append("TaskID", id);
    try {
      const response = await fetch(`${baseUrl}Task/ViewTaskHistory`, {
        method: "POST",
        body: TaskStagef,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }

      if (data && data.result[0].message === "Success") {
        setHTaskId(data && data.result[0].TaskID);
        setHistoryData(data && data.result);
        setHNextStage(data && data.result[0].TaskStageID);
        setTaskDescription("");
        setDTaskName(data && data.result[0].TaskName);
        setDDeadLine(data && data.result[0].DeadLine);
        setDTaskStage(data && data.result[0].TaskStatus);

        if (data && data.result.length > "1") {
          setHistoryShow(true);
        } else setHistoryShow(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getById = async (id) => {
    const data = await userDetails(id, "Task/ViewTaskById");
    if (data === "token") {
      setTimeout(() => {
        closeEdit.click();
        navigate("/");
      }, 3000);
    } else {
      setEditID(data && data.result[0].Id);
      setEditUserID(data && data.result[0].UserID);
      setEditDeadLine(data && data.result[0].DeadLine);
      setEditTaskName(data && data.result[0].TaskName);
      setEditDetails(data && data.result[0].Details);
      setEditStageRemarks(data && data.result[0].StageRemarks);
      setEditNextStage(data && data.result[0].NextStage);
      setEditCaseID(data && data.result[0].CaseID);
      setEditTaskRelated(
        data && data.result[0].TaskRelated === null
          ? ""
          : data.result[0].TaskRelated
      );
      setEditTaskOther(
        data && data.result[0].TaskOther === null
          ? ""
          : data.result[0].TaskOther
      );
      setFileNoShow(
        data && data.result[0].TaskRelated === "Case" ? true : false
      );
    }
  };

  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  const openInNewTab = (id) => {
    window.open(
      `https://api.lawyerhunt.in/GoogleCalendarController/authenticates/${id}`,
      "_blank",
      "width=600, height=600"
    );
  };

  const openInNewTabFun = (id) => {
    if (addCalender === true) {
      openInNewTab(id);
    }
  };

  //        -------- SUBMIT TASK DATA ---------

  const submitHandler = async (event) => {
    event.preventDefault();
    setSaveButton(false);
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("UserID", UserID);
    formData.append("DeadLine", DeadLine);
    formData.append("TaskName", TaskName.toUpperCase());
    formData.append("Details", Details.toUpperCase());
    formData.append("StageRemarks", StageRemarks.toUpperCase());
    formData.append("NextStage", NextStage);
    formData.append("CaseID", CaseID);
    formData.append("TaskRelated", taskRelated);
    formData.append("TaskOther", taskOther);
    formData.append("AddCalendar", addCalender === true ? "1" : "0");
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    try {
      const response = await fetch(`${baseUrl}Task/AddTask`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 3000);
      }
      if (data.result.length && data.result[0].message === "Success") {
        toast.success("Task added successfully");
        handleClose();
        setAddCalender(false);
        setTaskRelated("");
        setTaskOther("");
        filterActive ? filterApi() : getCaseView();
        setUserID("");
        setDeadLine(currentDate);
        setTaskName("");
        setDetails("");
        setStageRemarks("");
        setNextStage("");
        setCaseID("");
        closeButton.click();
        openInNewTabFun(data.result[0].Id);
        setSaveButton(true);
      } else {
        toast.error(data.result.message);
        setSaveButton(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        --------HISTORY UPDATE TASK API ---------

  const submitHistoryHandler = async (event) => {
    event.preventDefault();
    // formEditData.append("AdminID", localStorage.getItem("id"));
    formEditData.append("TaskID", hTaskId);
    formEditData.append("TaskDetails", taskDescription.toUpperCase());
    formEditData.append("NextStage", hNextStage);
    formEditData.append("UserID", localStorage.getItem("UserId"));
    formEditData.append("CreatedBy", localStorage.getItem("UserId"));
    try {
      const response = await fetch(`${baseUrl}Task/AddTaskHistory`, {
        method: "POST",
        body: formEditData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeEdit.click();
          navigate("/");
        }, 3000);
      }
      if (data.result.length && data.result[0].message === "Success") {
        toast.success(" Updated successfully");
        closeEditHistory.click();
        filterActive ? filterApi() : getCaseView();
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        -------- EDIT TASK API ---------

  const submitEditHandler = async (event) => {
    event.preventDefault();
    formEditData.append("ID", EditID);
    formEditData.append("AdminID", localStorage.getItem("id"));
    formEditData.append("UserID", EditUserID);
    formEditData.append("DeadLine", EditDeadLine);
    formEditData.append("TaskName", EditTaskName.toUpperCase());
    formEditData.append("Details", EditDetails.toUpperCase());
    formEditData.append("StageRemarks", EditStageRemarks.toUpperCase());
    formEditData.append("NextStage", EditNextStage);
    formEditData.append("CaseID", EditCaseID);
    formEditData.append("TaskRelated", editTaskRelated);
    formEditData.append("TaskOther", editTaskOther);
    formEditData.append("UpdatedBy", localStorage.getItem("UserId"));
    try {
      const response = await fetch(`${baseUrl}Task/EditTask`, {
        method: "POST",
        body: formEditData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeEdit.click();
          navigate("/");
        }, 3000);
      }
      if (data.result.length && data.result[0].message === "Success") {
        toast.success(" Updated successfully");
        closeEdit.click();
        filterActive ? filterApi() : getCaseView();
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddCalendar = (e) => {
    setAddCalender(e.target.checked);
  };

  //        -------- TABLE ---------

  const columnHelper = createMRTColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      header: "Action",
      Cell: (row) => {
        return (
          <>
            <div style={{ display: "flex", gap: "15px" }}>
              {isEdit !== "0" && (
                <span
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalLongEdit"
                  onClick={() => getById(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/edit.svg"
                    alt="Edit"
                    title="Edit"
                  />
                </span>
              )}
              {isEdit !== "0" && (
                <span
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalLongHistory"
                  onClick={() => historyUpdate(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/add.svg"
                    alt="History Update"
                    title="History Update"
                  />
                </span>
              )}
              <span
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongDetail"
                onClick={() => details(row.row.original.Id)}
              >
                <img
                  src="assets/img/icons/eye.svg"
                  alt="Details"
                  title="Details"
                />
              </span>
              <span>
                <a
                  style={{
                    cursor: "pointer",
                    display: isStatus === "0" ? "none" : "block",
                  }}
                  onClick={() => deActive(row.row.original)}
                >
                  {row.row.original.Status === "1" ? (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Up.svg"
                      title="Status"
                    />
                  ) : (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Down.svg"
                      title="Status"
                    />
                  )}
                </a>
              </span>
              {isDelete !== "0" && (
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style={{ cursor: "pointer" }}
                  onClick={() => setDelId(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/delete.svg"
                    alt="Delete"
                    title="Delete"
                  />
                </span>
              )}
            </div>
          </>
        );
      },
    }),

    columnHelper.accessor("Date", {
      header: "Deadline Date",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.DeadLine === null ||
            row.row.original.DeadLine === "0000-00-00"
              ? "-"
              : new Date(row.row.original.DeadLine).toLocaleDateString("en-GB")}
          </div>
        );
      },
    }),

    columnHelper.accessor("FileNo", {
      header: "File No",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.FileNo === null || row.row.original.FileNo === ""
              ? "-"
              : row.row.original.FileNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseNo", {
      header: "Case No",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CaseNo === null || row.row.original.CaseNo === ""
              ? "-"
              : row.row.original.CaseNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseName", {
      header: "Case Name",
      Cell: (row) => {
        return (
          <div style={{ minWidth: "350px" }} className="upperCase">
            <a
              title="Case Details"
              href={`#/view-casedetails/${row.row.original.CaseID}`}
              className="visuald_case"
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              {row.row.original.CaseName === null ||
              row.row.original.CaseName === ""
                ? "-"
                : row.row.original.CaseName}
            </a>
          </div>
        );
      },
    }),
    columnHelper.accessor("TaskName", {
      header: "Task Name",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.TaskName === null ||
            row.row.original.TaskName === ""
              ? "-"
              : row.row.original.TaskName}
          </div>
        );
      },
    }),
    columnHelper.accessor("Counsel", {
      header: "Counsel",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Counsel === null ||
            row.row.original.Counsel === ""
              ? "-"
              : row.row.original.Counsel}
          </div>
        );
      },
    }),
    columnHelper.accessor("Cocounsel", {
      header: "Co-counsel",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Cocounsel === null ||
            row.row.original.Cocounsel === ""
              ? "-"
              : row.row.original.Cocounsel}
          </div>
        );
      },
    }),
    columnHelper.accessor("ClientName", {
      header: "Client",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.ClientName}
            <br />
            {row.row.original.MobileNo}
          </div>
        );
      },
    }),
    columnHelper.accessor("Details", {
      header: "Details",
      Cell: (row) => {
        return (
          <div
            className="upperCase"
            style={{
              display: " -webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}
          >
            {row.row.original.Details === null ||
            row.row.original.Details === ""
              ? "-"
              : row.row.original.Details}
          </div>
        );
      },
    }),
    columnHelper.accessor("StageRemarks", {
      header: "Stage Remarks",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.StageRemarks === null ||
            row.row.original.StageRemarks === ""
              ? "-"
              : row.row.original.StageRemarks}
          </div>
        );
      },
    }),
    columnHelper.accessor("AssignTo", {
      header: "Assign To",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.AssignTo === null ||
            row.row.original.AssignTo === ""
              ? "-"
              : row.row.original.AssignTo}
          </div>
        );
      },
    }),

    columnHelper.accessor("UpdatedBy", {
      header: "Last-Modified",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.UpdatedBy === null ||
            row.row.original.UpdatedBy === ""
              ? "-"
              : row.row.original.UpdatedBy}
            <br />
            {formateDateTime(row.row.original.UpdatedDate)}
          </div>
        );
      },
    }),

    columnHelper.accessor("NextStage", {
      header: "Next Stage",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.NextStage === null ||
            row.row.original.NextStage === ""
              ? "-"
              : row.row.original.NextStage}
          </div>
        );
      },
    }),
    columnHelper.accessor("Status", {
      header: "Status",
      Cell: (row) => {
        return (
          <div>{row.row.original.Status === "1" ? "ACTIVE" : "DEACTIVE"}</div>
        );
      },
    }),

    columnHelper.accessor("AddedBy", {
      header: "Added By",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.AddedBy === null ||
            row.row.original.AddedBy === ""
              ? "-"
              : row.row.original.AddedBy}
            <br />
            {formateDateTime(row.row.original.CreatedDate)}
          </div>
        );
      },
    }),
  ];

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });
  /** Get File Name  */
  const Case = new FormData();
  const [optionsCase, setOptionsCase] = useState([]);

  const getFileNo = async () => {
    Case.append("AdminID", localStorage.getItem("id"));
    Case.append("UserID", localStorage.getItem("uId"));

    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: Case,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }

      if (data && data.result[0].message === "Success") {
        const Mapped = data.result.map((item) => ({
          value: item.CaseID,
          label: `${item.FileNo} / ${item.CaseNo} / ${item.CaseName} / ${item.CaseYear}`,
        }));
        setOptionsCase(Mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleTaskRelated = (event) => {
    setTaskRelated(event.target.value);
    if (event.target.value === "Other") {
      setFileNoShow(false);
      setCaseID("");
    } else {
      setFileNoShow(true);
      setTaskOther("");
    }
  };

  const handleEditTaskRelated = (event) => {
    setEditTaskRelated(event.target.value);
    if (event.target.value === "Other") {
      setFileNoShow(false);
      setEditCaseID("");
    } else {
      setFileNoShow(true);
      setEditTaskOther("");
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setCaseID(null);
    } else {
      setCaseID(selectedOption.value);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getCaseView();
    }
  }, []);

  useEffect(() => {
    getTaskStage();
  }, []);

  const excelData =
    data &&
    data.map((obj) => {
      const { Id, code, message, Status, AdminID, ...rest } = obj;
      return rest;
    });

  const handleExportData = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });

    const formattedDate = new Date().toISOString().slice(0, 10);
    const filename = `Task_${formattedDate}.csv`;

    const csv = generateCsv(csvConfig)(excelData);
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const columnsToExport = [
      { id: "serialNo", header: "S No." },
      ...columns.filter((column) => selectedColumns.includes(column.id)),
    ];

    const tableHeaders = columnsToExport.map((c) => c.header);

    const formattedDate = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const filename = `Task_${formattedDate}.pdf`;
    const addWatermarkToFirstPage = () => {
      const watermarkHeight = 20;

      // Calculate positioning to center the watermark on each page

      doc.setFontSize(10);
      doc.text(
        10,
        watermarkHeight + -10,
        `Generated by : ${localStorage.getItem("name")} `
      );
      doc.text(172, watermarkHeight + -10, `Date: ${formattedDate}`);
      doc.setFontSize(16);
      doc.text("Task Table", 84, 20);
    };
    const watermarkHeight = 20;

    addWatermarkToFirstPage();
    const tableData = rows.map((row, index) => {
      const formattedDateInPdf = row.original["DeadLine"]
        ? format(new Date(row.original["DeadLine"]), "dd/MM/yyyy")
        : "";

      return [
        { content: (index + 1).toString(), styles: { fontStyle: "bold" } }, // Serial number
        ...columnsToExport.slice(1).map((column) => {
          // Exclude the first column (serial number) when mapping
          if (column.id === "Date") {
            return formattedDateInPdf;
          }

          const value =
            column.id in row.original ? row.original[column.id] : "";
          return value !== null && value !== undefined ? value : "";
        }),
      ];
    });

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY: watermarkHeight + 5,
    })
    const pdfBlob = doc.output("blob");
    const blobUrl = URL.createObjectURL(pdfBlob);
    window.open(blobUrl, "_blank");
    URL.revokeObjectURL(blobUrl);
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    initialState: {
      columnVisibility: {
        Status: false,
        AddedBy: false,
        UpdatedBy: false,
        UpdatedDate: false,
        CreatedDate: false,
        NextStage: false,
      },
    },
    enableStickyHeader: true,
    enableRowNumbers: true,
    state: {
      isLoading: loading,
    },
    muiCircularProgressProps: {
      color: "primary",
      thickness: 5,
      size: 55,
      style: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000,
      },
    },
    muiSkeletonProps: {
      animation: "pulse",
      height: 20,
    },
    muiPaginationProps: {
      rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export Excel
        </Button>

        <Button
          disabled={
            !table || table.getPrePaginationRowModel().rows.length === 0
          }
          onClick={handleExportButtonClick}
          startIcon={<FileDownloadIcon />}
        >
          Export PDF
        </Button>
        <Dialog open={open} onClose={handleClose1}>
          <DialogTitle>Select Columns To Export</DialogTitle>
          <DialogContent>
            {columns.map((column) =>
              column.header !== "Action" ? (
                <div key={column.id}>
                  <Checkbox
                    checked={selectedColumns.includes(column.id)}
                    onChange={() => handleToggleColumn(column.id)}
                  />
                  {column.header}
                </div>
              ) : (
                ""
              )
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose1}>Cancel</Button>
            <Button
              onClick={() =>
                handleExportRows(table.getPrePaginationRowModel().rows)
              }
            >
              Export
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    ),
  });

  return (
    <>
      <DocumentTitle title="TASK | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  <div className="pageContent">
                    <div className="PageTitle">
                      <h3>Task List</h3>

                      <a
                        style={{
                          display: isAdd === "0" ? "none" : "block",
                        }}
                        type="button"
                        className="btn btn-primary btn_client headbtn"
                        data-toggle="modal"
                        data-target="#exampleModalLong"
                      >
                        Add Task <img src="assets/img/icons/add.svg" />
                      </a>
                    </div>

                    <div
                      className="filter_type add_wrap"
                      style={{
                        display: isView === "0" ? "none" : "block",
                      }}
                    >
                      <div className="form_flex1 flex_wrap  caseMobile">
                        <div className="form-group1 zIndex1">
                          <label>File No.</label>

                          <Select
                            className="basic-single"
                            placeholder="Please Select "
                            classNamePrefix="select"
                            onChange={handleFilterCase}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={optionsCase}
                          />
                        </div>
                        <div className="form-group1 zIndex2">
                          <label>Client Name</label>

                          <Select
                            className="basic-single"
                            placeholder="Please Select "
                            classNamePrefix="select"
                            onChange={handleFilterClient}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={fClientOption}
                          />
                        </div>
                        <div className="form-group1">
                          <label>From Date</label>
                          <input
                            // max={currentDate}
                            autoComplete="off"
                            className="name"
                            type="date"
                            value={fFromDate}
                            onChange={(e) => setFFromDate(e.target.value)}
                          />
                        </div>
                        <div className="form-group1">
                          <label>To Date</label>
                          <input
                            autoComplete="off"
                            className="name"
                            type="date"
                            value={fToDate}
                            onChange={(e) => setFToDate(e.target.value)}
                          />
                        </div>
                        <div className="form-group1 caseMobile btnGroup">
                          <div className="btn_main casebtn">
                            <button
                              type="submit"
                              className="btn btn_client"
                              onClick={filterApi}
                            >
                              Search
                            </button>
                            <button
                              type="button"
                              className="btn btn_client"
                              onClick={filterCancel}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: isView === "0" ? "none" : "block",
                      }}
                      // className="searDesign"
                    >
                      <MaterialReactTable table={table} />
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <Footer />
            </div>

            {/*-------- DELETE TASK FORM -------*/}

            <div
              className="modal fade sc_modal"
              id="exampleModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close mm"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <h4>Delete Row</h4>
                    <p>Are you sure you want to delete Task ?</p>
                    <ul>
                      <li>
                        <a
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </a>
                      </li>
                      <li>
                        <a
                          className="btn btn-danger"
                          onClick={() => deleteCaseLead(delId)}
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- ADD TASK FORM ---------*/}

            <div
              className="modal fade"
              id="exampleModalLong"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
              show={show}
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Add Task</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Task Name<text className="m_star">*</text>
                            </label>
                            <input
                              className="name"
                              type="text"
                              placeholder="Enter Task Name"
                              name="uname"
                              value={TaskName}
                              onChange={(e) => setTaskName(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              Task Related<text className="m_star">*</text>
                            </label>
                            <div
                              style={{
                                display: "flex",
                                gap: "40px",
                                borderRadius: "4px",
                                border: "1px solid #7a0000",
                              }}
                            >
                              <div
                                style={{ display: "flex", marginLeft: "20px" }}
                              >
                                <input
                                  required
                                  style={{ width: "20px" }}
                                  className="name"
                                  type="radio"
                                  id="case"
                                  name="taskrelated"
                                  value="Case"
                                  onChange={handleTaskRelated}
                                />{" "}
                                &nbsp;&nbsp;{" "}
                                <label
                                  style={{ marginTop: "10px" }}
                                  htmlFor="case"
                                >
                                  Case
                                </label>
                              </div>
                              <div style={{ display: "flex" }}>
                                <input
                                  required
                                  style={{ width: "20px" }}
                                  className="name"
                                  type="radio"
                                  id="other"
                                  name="taskrelated"
                                  value="Other"
                                  onChange={handleTaskRelated}
                                />
                                &nbsp;&nbsp;{" "}
                                <label
                                  style={{ marginTop: "10px" }}
                                  htmlFor="other"
                                >
                                  Other
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          {fileNoShow ? (
                            <div className="form-group">
                              <label htmlFor="uname">
                                File No.<text className="m_star">*</text>
                              </label>
                              <Select
                                required
                                className="basic-single"
                                placeholder="Select "
                                classNamePrefix="select"
                                onChange={handleSelectChange}
                                isClearable={true}
                                isSearchable={true}
                                name="color"
                                options={optionsCase}
                              />
                            </div>
                          ) : (
                            <div className="form-group">
                              <label htmlFor="uname">
                                Other<text className="m_star">*</text>
                              </label>
                              <input
                                className="name"
                                type="text"
                                placeholder="Enter Other Name"
                                name="uname"
                                value={taskOther}
                                onChange={(e) => setTaskOther(e.target.value)}
                                required
                              />
                            </div>
                          )}
                          <div className="form-group">
                            <label htmlFor="uname">
                              Current Stage<text className="m_star">*</text>
                            </label>
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;
                            <a className="visuald" href="/#/view-taskstage">
                              +Add Next Stage
                            </a>
                            <select
                              className="name"
                              name="cars"
                              id="cars"
                              value={NextStage}
                              onChange={(e) => setNextStage(e.target.value)}
                              required
                            >
                              <option value="">--Please Select--</option>

                              {activeTaskStage.map((val, index) => {
                                return (
                                  <option key={index} value={val.Id}>
                                    {val.TaskStage}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Assigned to User<text className="m_star">*</text>
                            </label>
                            <select
                              className="name"
                              id="city"
                              value={UserID}
                              onChange={(e) => setUserID(e.target.value)}
                              required
                            >
                              <option value="">--Please Select--</option>
                              {user && user.length > 0 ? (
                                user.map((val) => (
                                  <option key={val.id} value={val.Id}>
                                    {val.Name}
                                  </option>
                                ))
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">DeadLine Date </label>
                            <input
                              className="name"
                              type="datetime-local"
                              min={currentDate}
                              placeholder=""
                              name="uname"
                              value={DeadLine}
                              onChange={(e) => {
                                setDeadLine(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">Stage Remarks </label>
                            <textarea
                              className="name"
                              type="text"
                              name="uname"
                              placeholder="Enter Stage Remarks"
                              value={StageRemarks}
                              onChange={(e) => setStageRemarks(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Details<text className="m_star">*</text>{" "}
                            </label>
                            <textarea
                              required
                              className="name"
                              type="text"
                              name="uname"
                              placeholder="Enter Details"
                              value={Details}
                              onChange={(e) => setDetails(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div style={{ display: "flex" }}>
                            <input
                              style={{ width: "20px" }}
                              className="name"
                              type="checkbox"
                              id="addcalendar"
                              checked={addCalender}
                              onChange={handleAddCalendar}
                            />
                            &nbsp;&nbsp;{" "}
                            <label
                              style={{ marginTop: "10px" }}
                              htmlFor="addcalendar"
                            >
                              Would you like to schedule it on Google Calendar?
                            </label>
                          </div>
                        </div>
                        {saveButton ? (
                          <div className="btn_main caseMobile">
                            <button type="submit" className="btn_save">
                              Save
                            </button>
                            <button
                              type="button"
                              data-dismiss="modal"
                              className="btn_save btn_cancle"
                            >
                              Cancel
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- EDIT TASK HISTORY FORM ---------*/}

            <div
              className="modal fade"
              id="exampleModalLongHistory"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "40%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>History Update</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close e_popup_h btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitHistoryHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <h4>
                              Task Stage &nbsp;: &nbsp;{" "}
                              {dTaskStage === null || dTaskStage === ""
                                ? "-"
                                : dTaskStage}{" "}
                            </h4>
                          </div>
                          <div className="form-group">
                            <h4>
                              Dead Line &nbsp;:&nbsp;{" "}
                              {dDeadLine === null
                                ? "-"
                                : formatDate1(dDeadLine)}{" "}
                            </h4>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <h4>
                              Task Name &nbsp;: &nbsp;{" "}
                              {dTaskName === null ? "-" : dTaskName}{" "}
                            </h4>
                          </div>
                        </div>
                        <div className="form_flex caseMobile col-md-6">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Status<text className="m_star">*</text>
                            </label>
                            <select
                              name="cars"
                              id="cars"
                              value={hNextStage}
                              onChange={(e) => setHNextStage(e.target.value)}
                              required
                            >
                              <option value="">--Please Select--</option>
                              {activeTaskStage.map((val, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={val.Id}
                                    selected={val.Id === hNextStage}
                                  >
                                    {val.TaskStage}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Task Descriptions<text className="m_star">*</text>
                            </label>
                            <textarea
                              type="text"
                              name="uname"
                              placeholder="Enter Task Description"
                              value={taskDescription}
                              onChange={(e) =>
                                setTaskDescription(e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                        <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_save btn_cancle"
                          >
                            Cancel
                          </button>
                        </div>
                        <br />
                        <div className="form_flex caseMobile">
                          {historyShow && (
                            <div className="form-group">
                              <h4>History :</h4>
                              <br />
                              <table className="table detail_table">
                                <tr>
                                  <th>S. No</th>
                                  <th>Task Stage</th>
                                  <th>Task Descriptios</th>
                                </tr>

                                {historyData &&
                                  historyData.map((val, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{val.TaskStatus}</td>
                                        <td>
                                          {val.TaskDescriptios === ""
                                            ? "-"
                                            : val.TaskDescriptios}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </table>
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- EDIT TASK FORM ---------*/}

            <div
              className="modal fade"
              id="exampleModalLongEdit"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Edit Task</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close e_popup btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitEditHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Task Name<text className="m_star">*</text>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Task Name"
                              name="uname"
                              value={EditTaskName}
                              onChange={(e) => setEditTaskName(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              Task Related<text className="m_star">*</text>
                            </label>
                            <div
                              style={{
                                display: "flex",
                                gap: "40px",
                                borderRadius: "4px",
                                border: "1px solid #7a0000",
                              }}
                            >
                              <div
                                style={{ display: "flex", marginLeft: "20px" }}
                              >
                                <input
                                  required
                                  style={{ width: "20px" }}
                                  className="name"
                                  type="radio"
                                  id="eCase"
                                  name="taskrelated"
                                  value="Case"
                                  onChange={handleEditTaskRelated}
                                  checked={"Case" === editTaskRelated}
                                />{" "}
                                &nbsp;&nbsp;{" "}
                                <label
                                  style={{ marginTop: "10px" }}
                                  htmlFor="eCase"
                                >
                                  Case
                                </label>
                              </div>
                              <div style={{ display: "flex" }}>
                                <input
                                  required
                                  style={{ width: "20px" }}
                                  className="name"
                                  type="radio"
                                  id="eOther"
                                  name="taskrelated"
                                  value="Other"
                                  onChange={handleEditTaskRelated}
                                  checked={"Other" === editTaskRelated}
                                />
                                &nbsp;&nbsp;{" "}
                                <label
                                  style={{ marginTop: "10px" }}
                                  htmlFor="eOther"
                                >
                                  Other
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="form_flex caseMobile">
                          {fileNoShow ? (
                            <div className="form-group">
                              <label htmlFor="uname">
                                File No.<text className="m_star">*</text>
                              </label>
                              <select
                                name="cars"
                                id="cars"
                                onChange={(e) => setEditCaseID(e.target.value)}
                                required
                              >
                                <option value="">--Please Select--</option>
                                {optionsCase && optionsCase.length ? (
                                  optionsCase.map((val, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={val.value}
                                        selected={val.value == EditCaseID}
                                      >
                                        {val.label}
                                      </option>
                                    );
                                  })
                                ) : (
                                  <option value="">Loading...</option>
                                )}
                              </select>
                            </div>
                          ) : (
                            <div className="form-group">
                              <label htmlFor="uname">
                                Other<text className="m_star">*</text>
                              </label>
                              <input
                                className="name"
                                type="text"
                                placeholder="Enter Other Name"
                                name="uname"
                                value={editTaskOther}
                                onChange={(e) =>
                                  setEditTaskOther(e.target.value)
                                }
                                required
                              />
                            </div>
                          )}

                          <div className="form-group">
                            <label htmlFor="uname">
                              Current Stage<text className="m_star">*</text>
                            </label>
                            <select
                              name="cars"
                              id="cars"
                              value={EditNextStage}
                              onChange={(e) => setEditNextStage(e.target.value)}
                              required
                            >
                              <option value="">--Please Select--</option>
                              {activeTaskStage.map((val, index) => {
                                return (
                                  <option key={index} value={val.Id}>
                                    {val.TaskStage}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Assigned to User<text className="m_star">*</text>
                            </label>
                            <select
                              id="city"
                              onChange={(e) => setEditUserID(e.target.value)}
                              required
                            >
                              <option value="">--Please Select--</option>
                              {user && user.length > 0 ? (
                                user.map((val) => (
                                  <option
                                    key={val.id}
                                    value={val.Id}
                                    selected={val.Id == EditUserID}
                                  >
                                    {val.Name}
                                  </option>
                                ))
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">DeadLine Date </label>
                            <input
                              type="date"
                              placeholder=""
                              name="uname"
                              value={EditDeadLine}
                              onChange={(e) => {
                                const selectedDate = e.target.value;
                                const today = new Date()
                                  .toISOString()
                                  .split("T")[0];
                                if (selectedDate >= today) {
                                  setEditDeadLine(selectedDate);
                                }
                              }}
                              min={new Date().toISOString().split("T")[0]}
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">Stage Remarks </label>
                            <textarea
                              type="text"
                              name="uname"
                              placeholder="Enter Stage Remarks"
                              value={EditStageRemarks}
                              onChange={(e) =>
                                setEditStageRemarks(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Details<text className="m_star">*</text>
                            </label>
                            <textarea
                              required
                              type="text"
                              name="uname"
                              placeholder="Enter Details"
                              value={EditDetails}
                              onChange={(e) => setEditDetails(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_save btn_cancle"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- DETAILS ---------*/}

            <div
              className="modal fade"
              id="exampleModalLongDetail"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Task Details</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close ab btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Task Name &nbsp;: &nbsp;{" "}
                            {dTaskName === null ? "-" : dTaskName}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Assigned To &nbsp;:&nbsp;{" "}
                            {dAssignedTo === null ? "-" : dAssignedTo}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Counsel Name &nbsp;: &nbsp;{" "}
                            {dCounselName == null ? "-" : dCounselName}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Co Counsel Name &nbsp;:&nbsp;{" "}
                            {dCoCounselName == null ? "-" : dCoCounselName}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Client &nbsp;: &nbsp;{" "}
                            {dClient === null ? "-" : dClient} {dClientlastName}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Next Stage &nbsp;:&nbsp;{" "}
                            {dNextStage === "" ? "-" : dNextStage}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Case Name &nbsp;: &nbsp;{" "}
                            {dCaseName === null ? "-" : dCaseName}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Client Company &nbsp;:&nbsp;{" "}
                            {dClientCompany === null ? "-" : dClientCompany}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Case No. &nbsp;: &nbsp;{" "}
                            {dCaseNo === "" ? "-" : dCaseNo}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            File No &nbsp;:&nbsp;{" "}
                            {dFileNo === null ? "-" : dFileNo}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Dead Line &nbsp;:&nbsp;{" "}
                            {dDeadLine === "0000-00-00"
                              ? "-"
                              : formatDate1(dDeadLine)}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Status &nbsp;:&nbsp;{" "}
                            {dStatus === "1" ? "ACTIVE" : "DEACTIVE"}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Stage Remarks &nbsp;:&nbsp;{" "}
                            {dStageRemarks === null ? "-" : dStageRemarks}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Details &nbsp;:&nbsp;{" "}
                            {dDetails === null ? "-" : dDetails}{" "}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- TOASTER ---------*/}

            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        )}
      </DocumentTitle>
    </>
  );
};

export default Task;
