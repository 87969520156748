// import { configureStore } from "@reduxjs/toolkit";
// import { setupListeners } from '@reduxjs/toolkit/query';
// import ViewUserApi from "../Reduxslice/ViewUserslice";
// import DeleteUserApi from "../Reduxslice/DeleteUserSlice";
// import DeleteRoleApi from "../Reduxslice/DeleteRoleSlice";
// import ClientLeadApi from "../Reduxslice/ClientLeadSlice";
// import DeleteClientLead from "../Reduxslice/DeleteClientLead";
// import DeleteClient from "../Reduxslice/DeleteClient";


// const Store = configureStore({
//     reducer: {
//         [ViewUserApi.reducerPath]: ViewUserApi.reducer,    
//         [DeleteUserApi.reducerPath]: DeleteUserApi.reducer,
//         [DeleteRoleApi.reducerPath]: DeleteRoleApi.reducer,
//         [ClientLeadApi.reducerPath]: ClientLeadApi.reducer,
//         [DeleteClientLead.reducerPath]: DeleteClientLead.reducer,
//         [DeleteClient.reducerPath]: DeleteClient.reducer,
//     },

//     middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware()
//     .concat(ViewUserApi.middleware)
//     .concat(DeleteUserApi.middleware)
//     .concat(DeleteRoleApi.middleware)
//     .concat(ClientLeadApi.middleware)
//     .concat(DeleteClientLead.middleware)
//     .concat(DeleteClient.middleware)
// })

// setupListeners(Store.dispatch)

// export default Store

import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from '@reduxjs/toolkit/query';
import ViewUserApi from "../Reduxslice/ViewUserslice";
import ViewRoleApi from "../Reduxslice/ViewRole";
import DeleteUserApi from "../Reduxslice/DeleteUserSlice";
import DeleteRoleApi from "../Reduxslice/DeleteRoleSlice";
import ViewCaseType from "../Reduxslice/ViewCaseType";
import AddClient from "../Reduxslice/AddClient";
import ViewClient from "../Reduxslice/ViewClient";
import ViewCity from "../Reduxslice/City";
import EditClient from "../Reduxslice/EditClient";
import DeleteClient from "../Reduxslice/DeleteClient";
import AddClientLead from "../Reduxslice/AddClientLead";
import EditClientLead from "../Reduxslice/EditClientLead";
import DeleteClientLead from "../Reduxslice/DeleteClientLead";
import DeleteCaseType from "../Reduxslice/DeleteCaseType";
const Store = configureStore({
    reducer: {
        [ViewUserApi.reducerPath]: ViewUserApi.reducer,
        [ViewRoleApi.reducerPath]: ViewRoleApi.reducer,
        [DeleteUserApi.reducerPath]: DeleteUserApi.reducer,
        [DeleteRoleApi.reducerPath]: DeleteRoleApi.reducer,
        [ViewCaseType.reducerPath]:ViewCaseType.reducer,
        [AddClient.reducerPath]:AddClient.reducer,
        [ViewClient.reducerPath]:ViewClient.reducer,
        [ViewCity.reducerPath]:ViewClient.reducer,
        [EditClient.reducerPath]:EditClient.reducer,
        [DeleteClient.reducerPath]:DeleteClient.reducer,
        [AddClientLead.reducerPath]:AddClientLead.reducer,
        [EditClientLead.reducerPath]:EditClientLead.reducer,
        [DeleteClientLead.reducerPath]:DeleteClientLead.reducer,
        [DeleteCaseType.reducerPath]:DeleteCaseType.reducer
    },

    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
    .concat(ViewUserApi.middleware)
    .concat(ViewRoleApi.middleware)
    .concat(DeleteUserApi.middleware)
    .concat(DeleteRoleApi.middleware)
    .concat(ViewCaseType.middleware)
    .concat(AddClient.middleware)
    .concat(ViewClient.middleware)
    .concat(ViewCity.middleware)
    .concat(EditClient.middleware)
    .concat(DeleteClient.middleware)
    .concat(AddClientLead.middleware)
    .concat(EditClientLead.middleware)
    .concat(DeleteClientLead.middleware)
    .concat(DeleteCaseType.middleware)
})

setupListeners(Store.dispatch)

export default Store