import React, { useState } from "react";
import autoTable from "jspdf-autotable";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import Header from "./Header";
import { format } from "date-fns";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import "react-quill/dist/quill.snow.css";
import { formatDate1, formateDateTime, withUidApi } from "../services/api.service";
import { FaFileAlt } from "react-icons/fa";
import html2pdf from "html2pdf.js";
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";
import {
  viewData,
  deleteUser,
  userDetails,
  activeStatus,
  permission,
  getClient,
} from "../services/api.service";
import Preloader from "./Preloader";
import Select from "react-select";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import DocumentTitle from "react-document-title";
import { jsPDF } from "jspdf";
import Footer from "./Footer";

const Document = () => {

  const [saveButton, setSaveButton] = useState(true);
  const [isLoading1, setIsLoading1] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading1(false);
    }, 500);
  }, []);

  var modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],

      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ["blockquote", "code-block"],
      ["link", "image", "video"],
      [{ direction: "rtl" }],
      [{ font: [] }],

      ["clean"][{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
    ],
  };

  var formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size",
    "background",
    "script",
    "video",
  ];
  const handleProcedureContentChange = (content) => {
    setDDescription(content);
  };

  const handleEdit = (content) => {
    setEditDescription(content);
  };

  // Define the htmlToPlainText function
  function htmlToPlainText(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const body = doc.body;
    const plainText = body.textContent;
    return plainText.trim();
  }

  // pdf

  const handleGeneratePDF = async (id) => {

    const data = await getByIdWord(id);
    handlePrint(data);
  };

  const [title, setTitle] = useState("");
  const [dTitle, setDTitle] = useState("");
  const currentDate = new Date().toISOString().split("T")[0];
  const closeButton = document.querySelector(".btn_popup");
  const closeEdit = document.querySelector(".e_popup");
  const closeDelete = document.querySelector(".d_document");
  const closeView = document.querySelector(".ab");
  const baseUrl = useContext(url);
  const navigate = useNavigate();
  const [excel, setExcel] = useState([]);
  const [isAdd, setIsAdd] = useState("0");
  const [isEdit, setIsEdit] = useState("0");
  const [isView, setIsView] = useState("0");
  const [isDelete, setIsDelete] = useState("0");
  const [isStatus, setIsStatus] = useState("0");
  const [fCaseId, setFCaseId] = useState("");
  const [fFromDate, setFFromDate] = useState("");
  const filterform = new FormData();
  const [fToDate, setFToDate] = useState("");
  const [fClientID, setFClientID] = useState("");
  const [fClientOption, setFClientOption] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const [optionsEvent, setOptionsEvent] = useState([]);
  const [data, setData] = useState([]);
  // Add Document State
  const [Template, setTemplate] = useState([]);
  const [DocumetData, setDocumetData] = useState([]);
  const [dCaseID, setDCaseID] = useState("");
  const [dDate, setDDate] = useState(currentDate);
  const [dFileName, setDFileName] = useState("");
  const [dDocTemplateID, setDDocTemplateID] = useState("");
  const [dDescription, setDDescription] = useState("");
  const [loading, setLoading] = useState(true);
  const [editTitle, setEditTitle] = useState("");

  // edit Document State

  const [editId, setEditId] = useState("");
  const [editCaseId, setEditCaseId] = useState("");
  const [editDate, setEditDate] = useState("");
  const [editfileName, setEditFileName] = useState("");
  const [editDocTemplateID, setEditDocTemplateID] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [wordDescriptionData, setWordDescriptionData] = useState("");

  // View Document State
  const [filterUser, setFilterUser] = useState("");
  const [delId, setDelId] = useState("");
  const [active, setActive] = useState(false);

  // Document Detail state

  const [CaseIDs, setCaseID] = useState("");
  const [Dates, setDates] = useState("");
  const [FileName, setFileName] = useState("");
  const [FileNos, setFileNos] = useState("");
  const [dFileUpload, setDFileUpload] = useState("");
  const [DocTemplateID, setDocTemplateID] = useState("");
  const [Descriptions, setDescriptions] = useState("");
  const [CaseName, setCaseName] = useState("");
  const [ClientName, setClientName] = useState("");
  const [ClientlastName, setClientlastName] = useState("");
  const [CounselName, setCounselName] = useState("");
  const [CoCounselName, setCoCounselName] = useState("");
  const [editTempId, setEditTempId] = useState();
  const formData = new FormData();
  const CaseID = new FormData();
  const ViewDocTemplate = new FormData();
  const editDocument = new FormData();

  const [open, setOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const handleExportButtonClick = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const handleToggleColumn = (columnName) => {
    const currentIndex = selectedColumns.indexOf(columnName);
    const newSelectedColumns = [...selectedColumns];

    if (currentIndex === -1) {
      newSelectedColumns.push(columnName);
    } else {
      newSelectedColumns.splice(currentIndex, 1);
    }

    setSelectedColumns(newSelectedColumns);
  };

  /** Add Document */

  const getDocumentView = async () => {
    const [view, data, DocTemplateData, cdata] = await Promise.all([
      withUidApi("Document/ViewDocument"),
      permission(9),
      getClient("Document/DocumentTemplatedList"),
      getClient("Client/ViewClient"),
      getFileNo(),
      setLoading(true),
    ]);

    if (view === null) {
      setLoading(false);
    } else {
      if (view === "token") {
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        setLoading(false);
        setData(view);
        setDocumetData(view);
      }
    }

    setTemplate(DocTemplateData);

    const clientData = cdata && cdata.filter((item) => item.Status === "1")

    const Mapped =
      clientData &&
      clientData.map((item) => ({
        value: item.Id,
        label: `${item.FirstName} ${item.LastName}`,
      }));
    setFClientOption(Mapped);

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }
    
    setIsAdd(data && data.result[0].IsAdd);
    setIsEdit(data && data.result[0].IsEdit);
    setIsView(data && data.result[0].IsView);
    setIsStatus(data && data.result[0].IsStatus);
    setIsDelete(data && data.result[0].IsDelete);
  };

  const deleteDocument = async () => {
    const data = await deleteUser(delId, "Document/DeleteDocument");
    if (data === "token") {
      setTimeout(() => {
        closeDelete.click();
        navigate("/");
      }, 3000);
    }

    if (data === true) {
      closeDelete.click();
      filterActive ? filterApi() : getDocumentView();
    }
  };

  const deActive = async (row) => {
    const data = await activeStatus(row, "Document/DocumentStatus");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }

    if (data === true) {
      filterActive ? filterApi() : getDocumentView();
    }
  };

  const detailsDocument = async (id) => {
    const data = await userDetails(id, "Document/ViewDocumentById");
    if (data === "token") {
      setTimeout(() => {
        closeView.click();
        navigate("/");
      }, 3000);
    } else {
      setFileNos(data.result[0].FileNo);
      setDTitle(data.result[0].Title);
      setCaseID(data.result[0].CaseNo);
      setCaseName(data.result[0].CaseName);
      setClientName(data.result[0].ClientName);
      setClientlastName(data.result[0].ClientlastName);
      setCounselName(data.result[0].Counsel);
      setCoCounselName(data.result[0].CoCounsel);
      setFileName(data.result[0].FileName);
      setDocTemplateID(data.result[0].DocTemplate);
      setEditTempId(data.result[0].DocTemplateID);
      setDates(data.result[0].Date);
      setDescriptions(data.result[0].Description);
      setEditDescription(data.result[0].Description);
    }
  };

  const getById = async (id) => {
    const data = await userDetails(id, "Document/ViewDocumentById");
    if (data === "token") {
      setTimeout(() => {
        closeEdit.click();
        navigate("/");
      }, 3000);
    } else {
      setEditId(data.result[0].Id);
      setEditTitle(data && data.result[0].Title === null ? "" : data.result[0].Title);
      setEditCaseId(data.result[0].CaseID);
      setEditDate(data.result[0].Date);
      setEditFileName(data.result[0].FileName);
      setEditDocTemplateID(data.result[0].DocTemplateID);
      setEditDescription(data.result[0].Description);
    }
  };

  const getByIdWord = async (id) => {
    const data = await userDetails(id, "Document/ViewDocumentById");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } else {
      return data.result[0];
    }
  };
  
  const submitHandler = async (event) => {
    event.preventDefault();
    setSaveButton(false);
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    formData.append("CaseID", dCaseID);
    formData.append("FileUpload", dFileUpload);
    formData.append("Title", title);
    formData.append("Date", dDate);
    formData.append("DocTemplateID", dDocTemplateID);
    formData.append("Description", dDescription);
    try {
      const response = await fetch(`${baseUrl}Document/AddDocument`, {
        method: "POST",
        body: formData,
        headers: {
          contentType: "multipart/form-data",
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].message === "Success") {
        toast.success("Document added successfully");
        filterActive ? filterApi() : getDocumentView();
        closeButton.click();
        setDCaseID("");
        setTitle("");
        setDDate(currentDate);
        setDDescription("");
        setDDocTemplateID("");
        setDFileName("");
        setSaveButton(true);
      } else {
        toast.error(data.result.message);
        setSaveButton(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /** Edit Document */

  const submitEditHandler = async (event) => {
    event.preventDefault();
    editDocument.append("ID", editId);
    editDocument.append("AdminID", localStorage.getItem("id"));
    editDocument.append("UpdatedBy", localStorage.getItem("UserId"));
    editDocument.append("CaseID", editCaseId);
    editDocument.append("Date", editDate);
    editDocument.append("Title", editTitle);
    editDocument.append("DocTemplateID", editDocTemplateID);
    editDocument.append("Description", editDescription);
    try {
      const response = await fetch(`${baseUrl}Document/EditDocument`, {
        method: "POST",
        body: editDocument,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeEdit.click();
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].code === "200") {
        toast.success(" Updated successfully");
        filterActive ? filterApi() : getDocumentView();
        closeEdit.click();
      } else {
        toast.error(data.result[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /** Get File Name  */
  const [optionsCase, setOptionsCase] = useState([]);

  const getFileNo = async () => {
    CaseID.append("AdminID", localStorage.getItem("id"));
    CaseID.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: CaseID,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].message === "Success") {
        const Mapped = data.result.map((item) => ({
          value: item.CaseID,
          label: `${item.FileNo} / ${item.CaseNo} / ${item.CaseName} / ${item.CaseYear}`,

        }));
        setOptionsCase(Mapped);
        setOptionsEvent(Mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setDCaseID(null);
    } else {
      setDCaseID(selectedOption.value);
    }
  };

  const handleDoctemp = (e) => {
    setEditDocTemplateID(e.target.value);
    setDDocTemplateID(e.target.value);
    const getTempDetails = async () => {
      CaseID.append("AdminID", localStorage.getItem("id"));
      CaseID.append("ID", e.target.value);

      try {
        const response = await fetch(`${baseUrl}Master/ViewDocTemplateById`, {
          method: "POST",
          body: CaseID,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        });
        const data = await response.json();
        if (data && data.result.message == "Session Time Expire.") {
          toast.error("Token expired please login again");
          setTimeout(() => {
            navigate("/");
          }, 3000);
        }
        if (data && data.result[0].code == 200) {
          setEditDescription(data.result[0].Description);
          setDDescription(data.result[0].Description);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getTempDetails();
  };

  // ---------- FILTER API ---------

  const filterApi = async () => {
    setLoading(true);
    filterform.append("AdminID", localStorage.getItem("id"));
    filterform.append("UserID", localStorage.getItem("uId"));
    filterform.append("CaseID", fCaseId);
    filterform.append("ClientID", fClientID);
    filterform.append("From", fFromDate);
    filterform.append("To", fToDate);
    try {
      const response = await fetch(`${baseUrl}Document/ViewDocumentfilter`, {
        method: "POST",
        body: filterform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 2000);
      }

      if (
        data &&
        data.result.length &&
        data &&
        data.result[0].message === "Success"
      ) {
        setFilterActive(true);
        setLoading(false);
        setDocumetData(data.result);
      } else {
        setLoading(false);
        setDocumetData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterCancel = () => {
    setFCaseId("");
    setFClientID("");
    setFFromDate("");
    setFToDate("");
    getDocumentView();
    setFilterActive(false);
  };

  const handleFilterCase = (selectedOption) => {
    if (selectedOption === null) {
      setFCaseId("");
    } else {
      setFCaseId(selectedOption.value);
    }
  };

  const handleFilterClient = (selectedOption) => {
    if (selectedOption === null) {
      setFClientID("");
    } else {
      setFClientID(selectedOption.value);
    }
  };
  
  const handleDownload = (fileUrl, fileName) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.target = "_blank";
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadWordFile = async (id) => {

    try {

      const data = await getByIdWord(id);
                var htmlContent = data;
        const blob = new Blob(
          [
            `<!DOCTYPE html><html><head><meta charset="UTF-8"></head>
          <body>${htmlContent.Description}</body></html>`,
          ],
          { type: "application/msword;charset=utf-8" }
        );
        const url = window.URL.createObjectURL(blob);
      
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.download =`Document/${wordDescriptionData}.doc`; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data or processing the file:", error);
    }
  };

  // ------ TABLE --------

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      header: "Action",
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      Cell: (row) => {
        return (
          <>
            <div style={{ display: "flex", gap: "15px" }}>
              {isEdit !== "0" && (
                <span
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalLongEdit"
                  onClick={() => getById(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/edit.svg"
                    alt="Edit"
                    title="Edit"
                  />
                </span>
              )}

              <span
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongDetail"
                onClick={() => detailsDocument(row.row.original.Id)}
              >
                <img
                  src="assets/img/icons/eye.svg"
                  alt="Details"
                  title="Details"
                />
              </span>
              <span>
                <a
                  style={{
                    cursor: "pointer",
                    display: isStatus === "0" ? "none" : "block",
                  }}
                  onClick={() => deActive(row.row.original)}
                >
                  {row.row.original.Status === "1" ? (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Up.svg"
                      title="Status"
                    />
                  ) : (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Down.svg"
                      title="Status"
                    />
                  )}
                </a>
              </span>
              <span  style={{ cursor: "pointer" }} title="PDF">
                <img
                  onClick={() => handleGeneratePDF(row.row.original.Id)}
                  src="assets/img/icons/pdf.svg"
                  className="pdf"
                  title="PDF"
                />
              </span>
              <span  style={{ cursor: "pointer" }}  title="Word File">
                <img
                  onClick={() => downloadWordFile(row.row.original.Id)}
                  src="assets/img/icons/word.svg"
                  className="pdf"
                  title="Word File"
                />
              </span>
              {isDelete !== "0" && (
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style={{ cursor: "pointer" }}
                  onClick={() => setDelId(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/delete.svg"
                    alt="Delete"
                    title="Delete"
                  />
                </span>
              )}
            </div>
          </>
        );
      },
    }),
    columnHelper.accessor("Date", {
      header: "Date",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.Date === null || row.row.original.Date === "0000-00-00"
              ? "-"
              : new Date(row.row.original.Date).toLocaleDateString("en-GB")}
          </div>
        );
      },
    }),
    columnHelper.accessor("FileNo", {
      header: "File No",
    }),
    columnHelper.accessor("CaseNo", {
      header: "Case No",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CaseNo === null || row.row.original.CaseNo === ""
              ? "-"
              : row.row.original.CaseNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseName", {
      header: "Case Name",
      Cell: (row) => {
        return (
          <div style={{ minWidth: "350px" }} className="upperCase">
            <a  title="Case Details"
              href={`#/view-casedetails/${row.row.original.CaseID}`}
              className="visuald_case"
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              {row.row.original.CaseName}
            </a>
          </div>
        );
      },
    }),
    columnHelper.accessor("Counsel", {
      header: "Counsel",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Counsel === null ||
            row.row.original.Counsel === ""
              ? "-"
              : row.row.original.Counsel}
          </div>
        );
      },
    }),
    columnHelper.accessor("Cocounsel", {
      header: "Co-counsel",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Cocounsel === null ||
            row.row.original.Cocounsel === ""
              ? "-"
              : row.row.original.Cocounsel}
          </div>
        );
      },
    }),
    columnHelper.accessor("ClientName", {
      header: "Client",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.ClientName}
            <br />
            {row.row.original.MobileNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("FileName", {
      header: "File",
      Cell: (row) => {
        const fileUrl = `https://api.lawyerhunt.in/uploaded/documents/${row.row.original.FileUpload}`;
        const fileName = row.row.original.FileName;

        return (
          <div
            style={{
              color: "black",
              fontSize: "22px",
             cursor: "pointer",
              padding: "4px",
              borderRadius: "6px",
              fontWeight: "bold",
              width: "5rem",
              textAlign: "center",
            }}
            onClick={() => handleDownload(fileUrl, fileName)}
          >
       { row.row.original.FileUpload ? <a style={{ color: "black", textDecoration: "underline" }}>
              <FaFileAlt />
            </a> : " "}
          </div>
        );
      },
    }),

    columnHelper.accessor("UpdatedBy", {
      header: "Last-Modified",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.UpdatedBy === null ||
            row.row.original.UpdatedBy === ""
              ? "-"
              : row.row.original.UpdatedBy}<br/>
                            {formateDateTime(row.row.original.UpdatedDate)}
          </div>
        );
      },
    }),

    columnHelper.accessor("Status", {
      header: "Status",
      Cell: (row) => {
        return <div>{row.row.original.Status === "1" ? "ACTIVE" : "DEACTIVE"}</div>;
      },
    }),

    columnHelper.accessor("AddedBy", {
      header: "Added By",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.AddedBy === null ||
            row.row.original.AddedBy === ""
              ? "-"
              : row.row.original.AddedBy}<br/>
                   {formateDateTime(row.row.original.CreatedDate)}
          </div>
        );
      },
    }),
  ];

  // File Upload By Disha (25-11-23)
  const [base64Data, setBase64Data] = useState(null);
  const handleFileChange = (event) => {
    // Access the selected file from the input
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Set the selected file and its base64-encoded data
        setDFileUpload(reader.result);
        setBase64Data(reader.result);
      };
      reader.readAsDataURL(file);
    }
    setDFileUpload(file);
  };
  
  const handlePrint = (data) => {
    const pdfContent = `<div style="padding: 15mm">${data.Description}</div>`;
  
    const pdfOptions = {
      filename: `${data.FileNo}/Document.pdf`,
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
  
    html2pdf(pdfContent, pdfOptions);
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getDocumentView();
    }
  }, []);

  async function generateAndSavePDF(content) {
    const pdfDoc = await PDFDocument.create();
    const pageWidth = 600;
    const contentHeight = 1000;
    const fontSize = 12;
    const font = await pdfDoc.embedFont(StandardFonts.TimesRoman);
    let page = pdfDoc.addPage([pageWidth, contentHeight]);
    let y = contentHeight - 30; // Start below the margin

    const div = document.createElement("div");
    div.innerHTML = content;
    // console.log(content);
    // div.innerHTML = content;
    // console.log(div.innerHTML);
    const elements = Array.from(div.childNodes);

    for (const element of elements) {
      const tagName = element.tagName?.toLowerCase();
    
      if (tagName === "p" || tagName === "div" || /^h[1-6]$/.test(tagName)) {
        const text = element.textContent;
        let fontSize = 12;
    
        // Adjust font size for headings
        if (/^h[1-6]$/.test(tagName)) {
          fontSize = 16; // You can adjust the font size for headings as needed
        }
    
        // Calculate text width
        const textWidth = font.widthOfTextAtSize(content, fontSize);
    
        if (y - fontSize < 30) {
          // If the text will overflow the current page, add a new page
          page = pdfDoc.addPage([pageWidth, contentHeight]);
          y = contentHeight - 30; // Start below the margin on the new page
        }
    
        // Wrap text if it exceeds the page width
        const lines = wrapText(text, font, fontSize, pageWidth - 2 * 50);
    
        for (const line of lines) {
          page.drawText(line, {
            x: 50,
            y,
            size: fontSize,
            font,
            color: rgb(0, 0, 0),
          });
    
          y -= fontSize * 1.5; // Increase spacing between lines
        }
      }
    }

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const pdfUrl = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = pdfUrl;
    a.download = `${wordDescriptionData}/Document.pdf`;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(pdfUrl);
  }

  // Function to wrap text to fit within a given width
  const wrapText = (text, font, fontSize, maxWidth) => {
    const words = text.split(" ");
    const lines = [];
    let currentLine = "";

    for (const word of words) {
      const testLine = currentLine + (currentLine === "" ? "" : " ") + word;
      const lineWidth = font.widthOfTextAtSize(testLine, fontSize);

      if (lineWidth <= maxWidth) {
        currentLine = testLine;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }

    if (currentLine) {
      lines.push(currentLine);
    }

    return lines;
  };

  // ---------DataTable-----

  const excelData =
    DocumetData &&
    DocumetData.map((obj) => {
      const { Id, code, message, Status, AdminID, ...rest } = obj;
      return rest;
    });

  const handleExportData = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });


    const formattedDate = new Date().toISOString().slice(0, 10); // Get today's date
    const filename = `Document|LegalCRM__${formattedDate}.csv`;

    const csv = generateCsv(csvConfig)(excelData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const columnsToExport = [
      { id: "serialNo", header: "S No." },
      ...columns.filter((column) => selectedColumns.includes(column.id)),
    ];

    const tableHeaders = columnsToExport.map((c) => c.header);

    const formattedDate = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const filename = `Document|LegalCRM__${formattedDate}.pdf`;
    const addWatermarkToFirstPage = () => {
      const watermarkHeight = 20;

      doc.setFontSize(10);
      doc.text(
        10,
        watermarkHeight + -10,
        `Generated by : ${localStorage.getItem("name")} `
      );
      doc.text(172, watermarkHeight + -10, `Date: ${formattedDate}`);
      doc.setFontSize(16);
     // Add a heading to the table
     doc.text("Document Table", 84, 20);
    };

    const watermarkHeight = 20;

    addWatermarkToFirstPage();
    const tableData = rows.map((row, index) => {
      const formattedDateInPdf = row.original["Date"]
        ? format(new Date(row.original["Date"]), "dd/MM/yyyy")
        : "";

      return [
        { content: (index + 1).toString(), styles: { fontStyle: "bold" } }, // Serial number
        ...columnsToExport.slice(1).map((column) => {
          // Exclude the first column (serial number) when mapping
          if (column.id === "Date") {
            return formattedDateInPdf;
          }

          const value =
            column.id in row.original ? row.original[column.id] : "";
          return value !== null && value !== undefined ? value : "";
        }),
      ];
    });
    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY: watermarkHeight + 5,
    });

   
    // Save the PDF to a Blob
    const pdfBlob = doc.output("blob");

    // Create a blob URL
    const blobUrl = URL.createObjectURL(pdfBlob);

    // Open the URL in a new tab
    window.open(blobUrl, "_blank");

    // Optionally revoke the object URL after opening the new tab
    URL.revokeObjectURL(blobUrl);
    // doc.save(filename);
  };

  const table = useMaterialReactTable({
    columns,
    data: DocumetData,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    initialState: {
      columnVisibility: {
        Status: false,
        AddedBy: false,
        UpdatedBy: false,
        UpdatedDate: false,
        CreatedDate: false,
      },
    },
    enableStickyHeader: true,
    enableRowNumbers: true,
    state: {
      isLoading: loading,
    },
    muiCircularProgressProps: {
      color: "primary",
      thickness: 5,
      size: 55,
      style: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000,
      },
    },
    muiSkeletonProps: {
      animation: "pulse",
      height: 20,
    },
    muiPaginationProps: {
      rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
 disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export Excel
        </Button>

        <Button
        disabled={!table || table.getPrePaginationRowModel().rows.length === 0}
        onClick={handleExportButtonClick}
        startIcon={<FileDownloadIcon />}
      >
        Export PDF
      </Button>
      <Dialog open={open} onClose={handleClose1}>
        <DialogTitle>Select Columns To Export</DialogTitle>
        <DialogContent>
            {columns.map((column) =>
              column.header !== "Action" ? (
                <div key={column.id}>
                  <Checkbox
                    checked={selectedColumns.includes(column.id)}
                    onChange={() => handleToggleColumn(column.id)}
                  />
                  {column.header}
                </div>
              ) : (
                ""
              )
            )}
          </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1}>Cancel</Button>
          <Button onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}>Export</Button>
        </DialogActions>
      </Dialog>
      </Box>
    ),
  });

  return (
    <>
      <DocumentTitle title=" DOCUMENT | LEGAL CRM">
        {isLoading1 ? (
          <Preloader />
        ) : (
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  <div className="pageContent">
                    <div className="PageTitle">
                      <h3>Document</h3>

                      <a
                        style={{
                          display: isAdd === "0" ? "none" : "block",
                        }}
                        type="button"
                        className="btn btn-primary btn_client headbtn"
                        data-toggle="modal"
                        data-target="#exampleModalLong"
                      >
                        Add Document <img src="assets/img/icons/add.svg" />
                      </a>
                    </div>

                    <div
                      className="filter_type add_wrap"
                      style={{
                        display: isView === "0" ? "none" : "block",
                      }}
                    >
                      <div className="form_flex1 flex_wrap  caseMobile">
                        <div className="form-group1 zIndex1">
                          <label>File No.</label>

                          <Select
                            className="basic-single"
                            placeholder="Please Select "
                            classNamePrefix="select"
                            onChange={handleFilterCase}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={optionsEvent}
                          />
                        </div>
                        <div className="form-group1 zIndex2">
                          <label>Client Name</label>

                          <Select
                            className="basic-single"
                            placeholder="Please Select "
                            classNamePrefix="select"
                            onChange={handleFilterClient}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={fClientOption}
                          />
                        </div>
                        <div className="form-group1">
                          <label>From Date</label>
                          <input
                            // max={currentDate}
                            autoComplete="off"
                            className="name"
                            type="date"
                            value={fFromDate}
                            onChange={(e) => setFFromDate(e.target.value)}
                          />
                        </div>
                        <div className="form-group1">
                          <label>To Date</label>
                          <input
                            autoComplete="off"
                            className="name"
                            type="date"
                            value={fToDate}
                            onChange={(e) => setFToDate(e.target.value)}
                          />
                        </div>
                        <div className="form-group1 caseMobile btnGroup">
                          <div className="btn_main casebtn">
                            <button
                              type="submit"
                              className="btn btn_client"
                              onClick={filterApi}
                            >
                              Search
                            </button>
                            <button
                              type="button"
                              className="btn btn_client"
                              onClick={filterCancel}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {DocumetData &&
                    DocumetData.length > 0 &&
                    DocumetData[0].message === "Data not found" ? (
                      <h3 className="visuald">
                        There are no records to display
                      </h3>
                    ) : (
                      <>
                        <div
                          style={{
                            display: isView === "0" ? "none" : "block",
                          }}
                          className="searDesign"
                        >
                          <MaterialReactTable table={table} />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <Footer/>
            </div>

            <div
              className="modal fade sc_modal"
              id="exampleModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close d_document"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <h4>Delete Row</h4>
                    <p>Are you sure you want to delete document ?</p>
                    <ul>
                      <li>
                        <a
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </a>
                      </li>
                      <li>
                        <a
                          className="btn btn-danger"
                          onClick={() => deleteDocument(delId)}
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Add User Form */}
            <div
              className="modal fade"
              id="exampleModalLong"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Add Document</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">
                              File No. <text className="m_star">*</text>
                            </label>
                            <Select
                              required
                              className="basic-single"
                              placeholder="Please Select "
                              classNamePrefix="select"
                              onChange={handleSelectChange}
                              isClearable={true}
                              isSearchable={true} 
                              name="color"
                              options={optionsCase}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              Date<text className="m_star">*</text>
                            </label>
                            <input
                              type="date"
                              placeholder=""
                              name="uname"
                              value={dDate}
                              onChange={(e) => setDDate(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                        <div className="form-group">
                      <label htmlFor="username">Title</label>
                      <input
                        className="name"
                        type="text"
                        placeholder="Enter Title"
                        name="uname"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                          <div className="form-group">
                            <label htmlFor="username">File Upload (JPEG,JPG,PDF,PNG)</label>
                            <input
                              className="name"
                              type="file"
                              placeholder="Enter File"
                              name="uname"
                              // value={dFileUpload}
                              onChange={handleFileChange}
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                         <div className="form-group">
                            <label htmlFor="username">Use Template</label>
                            <a
                              className="visuald"
                              href="/#/view-documentTemplate"
                            >
                              +Add Template
                            </a>
                            <select
                              name="cars"
                              id="cars"
                              onChange={handleDoctemp}
                            >
                              <option>
                                --Please Select only when you want to change
                                template--
                              </option>
                              {Template && Template.length > 0 ? (
                                Template.map((val, index) => (
                                  <option
                                    key={index}
                                    value={val.Id}
                                    selected={val.Id === editDocTemplateID}
                                  >
                                    {val.DocTemplate}
                                  </option>
                                ))
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <label htmlFor="username">Details</label>
                        </div>
                       
                        <ReactQuill
                          value={dDescription == null ? "-" : dDescription}
                          theme="snow"
                          modules={modules}
                          formats={formats}
                          placeholder="write your content ...."
                          onChange={handleProcedureContentChange}
                          style={{ height: "290px" }}
                        ></ReactQuill>
                         <br/><br/>
                      {
                        saveButton ? (
                          <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_save btn_cancle"
                          >
                            Cancel
                          </button>
                        </div>
                        ) : ""
                      }
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Edit Document Form */}

            <div
              className="modal fade"
              id="exampleModalLongEdit"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Edit Document</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close e_popup btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitEditHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">
                              File No.<text className="m_star">*</text>
                            </label>

                            <select
                              name="uname"
                              id="cars"
                              onChange={(e) => setEditCaseId(e.target.value)}
                              required
                            >
                              <option>--Please Select--</option>
                              {optionsCase &&
                                optionsCase.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.value}
                                      selected={val.value === editCaseId}
                                    >
                                      {val.label} 
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              Date<text className="m_star">*</text>
                            </label>
                            <input
                              type="date"
                              name="uname"
                              value={editDate}
                              onChange={(e) => setEditDate(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                        <div className="form-group">
                      <label htmlFor="username">Title</label>
                      <input
                        className="name"
                        type="text"
                        placeholder="Enter Title"
                        name="uname"
                        value={editTitle}
                        onChange={(e) => setEditTitle(e.target.value)}
                      />
                    </div>
                          <div className="form-group">
                            <label htmlFor="username">Use Template </label>
                            <select
                              name="cars"
                              id="cars"
                              onChange={handleDoctemp}
                            >
                              <option>
                                --Please Select only when you want to change
                                template--
                              </option>
                              {Template && Template.length > 0 ? (
                                Template.map((val, index) => (
                                  <option
                                    key={index}
                                    value={val.Id}
                                    selected={val.Id == editDocTemplateID}
                                  >
                                    {val.DocTemplate}
                                  </option>
                                ))
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <label htmlFor="username">Details</label>
                        </div>
                        <br />
                        <ReactQuill
                          value={
                            editDescription == null ? "-" : editDescription
                          }
                          theme="snow"
                          modules={modules}
                          formats={formats}
                          placeholder="write your content ...."
                          onChange={handleEdit}
                          style={{ height: "320px" }}
                        ></ReactQuill>
                        <br />
                        <br />

                        <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_save btn_cancle"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  Document Form */}
            <div
              className="modal fade"
              id="exampleModalLongDetail"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Document Details</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close ab btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Case &nbsp;:&nbsp;{" "}
                            {CaseName == null ? "-" : CaseName}{" "}
                          </h4>
                        </div>

                        <div className="form-group">
                          <h4>
                            File No. &nbsp;: &nbsp;{" "}
                            {FileNos == null ? "-" : FileNos}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                      <div className="form-group">
                          <h4>
                            Client Name &nbsp;: &nbsp;{" "}
                            {ClientName == null || ClientName === "" ? "-" : ClientName}{" "}
                          </h4>
                        </div>
                      <div className="form-group">
                          <h4>
                          Client Last Name &nbsp;: &nbsp;{" "}
                            {ClientlastName === null || ClientlastName === "" ? "-" : ClientlastName}{" "}
                          </h4>
                        </div>
                       
                      </div>
                    
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Date &nbsp;: &nbsp;{" "}
                            {Dates === null || Dates === "0000-00-00" ? "-" : formatDate1(Dates)}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Title &nbsp;: &nbsp;{" "}
                            {dTitle === null ? "-" : dTitle}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Template &nbsp;: &nbsp;{" "}
                            {DocTemplateID == null ? "-" : DocTemplateID}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <h4>Details</h4>
                      </div>
                      <br />
                      <ReactQuill
                        value={editDescription == null ? "-" : editDescription}
                        theme="snow"
                        className="editorReact"
                        modules={modules}
                        formats={formats}
                        readOnly
                        style={{ height: "320px" }}
                      ></ReactQuill>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Toster */}
            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        )}
      </DocumentTitle>
    </>
  );
};
export default Document;
