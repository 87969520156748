import { useParams } from "react-router-dom";
import Swal from 'sweetalert';
import React, { useState, useMemo, useRef } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";
import {
  deleteUser,
  formatDate1,
  activeStatus,
  getcourtView,
  permission,
  getClient,
  formateDateTime,
  getBranchCity,
} from "../services/api.service";
import Preloader from "./Preloader";
import Select from "react-select";
import DocumentTitle from "react-document-title";
import Footer from "./Footer";

const ClientDetails = () => {

  //------------ CASE VARIABLE ----------

  const [saveButton, setSaveButton] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [caseData, setCaseData] = useState([]);
  const clientName = caseData.FirstName;
  const { id } = useParams();
  const ViewCDData = new FormData();
  const [loading, setLoading] = useState(true);

  const handleKeyPress = (e) => {
    const charCode = e.which || e.keyCode;
    if (
      charCode < 48 ||
      charCode > 57 ||
      (charCode !== 8 && e.target.value.length >= 10)
    ) {
      e.preventDefault();
    }
  };

  //------------ CLOSE POPUP VARIABLE ----------

  const duplicateCaseForm = new FormData();
  const [viewUserCoCounselData, setViewUserCoCounselData] = useState([]);
  const closeButton = document.querySelector(".btn_popup");
  const closeButtonC = document.querySelector(".btn_popup");
  const closeEdit = document.querySelector(".e_popup");
  const closeDelete = document.querySelector(".d_case");
  const closeView = document.querySelector(".ab");
  const closeclientEdit = document.querySelector(".closeclientEdit");

  //------------ CASE VARIABLE ----------

  const EditformData = new FormData();
  const [referredByCounselData, setReferredByCounselData] = useState([]);
  const [referredByClientData, setReferredByClientData] = useState([]);
  const [UserList, setUserList] = useState([]);
  const [branchCity, setBranchCity] = useState("");
  const [editBranchCity, setEditBranchCity] = useState("");
  const [branchCityData, setBranchCityData] = useState([]);
  const currentDate = new Date().toISOString().split("T")[0];
  const [coCounselData, setCoCounselData] = useState([]);
  const ViewData = new FormData();
  const [options, setOptions] = useState([]);
  const [fFilingDate, setFFilingDate] = useState("");
  const [isFieldRequired, setIsFieldRequired] = useState(false);
  const filterform = new FormData();
  const editCaseDelete = new FormData();
  const detailsForm = new FormData();
  const Case = new FormData();
  const [EditReferredByClientID, setEditReferredByClientID] = useState("");
  const [EditReferredByCounselID, setEditReferredByCounselID] = useState("");
  const [Edittext, setEditText] = useState("");
  const [EditCountryID, setEditCountryID] = useState("");
  const [EditselectedOption, setEditSelectedOption] = useState("");
  const [editClientId, setEditClientId] = useState("");
  const [EditFirstName, setEditFirstName] = useState("");
  const [EditLastName, setEditLastName] = useState("");
  const [EditCompanyName, setEditCompanyName] = useState("");
  const [EditDesignation, setEditDesignation] = useState("");
  const [EditHouseNo, setEditHouseNo] = useState("");
  const [EditMobileNo, setEditMobileNo] = useState("");
  const [EditPhone, setEditPhone] = useState("");
  const [EditEmail, setEditEmail] = useState("");
  const [EditAddress, setEditAddress] = useState("");
  const [EditPinCode, setEditPinCode] = useState("");
  const [EditSource, setEditSource] = useState("");
  const [EditIsLead, setEditIsLead] = useState("");
  const baseUrl = useContext(url);
  const [excel, setExcel] = useState([]);
  const navigate = useNavigate();
  const [dCaseName, setDCaseName] = useState("");
  const [dCaseType, setDCaseType] = useState("");
  const [dCaseYear, setDCaseYear] = useState("");
  const [dcity_title, setDcity_title] = useState("");
  const [dClientCompany, setDClientCompany] = useState("");
  const [dClientLastName, setDClientLastName] = useState("");
  const [dClientName, setDClientName] = useState("");
  const [dCourtName, setDCourtName] = useState("");
  const [dAddedBy, setDAddedBy] = useState("");
  const [dDate, setDDate] = useState("");
  const [dCityCourtName, setDCityCourtName] = useState("");
  const [dIsOpen, setDIsOpen] = useState("");
  const [ddiaryNo, setDDiaryNo] = useState("");
  const [ddiaryYear, setDDiaryYear] = useState("");
  const [dcaseNo, setDCaseNo] = useState("");
  const [villageID, setVillageID] = useState("");
  const [village, setVillage] = useState([]);
  const [detailData, setDetailData] = useState([]);
  const [dsummary, setDSummary] = useState("");
  const [dfixedFees, setDFixedFees] = useState("");
  const [dexpenses, setDExpenses] = useState("");
  const [dappearanceFee, setDAppearanceFee] = useState("");
  const [dnonAppearanceFee, setDNonAppearanceFee] = useState("");
  const [ReferredByClientName, setReferredByClientName] = useState("");
  const [ReferredByCounselName, setReferredByCounselName] = useState("");
  const [city_title, setcity_title] = useState("");
  const [district_title, setdistrict_title] = useState("");
  const [state_title, setstate_title] = useState("");
  const [country_title, setcountry_title] = useState("");
  const [daor, setDAor] = useState("");
  const [dretaineed, setDRetaineed] = useState("");
  const [dpatitioners, setDPatitioners] = useState("");
  const [drespondants, setDRespondants] = useState("");
  const [dintervenors, setDIntervenors] = useState("");
  const [ddistrict_title, setDdistrict_title] = useState("");
  const [dFileNo, setDFileNo] = useState("");
  const [dstate_title, setDstate_title] = useState("");
  const [isAdd, setIsAdd] = useState("0");
  const [isEdit, setIsEdit] = useState("0");
  const [isView, setIsView] = useState("0");
  const [isDelete, setIsDelete] = useState("0");
  const [isStatus, setIsStatus] = useState("0");
  const [popup, setPopup] = useState(false);
  const [compantPrecentage, setCompantPrecentage] = useState("");
  const [viewUserData, setViewUserData] = useState([]);
  const [cocounselRow, setCocounselRow] = useState([
    { coCounselName: "", coCounselShare: "" },
  ]);
  const [caseLeadData, setCaseLeadData] = useState([]);
  const [filingDate, setFilingdate] = useState("");
  const [fileNo, setFileNo] = useState("");
  const [title1, setTitle1] = useState("");
  const [title2, setTitle2] = useState("");
  const [year, setYear] = useState([]);
  const [caseType, setCaseType] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [stateId, setStateId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [cityId, setCityId] = useState("");
  const [courtData, setCourtData] = useState([]);
  const [court, setCourt] = useState("");
  const [city, setCity] = useState([]);
  const [type, setType] = useState("");
  const [diaryNo, setDiaryNo] = useState("");
  const [diaryYear, setDiaryYear] = useState("");
  const [caseNo, setCaseNo] = useState("");
  const [caseYear, setCaseYear] = useState("");
  const [client, setClient] = useState("");
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [fixedFees, setFixedFees] = useState("");
  const [expenses, setExpenses] = useState("");
  const [appearanceFee, setAppearanceFee] = useState("");
  const [nonAppearanceFee, setNonAppearanceFee] = useState("");
  const [counsel, setCounsel] = useState("");
  const [coCounsel, setCoCounsel] = useState("");
  const [counselShare, setCounselShare] = useState("0");
  const [coCounselShare, setCoCounselShare] = useState("");
  const [aor, setAor] = useState("");
  const [retaineed, setRetaineed] = useState("");
  const [patitioners, setPatitioners] = useState("");
  const [respondants, setRespondants] = useState("");
  const [intervenors, setIntervenors] = useState("");
  const [filterUser, setFilterUser] = useState("");
  const [delId, setDelId] = useState("");
  const [active, setActive] = useState(false);
  const [totalCoCounsel, setTotalCoCounsel] = useState("0");
  const [data, setData] = useState([]);

  //------------ EDIT CASE VARIABLE ----------

  const [editId, setEditId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [editfilingDate, setEditFilingdate] = useState("");

  const [inputWidth, setInputWidth] = useState(6);
  const [showClosingDate, setShowClosingDate] = useState(false);
  const [closingDate, setClosingDate] = useState("");
  const [isOpen, setIsOpen] = useState("1");
  const [editnewOld, setEditNewOld] = useState("");
  const [editdiaryNo, setEditDiaryNo] = useState("");
  const [editdiaryYear, setEditDiaryYear] = useState("");
  const [editcaseNo, setEditCaseNo] = useState("");
  const [editcaseYear, setEditCaseYear] = useState("");
  const [editclient, setEditClient] = useState("");
  const [edittitle, setEditTitle] = useState("");
  const [editsummary, setEditSummary] = useState("");
  const [editfixedFees, setEditFixedFees] = useState("");
  const [editexpenses, setEditExpenses] = useState("");
  const [editappearanceFee, setEditAppearanceFee] = useState("");
  const [editnonAppearanceFee, setEditNonAppearanceFee] = useState("");
  const [editcounsel, setEditCounsel] = useState("");
  const [editcoCounsel, setEditCoCounsel] = useState("");
  const [editcounselShare, setEditCounselShare] = useState("");
  const [editcoCounselShare, setEditCoCounselShare] = useState("");
  const [editaor, setEditAor] = useState("");
  const [editretaineed, setEditRetaineed] = useState("");
  const [editpatitioners, setEditPatitioners] = useState("");
  const [editrespondants, setEditRespondants] = useState("");
  const [editintervenors, setEditIntervenors] = useState("");
  const formData = new FormData();
  const editCaseLead = new FormData();
  const districtForm = new FormData();
  const cityForm = new FormData();
  const villegeForm = new FormData();
  const configForm = new FormData();
  const form = new FormData();

  const [open, setOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const handleExportButtonClick = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const handleToggleColumn = (columnName) => {
    const currentIndex = selectedColumns.indexOf(columnName);
    const newSelectedColumns = [...selectedColumns];

    if (currentIndex === -1) {
      newSelectedColumns.push(columnName);
    } else {
      newSelectedColumns.splice(currentIndex, 1);
    }

    setSelectedColumns(newSelectedColumns);
  };

    //------------ VIEW CASE ---------

    const getUserClientApi = async () => {
      form.append("AdminID", localStorage.getItem("id"));
      try {
        const response = await fetch(`${baseUrl}Task/ViewUserList`, {
          method: "POST",
          body: form,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        });
        const data = await response.json();
  
        if (data && data.result[0].message == "Success") {
          setUserList(data && data.result);
          setReferredByCounselData(data && data.result);
        }
      } catch (error) {
        console.error(error);
      }
    };
    
  const configApi = async () => {
    configForm.append("AdminID", localStorage.getItem("id"));
    try {
      const response = await fetch(`${baseUrl}Config/ViewConfigById`, {
        method: "POST",
        body: configForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      setCompantPrecentage(parseInt(data && data.result[0].CompanyPer));
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const clientApi = async () => {
    configForm.append("AdminID", localStorage.getItem("id"));
    configForm.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}Admin/ViewUser`, {
        method: "POST",
        body: configForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      
      const clientData = data && data.result.advocateUser.filter((item) => item.Status === "1");
      const sortData = clientData && clientData.sort((a, b) =>{
        const nameA = a.Name.toUpperCase();
        const nameB = b.Name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
      
      setViewUserData(sortData);

    } catch (error) {
      console.error(error);
    }
  };



  const userById = new FormData();
  const updateUser = async (id) => {
    userById.append("AdminID", `${localStorage.getItem("id")}`);
    userById.append("ID", id);

    try {
      const response = await fetch(`${baseUrl}Client/ViewClientById`, {
        method: "POST",
        body: userById,

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data5 = await response.json();
      if (data5 && data5.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      setEditClientId(data5.result[0].Id);
      setEditFirstName(data5.result[0].FirstName);
      setEditLastName(data5.result[0].LastName);
      setEditCompanyName(data5.result[0].CompanyName);
      setEditDesignation(data5.result[0].Designation);
      setEditHouseNo(data5.result[0].HouseNo);
      setEditMobileNo(data5.result[0].MobileNo);
      setEditPhone(data5.result[0].Phone);
      setEditEmail(data5.result[0].Email);
      setEditIsLead(data5.result[0].IsLead);
      setEditSelectedOption(data5.result[0].IsLead);
      setCityData(
        data5.result[0].CityID === "0" ? "" : data5 && data5.result[0].CityID
      );
      setEditAddress(data5.result[0].Address);
      setEditPinCode(data5.result[0].PinCode);
      setVillageID(data5.result[0].VillageID);
      setEditSource(data5.result[0].Source);
      setEditReferredByClientID(
        data5.result[0].ReferredByClientID === "0"
          ? ""
          : data5 && data5.result[0].ReferredByClientID
      );
      setEditReferredByCounselID(
        data5.result[0].ReferredByCounselID === "0"
          ? ""
          : data5 && data5.result[0].ReferredByCounselID
      );
      setEditText(data5.result[0].Comments);
      setEditCountryID(
        data5.result[0].CountryID === "0"
          ? ""
          : data5 && data5.result[0].CountryID
      );
      setStateId(
        data5 && data5.result[0].StateID === "0"
          ? ""
          : data5 && data5.result[0].StateID
      );
      setDistrictId(
        data5.result[0].DistrictID === "0"
          ? ""
          : data5 && data5.result[0].DistrictID
      );
      setIsStatus(data5.result[0].Status);
      setReferredByClientName(
        data5.result[0].ReferredByClientName === "0"
          ? ""
          : data5 && data5.result[0].ReferredByClientName
      );
      setReferredByCounselName(
        data5.result[0].ReferredByCounselName === "0"
          ? ""
          : data5 && data5.result[0].ReferredByCounselName
      );
      setcity_title(data5.result[0].city_title);
      setdistrict_title(data5.result[0].district_title);
      setstate_title(data5.result[0].state_title);
      setcountry_title(data5.result[0].state_title);
    } catch (error) {
      console.error(error);
    }
  };

  const HandleEditClientSubmit = async (e) => {
    e.preventDefault();
    EditformData.append("ID", editClientId);
    EditformData.append("AdminID", localStorage.getItem("id"));
    EditformData.append("FirstName", EditFirstName.toUpperCase());
    EditformData.append("LastName", EditLastName.toUpperCase());
    EditformData.append("CompanyName", EditCompanyName.toUpperCase());
    EditformData.append("Designation", EditDesignation.toUpperCase());
    EditformData.append("HouseNo", EditHouseNo.toUpperCase());
    EditformData.append("MobileNo", EditMobileNo);
    EditformData.append("Phone", EditPhone);
    EditformData.append("Email", EditEmail.toUpperCase());
    EditformData.append("IsLead", EditselectedOption);
    EditformData.append("CityID", cityData);
    EditformData.append("Address", EditAddress.toUpperCase());
    EditformData.append("PinCode", EditPinCode);
    EditformData.append("Source", EditSource);
    EditformData.append("ReferredByClientID", EditReferredByClientID);
    EditformData.append("ReferredByCounselID", EditReferredByCounselID);

    if (EditCountryID && EditCountryID.length > 0) {
      EditformData.append("CountryID", EditCountryID);
    } else {
      EditformData.append("CountryID", "");
    }

    EditformData.append("StateID", stateId);
    if (villageID && villageID.length > 0) {
      EditformData.append("VillageID", villageID);
    } else {
      EditformData.append("VillageID", " ");
    }
    EditformData.append("DistrictID", districtId);
    EditformData.append("Comments", Edittext.toUpperCase());
    EditformData.append("UpdatedBy", localStorage.getItem("UserId"));

    try {
      const response = await fetch(`${baseUrl}Client/EditClient`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: EditformData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
      }

      if (data && data.result[0].message === "Success") {
        toast.success("Client Updated !");
        getCaseHistory();
        closeclientEdit.click();
      } else {
        toast.error(data.result[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleTextChange1 = (event) => {
    setEditText(event.target.value);
  };

  const handleInputChange1 = (e) => {
    const value = e.target.value;
    const isValidInput = /^\d{10}$/.test(value);

    setEditMobileNo(value);
    setIsValid(isValidInput);
  };

  const handleKeyPressalpha = (e) => {
    const charCode = e.which || e.keyCode;

    if (
      (charCode < 65 || charCode > 90) &&
      (charCode < 97 || charCode > 122) &&
      charCode !== 8 &&
      charCode !== 32 &&
      charCode !== 44
    ) {
      e.preventDefault();
    }
  };

  const handleInputChange3 = (e) => {
    const value = e.target.value;
    const isValidInput = /^\d{10}$/.test(value);

    setEditPhone(value);
    setIsValid(isValidInput);
  };

  const handleKeyPress1 = (e) => {
    const charCode = e.which || e.keyCode;
    if (
      charCode < 48 ||
      charCode > 57 ||
      (charCode !== 8 && charCode !== 47 && e.target.value.length >= 6)
    ) {
      e.preventDefault();
    }
  };
  
  const handleOptionChange2 = (event) => {
    setEditSelectedOption(event.target.value);
  };

  const handleOptionChange3 = (event) => {
    setEditSource(event.target.value);
  };
  
  const handleEditReferredByClientID = (value) => {
    if (value === "") {
      setEditReferredByClientID("");
      setReferredByCounselData(UserList);
    } else {
      setEditReferredByClientID(value);
      setEditReferredByCounselID("");
      setReferredByCounselData([]);
    }
  };

  const handleEditReferredByCounselID = (value) => {
    if (value === "") {
      setEditReferredByCounselID("");
      // setReferredByClientData(countries);
    } else {
      setEditReferredByCounselID(value);
      setEditReferredByClientID("");
      setReferredByClientData([]);
    }
  };

  const getCaseHistory = async () => {
    ViewCDData.append("CliendID", id);
    ViewCDData.append("AdminID", localStorage.getItem("id"));

    try {
      const response = await fetch(`${baseUrl}Client/ViewClientDetailsByID`, {
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: ViewCDData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }

      if (data && data.result[0].Client[0].message === "Success") {
        setCaseData(data && data.result[0].Client[0]);
      } 

      if (data && data.result[0].Case[0].message === "Success") {
        setLoading(false);
        setData(data && data.result[0].Case);
      } else {
        setData([]);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCaseView = async () => {
    const [yearData, coData, data, branchCityData] = await Promise.all([
      getYear(),
      getcourtView("Master/ViewCourt", court),
      permission(4),
      getBranchCity(),
      configApi(),
      getUserClientApi(),
      clientApi(),
      getCountries(),
      getCountriesUser(),
      getCaseHistory(),
    ]);

    setYear(yearData);
    setBranchCityData(branchCityData);
    const c_data = coData && coData.filter((item) => item.Status === "1")
    setCourtData(c_data);
    setIsView(data && data.result[0].IsView);
    setIsAdd(data && data.result[0].IsAdd);
    setIsEdit(data && data.result[0].IsEdit);
    setIsStatus(data && data.result[0].IsStatus);
    setIsDelete(data && data.result[0].IsDelete);
  };

  const getCaseType = async () => {
    const data = await getcourtView("Master/ViewCaseTypeByCourtId", court);
    setCaseType(data);
  };

  //----------- DELETE CASE ----------

  const deleteCaseLead = async () => {
    const data = await deleteUser(delId, "Cases/DeleteCase");
    if (data === "token") {
      setTimeout(() => {
        closeDelete.click();
        navigate("/");
      }, 1000);
    }

    if (data === true) {
      closeDelete.click();
      getCaseView();
    }
  };

  //------------ ACTIVE DEACTIVE CASE ----------

  const deActive = async (row) => {
    const data = await activeStatus(row, "Cases/CaseStatus");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 1000);
    }

    if (data === true) {
      getCaseView();
    }
  };

  //------------ SEARCH DETAILS ----------

  const details = async (id) => {
    detailsForm.append("AdminID", `${localStorage.getItem("id")}`);
    detailsForm.append("ID", id);
    try {
      const response = await fetch(`${baseUrl}Cases/ViewCaseByIdOLD`, {
        method: "POST",
        body: detailsForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message === "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }

      if (data && data.result.Case[0].message === "Success") {
        setDetailData(data && data.result.CaseDetail);
        setDCourtName(data && data.result.Case[0].CourtName);
        setDstate_title(data && data.result.Case[0].state_title);
        setDdistrict_title(data && data.result.Case[0].district_title);
        setDcity_title(data && data.result.Case[0].city_title);
        setDCaseType(data && data.result.Case[0].CaseType);
        setDFileNo(data && data.result.Case[0].FileNo);
        setDCaseYear(data && data.result.Case[0].CaseYear);
        setDClientName(data && data.result.Case[0].ClientName);
        setDClientLastName(data && data.result.Case[0].ClientLastName);
        setDClientCompany(data && data.result.Case[0].ClientCompany);
        setDCaseName(data && data.result.Case[0].CaseName);
        setDAddedBy(data && data.result.Case[0].AddedBy);
        setDDate(data && data.result.Case[0].Date);
        setDCityCourtName(data && data.result.Case[0].CityCourtName);
        setDIsOpen(data && data.result.Case[0].IsOpen);
        setDAor(data && data.result.Case[0].AOR);
        setDIntervenors(data && data.result.Case[0].Intervenors);
        setDRespondants(data && data.result.Case[0].Respondants);
        setDRetaineed(data && data.result.Case[0].RetainedFor);
        setDPatitioners(data && data.result.Case[0].Petitioners);
        setDNonAppearanceFee(data && data.result.Case[0].NonAppearanceFee);
        setDAppearanceFee(data && data.result.Case[0].AppearanceFee);
        setDExpenses(data && data.result.Case[0].Expenses);
        setDFixedFees(data && data.result.Case[0].FixedFees);
        setDSummary(data && data.result.Case[0].CaseSummary);
        setDCaseNo(data && data.result.Case[0].CaseNo);
        setDDiaryNo(data && data.result.Case[0].DiaryNo);
        setDDiaryYear(data && data.result.Case[0].DiaryYear);
      } else {
        toast.error(data && data.result.Case[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getById = async (id) => {
    detailsForm.append("AdminID", `${localStorage.getItem("id")}`);
    detailsForm.append("ID", id);
    try {
      const response = await fetch(`${baseUrl}Cases/ViewCaseByIdOLD`, {
        method: "POST",
        body: detailsForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message === "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }

      if (data && data.result.Case[0].message === "Success") {
        const updatedItems =
          data &&
          data.result.CaseDetail.filter((item) => item.CounselType === "2");

        const rowsData = [];

        if (updatedItems && updatedItems.length) {
          updatedItems &&
            updatedItems.forEach((item) => {
              rowsData.push({
                coCounselName: item.CounselID,
                coCounselShare: item.CounselPer,
                coCounselId: item.Id,
              });
            });

          setCocounselRow(rowsData);
        } else {
          setCocounselRow([{ coCounselName: "", coCounselShare: "" }]);
        }

        setEditId(data && data.result.Case[0].Id);
        setVillageID(data && data.result.Case[0].VillageID);
        setCourt(
          data && data.result.Case[0].CourtID === "0"
            ? ""
            : data.result.Case[0].CourtID
        );
        setStateId(
          data && data.result.Case[0].StateID === "0"
            ? ""
            : data.result.Case[0].StateID
        );
        setDistrictId(
          data.result.Case[0].DistrictID === "0"
            ? ""
            : data.result.Case[0].DistrictID
        );
        setCityId(
          data && data.result.Case[0].CityID === "0"
            ? ""
            : data.result.Case[0].CityID
        );
        setType(
          data && data.result.Case[0].CaseTypeID === "0"
            ? ""
            : data.result.Case[0].CaseTypeID
        );

        setEditNewOld(data && data.result.Case[0].CaseStage);
        setEditDiaryNo(data && data.result.Case[0].DiaryNo);
        setEditDiaryYear(data && data.result.Case[0].DiaryYear);
        setEditCaseNo(data && data.result.Case[0].CaseNo);
        setEditCaseYear(data && data.result.Case[0].CaseYear);
        setEditClient(data && data.result.Case[0].ClientID);
        const ab = data && data.result.Case[0].CaseName;
        const a = ab.split("VS");
        setTitle1(a[0]);
        setTitle2(a[1]);
        setEditSummary(data && data.result.Case[0].CaseSummary);
        setEditBranchCity(data && data.result.Case[0].BranchCity);
        setEditPatitioners(data && data.result.Case[0].Petitioners);
        setEditIntervenors(data && data.result.Case[0].Intervenors);
        setEditFixedFees(data && data.result.Case[0].FixedFees);
        setEditAppearanceFee(data && data.result.Case[0].AppearanceFee);
        setEditAor(data && data.result.Case[0].AOR);
        setEditRespondants(data && data.result.Case[0].Respondants);
        setEditRetaineed(data && data.result.Case[0].RetainedFor);
        setEditExpenses(data && data.result.Case[0].Expenses);
        setEditNonAppearanceFee(data && data.result.Case[0].NonAppearanceFee);
        setEditFilingdate(data && data.result.Case[0].FilingDate);
        setEditTitle(data && data.result.Case[0].CaseName);
        setIsOpen(data && data.result.Case[0].IsOpen);
        setClosingDate(data && data.result.Case[0].ClosingDate === null || data.result.Case[0].ClosingDate === "0000-00-00" ? "" : data.result.Case[0].ClosingDate);
        setEditCounsel(data && data.result.CaseDetail[0].CounselID);
        setEditCounselShare(data && data.result.CaseDetail[0].CounselPer);
        data && data.result.Case[0].CourtID === "3" ? setShowInputField(true) : setShowInputField(false);
      } else {
        toast.error(data && data.result.Case[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };
  const [showField, setShowField] = useState(false);
  const handleClick = () => {
    setShowField(true);
    if (showField === true) {
      setShowField(false);
    }
  };

  const handleClickback = () => {
    setShowField(false);
    clearForm();
  };

  const [showInputField, setShowInputField] = useState(false);
  const [showInputField1, setShowInputField1] = useState(false);

  const handleCourtChange = (selectedValue, name) => {
    setCourt(selectedValue);
    setType("");
    if (name === "Supreme Court") {
      setShowInputField(true);
    } else {
      setShowInputField(false);
      setAor("");
      setDiaryYear("");
      setDiaryNo("");
      setEditDiaryNo("");
      setEditDiaryYear("");
      setEditAor("");
    }
  };

  const handlecoCounsel = (index, value) => {
    const updatedFormData = [...cocounselRow];
    updatedFormData[index].coCounselName = value;
    setCocounselRow(updatedFormData);
  };

  const editHandlecoCounsel = (index, value) => {
    const updatedFormData = [...cocounselRow];
    updatedFormData[index].coCounselName = value;
    setCocounselRow(updatedFormData);
  };

  const handlecoCounsel1 = (event) => {
    const selectedValue = event.target.value;
    setEditCoCounsel(selectedValue);
    setEditCoCounselShare("");
    if (selectedValue) {
      setShowInputField1(true);
    } else {
      setShowInputField1(false);
    }
  };
  const handleStateChange = (e) => {
    setStateId(e.target.value);
    setDistrictId("");
    setCityId("");
  };

  const handleDistrictChange = (e) => {
    setDistrictId(e.target.value);
    setCityId("");
  };

  // ---------- FILTER API --------- MANISH PRAJAPAT DATE 06 - 10 - 2023 ---------

  const filterCancel = () => {
    clearForm();
    getCaseView();
  };

  // --------- ADD ROW ---------> MANISH PRAJAPAT 05 - 10 - 2023 <---------

  const addRow = () => {
    const total = 100 - compantPrecentage;
    if (cocounselRow.length < total) {
      setCocounselRow([
        ...cocounselRow,
        { coCounselName: "", coCounselShare: "" },
      ]);
    } else {
      alert(`Total Percentage is ${total} %`);
    }
  };

  const editAddRow = () => {
    const total = 100 - compantPrecentage;
    if (cocounselRow.length < total) {
      setCocounselRow([
        ...cocounselRow,
        { coCounselName: "", coCounselShare: "" },
      ]);
    } else {
      alert(`Total Percentage is ${total} %`);
    }
  };

  const removeRow = (indexToRemove) => {
    if (cocounselRow.length === 1) {
      return;
    }

    let updatedFormData = cocounselRow.filter(
      (_, index) => index !== indexToRemove
    );

    setTotalCoCounsel(
      totalCoCounsel - cocounselRow[indexToRemove].coCounselShare
    );
    setCocounselRow(updatedFormData);
  };

  const editRemoveRow = async (indexToRemove, Id) => {
    editCaseDelete.append("AdminID", localStorage.getItem("id"));
    editCaseDelete.append("ID", Id);
    editCaseDelete.append("CaseID", editId);
    editCaseDelete.append("DeletedBy", localStorage.getItem("UserId"));

    try {
      const response3 = await fetch(`${baseUrl}Cases/DeleteCaseDeatil`, {
        method: "POST",
        body: editCaseDelete,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
    } catch (error) {
      console.error(error);
    }

    if (cocounselRow.length === 1) {
      return;
    }

    let updatedFormData = cocounselRow.filter(
      (_, index) => index !== indexToRemove
    );

    setTotalCoCounsel(
      totalCoCounsel - cocounselRow[indexToRemove].coCounselShare
    );
    setCocounselRow(updatedFormData);
  };

  //------------ SUBMIT CASE ----------

  const [isValid1, setIsValid1] = useState(true);

  const handleCheck = () => {
    const num1 = parseInt(counselShare);
    const num2 = parseInt(coCounselShare);
    if (isNaN(num1) || num2 < 0 || num2 > 50) {
      setIsValid1(false);
    } else {
      const sum = num1 + num2;
      if (sum === 50) {
        formData.append("CoCounselShare", coCounselShare);
        formData.append("CounselShare", counselShare);
        setIsValid1(true);
      } else {
        toast.error("Sum is not 50.");
      }
    }
  };

  useEffect(() => {
    setIsOpen(isOpen);
    if (isOpen === "0") {
      setShowClosingDate(true);
      setInputWidth(12);
    } else {
      setShowClosingDate(false);
      setInputWidth(6);
      setClosingDate("");
    }
  }, [isOpen]);

  const handleCounselShareChange = (e) => {
    if (e.target.value === "") {
      setCounselShare("0");
    } else {
      const value = parseInt(e.target.value);
      const total = 100 - compantPrecentage;
      const total1 = total - totalCoCounsel;

      if (
        value !== null &&
        value !== undefined &&
        value !== "" &&
        value <= total &&
        value <= total1
      ) {
        setCounselShare(value);
      } else {
        alert(`Total Percentage is ${total} %`);
      }
    }
  };

  const handleCounselShareChange1 = (e) => {
    const value = parseInt(e.target.value);
    if (isNaN(value) || value < 0 || value > 50) {
      setIsValid1(false);
      // toast.error("Please enter a valid number between 0 and 50.");
      return;
    }
    setEditCounselShare(value);
    // setIsValid1(true)
    setEditCoCounselShare(50 - parseInt(value));
  };

  const [isValid, setIsValid] = useState(true);

  const handleCoCounselShareChange = (index, value) => {
    const updatedFormData = [...cocounselRow];
    if (updatedFormData[index].coCounselName === "") {
      toast.error("Please Select CoCounsel");
    } else {
      const total = 100 - compantPrecentage;
      const updatedFormData = [...cocounselRow];
      const oldValue = +updatedFormData[index].coCounselShare;
      const total1 = total - counselShare;

      if (value <= total && value <= total1) {
        updatedFormData[index].coCounselShare = value;

        let sum = updatedFormData.reduce(function (prev, current) {
          return prev + +current.coCounselShare;
        }, 0);

        if (sum <= total && sum <= total1) {
          setTotalCoCounsel(sum);
          setCocounselRow(updatedFormData);
        } else {
          updatedFormData[index].coCounselShare = oldValue;
          alert(`Total Percentage is ${total} %`);
        }
      } else {
        alert(`Total Percentage is ${total} %`);
      }
    }
  };

  const editHandleCoCounselShare = (index, value) => {
    const updatedFormData = [...cocounselRow];
    if (updatedFormData[index].coCounselName === "") {
      toast.error("Please Select CoCounsel");
    } else {
      const total = 100 - compantPrecentage;
      const updatedFormData = [...cocounselRow];
      const oldValue = +updatedFormData[index].coCounselShare;
      const total1 = total - editcounselShare;

      if (value <= total && value <= total1) {
        updatedFormData[index].coCounselShare = value;

        let sum = updatedFormData.reduce(function (prev, current) {
          return prev + +current.coCounselShare;
        }, 0);

        if (sum <= total && sum <= total1) {
          setCocounselRow(updatedFormData);
        } else {
          updatedFormData[index].coCounselShare = oldValue;
          alert(`Total Percentage is ${total} %`);
        }
      } else {
        alert(`Total Percentage is ${total} %`);
      }
    }
  };

  const handleCoCounselShareChange1 = (e) => {
    const value = parseInt(e.target.value);

    if (isNaN(value) || value < 0 || value > 50) {
      setIsValid(false);
      // toast.error("Please enter a valid number between 0 and 100.");
      return;
    }
    setEditCoCounselShare(value);
    // setIsValid(true)
    setEditCounselShare(50 - parseInt(value));
  };
  const handleCheck1 = () => {
    const num1 = parseInt(editcounselShare);
    const num2 = parseInt(editcoCounselShare);
    if (isNaN(num1) || isNaN(num2)) {
    } else {
      const sum = num1 + num2;
      if (sum === 50) {
        formData.append("CoCounselShare", coCounselShare);
        formData.append("CounselShare", counselShare);
      } else {
        toast.error("Sum is not 50.");
      }
    }
  };

  //------------ SUBMIT CASE ---------

  const dataSave = async() =>{
    setSaveButton(false);

    const filter =
      cocounselRow &&
      cocounselRow.filter(
        (item) => item.coCounselName !== "" && item.coCounselName !== "0"
      );

    const coCounselNameData =
      filter &&
      filter.map((coCounsel) =>
        coCounsel.coCounselName === "" ? "0" : coCounsel.coCounselName
      );
    const coCounselshareData =
      filter &&
      filter.map((coCounsel) =>
        coCounsel.coCounselShare === "" ? "0" : coCounsel.coCounselShare
      );

    const CounselName = [counsel, ...coCounselNameData];

    const CounselPer = [counselShare, ...coCounselshareData];

    const CounselType =
      CounselPer &&
      CounselPer.map((obj, index) => {
        return index === 0 ? 1 : 2;
      });

    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    formData.append("CourtID", court);
    formData.append("StateID", stateId);
    formData.append("CaseTypeID", type === "0" ? "" : type);
    formData.append("DistrictID", districtId);
    formData.append("CityID", cityId);
    formData.append("DiaryNo", diaryNo);
    formData.append("DiaryYear", diaryYear);
    formData.append("CaseNo", caseNo);
    formData.append("CaseYear", caseYear);
    formData.append("ClientID", id);
    formData.append("CaseName", title.toUpperCase());
    formData.append("CaseSummary", summary.toUpperCase());
    formData.append("Petitioners", patitioners.toUpperCase());
    formData.append("Intervenors", intervenors.toUpperCase());
    formData.append("CounselID", CounselName);
    formData.append("CounselPer", CounselPer);
    formData.append("CounselType", CounselType);
    formData.append("FixedFees", fixedFees);
    formData.append("AppearanceFee", appearanceFee);
    formData.append("AOR", aor.toUpperCase());
    formData.append("Respondants", respondants.toUpperCase());
    formData.append("RetainedFor", retaineed);
    formData.append("CoCounselID", coCounsel);
    formData.append("Expenses", expenses);
    formData.append("NonAppearanceFee", nonAppearanceFee);
    formData.append("Date", filingDate);
    formData.append("CityCourtID", 1);
    formData.append("FilingDate", filingDate);
    // formData.append("VillageID", villageID);
    formData.append("CompanyPer", compantPrecentage);
    formData.append("BranchCity", branchCity);

    try {
      const response = await fetch(`${baseUrl}Cases/AddCase`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 1000);
      }

      if (data.result.length && data.result[0].code == 200) {
        toast.success("Case added successfully");
        handleClose();
        closeButton.click();
        getCaseView();
        setBranchCity("");
        setCourt("");
        setStateId("");
        setType("");
        setDistrictId("");
        setCityId("");
        setDiaryNo("");
        setDiaryYear("");
        setCaseNo("");
        setCaseYear("");
        setClient("");
        setTitle1("");
        setTitle2("");
        setTitle("");
        setSummary("");
        setPatitioners("");
        setIntervenors("");
        setCounsel("");
        setCocounselRow([{ coCounselName: "", coCounselShare: "" }]);
        setCounselShare("");
        setFixedFees("");
        setAppearanceFee("");
        setAor("");
        setTotalCoCounsel("0");
        setRespondants("");
        setRetaineed("");
        setCoCounsel("");
        setCoCounselShare("");
        setExpenses("");
        setNonAppearanceFee("");
        setFilingdate("");
        setFileNo("");
        setSaveButton(true);
      } else {
        toast.error(data.result.message);
        setSaveButton(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const sweetAlert = () =>{
    Swal({
      title: "Are you sure ?",
      text: "We have identified a duplicate record based on the combination of Case year, Court name , Case type , Client name  and Case No. Are you certain you wish to proceed with adding this duplicate record ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        dataSave();
      } else {
        Swal("Case not added !");
      }
    });
  }

    const duplicateCase = async () => {
    duplicateCaseForm.append("CaseYear", caseYear);
    duplicateCaseForm.append("CaseNo", caseNo);
    duplicateCaseForm.append("CourtID", court);
    duplicateCaseForm.append("CaseTypeID", type);
    duplicateCaseForm.append("ClientID", id);
    try {
      const response = await fetch(
        `${baseUrl}Cases/IsDuplicateCase`,
        {
          method: "POST",
          body: duplicateCaseForm,
          headers: {
            "Authorization": `${localStorage.getItem("access_token")}`,
            'Accept': '*/*'
          }
        }
      );
       
      const data = await response.json();

      if (data && data.result[0].IsDuplicateCase === "1") {
        sweetAlert();
      } else {
        dataSave();
      }
    }
    catch (error) {
      console.error(error);
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    duplicateCase();
  };

  //------------ EDIT CASE ----------

  const editDataSave = async() =>{
    const filter =
    cocounselRow &&
    cocounselRow.filter(
      (item) => item.coCounselName !== "" && item.coCounselName !== "0"
    );

  const coCounselNameData =
    filter &&
    filter.map((coCounsel) =>
      coCounsel.coCounselName === "" ? "0" : coCounsel.coCounselName
    );
  const coCounselshareData =
    filter &&
    filter.map((coCounsel) =>
      coCounsel.coCounselShare === "" ? "0" : coCounsel.coCounselShare
    );

  const CounselName = [editcounsel, ...coCounselNameData];

  const CounselPer = [editcounselShare, ...coCounselshareData];

  const CounselType =
    CounselPer &&
    CounselPer.map((obj, index) => {
      return index === 0 ? 1 : 2;
    });

  editCaseLead.append("ID", editId);
  editCaseLead.append("AdminID", localStorage.getItem("id"));
  editCaseLead.append("UpdatedBy", localStorage.getItem("UserId"));
  editCaseLead.append("CourtID", court);
  editCaseLead.append("StateID", stateId);
  editCaseLead.append("CaseTypeID", type === "0" ? "" : type);
  editCaseLead.append("DistrictID", districtId);
  editCaseLead.append("CityID", cityId);
  editCaseLead.append("DiaryNo", editdiaryNo);
  editCaseLead.append("DiaryYear", editdiaryYear);
  editCaseLead.append("CaseNo", editcaseNo);
  editCaseLead.append("CaseYear", editcaseYear);
  editCaseLead.append("CounselID", CounselName);
  editCaseLead.append("CounselPer", CounselPer);
  editCaseLead.append("CounselType", CounselType);
  editCaseLead.append("ClientID", editclient);
  editCaseLead.append("CaseName", edittitle.toUpperCase());
  editCaseLead.append("CaseSummary", editsummary.toUpperCase());
  editCaseLead.append("Petitioners", editpatitioners.toUpperCase());
  editCaseLead.append("Intervenors", editintervenors.toUpperCase());
  editCaseLead.append("FixedFees", editfixedFees);
  editCaseLead.append("AppearanceFee", editappearanceFee);
  editCaseLead.append("AOR", editaor.toUpperCase());
  editCaseLead.append("Respondants", editrespondants.toUpperCase());
  editCaseLead.append("RetainedFor", editretaineed);
  editCaseLead.append("Expenses", editexpenses);
  editCaseLead.append("NonAppearanceFee", editnonAppearanceFee);
  editCaseLead.append("FilingDate", editfilingDate);
  // editCaseLead.append("VillageID", villageID);
  editCaseLead.append("IsOpen", isOpen);
  editCaseLead.append("ClosingDate", closingDate);
  editCaseLead.append("BranchCity", editBranchCity);

  try {
    const response = await fetch(`${baseUrl}Cases/EditCaseRecords`, {
      method: "POST",
      body: editCaseLead,
      headers: {
        Authorization: `${localStorage.getItem("access_token")}`,
        Accept: "*/*",
      },
    });
    const data = await response.json();

    if (data && data.result.message === "Session Time Expire.") {
      toast.error("Token expired please login again");
      setTimeout(() => {
        closeEdit.click();
        navigate("/");
      }, 1000);
    }

    if (data.result.length && data.result[0].code === "200") {
      toast.success(" Updated successfully");
      setTitle("");
      setTitle1("");
      setTitle2("");
      setCocounselRow([{ coCounselName: "", coCounselShare: "" }]);
      closeEdit.click();
      getCaseView();
    } else {
      toast.error(data.result.message);
    }
  } catch (error) {
    console.error(error);
  }
  }

  const editsweetAlert = () =>{
    Swal({
      title: "Are you sure ?",
      text: "We have identified a duplicate record based on the combination of Case year, Court name, Case type, Client name  and Case No. Are you certain you wish to proceed with adding this duplicate record ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        editDataSave();
      } else {
        Swal("Case not added !");
      }
    });
  }

  const editDuplicateCase = async () => {
    duplicateCaseForm.append("CaseYear", editcaseYear);
    duplicateCaseForm.append("CaseNo", editcaseNo);
    duplicateCaseForm.append("CourtID", court);
    duplicateCaseForm.append("CaseTypeID", type);
    duplicateCaseForm.append("ClientID", editclient);
    try {
      const response = await fetch(
        `${baseUrl}Cases/IsDuplicateCase`,
        {
          method: "POST",
          body: duplicateCaseForm,
          headers: {
            "Authorization": `${localStorage.getItem("access_token")}`,
            'Accept': '*/*'
          }
        }
      );
       
      const data = await response.json();

      if (data && data.result[0].IsDuplicateCase === "1") {
        editsweetAlert();
      } else {
        editDataSave();
      }
    }
    catch (error) {
      console.error(error);
    }
  };

  const submitEditHandler = async (event) => {
    event.preventDefault();
    editDuplicateCase();
  };

  //------------ CASE TABLE ----------

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      header: "Action",
      Cell: (row) => {
        return (
          <>
            <div style={{ display: "flex", gap: "15px" }}>
              {isEdit !== "0" && (
                <span
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalLongEdit"
                  onClick={() => getById(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/edit.svg"
                    alt="Edit"
                    title="Edit"
                  />
                </span>
              )}

              <span
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongDetail"
                onClick={() => details(row.row.original.Id)}
              >
                <img
                  src="assets/img/icons/eye.svg"
                  alt="Details"
                  title="Details"
                />
              </span>
              <span
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongDetail"
                onClick={() => details(row.row.original.Id)}
              >
                <img
                  src="assets/img/icons/send.svg"
                  alt="Email"
                  title="Send Notification"
                />
              </span>
              <span>
                <a
                  style={{
                    cursor: "pointer",
                    display: isStatus === "0" ? "none" : "block",
                  }}
                  onClick={() => deActive(row.row.original)}
                >
                  {row.row.original.Status === "1" ? (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Up.svg"
                      title="Status"
                    />
                  ) : (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Down.svg"
                      title="Status"
                    />
                  )}
                </a>
              </span>
              <span>
                <Link
                  target="_blank"
                  to={`/View_Case_PDF/${row.row.original.Id}`}
                  title="PDF"
                >
                  <img
                    src="assets/img/icons/pdf.svg"
                    className="pdf"
                    title="PDF"
                  />
                </Link>
              </span>
              {isDelete !== "0" && (
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style={{ cursor: "pointer" }}
                  onClick={() => setDelId(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/delete.svg"
                    alt="Delete"
                    title="Delete"
                  />
                </span>
              )}
            </div>
          </>
        );
      },
    }),

    columnHelper.accessor("FileNo", {
      minSize: "10px",
      header: "File No",
    }),

    columnHelper.accessor("CaseType", {
      header: "Case Type",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.CaseType === null ||
            row.row.original.CaseType === ""
              ? "-"
              : row.row.original.CaseType}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseNo", {
      minSize: "10px",
      header: "Case No.",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CaseNo === null || row.row.original.CaseNo === ""
              ? "-"
              : row.row.original.CaseNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseYear", {
      minSize: "10px",
      header: "Case Year",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CaseYear === null ||
            row.row.original.CaseYear === ""
              ? "-"
              : row.row.original.CaseYear}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseName", {
      header: "Case Name",
      Cell: (row) => {
        return (
          <div style={{ minWidth: "350px" }} className="upperCase">
            <a  title="Case Details"
              href={`#/view-casedetails/${row.row.original.Id}`}
              className="visuald_case"
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              {row.row.original.CaseName}
            </a>
          </div>
        );
      },
    }),

    columnHelper.accessor("CourtName", {
      header: "Court Name",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.CourtName === null ||
            row.row.original.CourtName === ""
              ? "-"
              : row.row.original.CourtName}
          </div>
        );
      },
    }),

    columnHelper.accessor("state_title", {
      header: "State",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.state_title === null ||
            row.row.original.state_title === ""
              ? "-"
              : row.row.original.state_title}
          </div>
        );
      },
    }),
    columnHelper.accessor("district_title", {
      header: "District",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.district_title === null ||
            row.row.original.district_title === ""
              ? "-"
              : row.row.original.district_title}
          </div>
        );
      },
    }),
    columnHelper.accessor("city_title", {
      header: "Tehsil",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.city_title === null ||
            row.row.original.city_title === ""
              ? "-"
              : row.row.original.city_title}
          </div>
        );
      },
    }),
    columnHelper.accessor("BranchCity", {
      header: "Branch City",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.BranchCity === null ||
            row.row.original.BranchCity === ""
              ? "-"
              : row.row.original.BranchCity}
          </div>
        );
      },
    }),

    columnHelper.accessor("DiaryNo", {
      minSize: "10px",
      header: "DiaryNo",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.DiaryNo === null ||
            row.row.original.DiaryNo === ""
              ? "-"
              : row.row.original.DiaryNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("DiaryYear", {
      minSize: "10px",
      header: "DiaryYear",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.DiaryYear === null ||
            row.row.original.DiaryYear === "" ||
            row.row.original.DiaryYear === "0000"
              ? "-"
              : row.row.original.DiaryYear}
          </div>
        );
      },
    }),

    columnHelper.accessor("AOR", {
      header: "AOR",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.AOR === null || row.row.original.AOR === ""
              ? "-"
              : row.row.original.AOR}
          </div>
        );
      },
    }),

    columnHelper.accessor("FilingDate", {
      minSize: "10px",
      header: "Filing Date",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.FilingDate === null ||
            row.row.original.FilingDate === "0000-00-00"
              ? "-"
              : formatDate1(row.row.original.FilingDate)}
          </div>
        );
      },
    }),

    columnHelper.accessor("Counsel", {
      header: "Counsel",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Counsel === null ||
            row.row.original.Counsel === ""
              ? "-"
              : row.row.original.Counsel}
          </div>
        );
      },
    }),
    columnHelper.accessor("Cocounsel", {
      header: "Co-counsel",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Cocounsel === null ||
            row.row.original.Cocounsel === ""
              ? "-"
              : row.row.original.Cocounsel}
          </div>
        );
      },
    }),

    columnHelper.accessor("ClientName", {
      header: "Client",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.ClientName} {row.row.original.ClientLastName}
<br/>
{ row.row.original.MobileNo  === "" || null ? row.row.original.Phone  :  row.row.original.MobileNo }
          </div>
        );
      },
    }),

    columnHelper.accessor("Petitioners", {
      header: "Petitioners",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Petitioners === null ||
            row.row.original.Petitioners === ""
              ? "-"
              : row.row.original.Petitioners}
          </div>
        );
      },
    }),

    columnHelper.accessor("Respondants", {
      header: "Respondants",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Respondants === null ||
            row.row.original.Respondants === ""
              ? "-"
              : row.row.original.Respondants}
          </div>
        );
      },
    }),

    columnHelper.accessor("Intervenors", {
      header: "Intervenors",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Intervenors === null ||
            row.row.original.Intervenors === ""
              ? "-"
              : row.row.original.Intervenors}
          </div>
        );
      },
    }),

    columnHelper.accessor("RetainedFor", {
      header: "RetainedFor",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.RetainedFor === null ||
            row.row.original.RetainedFor === ""
              ? "-"
              : row.row.original.RetainedFor}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseSummary", {
      header: "Case Summary",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.CaseSummary === null ||
            row.row.original.CaseSummary === ""
              ? "-"
              : row.row.original.CaseSummary}
          </div>
        );
      },
    }),

    columnHelper.accessor("IsOpen", {
      minSize: "10px",
      header: "Status",
      Cell: (row) => {
        return <div>{row.row.original.IsOpen}</div>;
      },
    }),

    columnHelper.accessor("ClosingDate", {
      minSize: "10px",
      header: "Closing Date",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.ClosingDate === null ||
            row.row.original.ClosingDate === "0000-00-00"
              ? "-"
              : formatDate1(row.row.original.ClosingDate)}
          </div>
        );
      },
    }),

    columnHelper.accessor("AddedBy", {
      header: "Created By",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.AddedBy === null ||
            row.row.original.AddedBy === ""
              ? "-"
              : row.row.original.AddedBy}
                     <br/>
              {formateDateTime(row.row.original.CreatedDate)}
          </div>
        );
      },
    }),
  ];

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  //-------- TITLE ------
  const handleTitle1 = (e) => {
    setTitle1(e.target.value);
    setPatitioners(e.target.value);
    setTitle(e.target.value + " " + "VS" + " " + title2);
  };

  const handleEditTitle1 = (e) => {
    setTitle1(e.target.value);
    setEditTitle(e.target.value + " " + "VS" + " " + title2);
  };

  const handleTitle2 = (e) => {
    setTitle2(e.target.value);
    setRespondants(e.target.value);
    setTitle(title1 + " " + "VS" + " " + e.target.value);
  };

  const handleEditTitle2 = (e) => {
    setTitle2(e.target.value);
    setEditTitle(title1 + " " + "VS" + " " + e.target.value);
  };

  const handlePatitioners = (e) => {
    setPatitioners(e.target.value);
  };

  const handleRespondants = (e) => {
    setRespondants(e.target.value);
  };

  //------------ VIEW CITY ----------

  const getState = async () => {
    try {
      const response = await fetch(`${baseUrl}Master/ViewState`, {
        method: "POST",
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      setState(data.result);
    } catch (error) {
      console.error(error);
    }
  };

  //------------ VIEW CITY ----------

  const getDistrict = async () => {
    districtForm.append("state_id", stateId);
    try {
      const response = await fetch(`${baseUrl}Master/ViewDistricByStateID`, {
        method: "POST",
        body: districtForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      setDistrict(data.result);
    } catch (error) {
      console.error(error);
    }
  };
  // ------------------Add Client----------------
  const formCData = new FormData();
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [Designation, setDesignation] = useState("");
  const [HouseNo, setHouseNo] = useState("");
  const [MobileNo, setMobileNo] = useState("");
  const [Phone, setPhone] = useState("");
  const [Email, setEmail] = useState("");
  const [IsLead, setIsLead] = useState("");
  const [CityID, setCityID] = useState([]);
  const [Address, setAddress] = useState("");
  const [PinCode, setPinCode] = useState("");
  const [Source, setSource] = useState("");
  const [ReferredByClientID, setReferredByClientID] = useState("");
  const [ReferredByCounselID, setReferredByCounselID] = useState("");
  const [CreatedBy, setCreatedBy] = useState("");
  const [Comments, setComments] = useState("");
  const [CountryID, setCountryID] = useState("1");
  const [StateID, setStateID] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [isValid2, setIsValid2] = useState(true);
  const handleInputChange = (e) => {
    const value = e.target.value;
    // Use regular expression to check if the input contains only numbers
    const isValidInput = /^\d{10}$/.test(value);

    setMobileNo(value);
    setIsValid(isValidInput);
  };

  const handleInputChange2 = (e) => {
    const value = e.target.value;
    // Use regular expression to check if the input contains only numbers
    const isValidInput = /^\d{10}$/.test(value);

    setPhone(value);
    setIsValid(isValidInput);
  };

  // ----------- VIEW User/Counsel---------
  const ViewUserData = new FormData();
  const [optionsUser, setOptionsUser] = useState([]);
  const [selectedOptionU, setSelectedOptionU] = useState(null);

  ViewUserData.append("AdminID", localStorage.getItem("id"));
  ViewUserData.append("UserID", localStorage.getItem("uId"));
  const [countriesUser, setCountriesUser] = useState([]);

  const getCountriesUser = async () => {
    try {
      const response = await fetch(`${baseUrl}Admin/ViewUser`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: ViewUserData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }

      setCountriesUser(data && data.result);

      const clientData = data && data.result.advocateUser.filter((item) => item.Status === "1");
      
      const sortData = clientData && clientData.sort((a, b) =>{
        const nameA = a.Name.toUpperCase();
        const nameB = b.Name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
      setOptionsUser(sortData);
      if (data.response_code === 400) {
        toast.error("Token expired please login again");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // ----------- VIEW Year---------
  const ViewYearData = new FormData();
  const [optionsYear, setOptionYear] = useState([]); // Initialize options state
  ViewYearData.append("AdminID", localStorage.getItem("id"));
  const getYear = async () => {
    try {
      const response = await fetch(`${baseUrl}Master/ViewYear`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: ViewYearData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }

      const y_data = data && data.result.filter((item) => item.Status === "1");

      const Mapped =
        y_data &&
        y_data.map((item) => ({
          value: item.Year,
          label: item.Year,
        }));
      setOptionYear(Mapped);
      if (data.response_code === 400) {
        toast.error("Token expired please login again");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // ----------- VIEW Client---------

  ViewData.append("AdminID", localStorage.getItem("id"));
  const getCountries = async () => {
    try {
      const response = await fetch(`${baseUrl}Client/ViewClient`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: ViewData,
      });

      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
      }

      if (data && data.result.length) {
        setOptions(data.result);
        const clientData =
        data && data.result.filter((item) => item.Status === "1");
        setReferredByClientData(clientData);
      } else {
        setOptions([]);
      }

      if (data.response_code === 400) {
        toast.error("Token expired please login again");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const HandleCSubmit = async (e) => {
    e.preventDefault();
    formCData.append("AdminID", localStorage.getItem("id"));
    formCData.append("FirstName", FirstName.toUpperCase());
    formCData.append("LastName", LastName.toUpperCase());
    formCData.append("CompanyName", CompanyName);
    formCData.append("Designation", Designation);
    formCData.append("HouseNo", HouseNo);
    if (MobileNo.length === 10) {
      formCData.append("MobileNo", MobileNo);
    } else {
      toast.error("Invalid Mobile No");
    }
    formCData.append("Phone", Phone);
    formCData.append("Email", Email);
    formCData.append("IsLead", selectedOption);
    formCData.append("CityID", cityId);
    formCData.append("Address", Address);
    formCData.append("PinCode", PinCode);
    formCData.append("Source", Source);
    formCData.append("ReferredByClientID", ReferredByClientID);
    formCData.append("ReferredByCounselID", ReferredByCounselID);
    formCData.append("CreatedBy", localStorage.getItem("UserId"));
    formCData.append("CountryID", CountryID);
    formCData.append("StateID", stateId);
    formCData.append("DistrictID", districtId);
    formCData.append("Comments", text);

    // Iterate through the fields and update empty or undefined fields
    for (let pair of formCData.entries()) {
      let fieldName = pair[0];
      let fieldValue = pair[1];

      if (fieldValue === "undefined" || fieldValue === "") {
        // Set the field value to three spaces
        formCData.set(fieldName, "");
      }
    }

    try {
      const response = await fetch(`${baseUrl}Client/AddClient`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: formCData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      if (data.result[0].code === "200") {
        handleClickback();
        clearForm();
        toast.success("Client Added !");
        getCountries();
        getClient();
      } else {
        toast.error(data.result[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleCounselChange = (selectedOption) => {
    if (selectedOption === "") {
      setCounsel("0");
      setCoCounselData([]);
    } else {
      setCounsel(selectedOption);
      const data =
      optionsUser && optionsUser.filter((item) => item.Id !== selectedOption);
    setCoCounselData(data);
    }
  };
  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setClient(null);
    } else {
      setClient(selectedOption.value);
    }
  };

  useEffect(() => {}, []);
  function clearForm() {
    var fields = document.getElementsByClassName("name");
    for (var i = 0; i < fields.length; i++) {
      fields[i].value = "";
    }
  }

  const [selectedOption, setSelectedOption] = useState([]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleOptionChange1 = (event) => {
    setSource(event.target.value);
  };

  const [text, setText] = useState("");

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  // dropdown

  const [State, setState1] = useState([]);
  const getState1 = async () => {
    try {
      const response = await fetch(`${baseUrl}Master/ViewState`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data2 = await response.json();
      if (data2 && data2.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      setState1(data2.result);
      setState(data2.result);
    } catch (error) {
      console.error(error);
    }
  };
  const district1 = new FormData();

  const [District, setDistrict1] = useState([]);
  const getDistrict1 = async () => {
    district1.append("state_id", StateID);
    try {
      const response = await fetch(`${baseUrl}Master/ViewDistricByStateID`, {
        method: "POST",
        body: district,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data3 = await response.json();
      if (data3 && data3.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
        window.location.reload();
      }
      setDistrict1(data3.result);
      setDistrict(data3.result);
    } catch (error) {
      console.error(error);
    }
  };

  const [City, setCity1] = useState([]);
  const getCity1 = async () => {
    cityForm.append("district_id", CityID);

    try {
      const response = await fetch(`${baseUrl}Master/ViewCityByDistrictid`, {
        method: "POST",
        body: cityForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data1 = await response.json();
      if (data1 && data1.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      setCity1(data1.result);
      setCity(data1.result);
    } catch (error) {
      console.error(error);
    }
  };
  const handleStateChange1 = (e) => {
    setStateID(e.target.value);
    getDistrict1();
  };

  const handleDistrictChange1 = (e) => {
    setCityID(e.target.value);
    getCity1();
  };
  const handleCityChange = (e) => {
    setCityData(e.target.value);
  };
  useEffect(() => {
    getState1();
    getCity1();
    getDistrict1();
  }, [StateID, CityID]);

  //------------ VIEW CITY ----------

  const getCity = async () => {
    cityForm.append("district_id", districtId);
    try {
      const response = await fetch(`${baseUrl}Master/ViewCityByDistrictid`, {
        method: "POST",
        body: cityForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      setCity(data.result);
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //------------ VIEW VILEGE ----------

  const getVillege = async () => {
    villegeForm.append("city_id", cityId);
    try {
      const response = await fetch(`${baseUrl}Master/ViewVillageByCityId`, {
        method: "POST",
        body: villegeForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      setVillage(data.result);
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() =>{
    if (editcounsel === "") {
      setEditCounsel("");
      setViewUserCoCounselData([]);
    } else {
      const data = viewUserData && viewUserData.filter((item) => item.Id !== editcounsel)
      setViewUserCoCounselData(data);
    }
  }, [editcounsel]);


  useEffect(() => {
    getDistrict();
    setCity(null);
  }, [stateId]);

  useEffect(() => {
    getCity();
    setVillage(null);
  }, [districtId]);

  useEffect(() => {
    getVillege();
  }, [cityId]);

  useEffect(() => {
    getCaseType();
  }, [court]);

  useEffect(() => {
    getState();
    getCity();
    getDistrict();
  }, [StateID, CityID]);
  //------------ Checking the share ----------
  useEffect(() => {
    if (counselShare !== "" && coCounselShare !== "") {
      handleCheck();
    }
  }, [counselShare, coCounselShare]);
  useEffect(() => {
    if (editcounselShare !== "" && editcoCounselShare !== "") {
      handleCheck1();
    }
  }, [editcounselShare, editcoCounselShare]);

  const handleFilingDate = (e) => {
    setFFilingDate(e.target.value);
    if (e.target.value !== "") {
      setIsFieldRequired(true);
    } else {
      setIsFieldRequired(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getCaseView();
      getState();
    }
  }, []);

  const excelData =
    data &&
    data.map((obj) => {
      const { Id, code, message, Status, AdminID, ...rest } = obj;
      return rest;
    });

  const handleExportData = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });
    const formattedDate = new Date().toISOString().slice(0, 10); // Get today's date
    const filename = `Case|LegalCRM__${formattedDate}.csv`;

    const csv = generateCsv(csvConfig)(excelData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const columnsToExport = [
      { id: "serialNo", header: "S No." },
      ...columns.filter((column) => selectedColumns.includes(column.id)),
    ];

    const tableHeaders = columnsToExport.map((c) => c.header);

    const formattedDate = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const filename = `Case|LegalCRM__${formattedDate}.pdf`;
    const addWatermarkToFirstPage = () => {
      const watermarkHeight = 20;

      // Calculate positioning to center the watermark on each page

      doc.setFontSize(10);
      doc.text(
        10,
        watermarkHeight + -10,
        `Generated by : ${localStorage.getItem("name")} `
      );
      doc.text(172, watermarkHeight + -10, `Date: ${formattedDate}`);
      doc.setFontSize(16);
      doc.text("Case Table", 84, 20);
    };
    const watermarkHeight = 20;

    addWatermarkToFirstPage();
    const tableData = rows.map((row, index) => {
      return [
        { content: (index + 1).toString(), styles: { fontStyle: "bold" } }, // Serial number
        ...columnsToExport.slice(1).map((column) => {
          // Exclude the first column (serial number) when mapping
          const value =
            column.id in row.original ? row.original[column.id] : "";
          return value !== null && value !== undefined ? value : "";
        }),
      ];
    });

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY: watermarkHeight + 5,
    });

    // Add a heading to the table

    // Save the PDF to a Blob
    const pdfBlob = doc.output("blob");

    // Create a blob URL
    const blobUrl = URL.createObjectURL(pdfBlob);

    // Open the URL in a new tab
    window.open(blobUrl, "_blank");

    // Optionally revoke the object URL after opening the new tab
    URL.revokeObjectURL(blobUrl);
    // doc.save(filename);
  };

  const table = useMaterialReactTable({
    columns,
    data: data && data,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    initialState: {
      columnVisibility: {
        DiaryNo: false,
        DiaryYear: false,
        AOR: false,
        Respondants: false,
        Petitioners: false,
        Intervenors: false,
        CaseSummary: false,
        RetainedFor: false,
        ClosingDate: false,
        IsOpen: false,
        AddedBy: false,
      },
    },
    state: {
      isLoading: loading,
    },
    muiCircularProgressProps: {
      color: "primary",
      thickness: 5,
      size: 55,
      style: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000,
      },
    },
    muiSkeletonProps: {
      animation: "pulse",
      height: 10,
    },

    enableStickyHeader: true,
    enableRowNumbers: true,
    muiPaginationProps: {
      rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
 disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export Excel
        </Button>

        <Button
        disabled={!table || table.getPrePaginationRowModel().rows.length === 0}
        onClick={handleExportButtonClick}
        startIcon={<FileDownloadIcon />}
      >
        Export PDF
      </Button>
      <Dialog open={open} onClose={handleClose1}>
        <DialogTitle>Select Columns To Export</DialogTitle>
        <DialogContent>
            {columns.map((column) =>
              column.header !== "Action" ? (
                <div key={column.id}>
                  <Checkbox
                    checked={selectedColumns.includes(column.id)}
                    onChange={() => handleToggleColumn(column.id)}
                  />
                  {column.header}
                </div>
              ) : (
                ""
              )
            )}
          </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1}>Cancel</Button>
          <Button onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}>Export</Button>
        </DialogActions>
      </Dialog>
      </Box>
    ),
  });

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <DocumentTitle title="DETAILS | LEGAL CRM">
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  <div className="pageContent">
                    <div className="PageTitle">
                      <h3>Client Details</h3>
                      <a
                          type="button"
                          className="btn btn-primary btn_client"
                          data-toggle="modal"
                          data-target="#exampleModalLongClientEdit"
                          onClick={() => updateUser(id)}
                        >
                          Client Info.
                        </a>
                    </div>
                    <br/>
                    <div className="caseDetail">
                      <div className="form-group">
                        <h4>First Name: </h4>
                        <p>
                          {" "}
                          {caseData.FirstName === "" ? "-" : caseData.FirstName}
                        </p>
                      </div>
                      <div className="form-group">
                        <h4>S/W/D Of : </h4>
                        <p>
                          {" "}
                          {caseData.LastName === "" ||
                          caseData.LastName === null
                            ? "-"
                            : caseData.LastName}
                        </p>
                      </div>

                      <div className="form-group">
                        <h4>Company Name : </h4>
                        <p>
                          {" "}
                          {caseData.CompanyName === "" ||
                          caseData.CompanyName === null
                            ? "-"
                            : caseData.CompanyName}
                        </p>
                      </div>

                      <div className="form-group">
                        <h4>Designation : </h4>
                        <p>
                          {" "}
                          {caseData.Designation === "" ||
                          caseData.Designation === null
                            ? "-"
                            : caseData.Designation}
                        </p>
                      </div>
                      <div className="form-group">
                        <h4>House No. :</h4>
                        <p>
                          {" "}
                          {caseData.HouseNo === "" || caseData.HouseNo === null
                            ? "-"
                            : caseData.HouseNo}
                        </p>
                      </div>
                      <div className="form-group">
                        <h4>Mobile No. : </h4>
                        <p>
                          {" "}
                          {caseData.MobileNo === "" ||
                          caseData.MobileNo === null
                            ? "-"
                            : caseData.MobileNo}
                        </p>
                      </div>

                      <div className="form-group">
                        <h4>Phone : </h4>
                        <p>
                          {" "}
                          {caseData.Phone === "" || caseData.Phone === null
                            ? "-"
                            : caseData.Phone}
                        </p>
                      </div>
                      <div className="form-group">
                        <h4>Email : </h4>
                        <p>
                          {" "}
                          {caseData.Email === "" || caseData.Email === null
                            ? "-"
                            : caseData.Email}
                        </p>
                      </div>

                      <div className="form-group">
                        <h4>Address : </h4>
                        <p>
                          {" "}
                          {caseData.Address === "" || caseData.Address === null
                            ? "-"
                            : caseData.Address}
                        </p>
                      </div>

                      <div className="form-group">
                        <h4>Pin Code : </h4>
                        <p>
                          {" "}
                          {caseData.PinCode === "" || caseData.PinCode === null
                            ? "-"
                            : caseData.PinCode}
                        </p>
                      </div>
                      <div className="form-group">
                        <h4>Source : </h4>
                        <p>
                          {" "}
                          {caseData.Source === "" || caseData.Source === null
                            ? "-"
                            : caseData.Source}
                        </p>
                      </div>

                      <div className="form-group">
                        <h4>Referred By Client : </h4>
                        <p>
                          {" "}
                          {caseData.ReferredByClientName === "" ||
                          caseData.ReferredByClientName === null
                            ? "-"
                            : caseData.ReferredByClientName}
                        </p>
                      </div>

                      <div className="form-group">
                        <h4>Referred By Counsel : </h4>
                        <p>
                          {" "}
                          {caseData.ReferredByCounselName === "" ||
                          caseData.ReferredByCounselName === null
                            ? "-"
                            : caseData.ReferredByCounselName}
                        </p>
                      </div>

                      <div className="form-group">
                        <h4>Country : </h4>
                        <p>
                          {" "}
                          {caseData.CountryName === "" ||
                          caseData.CountryName === null
                            ? "-"
                            : caseData.CountryName}
                        </p>
                      </div>

                      <div className="form-group">
                        <h4>State : </h4>
                        <p>
                          {" "}
                          {caseData.state_title === "" ||
                          caseData.state_title === null
                            ? "-"
                            : caseData.state_title}
                        </p>
                      </div>

                      <div className="form-group">
                        <h4>District : </h4>
                        <p>
                          {" "}
                          {caseData.district_title === "" ||
                          caseData.district_title === null
                            ? "-"
                            : caseData.district_title}
                        </p>
                      </div>

                      <div className="form-group">
                        <h4>Tehsil : </h4>
                        <p>
                          {" "}
                          {caseData.city_title === "" ||
                          caseData.city_title === null
                            ? "-"
                            : caseData.city_title}
                        </p>
                      </div>

                      <div className="form-group">
                        <h4>Village : </h4>
                        <p>
                          {" "}
                          {caseData.village_name === "" ||
                          caseData.village_name === null
                            ? "-"
                            : caseData.village_name}
                        </p>
                      </div>
                      <div className="form-group">
                        <h4>Is Lead : </h4>
                        <p>
                          {" "}
                          {caseData.IsLead === "" || caseData.IsLead === null
                            ? "-"
                            : caseData.IsLead}
                        </p>
                      </div>

                      <div className="form-group">
                        <h4>Status : </h4>
                        <p>
                          {caseData.Status == 1 ? "ACTIVE" : "DEACTIVE"}
                        </p>
                      </div>

                      <div className="form-group">
                        <h4>Comments : </h4>
                        <p>
                          {" "}
                          {caseData.Comments === "" ||
                          caseData.Comments === null
                            ? "-"
                            : caseData.Comments}
                        </p>
                      </div>
                    </div>
                    <br />
                    <hr />
                    <br />

                    <div className="PageTitle">
                      <h3>Case List</h3>

                      <a
                        style={{
                          display: isAdd === "0" ? "none" : "block",
                        }}
                        type="button"
                        className="btn btn-primary btn_client headbtn"
                        data-toggle="modal"
                        data-target="#exampleModalLong"
                      >
                        Add Case <img src="assets/img/icons/add.svg" />
                      </a>
                    </div>
                    {/* <div style={{ marginTop: "20px", marginBottom: "20px", display: "flex", justifyContent: 'center', alignItems: "center" }}>   {loading && <CircularProgressIcon />}</div> */}
                    {/* <div
                            style={{
                              display: isView == "0" ? "none" : "block",
                            }}
                            className="searDesign"
                          > */}
                    <MaterialReactTable table={table} />
                    {/* </div> */}

                    {/*------------ DELETE CASE -----------*/}

                    <div
                      className="modal fade sc_modal"
                      id="exampleModal"
                      tabIndex={-1}
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <button
                              type="button"
                              className="btn-close d_case"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            />
                          </div>
                          <div className="modal-body">
                          <h4>Delete Case</h4>
                          <p>Event, Note, Letter, Task, Invoice, Receipt and Appointment all related to this case will be deleted.</p>
                            <ul>
                              <li>
                                <a
                                  className="btn btn-secondary"
                                  data-bs-dismiss="modal"
                                >
                                  Cancel
                                </a>
                              </li>
                              <li>
                                <a
                                  className="btn btn-danger"
                                  onClick={() => deleteCaseLead(delId)}
                                >
                                  Delete
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*------------ ADD CASE FORM -----------*/}

                    <div
                      className="modal fade"
                      id="exampleModalLong"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="exampleModalLongTitle"
                      aria-hidden="true"
                      show={show}
                    >
                      <div
                        className="modal-dialog modal-xl"
                        role="document"
                        // style={{ maxWidth: "80%" }}
                      >
                        <div className="modal-content">
                          <div className="add_wrap">
                            <div className="main_heading">
                              <h2>Add Case</h2>
                            </div>
                            {showField && (
                              <div className="add_wrap">
                                <form
                                  role="form "
                                  className="clientf"
                                  onSubmit={HandleCSubmit}
                                >
                                  <div className="main_heading">
                                    <h2>Add New Client</h2>
                                  </div>

                                  <hr />
                                  <div className="form_flex caseMobile">
                                    <div className="form-group">
                                      <label for="uname">
                                        Full Name{" "}
                                        <text className="m_star">*</text>
                                      </label>
                                      <input
                                        autoComplete="off"
                                        className="name"
                                        type="text"
                                        placeholder="Enter Full Name"
                                        onChange={(e) =>
                                          setFirstName(e.target.value)
                                        }
                                        required
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label for="uname">
                                        Mobile No.{" "}
                                        <text className="m_star">*</text>
                                      </label>
                                      <input
                                        onKeyPress={handleKeyPress}
                                        maxLength={10}
                                        className="name"
                                        autoComplete="off"
                                        type="text"
                                        placeholder="Enter Mobile No"
                                        onChange={handleInputChange}
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="form_flex caseMobile">
                                    <div className="form-group">
                                      <label htmlFor="username">State </label>
                                      <select
                                        required
                                        className="name"
                                        name="cars"
                                        id="cars"
                                        onChange={(e) =>
                                          setStateId(e.target.value)
                                        }
                                      >
                                        <option>--Select State--</option>
                                        {state && state.length ? (
                                          state &&
                                          state.map((val, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={val.state_id}
                                              >
                                                {val.state_title}
                                              </option>
                                            );
                                          })
                                        ) : (
                                          <option value="">Loading...</option>
                                        )}
                                      </select>
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="username">
                                        {" "}
                                        District{" "}
                                      </label>
                                      <select
                                        required
                                        className="name"
                                        name="cars"
                                        id="cars"
                                        onChange={(e) =>
                                          setDistrictId(e.target.value)
                                        }
                                      >
                                        <option>--Select District--</option>
                                        {district && district.length ? (
                                          district.map((val, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={val.district_id}
                                              >
                                                {val.district_title}
                                              </option>
                                            );
                                          })
                                        ) : (
                                          <option value="">Loading...</option>
                                        )}
                                      </select>
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="username">Tehsil </label>
                                      <select
                                        className="name"
                                        required
                                        name="cars"
                                        id="cars"
                                        onChange={(e) =>
                                          setCityId(e.target.value)
                                        }
                                      >
                                        <option value="">
                                          --Select Tehsil--
                                        </option>
                                        {city && city.length ? (
                                          city.map((val, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={val.city_id}
                                              >
                                                {val.city_title}
                                              </option>
                                            );
                                          })
                                        ) : (
                                          <option value="">Loading...</option>
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="btn_main">
                                    <button type="submit" className="btn_save">
                                      Save
                                    </button>
                                    <button
                                      type="button"
                                      onClick={handleClickback}
                                      className="btn_save btn_cancle"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                  {/* </div> */}
                                </form>
                              </div>
                            )}
                          </div>
                          <div className="popup_modal ">
                            <button
                              type="button"
                              className="close  btn_popup"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span
                                aria-hidden="true"
                                onClick={handleClickback}
                              >
                                ×
                              </span>
                            </button>

                            <a
                              style={{
                                display: isAdd === "0" ? "none" : "block",
                              }}
                              type="button"
                              className="clientpop"
                              data-toggle="modal"
                              onClick={handleClick}
                            >
                              + Add Client if you missed
                            </a>

                            <div className="dashed_popup">
                              <form
                                role="form"
                                onSubmit={submitHandler}
                                ref={formRef}
                              >
                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label htmlFor="uname">
                                      Case Name<text className="m_star">*</text>
                                    </label>

                                    <input
                                      className="name"
                                      type="text"
                                      placeholder="Enter Case Name"
                                      name="uname"
                                      onChange={handleTitle1}
                                      required
                                    />
                                  </div>
                                  VS
                                  <div className="form-group">
                                    <label htmlFor="uname">&nbsp;</label>

                                    <input
                                      className="name"
                                      type="text"
                                      placeholder="Enter Case Name"
                                      name="uname"
                                      onChange={handleTitle2}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="uname" className="clientd">
                                      Client<text className="m_star">*</text>
                                    </label>
                                   <select disabled>
                                    
                                   {options && options.map((val) =>{
  return (
                                            <option selected={val.Id === id}>
                                              {val.FirstName}
                                            </option>
                                          );
                                   })}
                                   </select>
                                  </div>
                                </div>

                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label htmlFor="username">Court </label>
                                    <select
                                      className="name"
                                      name="cars"
                                      id="cars"
                                      onChange={(e) => {
                                        const selectedCourtId = e.target.value;
                                        const selectedCourtName = courtData.find(
                                          (val) => val.Id === selectedCourtId
                                        )?.CourtName;
                                        handleCourtChange(
                                          selectedCourtId,
                                          selectedCourtName
                                        );
                                      }}
                                    >
                                      <option value="">--Please Select--</option>

                                      {courtData &&
                                        courtData.map((val, index) => {
                                          return (
                                            <option key={index} value={val.Id}>
                                              {val.CourtName}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>

                                  <div className="form-group">
                                    <label htmlFor="username">Case Type </label>
                                    <select
                                      className="name"
                                      name="cars"
                                      id="cars"
                                      onChange={(e) => setType(e.target.value)}
                                    >
                                      <option value="">--Please Select--</option>
                                      {caseType && caseType.length ? (
                                        caseType &&
                                        caseType.map((val, index) => {
                                          return (
                                            <option key={index} value={val.Id}>
                                              {val.CaseType}
                                            </option>
                                          );
                                        })
                                      ) : (
                                        <>
                                          <option value="Booked Consultation/Case">
                                            Booked Consultation / Case
                                          </option>

                                          <option value="">
                                            Please select court first...
                                          </option>
                                        </>
                                      )}
                                    </select>
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="uname">Case No.</label>
                                    <input
                                      className="name"
                                      onKeyPress={handleKeyPress}
                                      type="text"
                                      placeholder="Enter Case No."
                                      name="uname"
                                      onChange={(e) =>
                                        setCaseNo(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="form_flex caseMobile">
                                <div className="form-group">
                                  <label htmlFor="uname">Case Year <text className="m_star">*</text></label>
                                    <select
                            required
                            value={caseYear}
                              className="name"
                              name="cars"
                              id="cars"
                              onChange={(e) => setCaseYear(e.target.value)}
                            >
                              <option value="">---Please select---</option>
                              {optionsYear &&
                                optionsYear.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.value}
                                    >
                                      {val.label}
                                    </option>
                                  );
                                })}
                            </select>
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="username">
                                      Court State{" "}
                                      <text className="m_star">*</text>
                                    </label>
                                    <select
                                      className="name"
                                      required
                                      name="cars"
                                      id="cars"
                                      onChange={(e) =>
                                        setStateId(e.target.value)
                                      }
                                    >
                                      <option>--Select State--</option>
                                      {state && state.length ? (
                                        state &&
                                        state.map((val, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={val.state_id}
                                            >
                                              {val.state_title}
                                            </option>
                                          );
                                        })
                                      ) : (
                                        <option value="">Loading...</option>
                                      )}
                                    </select>
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="username">
                                      {" "}
                                      Court District{" "}
                                      <text className="m_star">*</text>
                                    </label>
                                    <select
                                      className="name"
                                      name="cars"
                                      required
                                      id="cars"
                                      onChange={(e) =>
                                        setDistrictId(e.target.value)
                                      }
                                    >
                                      <option>--Select District--</option>
                                      {district && district.length ? (
                                        district.map((val, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={val.district_id}
                                            >
                                              {val.district_title}
                                            </option>
                                          );
                                        })
                                      ) : (
                                        <option value="">Loading...</option>
                                      )}
                                    </select>
                                  </div>
                             
                                </div>
                                <div className="form_flex caseMobile">
                                <div className="form-group">
                                    <label htmlFor="username">
                                      Court Tehsil{" "}
                                      <text className="m_star">*</text>{" "}
                                    </label>
                                    <select
                                      className="name"
                                      required
                                      name="cars"
                                      id="cars"
                                      onChange={(e) =>
                                        setCityId(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        --Select Tehsil--
                                      </option>
                                      {city && city.length ? (
                                        city.map((val, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={val.city_id}
                                            >
                                              {val.city_title}
                                            </option>
                                          );
                                        })
                                      ) : (
                                        <option value="">Loading...</option>
                                      )}
                                    </select>
                                  </div>
                                  <div className="form-group">
                            <label htmlFor="uname">Case Booked At <text className="m_star">*</text></label>
                          

                          <select
                            required
                            value={branchCity}
                              className="name"
                              name="cars"
                              id="cars"
                              onChange={(e) => setBranchCity(e.target.value)}
                            >
                              <option value="">---Please select---</option>
                              {branchCityData &&
                                branchCityData.map((val, index) =>{
                                  return (
                                    <option
                                      key={index}
                                      value={val.Id}
                                    >
                                      {val.BranchCity}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>

                                  <div className="form-group">
                                    <label htmlFor="uname">Filing Date </label>
                                    <input
                                      className="name"
                                      value={filingDate}
                                      type="date"
                                      placeholder=""
                                      name="uname"
                                      onChange={(e) =>
                                        setFilingdate(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>

                                {showInputField && (
                                  <>
                                       <hr />
                                  <div className="main_heading">
                               
                                    <h2>Diary</h2>
                                    </div>
                                    <div className="form_flex caseMobile">
                                      <div className="form-group">
                                        <label htmlFor="uname">Diary No.</label>
                                        <input
                                          className="name"
                                          type="text"
                                          placeholder="Enter Diart No."
                                          name="uname"
                                          onChange={(e) =>
                                            setDiaryNo(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label htmlFor="uname">
                                          Diary Year
                                        </label>
                                        <select
                                          className="name"
                                          name="cars"
                                          id="cars"
                                          onChange={(e) =>
                                            setDiaryYear(e.target.value)
                                          }
                                        >
                                          <option value="">
                                            --Please Select--
                                          </option>
                                          {optionsYear &&
                                            optionsYear.map((val, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={val.label}
                                                >
                                                  {val.label}
                                                </option>
                                              );
                                            })}
                                        </select>
                                      </div>
                                      <div className="form-group">
                                        <label htmlFor="uname">
                                          Name OF AOR
                                        </label>
                                        <input
                                          className="name"
                                          type="text"
                                          placeholder="Enter Name OF AOR"
                                          onChange={(e) =>
                                            setAor(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                
                                  </>
                                )}
                                <hr />
                                <div className="main_heading">
                                  <h2>Case Summary</h2>
                                </div>

                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label htmlFor="uname">Summary</label>
                                    <textarea
                                      className="name"
                                      type="text"
                                      placeholder="Enter Case Summary"
                                      name="uname"
                                      onChange={(e) =>
                                        setSummary(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <hr />
                                <div className="main_heading">
                                  <h2>Counsel</h2>
                                </div>

                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label htmlFor="uname">
                                      Counsel (Incharge){" "}
                                      <text className="m_star">*</text>{" "}
                                    </label>
                                    <select
                              className="name"
                              name="cars"
                              id="cars"
                              value={counsel}
                              onChange={(e) => handleCounselChange(e.target.value)}
                              required
                            >
                              <option value="">Please select</option>
                              {optionsUser &&
                                optionsUser.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.Id}
                  
                                    >
                                      {val.Name}
                                    </option>
                                  );
                                })}
                            </select>
                                  </div>

                                  <div className="form-group">
                                    <label htmlFor="uname">
                                      Counsel Share ( In % )
                                    </label>
                                    <input
                                      className="name"
                                      type="text"
                                      placeholder="%"
                                      value={counselShare}
                                      onChange={handleCounselShareChange}
                                    />
                                  </div>
                                </div>
                                <hr />
                                {cocounselRow &&
                                  cocounselRow.map((row, index) => (
                                    <div key={index}>
                                      <div className="form_flex caseMobile">
                                        <div className="form-group">
                                          <label
                                            htmlFor={`coCounselName-${index}`}
                                          >
                                            Co-Counsel _ {index + 1}
                                          </label>
                                          <select
                                            className="name"
                                            name="cars"
                                            id="cars"
                                            value={row.coCounselName}
                                            onChange={(e) =>
                                              handlecoCounsel(
                                                index,
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="">
                                              --Please Select--
                                            </option>
                                            {coCounselData &&
                                              coCounselData.map((val, index) => {
                                                return (
                                                  <option value={val.Id}>
                                                    {val.Name}
                                                  </option>
                                                );
                                              })}
                                          </select>
                                        </div>

                                        <div className="form-group">
                                          <label
                                            htmlFor={`coCounselShare-${index}`}
                                          >
                                            Co Counsel Share ( In % )
                                          </label>
                                          <input
                                            className="name"
                                            type="text"
                                            placeholder="%"
                                            value={row.coCounselShare}
                                            onChange={(e) =>
                                              handleCoCounselShareChange(
                                                index,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                        {index === 0 && (
                                          <a
                                            onClick={addRow}
                                            style={{
                                              color: "white",
                                              cursor: "pointer",
                                              margin: "10px",
                                              padding: "6px 10px",
                                              background: "green",
                                            }}
                                          >
                                            +
                                          </a>
                                        )}

                                        {index !== 0 && (
                                          <a
                                            onClick={() => removeRow(index)}
                                            style={{
                                              color: "white",
                                              cursor: "pointer",
                                              margin: "10px",
                                              padding: "6px 6px",
                                            }}
                                          >
                                            ❌
                                          </a>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label htmlFor="uname">
                                      Company Percentage
                                    </label>
                                    <input
                                      className="name"
                                      readOnly
                                      type="text"
                                      value={compantPrecentage}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="uname">
                                      Total Co Counsel Share
                                    </label>
                                    <input
                                      className="name"
                                      readOnly
                                      type="text"
                                      value={totalCoCounsel}
                                    />
                                  </div>
                                </div>

                                <hr />
                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label htmlFor="uname">Retaineed For</label>
                                    <select
                                      className="name"
                                      name="cars"
                                      id="cars"
                                      onChange={(e) =>
                                        setRetaineed(e.target.value)
                                      }
                                    >
                                      <option>--Please Select--</option>
                                      <option value="Petitioners">
                                        Petitioners/ Plaintiffs/ Appellants
                                      </option>
                                      <option value="Respondants">
                                        Respondants/ Defandants
                                      </option>
                                      <option value="Intervenors">
                                        Intervenors/ Complainants
                                      </option>
                                    </select>
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="uname">
                                      Intervenors/ Complainants
                                    </label>
                                    <textarea
                                      className="name"
                                      rows={2}
                                      cols={10}
                                      placeholder="Enter Intervenors"
                                      onChange={(e) =>
                                        setIntervenors(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label htmlFor="uname">
                                      Petitioners/ Plaintiffs
                                    </label>
                                    <textarea
                                      className="name"
                                      rows={2}
                                      cols={10}
                                      // value={Petitioners}
                                      placeholder="Enter Petitioners"
                                      onChange={handlePatitioners}
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label htmlFor="uname">
                                      Respondants/ Defandants
                                    </label>
                                    <textarea
                                      className="name"
                                      rows={2}
                                      cols={10}
                                      // value={respondants}
                                      placeholder="Enter Respondants"
                                      onChange={handleRespondants}
                                    />
                                  </div>
                                </div>
                                <hr />
                                <div className="main_heading">
                                  <h2>Payment Structure</h2>
                                </div>

                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label htmlFor="uname">Fixed Fees</label>
                                    <input
                                      className="name"
                                      onKeyPress={handleKeyPress}
                                      type="text"
                                      placeholder="Enter Fixed Fees"
                                      name="uname"
                                      onChange={(e) =>
                                        setFixedFees(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="uname">Expenses</label>
                                    <select
                                      className="name"
                                      name="cars"
                                      id="cars"
                                      onChange={(e) =>
                                        setExpenses(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        --Please Select--
                                      </option>
                                      <option value="0">Exclusive</option>
                                      <option value="1">Inclusive</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label htmlFor="uname">
                                      Effective Appearance Fees
                                    </label>
                                    <input
                                      className="name"
                                      onKeyPress={handleKeyPress}
                                      type="text"
                                      placeholder="Per Effective Appearance Fees"
                                      name="uname"
                                      onChange={(e) =>
                                        setAppearanceFee(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="uname">
                                      Non Effective Appr. Fees
                                    </label>
                                    <input
                                      className="name"
                                      onKeyPress={handleKeyPress}
                                      type="text"
                                      placeholder="per Non Effective Appr. Fees"
                                      name="uname"
                                      onChange={(e) =>
                                        setNonAppearanceFee(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                             {
                              saveButton ? (
                                <div className="btn_main caseMobile">
                                <button
                                  type="submit"
                                  className="btn_save"
                                  data-dismiss={popup ? "modal" : ""}
                                >
                                  Save
                                </button>
                                <button
                                  type="button"
                                  data-dismiss="modal"
                                  className="btn_save btn_cancle"
                                >
                                  Cancel
                                </button>
                              </div>
                              ) : ""
                             }
                                
                              
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*------------ EDIT CASE FORM -----------*/}

                    <div
                      className="modal fade"
                      id="exampleModalLongEdit"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="exampleModalLongTitle"
                      aria-hidden="true"
                    >
                      <div
                        className="modal-dialog modal-xl"
                        role="document"
                        // style={{ maxWidth: "80%" }}
                      >
                        <div className="modal-content">
                          <div className="add_wrap">
                            <div className="main_heading">
                              <h2>Edit Case</h2>
                            </div>
                          </div>
                          <div className="popup_modal ">
                            <button
                              type="button"
                              className="close e_popup btn_popup"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                            <div className="main_heading">
                              <h2>Case</h2>
                            </div>
                            <div className="dashed_popup">
                              <form role="form" onSubmit={submitEditHandler}>
                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label htmlFor="uname">
                                      Case Name<text className="m_star">*</text>
                                    </label>
                                    <input
                                      className="name"
                                      type="text"
                                      placeholder="Enter Case Name"
                                      name="uname"
                                      value={title1}
                                      onChange={handleEditTitle1}
                                      required
                                    />
                                  </div>
                                  VS
                                  <div className="form-group">
                                    <label htmlFor="uname">&nbsp;</label>
                                    <input
                                      className="name"
                                      type="text"
                                      placeholder="Enter Case Name"
                                      name="uname"
                                      value={title2}
                                      onChange={handleEditTitle2}
                                      required
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="uname">
                                      Client <text className="m_star">*</text>
                                    </label>
                                    <select
                                      className="name"
                                      name="cars"
                                      id="cars"
                                      required
                                      disabled
                                    >
                                      <option value="">Please select</option>
                                      {options &&
                                        options.map((val, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={val.value}
                                              selected={
                                                val.Id === editclient
                                              }
                                            >
                                              {val.FirstName}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>
                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label htmlFor="username">Court </label>

                                    <select
                                      className="name"
                                      name="cars"
                                      id="cars"
                                      onChange={(e) => {
                                        const selectedCourtId = e.target.value;
                                        const selectedCourtName = courtData.find(
                                          (val) => val.Id === selectedCourtId
                                        )?.CourtName;
                                        handleCourtChange(
                                          selectedCourtId,
                                          selectedCourtName
                                        );
                                      }}
                                    >
                                      <option value="">--Please Select--</option>

                                      {courtData &&
                                        courtData.map((val, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={val.Id}
                                              selected={val.Id === court}
                                            >
                                              {val.CourtName}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="username">Case Type </label>

                                    <select
                                      className="name"
                                      name="cars"
                                      id="cars"
                                      onChange={(e) => setType(e.target.value)}
                                    >
                                      <option value="">Please select</option>
                                      {caseType && caseType.length ? (
                                        caseType.map((val, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={val.Id}
                                              selected={val.Id === type}
                                            >
                                              {val.CaseType}
                                            </option>
                                          );
                                        })
                                      ) : (
                                        <option value="">Loading...</option>
                                      )}
                                    </select>
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="uname">Case No.</label>
                                    <input
                                    onKeyPress={handleKeyPress}
                                      className="name"
                                      type="text"
                                      placeholder="Enter Case No."
                                      name="uname"
                                      value={editcaseNo}
                                      onChange={(e) =>
                                        setEditCaseNo(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="form_flex caseMobile">
                                <div className="form-group">
                                    <label htmlFor="uname">Case Year<text className="m_star">*</text></label>
                                    <select
                                    required
                                      className="name"
                                      name="cars"
                                      id="cars"
                                      onChange={(e) =>
                                        setEditCaseYear(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        ---Please select---
                                      </option>
                                      {optionsYear &&
                                        optionsYear.map((val, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={val.value}
                                              selected={
                                                val.value == editcaseYear
                                              }
                                            >
                                              {val.label}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="username">
                                      Court State{" "}
                                    </label>
                                    <select
                                      className="name"
                                      name="cars"
                                      id="cars"
                                      onChange={handleStateChange}
                                    >
                                      <option value="">
                                        --Please Select--
                                      </option>
                                      {state && state.length
                                        ? state.map((val, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={val.state_id}
                                                selected={
                                                  val.state_id === stateId
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {val.state_title}
                                              </option>
                                            );
                                          })
                                        : ""}
                                    </select>
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="username">
                                      Court District{" "}
                                    </label>
                                    <select
                                      className="name"
                                      name="cars"
                                      id="cars"
                                      onChange={handleDistrictChange}
                                    >
                                      <option value="">
                                        --Please Select--
                                      </option>
                                      {district && district.length
                                        ? district.map((val, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={val.district_id}
                                                selected={
                                                  val.district_id === districtId
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {val.district_title}
                                              </option>
                                            );
                                          })
                                        : ""}
                                    </select>
                                  </div>
                                
                                </div>

                                <div className="form_flex caseMobile">
                                  {/* <div className="form-group">
                            <label htmlFor="username">Court Village </label>
                            <select
                              name="cars"
                              id="cars"
                              onChange={(e) => setVillageID(e.target.value)}
                            >
                              ( <option value="">please select...</option> )
                              {village && village.length ? (
                                village.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.village_id}
                                      selected={
                                        val.village_id === villageID
                                          ? true
                                          : false
                                      }
                                    >
                                      {val.village_name}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div> */}
                                
                                <div className="form-group">
                                    <label htmlFor="username">
                                      Court Tehsil{" "}
                                    </label>
                                    <select
                                      className="name"
                                      name="cars"
                                      id="cars"
                                      onChange={(e) =>
                                        setCityId(e.target.value)
                                      }
                                    >
                                      ({" "}
                                      <option value="">please select...</option>{" "}
                                      )
                                      {city && city.length ? (
                                        city.map((val, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={val.city_id}
                                              selected={
                                                val.city_id === cityId
                                                  ? true
                                                  : false
                                              }
                                            >
                                              {val.city_title}
                                            </option>
                                          );
                                        })
                                      ) : (
                                        <option value="">Loading...</option>
                                      )}
                                    </select>
                                  </div>
                                  <div className="form-group">
                            <label htmlFor="uname">Case Booked At <text className="m_star">*</text></label>
                          

                          <select
                            required
                            value={editBranchCity}
                              className="name"
                              name="cars"
                              id="cars"
                              onChange={(e) => setEditBranchCity(e.target.value)}
                            >
                              <option value="">---Please select---</option>
                              {branchCityData &&
                                branchCityData.map((val, index) =>{
                                  return (
                                    <option
                                      key={index}
                                      value={val.Id}
                                      selected={val.Id === editBranchCity}
                                    >
                                      {val.BranchCity}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                                  <div className="form-group">
                                    <label htmlFor="uname">Filing Date </label>
                                    <input
                                      className="name"
                                      type="date"
                                      name="uname"
                                      value={editfilingDate}
                                      onChange={(e) =>
                                        setEditFilingdate(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <hr />

                                {showInputField && (
                                  <>
                                    <div className="main_heading">
                                      <h2>Diary</h2>
                                    </div>
                                    <div className="form_flex caseMobile">
                                      <div className="form-group">
                                        <label htmlFor="uname">Diary No.</label>
                                        <input
                                          className="name"
                                          type="text"
                                          placeholder="Enter Diary No."
                                          name="uname"
                                          value={editdiaryNo}
                                          onChange={(e) =>
                                            setEditDiaryNo(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label htmlFor="uname">
                                          Diary Year
                                        </label>
                                        <select
                                          className="name"
                                          name="cars"
                                          id="cars"
                                          onChange={(e) =>
                                            setEditDiaryYear(e.target.value)
                                          }
                                        >
                                          <option value="">
                                            --Please Select--
                                          </option>
                                          {optionsYear &&
                                            optionsYear.map((val, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={val.label}
                                                  selected={
                                                    val.label === editdiaryYear
                                                      ? true
                                                      : false
                                                  }
                                                >
                                                  {val.label}
                                                </option>
                                              );
                                            })}
                                        </select>
                                      </div>
                                      <div className="form-group">
                                        <label htmlFor="uname">
                                          Name OF AOR
                                        </label>
                                        <input
                                          className="name"
                                          type="text"
                                          placeholder="Enter Name OF AOR"
                                          value={editaor}
                                          onChange={(e) =>
                                            setEditAor(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <hr />
                                  </>
                                )}

                                <div className="main_heading">
                                  <h2>Case Summary</h2>
                                </div>

                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label htmlFor="uname">Case Summary</label>
                                    <textarea
                                      className="name"
                                      type="text"
                                      placeholder="Enter Case Summary"
                                      name="uname"
                                      value={editsummary}
                                      onChange={(e) =>
                                        setEditSummary(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <hr />
                                <div className="main_heading">
                                  <h2>Counsel</h2>
                                </div>

                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label htmlFor="uname">
                                      Counsel (Incharge){" "}
                                      <text className="m_star">*</text>{" "}
                                    </label>
                                    <select
                                      className="name"
                                      name="cars"
                                      id="cars"
                                      onChange={(e) =>
                                        setEditCounsel(e.target.value)
                                      }
                                      required
                                    >
                                      <option value="">Please select</option>
                                      {viewUserData &&
                                        viewUserData.map((val, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={val.Id}
                                              selected={val.Id == editcounsel}
                                            >
                                              {val.Name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="uname">
                                      Counsel Share ( In % )
                                    </label>
                                    <input
                                      className="name"
                                      type="text"
                                      placeholder="%"
                                      value={editcounselShare}
                                      onChange={handleCounselShareChange1}
                                    />
                                  </div>
                                </div>
                                <hr />
                                {cocounselRow &&
                                  cocounselRow.map((row, index) => (
                                    <div key={index}>
                                      <div className="form_flex caseMobile">
                                        <div className="form-group">
                                          <label
                                            htmlFor={`coCounselName-${index}`}
                                          >
                                            Co-Counsel _ {index + 1}
                                          </label>
                                          <select
                                            className="name"
                                            name="cars"
                                            id="cars"
                                            value={row.coCounselName}
                                            onChange={(e) =>
                                              editHandlecoCounsel(
                                                index,
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="">
                                              --Please Select--
                                            </option>
                                            {viewUserCoCounselData &&
                                              viewUserCoCounselData.map((val, index) => {
                                                return (
                                                  <option
                                                    value={val.Id}
                                                    selected={
                                                      val.Id ===
                                                      row.coCounselName
                                                    }
                                                  >
                                                    {val.Name}
                                                  </option>
                                                );
                                              })}
                                          </select>
                                        </div>

                                        <div className="form-group">
                                          <label
                                            htmlFor={`coCounselShare-${index}`}
                                          >
                                            Co Counsel Share ( In % )
                                          </label>
                                          <input
                                            className="name"
                                            type="text"
                                            placeholder="%"
                                            value={row.coCounselShare}
                                            onChange={(e) =>
                                              editHandleCoCounselShare(
                                                index,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </div>
                                        {index === 0 && (
                                          <a
                                            onClick={editAddRow}
                                            style={{
                                              color: "white",
                                              cursor: "pointer",
                                              margin: "10px",
                                              padding: "6px 10px",
                                              background: "green",
                                            }}
                                          >
                                            +
                                          </a>
                                        )}

                                        {index !== 0 && (
                                          <a
                                            onClick={() =>
                                              editRemoveRow(
                                                index,
                                                row.coCounselId
                                              )
                                            }
                                            style={{
                                              color: "white",
                                              cursor: "pointer",
                                              margin: "10px",
                                              padding: "6px 6px",
                                            }}
                                          >
                                            ❌
                                          </a>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label htmlFor="uname">Retaineed For</label>
                                    <select
                                      className="name"
                                      name="cars"
                                      id="cars"
                                      value={editretaineed}
                                      onChange={(e) =>
                                        setEditRetaineed(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        --Please Select--
                                      </option>
                                      <option value="Petitioners">
                                        Petitioners/ Plaintiffs/ Appellants
                                      </option>
                                      <option value="Respondants">
                                        Respondants/ Defandants
                                      </option>
                                      <option value="Intervenors">
                                        Intervenors/ Complainants
                                      </option>
                                    </select>
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="uname">
                                      Intervenors/ Complainants
                                    </label>
                                    <textarea
                                      className="name"
                                      placeholder="Enter Intervenors"
                                      value={editintervenors}
                                      onChange={(e) =>
                                        setEditIntervenors(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label htmlFor="uname">
                                    Petitioners/ Plaintiffs
                                    </label>
                                    <textarea
                                      className="name"
                                      placeholder="Enter Petitioners"
                                      value={editpatitioners}
                                      onChange={(e) =>
                                        setEditPatitioners(e.target.value)
                                      }
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label htmlFor="uname">
                                      Respondants/ Defandants
                                    </label>
                                    <textarea
                                      className="name"
                                      placeholder="Enter Respondants"
                                      value={editrespondants}
                                      onChange={(e) =>
                                        setEditRespondants(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className={`form_flex col-${inputWidth}`}>
                                  <div className="form-group">
                                    <label htmlFor="uname">IsOpen</label>
                                    <select
                                      className="name"
                                      name="cars"
                                      id="cars"
                                      value={isOpen}
                                      onChange={(e) =>
                                        setIsOpen(e.target.value)
                                      }
                                    >
                                      <option value="1">Open</option>
                                      <option value="0">Close</option>
                                    </select>
                                  </div>
                                  {showClosingDate && (
                                    <div className="form-group">
                                      <label htmlFor="uname">
                                        Closing Date{" "}
                                        <text className="m_star">*</text>
                                      </label>
                                      <input
                                        className="name"
                                        required
                                        type="Date"
                                        value={closingDate}
                                        onChange={(e) =>
                                          setClosingDate(e.target.value)
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                                <hr />
                                <div className="main_heading">
                                  <h2>Payment Structure</h2>
                                </div>

                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label htmlFor="uname">Fixed Fees</label>
                                    <input
                                      className="name"
                                      onKeyPress={handleKeyPress}
                                      type="text"
                                      placeholder="Enter Fixed Fees"
                                      name="uname"
                                      value={editfixedFees}
                                      onChange={(e) =>
                                        setEditFixedFees(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="uname">Expenses</label>
                                    <select
                                      className="name"
                                      name="cars"
                                      id="cars"
                                      value={editexpenses}
                                      onChange={(e) =>
                                        setEditExpenses(e.target.value)
                                      }
                                    >
                                      <option value="">
                                        --- Please Select ---
                                      </option>
                                      <option value="0">Exclusive</option>
                                      <option value="1">Inclusive</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="form_flex caseMobile">
                                  <div className="form-group">
                                    <label htmlFor="uname">
                                      Effective Appearance Fees
                                    </label>
                                    <input
                                      className="name"
                                      onKeyPress={handleKeyPress}
                                      type="text"
                                      placeholder="Per Effective Appearance Fees"
                                      name="uname"
                                      value={editappearanceFee}
                                      onChange={(e) =>
                                        setEditAppearanceFee(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="uname">
                                      Non Effective Appr. Fees
                                    </label>
                                    <input
                                      className="name"
                                      onKeyPress={handleKeyPress}
                                      type="text"
                                      placeholder="per Non Effective Appr. Fees"
                                      name="uname"
                                      value={editnonAppearanceFee}
                                      onChange={(e) =>
                                        setEditNonAppearanceFee(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="btn_main caseMobile">
                                  <button type="submit" className="btn_save">
                                    Save
                                  </button>
                                  <button
                                    type="button"
                                    data-dismiss="modal"
                                    className="btn_save btn_cancle"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*--------- CASE DETAIL ---------*/}

                    <div
                      className="modal fade"
                      id="exampleModalLongDetail"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="exampleModalLongTitle"
                      aria-hidden="true"
                    >
                      <div
                        className="modal-dialog modal-xl"
                        role="document"
                        // style={{ maxWidth: "70%" }}
                      >
                        <div className="modal-content">
                          <div className="add_wrap">
                            <div className="main_heading">
                              <h2>Case Details</h2>
                            </div>
                          </div>
                          <div className="popup_modal ">
                            <button
                              type="button"
                              className="close ab btn_popup"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                            <div className="dashed_popup">
                              <div className="form_flex caseMobile">
                                <div className="form-group">
                                  <h4>
                                    Name &nbsp;: &nbsp;{" "}
                                    {dClientName == null ? "-" : dClientName}{" "}
                                  </h4>
                                </div>
                                <div className="form-group">
                                  <h4>
                                    Last Name &nbsp;:&nbsp;{" "}
                                    {dClientLastName == null
                                      ? "-"
                                      : dClientLastName}{" "}
                                  </h4>
                                </div>
                                <div className="form-group">
                                  <h4>
                                    Company &nbsp;: &nbsp;{" "}
                                    {dClientCompany == null
                                      ? "-"
                                      : dClientCompany}{" "}
                                  </h4>
                                </div>
                              </div>

                              <div className="form_flex caseMobile">
                                <div className="form-group">
                                  <h4>
                                    File No. &nbsp;: &nbsp;{" "}
                                    {dFileNo == null ? "-" : dFileNo}{" "}
                                  </h4>
                                </div>
                                <div className="form-group">
                                  <h4>
                                    Case No. &nbsp;:&nbsp;{" "}
                                    {dcaseNo === null ? "-" : dcaseNo}
                                  </h4>
                                </div>
                                <div className="form-group">
                                  <h4>
                                    Case Name &nbsp;:&nbsp;{" "}
                                    {dCaseName === null ? "-" : dCaseName}
                                  </h4>
                                </div>
                              </div>

                              <div className="form_flex caseMobile">
                                <div className="form-group">
                                  <h4>
                                    Court &nbsp;: &nbsp;{" "}
                                    {dCourtName == null ? "-" : dCourtName}{" "}
                                  </h4>
                                </div>
                                <div className="form-group">
                                  <h4>
                                    Case Type &nbsp;: &nbsp;{" "}
                                    {dCaseType == null ? "-" : dCaseType}{" "}
                                  </h4>
                                </div>
                                <div className="form-group">
                                  <h4>
                                    Case Year &nbsp;:&nbsp;{" "}
                                    {dCaseYear == null || "0000"? "-" : dCaseYear}{" "}
                                  </h4>
                                </div>
                              </div>

                              <div className="form_flex caseMobile">
                                <div className="form-group">
                                  <h4>
                                    State &nbsp;:&nbsp;{" "}
                                    {dstate_title == null ? "-" : dstate_title}{" "}
                                  </h4>
                                </div>

                                <div className="form-group">
                                  <h4>
                                    District &nbsp;: &nbsp;{" "}
                                    {ddistrict_title == null
                                      ? "-"
                                      : ddistrict_title}{" "}
                                  </h4>
                                </div>
                                <div className="form-group">
                                  <h4>
                                    City &nbsp;:&nbsp;{" "}
                                    {dcity_title == null ? "-" : dcity_title}{" "}
                                  </h4>
                                </div>
                              </div>

                              <div className="form_flex caseMobile">
                                <div className="form-group">
                                  <h4>
                                    AOR &nbsp;: &nbsp;{" "}
                                    {daor == null ? "-" : daor}{" "}
                                  </h4>
                                </div>
                                <div className="form-group">
                                  <h4>
                                    Intervenors &nbsp;:&nbsp;{" "}
                                    {dintervenors == null ? "-" : dintervenors}{" "}
                                  </h4>
                                </div>
                                <div className="form-group">
                                  <h4>
                                    Respondants &nbsp;: &nbsp;{" "}
                                    {drespondants == null ? "-" : drespondants}{" "}
                                  </h4>
                                </div>
                              </div>

                              <div className="form_flex caseMobile">
                                <div className="form-group">
                                  <h4>
                                    Retained For &nbsp;:&nbsp;{" "}
                                    {dretaineed == null ? "-" : dretaineed}{" "}
                                  </h4>
                                </div>
                                <div className="form-group">
                                  <h4>
                                    Petitioners &nbsp;: &nbsp;{" "}
                                    {dpatitioners == null ? "-" : dpatitioners}{" "}
                                  </h4>
                                </div>
                                <div className="form-group">
                                  <h4>
                                    Expenses &nbsp;:&nbsp;{" "}
                                    {dexpenses === "0"
                                      ? "Exclusive"
                                      : dexpenses === "1"
                                      ? "Inclusive"
                                      : "-"}
                                  </h4>
                                </div>
                              </div>

                              <div className="form_flex caseMobile">
                                <div className="form-group">
                                  <h4>
                                    Appearance Fee &nbsp;: &nbsp;{" "}
                                    {dappearanceFee == null
                                      ? "-"
                                      : dappearanceFee}{" "}
                                  </h4>
                                </div>
                                <div className="form-group">
                                  <h4>
                                    Non Appearance Fee &nbsp;:&nbsp;{" "}
                                    {dnonAppearanceFee == null
                                      ? "-"
                                      : dnonAppearanceFee}{" "}
                                  </h4>
                                </div>

                                <div className="form-group">
                                  <h4>
                                    Fixed Fee &nbsp;: &nbsp;{" "}
                                    {dfixedFees == null ? "-" : dfixedFees}{" "}
                                  </h4>
                                </div>
                              </div>

                              <div className="form_flex caseMobile">
                                <div className="form-group">
                                  <h4>
                                    Diary No &nbsp;: &nbsp;{" "}
                                    {ddiaryNo == null ? "-" : ddiaryNo}{" "}
                                  </h4>
                                </div>
                                <div className="form-group">
                                  <h4>
                                    Diary Year &nbsp;:&nbsp;{" "}
                                    {ddiaryYear == null || "0000" ? "-" : ddiaryYear}{" "}
                                  </h4>
                                </div>

                                <div className="form-group">
                                  <h4>
                                    Added By&nbsp;: &nbsp;{" "}
                                    {dAddedBy == null ? "-" : dAddedBy}
                                    {dDate == null || "0000-00-00" ? "-" : formatDate1(dDate)}
                                  </h4>
                                </div>
                              </div>

                              <div className="form_flex caseMobile">
                                <div className="form-group">
                                  <h4>
                                    Case Status &nbsp;: &nbsp;{" "}
                                    {dIsOpen === "1" ? "OPEN" : "CLOSE"}
                                  </h4>
                                </div>
                                <div className="form-group">
                                  <h4>
                                    Case Summary &nbsp;: &nbsp;{" "}
                                    {dsummary == null || dsummary === ""
                                      ? "-"
                                      : dsummary}{" "}
                                  </h4>
                                </div>
                              </div>

                              <div className="form_flex">
                                <div className="form-group">
                                  <table className="table detail_table">
                                    <tr>
                                      <th>S. No</th>
                                      <th>Counsel Name</th>
                                      <th>Percentage</th>
                                      <th>Counsel Type</th>
                                    </tr>

                                    {detailData &&
                                      detailData.map((val, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{val.counsel}</td>
                                            <td>{val.CounselPer} % </td>
                                            <td>
                                              {val.CounselType === "1"
                                                ? "Counsel"
                                                : "Co-Counsel"}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <Footer/>
            </div>
      {/*-------------------- Edit Client Form-------------------- */}
      <div
        className="modal fade"
        id="exampleModalLongClientEdit"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog popUp modal-xl"
          role="document"
          // style={{ maxWidth: "75%" }}
        >
          <div className="modal-content">
            <div className="add_wrap">
              <div className="main_heading">
                <h2>Edit Client</h2>
              </div>
            </div>
            <div className="popup_modal ">
              <button
                type="button"
                className="close btn_popup closeclientEdit"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>

              <div className="dashed_popup">
                <form role="form " onSubmit={HandleEditClientSubmit}>
                  <div className="main_heading">
                    <h2>Personal Details </h2>
                  </div>
                  <hr />
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label for="uname">
                        Full Name
                        <text className="m_star">*</text>
                      </label>
                      <input
                        autoComplete="off"
                        className="name"
                        value={EditFirstName}
                        type="text"
                        placeholder="Enter Full Name"
                        onChange={(e) => setEditFirstName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label for="uname">
                        Mobile No
                        <text className="m_star">*</text>
                      </label>
                      <input
                        value={EditMobileNo}
                        className="name"
                        onKeyPress={handleKeyPress}
                        maxLength={10}
                        autoComplete="off"
                        type="text"
                        placeholder="Enter Mobile No"
                        onChange={handleInputChange1}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label for="uname">
                        Last Name & Son/Wife/Daughter of
                      </label>
                      <input
                        onKeyPress={handleKeyPressalpha}
                        autoComplete="off"
                        className="name"
                        value={EditLastName}
                        type="text"
                        placeholder="Enter Name of Father/Husband"
                        onChange={(e) => setEditLastName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label for="uname">Designation </label>
                      <input
                        onKeyPress={handleKeyPressalpha}
                        value={EditDesignation}
                        autoComplete="off"
                        className="name"
                        type="text"
                        placeholder="Enter Designation "
                        onChange={(e) => setEditDesignation(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label for="uname">Company Name</label>
                      <input
                        autoComplete="off"
                        className="name"
                        value={EditCompanyName}
                        type="text"
                        placeholder="Enter Company Name"
                        onChange={(e) => setEditCompanyName(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <label for="uname">Email</label>
                      <input
                        value={EditEmail}
                        className="name"
                        autoComplete="off"
                        type="Email"
                        placeholder="Enter Email"
                        onChange={(e) => setEditEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="main_heading">
                    <h2> Address </h2>
                  </div>
                  <hr />
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label for="uname">Telephone No</label>
                      <input
                        value={EditPhone}
                        onKeyPress={handleKeyPress}
                        maxLength={10}
                        className="name"
                        autoComplete="off"
                        type="text"
                        onChange={handleInputChange3}
                        placeholder="Enter Telephone No"
                      />
                    </div>
                    <div className="form-group">
                      <label for="uname">House No</label>
                      <input
                        value={EditHouseNo}
                        className="name"
                        autoComplete="off"
                        type="text"
                        placeholder="Enter House No"
                        onChange={(e) => setEditHouseNo(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <label for="uname">Address</label>
                      <input
                        value={EditAddress}
                        autoComplete="off"
                        className="name"
                        type="text"
                        placeholder="Enter Address"
                        onChange={(e) => setEditAddress(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label for="uname">Pin Code </label>
                      <input
                        onKeyPress={handleKeyPress1}
                        value={EditPinCode}
                        autoComplete="off"
                        className="name"
                        type="text"
                        placeholder="Enter PinCode "
                        onChange={(e) => setEditPinCode(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="city">Country</label>

                      <select
                        value={CountryID}
                        onChange={(e) => setEditCountryID(e.target.value)}
                      >
                        <option value="">--Please Select--</option>
                        <option value="1">India</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <div>
                        <label htmlFor="city">
                          State<text className="m_star">*</text>
                        </label>

                        <select id="city" onChange={handleStateChange} required>
                          <option value="">--Please Select--</option>
                          {State && State.length > 0 ? (
                            State.map((val) => (
                              <option
                                key={val.state_id}
                                value={val.state_id}
                                selected={val.state_id === stateId}
                              >
                                {val.state_title}
                              </option>
                            ))
                          ) : (
                            <option value="">Loading...</option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="city">
                        District<text className="m_star">*</text>
                      </label>
                      <select
                        required
                        id="citySelect"
                        onChange={handleDistrictChange}
                      >
                        <option value="">--Please Select--</option>

                        {district && district.length > 0 ? (
                          district.map((val) => (
                            <option
                              key={val.district_id}
                              value={val.district_id}
                              selected={val.district_id === districtId}
                            >
                              {val.district_title}
                            </option>
                          ))
                        ) : (
                          <option value="">Loading...</option>
                        )}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="city">
                        Tehsil<text className="m_star">*</text>
                      </label>
                      <select
                        required
                        id="citySelect"
                        onChange={handleCityChange}
                      >
                        <option value="">--Please Select--</option>
                        {city && city.length > 0 ? (
                          city.map((val) => (
                            <option
                              key={val.city_id}
                              value={val.city_id}
                              selected={val.city_id === cityData}
                            >
                              {val.city_title}
                            </option>
                          ))
                        ) : (
                          <option value="">Loading...</option>
                        )}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="username">Village</label>
                      <select
                        name="cars"
                        id="cars"
                        onChange={(e) => setVillageID(e.target.value)}
                      >
                        <option value="1">--Select Village--</option>
                        {village && village.length ? (
                          village.map((val, index) => {
                            return (
                              <option key={index} value={val.village_id}>
                                {val.village_name}
                              </option>
                            );
                          })
                        ) : (
                          <option value="">Loading...</option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="main_heading">
                    <h2> Reference </h2>
                  </div>
                  <hr />
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <div>
                        <label htmlFor="city">Is Lead</label>
                        <select
                          value={EditselectedOption}
                          onChange={handleOptionChange2}
                        >
                          <option value="">--Please Select--</option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <div>
                        <label htmlFor="city">Source</label>
                        <select
                          value={EditSource}
                          onChange={handleOptionChange3}
                        >
                          <option value="null">--Please Select--</option>
                          <option value="Walkin">WALKIN</option>
                          <option value="Reffered">REFFERED</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label for="uname">Referred By Client</label>
                      <select
                        className="name"
                        id="city"
                        onChange={(e) =>
                          handleEditReferredByClientID(e.target.value)
                        }
                      >
                        <option value="">--Please Select--</option>
                        {referredByClientData &&
                        referredByClientData.length > 0 ? (
                          referredByClientData.map((val) => (
                            <option
                              value={val.Id}
                              selected={val.Id === EditReferredByClientID}
                            >
                              {val.FirstName}
                            </option>
                          ))
                        ) : (
                          <option value=""></option>
                        )}
                      </select>
                    </div>
                    <div className="form-group">
                      <label for="uname">Referred By Counsel</label>
                      <select
                        className="name"
                        id="city"
                        onChange={(e) =>
                          handleEditReferredByCounselID(e.target.value)
                        }
                      >
                        <option value="">--Please Select--</option>
                        {referredByCounselData &&
                        referredByCounselData.length > 0 ? (
                          referredByCounselData.map((val) => (
                            <option
                              value={val.Id}
                              selected={val.Id === EditReferredByCounselID}
                            >
                              {val.ContactPersonName}
                            </option>
                          ))
                        ) : (
                          <option value=""></option>
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label for="uname">Comments</label>
                      <textarea
                        value={Edittext}
                        onChange={handleTextChange1}
                        rows={4}
                        cols={50}
                        placeholder="Enter your text here..."
                      />
                    </div>
                  </div>
                  <div className="btn_main caseMobile">
                    <button type="submit" className="btn_save">
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn_save btn_cancle"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
            {/*--------- Toster ---------*/}

            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        </DocumentTitle>
      )}
    </>
  );
};

export default ClientDetails;
