import React, { useState, useRef } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import DocumentTitle from "react-document-title";
import Footer from "./Footer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import {
  deleteUser,
  userDetails,
  activeStatus,
  permission,
  getClient,
  withUidApi,
} from "../services/api.service";
import { formatDate1 } from "../services/api.service";
import Select from "react-select";

const NotesCD = () => {

  const [saveButton, setSaveButton] = useState(true);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);
  //---------- CLOSE POPUP VARIABLE ----------

  const currentDate = new Date().toISOString().split("T")[0];
  const closeButton = document.querySelector(".btn_popup");
  const closeEdit = document.querySelector(".e_popup");
  const closeDelete = document.querySelector(".dnote");
  const closeView = document.querySelector(".ab");

  //----------  NOTE VARIABLE ----------

  const baseUrl = useContext(url);
  const navigate = useNavigate();
  const [dSummary, setDSummary] = useState("");
  const [dDetails, setDDetails] = useState("");
  const [dFileNo, setDFileNo] = useState("");
  const [dCaseNo, setDCaseNo] = useState("");
  const [dCaseYear, setDCaseYear] = useState("");
  const [dCaseName, setDCaseName] = useState("");
  const [dClientName, setDClientName] = useState("");
  const [dClientLastName, setDClientLastName] = useState("");
  const [dClientCompany, setDClientCompany] = useState("");
  const [dCounselName, setDCounselName] = useState("");
  const [dCoCounselName, setDCoCounselName] = useState("");
  const [isAdd, setIsAdd] = useState("0");
  const [isEdit, setIsEdit] = useState("0");
  const [isView, setIsView] = useState("0");
  const [isDelete, setIsDelete] = useState("0");
  const [isStatus, setIsStatus] = useState("0");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  //---------- ADD NOTE VARIABLE ----------

  const [caseLeadData, setCaseLeadData] = useState([]);
  const [caseData, setCaseData] = useState("");
  const [fileNo, setFileNo] = useState("");
  const [summary, setSummary] = useState("");
  const [status, setStatus] = useState("");
  const [detail, setDetail] = useState("");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [filterUser, setFilterUser] = useState("");
  const [delId, setDelId] = useState("");
  const [active, setActive] = useState(false);
  const [fCaseId, setFCaseId] = useState("");
  const [fFromDate, setFFromDate] = useState("");
  const ViewCDData = new FormData();
  const [optionsCD, setOptionsCD] = useState([]);

  //---------- EDIT NOTE VARIABLE ----------

  const [editId, setEditId] = useState("");
  const [editDate, setEditDate] = useState("");
  const [editfileNo, setEditFileNo] = useState("");
  const [editDetail, setEditDetail] = useState("");
  const [editsummary, setEditSummary] = useState("");
  const formData = new FormData();
  const editCaseLead = new FormData();

  //---------- VIEW NOTE API ----------

  const getNoteView = async () => {
    const [view, data, cdata] = await Promise.all([
      withUidApi("Notes/ViewNotes"),
      permission(8),
      getClient("Client/ViewClient"),
      getFileNo(),
      setLoading(true),
    ]);

    if (view === null) {
      setLoading(false);
      setCaseLeadData([]);
    } else {
      if (view === "token") {
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        setLoading(false);
        setCaseLeadData(view);
      }
    }

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }
  };
  //------------ VIEW CASE ---------

  const getCaseHistory = async () => {
    ViewCDData.append("ID", id);
    ViewCDData.append("AdminID", localStorage.getItem("id"));

    try {
      const response = await fetch(
        `${baseUrl}CaseHistory/ViewCaseHistoryById`,
        {
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
          body: ViewCDData,
        }
      );

      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }

      setCaseData(data && data.result[0].case[0])
      setOptionsCD(data && data.result[0]);
    } catch (error) {
      console.error(error);
    }
  };

  //---------- DELETE NOTE ----------

  const deleteCaseLead = async () => {
    const data = await deleteUser(delId, "Notes/DeleteNotes");
    if (data === "token") {
      setTimeout(() => {
        closeDelete.click();
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      closeDelete.click();
      getNoteView();
      getCaseHistory();
    }
  };

  //---------- ACTIVE DEACTIVE NOTE ----------

  const deActive = async (row) => {
    const data = await activeStatus(row, "Notes/NotesStatus");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      getNoteView();
      getCaseHistory();
    }
  };

  //---------- NOTE DETAIL ----------

  const details = async (id) => {
    const data = await userDetails(id, "Notes/ViewNotesById");
    if (data === "token") {
      setTimeout(() => {
        closeView.click();
        navigate("/");
      }, 3000);
    } else {
      setStatus(data && data.result[0].Status);
      setDSummary(data && data.result[0].Summary);
      setDDetails(data && data.result[0].Details);
      setDFileNo(data && data.result[0].FileNo);
      setDCaseNo(data && data.result[0].CaseNo);
      setDCaseYear(data && data.result[0].CaseYear);
      setDCaseName(data && data.result[0].CaseName);
      setDClientName(data && data.result[0].ClientName);
      setDClientLastName(data && data.result[0].ClientLastName);
      setDClientCompany(data && data.result[0].ClientCompany);
      setDCounselName(data && data.result[0].CounselName);
      setDCoCounselName(data && data.result[0].CoCounselName);
    }
  };

  /** Get File Name  */
  const Case = new FormData();
  const [optionsCase, setOptionsCase] = useState([]);
  const getFileNo = async () => {
    Case.append("AdminID", localStorage.getItem("id"));
    Case.append("UserID", localStorage.getItem("uId"));
    
    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: Case,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }

      if (data && data.result[0].message === "Success") {
        const Mapped = data.result.map((item) => ({
          value: item.CaseID,
          label: `${item.FileNo} / ${item.CaseNo} / ${item.CaseName} / ${item.CaseYear}`,
        }));
        setOptionsCase(Mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setFileNo("");
    } else {
      setFileNo(selectedOption.value);
    }
  };

  //---------- NOTE DETAILS ----------

  const getById = async (id) => {
    const data = await userDetails(id, "Notes/ViewNotesById");
    if (data === "token") {
      setTimeout(() => {
        closeEdit.click();
        navigate("/");
      }, 3000);
    } else {
      setEditId(data && data.result[0].Id);
      setEditSummary(data && data.result[0].Summary);
      setEditDetail(data && data.result[0].Details);
      setEditFileNo(data && data.result[0].CaseID);
      setEditDate(data && data.result[0].FormattedDate);
    }
  };

  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  //---------- SUBMIT NOTE DATA ----------

  const submitHandler = async (event) => {
    event.preventDefault();
    setSaveButton(false);
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    formData.append("Summary", summary.toUpperCase());
    formData.append("Details", detail.toUpperCase());
    formData.append("Date", date);
    formData.append("CaseID", id);
    try {
      const response = await fetch(`${baseUrl}Notes/AddNotes`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].message === "Success") {
        toast.success("Note added successfully");
        closeButton.click();
        handleClose();
        getNoteView();
        getCaseHistory();
        setSummary("");
        setDetail("");
        setDate("");
        setFileNo("");
        setSaveButton(true);
      } else {
        toast.error(data.result.message);
        setSaveButton(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //---------- EDIT NOTE ----------

  const submitEditHandler = async (event) => {
    event.preventDefault();
    editCaseLead.append("ID", editId);
    editCaseLead.append("AdminID", localStorage.getItem("id"));
    editCaseLead.append("UpdatedBy", localStorage.getItem("UserId"));
    editCaseLead.append("CaseID", id);
    editCaseLead.append("Date", editDate);
    editCaseLead.append("Details", editDetail.toUpperCase());
    editCaseLead.append("Summary", editsummary.toUpperCase());
    try {
      const response = await fetch(`${baseUrl}Notes/EditNotes`, {
        method: "POST",
        body: editCaseLead,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeEdit.click();
          navigate("/");
        }, 3000);
      }
      if (data.result.length && data.result[0].code === "200") {
        toast.success(" Updated successfully");
        closeEdit.click();
        getNoteView();
        getCaseHistory();
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
useEffect(()=>{
  getCaseHistory();
  getFileNo();
},[])
  return (
    <DocumentTitle title="NOTE | LEGAL CRM">
    <>
    <div className="wraper">
    <Header />
<div className="mainWraper">
<Sidebar />
  <div className="pageBody">
    <div className="pageContent">
      <div className="PageTitle">
        <h3>Case Detail</h3>
        <a title="Case Details"
           href={`#/view-casedetails/${id}`}
                        type="button"
                        className="btn btn-primary btn_client"
                      >
                        Back
                      </a>
      </div>
<br/>

      <div className="caseDetail">
        <div className="form-group Details">
          <h4>Case Name : </h4>
          <p>
            {caseData.CaseName === "" ? "-" : caseData.CaseName}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Case No. : </h4>
          <p>
            {caseData.CaseNo === "" || caseData.CaseNo === null
              ? "-"
              : caseData.CaseNo}
          </p>
        </div>

        <div className="form-group Details">
          <h4>Case Year : </h4>
          <p>
            {caseData.CaseYear === "" ||
            caseData.CaseYear === null
              ? "-"
              : caseData.CaseYear}
          </p>
        </div>

        <div className="form-group Details">
          <h4>Court Name : </h4>
          <p>
            {caseData.CourtName === "" ||
            caseData.CourtName === null
              ? "-"
              : caseData.CourtName}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Case Type :</h4>
          <p>
            {caseData.CaseType === "" ||
            caseData.CaseType === null
              ? "-"
              : caseData.CaseType}
          </p>
        </div>
        <div className="form-group Details">
          <h4>File No. : </h4>
          <p>
            {caseData.FileNo === "" || caseData.FileNo === null
              ? "-"
              : caseData.FileNo}
          </p>
        </div>

        <div className="form-group Details">
          <h4>Diary No. : </h4>
          <p>
            {caseData.DiaryNo === "" || caseData.DiaryNo === null
              ? "-"
              : caseData.DiaryNo}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Diary Year: </h4>
          <p>
            {caseData.DiaryYear === "" ||
            caseData.DiaryYear === null
              ? "-"
              : caseData.DiaryYear}
          </p>
        </div>
       

        <div className="form-group Details">
          <h4>Client Name : </h4>
          <p>
            {caseData.ClientName === "" ||
            caseData.ClientName === null
              ? "-"
              : caseData.ClientName}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Client Last Name : </h4>
          <p>
            {caseData.ClientLastName === "" ||
            caseData.ClientLastName === null
              ? "-"
              : caseData.ClientLastName}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Company Name : </h4>
          <p>
            {caseData.CompanyName === "" ||
            caseData.CompanyName === null
              ? "-"
              : caseData.CompanyName}
          </p>
        </div>

        <div className="form-group Details">
          <h4>Client Mobile No. : </h4>

          <p>
            {caseData.MobileNo === "" ||
            caseData.MobileNo === null
              ? "-"
              : caseData.MobileNo}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Phone : </h4>
          <p>
            {caseData.Phone === "" || caseData.Phone === null
              ? "-"
              : caseData.Phone}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Email :</h4>
          <p>
            {caseData.Email === "" || caseData.Email === null
              ? "-"
              : caseData.Email}
          </p>
        </div>
        </div>
<br/>
      <div className="report_crmgraph">
        <div className="row">
          <div className="col-md-12">
            <div className="myCard">
              <div className="cardHeader">
                <h4>Notes</h4>
                <a
                  style={{
                    // display: isAdd === "0" ? "none" : "block",
                  }}
                  type="button"
                  className="btn btn-primary btn_client headbtn"
                  data-toggle="modal"
                  data-target="#exampleModalLong"
                >
                  Add Note <img src="assets/img/icons/add.svg" />
                </a>
                <a href="/#/view-note" className="rytContent">
                  <img
                    src="assets/img/icons/dots.png"
                    alt=""
                    title="All Notes"
                  />
                </a>
              </div>
              {optionsCD.Notes && optionsCD.Notes[0].message === "Success" ? (
                <div className="cardBody">
                  <table className="myTable" style={{tableLayout:"fixed"}}>
                    <thead>
                      <th>Action</th>
                      <th>Date</th>
                      <th>Details</th>
                      <th>Summary</th>
                    </thead>

                    {optionsCD.Notes && optionsCD.Notes.length > 0 ? (
                      optionsCD.Notes.map((val, index) => (
                        <tr
                          key={index}
                          className={index % 2 === 0 ? "row-even" : "row-odd"}
                        >
                          <td>
                            <div style={{ display: "flex", gap: "15px" }}>
                              {/* {isEdit !== "0" && ( */}
                                <span
                                  type="button"
                                  data-toggle="modal"
                                  data-target="#exampleModalLongEdit"
                                  onClick={() => getById(val.Id)}
                                >
                                  <img
                                    src="assets/img/icons/edit.svg"
                                    alt="Edit"
                                    title="Edit"
                                  />
                                </span>
                              {/* )} */}

                              <span
                                type="button"
                                data-toggle="modal"
                                data-target="#exampleModalLongDetail"
                                onClick={() => details(val.Id)}
                              >
                                <img
                                  src="assets/img/icons/eye.svg"
                                  alt="Details"
                                  title="Details"
                                />
                              </span>
                              <span>
                                <a
                                  style={{
                                    cursor: "pointer",
                                    // display:
                                    //   isStatus === "0" ? "none" : "block",
                                  }}
                                  onClick={() =>
                                    deActive(val, "Notes/NotesStatus")
                                  }
                                >
                                  {val.Status === "1" ? (
                                    <img
                                      className="thumb_icon"
                                      src="assets/img/icons/Up.svg"
                                      title="Status"
                                    />
                                  ) : (
                                    <img
                                      className="thumb_icon"
                                      src="assets/img/icons/Down.svg"
                                      title="Status"
                                    />
                                  )}
                                </a>
                              </span>
                              {/* {isDelete !== "0" && ( */}
                                <span
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setDelId(val.Id)}
                                >
                                  <img
                                    src="assets/img/icons/delete.svg"
                                    alt="Delete"
                                    title="Delete"
                                  />
                                </span>
                              {/* )} */}
                            </div>
                          </td>
                          <td >{formatDate1(val.Date)}</td>
                          <td>
                            {val.Details === "" || val.Details === null
                              ? "-"
                              : val.Details}
                          </td>
                          <td>
                            {val.Summary === "" || val.Summary === null
                              ? "-"
                              : val.Summary}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div></div>
                    )}
                  </table>
                </div>
              ) : (
                <p className="case_detail_table">Data not found</p>
              )}
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
      </div>
      <Footer />
        </div>
      {/*-------- DELETE NOTE  --------*/}

      <div
        className="modal fade sc_modal"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close dnote"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <h4>Delete Row</h4>
              <p>Are you sure you want to delete note ?</p>
              <ul>
                <li>
                  <a className="btn btn-secondary" data-bs-dismiss="modal">
                    Cancel
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-danger"
                    onClick={() => deleteCaseLead(delId)}
                  >
                    Delete
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/*-------- ADD NOTE FORM --------*/}

      <div
        className="modal fade"
        id="exampleModalLong"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
        show={show}
      >
        <div
          className="modal-dialog modal-lg"
          role="document"
          // style={{ maxWidth: "50%" }}
        >
          <div className="modal-content">
            <div className="add_wrap">
              <div className="main_heading">
                <h2>Add Note</h2>
              </div>
            </div>
            <div className="popup_modal ">
              <button
                type="button"
                className="close btn_popup"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="dashed_popup">
                <form role="form" onSubmit={submitHandler} ref={formRef}>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="username">
                        File No. <text className="m_star">*</text>
                      </label>
                      <select disabled id="city" required>
                                          {optionsCase &&
                                            optionsCase.map((val, index) => (
                                              <option
                                                key={index}
                                                value={val.value}
                                                selected={val.value === id}
                                              >
                                                {val.label}
                                              </option>
                                            ))}
                                        </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="uname">
                        Date <text className="m_star">*</text>
                      </label>
                      <input
                        className="name"
                        type="date"
                        placeholder=""
                        name="uname"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="username">Summary</label>
                      <textarea
                        className="name"
                        type="text"
                        placeholder="Enter Summary"
                        name="uname"
                        value={summary}
                        onChange={(e) => setSummary(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="username">Details</label>
                      <textarea
                        className="name"
                        type="text"
                        rows={10}
                        placeholder="Enter Details"
                        name="uname"
                        value={detail}
                        onChange={(e) => setDetail(e.target.value)}
                      />
                    </div>
                  </div>

               {
                saveButton ? (
                  <div className="btn_main caseMobile">
                  <button type="submit" className="btn_save">
                    Save
                  </button>
                  <button
                    type="button"
                    data-dismiss="modal"
                    className="btn_save btn_cancle"
                  >
                    Cancel
                  </button>
                </div>
                ) : ""
               }
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*-------- EDIT NOTE FORM --------*/}

      <div
        className="modal fade"
        id="exampleModalLongEdit"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg"
          role="document"
          // style={{ maxWidth: "50%" }}
        >
          <div className="modal-content">
            <div className="add_wrap">
              <div className="main_heading">
                <h2>Edit Note</h2>
              </div>
            </div>
            <div className="popup_modal ">
              <button
                type="button"
                className="close e_popup btn_popup"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="dashed_popup">
                <form role="form" onSubmit={submitEditHandler}>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="username">
                        File No.<text className="m_star">*</text>
                      </label>
                      <select disabled id="city" required>
                                          {optionsCase &&
                                            optionsCase.map((val, index) => (
                                              <option
                                                key={index}
                                                value={val.value}
                                                selected={val.value === id}
                                              >
                                                {val.label}
                                              </option>
                                            ))}
                                        </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="uname">
                        Date<text className="m_star">*</text>
                      </label>
                      <input
                        type="date"
                        placeholder=""
                        name="uname"
                        value={editDate}
                        onChange={(e) => setEditDate(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="username">Summary</label>
                      <textarea
                        type="text"
                        placeholder="Enter Summary"
                        name="uname"
                        value={editsummary}
                        onChange={(e) => setEditSummary(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="username">Details</label>
                      <textarea
                        type="text"
                        rows={10}
                        placeholder="Enter Details"
                        name="uname"
                        value={editDetail}
                        onChange={(e) => setEditDetail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="btn_main caseMobile">
                    <button type="submit" className="btn_save">
                      Save
                    </button>
                    <button
                      type="button"
                      data-dismiss="modal"
                      className="btn_save btn_cancle"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*-------- NOTE DETAILS --------*/}

      <div
        className="modal fade"
        id="exampleModalLongDetail"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg"
          role="document"
          // style={{ maxWidth: "60%", height: "700px" }}
        >
          <div className="modal-content">
            <div className="add_wrap">
              <div className="main_heading">
                <h2>Note Details</h2>
              </div>
            </div>
            <div className="popup_modal ">
              <button
                type="button"
                className="close ab btn_popup"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="dashed_popup">
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>
                      File No. &nbsp;: &nbsp; {dFileNo == null ? "-" : dFileNo}{" "}
                    </h4>
                  </div>
                  <div className="form-group">
                    <h4>
                      Status &nbsp;:&nbsp; {status == 1 ? "Active" : "DeActive"}{" "}
                    </h4>
                  </div>
                </div>
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>
                      Case No &nbsp;: &nbsp; {dCaseNo === "" ? "-" : dCaseNo}{" "}
                    </h4>
                  </div>
                  <div className="form-group">
                    <h4>
                      Case Year &nbsp;:&nbsp;{" "}
                      {dCaseYear === "" ? "-" : dCaseYear}{" "}
                    </h4>
                  </div>
                </div>
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>
                      Case Name &nbsp;: &nbsp;{" "}
                      {dCaseName === "" ? "-" : dCaseName}{" "}
                    </h4>
                  </div>
                  <div className="form-group">
                    <h4>
                      Client Name &nbsp;:&nbsp;{" "}
                      {dClientName == null ? "-" : dClientName}{" "}
                    </h4>
                  </div>
                </div>
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>
                      Client Last Name &nbsp;: &nbsp;{" "}
                      {dClientLastName == null ? "-" : dClientLastName}{" "}
                    </h4>
                  </div>
                  <div className="form-group">
                    <h4>
                      Client Company &nbsp;:&nbsp;{" "}
                      {dClientCompany == null ? "-" : dClientCompany}{" "}
                    </h4>
                  </div>
                </div>
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>
                      Counsel Name &nbsp;: &nbsp;{" "}
                      {dCounselName == null ? "-" : dCounselName}{" "}
                    </h4>
                  </div>
                  <div className="form-group">
                    <h4>
                      Co Counsel Name &nbsp;:&nbsp;{" "}
                      {dCoCounselName == null ? "-" : dCoCounselName}{" "}
                    </h4>
                  </div>
                </div>
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>
                      Summary &nbsp;: &nbsp; {dSummary == null ? "-" : dSummary}{" "}
                    </h4>
                  </div>
                  <div className="form-group">
                    <h4>
                      Details &nbsp;:&nbsp; {dDetails == null ? "-" : dDetails}{" "}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    </DocumentTitle>
  );
};

export default NotesCD;
