import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import Header from "./Header";
import Sidebar from "./Sidebar";
import DataTable from "react-data-table-component";
import { useContext } from "react";
import { url } from "../index";
import { useNavigate } from "react-router";
import { customStyles } from "../services/api.service";
import Preloader from "./Preloader";
import DocumentTitle from "react-document-title";
import Footer from "./Footer";

const Permission = () => {
  /********************************preloader****************************************************/
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    /***********************Simulate an asynchronous operation (e.g., API call) with setTimeout****************************************/
    setTimeout(() => {
      setIsLoading(false);
      /**********************************Simulating a 1-second delay*********************************************/
    }, 500);
  }, []);
  /*--------- VARIABLE --------*/

  const navigate = useNavigate();
  const baseUrl = useContext(url);
  const [viewRoleData, setViewRoleData] = useState([]);
  const [viewRoleFilter, setViewRoleFilter] = useState([]);
  const [filterUser, setFilterUser] = useState();
  const [active, setActive] = useState(false);
  const [viewPermissionData, setViewPermissionData] = useState([]);
  const addForm = new FormData();
  const viewCase = new FormData();

  /*--------- SUBMIT PERMISSION --------*/

  const submitHandler = async () => {
    const update = viewPermissionData.map((obj) => {
      const { message, AddedBy, code, ...rest } = obj;
      return rest;
    });
    addForm.append("Data", JSON.stringify(update));
    try {
      const response = await fetch(`${baseUrl}Permission/AddPermission`, {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("access_token"),
        },
        body: addForm,
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data.result.code == 200) {
        toast.success("Data Updated Successfully");
        setViewPermissionData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /*--------- PERMISSION ROLE --------*/

  const permissionViewApi = async (value) => {
    const formData = new FormData();
    formData.append("RoleID", value);
    formData.append("AdminID", localStorage.getItem("id"));
    try {
      const response = await fetch(
        `${baseUrl}Permission/ViewPermissionByRoleId`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: localStorage.getItem("access_token"),
            Accept: "*/*",
          },
        }
      );
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }

      if (data && data.result[0].message === "Success") {
        setViewPermissionData(data && data.result);
      } else {
        setViewPermissionData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const permissionViewRole = (value) =>{
    if (value === "") {
      setViewPermissionData([]);
    } else {
      permissionViewApi(value)
    }
  }

  /*--------- PERMISSION --------*/

  const add = (e, index) => {
    let res = [...viewPermissionData];
    res[index].IsAdd = e.target.checked ? 1 : 0;
    setViewPermissionData(res);
  };

  const edit = (e, index) => {
    let res = [...viewPermissionData];
    res[index].IsEdit = e.target.checked ? 1 : 0;
    setViewPermissionData(res);
  };

  const view = (e, index) => {
    let res = [...viewPermissionData];
    res[index].IsView = e.target.checked ? 1 : 0;
    setViewPermissionData(res);
  };

  const statusF = (e, index) => {
    let res = [...viewPermissionData];
    res[index].IsStatus = e.target.checked ? 1 : 0;
    setViewPermissionData(res);
  };

  const deleteF = (e, index) => {
    let res = [...viewPermissionData];
    res[index].IsDelete = e.target.checked ? 1 : 0;
    setViewPermissionData(res);
  };

  /*--------- TOASTER --------*/

  const columns = [
    {
      name: "S. No",
      selector: (_, index) => index + 1,
    },

    {
      name: "Module Name",
      selector: (row) => row.ModuleName.toUpperCase(),
      allowOverflow: true,
    },
    {
      name: (
        <div style={{display: "flex"}}>
          <input
            style={{ height: "20px", width: "20px" }}
            type="checkbox"
            checked={
              viewPermissionData.every((module) => module.IsAdd === 1) &&
              viewPermissionData.length > 0
            }
            onChange={(e) => {
              viewPermissionData.forEach((module, index) => {
                add(e, index);
              });
            }}
          />
          &nbsp;&nbsp;<span style={{marginTop : "15px" }}>Add</span> 
        </div>
      ),
      ignoreRowClick: true,
      cell: (row, index) => (
        <div>
          <input
            className="perBox"
            type="checkbox"
            checked={row.IsAdd == 1 ? true : false}
            onChange={(e) => add(e, index)}
          />
        </div>
      ),
    },
    {
      name: (
        <div style={{display: "flex"}}>
          <input
            style={{ height: "20px", width: "20px" }}
            type="checkbox"
            checked={
              viewPermissionData.every((module) => module.IsEdit === 1) &&
              viewPermissionData.length > 0
            }
            onChange={(e) => {
              viewPermissionData.forEach((module, index) => {
                edit(e, index);
              });
            }}
          />{" "}
            &nbsp;&nbsp;<span style={{marginTop : "15px" }}>Edit</span> 
        </div>
      ),
      selector: (row, index) => (
        <div>
          <input
            className="perBox"
            type="checkbox"
            checked={row.IsEdit == 1 ? true : false}
            onChange={(e) => edit(e, index)}
          />
        </div>
      ),
    },
    {
      name: (
        <div style={{display: "flex"}}>
          <input
            style={{ height: "20px", width: "20px" }}
            type="checkbox"
            checked={
              viewPermissionData.every((module) => module.IsView === 1) &&
              viewPermissionData.length > 0
            }
            onChange={(e) => {
              viewPermissionData.forEach((module, index) => {
                view(e, index);
              });
            }}
          />{" "}
                      &nbsp;&nbsp;<span style={{marginTop : "15px" }}>View</span>

        </div>
      ),
      selector: (row, index) => (
        <div>
          <input
            className="perBox"
            type="checkbox"
            checked={row.IsView == 1 ? true : false}
            onChange={(e) => view(e, index)}
          />
        </div>
      ),
    },
    {
      name: (
        <div style={{display: "flex"}}>
          <input
            style={{ height: "20px", width: "20px" }}
            type="checkbox"
            checked={
              viewPermissionData.every((module) => module.IsStatus === 1) &&
              viewPermissionData.length > 0
            }
            onChange={(e) => {
              viewPermissionData.forEach((module, index) => {
                statusF(e, index);
              });
            }}
          />{" "}
                      &nbsp;&nbsp;<span style={{marginTop : "15px" }}>Status</span> 

        </div>
      ),
      selector: (row, index) => (
        <div>
          <input
            className="perBox"
            type="checkbox"
            checked={row.IsStatus == 1 ? true : false}
            onChange={(e) => statusF(e, index)}
          />
        </div>
      ),
    },
    {
      name: (
        <div style={{display: "flex"}}>
          <input
            style={{ height: "20px", width: "20px" }}
            type="checkbox"
            checked={
              viewPermissionData.every((module) => module.IsDelete === 1) &&
              viewPermissionData.length > 0
            }
            onChange={(e) => {
              viewPermissionData.forEach((module, index) => {
                deleteF(e, index);
              });
            }}
          />{" "}
                      &nbsp;&nbsp;<span style={{marginTop : "15px" }}>Delete</span> 
        </div>
      ),
      selector: (row, index) => (
        <div>
          <input
            className="perBox"
            type="checkbox"
            checked={row.IsDelete == 1 ? true : false}
            onChange={(e) => deleteF(e, index)}
          />
        </div>
      ),
    },
  ];

  /*--------- SEARCH --------*/

  const searchInput = (e) => {
    if (e.length > 0) {
      const res = viewPermissionData.filter((f) =>
        f.ModuleName.toLowerCase().includes(e.toLowerCase())
      );
      setActive(true);
      setFilterUser(res);
    } else {
      setActive(false);
    }
  };

  const viewRole = async () => {
    viewCase.append("AdminID", localStorage.getItem("id"));
    try {
      const response = await fetch(`${baseUrl}Master/ViewRole`, {
        method: "POST",
        body: viewCase,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }

      if (data && data.result[0].message == "Success") {
        setViewRoleFilter(data && data.result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const data =
      viewRoleFilter &&
      viewRoleFilter
        .filter((item) => item.Status === "1")
        .map((item) => ({
          ...item,
         
          RoleName: item.RoleName,
      
        }));
    setViewRoleData(data);
  }, [viewRoleFilter]);

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    }
    window.scrollTo(0, 0);
    viewRole();
  }, []);

  return (
    <>
      <DocumentTitle title="PERMISSION | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  <div className="pageContent">
                    <div className="PageTitle">
                      <h3>Add Permission</h3>
                    </div>
                    <div className="permission_flex caseMobile">
                      <div className="form_flex " style={{ width: "22rem" }}>
                        <div className="form-group from_permission">
                          <label htmlFor="uname">Role</label>
                          <select
                            name="cars"
                            id="cars"
                            className="RolePer"
                            onChange={(e) => permissionViewRole(e.target.value)}
                            required
                          >
                            <option value="">--Role Select--</option>
                            {viewRoleData &&
                              viewRoleData.map((val, index) => (
                                <option key={index} value={val.Id}>
                                  {val.RoleName}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <input
                        type="search"
                        className="searchBox"
                        placeholder="Search Module"
                        onChange={(e) => searchInput(e.target.value)}
                      />
                    </div>
                    <div className="searDesign">
                      <DataTable
                        columns={columns}
                        data={active ? filterUser : viewPermissionData}
                        highlightOnHover
                        noDataComponent="Data Not Found"
                        pointerOnHover
                        responsive
                        striped
                        customStyles={customStyles}
                      />
                    </div>
                    <div className="btn_permission">
                      <button
                        style={{
                          display: viewPermissionData.length ? "block" : "none",
                        }}
                        type="button"
                        className="btn btn-primary btn_client btn_view"
                        data-toggle="modal"
                        data-target="#exampleModalLong"
                        onClick={() => submitHandler()}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <Footer/>
            </div>

            {/*--------- TOASTER --------*/}

            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        )}
      </DocumentTitle>
    </>
  );
};

export default Permission;
