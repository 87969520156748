import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  FaBars,
  FaTasks,
  FaCalculator,
  FaFileInvoice,
  FaUsers,
  FaUser,
  FaUserPlus,
  FaUserTie,
  FaRupeeSign,
} from "react-icons/fa";
import * as MdIcons from "react-icons/md";
import { RiArrowUpSFill } from "react-icons/ri";
import {
  AiFillHome,
  AiFillProfile,
  AiOutlineAccountBook,
  AiOutlineSetting,
} from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import { FaFileInvoiceDollar } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import { RiArrowDownSFill } from "react-icons/ri";
import SubMenu from "./SubMenu";
import { HiDocumentReport } from "react-icons/hi";
import { IconContext } from "react-icons/lib";
import { AiTwotoneLock } from "react-icons/ai";
import { GrConfigure } from "react-icons/gr";
import * as IoIcons from "react-icons/io";
import { TbSpeakerphone } from "react-icons/tb";
import { BsPinFill, BsReceipt } from "react-icons/bs";
import { BiRupee, BiTask, BiSolidBank, BiTransfer, BiSolidReport } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { GrTemplate, GrNotes } from "react-icons/gr";
import { MdAccountCircle } from "react-icons/md";
import { GiScales } from "react-icons/gi";
import { BsFillEnvelopeFill, BsCalendar3 } from "react-icons/bs";
import { useContext } from "react";
import { url } from "../index";
import { IoMdAnalytics } from "react-icons/io";
import { RiUserSettingsFill } from "react-icons/ri";
import { useEffect } from "react";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { IoDocumentSharp } from "react-icons/io5";
import { CgTemplate } from "react-icons/cg";
import { ImProfile, ImUserCheck } from "react-icons/im";

import { MdManageAccounts, MdPayments } from "react-icons/md";
import { TbCalendarTime } from "react-icons/tb";
const iconMap = {
  IoIosBookmark: <IoIcons.IoIosBookmark />,
  IoIosPaper: <IoIcons.IoIosPaper />,
  MdManageAccounts: <MdIcons.MdManageAccounts />,
  TbSpeakerphone: <TbSpeakerphone />,
  BsPinFill: <BsPinFill />,
  BiRupee: <BiRupee />,
  BsFillEnvelopeFill: <BsFillEnvelopeFill />,
  GrTemplate: <GrTemplate />,
  GrNotes: <GrNotes />,
  MdPayments: <MdPayments />,
  LiaFileInvoiceDollarSolid: <LiaFileInvoiceDollarSolid />,
  BiTask: <BiTask />,
  TbCalendarTime: <TbCalendarTime />,
  FaCalculator: <FaCalculator />,
  FaRupeeSign: <FaRupeeSign />,
  CgTemplate: <CgTemplate />,
  FaTasks: <FaTasks />,
  GrConfigure: <GrConfigure />,
  RiUserSettingsFill: <RiUserSettingsFill />,
  FaUsers: <FaUsers />,
  FaUserTie: <FaUserTie />,
  FaUserPlus: <FaUserPlus />,
  FaFileInvoice: <FaFileInvoice />,
  BsReceipt: <BsReceipt />,
  MdManageAccounts: <MdManageAccounts />,
  BsCalendar3: <BsCalendar3 />,
  IoDocumentSharp: <IoDocumentSharp />,
  MdAccountCircle: <MdAccountCircle />,
  FaUser: <FaUser />,
  AiOutlineSetting: <AiOutlineSetting />,
  BiSolidBank: <BiSolidBank />,
  BiTransfer: <BiTransfer />,
  ImProfile: <ImProfile />,
  GiScales: <GiScales />,
  BiSolidReport:<BiSolidReport/>,
  IoMdAnalytics: <IoMdAnalytics />,
  HiDocumentReport :<HiDocumentReport />,
  FaFileInvoiceDollar: <FaFileInvoiceDollar/>
};
const Nav = styled.div`
  background: #fffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 12px;
  z-index: 1;
`;

const NavIcon = styled(Link)`
  margin-left: 1rem;
  font-size: 2rem;
  display: flex;
  justify-content: end;
  align-items: center;
`;

const SidebarNav = styled.nav`
  overflow:scroll,
  background: #ffff;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
 position :fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 9;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const Sidebar = () => {
  const navigate = useNavigate();

  const handleLogOut = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };
  const [sidebar, setSidebar] = useState(false);
  const [data, setData] = useState([]);
  const [moduleData, setModuleData] = useState([]);
  const [SidebarData, setSidebarData] = useState([]);
  const showSidebar = () => setSidebar(!sidebar);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const toggleSidebar1 = () => {
    setIsSidebarOpen(isSidebarOpen);
  };
  const permissionForm = new FormData();
  const baseUrl = useContext(url);

  const getModule = async () => {
    permissionForm.append("UserID", localStorage.getItem("UserId"));
    try {
      const response = await fetch(
        `${baseUrl}Permission/GetPermissionByUserId`,
        {
          method: "POST",
          body: permissionForm,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        }
      );
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result && Array.isArray(data.result)) {
        setModuleData(data.result);
      } else {
        console.error("Invalid module data format");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      getModule();
    }
  }, []);

  useEffect(() => {
    if (moduleData && Array.isArray(moduleData) && moduleData.length > 0) {
      const dynamicSidebarData = [];

      dynamicSidebarData.push({
        title: "Dashboard",
        icon: <AiTwotoneLock />,
        path: "/home",
        icon: <AiFillHome />,
      });

      if (localStorage.getItem("admin") == "true") {
        dynamicSidebarData.push({
          title: "Manage Permission",
          icon: <AiTwotoneLock />,
          iconClosed: <RiArrowDownSFill />,
          iconOpened: <RiArrowUpSFill />,
          subNav: [
            {
              title: "Permission",
              path: "/view-permission",
              icon: <IoIcons.IoIosPaper />,
            },
          ],
        });
      }

      dynamicSidebarData.push(
        ...moduleData.map((item) => {
          if (item.isSubmenu > 0) {
            const subNavItems = item.subNav.map((subNavItem) => ({
              title: subNavItem.moduleName,
              path: subNavItem.path,
              icon: iconMap[subNavItem.icon],
            }));

            return {
              title: item.title,
              iconClosed: <RiArrowDownSFill />,
              iconOpened: <RiArrowUpSFill />,
              subNav: subNavItems,
              icon: iconMap[item.icon],
            };
          } else {
            const subNav = item.subNav[0];
            return {
              title: item.title,
              icon: iconMap[item.icon],
              path: subNav.path,
            };
          }
        })
      );

      if (localStorage.getItem("admin") == "true") {
        dynamicSidebarData.push({
          title: "My Account",
          icon: <ImUserCheck />,
          iconClosed: <RiArrowDownSFill />,
          iconOpened: <RiArrowUpSFill />,
          subNav: [
            {
              title: "My Profile",
              path: "/view-my-profile",
              icon: <ImProfile />,
            },
          ],
        });
      }

      if (localStorage.getItem("admin") == "false") {
        dynamicSidebarData.push({
          title: "My Account",
          icon: <ImUserCheck />,
          iconClosed: <RiArrowDownSFill />,
          iconOpened: <RiArrowUpSFill />,
          subNav: [
            {
              title: "My Profile",
              path: "/view-profile",
              icon: <ImProfile />,
            },
            {
              title: "Manage Attendance",
              path: "/view-attendance",
              icon: <ImProfile />,
            },
            {
              title: "Payment History",
              path: "/view-payment-history",
              icon: <MdPayments />,
            },
          ],
        });
      }

      setSidebarData(dynamicSidebarData);
    }
  }, [moduleData]);

  return (
    <>
      <div>
        <IconContext.Provider value={{ color: "#000" }}>
          <div className="navbarmy">
            <Nav>
              <NavIcon to="#">
                <img
                  className="BarsImage"
                  src="assets/img/icons/menu.svg"
                  onClick={showSidebar}
                />
              </NavIcon>
            </Nav>

            <SidebarNav className="customsidenav" sidebar={sidebar}>
              <SidebarWrap>
                <NavIcon to="#">
                  <AiOutlineClose
                    onClick={showSidebar}
                    style={{ height: "25px", width: "25px" }}
                  />
                </NavIcon>

                {SidebarData.map((item, index) => {
                  return <SubMenu item={item} key={index} />;
                })}

                <subNav>
                  <div className="logoutbn">
                    <button
                      className="logoutbtn"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalLogOutM"
                      style={{
                        display: "flex",
                        backgroundColor: "#ffffff",
                        color: "#810002",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "15px 10px",
                        listStyle: "none",
                        border: "none",
                        gap: "18px",
                        height: "40px",
                        borderRadius: "10px",
                        textDecoration: "none",
                        fontSize: " 16px",
                        margin: "0 0 6px",
                      }}
                      // onClick={handleLogOut}
                    >
                      <span className="logoutlogo">
                        <IoIcons.IoIosLogOut />
                      </span>
                      Logout
                    </button>
                  </div>
                </subNav>
                <div
                  className="modal fade sc_modal"
                  id="exampleModalLogOutM"
                  tabIndex={-1}
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        />
                      </div>
                      <div className="modal-body">
                        <h4>Logout</h4>
                        <p>Are you sure you want to Logout ?</p>
                        <ul>
                          <li>
                            <a
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </a>
                          </li>
                          <li>
                            <a
                              className="btn btn-danger"
                              onClick={() => handleLogOut()}
                            >
                              Logout
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </SidebarWrap>
            </SidebarNav>
          </div>
        </IconContext.Provider>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Sidebar;
