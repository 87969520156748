import React, { useState } from "react";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import "react-quill/dist/quill.snow.css";
import { formatDate1, withUidApi, pdfSplit } from "../services/api.service";
import { FaFileAlt, FaImage } from "react-icons/fa";
import { useParams } from "react-router-dom";
import {
  deleteUser,
  userDetails,
  activeStatus,
  permission,
  getClient,
} from "../services/api.service";
import DocumentTitle from "react-document-title";
import Footer from "./Footer";

const DocumentCD = () => {
  const { id } = useParams();
  const [saveButton, setSaveButton] = useState(true);

  var modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],

      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ["blockquote", "code-block"],
      ["link", "image", "video"],
      [{ direction: "rtl" }],
      [{ font: [] }],

      ["clean"][{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
    ],
  };

  var formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size",
    "background",
    "script",
    "video",
  ];
  const handleProcedureContentChange = (content) => {
    setDDescription(content);
  };

  const handleEdit = (content) => {
    setEditDescription(content);
  };

  // pdf

  const currentDate = new Date().toISOString().split("T")[0];
  const closeButton = document.querySelector(".btn_popup");
  const closeEdit = document.querySelector(".e_popup");
  const closeDelete = document.querySelector(".d_document");
  const closeView = document.querySelector(".ab");
  const [caseData, setCaseData] = useState("");
  const baseUrl = useContext(url);
  const navigate = useNavigate();
  const [optionsCD, setOptionsCD] = useState("");
  const [fClientOption, setFClientOption] = useState([]);
  const [optionsEvent, setOptionsEvent] = useState([]);
  const [data, setData] = useState([]);
  // Add Document State
  const [Template, setTemplate] = useState([]);
  const [DocumetData, setDocumetData] = useState([]);
  const [dCaseID, setDCaseID] = useState("");
  const [dDate, setDDate] = useState(currentDate);
  const [dFileName, setDFileName] = useState("");
  const [dDocTemplateID, setDDocTemplateID] = useState("");
  const [dDescription, setDDescription] = useState("");
  const [loading, setLoading] = useState(true);

  // edit Document State

  const [editId, setEditId] = useState("");
  const [editCaseId, setEditCaseId] = useState("");
  const [editDate, setEditDate] = useState("");
  const [editfileName, setEditFileName] = useState("");
  const [editDocTemplateID, setEditDocTemplateID] = useState("");
  const [editDescription, setEditDescription] = useState("");
  // View Document State
  const [title, setTitle] = useState("");
  const [editTitle, setEditTitle] = useState("");
  const [delId, setDelId] = useState("");

  // Document Detail state

  const [dTitle, setDTitle] = useState("");
  const [CaseIDs, setCaseID] = useState("");
  const [Dates, setDates] = useState("");
  const [FileName, setFileName] = useState("");
  const [FileNos, setFileNos] = useState("");
  const [dFileUpload, setDFileUpload] = useState("");
  const [DocTemplateID, setDocTemplateID] = useState("");
  const [Descriptions, setDescriptions] = useState("");
  const [CaseName, setCaseName] = useState("");
  const [ClientName, setClientName] = useState("");
  const [ClientlastName, setClientlastName] = useState("");
  const [CounselName, setCounselName] = useState("");
  const [CoCounselName, setCoCounselName] = useState("");
  const [editTempId, setEditTempId] = useState();
  const formData = new FormData();
  const CaseID = new FormData();
  const ViewCDData = new FormData();
  const editDocument = new FormData();

  /** Add Document */

  const getCaseHistory = async () => {
    ViewCDData.append("ID", id);
    ViewCDData.append("AdminID", localStorage.getItem("id"));

    try {
      const response = await fetch(
        `${baseUrl}CaseHistory/ViewCaseHistoryById`,
        {
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
          body: ViewCDData,
        }
      );

      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }

      setOptionsCD(data && data.result[0]);
      setCaseData(data && data.result[0].case[0]);
    } catch (error) {
      console.error(error);
    }
  };

  const getDocumentView = async () => {
    const [view, data, DocTemplateData, cdata] = await Promise.all([
      withUidApi("Document/ViewDocument"),
      permission(9),
      getClient("Document/DocumentTemplatedList"),
      getClient("Client/ViewClient"),
      getFileNo(),
      setLoading(true),
    ]);

    if (view === null) {
      setLoading(false);
    } else {
      if (view === "token") {
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        setLoading(false);
        setData(view);
        setDocumetData(view);
      }
    }

    setTemplate(DocTemplateData);

    const clientData = cdata && cdata.filter((item) => item.Status === "1");

    const Mapped =
      clientData &&
      clientData.map((item) => ({
        value: item.Id,
        label: `${item.FirstName} ${item.LastName}`,
      }));
    setFClientOption(Mapped);

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }
  };

  const details = async (id) => {
    const data = await userDetails(id, "Document/ViewDocumentById");
    if (data === "token") {
      setTimeout(() => {
        closeView.click();
        navigate("/");
      }, 3000);
    } else {
      setFileNos(data && data.result[0].FileNo);
      setDTitle(data && data.result[0].Title);
      setCaseID(data && data.result[0].CaseNo);
      setCaseName(data && data.result[0].CaseName);
      setClientName(data && data.result[0].ClientName);
      setClientlastName(data && data.result[0].ClientlastName);
      setCounselName(data && data.result[0].Counsel);
      setCoCounselName(data && data.result[0].CoCounsel);
      setFileName(data && data.result[0].FileName);
      setDocTemplateID(data && data.result[0].DocTemplate);
      setEditTempId(data && data.result[0].DocTemplateID);
      setDates(data && data.result[0].Date);
      setDescriptions(data && data.result[0].Description);
      setEditDescription(data && data.result[0].Description);
    }
  };

  const deleteDocument = async () => {
    const data = await deleteUser(delId, "Document/DeleteDocument");
    if (data === "token") {
      setTimeout(() => {
        closeDelete.click();
        navigate("/");
      }, 3000);
    }

    if (data === true) {
      closeDelete.click();
      getCaseHistory();
    }
  };

  const deActive = async (row) => {
    const data = await activeStatus(row, "Document/DocumentStatus");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }

    if (data === true) {
      getCaseHistory();
    }
  };

  const getById = async (id) => {
    const data = await userDetails(id, "Document/ViewDocumentById");
    if (data === "token") {
      setTimeout(() => {
        closeEdit.click();
        navigate("/");
      }, 3000);
    } else {
      setEditId(data && data.result[0].Id);
      setEditTitle(
        data && data.result[0].Title === null ? "" : data.result[0].Title
      );
      setEditCaseId(data && data.result[0].CaseID);
      setEditDate(data && data.result[0].Date);
      setEditFileName(data && data.result[0].FileName);
      setEditDocTemplateID(data && data.result[0].DocTemplateID);
      setEditDescription(data && data.result[0].Description);
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    setSaveButton(false);
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    formData.append("CaseID", id);
    formData.append("FileUpload", dFileUpload);
    formData.append("Title", title);
    formData.append("Date", dDate);
    formData.append("DocTemplateID", dDocTemplateID);
    formData.append("Description", dDescription);
    try {
      const response = await fetch(`${baseUrl}Document/AddDocument`, {
        method: "POST",
        body: formData,
        headers: {
          contentType: "multipart/form-data",
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].message === "Success") {
        toast.success("Document added successfully");
        getCaseHistory();
        closeButton.click();
        setDCaseID("");
        setTitle("");
        setDDate(currentDate);
        setDDescription("");
        setDDocTemplateID("");
        setDFileName("");
        setSaveButton(true);
      } else {
        toast.error(data.result.message);
        setSaveButton(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /** Edit Document */

  const submitEditHandler = async (event) => {
    event.preventDefault();
    editDocument.append("ID", editId);
    editDocument.append("AdminID", localStorage.getItem("id"));
    editDocument.append("UpdatedBy", localStorage.getItem("UserId"));
    editDocument.append("CaseID", id);
    editDocument.append("Date", editDate);
    editDocument.append("Title", editTitle);
    editDocument.append("DocTemplateID", editDocTemplateID);
    editDocument.append("Description", editDescription);
    try {
      const response = await fetch(`${baseUrl}Document/EditDocument`, {
        method: "POST",
        body: editDocument,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeEdit.click();
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].code === "200") {
        toast.success(" Updated successfully");
        getCaseHistory();
        closeEdit.click();
      } else {
        toast.error(data.result[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /** Get File Name  */
  const [optionsCase, setOptionsCase] = useState([]);

  const getFileNo = async () => {
    CaseID.append("AdminID", localStorage.getItem("id"));
    CaseID.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: CaseID,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].message === "Success") {
        const Mapped = data.result.map((item) => ({
          value: item.CaseID,
          label: `${item.FileNo} / ${item.CaseNo} / ${item.CaseName} / ${item.CaseYear}`,
        }));
        setOptionsCase(Mapped);
        setOptionsEvent(Mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownload = (fileUrl, fileName) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.target = "_blank";
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDoctemp = (e) => {
    setEditDocTemplateID(e.target.value);
    setDDocTemplateID(e.target.value);
    const getTempDetails = async () => {
      CaseID.append("AdminID", localStorage.getItem("id"));
      CaseID.append("ID", e.target.value);

      try {
        const response = await fetch(`${baseUrl}Master/ViewDocTemplateById`, {
          method: "POST",
          body: CaseID,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        });
        const data = await response.json();
        if (data && data.result.message == "Session Time Expire.") {
          toast.error("Token expired please login again");
          setTimeout(() => {
            navigate("/");
          }, 3000);
        }
        if (data && data.result[0].code == 200) {
          setEditDescription(data.result[0].Description);
          setDDescription(data.result[0].Description);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getTempDetails();
  };

  // File Upload By Disha (25-11-23)
  const [base64Data, setBase64Data] = useState(null);
  const handleFileChange = (event) => {
    // Access the selected file from the input
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setDFileUpload(reader.result);
        setBase64Data(reader.result);
      };
      reader.readAsDataURL(file);
    }
    setDFileUpload(file);
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getDocumentView();
    }
  }, []);

  useEffect(() => {
    getCaseHistory();
    getFileNo();
  }, []);

  return (
    <>
      <DocumentTitle title=" DOCUMENT | LEGAL CRM">
        <div className="wraper">
          <Header />
          <div className="mainWraper">
            <Sidebar />
            <div className="pageBody">
              <div className="pageContent">
                <div className="PageTitle">
                  <h3>Case Detail</h3>
                  <a
                    href={`#/view-casedetails/${id}`}
                    type="button"
                    className="btn btn-primary btn_client"
                  >
                    Back
                  </a>
                </div>
                <br />
                {/* <div className="caseMobile"> */}
                <div className="caseDetail">
                  <div className="form-group Details">
                    <h4>Case Name : </h4>
                    <p>{caseData.CaseName === "" ? "-" : caseData.CaseName}</p>
                  </div>
                  <div className="form-group Details">
                    <h4>Case No. : </h4>
                    <p>
                      {caseData.CaseNo === "" || caseData.CaseNo === null
                        ? "-"
                        : caseData.CaseNo}
                    </p>
                  </div>

                  <div className="form-group Details">
                    <h4>Case Year : </h4>
                    <p>
                      {caseData.CaseYear === "" || caseData.CaseYear === null
                        ? "-"
                        : caseData.CaseYear}
                    </p>
                  </div>

                  <div className="form-group Details">
                    <h4>Court Name : </h4>
                    <p>
                      {caseData.CourtName === "" || caseData.CourtName === null
                        ? "-"
                        : caseData.CourtName}
                    </p>
                  </div>
                  <div className="form-group Details">
                    <h4>Case Type :</h4>
                    <p>
                      {caseData.CaseType === "" || caseData.CaseType === null
                        ? "-"
                        : caseData.CaseType}
                    </p>
                  </div>
                  <div className="form-group Details">
                    <h4>File No. : </h4>
                    <p>
                      {caseData.FileNo === "" || caseData.FileNo === null
                        ? "-"
                        : caseData.FileNo}
                    </p>
                  </div>

                  <div className="form-group Details">
                    <h4>Diary No. : </h4>
                    <p>
                      {caseData.DiaryNo === "" || caseData.DiaryNo === null
                        ? "-"
                        : caseData.DiaryNo}
                    </p>
                  </div>
                  <div className="form-group Details">
                    <h4>Diary Year: </h4>
                    <p>
                      {caseData.DiaryYear === "" || caseData.DiaryYear === null
                        ? "-"
                        : caseData.DiaryYear}
                    </p>
                  </div>

                  <div className="form-group Details">
                    <h4>Client Name : </h4>
                    <p>
                      {caseData.ClientName === "" ||
                      caseData.ClientName === null
                        ? "-"
                        : caseData.ClientName}
                    </p>
                  </div>
                  <div className="form-group Details">
                    <h4>Client Last Name : </h4>
                    <p>
                      {caseData.ClientLastName === "" ||
                      caseData.ClientLastName === null
                        ? "-"
                        : caseData.ClientLastName}
                    </p>
                  </div>
                  <div className="form-group Details">
                    <h4>Company Name : </h4>
                    <p>
                      {caseData.CompanyName === "" ||
                      caseData.CompanyName === null
                        ? "-"
                        : caseData.CompanyName}
                    </p>
                  </div>

                  <div className="form-group Details">
                    <h4>Client Mobile No. : </h4>

                    <p>
                      {caseData.MobileNo === "" || caseData.MobileNo === null
                        ? "-"
                        : caseData.MobileNo}
                    </p>
                  </div>
                  <div className="form-group Details">
                    <h4>Phone : </h4>
                    <p>
                      {caseData.Phone === "" || caseData.Phone === null
                        ? "-"
                        : caseData.Phone}
                    </p>
                  </div>
                  <div className="form-group Details">
                    <h4>Email :</h4>
                    <p>
                      {caseData.Email === "" || caseData.Email === null
                        ? "-"
                        : caseData.Email}
                    </p>
                  </div>
                </div>
                <br />
                <div className="report_crmgraph">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="myCard">
                        <div className="cardHeader">
                          <h4>Document</h4>
                          <a
                            //   style={{
                            //     display: isAdd === "0" ? "none" : "block",
                            //   }}
                            type="button"
                            className="btn btn-primary btn_client headbtn"
                            data-toggle="modal"
                            data-target="#exampleModalLong"
                          >
                            Add Document
                            <img src="assets/img/icons/add.svg" />
                          </a>
                          <a href="/#/view-document" className="rytContent">
                            <img
                              src="assets/img/icons/dots.png"
                              alt=""
                              title="All Document"
                            />
                          </a>
                        </div>
                        {optionsCD.documents &&
                        optionsCD.documents[0].message === "Success" ? (
                          <div className="cardBody">
                            <table
                              className="myTable"
                              style={{ tableLayout: "fixed" }}
                            >
                              <thead>
                                <th>Action</th>
                                <th>Date</th>
                                <th>Title</th>
                                <th>Added By</th>
                              </thead>
                              {optionsCD.documents &&
                              optionsCD.documents.length > 0 ? (
                                optionsCD.documents.map((val, index) => (
                                  <tr
                                    key={index}
                                    className={
                                      index % 2 === 0 ? "row-even" : "row-odd"
                                    }
                                  >
                                    <td>
                                      <div
                                        style={{ display: "flex", gap: "15px" }}
                                      >
                                        {/* {isEdit !== "0" && ( */}
                                        <span
                                          type="button"
                                          data-toggle="modal"
                                          data-target="#exampleModalLongEdit"
                                          onClick={() => getById(val.Id)}
                                        >
                                          <img
                                            src="assets/img/icons/edit.svg"
                                            alt="Edit"
                                            title="Edit"
                                          />
                                        </span>
                                        {/* )} */}

                                        <span
                                          type="button"
                                          data-toggle="modal"
                                          data-target="#exampleModalLongDetail"
                                          onClick={() => details(val.Id)}
                                        >
                                          <img
                                            src="assets/img/icons/eye.svg"
                                            alt="Details"
                                            title="Details"
                                          />
                                        </span>
                                        <span>
                                          <a
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              deActive(
                                                val,
                                                "Expenses/ExpensesStatus"
                                              )
                                            }
                                          >
                                            {val.Status === "1" ? (
                                              <img
                                                className="thumb_icon"
                                                src="assets/img/icons/Up.svg"
                                                title="Status"
                                              />
                                            ) : (
                                              <img
                                                className="thumb_icon"
                                                src="assets/img/icons/Down.svg"
                                                title="Status"
                                              />
                                            )}
                                          </a>
                                        </span>
                                        <span
                                          data-bs-toggle="modal"
                                          data-bs-target="#exampleModal"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => setDelId(val.Id)}
                                        >
                                          <img
                                            src="assets/img/icons/delete.svg"
                                            alt="Delete"
                                            title="Delete"
                                          />
                                        </span>
                                        <div
                                          title="File"
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "18px",
                                          }}
                                          onClick={() =>
                                            handleDownload(
                                              `https://api.lawyerhunt.in/uploaded/documents/${val.FileUpload}`,
                                              val.fileName
                                            )
                                          }
                                        >
                                          {val.FileUpload ? (
                                            pdfSplit(val.FileUpload) ===
                                            "pdf" ? (
                                              <FaFileAlt />
                                            ) : (
                                              <FaImage />
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                        {/* )} */}
                                      </div>
                                    </td>
                                    <td>{formatDate1(val.Date)}</td>
                                    <td>
                                      {val.Title === null || val.Title === ""
                                        ? "-"
                                        : val.Title}{" "}
                                    </td>
                                    <td>
                                      {val.AddedBy === null ||
                                      val.AddedBy === ""
                                        ? "-"
                                        : val.AddedBy}{" "}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <div></div>
                              )}
                            </table>
                          </div>
                        ) : (
                          <p className="case_detail_table">Data not found</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </DocumentTitle>

      <div
        className="modal fade sc_modal"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close d_document"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <h4>Delete Row</h4>
              <p>Are you sure you want to delete document ?</p>
              <ul>
                <li>
                  <a className="btn btn-secondary" data-bs-dismiss="modal">
                    Cancel
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-danger"
                    onClick={() => deleteDocument(delId)}
                  >
                    Delete
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Add User Form */}
      <div
        className="modal fade"
        id="exampleModalLong"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg"
          role="document"
          // style={{ maxWidth: "50%" }}
        >
          <div className="modal-content">
            <div className="add_wrap">
              <div className="main_heading">
                <h2>Add Document</h2>
              </div>
            </div>
            <div className="popup_modal ">
              <button
                type="button"
                className="close btn_popup"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="dashed_popup">
                <form role="form" onSubmit={submitHandler}>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="username">
                        File No. <text className="m_star">*</text>
                      </label>
                      <select disabled id="city" required>
                        {optionsCase &&
                          optionsCase.map((val, index) => (
                            <option
                              key={index}
                              value={val.value}
                              selected={val.value === id}
                            >
                              {val.label}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="uname">
                        Date<text className="m_star">*</text>
                      </label>
                      <input
                        type="date"
                        placeholder=""
                        name="uname"
                        value={dDate}
                        onChange={(e) => setDDate(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="username">Title</label>
                      <input
                        className="name"
                        type="text"
                        placeholder="Enter Title"
                        name="uname"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="username">
                        File Upload (JPEG,JPG,PDF,PNG)
                      </label>
                      <input
                        className="name"
                        type="file"
                        placeholder="Enter File"
                        name="uname"
                        // value={dFileUpload}
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="username">Use Template</label>
                      <a className="visuald" href="/#/view-documentTemplate">
                        +Add Template
                      </a>
                      <select name="cars" id="cars" onChange={handleDoctemp}>
                        <option>
                          --Please Select only when you want to change
                          template--
                        </option>
                        {Template && Template.length > 0 ? (
                          Template.map((val, index) => (
                            <option
                              key={index}
                              value={val.Id}
                              selected={val.Id === editDocTemplateID}
                            >
                              {val.DocTemplate}
                            </option>
                          ))
                        ) : (
                          <option value="">Loading...</option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <label htmlFor="username">Details</label>
                  </div>

                  <ReactQuill
                    value={dDescription == null ? "-" : dDescription}
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    placeholder="write your content ...."
                    onChange={handleProcedureContentChange}
                    style={{ height: "290px" }}
                  ></ReactQuill>
                  <br />
                  <br />
                  {saveButton ? (
                    <div className="btn_main caseMobile">
                      <button type="submit" className="btn_save">
                        Save
                      </button>
                      <button
                        type="button"
                        data-dismiss="modal"
                        className="btn_save btn_cancle"
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Document Form */}

      <div
        className="modal fade"
        id="exampleModalLongEdit"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg"
          role="document"
          // style={{ maxWidth: "50%" }}
        >
          <div className="modal-content">
            <div className="add_wrap">
              <div className="main_heading">
                <h2>Edit Document</h2>
              </div>
            </div>
            <div className="popup_modal ">
              <button
                type="button"
                className="close e_popup btn_popup"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="dashed_popup">
                <form role="form" onSubmit={submitEditHandler}>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="username">
                        File No.<text className="m_star">*</text>
                      </label>
                      <select disabled id="city" required>
                        {optionsCase &&
                          optionsCase.map((val, index) => (
                            <option
                              key={index}
                              value={val.value}
                              selected={val.value === id}
                            >
                              {val.label}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="uname">
                        Date<text className="m_star">*</text>
                      </label>
                      <input
                        type="date"
                        name="uname"
                        value={editDate}
                        onChange={(e) => setEditDate(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="username">Title</label>
                      <input
                        className="name"
                        type="text"
                        placeholder="Enter Title"
                        name="uname"
                        value={editTitle}
                        onChange={(e) => setEditTitle(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="username">Use Template </label>
                      <select name="cars" id="cars" onChange={handleDoctemp}>
                        <option>
                          --Please Select only when you want to change
                          template--
                        </option>
                        {Template && Template.length > 0 ? (
                          Template.map((val, index) => (
                            <option
                              key={index}
                              value={val.Id}
                              selected={val.Id == editDocTemplateID}
                            >
                              {val.DocTemplate}
                            </option>
                          ))
                        ) : (
                          <option value="">Loading...</option>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <label htmlFor="username">Details</label>
                  </div>
                  <br />
                  <ReactQuill
                    value={editDescription == null ? "-" : editDescription}
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    placeholder="write your content ...."
                    onChange={handleEdit}
                    style={{ height: "320px" }}
                  ></ReactQuill>
                  <br />
                  <br />

                  <div className="btn_main caseMobile">
                    <button type="submit" className="btn_save">
                      Save
                    </button>
                    <button
                      type="button"
                      data-dismiss="modal"
                      className="btn_save btn_cancle"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  Document Form */}
      <div
        className="modal fade"
        id="exampleModalLongDetail"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg"
          role="document"
          // style={{ maxWidth: "50%" }}
        >
          <div className="modal-content">
            <div className="add_wrap">
              <div className="main_heading">
                <h2>Document Details</h2>
              </div>
            </div>
            <div className="popup_modal ">
              <button
                type="button"
                className="close ab btn_popup"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="dashed_popup">
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>
                      Case &nbsp;:&nbsp; {CaseName == null ? "-" : CaseName}{" "}
                    </h4>
                  </div>

                  <div className="form-group">
                    <h4>
                      File No. &nbsp;: &nbsp; {FileNos == null ? "-" : FileNos}{" "}
                    </h4>
                  </div>
                </div>
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>
                      Client Name &nbsp;: &nbsp;{" "}
                      {ClientName == null || ClientName === ""
                        ? "-"
                        : ClientName}{" "}
                    </h4>
                  </div>
                  <div className="form-group">
                    <h4>
                      Client Last Name &nbsp;: &nbsp;{" "}
                      {ClientlastName === null || ClientlastName === ""
                        ? "-"
                        : ClientlastName}{" "}
                    </h4>
                  </div>
                </div>

                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>
                      Date &nbsp;: &nbsp;{" "}
                      {Dates === null || Dates === "0000-00-00"
                        ? "-"
                        : formatDate1(Dates)}{" "}
                    </h4>
                  </div>
                  <div className="form-group">
                    <h4>
                      Title &nbsp;: &nbsp; {dTitle === null ? "-" : dTitle}{" "}
                    </h4>
                  </div>
                </div>
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>
                      Template &nbsp;: &nbsp;{" "}
                      {DocTemplateID == null ? "-" : DocTemplateID}{" "}
                    </h4>
                  </div>
                </div>
                <div className="form_flex caseMobile">
                  <h4>Details</h4>
                </div>
                <br />
                <ReactQuill
                  value={editDescription == null ? "-" : editDescription}
                  theme="snow"
                  className="editorReact"
                  modules={modules}
                  formats={formats}
                  readOnly
                  style={{ height: "320px" }}
                ></ReactQuill>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DocumentCD;
