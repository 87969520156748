import React, { useState, useRef } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { Link, useParams } from "react-router-dom";
import Preloader from "./Preloader";
import DocumentTitle from "react-document-title";
import Footer from "./Footer";
import {
  formatDate1,
  getClient,
  deleteUser,
  userDetails,
  activeStatus,
  permission,
  withUidApi,
} from "../services/api.service";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import ReactQuill from "react-quill";
import Swal from "sweetalert2";

const ExpenseCD = () => {
  const [saveButton, setSaveButton] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);
  const { id } = useParams();
  //-------- CLOSE POPUP VARIABLE -------

  const closeButton = document.querySelector(".btn_popup");
  const closeEdit = document.querySelector(".e_popup");
  const closeView = document.querySelector(".ab");
  const closeDelete = document.querySelector(".d_expenses");
  const baseUrl = useContext(url);
  const navigate = useNavigate();
  const [isAdd, setIsAdd] = useState("0");
  const [isEdit, setIsEdit] = useState("0");
  const [isView, setIsView] = useState("0");
  const [isDelete, setIsDelete] = useState("0");
  const [isStatus, setIsStatus] = useState("0");
  const [data, setData] = useState([]);
  const handleKeyPress = (e) => {
    const charCode = e.which || e.keyCode;
    // Allow only numeric characters and backspace
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };
  //-------- ADD EXPENSES VARIABLE -------

  const currentDate = new Date().toISOString().split("T")[0];
  const [caseLeadData, setCaseLeadData] = useState([]);
  const [excel, setExcel] = useState([]);
  const [caseID, setCaseID] = useState("");
  const [expensesDetails, setExpensesDetails] = useState("");
  const [amount, setAmount] = useState("");
  const [status, setStatus] = useState("");
  const [date, setDate] = useState(currentDate);
  const [filterUser, setFilterUser] = useState("");
  const [delId, setDelId] = useState("");
  const [active, setActive] = useState(false);
  const [fCaseId, setFCaseId] = useState("");
  const [fFromDate, setFFromDate] = useState("");
  const filterform = new FormData();
  const [fToDate, setFToDate] = useState("");
  const [fClientID, setFClientID] = useState("");
  const [fClientOption, setFClientOption] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const [caseData, setCaseData] = useState("");
  const [loading, setLoading] = useState(true);

  //-------- EDIT EXPENSES VARIABLE -------

  const [editId, setEditId] = useState("");
  const [editDate, setEditDate] = useState("");
  const [editAmount, setEditAmount] = useState("");
  const [editExpensesDetails, setEditExpensesDetails] = useState("");
  const [editCaseID, setEditCaseID] = useState("");
  const formData = new FormData();
  const editCaseLead = new FormData();
  const form = new FormData();

  //----------- EXPENSES DETAIL VARIABLE ---------

  const [dAmount, setDAmount] = useState("");
  const [dCaseName, setDCaseName] = useState("");
  const [dCaseYear, setDCaseYear] = useState("");
  const [dClientName, setDClientName] = useState("");
  const [dCounselName, setDCounselName] = useState("");
  const [dDate, setDDate] = useState("");
  const [dExpensesDetails, setDExpensesDetails] = useState("");
  const [dFileNo, setDFileNo] = useState("");
  const [dCoCounselName, setDCoCounselName] = useState("");
  const [dClientLastName, setDClientLastName] = useState("");
  const [dClientCompany, setDClientCompany] = useState("");
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState("0");
  const ViewCDData = new FormData();
  const [optionsCD, setOptionsCD] = useState("");

  const getCaseHistory = async () => {
    ViewCDData.append("ID", id);
    ViewCDData.append("AdminID", localStorage.getItem("id"));

    try {
      const response = await fetch(
        `${baseUrl}CaseHistory/ViewCaseHistoryById`,
        {
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
          body: ViewCDData,
        }
      );

      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }

      setOptionsCD(data && data.result[0]);
      setCaseData(data && data.result[0].case[0]);
    } catch (error) {
      console.error(error);
    }
  };
  
  //-------- VIEW EXPENSES -------

  const withUidApi = async (path) => {
    form.append("AdminID", localStorage.getItem("id"));
    form.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}${path}`, {
        method: "POST",
        body: form,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        return "token";
      }

      if (data && data.result.Expenses[0].message === "Success") {
        return data.result.Expenses;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getExpensesView = async () => {
    const [view, data, cdata] = await Promise.all([
      withUidApi("Expenses/ViewExpenses "),
      permission(12),
      getClient("Client/ViewClient"),
      getFileNo(),
      setLoading(true),
    ]);

    if (view === null) {
      setLoading(false);
      setTotalInvoiceAmount("0");
      setCaseLeadData([]);
      setData([]);
    } else {
      if (view === "token") {
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        setLoading(false);
        setCaseLeadData(view);
        setData(view);

        let sum =
          view &&
          view.reduce(function (prev, current) {
            return prev + +current.Amount;
          }, 0);
        setTotalInvoiceAmount(sum);
      }
    }

    const clientData = cdata && cdata.filter((item) => item.Status === "1");

    const Mapped =
      clientData &&
      clientData.map((item) => ({
        value: item.Id,
        label: `${item.FirstName} ${item.LastName}`,
      }));
    setFClientOption(Mapped);

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }

    setIsAdd(data && data.result[0].IsAdd);
    setIsEdit(data && data.result[0].IsEdit);
    setIsView(data && data.result[0].IsView);
    setIsStatus(data && data.result[0].IsStatus);
    setIsDelete(data && data.result[0].IsDelete);
  };

  //-------- DELETE EXPENSES -------

  const deleteCaseLead = async () => {
    const data = await deleteUser(delId, "Expenses/DeleteExpenses");
    if (data === "token") {
      setTimeout(() => {
        closeDelete.click();
        navigate("/");
      }, 3000);
    }

    if (data === true) {
      closeDelete.click();
      getExpensesView();
      getCaseHistory();
    }
  };

  //-------- ACTIVE DEACTIVE EXPENSES -------

  const deActive = async (row) => {
    const data = await activeStatus(row, "Expenses/ExpensesStatus");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }

    if (data === true) {
      getExpensesView();
      getCaseHistory();
    }
  };

  /** Get File Name  */
  const Case = new FormData();
  const [optionsCase, setOptionsCase] = useState([]); // Initialize options state

  const getFileNo = async () => {
    Case.append("AdminID", localStorage.getItem("id"));
    Case.append("UserID", localStorage.getItem("uId"));

    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: Case,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }

      if (data && data.result[0].message === "Success") {
        const Mapped = data.result.map((item) => ({
          value: item.CaseID,
          label: `${item.FileNo} / ${item.CaseNo} / ${item.CaseName} / ${item.CaseYear}`,
        }));
        setOptionsCase(Mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //-------- DETEILS EXPENSES -------

  const details = async (id) => {
    const data = await userDetails(id, "Expenses/ViewExpensesById");
    if (data === "token") {
      setTimeout(() => {
        closeView.click();
        navigate("/");
      }, 3000);
    } else {
      setDAmount(data && data.result[0].Amount);
      setDCaseName(data && data.result[0].CaseName);
      setDCaseYear(data && data.result[0].CaseYear);
      setDClientName(data && data.result[0].ClientName);
      setDCounselName(data && data.result[0].CounselName);
      setDDate(data && data.result[0].Date);
      setDExpensesDetails(data && data.result[0].ExpensesDetails);
      setDFileNo(data && data.result[0].FileNo);
      setDCoCounselName(data && data.result[0].CoCounselName);
      setDClientLastName(data && data.result[0].ClientLastName);
      setDClientCompany(data && data.result[0].ClientCompany);
    }
  };

  //-------- DETAILS EXPENSES -------

  const getById = async (id) => {
    const data = await userDetails(id, "Expenses/ViewExpensesById");
    if (data === "token") {
      setTimeout(() => {
        closeEdit.click();
        navigate("/");
      }, 3000);
    } else {
      setEditId(data && data.result[0].Id);
      setEditExpensesDetails(data && data.result[0].ExpensesDetails);
      setEditAmount(data && data.result[0].Amount);
      setEditDate(data && data.result[0].Date);
      setEditCaseID(data && data.result[0].CaseID);
    }
  };

  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  //-------- ADD EXPENSES -------

  const submitHandler = async (event) => {
    event.preventDefault();
    setSaveButton(false);
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CaseID", id);
    formData.append("ExpensesDetails", expensesDetails.toUpperCase());
    formData.append("Amount", amount);
    formData.append("Date", date);
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    try {
      const response = await fetch(`${baseUrl}Expenses/AddExpenses`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].message === "Success") {
        toast.success("Expenses added successfully");
        closeButton.click();
        handleClose();
        getExpensesView();
        getCaseHistory();
        setDate(currentDate);
        setAmount("");
        setExpensesDetails("");
        setCaseID("");
        setSaveButton(true);
      } else {
        toast.error(data && data.result.message);
        setSaveButton(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //-------- EDIT EXPENSES -------

  const submitEditHandler = async (event) => {
    event.preventDefault();
    editCaseLead.append("ID", editId);
    editCaseLead.append("AdminID", localStorage.getItem("id"));
    editCaseLead.append("CaseID", id);
    editCaseLead.append("ExpensesDetails", editExpensesDetails.toUpperCase());
    editCaseLead.append("Amount", editAmount);
    editCaseLead.append("Date", editDate);
    editCaseLead.append("UpdatedBy", localStorage.getItem("UserId"));
    try {
      const response = await fetch(`${baseUrl}Expenses/EditExpenses`, {
        method: "POST",
        body: editCaseLead,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeEdit.click();
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].code === "200") {
        toast.success(" Updated successfully");
        closeEdit.click();
        getExpensesView();
        getCaseHistory();
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  let sum = 0;
  caseLeadData &&
    caseLeadData.map((val, index) => {
      sum += parseFloat(val.Amount);
    });

  useEffect(() => {
    getCaseHistory();
    getFileNo();
  }, []);

  return (
    <>
      <DocumentTitle title="EXPENSES | LEGAL CRM">
        <div className="wraper">
          <Header />
          <div className="mainWraper">
            <Sidebar />
            <div className="pageBody">
              <div className="pageContent">
                <div className="PageTitle">
                  <h3>Case Detail</h3>
                  <a
                    href={`#/view-casedetails/${id}`}
                    type="button"
                    className="btn btn-primary btn_client"
                  >
                    Back
                  </a>
                </div>
                <br />
                {/* <div className="caseMobile"> */}
                <div className="caseDetail">
                  <div className="form-group Details">
                    <h4>Case Name : </h4>
                    <p>{caseData.CaseName === "" ? "-" : caseData.CaseName}</p>
                  </div>
                  <div className="form-group Details">
                    <h4>Case No. : </h4>
                    <p>
                      {caseData.CaseNo === "" || caseData.CaseNo === null
                        ? "-"
                        : caseData.CaseNo}
                    </p>
                  </div>

                  <div className="form-group Details">
                    <h4>Case Year : </h4>
                    <p>
                      {caseData.CaseYear === "" || caseData.CaseYear === null
                        ? "-"
                        : caseData.CaseYear}
                    </p>
                  </div>

                  <div className="form-group Details">
                    <h4>Court Name : </h4>
                    <p>
                      {caseData.CourtName === "" || caseData.CourtName === null
                        ? "-"
                        : caseData.CourtName}
                    </p>
                  </div>
                  <div className="form-group Details">
                    <h4>Case Type :</h4>
                    <p>
                      {caseData.CaseType === "" || caseData.CaseType === null
                        ? "-"
                        : caseData.CaseType}
                    </p>
                  </div>
                  <div className="form-group Details">
                    <h4>File No. : </h4>
                    <p>
                      {caseData.FileNo === "" || caseData.FileNo === null
                        ? "-"
                        : caseData.FileNo}
                    </p>
                  </div>

                  <div className="form-group Details">
                    <h4>Diary No. : </h4>
                    <p>
                      {caseData.DiaryNo === "" || caseData.DiaryNo === null
                        ? "-"
                        : caseData.DiaryNo}
                    </p>
                  </div>
                  <div className="form-group Details">
                    <h4>Diary Year: </h4>
                    <p>
                      {caseData.DiaryYear === "" || caseData.DiaryYear === null
                        ? "-"
                        : caseData.DiaryYear}
                    </p>
                  </div>

                  <div className="form-group Details">
                    <h4>Client Name : </h4>
                    <p>
                      {caseData.ClientName === "" ||
                      caseData.ClientName === null
                        ? "-"
                        : caseData.ClientName}
                    </p>
                  </div>
                  <div className="form-group Details">
                    <h4>Client Last Name : </h4>
                    <p>
                      {caseData.ClientLastName === "" ||
                      caseData.ClientLastName === null
                        ? "-"
                        : caseData.ClientLastName}
                    </p>
                  </div>
                  <div className="form-group Details">
                    <h4>Company Name : </h4>
                    <p>
                      {caseData.CompanyName === "" ||
                      caseData.CompanyName === null
                        ? "-"
                        : caseData.CompanyName}
                    </p>
                  </div>

                  <div className="form-group Details">
                    <h4>Client Mobile No. : </h4>

                    <p>
                      {caseData.MobileNo === "" || caseData.MobileNo === null
                        ? "-"
                        : caseData.MobileNo}
                    </p>
                  </div>
                  <div className="form-group Details">
                    <h4>Phone : </h4>
                    <p>
                      {caseData.Phone === "" || caseData.Phone === null
                        ? "-"
                        : caseData.Phone}
                    </p>
                  </div>
                  <div className="form-group Details">
                    <h4>Email :</h4>
                    <p>
                      {caseData.Email === "" || caseData.Email === null
                        ? "-"
                        : caseData.Email}
                    </p>
                  </div>
                </div>
                <br />
                <div className="report_crmgraph">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="myCard">
                        <div className="cardHeader">
                          <h4>Expenses</h4>
                          <a
                            //   style={{
                            //     display: isAdd === "0" ? "none" : "block",
                            //   }}
                            type="button"
                            className="btn btn-primary btn_client headbtn"
                            data-toggle="modal"
                            data-target="#exampleModalLong"
                          >
                            Add Expenses
                            <img src="assets/img/icons/add.svg" />
                          </a>
                          <a href="/#/view-expenses" className="rytContent">
                            <img
                              src="assets/img/icons/dots.png"
                              alt=""
                              title="All Expenses"
                            />
                          </a>
                        </div>
                        {optionsCD.Expenses &&
                        optionsCD.Expenses[0].message === "Success" ? (
                          <div className="cardBody">
                            <table
                              className="myTable"
                              style={{ tableLayout: "fixed" }}
                            >
                              <thead>
                                <th>Action</th>
                                <th>Date </th>
                                <th>Amount </th>
                                <th>Expenses Details </th>
                              </thead>
                              {optionsCD.Expenses &&
                              optionsCD.Expenses.length > 0 ? (
                                optionsCD.Expenses.map((val, index) => (
                                  <tr
                                    key={index}
                                    className={
                                      index % 2 === 0 ? "row-even" : "row-odd"
                                    }
                                  >
                                    <td>
                                      <div
                                        style={{ display: "flex", gap: "15px" }}
                                      >
                                        {/* {isEdit !== "0" && ( */}
                                        <span
                                          type="button"
                                          data-toggle="modal"
                                          data-target="#exampleModalLongEdit"
                                          onClick={() => getById(val.Id)}
                                        >
                                          <img
                                            src="assets/img/icons/edit.svg"
                                            alt="Edit"
                                            title="Edit"
                                          />
                                        </span>
                                        {/* )} */}

                                        <span
                                          type="button"
                                          data-toggle="modal"
                                          data-target="#exampleModalLongDetail"
                                          onClick={() => details(val.Id)}
                                        >
                                          <img
                                            src="assets/img/icons/eye.svg"
                                            alt="Details"
                                            title="Details"
                                          />
                                        </span>
                                        <span>
                                          <a
                                            style={{
                                              cursor: "pointer",
                                              // display:
                                              //   isStatus === "0" ? "none" : "block",
                                            }}
                                            onClick={() =>
                                              deActive(
                                                val,
                                                "Expenses/ExpensesStatus"
                                              )
                                            }
                                          >
                                            {val.Status === "1" ? (
                                              <img
                                                className="thumb_icon"
                                                src="assets/img/icons/Up.svg"
                                                title="Status"
                                              />
                                            ) : (
                                              <img
                                                className="thumb_icon"
                                                src="assets/img/icons/Down.svg"
                                                title="Status"
                                              />
                                            )}
                                          </a>
                                        </span>
                                        {/* {isDelete !== "0" && ( */}
                                        <span
                                          data-bs-toggle="modal"
                                          data-bs-target="#exampleModal"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => setDelId(val.Id)}
                                        >
                                          <img
                                            src="assets/img/icons/delete.svg"
                                            alt="Delete"
                                            title="Delete"
                                          />
                                        </span>
                                        {/* )} */}
                                      </div>
                                    </td>
                                    <td>{formatDate1(val.Date)}</td>
                                    <td>
                                      {val.Amount === null || val.Amount === ""
                                        ? "-"
                                        : val.Amount}{" "}
                                    </td>
                                    <td>
                                      {val.ExpensesDetails === null ||
                                      val.ExpensesDetails === ""
                                        ? "-"
                                        : val.ExpensesDetails}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <div></div>
                              )}
                            </table>
                          </div>
                        ) : (
                          <p className="case_detail_table">Data not found</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </DocumentTitle>
      
      {/*-------- DELETE EXPENSES -----------*/}
      <div
        className="modal fade sc_modal"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close d_expenses"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <h4>Delete Row</h4>
              <p>Are you sure you want to delete Expenses ?</p>
              <ul>
                <li>
                  <a className="btn btn-secondary" data-bs-dismiss="modal">
                    Cancel
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-danger"
                    onClick={() => deleteCaseLead(delId)}
                  >
                    Delete
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/*-------- ADD EXPENSES FORM -----------*/}

      <div
        className="modal fade"
        id="exampleModalLong"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
        show={show}
      >
        <div
          className="modal-dialog modal-lg"
          role="document"
          // style={{ maxWidth: "50%" }}
        >
          <div className="modal-content">
            <div className="add_wrap">
              <div className="main_heading">
                <h2>Add Expenses</h2>
              </div>
            </div>
            <div className="popup_modal ">
              <button
                type="button"
                className="close btn_popup"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="dashed_popup">
                <form role="form" onSubmit={submitHandler} ref={formRef}>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="username">
                        File No. <text className="m_star">*</text>
                      </label>
                      <select disabled id="city" required>
                        {optionsCase &&
                          optionsCase.map((val, index) => (
                            <option
                              key={index}
                              value={val.value}
                              selected={val.value === id}
                            >
                              {val.label}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="uname">Date</label>
                      <input
                        className="name"
                        type="date"
                        placeholder=""
                        name="uname"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="username">Amount</label>
                      <input
                        className="name"
                        type="text"
                        onKeyPress={handleKeyPress}
                        placeholder="Enter Amount"
                        name="uname"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="username">Expenses Details</label>
                      <textarea
                        className="name"
                        type="text"
                        rows={10}
                        placeholder="Enter Details"
                        name="uname"
                        value={expensesDetails}
                        onChange={(e) => setExpensesDetails(e.target.value)}
                      />
                    </div>
                  </div>
                  {saveButton ? (
                    <div className="btn_main caseMobile">
                      <button type="submit" className="btn_save">
                        Save
                      </button>
                      <button
                        type="button"
                        data-dismiss="modal"
                        className="btn_save btn_cancle"
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*-------- EDIT EXPENSES FORM -----------*/}

      <div
        className="modal fade"
        id="exampleModalLongEdit"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg"
          role="document"
          // style={{ maxWidth: "50%" }}
        >
          <div className="modal-content">
            <div className="add_wrap">
              <div className="main_heading">
                <h2>Edit Expenses</h2>
              </div>
            </div>
            <div className="popup_modal ">
              <button
                type="button"
                className="close e_popup btn_popup"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="dashed_popup">
                <form role="form" onSubmit={submitEditHandler} ref={formRef}>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="username">
                        File No. <text className="m_star">*</text>
                      </label>
                      <select disabled id="city" required>
                        {optionsCase &&
                          optionsCase.map((val, index) => (
                            <option
                              key={index}
                              value={val.value}
                              selected={val.value === id}
                            >
                              {val.label}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="uname">Date</label>
                      <input
                        type="date"
                        placeholder=""
                        name="uname"
                        value={editDate}
                        onChange={(e) => setEditDate(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="username">Amount</label>
                      <input
                        type="number"
                        placeholder="Enter Amount"
                        name="uname"
                        value={editAmount}
                        onChange={(e) => setEditAmount(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="username">Expenses Details</label>
                      <textarea
                        type="text"
                        rows={10}
                        placeholder="Enter Details"
                        name="uname"
                        value={editExpensesDetails}
                        onChange={(e) => setEditExpensesDetails(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="btn_main caseMobile">
                    <button type="submit" className="btn_save">
                      Save
                    </button>
                    <button
                      type="button"
                      data-dismiss="modal"
                      className="btn_save btn_cancle"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*-------- Expenses Details ----------*/}

      <div
        className="modal fade"
        id="exampleModalLongDetail"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg"
          role="document"
          // style={{ maxWidth: "50%" }}
        >
          <div className="modal-content">
            <div className="add_wrap">
              <div className="main_heading">
                <h2>Expenses Details</h2>
              </div>
            </div>
            <div className="popup_modal ">
              <button
                type="button"
                className="close ab btn_popup"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>

              <div className="dashed_popup">
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>
                      File No. &nbsp;: &nbsp; {dFileNo == null ? "-" : dFileNo}{" "}
                    </h4>
                  </div>
                  <div className="form-group">
                    <h4>
                      Date &nbsp;:&nbsp;{" "}
                      {dDate == "0000-00-00" ? "-" : formatDate1(dDate)}{" "}
                    </h4>
                  </div>
                </div>
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>
                      Amount &nbsp;: &nbsp; {dAmount == null ? "-" : dAmount}{" "}
                    </h4>
                  </div>
                  <div className="form-group">
                    <h4>
                      Case Year &nbsp;:&nbsp;{" "}
                      {dCaseYear == "0000" ? "-" : dCaseYear}{" "}
                    </h4>
                  </div>
                </div>
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>
                      Case Name &nbsp;: &nbsp;{" "}
                      {dCaseName == null ? "-" : dCaseName}{" "}
                    </h4>
                  </div>
                  <div className="form-group">
                    <h4>
                      Client Name &nbsp;:&nbsp;{" "}
                      {dClientName == null ? "-" : dClientName}{" "}
                    </h4>
                  </div>
                </div>
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>
                      Client Last Name &nbsp;: &nbsp;{" "}
                      {dClientLastName == null ? "-" : dClientLastName}{" "}
                    </h4>
                  </div>
                  <div className="form-group">
                    <h4>
                      Client Company &nbsp;:&nbsp;{" "}
                      {dClientCompany == null ? "-" : dClientCompany}{" "}
                    </h4>
                  </div>
                </div>
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>
                      Counsel Name &nbsp;: &nbsp;{" "}
                      {dCounselName == null ? "-" : dCounselName}{" "}
                    </h4>
                  </div>
                  <div className="form-group">
                    <h4>
                      Co Counsel Name &nbsp;:&nbsp;{" "}
                      {dCoCounselName == null ? "-" : dCoCounselName}{" "}
                    </h4>
                  </div>
                </div>
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>
                      Expenses Details &nbsp;: &nbsp;{" "}
                      {dExpensesDetails == null ? "-" : dExpensesDetails}{" "}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpenseCD;
