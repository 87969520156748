import React, { useState, useRef } from "react";
import autoTable from "jspdf-autotable";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { clearForm, formateDateTime } from "../services/api.service";
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  viewData,
  deleteUser,
  userDetails,
  activeStatus,
  permission,
  getFileNo,
  customStyles,
  getClient,
} from "../services/api.service";
import Preloader from "./Preloader";
import DocumentTitle from "react-document-title";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";
import jsPDF from "jspdf";
import Footer from "./Footer";

const Bank = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  //        -------- POPUP CLOSE VARIABLE ---------

  const [open, setOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const closeButton = document.querySelector(".btn_popup");
  const closeView = document.querySelector(".ab");
  const closeEdit = document.querySelector(".e_popup");
  const closeDelete = document.querySelector(".mm");

  //        -------- ADD VARIABLE ---------
  const [isValid1, setIsValid1] = useState(true);

  const HandleAccChange = (e) => {
    const value = e.target.value;
    const isValidInput = /^\d{14}$/.test(value);

    setAccountNo(value);
    setIsValid1(isValidInput);
  };

  const handleKeyPress1 = (e) => {
    const charCode = e.which || e.keyCode;
    if (
      charCode < 48 ||
      charCode > 57
      // charCode !== 8
    ) {
      e.preventDefault();
    }
  };
  const handleKeyPressPan = (e) => {
    const charCode = e.which || e.keyCode;
    const inputValue = e.target.value + String.fromCharCode(charCode);

    if (
      (charCode >= 65 && charCode <= 90) ||
      (charCode >= 48 && charCode <= 57) ||
      (charCode >= 97 && charCode <= 122) ||
      charCode === 8 // Backspace
    ) {
      if (inputValue.length > 10) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };
  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setIFSCCode(newValue);
  };
  const handleInputChangeEdit = (e) => {
    const newValue = e.target.value;
    setEditIFSCCode(newValue);
  };
  const handleInputChange1 = (e) => {
    const newValue = e.target.value;
    setPanNo(newValue);
  };
  const handleInputChange1Edit = (e) => {
    const newValue = e.target.value;
    setEditPanNo(newValue);
  };
  const handleKeyPress = (e) => {
    const charCode = e.which || e.keyCode;
    const inputValue = e.target.value + String.fromCharCode(charCode);

    if (
      (charCode >= 48 && charCode <= 57) ||
      (charCode >= 65 && charCode <= 90) ||
      (charCode >= 97 && charCode <= 122) ||
      charCode === 8 // Backspace
    ) {
      if (inputValue.length > 11) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }

    const ifscInput = e.target.value;
    if (ifscInput.length < 11) {
      document.getElementById("error-message").textContent =
        "IFSC code must be 11 characters long";
    } else {
      document.getElementById("error-message").textContent = "";
    }
  };

  const [radioValue, setRadioValue] = useState("");

  const handleRadioChange = (event) => {
    const newValue1 = parseInt(event.target.value);
    setRadioValue(newValue1);
  };
  const handleRadioChange1 = (event) => {
    const newValue = parseInt(event.target.value);
    setEditRadioValue(newValue);
  };
  const baseUrl = useContext(url);
  const [excel, setExcel] = useState([]);
  const navigate = useNavigate();
  const [Bank, setBank] = useState("");
  const [Branch, setBranch] = useState("");
  const [AccountNo, setAccountNo] = useState("");
  const [IFSCCode, setIFSCCode] = useState("");
  const [PanNo, setPanNo] = useState("");

  const [Beneficiary, setBeneficiary] = useState("");

  //        --------  EDIT  VARIABLE ---------
  const [EditBank, setEditBank] = useState("");
  const [EditBranch, setEditBranch] = useState("");
  const [EditAccountNo, setEditAccountNo] = useState("");
  const [EditIFSCCode, setEditIFSCCode] = useState("");
  const [EditPanNo, setEditPanNo] = useState("");
  const [EditradioValue, setEditRadioValue] = useState("");
  const [EditBeneficiary, setEditBeneficiary] = useState("");

  const [dBank, setDBank] = useState("");
  const [dradioValue, setDradioValue] = useState("");
  const [dBranch, setDBranch] = useState("");
  const [dAccountNo, setDAccountNo] = useState("");
  const [dIFSCCode, setDIFSCCode] = useState("");
  const [dPanNo, setDPanNo] = useState("");
  const [dBeneficiary, setDBeneficiary] = useState("");
  const [EditID, setEditID] = useState("");
  const [user, setuser] = useState([]);
  const [isAdd, setIsAdd] = useState("0");
  const [isEdit, setIsEdit] = useState("0");
  const [isView, setIsView] = useState("0");
  const [isDelete, setIsDelete] = useState("0");
  const [isStatus, setIsStatus] = useState("0");
  const [caseLeadData, setCaseLeadData] = useState([]);
  const [filterUser, setFilterUser] = useState("");
  const [delId, setDelId] = useState("");
  const [active, setActive] = useState(false);
  const formData = new FormData();
  const formEditData = new FormData();
  const [loading, setLoading] = useState(true);

  //        -------- VIEW TASK DATA ---------

  const getCaseView = async () => {
    const [view, data, userData] = await Promise.all([
      viewData("Master/ViewBank"),
      permission(17),
      getClient("Admin/ViewUser"),
      setLoading(true),
    ]);

    if (view === null) {
      setLoading(false);
    } else {
      if (view === "token") {
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        setLoading(false);
        setCaseLeadData(view);
      }
    }

    setuser(userData);

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }

    setIsAdd(data && data.result[0].IsAdd);
    setIsEdit(data && data.result[0].IsEdit);
    setIsView(data && data.result[0].IsView);
    setIsStatus(data && data.result[0].IsStatus);
    setIsDelete(data && data.result[0].IsDelete);
  };

  //        -------- DELETE TASK API ---------

  const deleteCaseLead = async () => {
    const data = await deleteUser(delId, "Master/DeleteBank");
    if (data === "token") {
      setTimeout(() => {
        closeDelete.click();
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      closeDelete.click();
      getCaseView();
    }
  };

  //        -------- ACTIVE DEACTIVE ---------

  const deActive = async (row) => {
    const data = await activeStatus(row, "Master/BankStatus");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      getCaseView();
    }
  };

  //        -------- TASK DETAILS DATA ---------

  const details = async (id) => {
    const data = await userDetails(id, "Master/ViewBankById");
    if (data === "token") {
      setTimeout(() => {
        closeView.click();
        navigate("/");
      }, 3000);
    } else {
      setEditID(data.result[0].Id);
      setDBank(data.result[0].Bank);
      setDBranch(data.result[0].Branch);
      setDAccountNo(data.result[0].AccountNo);
      setDIFSCCode(data.result[0].IFSCCode);
      setDPanNo(data.result[0].PanNo);
      setDradioValue(data.result[0].IsDefault);
      setDBeneficiary(data.result[0].Beneficiary);
    }
  };

  //        -------- TASK DATA ---------

  const getById = async (id) => {
    const data = await userDetails(id, "Master/ViewBankById");
    if (data === "token") {
      setTimeout(() => {
        closeEdit.click();
        navigate("/");
      }, 3000);
    } else {
      setEditID(data.result[0].Id);
      setEditBank(data.result[0].Bank);
      setEditBranch(data.result[0].Branch);
      setEditAccountNo(data.result[0].AccountNo);
      setEditIFSCCode(data.result[0].IFSCCode);
      setEditPanNo(data.result[0].PanNo);
      setEditRadioValue(data.result[0].IsDefault);
      setEditBeneficiary(data.result[0].Beneficiary);
    }
  };

  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  //        -------- SUBMIT TASK DATA ---------

  const submitHandler = async (event) => {
    event.preventDefault();
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("Beneficiary", Beneficiary.toUpperCase());
    formData.append("Bank", Bank.toUpperCase());
    formData.append("Branch", Branch.toUpperCase());
    formData.append("AccountNo", AccountNo);
    formData.append("IFSCCode", IFSCCode.toUpperCase());
    formData.append("PanNo", PanNo.toUpperCase());
    formData.append("IsDefault", "0");
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    try {
      const response = await fetch(`${baseUrl}Master/AddBank`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 3000);
      }
      if (data.result.length && data.result[0].code == 200) {
        toast.success("Bank Account added successfully");
        handleClose();
        clearForm();
        getCaseView();

        closeButton.click();
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        -------- EDIT TASK API ---------

  const submitEditHandler = async (event) => {
    event.preventDefault();
    formEditData.append("ID", EditID);
    formEditData.append("AdminID", localStorage.getItem("id"));
    formEditData.append("Beneficiary", EditBeneficiary.toUpperCase());
    formEditData.append("Bank", EditBank.toUpperCase());
    formEditData.append("Branch", EditBranch.toUpperCase());
    formEditData.append("AccountNo", EditAccountNo);
    formEditData.append("IFSCCode", EditIFSCCode.toUpperCase());
    formEditData.append("PanNo", EditPanNo.toUpperCase());
    formEditData.append("IsDefault", EditradioValue);
    formEditData.append("UpdatedBy", localStorage.getItem("UserId"));
    try {
      const response = await fetch(`${baseUrl}Master/EditBank`, {
        method: "POST",
        body: formEditData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeEdit.click();
          navigate("/");
        }, 3000);
      }
      if (data.result.length && data.result[0].code == 200) {
        toast.success(" Updated successfully");
        closeEdit.click();
        clearForm();
        getCaseView();
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        -------- TABLE ---------

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      header: "Action",
      minSize: "10px",
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      Cell: (row) => {
        return (
          <>
            <div style={{ display: "flex", gap: "15px" }}>
              {isEdit !== "0" && (
                <span
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalLongEdit"
                  onClick={() => getById(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/edit.svg"
                    alt="Edit"
                    title="Edit"
                  />
                </span>
              )}

              <span
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongDetail"
                onClick={() => details(row.row.original.Id)}
              >
                <img
                  src="assets/img/icons/eye.svg"
                  alt="Details"
                  title="Details"
                />
              </span>
              <span>
                <a
                  style={{
                    cursor: "pointer",
                    display: isStatus === "0" ? "none" : "block",
                  }}
                  onClick={() => deActive(row.row.original)}
                >
                  {row.row.original.Status === "1" ? (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Up.svg"
                      title="Status"
                    />
                  ) : (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Down.svg"
                      title="Status"
                    />
                  )}
                </a>
              </span>
              {isDelete !== "0" && (
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style={{ cursor: "pointer" }}
                  onClick={() => setDelId(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/delete.svg"
                    alt="Delete"
                    title="Delete"
                  />
                </span>
              )}
            </div>
          </>
        );
      },
    }),

    columnHelper.accessor("AccountNo", {
      header: "Account No",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.AccountNo === null ||
            row.row.original.AccountNo === ""
              ? "-"
              : row.row.original.AccountNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("Bank", {
      header: "Bank",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Bank === null || row.row.original.Bank === ""
              ? "-"
              : row.row.original.Bank}
          </div>
        );
      },
    }),

    columnHelper.accessor("Beneficiary", {
      header: "Beneficiary",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Beneficiary === null ||
            row.row.original.Beneficiary === ""
              ? "-"
              : row.row.original.Beneficiary}
          </div>
        );
      },
    }),
    columnHelper.accessor("Branch", {
      header: "Branch",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Branch === null || row.row.original.Branch === ""
              ? "-"
              : row.row.original.Branch}
          </div>
        );
      },
    }),

    columnHelper.accessor("IFSCCode", {
      header: "IFSC Code",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.IFSCCode === null ||
            row.row.original.IFSCCode === ""
              ? "-"
              : row.row.original.IFSCCode}
          </div>
        );
      },
    }),

    columnHelper.accessor("PanNo", {
      header: "Pan No",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.PanNo === null || row.row.original.PanNo === ""
              ? "-"
              : row.row.original.PanNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("UpdatedDate", {
      header: "Updated Date",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.UpdatedDate === null ||
            row.row.original.UpdatedDate === "0000-00-00"
              ? "-"
              : formateDateTime(row.row.original.UpdatedDate)}
          </div>
        );
      },
    }),

    columnHelper.accessor("Status", {
      header: "Status",
      Cell: (row) => {
        return (
          <div>{row.row.original.Status === "1" ? "ACTIVE" : "DEACTIVE"}</div>
        );
      },
    }),

    columnHelper.accessor("AddedBy", {
      header: "Added By",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.AddedBy === null ||
            row.row.original.AddedBy === ""
              ? "-"
              : row.row.original.AddedBy}
          </div>
        );
      },
    }),
  ];
  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getCaseView();
    }
  }, []);

  const excelData =
    caseLeadData &&
    caseLeadData.map((obj) => {
      const { Id, code, IsDefault, message, Status, AdminID, ...rest } = obj;
      return rest;
    });

  const handleExportData = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });

    const formattedDate = new Date().toISOString().slice(0, 10);
    const filename = `Bank|LegalCRM__${formattedDate}.csv`;

    const csv = generateCsv(csvConfig)(excelData);
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const columnsToExport = [
      { id: "serialNo", header: "S No." },
      ...columns.filter((column) => selectedColumns.includes(column.id)),
    ];

    const tableHeaders = columnsToExport.map((c) => c.header);

    const formattedDate = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const filename = `Bank|LegalCRM_${formattedDate}.pdf`;
    const addWatermarkToFirstPage = () => {
      const watermarkHeight = 20;

      doc.setFontSize(10);
      doc.text(
        10,
        watermarkHeight + -10,
        `Generated by : ${localStorage.getItem("name")} `
      );
      doc.text(172, watermarkHeight + -10, `Date: ${formattedDate}`);
      doc.setFontSize(16);
      doc.text("Bank Table", 84, 20);
    };
    const watermarkHeight = 20;

    addWatermarkToFirstPage();
    const tableData = rows.map((row, index) => {
      return [
        { content: (index + 1).toString(), styles: { fontStyle: "bold" } },
        ...columnsToExport.slice(1).map((column) => {
          const value =
            column.id in row.original ? row.original[column.id] : "";
          return value !== null && value !== undefined ? value : "";
        }),
      ];
    });

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY: watermarkHeight + 5,
    });

    // Save the PDF to a Blob
    const pdfBlob = doc.output("blob");

    // Create a blob URL
    const blobUrl = URL.createObjectURL(pdfBlob);

    // Open the URL in a new tab
    window.open(blobUrl, "_blank");

    // Optionally revoke the object URL after opening the new tab
    URL.revokeObjectURL(blobUrl);
    // doc.save(filename);
  };
  const handleExportRow1 = (rows) => {
    const doc = new jsPDF();
    const tableData = rows.map((row) => Object.values(row.original));
    const tableHeaders = columns.map((c) => c.header);

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save("mrt-pdf-example.pdf");
  };
  const [selectedColumnIds, setSelectedColumnIds] = useState([]);
  // Function to handle column selection
  const handleColumnSelection = (columnId) => {
    // Check if the column ID is already selected
    if (selectedColumnIds.includes(columnId)) {
      // If selected, remove it from the array
      setSelectedColumnIds(selectedColumnIds.filter(id => id !== columnId));
    } else {
      // If not selected, add it to the array
      setSelectedColumnIds([...selectedColumnIds, columnId]);
    }
  };
  
  const handleToggleColumn = (columnName) => {
    const currentIndex = selectedColumns.indexOf(columnName);
    const newSelectedColumns = [...selectedColumns];

    if (currentIndex === -1) {
      newSelectedColumns.push(columnName);
    } else {
      newSelectedColumns.splice(currentIndex, 1);
    }

    setSelectedColumns(newSelectedColumns);
  };

  const handleExportButtonClick = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };
  // ---------DataTable-----
  const table = useMaterialReactTable({
    columns,
    data: caseLeadData,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    initialState: {
      columnVisibility: {
        AddedBy: false,
        UpdatedBy: false,
        UpdatedDate: false,
        CreatedDate: false,
      },
    },
    state: {
      isLoading: loading,
    },
    muiCircularProgressProps: {
      color: "primary",
      thickness: 5,
      size: 55,
      style: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000,
      },
    },
    muiSkeletonProps: {
      animation: "pulse",
      height: 20,
    },
    enableStickyHeader: true,
    enableRowNumbers: true,
    rowNumberDisplayMode: "original",
    muiPaginationProps: {
      rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export Excel
        </Button>
         <Button
        disabled={!table || table.getPrePaginationRowModel().rows.length === 0}
        onClick={handleExportButtonClick}
        startIcon={<FileDownloadIcon />}
      >
        Export PDF
      </Button>
      <Dialog open={open} onClose={handleClose1}>
        <DialogTitle>Select Columns To Export</DialogTitle>
        <DialogContent>
            {columns.map((column) =>
              column.header !== "Action" ? (
                <div key={column.id}>
                  <Checkbox
                    checked={selectedColumns.includes(column.id)}
                    onChange={() => handleToggleColumn(column.id)}
                  />
                  {column.header}
                </div>
              ) : (
                ""
              )
            )}
          </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1}>Cancel</Button>
          <Button onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}>Export</Button>
        </DialogActions>
      </Dialog>
      </Box>

    ),
  });
  return (
    <>
      <DocumentTitle title="BANK | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  <div className="pageContent">
                    <div className="PageTitle">
                      <h3>Bank List</h3>

                      <a
                        style={{
                          display: isAdd === "0" ? "none" : "block",
                        }}
                        type="button"
                        className="btn btn-primary btn_client headbtn"
                        data-toggle="modal"
                        data-target="#exampleModalLong"
                      >
                        Add Bank
                        <img src="assets/img/icons/add.svg" />
                      </a>
                    </div>

                    <div
                      style={{
                        display: isView === "0" ? "none" : "block",
                      }}
                      className="searDesign"
                    >
                      <MaterialReactTable table={table} 
        onColumnSelection={handleColumnSelection}/>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>

            {/*-------- DELETE  FORM -------*/}

            <div
              className="modal fade sc_modal"
              id="exampleModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close mm"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <h4>Delete Row</h4>
                    <p>Are you sure you want to delete Bank account ?</p>
                    <ul>
                      <li>
                        <a
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </a>
                      </li>
                      <li>
                        <a
                          className="btn btn-danger"
                          onClick={() => deleteCaseLead(delId)}
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- ADD FORM ---------*/}

            <div
              className="modal fade"
              id="exampleModalLong"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
              show={show}
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Add Bank Account </h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Account Holder Name{" "}
                              <text className="m_star">*</text>
                            </label>
                            <input
                              className="name"
                              type="text"
                              placeholder="Enter Account Holder Name"
                              name="uname"
                              // value={Beneficiary}
                              onChange={(e) => setBeneficiary(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">Bank </label>
                            <input
                              className="name"
                              type="text"
                              name="uname"
                              placeholder="Enter Bank"
                              // value={Bank}
                              onChange={(e) => setBank(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              IFSC Code <text className="m_star">*</text>
                            </label>
                            <input
                              className="name"
                              onKeyPress={handleKeyPress}
                              type="text"
                              placeholder="Enter IFSC Code"
                              name="uname"
                              // value={IFSCCode}
                              onChange={handleInputChange}
                              // onChange={(e) => setIFSCCode(e.target.value)}
                              required
                            />
                            <div
                              id="error-message"
                              style={{ color: "red" }}
                            ></div>
                          </div>

                          <div className="form-group">
                            <label htmlFor="uname">
                              Account No. <text className="m_star">*</text>
                            </label>
                            <input
                              required
                              className="name"
                              type="text"
                              onKeyPress={handleKeyPress1}
                              name="uname"
                              placeholder="Enter Account NO"
                              // value={AccountNo}
                              onChange={HandleAccChange}
                            />
                            {!isValid1 && (
                              <p style={{ color: "red" }}>
                                Please enter a valid Account No.
                              </p>
                            )}

                            {/* {!isValid && <p className="visuald">Invalid account number. Account No. must be  </p>} */}
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">Pan No </label>
                            <input
                              className="name"
                              onKeyPress={handleKeyPressPan}
                              type="text"
                              name="uname"
                              placeholder="Enter Pan No"
                              // value={PanNo}
                              onChange={handleInputChange1}
                              // onChange={(e) => setPanNo(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              Branch
                              <text className="m_star">*</text>
                            </label>
                            <input
                              className="name"
                              type="text"
                              placeholder="Enter Branch"
                              name="uname"
                              // value={Beneficiary}
                              onChange={(e) => setBranch(e.target.value)}
                              required
                            />
                          </div>
                        </div>

                        <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_save btn_cancle"
                            onClick={clearForm}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- EDIT FORM ---------*/}

            <div
              className="modal fade"
              id="exampleModalLongEdit"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Edit Bank Account</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close e_popup btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitEditHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Account Holder Name{" "}
                              <text className="m_star">*</text>
                            </label>
                            <input
                              className="name"
                              type="text"
                              placeholder="Enter Account Holder Name"
                              name="uname"
                              value={EditBeneficiary}
                              onChange={(e) =>
                                setEditBeneficiary(e.target.value)
                              }
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">Bank </label>
                            <input
                              className="name"
                              type="text"
                              name="uname"
                              placeholder="Enter Bank"
                              value={EditBank}
                              onChange={(e) => setEditBank(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              IFSC Code <text className="m_star">*</text>
                            </label>
                            <input
                              className="name"
                              onKeyPress={handleKeyPress}
                              type="text"
                              placeholder="Enter IFSC Code"
                              name="uname"
                              value={EditIFSCCode}
                              onChange={handleInputChangeEdit}
                              // onChange={(e) => setEditIFSCCode(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              Account No. <text className="m_star">*</text>
                            </label>
                            <input
                              className="name"
                              onKeyPress={handleKeyPress1}
                              required
                              type="text"
                              name="uname"
                              placeholder="Enter Account NO"
                              value={EditAccountNo}
                              onChange={(e) => setEditAccountNo(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">Pan No </label>
                            <input
                              className="name"
                              type="text"
                              onKeyPress={handleKeyPressPan}
                              name="uname"
                              placeholder="Enter Pan No"
                              value={EditPanNo}
                              onChange={handleInputChange1Edit}
                              // onChange={(e) => setEditPanNo(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">Branch</label>
                            <input
                              className="name"
                              type="text"
                              name="uname"
                              placeholder="Enter Branch"
                              value={EditBranch}
                              onChange={(e) => setEditBranch(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_save btn_cancle"
                            onClick={clearForm}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- DETAILS ---------*/}

            <div
              className="modal fade"
              id="exampleModalLongDetail"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Bank Details</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close ab btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Account Holder Name &nbsp;: &nbsp;{" "}
                            {dBeneficiary === null || dBeneficiary === ""
                              ? "-"
                              : dBeneficiary}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Bank &nbsp;:&nbsp;{" "}
                            {dBank === null || dBank === "" ? "-" : dBank}{" "}
                          </h4>
                        </div>
                      </div>

                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            IFSC Code &nbsp;: &nbsp;{" "}
                            {dIFSCCode === null || dIFSCCode === ""
                              ? "-"
                              : dIFSCCode}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Account No. &nbsp;:&nbsp;{" "}
                            {dAccountNo === null || dAccountNo === ""
                              ? "-"
                              : dAccountNo}{" "}
                          </h4>
                        </div>
                      </div>

                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Pan No. &nbsp;:&nbsp;{" "}
                            {dPanNo === null || dPanNo === "" ? "-" : dPanNo}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Branch &nbsp;:&nbsp;{" "}
                            {dBranch === null || dBranch === "" ? "-" : dBranch}{" "}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- TOASTER ---------*/}

            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        )}
      </DocumentTitle>
    </>
  );
};

export default Bank;
