import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import DocumentTitle from "react-document-title";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import {
  formatDate1,
  getClient,
  deleteUser,
  userDetails,
  activeStatus,
  permission,
  withUidApi,
} from "../services/api.service";

const EventCD = () => {
  //---------- POPUP CLOSE VARIABLE ----------
  const { id } = useParams();
  const currentDate = new Date().toISOString().split("T")[0];
  const closeButton = document.querySelector(".btn_popup");
  const closeEdit = document.querySelector(".e_popup");
  const closeDelete = document.querySelector(".d_event");
  const closeView = document.querySelector(".ab");
  const baseUrl = useContext(url);
  const navigate = useNavigate();
  const [caseData, setCaseData] = useState("");

  //---------- ADD EVENT VARIABLE ----------

  const [caseLeadData, setCaseLeadData] = useState([]);
  const [eventDetails, setEventDetails] = useState("");
  const [isEffective, setIsEffective] = useState("");
  const [isUpcoming, setIsUpcoming] = useState("");
  const [nextDate, setNextDate] = useState(currentDate);
  const [caseID, setCaseID] = useState("");
  const [fCaseId, setFCaseId] = useState("");
  const [fFromDate, setFFromDate] = useState("");
  const filterform = new FormData();
  const [fToDate, setFToDate] = useState("");
  const [fClientID, setFClientID] = useState("");
  const [fClientOption, setFClientOption] = useState([]);
  const [date, setDate] = useState(currentDate);
  const [fIsOpen, setFIsOpen] = useState("1");
  const [delId, setDelId] = useState("");
  const [active, setActive] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [dStatus, setDStatus] = useState("");
  const [dIsEffective, setDIsEffective] = useState("");
  const [dDate, setDDate] = useState("");
  const [dNextDate, setDNextDate] = useState("");
  const [dEventDetails, setDEventDetails] = useState("");
  const [dIsUpcoming, setDIsUpcoming] = useState("");
  const [dAddedBy, setDAddedBy] = useState("");
  const [dCaseName, setDCaseName] = useState("");
  const [dCaseNo, setDCaseNo] = useState("");
  const [dFileNo, setDFileNo] = useState("");
  const [dCaseYear, setDCaseYear] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  //---------- EDIT EVENT VARIABLE ----------

  const [editId, setEditId] = useState("");
  const [editDate, setEditDate] = useState("");
  const [editEventDetails, setEditEventDetails] = useState("");
  const [editIsEffective, setEditIsEffective] = useState("");
  const [editIsUpcoming, setEditIsUpcoming] = useState("");
  const [editNextDate, setEditNextDate] = useState("");
  const [editCaseID, setEditCaseID] = useState("");
  const formData = new FormData();
  const editCaseLead = new FormData();

  // ----------------------BY DISHA HALKARA ---------------------------------------------------

  const [saveButton, setSaveButton] = useState(true);
  const Case = new FormData();
  const [optionsEvent, setOptionsEvent] = useState([]);
  const getFileNo = async () => {
    Case.append("AdminID", localStorage.getItem("id"));
    Case.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: Case,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].message === "Success") {
        const Mapped = data.result.map((item) => ({
          value: item.CaseID,
          label: `${item.FileNo} / ${item.CaseNo} / ${item.CaseName} / ${item.CaseYear}`,
        }));
        setOptionsEvent(Mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getFileNo();
  }, []); /** Get Case History  */

  const ViewCDData = new FormData();
  const [optionsCD, setOptionsCD] = useState("");
  const getCaseHistory = async () => {
    ViewCDData.append("ID", id);
    ViewCDData.append("AdminID", localStorage.getItem("id"));

    try {
      const response = await fetch(
        `${baseUrl}CaseHistory/ViewCaseHistoryById`,
        {
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
          body: ViewCDData,
        }
      );

      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }

      setCaseData(data && data.result[0].case[0]);
      setOptionsCD(data && data.result[0]);

      //   setCID(data && data.result[0].case[0].Id);
    } catch (error) {
      console.error(error);
    }
  };
  //---------- VIEW EVENT DATA ----------
  const form = new FormData();

  const getEventView = async () => {
    const [data, cdata] = await Promise.all([
      permission(6),
      getClient("Client/ViewClient"),
      getFileNo(),
    ]);

    const clientData = cdata && cdata.filter((item) => item.Status === "1");

    const Mapped =
      clientData &&
      clientData.map((item) => ({
        value: item.Id,
        label: `${item.FirstName} ${item.LastName}`,
      }));
    setFClientOption(Mapped);

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }

    // setIsAdd(data && data.result[0].IsAdd);
    // setIsEdit(data && data.result[0].IsEdit);
    // setIsView(data && data.result[0].IsView);
    // setIsStatus(data && data.result[0].IsStatus);
    // setIsDelete(data && data.result[0].IsDelete);
  };

  //---------- DELETE EVENT ----------

  const deleteCaseLead = async () => {
    const data = await deleteUser(delId, "Event/DeleteEvent");
    if (data === "token") {
      setTimeout(() => {
        closeDelete.click();
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      closeDelete.click();
      getEventView();
      getCaseHistory();
      getFileNo();
    }
  };

  //---------- ACTIVE DEACTIVE EVENT ----------

  const deActive = async (row) => {
    const data = await activeStatus(row, "Event/EventStatus");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      getEventView();
      getCaseHistory();
      getFileNo();
    }
  };

  //---------- DETAILS EVENT ----------

  const details = async (id) => {
    const data = await userDetails(id, "Event/ViewEventById");
    if (data === "token") {
      setTimeout(() => {
        closeView.click();
        navigate("/");
      }, 3000);
    } else {
      setDDate(data && data.result[0].Date);
      setDEventDetails(data && data.result[0].EventDetails);
      setDIsEffective(data && data.result[0].IsEffective);
      setDIsUpcoming(data && data.result[0].IsUpcoming);
      setDNextDate(data && data.result[0].NextDate);
      setDStatus(data && data.result[0].Status);
      setDFileNo(data && data.result[0].FileNo);
      setDCaseNo(data && data.result[0].CaseNo);
      setDCaseYear(data && data.result[0].CaseYear);
      setDCaseName(data && data.result[0].CaseName);
      setDAddedBy(data && data.result[0].AddedBy);
    }
  };


  //---------- DETAILS EVENT ----------

  const getById = async (id) => {
    const data = await userDetails(id, "Event/ViewEventById");
    if (data === "token") {
      setTimeout(() => {
        closeEdit.click();
        navigate("/");
      }, 2000);
    } else {
      setEditId(data && data.result[0].Id);
      setEditCaseID(data && data.result[0].CaseID);
      setEditDate(data && data.result[0].Date);
      setEditEventDetails(data && data.result[0].EventDetails);
      setEditIsEffective(data && data.result[0].IsEffective);
      setEditIsUpcoming(data && data.result[0].IsUpcoming);
      setEditNextDate(data && data.result[0].NextDate);
    }
  };

  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  //---------- ADD EVENT ----------

  const submitHandler = async (event) => {
    event.preventDefault();
    setSaveButton(false);
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    formData.append("Date", date);
    formData.append("EventDetails", eventDetails.toUpperCase());
    formData.append("IsEffective", isEffective);
    formData.append("IsUpcoming", isUpcoming);
    formData.append("NextDate", nextDate);
    formData.append("CaseID", id);
    try {
      const response = await fetch(`${baseUrl}Event/AddEvent`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 2000);
      }

      if (data.result.length && data.result[0].message === "Success") {
        toast.success("Added Successfully");
        closeButton.click();
        handleClose();
        getEventView();
        getCaseHistory();
        getFileNo();
        setIsEffective("");
        setCaseID("");
        setDate(currentDate);
        setNextDate(currentDate);
        setEventDetails("");
        setIsUpcoming("");
        setSaveButton(true);
      } else {
        toast.error(data.result.message);
        setSaveButton(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //---------- EDIT  EVENT ----------

  const submitEditHandler = async (event) => {
    event.preventDefault();
    editCaseLead.append("ID", editId);
    editCaseLead.append("AdminID", localStorage.getItem("id"));
    editCaseLead.append("UpdatedBy", localStorage.getItem("UserId"));
    editCaseLead.append("Date", editDate);
    editCaseLead.append("EventDetails", editEventDetails.toUpperCase());
    editCaseLead.append("IsEffective", editIsEffective);
    editCaseLead.append("IsUpcoming", editIsUpcoming);
    editCaseLead.append("NextDate", editNextDate);
    editCaseLead.append("CaseID", id);
    try {
      const response = await fetch(`${baseUrl}Event/EditEvent`, {
        method: "POST",
        body: editCaseLead,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeEdit.click();
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].code == 200) {
        toast.success("Updated successfully");
        closeEdit.click();
        getEventView();
        getCaseHistory();
        getFileNo();
      } else {
        toast.error(data.result[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getCaseHistory();
    }
  }, []);

  return (
    <>
      <DocumentTitle title="EVENT | LEGAL CRM">

      <div className="wraper">
    <Header />
<div className="mainWraper">
<Sidebar />
  <div className="pageBody">
    <div className="pageContent">
      <div className="PageTitle">
        <h3>Case Detail</h3>
        <a  title="Case Details"
           href={`#/view-casedetails/${id}`}
                        type="button"
                        className="btn btn-primary btn_client"
                      >
                        Back
                      </a>
      
      </div>
<br/>
      {/* <div className="caseMobile"> */}
      <div className="caseDetail">
        <div className="form-group Details">
          <h4>Case Name : </h4>
          <p>
            {caseData.CaseName === "" ? "-" : caseData.CaseName}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Case No. : </h4>
          <p>
            {caseData.CaseNo === "" || caseData.CaseNo === null
              ? "-"
              : caseData.CaseNo}
          </p>
        </div>

        <div className="form-group Details">
          <h4>Case Year : </h4>
          <p>
            {caseData.CaseYear === "" ||
            caseData.CaseYear === null
              ? "-"
              : caseData.CaseYear}
          </p>
        </div>

        <div className="form-group Details">
          <h4>Court Name : </h4>
          <p>
            {caseData.CourtName === "" ||
            caseData.CourtName === null
              ? "-"
              : caseData.CourtName}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Case Type :</h4>
          <p>
            {caseData.CaseType === "" ||
            caseData.CaseType === null
              ? "-"
              : caseData.CaseType}
          </p>
        </div>
        <div className="form-group Details">
          <h4>File No. : </h4>
          <p>
            {caseData.FileNo === "" || caseData.FileNo === null
              ? "-"
              : caseData.FileNo}
          </p>
        </div>

        <div className="form-group Details">
          <h4>Diary No. : </h4>
          <p>
            {caseData.DiaryNo === "" || caseData.DiaryNo === null
              ? "-"
              : caseData.DiaryNo}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Diary Year: </h4>
          <p>
            {caseData.DiaryYear === "" ||
            caseData.DiaryYear === null
              ? "-"
              : caseData.DiaryYear}
          </p>
        </div>
       

        <div className="form-group Details">
          <h4>Client Name : </h4>
          <p>
            {caseData.ClientName === "" ||
            caseData.ClientName === null
              ? "-"
              : caseData.ClientName}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Client Last Name : </h4>
          <p>
            {caseData.ClientLastName === "" ||
            caseData.ClientLastName === null
              ? "-"
              : caseData.ClientLastName}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Company Name : </h4>
          <p>
            {caseData.CompanyName === "" ||
            caseData.CompanyName === null
              ? "-"
              : caseData.CompanyName}
          </p>
        </div>

        <div className="form-group Details">
          <h4>Client Mobile No. : </h4>

          <p>
            {caseData.MobileNo === "" ||
            caseData.MobileNo === null
              ? "-"
              : caseData.MobileNo}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Phone : </h4>
          <p>
            {caseData.Phone === "" || caseData.Phone === null
              ? "-"
              : caseData.Phone}
          </p>
        </div>
        <div className="form-group Details">
          <h4>Email :</h4>
          <p>
            {caseData.Email === "" || caseData.Email === null
              ? "-"
              : caseData.Email}
          </p>
        </div>
        </div>

<br/>
          <div class="report_crmgraph">
            <div class="row">
              <div class="col-md-12">
                <div class="myCard">
                  <div class="cardHeader">
                    <h4>Events</h4>
                    <a
                      style={{
                        color: "white",
                      }}
                      type="button"
                      // className="btn btn-primary btn_client headbtn"
                      data-toggle="modal"
                      data-target="#exampleModalLong"
                    >
                      Add Event
                      <img src="assets/img/icons/add.svg" />
                    </a>
                    <a href="/#/view-events" className="rytContent">
                      <img
                        src="assets/img/icons/dots.png"
                        alt=""
                        title="All Events"
                      />
                    </a>
                  </div>
                  {optionsCD.event &&
                  optionsCD.event[0].message === "Success" ? (
                    <div class="cardBody">
                      <table class="myTable">
                        <thead>
                          <th>Action</th>
                          <th>Date</th>
                          <th>Event Details </th>
                        </thead>
                        {optionsCD.event && optionsCD.event.length > 0 ? (
                          optionsCD.event.map((val, index) => (
                            <tr
                              key={index}
                              className={
                                index % 2 === 0 ? "row-even" : "row-odd"
                              }
                            >
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "15px",
                                  }}
                                >
                                  <span>
                                    <a
                                      // style={{
                                      //   display:
                                      //     isEdit === "0"
                                      //       ? "none"
                                      //       : "block",
                                      // }}
                                      type="button"
                                      data-toggle="modal"
                                      data-target="#exampleModalLongEdit"
                                      onClick={() => getById(val.Id)}
                                    >
                                      <img
                                        src="assets/img/icons/edit.svg"
                                        title="Edit"
                                      />
                                    </a>
                                  </span>
                                  <span>
                                    <a
                                      type="button"
                                      data-toggle="modal"
                                      data-target="#exampleModalLongDetail"
                                      onClick={() => details(val.Id)}
                                    >
                                      <img
                                        src="assets/img/icons/eye.svg"
                                        title="Details"
                                      />
                                    </a>
                                  </span>

                                  <span>
                                    <a
                                      // style={{
                                      //   cursor: "pointer",
                                      //   display:
                                      //     isStatus === "0"
                                      //       ? "none"
                                      //       : "block",
                                      // }}
                                      onClick={() =>
                                        deActive(val, "Event/EventStatus")
                                      }
                                    >
                                      {val.Status === "1" ? (
                                        <img
                                          className="thumb_icon"
                                          src="assets/img/icons/Up.svg"
                                          title="Status"
                                        />
                                      ) : (
                                        <img
                                          className="thumb_icon"
                                          src="assets/img/icons/Down.svg"
                                          title="Status"
                                        />
                                      )}
                                    </a>
                                  </span>
                                  {/* {isDelete !== "0" && ( */}
                                  <span
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setDelId(val.Id)}
                                  >
                                    <img
                                      src="assets/img/icons/delete.svg"
                                      alt="Delete"
                                      title="Delete"
                                    />
                                  </span>
                                  {/* )} */}
                                </div>
                              </td>
                              <td style={{ width: "100px" }}>
                                {formatDate1(val.Date)}
                              </td>
                              <td>
                                {val.EventDetails === null ||
                                val.EventDetails === ""
                                  ? "-"
                                  : val.EventDetails}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <div></div>
                        )}
                      </table>
                    </div>
                  ) : (
                    <p className="case_detail_table">Data not found</p>
                  )}

                  {/*-------- DELETE EVENT ---------*/}

                  <div
                    className="modal fade sc_modal"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button
                            type="button"
                            className="btn-close d_event"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          />
                        </div>
                        <div className="modal-body">
                          <h4>Delete Row</h4>
                          <p>Are you sure you want to delete Event ?</p>
                          <ul>
                            <li>
                              <a
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Cancel
                              </a>
                            </li>
                            <li>
                              <a
                                className="btn btn-danger"
                                onClick={() => deleteCaseLead(delId)}
                              >
                                Delete
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*-------- ADD EVENT FORM ---------*/}

                  <div
                    className="modal fade"
                    id="exampleModalLong"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="exampleModalLongTitle"
                    aria-hidden="true"
                    show={show}
                  >
                    <div
                      className="modal-dialog modal-lg"
                      role="document"
                      // style={{ maxWidth: "50%" }}
                    >
                      <div className="modal-content">
                        <div className="add_wrap">
                          <div className="main_heading">
                            <h2>Add Event</h2>
                          </div>
                        </div>
                        <div className="popup_modal ">
                          <button
                            type="button"
                            className="close btn_popup"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                          <div className="dashed_popup">
                            <form role="form " onSubmit={submitHandler}>
                              <div className="form_flex caseMobile">
                                <div className="form-group">
                                  <label for="uname">
                                    Date <text className="m_star">*</text>
                                  </label>
                                  <input
                                    required
                                    autoComplete="off"
                                    className="name"
                                    type="date"
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                  />
                                </div>
                                <div className="form-group">
                                  <label for="uname">File No.</label>
                                  <text className="m_star">*</text>
                                  <select disabled id="city" required>
                                    {optionsEvent &&
                                      optionsEvent.map((val, index) => (
                                        <option
                                          key={index}
                                          value={val.value}
                                          selected={val.value === id}
                                        >
                                          {val.label}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              </div>
                              <div className="form_flex caseMobile">
                                <div className="form-group">
                                  <div>
                                    <label for="uname">Is Effective </label>
                                    <select
                                      className="name"
                                      value={isEffective}
                                      onChange={(e) =>
                                        setIsEffective(e.target.value)
                                      }
                                    >
                                      <option>--Please Select--</option>
                                      <option value="1">YES</option>
                                      <option value="0">NO</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div>
                                    <label for="uname">Is Upcoming </label>
                                    <select
                                      className="name"
                                      value={isUpcoming}
                                      onChange={(e) =>
                                        setIsUpcoming(e.target.value)
                                      }
                                    >
                                      <option>--Please Select--</option>
                                      <option value="Upcoming">UPCOMING</option>
                                      <option value="Completed">
                                        COMPLETED
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="form_flex col-md-6">
                                <div
                                  className="form-group"
                                >
                                  <label for="uname">Next Date</label>
                                  <input
                                    min={currentDate}
                                    autoComplete="off"
                                    className="name"
                                    type="date"
                                    value={nextDate}
                                    onChange={(e) =>
                                      setNextDate(e.target.value)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="form_flex">
                                <div className="form-group">
                                  <label for="uname">Event Details</label>
                                  <textarea
                                    value={eventDetails}
                                    onChange={(e) =>
                                      setEventDetails(e.target.value)
                                    }
                                    rows={4}
                                    cols={50}
                                    placeholder="Enter your text here..."
                                  />
                                </div>
                              </div>
                             {
                              saveButton ? (
                                <div className="btn_main caseMobile">
                                <button type="submit" className="btn_save">
                                  Save
                                </button>
                                <button
                                  type="button"
                                  className="btn_save btn_cancle"
                                  data-dismiss="modal"
                                >
                                  Cancel
                                </button>
                              </div>
                              ) : ""
                             }
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*-------- EDIT EVENT FORM ---------*/}

                  <div
                    className="modal fade"
                    id="exampleModalLongEdit"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="exampleModalLongTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-lg"
                      role="document"
                      // style={{ maxWidth: "50%" }}
                    >
                      <div className="modal-content">
                        <div className="add_wrap">
                          <div className="main_heading">
                            <h2>Edit Event</h2>
                          </div>
                        </div>
                        <div className="popup_modal ">
                          <button
                            type="button"
                            className="close e_popup btn_popup"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                          <div className="dashed_popup">
                            <form role="form " onSubmit={submitEditHandler}>
                              <div className="form_flex caseMobile">
                                <div className="form-group">
                                  <label for="uname">Date</label>
                                  <input
                                    autoComplete="off"
                                    className="name"
                                    type="date"
                                    value={editDate}
                                    onChange={(e) =>
                                      setEditDate(e.target.value)
                                    }
                                  />
                                </div>
                                <div className="form-group">
                                  <label for="uname">File No.</label>
                                  <text
                                    style={{ color: "red", fontSize: "20px" }}
                                  >
                                    *
                                  </text>
                                  <select
                                    id="city"
                                    onChange={(e) =>
                                      setEditCaseID(e.target.value)
                                    }
                                    required
                                  >
                                    {optionsEvent && optionsEvent.length ? (
                                      optionsEvent.map((val, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={val.value}
                                            selected={val.value == editCaseID}
                                          >
                                            {val.label}
                                            {/* {val.FileNo && `${val.FileNo} /`}{" "}
                                {val.CaseName && `${val.CaseName} /`}{" "}
                                {val.CaseYear} */}
                                          </option>
                                        );
                                      })
                                    ) : (
                                      <option>No Data Found</option>
                                    )}
                                  </select>
                                </div>
                              </div>
                              <div className="form_flex caseMobile">
                                <div className="form-group">
                                  <div>
                                    <label for="uname">Is Effective </label>
                                    <select
                                      value={editIsEffective}
                                      onChange={(e) =>
                                        setEditIsEffective(e.target.value)
                                      }
                                    >
                                      <option>--Please Select--</option>
                                      <option value="1">YES</option>
                                      <option value="0">NO</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div>
                                    <label for="uname">Is Upcoming </label>
                                    <select
                                      value={editIsUpcoming}
                                      onChange={(e) =>
                                        setEditIsUpcoming(e.target.value)
                                      }
                                    >
                                      <option>--Please Select--</option>
                                      <option value="Upcoming">UPCOMING</option>
                                      <option value="Completed">
                                      COMPLETED
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="form_flex col-md-6">
                                <div
                                  className="form-group"
                                >
                                  <label for="uname">Next Date</label>
                                  <input
                                    autoComplete="off"
                                    className="name"
                                    type="date"
                                    value={editNextDate}
                                    onChange={(e) =>
                                      setEditNextDate(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="form_flex">
                                <div className="form-group">
                                  <label for="uname">Event Details</label>
                                  <textarea
                                    value={editEventDetails}
                                    onChange={(e) =>
                                      setEditEventDetails(e.target.value)
                                    }
                                    rows={4}
                                    cols={50}
                                    placeholder="Enter your text here..."
                                  />
                                </div>
                              </div>
                              <div className="btn_main caseMobile">
                                <button type="submit" className="btn_save">
                                  Save
                                </button>
                                <button
                                  type="button"
                                  className="btn_save btn_cancle"
                                  data-dismiss="modal"
                                  // onClick={filterCancel}
                                >
                                  Cancel
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*-------- EVENT DETAILS ---------*/}

                  <div
                    className="modal fade"
                    id="exampleModalLongDetail"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="exampleModalLongTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-lg"
                      role="document"
                      // style={{ maxWidth: "50%" }}
                    >
                      <div className="modal-content">
                        <div className="add_wrap">
                          <div className="main_heading">
                            <h2>Event Details</h2>
                          </div>
                        </div>
                        <div className="popup_modal ">
                          <button
                            type="button"
                            className="close ab btn_popup"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                          <div className="dashed_popup">
                            <div className="form_flex caseMobile">
                              <div className="form-group">
                                <h4>
                                  Case Name &nbsp;: &nbsp;{" "}
                                  {dCaseName === "" ? "-" : dCaseName}{" "}
                                </h4>
                              </div>
                              <div className="form-group">
                                <h4>
                                  Case No. &nbsp;: &nbsp;{" "}
                                  {dCaseNo === "" ? "-" : dCaseNo}
                                </h4>
                              </div>
                            </div>

                            <div className="form_flex caseMobile">
                              <div className="form-group">
                                <h4>
                                  Case Year &nbsp;: &nbsp;{" "}
                                  {dCaseYear === "" ? "-" : dCaseYear}
                                </h4>
                              </div>
                              <div className="form-group">
                                <h4>
                                  File No &nbsp;:&nbsp;{" "}
                                  {dFileNo === "" ? "-" : dFileNo}
                                </h4>
                              </div>
                            </div>

                            <div className="form_flex caseMobile">
                              <div className="form-group">
                                <h4>
                                  Date &nbsp;: &nbsp;{" "}
                                  {dDate === "0000-00-00"
                                    ? "-"
                                    : formatDate1(dDate)}
                                </h4>
                              </div>
                              <div className="form-group">
                                <h4>
                                  Status &nbsp;:&nbsp;{" "}
                                  {dStatus == 1 ? "Active" : "DeActive"}{" "}
                                </h4>
                              </div>
                            </div>

                            <div className="form_flex caseMobile">
                              <div className="form-group">
                                <h4>
                                  Next Date &nbsp;: &nbsp;{" "}
                                  {dNextDate === "" || dNextDate == "0000-00-00"
                                    ? "-"
                                    : formatDate1(dNextDate)}
                                </h4>
                              </div>
                              <div className="form-group">
                                <h4>
                                  Is Upcoming &nbsp;: &nbsp;{" "}
                                  {dIsUpcoming === "" ? "-" : dIsUpcoming}
                                </h4>
                              </div>
                            </div>

                            <div className="form_flex caseMobile">
                              <div className="form-group">
                                <h4>
                                  Is Effective &nbsp;: &nbsp;
                                  {dIsEffective == 0 ? "NO" : "YES"}
                                </h4>
                              </div>
                              <div className="form-group">
                                <h4>
                                  Added By &nbsp;: &nbsp;{" "}
                                  {dAddedBy === "" ? "-" : dAddedBy}
                                </h4>
                              </div>
                            </div>

                            <div className="form_flex caseMobile">
                              <div className="form-group">
                                <h4>
                                  Event Details &nbsp;: &nbsp;{" "}
                                  {dEventDetails === "" ? "-" : dEventDetails}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        </div>
        <Footer />
</div>
      </DocumentTitle>
    </>
  );
};

export default EventCD;
