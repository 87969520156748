import React, { useState, useRef } from "react";import autoTable from "jspdf-autotable";  
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";

import {
  viewData,
  deleteUser,
  userDetails,
  activeStatus,
  permission,
  getFileNo,
  customStyles,
  getClient,
  getCurrentDate,
  clearForm,
} from "../services/api.service";
import { formatDate1 } from "../services/api.service";
import Preloader from "./Preloader";
import DocumentTitle from "react-document-title";
import { format } from "date-fns";
import jsPDF from "jspdf";
import Footer from "./Footer";
const InvoiceTemp = () => {
  /********************************preloader****************************************************/
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    /***********************Simulate an asynchronous operation (e.g., API call) with setTimeout****************************************/
    setTimeout(() => {
      setIsLoading(false);
      /**********************************Simulating a 1-second delay*********************************************/
    }, 500);
  }, []);
  //        -------- POPUP CLOSE VARIABLE ---------

  const closeButton = document.querySelector(".btn_popup");
  const closeView = document.querySelector(".ab");
  const closeEdit = document.querySelector(".e_popup");
  const closeDelete = document.querySelector(".mm");

  //        -------- TASK VARIABLE ---------

  const baseUrl = useContext(url);
  const navigate = useNavigate();
  const handleKeyPress = (e) => {
    const charCode = e.which || e.keyCode;
    // Allow only numeric characters and backspace
    if (
      charCode < 48 ||
      charCode > 57 ||
      (charCode !== 8 && e.target.value.length >= 10)
    ) {
      e.preventDefault();
    }
  };
  const [Name, setName] = useState("");
  const [Address, setAddress] = useState("");
  const [MobileNo, setMobileNo] = useState("");

  //        -------- ADD TASK EDIT TASK VARIABLE ---------

  const [EditName, setEditName] = useState("");
  const [excel, setExcel] = useState([]);
  const [EditAddress, setEditAddress] = useState("");
  const [EditMobileNo, setEditMobileNo] = useState("");
  const [dName, setDName] = useState("");
  const [dAddress, setDAddress] = useState("");
  const [dMobileNo, setDMobileNo] = useState("");
  const [EditID, setEditID] = useState("");
  const [user, setuser] = useState([]);
  const [isAdd, setIsAdd] = useState("0");
  const [isEdit, setIsEdit] = useState("0");
  const [isView, setIsView] = useState("0");
  const [isDelete, setIsDelete] = useState("0");
  const [isStatus, setIsStatus] = useState("0");
  const [caseLeadData, setCaseLeadData] = useState([]);
  const [filterUser, setFilterUser] = useState("");
  const [delId, setDelId] = useState("");
  const [active, setActive] = useState(false);
  const formData = new FormData();
  const formEditData = new FormData();
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const handleExportButtonClick = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const handleToggleColumn = (columnName) => {
    const currentIndex = selectedColumns.indexOf(columnName);
    const newSelectedColumns = [...selectedColumns];

    if (currentIndex === -1) {
      newSelectedColumns.push(columnName);
    } else {
      newSelectedColumns.splice(currentIndex, 1);
    }

    setSelectedColumns(newSelectedColumns);
  };
  
  //        -------- VIEW TASK DATA ---------

  const getCaseView = async () => {
    const [view, data, userData] = await Promise.all([
      viewData("Master/ViewInvoiceTemplate"),
      permission(19),
      getClient("Admin/ViewUser"),
      setLoading(true)
    ]);

    if (view === null) {
      setLoading(false)
    } else {
      if (view === "token") {
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        setLoading(false)
        setCaseLeadData(view);
      }
    }

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }
    
    setuser(userData);
    setIsAdd(data && data.result[0].IsAdd);
    setIsEdit(data && data.result[0].IsEdit);
    setIsView(data && data.result[0].IsView);
    setIsStatus(data && data.result[0].IsStatus);
    setIsDelete(data && data.result[0].IsDelete);
  };

  //        -------- DELETE TASK API ---------

  const deleteCaseLead = async () => {
    const data = await deleteUser(delId, "Master/DeleteInvoiceTemplate");
    if (data === "token") {
      setTimeout(() => {
        closeDelete.click();
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      closeDelete.click();
      getCaseView();
    }
  };

  //        -------- ACTIVE DEACTIVE ---------

  const deActive = async (row) => {
    const data = await activeStatus(row, "Master/InvoiceTemplateStatus");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      getCaseView();
    }
  };

  //        -------- TASK DETAILS DATA ---------

  const details = async (id) => {
    const data = await userDetails(id, "Master/ViewInvoiceTemplateById");
    if (data === "token") {
      setTimeout(() => {
        closeView.click();
        navigate("/");
      }, 3000);
    } else {
      setDAddress(data.result[0].Address);
      setDMobileNo(data.result[0].MobileNo);
      setDName(data.result[0].Name);
      // setDOrder(data.result[0].Order);
      // setDTaskName(data.result[0].TaskStage);
    }
  };

  //        -------- TASK DATA ---------

  const getById = async (id) => {
    const data = await userDetails(id, "Master/ViewInvoiceTemplateById");
    if (data === "token") {
      setTimeout(() => {
        closeEdit.click();
        navigate("/");
      }, 3000);
    } else {
      setEditID(data.result[0].Id);
      setEditAddress(data.result[0].Address);
      setEditMobileNo(data.result[0].MobileNo);
      setEditName(data.result[0].Name);
      // setEditTaskName(data.result[0].TaskStage);
      // setEditOrder(data.result[0].Order);
    }
  };

  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  //        -------- SUBMIT TASK DATA ---------

  const submitHandler = async (event) => {
    event.preventDefault();
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("Name", Name.toUpperCase());
    formData.append("Address", Address.toUpperCase());
    formData.append("MobileNo", MobileNo);
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    try {
      const response = await fetch(`${baseUrl}Master/AddInvoiceTemplate`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 3000);
      }
      if (data.result.length && data.result[0].code == 200) {
        toast.success("Template added successfully");
        handleClose();
        getCaseView();
        setName("");
        setMobileNo("");
        setAddress("");

        closeButton.click();
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        -------- EDIT TASK API ---------

  const submitEditHandler = async (event) => {
    event.preventDefault();
    formEditData.append("ID", EditID);
    formEditData.append("AdminID", localStorage.getItem("id"));
    formEditData.append("Name", EditName.toUpperCase());
    formEditData.append("Address", EditAddress.toUpperCase());
    formEditData.append("MobileNo", EditMobileNo);
    formEditData.append("UpdatedBy", localStorage.getItem("UserId"));
    try {
      const response = await fetch(`${baseUrl}Master/EditInvoiceTemplate`, {
        method: "POST",
        body: formEditData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeEdit.click();
          navigate("/");
        }, 3000);
      }
      if (data.result.length && data.result[0].code === "200") {
        toast.success(" Updated successfully");
        closeEdit.click();
        getCaseView();
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        -------- TABLE ---------

  const columnHelper = createMRTColumnHelper();
  
  const columns = [
    columnHelper.accessor("name", {
      header: "Action",
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      Cell: (row) => {
        return (
          <>
            <div style={{ display: "flex", gap: "15px" }}>
              {isEdit !== "0" && (
                <span
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalLongEdit"
                  onClick={() => getById(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/edit.svg"
                    alt="Edit"
                    title="Edit"
                  />
                </span>
              )}

              <span
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongDetail"
                onClick={() => details(row.row.original.Id)}
              >
                <img
                  src="assets/img/icons/eye.svg"
                  alt="Details"
                  title="Details"
                />
              </span>
              <span>
                <a
                  style={{
                    cursor: "pointer",
                    display: isStatus === "0" ? "none" : "block",
                  }}
                  onClick={() => deActive(row.row.original)}
                >
                  {row.row.original.Status === "1" ? (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Up.svg"
                      title="Status"
                    />
                  ) : (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Down.svg"
                      title="Status"
                    />
                  )}
                </a>
              </span>
              {isDelete !== "0" && (
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style={{ cursor: "pointer" }}
                  onClick={() => setDelId(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/delete.svg"
                    alt="Delete"
                    title="Delete"
                  />
                </span>
              )}
            </div>
          </>
        );
      },
    }),

  

    columnHelper.accessor("Name", {
      header: "Name",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Name === null || row.row.original.Name === ""
              ? "-"
              : row.row.original.Name}
          </div>
        );
      },
    }),

    

    columnHelper.accessor("MobileNo", {
      header: "Mobile No",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.MobileNo === null ||
            row.row.original.MobileNo === ""
              ? "-"
              : row.row.original.MobileNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("Address", {
      header: "Address",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Address === null ||
            row.row.original.Address === ""
              ? "-"
              : row.row.original.Address}
          </div>
        );
      },
    }),

   

    columnHelper.accessor("Status", {
      header: "Status",
      Cell: (row) => {
        return (
          <div>{row.row.original.Status === "1" ? "ACTIVE" : "DEACTIVE"}</div>
        );
      },
    }),

    columnHelper.accessor("AddedBy", {
      header: "Added By",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.AddedBy === null ||
            row.row.original.AddedBy === ""
              ? "-"
              : row.row.original.AddedBy}
          </div>
        );
      },
    }),
  ];
  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });;

  useEffect(() => {
    viewData();
  });

  const removekey = () => {
    if (caseLeadData && caseLeadData.length > 0) {
      const excelData =
        caseLeadData &&
        caseLeadData.map((obj) => {
          const { Status, Id, code, message, AdminID, ...rest } = obj;
          return rest;
        });
      setExcel(
        excelData.map((obj, index) => {
          return { "S. No.": `${index + 1}`, ...obj };
        })
      );
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getCaseView();
    }
  }, []);

  // ---------DataTable-----

  const excelData =
  caseLeadData &&
  caseLeadData.map((obj) => {
    const { Id, code, message,Status, AdminID, ...rest } = obj;
    return rest;
  });


  const handleExportData = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });
   const formattedDate = new Date().toISOString().slice(0, 10);
    const filename = `Invoice_Template|LegalCRM_${formattedDate}.csv`;
  
    const csv = generateCsv(csvConfig)(excelData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const columnsToExport = [
      { id: "serialNo", header: "S No." },
      ...columns.filter((column) => selectedColumns.includes(column.id)),
    ];
  
    const tableHeaders = columnsToExport.map((c) => c.header);
  
    const formattedDate = new Date().toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  
    const filename = `Invoice_Template|LegalCRM_${formattedDate}.pdf`;
    const addWatermarkToFirstPage = () => {
      const watermarkHeight = 20;

      // Calculate positioning to center the watermark on each page

      doc.setFontSize(10);
      doc.text(
        10,
        watermarkHeight + -10,
        `Generated by : ${localStorage.getItem("name")} `
      );
      doc.text(172, watermarkHeight + -10, `Date: ${formattedDate}`);
      doc.setFontSize(16);
      doc.text('Invoice Template Table', 74, 20);
    };
    const watermarkHeight = 20;

    addWatermarkToFirstPage();
    const tableData = rows.map((row, index) => {
      const formattedDateInPdf = row.original['Date']
        ? format(new Date(row.original['Date']), 'dd/MM/yyyy')
        : '';
    
      return [
        { content: (index + 1).toString(), styles: { fontStyle: 'bold' } }, // Serial number
        ...columnsToExport.slice(1).map((column) => { // Exclude the first column (serial number) when mapping
          if (column.id === 'Date') {
            return formattedDateInPdf;
          }
    
          const value = column.id in row.original ? row.original[column.id] : '';
          return value !== null && value !== undefined ? value : '';
        }),
      ];
    });
    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY: watermarkHeight + 5,
    });
  
    // Add a heading to the table
 
   // Save the PDF to a Blob
   const pdfBlob = doc.output('blob');

   // Create a blob URL
   const blobUrl = URL.createObjectURL(pdfBlob);
 
   // Open the URL in a new tab
   window.open(blobUrl, '_blank');
 
   // Optionally revoke the object URL after opening the new tab
   URL.revokeObjectURL(blobUrl);
    // doc.save(filename);
  };


  const table = useMaterialReactTable({
    columns,
    data: caseLeadData,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    initialState: {
      columnVisibility: {
        Status: false,
        AddedBy: false,
        UpdatedBy: false,
        UpdatedDate: false,
        CreatedDate: false,
      },
    },
    enableStickyHeader: true,
    enableRowNumbers: true,
    state:{
      isLoading: loading
    },
    muiCircularProgressProps:{
      color: 'primary',
      thickness: 5,
      size: 55,
       style:{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1000,
    }},
    muiSkeletonProps:{
      animation: 'pulse',
      height: 20,
    },
    muiPaginationProps: {
      rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
      sx={{
        display: "flex",
        gap: "16px",
        padding: "8px",
        flexWrap: "wrap",
      }}
    >
      <Button
 disabled={table.getPrePaginationRowModel().rows.length === 0}
        onClick={handleExportData}
        startIcon={<FileDownloadIcon />}
      >
        Export Excel
      </Button>

      <Button
        disabled={!table || table.getPrePaginationRowModel().rows.length === 0}
        onClick={handleExportButtonClick}
        startIcon={<FileDownloadIcon />}
      >
        Export PDF
      </Button>
      <Dialog open={open} onClose={handleClose1}>
        <DialogTitle>Select Columns To Export</DialogTitle>
        <DialogContent>
            {columns.map((column) =>
              column.header !== "Action" ? (
                <div key={column.id}>
                  <Checkbox
                    checked={selectedColumns.includes(column.id)}
                    onChange={() => handleToggleColumn(column.id)}
                  />
                  {column.header}
                </div>
              ) : (
                ""
              )
            )}
          </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1}>Cancel</Button>
          <Button onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}>Export</Button>
        </DialogActions>
      </Dialog>
    </Box>
    ),
  });

  return (
    <>
      <DocumentTitle title="INVOICE TEMPLATE | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  {/* <div className="accountContainer userListContainer"> */}
                    <div className="pageContent">
                      <div className="PageTitle">
                              <h3 >
                                Invoice Template List
                              </h3>
                            
                              <a
                                style={{
                                  display: isAdd === "0" ? "none" : "block",
                                }}
                                type="button"
                                className="btn btn-primary btn_client headbtn"
                                data-toggle="modal"
                                data-target="#exampleModalLong"
                              >
                                Add Invoice Template
                                <img src="assets/img/icons/add.svg" />
                              </a>
                            </div>
                           
                         
                     
                      
                              <div
                                style={{
                                  display: isView === "0" ? "none" : "block",
                                }}
                                className="searDesign"
                              >
                                <MaterialReactTable table={table} />
                              </div>
                   
              
                      
                    </div>
                  {/* </div> */}
                </div>
              </div>
              <Footer/>
            </div>

            {/*-------- DELETE  FORM -------*/}

            <div
              className="modal fade sc_modal"
              id="exampleModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close mm"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <h4>Delete Row</h4>
                    <p>Are you sure you want to delete this Template ?</p>
                    <ul>
                      <li>
                        <a
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </a>
                      </li>
                      <li>
                        <a
                          className="btn btn-danger"
                          onClick={() => deleteCaseLead(delId)}
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- ADD  FORM ---------*/}

            <div
              className="modal fade"
              id="exampleModalLong"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
              show={show}
            >
              <div
                className="modal-dialog modal-xl"
                role="document"
                // style={{ maxWidth: "75%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Add Invoice Template</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Name <text className="m_star">*</text>
                            </label>
                            <input
                            className="name"
                              type="text"
                              placeholder="Enter Name"
                              name="uname"
                              onChange={(e) => setName(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">Address </label>
                            <input
                            className="name"
                              type="text"
                              name="uname"
                              placeholder="Enter Address"
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              Mobile No <text className="m_star">*</text>
                            </label>
                            <input
                            className="name"
                              onKeyPress={handleKeyPress}
                              maxLength={10}
                              type="text"
                              placeholder="Enter Mobile No"
                              name="uname"
                              onChange={(e) => setMobileNo(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_save btn_cancle"
                            onClick={clearForm}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- EDIT TASK FORM ---------*/}

            <div
              className="modal fade"
              id="exampleModalLongEdit"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-xl"
                role="document"
                // style={{ maxWidth: "40%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Edit Invoice Template</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close e_popup btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitEditHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Name <text className="m_star">*</text>
                            </label>
                            <input
                           
                              type="text"
                              placeholder="Enter Name"
                              name="uname"
                              value={EditName}
                              onChange={(e) => setEditName(e.target.value)}
                              required
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label htmlFor="uname">Address </label>
                          <input
                            type="text"
                            name="uname"
                            placeholder="Enter Address"
                            value={EditAddress}
                            onChange={(e) => setEditAddress(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="uname">
                            Mobile No <text className="m_star">*</text>
                          </label>
                          <input
                            value={EditMobileNo}
                            type="text"
                            placeholder="Enter Mobile No"
                            name="uname"
                            onChange={(e) => setEditMobileNo(e.target.value)}
                            required
                          />
                        </div>
                        <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_save btn_cancle"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- DETAILS ---------*/}

            <div
              className="modal fade"
              id="exampleModalLongDetail"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Invoice Template Details</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close ab btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Name &nbsp;: &nbsp; {dName === null || dName === "" ? "-" : dName}{" "}
                          </h4>
                        </div>
                     
                        <div className="form-group">
                          <h4>
                            Mobile No &nbsp;:&nbsp;{" "}
                            {dMobileNo === null || dMobileNo === "" ? "-" : dMobileNo}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                      <div className="form-group">
                          <h4>
                            Address &nbsp;:&nbsp;{" "}
                            {dAddress === null || dAddress === "" ? "-" : dAddress}{" "}
                          </h4>
                        </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- TOASTER ---------*/}

            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        )}
      </DocumentTitle>
    </>
  );
};

export default InvoiceTemp;
