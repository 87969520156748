import React, { useState, useEffect } from "react";
import Header from "../Header";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../Sidebar";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { useContext } from "react";
import { url } from "../../index";
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { format } from "date-fns";
import { useNavigate } from "react-router";
import {
  viewData,
  permission,
  activeStatus,
  clearForm,
} from "../../services/api.service";
import Preloader from "../Preloader";
import DocumentTitle from "react-document-title";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Footer from "../Footer";
const ViewRole = () => {

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);
  //---------- ROLE VARIABLE ----------

  const closeButton = document.querySelector(".btn_popup");
  const closeEdit = document.querySelector(".e_popup");
  const navigate = useNavigate();
  const [excel, setExcel] = useState([]);
  const baseUrl = useContext(url);
  const [roleName, setRoleName] = useState("");
  const [editRoleId, setEditRoleId] = useState("");
  const [editRoleName, setEditRoleName] = useState("");
  const [viewRoleData, setViewRoleData] = useState([]);
  const [isAdd, setIsAdd] = useState("0");
  const [isEdit, setIsEdit] = useState("0");
  const [isView, setIsView] = useState("0");
  const [isDelete, setIsDelete] = useState("0");
  const [isStatus, setIsStatus] = useState("0");
  const formData = new FormData();
  const roleById = new FormData();
  const editRole = new FormData();

  const [open, setOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const handleExportButtonClick = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const handleToggleColumn = (columnName) => {
    const currentIndex = selectedColumns.indexOf(columnName);
    const newSelectedColumns = [...selectedColumns];

    if (currentIndex === -1) {
      newSelectedColumns.push(columnName);
    } else {
      newSelectedColumns.splice(currentIndex, 1);
    }

    setSelectedColumns(newSelectedColumns);
  };

  //----------- VIEW ROLE --------

  const viewRole = async () => {
    const [view, data] = await Promise.all([
      viewData("Master/ViewRole"),
      permission(1),
    ]);
    if (view === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } else {
      setViewRoleData(view);
    }

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }
    
    setIsAdd(data && data.result[0].IsAdd);
    setIsEdit(data && data.result[0].IsEdit);
    setIsView(data && data.result[0].IsView);
    setIsStatus(data && data.result[0].IsStatus);
    setIsDelete(data && data.result[0].IsDelete);
  };

  //----------- ACTIVE DEACTIVE ROLE --------

  const deActive = async (row) => {
    const data = await activeStatus(row, "Master/RoleStatus");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }

    if (data === true) {
      viewRole();
    }
  };

  //----------- SUBMIT ROLE --------

  const submitHandler = async (event) => {
    event.preventDefault();
    if (roleName.length < 2) {
      toast.error("Invalid RoleName");
    } else {
      formData.append("RoleName", roleName.toUpperCase());
    }
    formData.append("AdminID", `${localStorage.getItem("id")}`);
    formData.append("AddedBy", `${localStorage.getItem("UserId")}`);

    try {
      const response = await fetch(`${baseUrl}Master/AddRole`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].code == 200) {
        toast.success("Role successfully added");
        viewRole();
        setRoleName("");
        closeButton.click();
      } else {
        toast.error(data && data.result[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //----------- EDIT ROLE --------

  const submitEditHandler = async (event) => {
    event.preventDefault();
    if (editRoleName.length < 2) {
      toast.error("Invalid Name");
    } else {
      editRole.append("RoleName", editRoleName.toUpperCase());
    }
    editRole.append("RoleID", editRoleId);
    editRole.append("AdminID", `${localStorage.getItem("id")}`);
    editRole.append("UpdatedBy", `${localStorage.getItem("UserId")}`);
    try {
      const response = await fetch(`${baseUrl}Master/EditRole`, {
        method: "POST",
        body: editRole,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeEdit.click();
          navigate("/");
        }, 3000);
      }
      if (data.result[0].code == 200) {
        toast.success("Role Updated successfully");
        viewRole();
        closeEdit.click();
      } else {
        toast.error(data && data.result[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //----------- EDIT ROLE --------

  const updateRole = async (id) => {
    roleById.append("AdminID", `${localStorage.getItem("id")}`);
    roleById.append("RoleID", id);
    try {
      const response = await fetch(`${baseUrl}Master/ViewRoleById`, {
        method: "POST",
        body: roleById,

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      setEditRoleId(data && data.result[0].Id);
      setEditRoleName(data && data.result[0].RoleName);
    } catch (error) {
      console.error(error);
    }
  };

  //----------- ROLE TABLE --------
  
  const excelData =
  viewRoleData &&
  viewRoleData.map((obj) => {
    const { Id, code, message,Status, AdminID, ...rest } = obj;
    return rest;
  });

  const handleExportData = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });

    const formattedDate = new Date().toISOString().slice(0, 10);
    const filename = `Role_${formattedDate}.csv`;

    const csv = generateCsv(csvConfig)(excelData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const columnsToExport = [
      { id: "serialNo", header: "S No." },
      ...columns.filter((column) => selectedColumns.includes(column.id)),
    ];

    const tableHeaders = columnsToExport.map((c) => c.header);

    const formattedDate = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const filename = `Role|LegalCRM_${formattedDate}.pdf`;

    const tableData = rows.map((row, index) => {
      const formattedDateInPdf = row.original["Date"]
        ? format(new Date(row.original["Date"]), "dd/MM/yyyy")
        : "";

      return [
        { content: (index + 1).toString(), styles: { fontStyle: "bold" } }, // Serial number
        ...columnsToExport.slice(1).map((column) => {
          // Exclude the first column (serial number) when mapping
          if (column.id === "Date") {
            return formattedDateInPdf;
          }

          const value =
            column.id in row.original ? row.original[column.id] : "";
          return value !== null && value !== undefined ? value : "";
        }),
      ];
    });
    
    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });


    doc.text("Role Table", 14, 10);

    const pdfBlob = doc.output("blob");
    const blobUrl = URL.createObjectURL(pdfBlob);
    window.open(blobUrl, "_blank");

    URL.revokeObjectURL(blobUrl);
  };
  const columnHelper = createMRTColumnHelper();

  const columns = [

    columnHelper.accessor('name', {
      header: 'Action',
      minSize: '10px',
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      Cell: (row) => {
        return <div style={{ display: "flex", gap: "15px" }}>
          {isEdit !== "0" && (
            <span>
              <a
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongEdit"
                onClick={() => updateRole(row.row.original.Id)}
              >
                <img src="assets/img/icons/edit.svg" title="Edit" />
              </a>
            </span>
          )}
          <span>
            <a
              style={{
                cursor: "pointer",
                display: isStatus === "0" ? "none" : "block",
              }}
              onClick={() => deActive(row.row.original)}
            >
              {row.row.original.Status === "1" ? (
                <img
                  className="thumb_icon"
                  src="assets/img/icons/Up.svg"
                  title="Status"
                />
              ) : (
                <img
                  className="thumb_icon"
                  src="assets/img/icons/Down.svg"
                  title="Status"
                />
              )}
            </a>
          </span>

        </div>
      },
    }),

    columnHelper.accessor('RoleName', {
      header: 'Role Name',
      Cell: (row) => {
        return <div className="upperCase">
          {row.row.original.RoleName === null || row.row.original.RoleName === "" ? "-" : row.row.original.RoleName}
        </div>
      },
    }),

    columnHelper.accessor('AddedBy', {
      header: 'Added By',
      Cell: (row) => {
        return <div className="upperCase">
          {row.row.original.AddedBy === null || row.row.original.AddedBy === "" ? "-" : row.row.original.AddedBy}
        </div>
      },
    }),

    columnHelper.accessor('Status', {
      header: 'Status',
      Cell: (row) => {
        return <div>{row.row.original.Status === "1" ? "ACTIVE" : "DEACTIVE"}</div>;
      },
    }),
  ];

  const table = useMaterialReactTable({
    columns,
    data: viewRoleData,
    enableRowSelection: false,
    columnFilterDisplayMode: 'popover',
    enableStickyHeader: true,
    enableRowNumbers: true,
    muiPaginationProps: {
      rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },
    positionToolbarAlertBanner: 'bottom',
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
         disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export Excel
        </Button>

        <Button
        disabled={!table || table.getPrePaginationRowModel().rows.length === 0}
        onClick={handleExportButtonClick}
        startIcon={<FileDownloadIcon />}
      >
        Export PDF
      </Button>
      <Dialog open={open} onClose={handleClose1}>
        <DialogTitle>Select Columns To Export</DialogTitle>
        <DialogContent>
            {columns.map((column) =>
              column.header !== "Action" ? (
                <div key={column.id}>
                  <Checkbox
                    checked={selectedColumns.includes(column.id)}
                    onChange={() => handleToggleColumn(column.id)}
                  />
                  {column.header}
                </div>
              ) : (
                ""
              )
            )}
          </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1}>Cancel</Button>
          <Button onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}>Export</Button>
        </DialogActions>
      </Dialog>
      </Box>
    ),
  });

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      viewRole();
    }
  }, []);

  return (
    <>
      <DocumentTitle title="ROLE | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  <div className="pageContent">


                    <div className="PageTitle">
                      <h3 >View Role</h3>
                
                     
                      <a
                        style={{ display: isAdd === "0" ? "none" : "block" }}
                        type="button"
                        className="btn btn-primary btn_client headbtn"
                        data-toggle="modal"
                        data-target="#exampleModalLong"
                      >
                        Add Role
                        <img src="assets/img/icons/add.svg" />
                      </a>
                    </div>


                    <div
                      style={{ display: isView === "0" ? "none" : "block" }}
                      className="searDesign"
                    >
                      <MaterialReactTable table={table} />
                    </div>

                  </div>

                </div>
              </div>
              <Footer/>
            </div>

            {/*--------- ADD ROLE FORM ----------*/}

            <div
              className="modal fade"
              id="exampleModalLong"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Add Role</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitHandler}>
                        <div className="form_flex">
                          <div className="form-group">
                            <label htmlFor="RoleName">Role Name <text className="m_star">*</text></label>
                            <input
                              className="name"
                              type="text"
                              placeholder="Enter Role Name"
                              value={roleName}
                              name="RoleName"
                              onChange={(e) => setRoleName(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            onClick={clearForm}
                            className="btn_save btn_cancle"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*--------- EDIT ROLE FORM ----------*/}

            <div
              className="modal fade"
              id="exampleModalLongEdit"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Edit Role</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close e_popup btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form " onSubmit={submitEditHandler}>
                        <div className="form_flex">
                          <div className="form-group">
                            <label htmlFor="username">Role Name <text className="m_star">*</text></label>
                            <input
                              type="text"
                              placeholder="Enter Role Name"
                              name="username"
                              value={editRoleName}
                              onChange={(e) => setEditRoleName(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_save btn_cancle"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*--------- Toster ----------*/}

            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        )}

      </DocumentTitle>
    </>
  );
};
export default ViewRole;
