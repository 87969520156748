import React from 'react'
import DocumentTitle from 'react-document-title';

const Error = () => {
  return (
    <>
    <DocumentTitle title="LEGAL CRM | ERROR">
        <h1>404 Page Not Found</h1>
        </DocumentTitle>
    </>
  )
}

export default Error;