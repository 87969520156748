import React, { useState, useRef } from "react";
import autoTable from "jspdf-autotable";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";
import {
  deleteUser,
  formatDate1,
  userDetails,
  activeStatus,
  permission,
  getFileNo,
  getClient,
  clearForm,
  withUidApi,
  formateDateTime,
} from "../services/api.service";
import Select from "react-select";
import DocumentTitle from "react-document-title";
import Preloader from "./Preloader";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import jsPDF from "jspdf";
import Footer from "./Footer";

const Estimates = () => {
  
  //        -------- POPUP CLOSE VARIABLE ---------

  const [saveButton, setSaveButton] = useState(true);
  const currentDate = new Date().toISOString().split("T")[0];
  const closeButton = document.querySelector(".btn_popup");
  const closeView = document.querySelector(".ab");
  const closeEdit = document.querySelector(".e_popup");
  const closeDelete = document.querySelector(".mm");
  const [FileNo, setFileNo] = useState([]);
  const [estimateAmount, setEstimateAmount] = useState("");
  const [Discount, setDiscount] = useState("");
  const [excel, setExcel] = useState([]);
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState("0");

  // Amount And Description

  const [formData1, setFormData1] = useState([{ description: "", amount: "" }]);
  const [formData2, setFormData2] = useState([]);
  const baseUrl = useContext(url);
  const navigate = useNavigate();
  const [CaseID, setCaseID] = useState("");
  const [Dates, setDates] = useState(currentDate);
  const [IsFinal, setIsFinal] = useState("1");
  const [estimateTotal, setEstimateTotal] = useState("");
  const [remark, setRemark] = useState("");
  const [data, setData] = useState([]);

  // ----------- VIEW Client ------------

  const ViewData = new FormData();
  const [options, setOptions] = useState([]);

  ViewData.append("AdminID", localStorage.getItem("id"));
  ViewData.append("UserID", localStorage.getItem("uId"));

  const getCountries = async () => {
    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: ViewData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
      }

      if (data && data.result[0].message === "Success") {
        const Mapped = data.result.map((item) => ({
          value: item.CaseID,
          label: `${item.FileNo} / ${item.CaseNo} / ${item.CaseName} / ${item.CaseYear}`,
        }));
        setOptions(Mapped);
        setOptionsEvent(Mapped);
      }

      if (data.response_code === 400) {
        toast.error("Token expired please login again");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setCaseID("");
    } else {
      setCaseID(selectedOption.value);
    }
  };

  //        --------  EDIT  VARIABLE ---------

  const [EditCaseID, setEditCaseID] = useState("");
  const [EditDate, setEditDate] = useState("");
  const [EditIsFinal, setEditIsFinal] = useState("");
  const [detailData, setDetailData] = useState([]);

  //        --------  View  VARIABLE ---------

  const [dCounselName, setDCounselName] = useState("");
  const [dCoCounselName, setDCoCounselName] = useState("");
  const [filterActive, setFilterActive] = useState(false);
  const [optionsEvent, setOptionsEvent] = useState([]);
  const [dCaseID, setDCaseID] = useState("");
  const [dClientName, setDClientName] = useState("");
  const [dClientLastName, setDClientLastName] = useState("");
  const [dClientMobile, setDClientMobile] = useState("");
  const [dDate, setDDate] = useState("");
  const [dIsFinal, setDIsFinal] = useState("");
  const [dDiscount, setDDiscount] = useState("");
  const [dEstimateTotal, setDEstimateTotal] = useState("");
  const [dRemark, setDRemark] = useState("");
  const [dFileNo, setDFileNo] = useState("");
  const [dEstimateAmount, setDEstimateAmount] = useState("");
  const [fCaseId, setFCaseId] = useState("");
  const [fFromDate, setFFromDate] = useState("");
  const filterform = new FormData();
  const [fToDate, setFToDate] = useState("");
  const [fClientID, setFClientID] = useState("");
  const [fClientOption, setFClientOption] = useState([]);
  const [EditID, setEditID] = useState("");
  const [user, setuser] = useState([]);
  const [isAdd, setIsAdd] = useState("0");
  const [editDiscount, setEditDiscount] = useState("");
  const [editRemark, setEditRemark] = useState("");
  const [editEstimateTotal, setEditEstimateTotal] = useState("");
  const [editEstimateAmount, setEditEstimateAmount] = useState("");
  const [isEdit, setIsEdit] = useState("0");
  const [isView, setIsView] = useState("0");
  const [isDelete, setIsDelete] = useState("0");
  const [isStatus, setIsStatus] = useState("0");
  const [caseLeadData, setCaseLeadData] = useState([]);
  const [filterUser, setFilterUser] = useState("");
  const [delId, setDelId] = useState("");
  const [active, setActive] = useState(false);
  const formData = new FormData();
  const estimatesDetail = new FormData();
  const formEditData = new FormData();
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const handleExportButtonClick = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const handleToggleColumn = (columnName) => {
    const currentIndex = selectedColumns.indexOf(columnName);
    const newSelectedColumns = [...selectedColumns];

    if (currentIndex === -1) {
      newSelectedColumns.push(columnName);
    } else {
      newSelectedColumns.splice(currentIndex, 1);
    }

    setSelectedColumns(newSelectedColumns);
  };

  const addRow = () => {
    setFormData1([...formData1, { description: "", amount: "" }]);
  };

  const editAddRow = () => {
    setFormData2([...formData2, { Description: "", Amount: "" }]);
  };

  const handleDescriptionChange = (index, value) => {
    const updatedFormData = [...formData1];
    updatedFormData[index].description = value;
    setFormData1(updatedFormData);
  };

  const editHandleDescriptionChange = (index, value) => {
    const updatedFormData = [...formData2];
    updatedFormData[index].Description = value;
    setFormData2(updatedFormData);
  };

  const handleAmountChange = (index, value) => {
    const updatedFormData = [...formData1];
    updatedFormData[index].amount = value;

    let sum = updatedFormData.reduce(function (prev, current) {
      return prev + +current.amount;
    }, 0);

    setEstimateTotal(sum);
    setEstimateAmount(sum - Discount);
    setFormData1(updatedFormData);
  };

  const editHandleAmountChange = (index, value) => {
    const updatedFormData = [...formData2];
    updatedFormData[index].Amount = value;

    let esti =
      updatedFormData &&
      updatedFormData.reduce(function (prev, current) {
        return prev + +current.Amount;
      }, 0);

    setEditEstimateTotal(esti);
    setEditEstimateAmount(esti - editDiscount);
    setFormData2(updatedFormData);
  };

  const handelDiscount = () => {
    let sum = formData1.reduce(function (prev, current) {
      return prev + +current.amount;
    }, 0);

    let dis = sum - Discount;
    setEstimateAmount(dis);
    setEditEstimateTotal(sum);
  };

  useEffect(() => {
    handelDiscount();
  }, [Discount]);

  const editHandelDiscount = () => {
    let sum =
      formData2 &&
      formData2.reduce(function (prev, current) {
        return prev + +current.Amount;
      }, 0);

    setEditEstimateAmount(sum - editDiscount);
  };

  useEffect(() => {
    editHandelDiscount();
  }, [editDiscount]);

  const removeRow = (indexToRemove) => {
    if (formData1.length === 1) {
      return;
    }

    let updatedFormData = formData1.filter(
      (_, index) => index !== indexToRemove
    );

    setEstimateTotal(estimateTotal - formData1[indexToRemove].amount);
    setEstimateAmount(estimateAmount - formData1[indexToRemove].amount);
    setFormData1(updatedFormData);
  };

  const editRremoveRow = (indexToRemove) => {
    if (formData2.length === 1) {
      return;
    }
    let updatedFormData = formData2.filter(
      (_, index) => index !== indexToRemove
    );

    setEditEstimateAmount(editEstimateAmount - formData2[indexToRemove].Amount);
    setEditEstimateTotal(editEstimateTotal - formData2[indexToRemove].Amount);
    setFormData2(updatedFormData);
  };

  //        -------- VIEW TASK DATA ---------

  const getCaseView = async () => {
    const [view, data, userData, cdata, fileData] = await Promise.all([
      withUidApi("Estimates/ViewEstiamtes"),
      permission(13),
      getClient("Admin/ViewUser"),
      getClient("Client/ViewClient"),
      getFileNo(),
      getCountries(),
      setLoading(true),
    ]);

    if (view === null) {
      setLoading(false);
      setTotalInvoiceAmount("0");
      setCaseLeadData([]);
      setData([]);
    } else {
      if (view === "token") {
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        setLoading(false);
        setCaseLeadData(view);
        setData(view);

        let sum =
          view &&
          view.reduce(function (prev, current) {
            return prev + +current.EstimateAmount;
          }, 0);
        setTotalInvoiceAmount(sum);
      }
    }

    setFileNo(fileData);
    setuser(userData);

    const clientData = cdata && cdata.filter((item) => item.Status === "1");

    const Mapped =
      clientData &&
      clientData.map((item) => ({
        value: item.Id,
        label: `${item.FirstName} ${item.LastName}`,
      }));
    setFClientOption(Mapped);

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }

    setIsAdd(data && data.result[0].IsAdd);
    setIsEdit(data && data.result[0].IsEdit);
    setIsView(data && data.result[0].IsView);
    setIsStatus(data && data.result[0].IsStatus);
    setIsDelete(data && data.result[0].IsDelete);
  };

  //        -------- DELETE TASK API ---------

  const deleteCaseLead = async () => {
    const data = await deleteUser(delId, "Estimates/DeleteEstimates");
    if (data === "token") {
      setTimeout(() => {
        closeDelete.click();
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      closeDelete.click();
      filterActive ? filterApi() : getCaseView();
    }
  };

  //        -------- ACTIVE DEACTIVE ---------

  const deActive = async (row) => {
    const data = await activeStatus(row, "Estimates/EstimateStatus");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      filterActive ? filterApi() : getCaseView();
    }
  };

  //        -------- TASK DETAILS DATA ---------

  const details = async (id) => {
    const data = await userDetails(id, "Estimates/ViewEstimatesById");
    const details = await userDetails(id, "Estimates/ViewEstimatesDetailById");
    if (data === "token") {
      setTimeout(() => {
        closeView.click();
        navigate("/");
      }, 3000);
    } else {
      setDetailData(details && details.result);
      setDCaseID(data && data.result[0].CaseName);
      setDClientName(data && data.result[0].ClientName);
      setDClientLastName(data && data.result[0].ClientlastName);
      setDClientMobile(data && data.result[0].ClientMobile);
      setDDate(data && data.result[0].Date);
      setDIsFinal(data && data.result[0].IsFinal);
      setDDiscount(data && data.result[0].Discount);
      setDEstimateAmount(data && data.result[0].EstimateAmount);
      setDEstimateTotal(data && data.result[0].TotalEstimate);
      setDRemark(data && data.result[0].Remark);
      setDFileNo(data && data.result[0].FileNo);
      setDCounselName(data && data.result[0].Counsel);
      setDCoCounselName(data && data.result[0].Cocounsel);
    }
  };

  //        -------- Viwe by ID DATA --------

  const getById = async (id) => {
    const data = await userDetails(id, "Estimates/ViewEstimatesById");
    const editDetail = await userDetails(
      id,
      "Estimates/ViewEstimatesDetailById"
    );

    if (data === "token") {
      setTimeout(() => {
        closeEdit.click();
        navigate("/");
      }, 2000);
    } else {
      setFormData2(editDetail && editDetail.result);

      setEditID(data && data.result[0].Id);
      setEditCaseID(data && data.result[0].CaseID);
      setEditDate(data && data.result[0].Date);
      setEditIsFinal(data && data.result[0].IsFinal);
      setEditDiscount(data && data.result[0].Discount);
      setEditRemark(data && data.result[0].Remark);
      setEditEstimateTotal(data && data.result[0].TotalEstimate);
    }
  };

  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  //        -------- SUBMIT DATA ---------

  const submitHandler = async (event) => {
    event.preventDefault();
    setSaveButton(false);
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CaseID", CaseID);
    formData.append("Remark", remark.toUpperCase());
    formData.append("TotalEstimate", estimateTotal);
    formData.append("Date", Dates);
    formData.append("IsFinal", IsFinal);
    formData.append("Discount", Discount);

    if (estimateAmount < 0) {
      toast.error("Estimate Amount is invalid");
      return null;
    } else {
      formData.append("EstimateAmount", estimateAmount);
    }
    formData.append("CreatedBy", localStorage.getItem("UserId"));

    try {
      const response = await fetch(`${baseUrl}Estimates/AddEstimates`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].message === "Success") {
        const EstimateID = data && data.result[0].LastInsertID;
        const rowsData = [];

        formData1.forEach((item) => {
          rowsData.push({
            DescriptionData: item.description,
            AmountData: item.amount,
          });
        });

        try {
          for (const row of rowsData) {
            estimatesDetail.append("AdminID", localStorage.getItem("id"));
            estimatesDetail.append("Amount", row.AmountData);
            estimatesDetail.append(
              "Description",
              row.DescriptionData.toUpperCase()
            );
            estimatesDetail.append("EstimateID", EstimateID);
            estimatesDetail.append("CreatedBy", localStorage.getItem("UserId"));

            const response2 = await fetch(
              `${baseUrl}Estimates/AddEstimatesDetail`,
              {
                method: "POST",
                body: estimatesDetail,
                headers: {
                  Authorization: `${localStorage.getItem("access_token")}`,
                  Accept: "*/*",
                },
              }
            );

            const data2 = await response2.json();
            if (data2 && data2.result.message == "Session Time Expire.") {
              toast.error("Token expired please login again");
              setTimeout(() => {
                navigate("/");
              }, 3000);
            }
            if (data2.result.length && data2.result[0].message === "Success") {
              toast.success("Estimate added successfully");
              handleClose();
              filterActive ? filterApi() : getCaseView();
              clearForm();
              setCaseID("");
              setDates(currentDate);
              setIsFinal("1");
              setRemark("");
              setEstimateTotal("");
              setEstimateAmount("");
              setDiscount("");
              setFormData1([{ description: "", amount: "" }]);
              closeButton.click();
              setSaveButton(true);
            } else {
              toast.error(data.result.message);
              setSaveButton(true);
            }
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        toast.error(data.result[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        -------- EDIT ESTIMATE ---------

  const editEstimatesDetail = new FormData();
  const editEstimatesDelete = new FormData();

  const submitEditHandler = async (event) => {
    event.preventDefault();
    formEditData.append("ID", EditID);
    formEditData.append("AdminID", localStorage.getItem("id"));
    formEditData.append("CaseID", EditCaseID);
    formEditData.append("Date", EditDate);
    formEditData.append("IsFinal", EditIsFinal);
    formEditData.append("Discount", editDiscount);
    formEditData.append("Remark", editRemark.toUpperCase());
    formEditData.append("TotalEstimate", editEstimateTotal);

    if (editEstimateAmount < 0) {
      toast.error("Estimate Amount is invalid");
      return null;
    } else {
      formEditData.append("EstimateAmount", editEstimateAmount);
    }

    formEditData.append("UpdatedBy", localStorage.getItem("UserId"));

    try {
      const response = await fetch(`${baseUrl}Estimates/EditEstimates`, {
        method: "POST",
        body: formEditData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeEdit.click();
          navigate("/");
        }, 2000);
      }

      if (data.result.length && data.result[0].code === "200") {
        const EstimateID = data && data.result[0].Id;

        editEstimatesDelete.append("AdminID", localStorage.getItem("id"));
        editEstimatesDelete.append("EstimateID", EstimateID);
        editEstimatesDelete.append("DeletedBy", localStorage.getItem("UserId"));

        try {
          const response3 = await fetch(
            `${baseUrl}Estimates/DeleteEstimatesDeatil`,
            {
              method: "POST",
              body: editEstimatesDelete,
              headers: {
                Authorization: `${localStorage.getItem("access_token")}`,
                Accept: "*/*",
              },
            }
          );

          const data3 = await response3.json();
          if (data3 && data3.result.message == "Session Time Expire.") {
            toast.error("Token expired please login again");
            setTimeout(() => {
              navigate("/");
            }, 3000);
          }
          if (data3 && data3.result.code === 200) {
            const rowsData = [];

            formData2.forEach((item) => {
              rowsData.push({
                DescriptionData: item.Description,
                AmountData: item.Amount,
              });
            });

            try {
              for (const row of rowsData) {
                editEstimatesDetail.append(
                  "AdminID",
                  localStorage.getItem("id")
                );
                editEstimatesDetail.append("Amount", row.AmountData);
                editEstimatesDetail.append(
                  "Description",
                  row.DescriptionData.toUpperCase()
                );
                editEstimatesDetail.append("EstimateID", EstimateID);
                editEstimatesDetail.append(
                  "CreatedBy",
                  localStorage.getItem("UserId")
                );

                const response2 = await fetch(
                  `${baseUrl}Estimates/AddEstimatesDetail`,
                  {
                    method: "POST",
                    body: editEstimatesDetail,
                    headers: {
                      Authorization: `${localStorage.getItem("access_token")}`,
                      Accept: "*/*",
                    },
                  }
                );

                const data2 = await response2.json();
                if (data2 && data2.result.message == "Session Time Expire.") {
                  toast.error("Token expired please login again");
                  setTimeout(() => {
                    navigate("/");
                  }, 3000);
                }
                if (data2.result.length && data2.result[0].code === "200") {
                  toast.success(" Updated successfully");
                  closeEdit.click();
                  filterActive ? filterApi() : getCaseView();
                  clearForm();
                } else {
                  toast.error(data2 && data2.result.message);
                }
              }
            } catch (error) {
              console.error(error);
            }
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // ---------- FILTER API ---------

  const filterApi = async () => {
    setLoading(true);
    filterform.append("AdminID", localStorage.getItem("id"));
    filterform.append("UserID", localStorage.getItem("uId"));
    filterform.append("CaseID", fCaseId);
    filterform.append("ClientID", fClientID);
    filterform.append("From", fFromDate);
    filterform.append("To", fToDate);
    try {
      const response = await fetch(`${baseUrl}Estimates/ViewEstimatefilter`, {
        method: "POST",
        body: filterform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 2000);
      }

      if (
        data &&
        data.result.length &&
        data &&
        data.result[0].message === "Success"
      ) {
        setFilterActive(true);
        setLoading(false);
        setCaseLeadData(data.result);

        let sum =
          data &&
          data.result.reduce(function (prev, current) {
            return prev + +current.EstimateAmount;
          }, 0);
        setTotalInvoiceAmount(sum);
      } else {
        setLoading(false);
        setTotalInvoiceAmount("0");
        setCaseLeadData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterCancel = () => {
    clearForm();
    setCaseID("");
    setFCaseId("");
    setFClientID("");
    setFFromDate("");
    setFToDate("");
    getCaseView();
    setFilterActive(false);
  };

  const handleFilterCase = (selectedOption) => {
    if (selectedOption === null) {
      setFCaseId("");
    } else {
      setFCaseId(selectedOption.value);
    }
  };

  const handleFilterClient = (selectedOption) => {
    if (selectedOption === null) {
      setFClientID("");
    } else {
      setFClientID(selectedOption.value);
    }
  };

  //        -------- TABLE ---------

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      header: "Action",
      // minSize: "10px",
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      Cell: (row) => {
        return (
          <>
            <div style={{ display: "flex", gap: "15px" }}>
              {isEdit !== "0" && (
                <span
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalLongEdit"
                  onClick={() => getById(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/edit.svg"
                    alt="Edit"
                    title="Edit"
                  />
                </span>
              )}

              <span
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongDetail"
                onClick={() => details(row.row.original.Id)}
              >
                <img
                  src="assets/img/icons/eye.svg"
                  alt="Details"
                  title="Details"
                />
              </span>
              <span>
                <a
                  style={{
                    cursor: "pointer",
                    display: isStatus === "0" ? "none" : "block",
                  }}
                  onClick={() => deActive(row.row.original)}
                >
                  {row.row.original.Status === "1" ? (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Up.svg"
                      title="Status"
                    />
                  ) : (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Down.svg"
                      title="Status"
                    />
                  )}
                </a>
              </span>
              <span>
                <Link
                  target="_blank"
                  to={`/View_Estimate_PDF/${row.row.original.Id}`}
                  title="PDF"
                >
                  <img
                    src="assets/img/icons/pdf.svg"
                    className="pdf"
                    title="PDF"
                  />
                </Link>
              </span>
              {isDelete !== "0" && (
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style={{ cursor: "pointer" }}
                  onClick={() => setDelId(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/delete.svg"
                    alt="Delete"
                    title="Delete"
                  />
                </span>
              )}
            </div>
          </>
        );
      },
    }),
    columnHelper.accessor("Date", {
      header: "Date",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.Date === null || row.row.original.Date === ""
              ? "-"
              : new Date(row.row.original.Date).toLocaleDateString("en-GB")}
          </div>
        );
      },
    }),

    columnHelper.accessor("Id", {
      header: "Estimate No.",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.Id === null || row.row.original.Id === ""
              ? "-"
              : row.row.original.Id}
          </div>
        );
      },
    }),

    columnHelper.accessor("FileNo", {
      header: "File No",
    }),

    columnHelper.accessor("CaseNo", {
      header: "Case No",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CaseNo === null || row.row.original.CaseNo === ""
              ? "-"
              : row.row.original.CaseNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseName", {
      header: "Case Name",
      Cell: (row) => {
        return (
          <div style={{ minWidth: "350px" }} className="upperCase">
            <a
              title="Case Details"
              href={`#/view-casedetails/${row.row.original.CaseID}`}
              className="visuald_case"
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              {row.row.original.CaseName}
            </a>
          </div>
        );
      },
    }),

    columnHelper.accessor("EstimateAmount", {
      header: "Estimate Amount",
      footer: `${totalInvoiceAmount} Rs.`,
      Cell: (row) => {
        return (
          <div>
            {row.row.original.EstimateAmount === null ||
            row.row.original.EstimateAmount === ""
              ? "-"
              : row.row.original.EstimateAmount + " Rs"}
          </div>
        );
      },
    }),

    columnHelper.accessor("IsFinal", {
      header: "Is Final ?",
      Cell: (row) => {
        return <div>{row.row.original.IsFinal === "1" ? "YES" : "NO"}</div>;
      },
    }),

    columnHelper.accessor("Counsel", {
      header: "Counsel",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Counsel === null ||
            row.row.original.Counsel === ""
              ? "-"
              : row.row.original.Counsel}
          </div>
        );
      },
    }),

    columnHelper.accessor("Cocounsel", {
      header: "Co-counsel",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Cocounsel === null ||
            row.row.original.Cocounsel === ""
              ? "-"
              : row.row.original.Cocounsel}
          </div>
        );
      },
    }),

    columnHelper.accessor("ClientName", {
      header: "Client",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.ClientName}
            <br />
            {row.row.original.ClientlastName}
            <br />
            {row.row.original.MobileNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("UpdatedBy", {
      header: "Last-Modified",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.UpdatedBy === null ||
            row.row.original.UpdatedBy === ""
              ? "-"
              : row.row.original.UpdatedBy}
            <br />
            {formateDateTime(row.row.original.UpdatedDate)}
          </div>
        );
      },
    }),

    columnHelper.accessor("Status", {
      header: "Status",
      Cell: (row) => {
        return (
          <div>{row.row.original.Status === "1" ? "ACTIVE" : "DEACTIVE"}</div>
        );
      },
    }),

    columnHelper.accessor("AddedBy", {
      header: "Added By",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.AddedBy === null ||
            row.row.original.AddedBy === ""
              ? "-"
              : row.row.original.AddedBy}
            <br />
            {formateDateTime(row.row.original.CreatedDate)}
          </div>
        );
      },
    }),
  ];

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getCaseView();
    }
  }, []);
  const [showField, setShowField] = useState(false);
  const handleAction = () => {
    setShowField(true);
    if (showField === true) {
      setShowField(false);
    }
  };

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  // ---------DataTable-----

  const excelData =
    caseLeadData &&
    caseLeadData.map((obj) => {
      const { Id, code, IsFinal, message, Status, AdminID, ...rest } = obj;
      return rest;
    });

  const handleExportData = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });

    const formattedDate = new Date().toISOString().slice(0, 10); // Get today's date
    const filename = `Estimate|LegalCRM__${formattedDate}.csv`;

    const csv = generateCsv(csvConfig)(excelData);
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const columnsToExport = [
      { id: "serialNo", header: "S No." },
      ...columns.filter((column) => selectedColumns.includes(column.id)),
    ];

    const tableHeaders = columnsToExport.map((c) => c.header);

    const formattedDate = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const filename = `Estimate|LegalCRM__${formattedDate}.pdf`;
    const addWatermarkToFirstPage = () => {
      const watermarkHeight = 20;

      // Calculate positioning to center the watermark on each page

      doc.setFontSize(10);
      doc.text(
        10,
        watermarkHeight + -10,
        `Generated by : ${localStorage.getItem("name")} `
      );
      doc.text(172, watermarkHeight + -10, `Date: ${formattedDate}`);
      doc.setFontSize(16);
      doc.text("Estimate Table", 84, 20);
    };

    const watermarkHeight = 20;

    addWatermarkToFirstPage();
    const tableData = rows.map((row, index) => {
      const formattedDateInPdf = row.original["Date"]
        ? format(new Date(row.original["Date"]), "dd/MM/yyyy")
        : "";

      return [
        { content: (index + 1).toString(), styles: { fontStyle: "bold" } }, // Serial number
        ...columnsToExport.slice(1).map((column) => {
          // Exclude the first column (serial number) when mapping
          if (column.id === "Date") {
            return formattedDateInPdf;
          }

          const value =
            column.id in row.original ? row.original[column.id] : "";
          return value !== null && value !== undefined ? value : "";
        }),
      ];
    });

    tableData.push([]);
    const extraRow = ["", "", "", "", "Total", `${totalInvoiceAmount}/-`];
    tableData.push(extraRow);

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY: watermarkHeight + 5,
    });

    // Add a heading to the table

    // Save the PDF to a Blob
    const pdfBlob = doc.output("blob");

    // Create a blob URL
    const blobUrl = URL.createObjectURL(pdfBlob);

    // Open the URL in a new tab
    window.open(blobUrl, "_blank");

    // Optionally revoke the object URL after opening the new tab
    URL.revokeObjectURL(blobUrl);
    // doc.save(filename);
  };

  const table = useMaterialReactTable({
    columns,
    data: caseLeadData,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    initialState: {
      columnVisibility: {
        Status: false,
        AddedBy: false,
        UpdatedBy: false,
        UpdatedDate: false,
        CreatedDate: false,
      },
    },
    enableStickyHeader: true,
    enableRowNumbers: true,
    state: {
      isLoading: loading,
    },
    muiCircularProgressProps: {
      color: "primary",
      thickness: 5,
      size: 55,
      style: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000,
      },
    },
    muiSkeletonProps: {
      animation: "pulse",
      height: 20,
    },
    muiPaginationProps: {
      rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export Excel
        </Button>

        <Button
        disabled={!table || table.getPrePaginationRowModel().rows.length === 0}
        onClick={handleExportButtonClick}
        startIcon={<FileDownloadIcon />}
      >
        Export PDF
      </Button>
      <Dialog open={open} onClose={handleClose1}>
        <DialogTitle>Select Columns To Export</DialogTitle>
        <DialogContent>
            {columns.map((column) =>
              column.header !== "Action" ? (
                <div key={column.id}>
                  <Checkbox
                    checked={selectedColumns.includes(column.id)}
                    onChange={() => handleToggleColumn(column.id)}
                  />
                  {column.header}
                </div>
              ) : (
                ""
              )
            )}
          </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1}>Cancel</Button>
          <Button onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}>Export</Button>
        </DialogActions>
      </Dialog>
      </Box>
    ),
  });

  return (
    <>
      <DocumentTitle title="ESTIMATE | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div className="wraper">
            <Header />
            <div className="mainWraper">
              <Sidebar />
              <div className="pageBody">
                {/* <div className="accountContainer userListContainer"> */}
                <div className="pageContent">
                  <div className="PageTitle">
                    <h3>Estimate List</h3>
                    <a
                      style={{
                        display: isAdd === "0" ? "none" : "block",
                      }}
                      type="button"
                      className="btn btn-primary btn_client headbtn"
                      data-toggle="modal"
                      data-target="#exampleModalLong"
                    >
                      Add Estimate <img src="assets/img/icons/add.svg" />
                    </a>
                  </div>

                  <div
                    className="filter_type add_wrap"
                    style={{
                      display: isView === "0" ? "none" : "block",
                    }}
                  >
                    <div className="form_flex1 flex_wrap  caseMobile">
                      <div className="form-group1 zIndex1">
                        <label>File No.</label>

                        <Select
                          className="basic-single"
                          placeholder="Please Select "
                          classNamePrefix="select"
                          onChange={handleFilterCase}
                          isClearable={true}
                          isSearchable={true}
                          name="color"
                          options={optionsEvent}
                        />
                      </div>
                      <div className="form-group1 zIndex2">
                        <label>Client Name</label>

                        <Select
                          className="basic-single"
                          placeholder="Please Select "
                          classNamePrefix="select"
                          onChange={handleFilterClient}
                          isClearable={true}
                          isSearchable={true}
                          name="color"
                          options={fClientOption}
                        />
                      </div>
                      <div className="form-group1">
                        <label>From Date</label>
                        <input
                          // max={currentDate}
                          autoComplete="off"
                          className="name"
                          type="date"
                          value={fFromDate}
                          onChange={(e) => setFFromDate(e.target.value)}
                        />
                      </div>
                      <div className="form-group1">
                        <label>To Date</label>
                        <input
                          autoComplete="off"
                          className="name"
                          type="date"
                          value={fToDate}
                          onChange={(e) => setFToDate(e.target.value)}
                        />
                      </div>
                      <div className="form-group1 caseMobile btnGroup">
                        <div className="btn_main casebtn">
                          <button
                            type="submit"
                            className="btn btn_client"
                            onClick={filterApi}
                          >
                            Search
                          </button>
                          <button
                            type="button"
                            className="btn btn_client"
                            onClick={filterCancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {caseLeadData &&
                  caseLeadData.length > 0 &&
                  caseLeadData[0].message === "Data not found" ? (
                    <h3 className="visuald">
                      There are no records to display here
                    </h3>
                  ) : (
                    <>
                      <div
                        style={{
                          display: isView === "0" ? "none" : "block",
                        }}
                        className="searDesign"
                      >
                        <MaterialReactTable table={table} />
                      </div>
                    </>
                  )}
                </div>
                {/* </div> */}
              </div>
            </div>
            <Footer />
          </div>
        )}
      </DocumentTitle>
      {/*-------- DELETE FORM -------*/}

      <div
        className="modal fade sc_modal"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close mm"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <h4>Delete Row</h4>
              <p>Are you sure you want to delete this Estimate ?</p>
              <ul>
                <li>
                  <a className="btn btn-secondary" data-bs-dismiss="modal">
                    Cancel
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-danger"
                    onClick={() => deleteCaseLead(delId)}
                  >
                    Delete
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/*-------- ADD FORM ---------*/}

      <div
        className="modal fade"
        id="exampleModalLong"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
        show={show}
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="add_wrap">
              <div className="main_heading">
                <h2>Add Estimate </h2>
              </div>
            </div>
            <div className="popup_modal ">
              <button
                type="button"
                className="close btn_popup"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="dashed_popup">
                <form role="form" onSubmit={submitHandler}>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="name">
                        File No<text className="m_star">*</text>
                      </label>
                      <Select
                        required
                        className="basic-single name"
                        placeholder="Please Select "
                        classNamePrefix="select"
                        onChange={handleSelectChange}
                        isClearable={true}
                        isSearchable={true}
                        name="color"
                        options={options}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="uname">Date </label>
                      <input
                        isMulti
                        type="Date"
                        value={Dates}
                        max={currentDate}
                        className="name"
                        onChange={(e) => setDates(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="uname">Is Final</label>
                      <select
                        className="name"
                        onChange={(e) => setIsFinal(e.target.value)}
                        value={IsFinal}
                      >
                        <option value="1">YES</option>
                        <option value="0">NO</option>
                      </select>
                    </div>
                  </div>
                  <hr />
                  <div>
                    {formData1 &&
                      formData1.map((row, index) => (
                        <div key={index}>
                          <div className="form_flex caseMobile">
                            <div className="form-group">
                              <label htmlFor={`description-${index}`}>
                                Description
                              </label>
                              <input
                                value={row.description}
                                type="text"
                                className="name"
                                placeholder="Enter Description"
                                onChange={(e) =>
                                  handleDescriptionChange(index, e.target.value)
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor={`amount-${index}`}>Amount</label>
                              <input
                                value={row.amount}
                                type="text"
                                className="name"
                                placeholder="Enter Amount"
                                onChange={(e) =>
                                  handleAmountChange(index, e.target.value)
                                }
                              />
                            </div>
                            {index === 0 && (
                              <a
                                onClick={addRow}
                                style={{
                                  color: "white",
                                  cursor: "pointer",
                                  margin: "10px",
                                  padding: "6px 10px",
                                  background: "green",
                                }}
                              >
                                +
                              </a>
                            )}

                            {index !== 0 && (
                              <a
                                onClick={() => removeRow(index)}
                                style={{
                                  color: "white",
                                  cursor: "pointer",
                                  margin: "10px",
                                  padding: "6px 6px",
                                }}
                              >
                                ❌
                              </a>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                  <hr />
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="uname">Remark</label>
                      <textarea
                        type="text"
                        placeholder="Enter Remark"
                        className="name"
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <div>
                        <label htmlFor="uname">Estimate Total</label>
                        <input
                          readOnly
                          type="text"
                          className="name"
                          value={estimateTotal}
                        />
                      </div>

                      <div>
                        <label htmlFor="uname">Discount ₹</label>
                        <input
                          type="text"
                          placeholder="Enter Discount"
                          className="name"
                          value={Discount}
                          onChange={(e) => setDiscount(e.target.value)}
                        />
                      </div>

                      <div>
                        <label htmlFor="uname">Final Amount </label>
                        <input
                          readOnly
                          type="text"
                          className="name"
                          value={estimateAmount}
                        />
                      </div>
                    </div>
                  </div>
                  {saveButton ? (
                    <div className="btn_main caseMobile">
                      <button type="submit" className="btn_save">
                        Save
                      </button>
                      <button
                        type="button"
                        data-dismiss="modal"
                        className="btn_save btn_cancle"
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*-------- EDIT TASK FORM ---------*/}

      <div
        className="modal fade"
        id="exampleModalLongEdit"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-xl"
          role="document"
          // style={{ maxWidth: "75%" }}
        >
          <div className="modal-content">
            <div className="add_wrap">
              <div className="main_heading">
                <h2>Edit Estimate</h2>
              </div>
            </div>
            <div className="popup_modal ">
              <button
                type="button"
                className="close e_popup btn_popup"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="dashed_popup">
                <form role="form" onSubmit={submitEditHandler}>
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="name">
                        File No<text className="m_star">*</text>
                      </label>

                      <select
                        required
                        className="name"
                        id="cars"
                        onChange={(e) => setEditCaseID(e.target.value)}
                        value={EditCaseID}
                      >
                        <option value="">please Select</option>
                        {FileNo && FileNo.length ? (
                          FileNo.map((val, index) => {
                            return (
                              <option
                                key={index}
                                value={val.CaseID}
                                selected={val.value === EditCaseID}
                              >
                                {val.FileNo && `${val.FileNo} /`}{" "}
                                {val.CaseName && `${val.CaseName} /`}{" "}
                                {val.CaseYear}
                              </option>
                            );
                          })
                        ) : (
                          <option value="">Loading...</option>
                        )}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="uname">Date </label>
                      <input
                        type="Date"
                        max={currentDate}
                        className="name"
                        value={EditDate}
                        onChange={(e) => setEditDate(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="uname">Is Final</label>
                      <select
                        className="name"
                        onChange={(e) => setEditIsFinal(e.target.value)}
                        value={EditIsFinal}
                      >
                        <option value="1">YES</option>
                        <option value="0">NO</option>
                      </select>
                    </div>
                  </div>
                  <hr />
                  <div>
                    {formData2 &&
                      formData2.map((val, index) => (
                        <div key={index}>
                          <div className="form_flex caseMobile">
                            <div className="form-group">
                              <label htmlFor={`description-${index}`}>
                                Description
                              </label>
                              <input
                                type="text"
                                className="name"
                                placeholder="Enter Description"
                                value={val.Description}
                                onChange={(e) =>
                                  editHandleDescriptionChange(
                                    index,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor={`amount-${index}`}>Amount</label>
                              <input
                                type="text"
                                className="name"
                                placeholder="Enter Amount"
                                value={val.Amount === "0.00" ? "" : val.Amount}
                                onChange={(e) =>
                                  editHandleAmountChange(index, e.target.value)
                                }
                              />
                            </div>
                            {index === 0 && (
                              <a
                                onClick={editAddRow}
                                style={{
                                  cursor: "pointer",
                                  color: "white",
                                  margin: "10px",
                                  padding: "6px 10px",
                                  background: "green",
                                }}
                              >
                                +
                              </a>
                            )}

                            {index !== 0 && (
                              <a
                                onClick={() => editRremoveRow(index)}
                                style={{
                                  cursor: "pointer",
                                  color: "white",
                                  margin: "10px",
                                  padding: "6px 6px",
                                }}
                              >
                                ❌
                              </a>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                  <hr />
                  <div className="form_flex caseMobile">
                    <div className="form-group">
                      <label htmlFor="uname">Remark</label>
                      <textarea
                        type="text"
                        placeholder="Enter Remark"
                        className="name"
                        value={editRemark}
                        onChange={(e) => setEditRemark(e.target.value)}
                      />
                    </div>

                    <div className="form-group">
                      <div>
                        <label htmlFor="uname">Estimate Total </label>
                        <input
                          readOnly
                          type="text"
                          className="name"
                          value={editEstimateTotal}
                        />
                      </div>

                      <div>
                        <label htmlFor="uname">Discount ₹</label>
                        <input
                          type="text"
                          placeholder="Enter Discount"
                          className="name"
                          value={editDiscount}
                          onChange={(e) => setEditDiscount(e.target.value)}
                        />
                      </div>

                      <div>
                        <label htmlFor="uname">Final Amount </label>
                        <input
                          readOnly
                          type="text"
                          className="name"
                          value={editEstimateAmount}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="btn_main caseMobile">
                    <button type="submit" className="btn_save">
                      Save
                    </button>
                    <button
                      type="button"
                      data-dismiss="modal"
                      className="btn_save btn_cancle"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*-------- DETAILS ---------*/}

      <div
        className="modal fade"
        id="exampleModalLongDetail"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg"
          role="document"
          // style={{ maxWidth: "50%" }}
        >
          <div className="modal-content">
            <div className="add_wrap">
              <div className="main_heading">
                <h2>Estimate Details</h2>
              </div>
            </div>
            <div className="popup_modal ">
              <button
                type="button"
                className="close ab btn_popup"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="dashed_popup">
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>File No &nbsp;: &nbsp; {dFileNo} </h4>
                  </div>
                  <div className="form-group">
                    <h4>Case Name &nbsp;: &nbsp; {dCaseID} </h4>
                  </div>
                </div>
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>
                      Client Name &nbsp;: &nbsp;{" "}
                      {dClientName === null || dClientName === ""
                        ? "-"
                        : dClientName}{" "}
                      {dClientLastName}{" "}
                    </h4>
                  </div>
                  <div className="form-group">
                    <h4>Client Mobile No. &nbsp;: &nbsp; {dClientMobile}</h4>
                  </div>
                </div>
                <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Counsel Name &nbsp;: &nbsp;{" "}
                            {dCounselName == null ? "-" : dCounselName}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Co Counsel Name &nbsp;:&nbsp;{" "}
                            {dCoCounselName == null ? "-" : dCoCounselName}{" "}
                          </h4>
                        </div>
                      </div>
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>
                      Is Final &nbsp;: &nbsp; {dIsFinal === "1" ? "YES" : "NO"}{" "}
                    </h4>
                  </div>
                  <div className="form-group">
                    <h4>Final Amount &nbsp;: &nbsp; {dEstimateAmount} </h4>
                  </div>
                </div>
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>Total Estimate &nbsp;: &nbsp; {dEstimateTotal} </h4>
                  </div>
                  <div className="form-group">
                    <h4>Discount &nbsp;: &nbsp; {dDiscount} </h4>
                  </div>
                </div>
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>
                      Date &nbsp;: &nbsp;{" "}
                      {dDate === "0000-00-00" ? "-" : formatDate1(dDate)}{" "}
                    </h4>
                  </div>
                </div>
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <h4>
                      Remark &nbsp;: &nbsp; {dRemark == "" ? "-" : dRemark}{" "}
                    </h4>
                  </div>
                </div>
                <br />
                <div className="form_flex caseMobile">
                  <div className="form-group">
                    <table className="table detail_table">
                      <tr>
                        <th>S. No</th>
                        <th>Amount</th>
                        <th>Description</th>
                      </tr>

                      {detailData &&
                        detailData.map((val, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{val.Amount}</td>
                              <td>
                                {val.Description === "" ? "-" : val.Description}
                              </td>
                            </tr>
                          );
                        })}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*-------- TOASTER ---------*/}

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Estimates;
