import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const ViewRoleApi = createApi({
  reducerPath: 'ViewRoleApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.lawyerhunt.in/api/Master/ViewRole',

    prepareHeaders: (headers) => {
      const token = localStorage.getItem('access_token'); 

      if (token) {
        headers.set('Authorization', `${token}`);
        headers.set(  'Accept', '*/*')
      }
      
    },
  }),

  endpoints: (builder) => ({
    getViewRole: builder.query({
      query: () => {
        const formData = new FormData();
        formData.append("AdminID", localStorage.getItem('id') );
    
        return {
        
          method: 'POST',
    
          body:formData
        };
      },
    }),
  }),
});

export const { useGetViewRoleQuery } = ViewRoleApi;

export default ViewRoleApi;