import { toast, ToastContainer } from "react-toastify";
import numberToWords from "number-to-words";
import "react-toastify/dist/ReactToastify.css";

export const getCurrentDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  const yyyy = today.getFullYear();
  return `${dd}-${mm}-${yyyy}`;
};

export const convertToIndianNumbering = (numericValue) => {
  const lakhValue = numericValue / 100000;
  const remainder = numericValue % 100000;

  const wordsLakh = lakhValue >= 1 ? numberToWords.toWords(Math.floor(lakhValue)) + ' LAKH' : '';
  const wordsRemainder = numberToWords.toWords(remainder);

  return `${wordsLakh} ${wordsRemainder} RUPEES ONLY/-`;
};

export const withUidApi = async ( path ) => {
  form.append("AdminID", localStorage.getItem("id"));
  form.append("UserID", localStorage.getItem("uId"));
  try {
    const response = await fetch(
      `${baseUrl}${path}`,
      {
        method: "POST",
        body : form,
        headers: {
          "Authorization": `${localStorage.getItem("access_token")}`,
          'Accept': '*/*'
        }
      }
    );
    const data = await response.json();
    if (data && data.result.message == "Session Time Expire.") {
      toast.error('Token expired please login again');
        return "token";  
    }
    
    if (data && data.result[0].message === "Success") {
      return data.result
    } else{
      return null
    }

  }
  catch (error) {
    console.error(error);
  }
};

export const customStyles = {
 
  cells: {
    style: {
      padding:"10px ",
      fontSize: '14px',
      margin:"-5px",
    },
  },

  headRow: {
    style: {
     
      // margin:"0px",
      color: "white",
      fontSize: "14px",
      backgroundColor: "#7a0103",
      borderRadius: "10px 10px 0px 0px",
    }
  },
}
//--------- Formate Date ----------
  //--------- By Disha (24-08-2023) ---------

 export const pdfSplit = (data) => {
  const dataPart = data.split(".");
  return dataPart[1];
 }

  export const formatDate1 = (apiDate) => {
    if (!apiDate) {
      return "";
    }
  
    const dateParts = apiDate.split("-");
    const day = dateParts[2];
    const month = dateParts[1];
    const year = dateParts[0];
  
    return `${day}-${month}-${year}`;
  };

  export const formateDateTime = (apiDate) => {
    if (!apiDate) {
      return "";
    }
  
    const onlyDate = apiDate.split(" ");

    const dateParts = onlyDate[0].split("-");
    const day = dateParts[2];
    const month = dateParts[1];
    const year = dateParts[0];
  
    return `${day}-${month}-${year}`;
  };
  
  //--------- Formate Date CLose ----------
  export function clearForm() {
    
    var fields = document.getElementsByClassName("name");
    for (var i = 0; i < fields.length; i++) {
      fields[i].value = "";
    }
  }
const baseUrl = "https://api.lawyerhunt.in/api/";

const form = new FormData()
const userDetailsForm = new FormData()

export const viewData = async ( path ) => {
    form.append("AdminID", localStorage.getItem("id"));
    try {
      const response = await fetch(
        `${baseUrl}${path}`,
        {
          method: "POST",
          body : form,
          headers: {
            "Authorization": `${localStorage.getItem("access_token")}`,
            'Accept': '*/*'
          }
        }
      );
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error('Token expired please login again');
          return "token";  
      }
      
      if (data && data.result[0].message === "Success") {
        return data.result
      } else {
        return null
      }

    }
    catch (error) {
      console.error(error);
    }
};

export const deleteUser = async ( id, path ) => { 
    form.append("AdminID", localStorage.getItem("id"));
    form.append("DeletedBy", localStorage.getItem("UserId"));
    form.append("ID", id );
    try {
      const response = await fetch(
        `${baseUrl}${path}`,
        {
          method: "POST",
          body: form,
          headers: {
            "Authorization": `${localStorage.getItem("access_token")}`,
            'Accept': '*/*'
          }
        }
      );
      
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error('Token expired please login again');
          return "token";  
      }

      if (data.result.code === 200) {
        toast.success(data.result.message)
        return true;
      } else{
        toast.error(data.result.message)

      }
    }
    catch (error) {
      console.error(error);
    }
  };

export  const userDetails = async (id, path) => {
    userDetailsForm.append("AdminID", `${localStorage.getItem("id")}`);
    userDetailsForm.append("ID", id);
    try {
      const response = await fetch(
        `${baseUrl}${path}`,
        {
          method: "POST",
          body : userDetailsForm,
          headers: {
            "Authorization": `${localStorage.getItem("access_token")}`,
            'Accept': '*/*'
          }
        }
      );
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error('Token expired please login again');
          return "token";  
      }
      
      if (data && data.result[0].message === "Success") {
        return data;
      } else{
        toast.error(data.result[0].message)
      }     
    }
    catch (error) {
      console.error(error);
    }
  };

export const activeStatus = async (row, path) => {
    form.append("AdminID", `${localStorage.getItem("id")}`);
    form.append("ID", row.Id);
    form.append("Status", row.Status === "1" ? "0" : "1" );
    try{
      const response = await fetch(
        `${baseUrl}${path}`,
        {
          method: "POST",
          body: form,
          headers: {
            "Authorization": `${localStorage.getItem("access_token")}`,
            'Accept': '*/*'
          }
        }
      );
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error('Token expired please login again');
          return "token";  
      }
      if (data && data.result.message === "Status Updated Successfully") {
       if (row.Status === "1") {
        toast.error("Status Deactivated");
       } else{
        toast.success("Status Activated");
       }


        return true
      }
    }
    catch (error) {
      console.error(error);
    }
  }
export const getBranchCity = async () => {
  form.append("AdminID", localStorage.getItem("id"));
    try {
      const response = await fetch(
        `${baseUrl}Master/ViewBranchCity`,
        {
          method: "POST",
          body: form,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        }
      );

      const data = await response.json();
      if (data && data.result[0].message === "Success") {
        return data.result;
      }

    } catch (error) {
      console.error(error);
    }
  };

  export const getYear = async () => {
    try {
      const response = await fetch(
        `${baseUrl}Master/ViewYear`,
        {
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        }
      );
      const data = await response.json();
      if (data && data.result[0].code == 200) {
        return data.result;
      }

    } catch (error) {
      console.error(error);
    }
  };

export const getcourtView = async (path, id) => { 
    try {
        form.append("CourtID", id);
      const response = await fetch(
        `${baseUrl}${path}`,
        {
          method: "POST",
          body: form,
          headers: {
            "Authorization": `${localStorage.getItem("access_token")}`,
            'Accept': '*/*'
          }
        }
      );
      const data = await response.json();

      if (data && data.result.length) {
        return data.result;
      }
    }

    catch (error) {
      console.error(error);
    }
};

export const permission = async (id) => {
  form.append("AdminID", localStorage.getItem("id"));
  form.append("RoleID", localStorage.getItem("roleId"));
  form.append("ModuleID", id);
  try {
    const response = await fetch(
      `${baseUrl}Permission/ViewPermissionByModule`,
      {
        method: "POST",
        body: form,
        headers: {
          "Authorization": `${localStorage.getItem("access_token")}`,
          'Accept': '*/*'
        }
      }
    );
    
    const data = await response.json();
  
    if (data && data.result.length ){
      return data;
    } else {
    }
  }
  catch (error) {
    console.error(error);
  }
};

export const getFileNo = async () => {
  form.append("AdminID", localStorage.getItem("id"))
  try {
    const response = await fetch(
      `${baseUrl}Event/GetFilename`,
      {
        method: "POST",
        body:  form,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      }
    );
    const data = await response.json();
    if (data && data.result[0].code == 200) {
       return data.result;
    } 
  } catch (error) {
    console.error(error);
  }
};
export const getLetterTemp = async () => {
  form.append("AdminID", localStorage.getItem("id"))
  try {
    const response = await fetch(
      `${baseUrl}Master/ViewLetterTemplate`,
      {
        method: "POST",
        body:  form,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      }
    );
    const data = await response.json();
    if (data && data.result[0].code == 200) {
       return data.result;
    } 
  } catch (error) {
    console.error(error);
  }
};

export const getClient = async (path) => { 
  form.append("AdminID", localStorage.getItem("id"));
  try {
    const response = await fetch(
      `${baseUrl}${path}`,
      {
        method: "POST",
        body : form,
        headers: {
          "Authorization": `${localStorage.getItem("access_token")}`,
          'Accept': '*/*'
        }
      }
    );
    const data = await response.json();
  
    if (data && data.result[0].message == "Success") {
      return data.result;
    }
  }
  catch (error) {
    console.error(error);
  }
};

export const viewRole = async () => {
  form.append("AdminID", localStorage.getItem("id"));
  try {
    const response = await fetch(
      `${baseUrl}Master/ViewRole`,
      {
        method: "POST",
        body: form,
        headers: {
          "Authorization": `${localStorage.getItem("access_token")}`,
          'Accept': '*/*'
        }
      }
    );
    const data = await response.json();
    
    if (data && data.result[0].message == "Success") {
      return data;
    }
  }
  catch (error) {
    console.error(error);
  }
};
//----------------------- Doc Template ----------------------
//--------------- By Disha (24-08-2023) ---------------------
export const getDocTemp = async () => {
  form.append("AdminID", localStorage.getItem("id"))
  try {
    const response = await fetch(
      `${baseUrl}Master/ViewDocTemplate`,
      {
        method: "POST",
        body:  form,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      }
    );
    const data = await response.json();
    if (data && data.result[0].code == 200) {
       return data.result;
    } 
  } catch (error) {
    console.error(error);
  }
};
  <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />