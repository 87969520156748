import React, { useState, useRef } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import Select from "react-select";
import { useNavigate } from "react-router";
import DocumentTitle from "react-document-title";
import Footer from "./Footer";

const AddTask = () => {
  //        -------- TASK VARIABLE ---------
  const [saveButton, setSaveButton] = useState(true);
  const currentDate = new Date().toISOString().split("T")[0];
  const baseUrl = useContext(url);
  const navigate = useNavigate();
  const [DeadLine, setDeadLine] = useState(currentDate);
  const form = new FormData();
  const [TaskName, setTaskName] = useState("");
  const [Details, setDetails] = useState("");
  const [StageRemarks, setStageRemarks] = useState("");
  const [NextStage, setNextStage] = useState("");
  const [CaseID, setCaseID] = useState("");
  const [UserID, setUserID] = useState("");
  const [user, setuser] = useState([]);
  const [taskRelated, setTaskRelated] = useState("");
  const formData = new FormData();
  const [fileNoShow, setFileNoShow] = useState(true);
  const [taskOther, setTaskOther] = useState("");
  const [addCalender, setAddCalender] = useState(false);

  //        -------- VIEW TASK DATA ---------

  const getUser = async (path) => {
    form.append("AdminID", localStorage.getItem("id"));
    form.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}${path}`, {
        method: "POST",

        body: form,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.UserList[0].message == "Success") {
        return data.result.UserList;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCaseView = async () => {
    const [userData] = await Promise.all([
      getUser("Admin/ViewUser"),
      getFileNo(),
    ]);

    const counselData =
      userData && userData.filter((item) => item.Status === "1");
    setuser(counselData);
  };

  //        -------- Task Stage ---------
  const TaskStagef = new FormData();
  const [TaskStage, setTaskStage] = useState([]);
  const [activeTaskStage, setActiveTaskStage] = useState([]);
  const getTaskStage = async () => {
    TaskStagef.append("AdminID", localStorage.getItem("id"));
    try {
      const response = await fetch(`${baseUrl}Master/ViewTaskStage`, {
        method: "POST",
        body: TaskStagef,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].code == 200) {
        setTaskStage(data.result);
        setActiveTaskStage(data.result.filter((court) => court.Status === "1"));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddCalendar = (e) => {
    setAddCalender(e.target.checked);
  };

  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  const openInNewTab = (id) => {
    window.open(
      `https://api.lawyerhunt.in/GoogleCalendarController/authenticates/${id}`,
      "_blank",
      "width=600,height=600"
    );
  };

  const openInNewTabFun = (id) => {
    if (addCalender === true) {
      openInNewTab(id);
    }
  };

  //        -------- SUBMIT TASK DATA ---------

  const submitHandler = async (event) => {
    event.preventDefault();
    setSaveButton(false);
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("UserID", UserID);
    formData.append("DeadLine", DeadLine);
    formData.append("TaskName", TaskName.toUpperCase());
    formData.append("Details", Details.toUpperCase());
    formData.append("StageRemarks", StageRemarks.toUpperCase());
    formData.append("NextStage", NextStage);
    formData.append("CaseID", CaseID);
    formData.append("TaskRelated", taskRelated);
    formData.append("TaskOther", taskOther);
    formData.append("AddCalendar", addCalender === true ? "1" : "0");
    formData.append("CreatedBy", localStorage.getItem("UserId"));

    try {
      const response = await fetch(`${baseUrl}Task/AddTask`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].message === "Success") {
        toast.success("Task added successfully");
        setSaveButton(true);
        setTaskOther("");
        setTaskRelated("");
        handleClose();
        setUserID("");
        setDeadLine(currentDate);
        setTaskName("");
        setDetails("");
        setStageRemarks("");
        setNextStage("");
        openInNewTabFun(data.result[0].Id);
        setCaseID("");
        setTimeout(() => {
          navigate("/home");
        }, 2000);
      } else {
        toast.error(data.result.message);
        setSaveButton(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        -------- TABLE ---------

  /** Get File Name  */
  const Case = new FormData();
  const [optionsCase, setOptionsCase] = useState([]);

  const getFileNo = async () => {
    Case.append("AdminID", localStorage.getItem("id"));
    Case.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: Case,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }

      if (data && data.result[0].message === "Success") {
        const Mapped = data.result.map((item) => ({
          value: item.CaseID,
          label: `${item.FileNo} / ${item.CaseNo} / ${item.CaseName} / ${item.CaseYear}`,
        }));
        setOptionsCase(Mapped);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleTaskRelated = (event) => {
    setTaskRelated(event.target.value);
    if (event.target.value === "Other") {
      setFileNoShow(false);
      setCaseID("");
    } else {
      setFileNoShow(true);
      setTaskOther("");
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setCaseID(null);
    } else {
      setCaseID(selectedOption.value);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getCaseView();
    }
  }, []);

  useEffect(() => {
    getTaskStage();
  }, []);

  return (
    <>
      <DocumentTitle title="ADD TASK | LEGAL CRM">
        <div>
          <div className="wraper">
            <Header />
            <div className="mainWraper">
              <Sidebar />
              <div className="pageBody">
                <div className="pageContent">
                  <div className="PageTitle">
                    <h3>Add Task</h3>
                  </div>

                  <div>
                    <div></div>
                    <div className="popup_modal ">
                      <button
                        type="button"
                        className="close btn_popup"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                      <div className="dashed_popup">
                        <form role="form" onSubmit={submitHandler}>
                          <div className="form_flex caseMobile">
                            <div className="form-group">
                              <label htmlFor="uname">
                                Task Name <text className="m_star">*</text>
                              </label>
                              <input
                                className="name"
                                type="text"
                                placeholder="Enter Task Name"
                                name="uname"
                                value={TaskName}
                                onChange={(e) => setTaskName(e.target.value)}
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="uname">
                                Task Related<text className="m_star">*</text>
                              </label>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "40px",
                                  borderRadius: "4px",
                                  border: "1px solid #7a0000",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    marginLeft: "20px",
                                  }}
                                >
                                  <input
                                    required
                                    style={{ width: "20px" }}
                                    className="name"
                                    type="radio"
                                    id="case"
                                    name="taskrelated"
                                    value="Case"
                                    onChange={handleTaskRelated}
                                  />{" "}
                                  &nbsp;&nbsp;{" "}
                                  <label
                                    style={{ marginTop: "10px" }}
                                    htmlFor="case"
                                  >
                                    Case
                                  </label>
                                </div>
                                <div style={{ display: "flex" }}>
                                  <input
                                    required
                                    style={{ width: "20px" }}
                                    className="name"
                                    type="radio"
                                    id="other"
                                    name="taskrelated"
                                    value="Other"
                                    onChange={handleTaskRelated}
                                  />
                                  &nbsp;&nbsp;{" "}
                                  <label
                                    style={{ marginTop: "10px" }}
                                    htmlFor="other"
                                  >
                                    Other
                                  </label>
                                </div>
                              </div>
                            </div>
                            {fileNoShow ? (
                              <div className="form-group">
                                <label htmlFor="uname">
                                  File No.<text className="m_star">*</text>
                                </label>
                                <Select
                                  required
                                  className="basic-single"
                                  placeholder="Select "
                                  classNamePrefix="select"
                                  onChange={handleSelectChange}
                                  isClearable={true}
                                  isSearchable={true}
                                  name="color"
                                  options={optionsCase}
                                />
                              </div>
                            ) : (
                              <div className="form-group">
                                <label htmlFor="uname">
                                  Other <text className="m_star">*</text>
                                </label>
                                <input
                                  className="name"
                                  type="text"
                                  placeholder="Enter Other Name"
                                  name="uname"
                                  value={taskOther}
                                  onChange={(e) => setTaskOther(e.target.value)}
                                  required
                                />
                              </div>
                            )}
                          </div>
                          <div className="form_flex caseMobile">
                            <div className="form-group">
                              <label htmlFor="uname">
                                Current Stage<text className="m_star">*</text>
                              </label>
                              &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                              &nbsp;&nbsp;&nbsp;
                              <a className="visuald" href="/#/view-taskstage">
                                +Add Next Stage
                              </a>
                              <select
                                className="name"
                                name="cars"
                                id="cars"
                                value={NextStage}
                                onChange={(e) => setNextStage(e.target.value)}
                                required
                              >
                                <option value="">--Please Select--</option>

                                {activeTaskStage.map((val, index) => {
                                  return (
                                    <option key={index} value={val.Id}>
                                      {val.TaskStage}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="uname">
                                Assigned to User
                                <text className="m_star">*</text>
                              </label>
                              <select
                                required
                                className="name"
                                id="city"
                                value={UserID}
                                onChange={(e) => setUserID(e.target.value)}
                              >
                                <option value="">--Please Select--</option>
                                {user && user.length > 0 ? (
                                  user.map((val) => (
                                    <option key={val.id} value={val.Id}>
                                      {val.Name}
                                    </option>
                                  ))
                                ) : (
                                  <option value="">Loading...</option>
                                )}
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="uname">DeadLine Date </label>
                              <input
                                className="name"
                           type="datetime-local"
                                min={currentDate}
                                placeholder=""
                                name="uname"
                                value={DeadLine}
                                onChange={(e) => {
                                  setDeadLine(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="form_flex caseMobile col-md-8">
                       
                            <div className="form-group">
                              <label htmlFor="uname">Stage Remarks </label>
                              <input
                                className="name"
                                type="text"
                                name="uname"
                                placeholder="Enter Stage Remarks"
                                value={StageRemarks}
                                onChange={(e) =>
                                  setStageRemarks(e.target.value)
                                }
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="uname">
                                Details <text className="m_star">*</text>
                              </label>
                              <textarea
                                required
                                className="name"
                                type="text"
                                name="uname"
                                placeholder="Enter Details"
                                value={Details}
                                onChange={(e) => setDetails(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="form_flex caseMobile">
                            <div style={{ display: "flex" }}>
                              <input
                                style={{ width: "20px" }}
                                className="name"
                                type="checkbox"
                                id="addcalendar"
                                checked={addCalender}
                                onChange={handleAddCalendar}
                              />
                              &nbsp;&nbsp;{" "}
                              <label
                                style={{ marginTop: "10px" }}
                                htmlFor="addcalendar"
                              >
                                Would you like to schedule it on Google
                                Calendar?
                              </label>
                            </div>
                          </div>
                          {saveButton ? (
                            <div className="btn_main caseMobile">
                              <button type="submit" className="btn_save">
                                Save
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>

          {/*-------- TOASTER ---------*/}

          <ToastContainer
            position="top-right"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </DocumentTitle>
    </>
  );
};

export default AddTask;
