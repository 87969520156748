import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const ViewUserApi = createApi({
  reducerPath: 'ViewUserApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.lawyerhunt.in/api/Admin/ViewUser',

    prepareHeaders: (headers) => {
      const token = localStorage.getItem('access_token'); 

      if (token) {
        headers.set('Authorization', `${token}`);
        headers.set(  'Accept', '*/*')
      }
      
    },
  }),

  endpoints: (builder) => ({
    getViewUser: builder.query({
      query: () => {
        const formData = new FormData();
        formData.append("AdminID", localStorage.getItem('id') );
        formData.append("UserID", localStorage.getItem("uId"));
    
        return {
        
          method: 'POST',
    
          body:formData
        };
      },
    }),
  }),
});

export const { useGetViewUserQuery } = ViewUserApi;

export default ViewUserApi;