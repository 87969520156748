import React from "react";
import { BiSolidBusiness } from "react-icons/bi";
import { useNavigate } from "react-router";
import { useState } from "react";
import { useContext } from "react";
import { url } from "../index";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import DocumentTitle from "react-document-title";

const Login = () => {

  const recaptchaRef = React.createRef();
  const baseUrl = useContext(url);
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleCaptchaChange = (value) => {
    if (value) {
      setIsCaptchaValid(true);
    } else {
      setIsCaptchaValid(false);
    }
  };

  const show = () => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  const navigate = useNavigate();
  const formData = new FormData();
  const [number, setNumber] = useState();
  const [password, setPassword] = useState();
  const [companyCode, setCompanyCode] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();
    formData.append("ContactNo", number);
    formData.append("Password", password);
    formData.append("CompanyCode", companyCode);

    // if (!isCaptchaValid){
    //   toast.error("Please verify first through the captcha")
    // }

    try {
      const response = await fetch(`${baseUrl}Admin/Login`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (data && data.result[0].message == "Success") {
        if (data.result[0].AdminID == null) {
          localStorage.setItem("admin", "true");
          localStorage.setItem("id", data.result[0].Id);
          localStorage.setItem("UserId", data.result[0].Id);
          localStorage.setItem("access_token", data.result[0].access_token);
          localStorage.setItem("name", data.result[0].Name);
          localStorage.setItem("roleId", data.result[0].RoleID);
          localStorage.setItem("CompanyName", data.result[0].CompanyName);
          localStorage.setItem("CompanyCode", data.result[0].CompanyCode);
          localStorage.setItem("RoleName", data.result[0].RoleName);
          localStorage.setItem("Email", data.result[0].Email);
          localStorage.setItem("MobileNo", data.result[0].MobileNo);
          localStorage.setItem("ActivateFrom", data.result[0].ActivateFrom);
          localStorage.setItem("ActivateTo", data.result[0].ActivateTo);
          localStorage.setItem("ActivationDate", data.result[0].ActivationDate);
          localStorage.setItem("Status", data.result[0].Status);
          localStorage.setItem("uId", "");
          localStorage.setItem("Address", data.result[0].Address);
        } else {
          localStorage.setItem("admin", "false");
          localStorage.setItem("id", data.result[0].AdminID);
          localStorage.setItem("UserId", data.result[0].Id);
          localStorage.setItem("uId", data.result[0].Id);
          localStorage.setItem("access_token", data.result[0].access_token);
          localStorage.setItem("name", data.result[0].Name);
          localStorage.setItem("roleId", data.result[0].RoleID);
          localStorage.setItem("CompanyName", data.result[0].CompanyName);
          localStorage.setItem("CompanyCode", data.result[0].CompanyCode);
          localStorage.setItem("RoleName", data.result[0].RoleName);
          localStorage.setItem("Email", data.result[0].Email);
          localStorage.setItem("ActivateFrom", data.result[0].ActivateFrom);
          localStorage.setItem("ActivateTo", data.result[0].ActivateTo);
          localStorage.setItem("ActivationDate", data.result[0].ActivationDate);
          localStorage.setItem("Status", data.result[0].Status);
          localStorage.setItem("Address", data.result[0].Address);
        }
        localStorage.setItem("login", "success");
        navigate("/home");
      } else {
        if (data.result[0].message === "Data not found") {
          toast.error("User Id Or Password Or Company Code Invalid");
        } else {
          toast.error(data.result[0].message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <DocumentTitle title="LOGIN | LEGAL CRM ">
       
        <section className="loginbgPicture">
      
          <div className="container">
            <div className="row ">
              <div className="col-md-6 col-lg-4 col-sm-12">
                <div className="login_pg">
                  <div className="login_Logo">
                    <figure>
                      <a href="#">
                        <img src="assets/img/logo.webp" />
                      </a>
                    </figure>
                  </div>
                  <div className="myHeading ">
                    <h2>Sign In</h2>
                  </div>
                  <form className="bannerForm" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-12 col-md-12  col-sm-12">
                        <div className="form-group">
                          <span
                            style={{ fontSize: "20px" }}
                            className="LoginSpan"
                          >
                            <BiSolidBusiness />
                          </span>
                          <input
                            placeholder="Enter Company Code"
                            id="my-input"
                            className="form-control"
                            type="text"
                            name="text"
                            onChange={(e) => setCompanyCode(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12  col-sm-12">
                        <div className="form-group">
                          <span className="LoginSpan">
                            <img src="assets/img/icons/user.svg" />
                          </span>
                          <input
                            placeholder="Enter User ID"
                            id="my-input"
                            className="form-control"
                            type="text"
                            name="number"
                            onChange={(e) => setNumber(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-sm-12">
                        <div className="form-group">
                          <span className="LoginSpan">
                            <img src="assets/img/icons/password.svg" />
                          </span>
                          <input
                            placeholder="Enter Password"
                            id="my-input"
                            className="form-control"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                          <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                            onClick={togglePasswordVisibility}
                            style={{
                              paddingTop: "-15px",
                              position: "absolute",
                              top: "19px",
                              right: "10px",
                            }}
                            className="password-icon"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12  col-sm-12">
                        <div className="form-group">
                          {/* <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6LdCWMMnAAAAAAhxzCUhd9QR50GYPTCfBROeeEoN"
                            onChange={handleCaptchaChange}
                          /> */}
                        </div>
                      </div>
                      <div className="col-lg-12  col-sm-12">
                        <div className="btn_login">
                          {/* <button type="submit" disabled={!isCaptchaValid}>
                            Login
                          </button> */}
                          <button type="submit">Login</button>
                        </div>
                      </div>
                      <div className="privacy_plcy">
                        <p>
                          This site is protected by reCAPTCHA and the Google
                          Privacy Policy.
                        </p>
                      </div>
                      <div className="termDiv">
                      <a href="/#/view-terms-and-condition" className="termstext"><p>Terms and Condition</p></a>
                      <a href="/#/view-privacy-policy" className="termstext"><p>Privacy Policy</p></a>
                    </div>
                      <div className="col-lg-12 col-sm-12">
                        <div className="dontHave">
                          {/* <a className="dont" href="register2.html">Forgot password?</a> */}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>
        </section>
      </DocumentTitle>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Login;
