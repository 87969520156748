import React, { useEffect, useState, useContext } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Preloader from "./Preloader";
import DocumentTitle from "react-document-title";
import Footer from "./Footer";
import { url } from "../index";
import { useNavigate, useParams } from "react-router";
import { toast, ToastContainer } from "react-toastify";

const ReceiptCounselData = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [totalAmount, setTotalAmount] = useState("0");
  const baseUrl = useContext(url);
  const Case1 = new FormData();
  const { id } = useParams();
  const navigate = useNavigate();
  const [casesData, setCasesData] = useState([]);
  const [months, setMonth] = useState("");
  const [years, setYear] = useState("");
  const getCaseData1 = async (month, year) => {
    Case1.append("AdminID", localStorage.getItem("id"));
    Case1.append("UserID", localStorage.getItem("uId"));
    Case1.append("Month", month);
    Case1.append("Year", year);

    try {
      const response = await fetch(`${baseUrl}Receipt/ReceiptCounselAmount`, {
        method: "POST",
        body: Case1,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message === "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }

      if (data && data.result.length) {
        setCasesData(data && data.result);

        const sum = data.result.reduce(function (prev, current) {
          const totalAmount = parseFloat(current.TotalAmount);
          const amountToAdd = isNaN(totalAmount) ? 0 : totalAmount;

          return prev + amountToAdd;
        }, 0);

        setTotalAmount(sum);
      } else {
        setCasesData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getParamsData = (id) => {
    const data = id.split("_");

    const month = data[0];
    const year = data[1];
    setMonth(month);
    setYear(year);
    getCaseData1(month, year);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    getParamsData(id);
  }, [id]);

  return (
    <>
      <DocumentTitle title="COUNSEL INFO | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  <div className="pageContent">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="myCard">
                          <div className="cardHeader">
                            <h4>COUNSEL LIST (RECEIPT)</h4>
                            <h4>( {months} - {years} )</h4>
                          </div>
                          <div className="cardBody">
                            <table
                              className="myTable"
                              style={{ border: "1px solid rgb(0, 0, 0)" }}
                            >
                              <thead>
                                <tr>
                                  <th>S NO.</th>
                                  <th>COUNSEL NAME</th>
                                  <th>AMOUNT</th>
                                </tr>
                              </thead>
                              <tbody>
                                {casesData &&
                                  casesData.map((val, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                          <a
                                            className="visuald_case upperCase"
                                            href={`/#/view-receipt/${val.Id}/${months}_${years}`}
                                          >
                                            {val.CounselName}
                                          </a>
                                        </td>
                                        <td>{val.TotalAmount}</td>
                                      </tr>
                                    );
                                  })}

                                {casesData && casesData.length ? (
                                  <tr style={{ borderTop: "1px solid black" }}>
                                    <td></td>
                                    <td
                                      style={{
                                        fontWeight: "bold",
                                        textAlign: "center",
                                      }}
                                    >
                                      Total
                                    </td>
                                    <td style={{ fontWeight: "bold" }}>
                                      {totalAmount} Rs.
                                    </td>
                                  </tr>
                                ) : (
                                  <tr>
                                    <td></td>
                                    <td style={{ textAlign: "center" }}>
                                      Data Not Found
                                    </td>
                                    <td></td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>{" "}
          </div>
        )}
      </DocumentTitle>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />{" "}
    </>
  );
};

export default ReceiptCounselData;
