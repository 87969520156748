import React, { useState, useRef } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { permission, viewData } from "../services/api.service";
import DocumentTitle from "react-document-title";
import Preloader from "./Preloader";
import Footer from "./Footer";

const EarningReport = () => {
  
  //        -------- POPUP CLOSE VARIABLE ---------
  const [earningReportFilter, setEarningReportFilter] = useState(false);
  const [fEarningReportGroupBy, setFEarningReportGroupBy] = useState("");
  const [fEarningReportFromDate, setFEarningReportFromDate] = useState("");
  const [fEarningReportPeriod, setFEarningReportPeriod] = useState("1");
  const [fEarningReportToDate, setFEarningReportToDate] = useState("");
  const [earningReportData, setEarningReportData] = useState([]);
  const [borderLeftCaseFlow, setBorderLeftCaseFlow] = useState(false);

  // Amount And Description

  const baseUrl = useContext(url);
  const navigate = useNavigate();

  // ----------- VIEW Client---------

  const filterform = new FormData();
  const form = new FormData();
  const [isView, setIsView] = useState(false);

  //        -------- VIEW TASK DATA ---------

  const viewData = async (path) => {
    form.append("AdminID", localStorage.getItem("id"));
    form.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}${path}`, {
        method: "POST",
        body: form,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }

      if (data && data.result[0].EarningReport.length) {
        setEarningReportData(data && data.result[0].EarningReport);
      } else {
        setEarningReportData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCaseView = async () => {
    const [data] = await Promise.all([
      permission(30),
      viewData("Cases/ViewEarningReport"),
    ]);

    setIsView(data && data.result[0].IsView === "1" ? true : false);
  };

  const filterEarningReportCancel = () => {
    setBorderLeftCaseFlow(false);
    document.getElementById("dynamicColumnEarning").innerHTML = "";
    // document.getElementById("dynamicEarning").innerHTML = "";
    setFEarningReportPeriod("1");
    setFEarningReportGroupBy("");
    setFEarningReportFromDate("");
    setFEarningReportToDate("");
    setEarningReportFilter(false);
    getCaseView();
  };

  const filterEarningReportApi = async () => {
    filterform.append("AdminID", localStorage.getItem("id"));
    filterform.append("UserID", localStorage.getItem("uId"));
    filterform.append("Period", fEarningReportPeriod);
    filterform.append("GroupBy", fEarningReportGroupBy);
    filterform.append("From", fEarningReportFromDate);
    filterform.append("To", fEarningReportToDate);
    try {
      const response = await fetch(`${baseUrl}Cases/EarningReport`, {
        method: "POST",
        body: filterform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }

      if (fEarningReportGroupBy === "1") {
        setBorderLeftCaseFlow(true);
        document.getElementById("dynamicColumnEarning").innerHTML = "City";
      } else {
        if (fEarningReportGroupBy === "2") {
          setBorderLeftCaseFlow(true);
          document.getElementById("dynamicColumnEarning").innerHTML =
            "Incharge";
        } else {
          if (fEarningReportGroupBy === "3") {
            setBorderLeftCaseFlow(true);
            document.getElementById("dynamicColumnEarning").innerHTML =
              "Co-counsel";
          } else {
            if (fEarningReportGroupBy === "4") {
              setBorderLeftCaseFlow(true);
              document.getElementById("dynamicColumnEarning").innerHTML =
                "Case-Type";
            } else {
              setBorderLeftCaseFlow(false);
              document.getElementById("dynamicColumnEarning").innerHTML = "";
              document.getElementById("dynamicEarning").innerHTML = "";
            }
          }
        }
      }

      if (data && data.result[0].EarningReport.length) {
        setEarningReportData(data.result[0].EarningReport);
      } else {
        setEarningReportData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const earningReportFilterShow = () => {
    setEarningReportFilter(true);
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getCaseView();
    }
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <>
      <DocumentTitle title="EARNING_REPORT | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div className="wraper">
            <Header />
            <div className="mainWraper">
              <Sidebar />
              <div className="pageBody">
                <div className="pageContent">
                  <div className="PageTitle">
                    <h3>Earning Report</h3>
                  </div>
                  {isView && (
                    <div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="myCard">
                            <div className="cardHeader">
                              <h4>Earning Report</h4>

                              <div
                                style={{
                                  display: "flex",
                                  gap: "20px",
                                }}
                              >
                                {earningReportFilter && (
                                  <div className="form_flex_home">
                                    <div className="form-g">
                                      <select
                                        onChange={(e) =>
                                          setFEarningReportPeriod(
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value="1">Daily</option>
                                        <option value="2">Monthly</option>
                                        <option value="3">Yearly</option>
                                      </select>
                                    </div>
                                    <div className="form-g">
                                      <select
                                        onChange={(e) =>
                                          setFEarningReportGroupBy(
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value="">Group By</option>
                                        <option value="1">City</option>
                                        <option value="4">Case Type</option>
                                      </select>
                                    </div>
                                    <div className="form-g">
                                      <input
                                        autoComplete="off"
                                        className="name"
                                        type="date"
                                        value={fEarningReportFromDate}
                                        onChange={(e) =>
                                          setFEarningReportFromDate(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="form-g">
                                      <input
                                        autoComplete="off"
                                        className="name"
                                        type="date"
                                        value={fEarningReportToDate}
                                        onChange={(e) =>
                                          setFEarningReportToDate(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                    <div>
                                      <div className="filter_button">
                                        <button
                                          type="button"
                                          onClick={filterEarningReportApi}
                                        >
                                          Search
                                        </button>
                                        <button
                                          type="button"
                                          onClick={filterEarningReportCancel}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "20px",
                                  }}
                                >
                                  <img
                                    type="button"
                                    style={{
                                      display:
                                        earningReportFilter === true
                                          ? "none"
                                          : "block",
                                    }}
                                    onClick={earningReportFilterShow}
                                    src="assets/img/icons/Vector.svg"
                                    alt=""
                                    title="Filter"
                                  />
                                  <div class="rytContent">
                                    <a href="/#/view-case">
                                      <img
                                        src="assets/img/icons/dots.png"
                                        alt=""
                                        title="All Case"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {earningReportData && earningReportData.length ? (
                            <div className="cardBody">
                              <table className="myTable">
                                <thead>
                                  <tr>
                                    <th rowspan="2">Period</th>
                                    <th rowspan="2">Counsel</th>
                                    <th
                                      rowspan="2"
                                      id="dynamicColumnEarning" style={{borderLeft: borderLeftCaseFlow ? "" : "none"}}
                                    ></th>
                                    <th
                                      style={{ textAlign: "center" }}
                                      colspan="3"
                                    >
                                      Invoices
                                    </th>
                                    <th
                                      style={{ textAlign: "center" }}
                                      colspan="2"
                                    >
                                      Receipts
                                    </th>
                                  </tr>
                                  <tr style={{border: "1px solid  white"}}>
                                    <th>Nos.</th>
                                    <th>Amt</th>
                                    <th>Paid</th>
                                    <th>Nos.</th>
                                    <th>Amt</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {earningReportData &&
                                    earningReportData.map((val, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>{val.Period}</td>
                                          <td>{val.Counsel}</td>
                                          <td id="dynamicEarning" style={{borderLeft: borderLeftCaseFlow ? "" : "none"}}>
                                            {val.GroupField === null
                                              ? ""
                                              : val.GroupField}
                                          </td>
                                          <td>
                                          <a
                                                    href={`/#/view-cash-flow/1_${fEarningReportGroupBy}_${
                                                      val.Period
                                                    }_${
                                                      fEarningReportGroupBy === "1"
                                                        ? val.CityID
                                                        : fEarningReportGroupBy === "4"
                                                        ? val.CaseTypeID
                                                        : ""
                                                    }`}
                                                    className="visuald_case"
                                                    target="_blank"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {val.TotalInvoice}
                                                  </a></td>
                                          <td>{val.InvoiceAmount}</td>
                                          <td>{val.InvoicePaidAmount}</td>
                                          <td>
                                          <a
                                                    href={`/#/view-cash-flow/2_${fEarningReportGroupBy}_${
                                                      val.Period
                                                    }_${
                                                      fEarningReportGroupBy === "1"
                                                        ? val.CityID
                                                        : fEarningReportGroupBy === "4"
                                                        ? val.CaseTypeID
                                                        : ""
                                                    }`}
                                                    className="visuald_case"
                                                    target="_blank"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {val.TotalReceipt}
                                                  </a></td>
                                   
                                          <td>{val.TotalReceiptAmount}</td>
                                        </tr>
                                      );
                                    })}

                                  <tr></tr>
                                </tbody>
                              </table>
                            </div>
                                                     ) : <p className="case_detail_table">Data not found</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Footer/>
          </div>
        )}
      </DocumentTitle>

      {/*-------- TOASTER ---------*/}

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default EarningReport;
