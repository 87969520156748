import React, { useState } from "react";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  FaBars,
  FaTasks,
  FaCalculator,
  FaFileInvoice,
  FaUsers,
  FaUser,
  FaUserPlus,
  FaUserTie,
  FaSearch,
} from "react-icons/fa";
import * as MdIcons from "react-icons/md";
import { RiArrowUpSFill } from "react-icons/ri";
import { AiFillHome, AiOutlineSetting } from "react-icons/ai";
import { RiArrowDownSFill } from "react-icons/ri";
import { AiTwotoneLock } from "react-icons/ai";
import { GrConfigure } from "react-icons/gr";
import * as IoIcons from "react-icons/io";
import { TbSpeakerphone } from "react-icons/tb";
import { BsPinFill, BsReceipt } from "react-icons/bs";
import { BiRupee, BiTask, BiSolidBank } from "react-icons/bi";
import { GrTemplate, GrNotes } from "react-icons/gr";
import { MdAccountCircle } from "react-icons/md";
import { GiScales } from "react-icons/gi";
import { BsFillEnvelopeFill, BsCalendar3 } from "react-icons/bs";
import { useContext } from "react";
import { url } from "../index";
import { RiUserSettingsFill } from "react-icons/ri";
import { useEffect } from "react";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { IoDocumentSharp } from "react-icons/io5";
import { CgTemplate } from "react-icons/cg";
import { TbCalendarTime } from "react-icons/tb";
import { FaPowerOff } from "react-icons/fa6";
import { clearForm } from "../services/api.service";
const iconMap = {
  IoIosBookmark: <IoIcons.IoIosBookmark />,
  IoIosPaper: <IoIcons.IoIosPaper />,
  MdManageAccounts: <MdIcons.MdManageAccounts />,
  TbSpeakerphone: <TbSpeakerphone />,
  BsPinFill: <BsPinFill />,
  BiRupee: <BiRupee />,
  BsFillEnvelopeFill: <BsFillEnvelopeFill />,
  GrTemplate: <GrTemplate />,
  GrNotes: <GrNotes />,
  LiaFileInvoiceDollarSolid: <LiaFileInvoiceDollarSolid />,
  BiTask: <BiTask />,
  TbCalendarTime: <TbCalendarTime />,
  FaCalculator: <FaCalculator />,
  CgTemplate: <CgTemplate />,
  FaTasks: <FaTasks />,
  GrConfigure: <GrConfigure />,
  RiUserSettingsFill: <RiUserSettingsFill />,
  FaUsers: <FaUsers />,
  FaUserTie: <FaUserTie />,
  FaUserPlus: <FaUserPlus />,
  FaFileInvoice: <FaFileInvoice />,
  BsReceipt: <BsReceipt />,
  BsCalendar3: <BsCalendar3 />,
  IoDocumentSharp: <IoDocumentSharp />,
  MdAccountCircle: <MdAccountCircle />,
  FaUser: <FaUser />,
  AiOutlineSetting: <AiOutlineSetting />,
  BiSolidBank: <BiSolidBank />,
  GiScales: <GiScales />,
};

const Nav = styled.div`
  background: #15171c;
  height: 80px;
  display: none !important;
  justify-content: flex-start;
  align-items: center;
`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: none !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  //   background: #ffffff;
  //   width: 270px;
  //   height: 100%;
  //   display: flex;
  //   justify-content: center;
  //   padding: 20px 8px;
  //   top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  //   transition: 350ms;
  //   z-index: -1;
`;
const icon = styled.div`
  color: #000 !important ;
`;
const SidebarWrap = styled.div`
  width: 100%;
`;

const Header = () => {
  const navigate = useNavigate();

  const handleLogOut = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };
  const [sidebar, setSidebar] = useState(false);
  const [data, setData] = useState([]);
  const [moduleData, setModuleData] = useState([]);
  const [SidebarData, setSidebarData] = useState([]);
  const showSidebar = () => setSidebar(!sidebar);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const toggleSidebar1 = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const permissionForm = new FormData();
  const baseUrl = useContext(url);
  /** Get File Name  */
  const Case = new FormData();

  const [caseSearch, setCaseID] = useState("");
  const [results, setResults] = useState("");

  const getFileNo = async (value) => {
    Case.append("AdminID", localStorage.getItem("id"));
    Case.append("SearchText", value);
    try {
      const response = await fetch(`${baseUrl}GenericSearch/GenericSearch`, {
        method: "POST",
        body: Case,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.code !== 400) {
        setResults(data && data.result);
      }
      
    } catch (error) {
      console.error(error);
    }
  };
  
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setCaseID(value);

    if (value !== "") {
      getFileNo(value);
    }
  };

  const getModule = async () => {
    permissionForm.append("UserID", localStorage.getItem("UserId"));
    try {
      const response = await fetch(
        `${baseUrl}/Permission/GetPermissionByUserId`,
        {
          method: "POST",
          body: permissionForm,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        }
      );
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result && Array.isArray(data.result)) {
        setModuleData(data.result);
      } else {
        console.error("Invalid module data format");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      getModule();
    }
  }, []);

  useEffect(() => {
    if (moduleData && Array.isArray(moduleData) && moduleData.length > 0) {
      const dynamicSidebarData = [];

      dynamicSidebarData.push({
        title: "Dashboard",
        icon: <AiTwotoneLock />,
        path: "/home",
        icon: <AiFillHome />,
      });

      if (localStorage.getItem("admin") == "true") {
        dynamicSidebarData.push({
          title: "Manage Permission",
          icon: <AiTwotoneLock />,
          iconClosed: <RiArrowDownSFill />,
          iconOpened: <RiArrowUpSFill />,
          subNav: [
            {
              title: "Permission",
              path: "/permission",
              icon: <IoIcons.IoIosPaper />,
            },
          ],
        });
      }

      dynamicSidebarData.push(
        ...moduleData.map((item) => {
          if (item.isSubmenu > 0) {
            const subNavItems = item.subNav.map((subNavItem) => ({
              title: subNavItem.moduleName,
              path: subNavItem.path,
              icon: iconMap[subNavItem.icon],
            }));

            return {
              title: item.title,
              iconClosed: <RiArrowDownSFill />,
              iconOpened: <RiArrowUpSFill />,
              subNav: subNavItems,
              icon: iconMap[item.icon],
            };
          } else {
            const subNav = item.subNav[0];
            return {
              title: item.title,
              icon: iconMap[item.icon],
              path: subNav.path,
            };
          }
        })
      );
      setSidebarData(dynamicSidebarData);
    }
  }, [moduleData]);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleClear = () => {
    // Clear the input field and hide the dropdown
    setCaseID("");
    setDropdownVisible(false);
  };

  return (
    <>
      <header className="headerArea fixed-top">
        <div className="container-fluid">
        <Nav>
              <NavIcon to="#">
                <img
                  className="BarsImage"
                  src="assets/img/icons/menu.svg"
                  onClick={showSidebar}
                />
              </NavIcon>
            </Nav>
          <nav className="navbar navbar-expand-xl customNav">
          <Link className="navbar-brand" to="/home">
              <img
                src="https://lawyerhunt.in/assets/frontend/images/logo.png"
                alt=""
              />
            </Link>

            <div
              className="input-group headerSearch"
              // style={{ justifyContent: "center" }}
            >
              <div
                className={`input-group-prepend ${
                  dropdownVisible ? "show" : ""
                }`}
              >
                <div
                  className={`dropdown-menu ${dropdownVisible ? "show" : ""}`}
                >
                  <p className="searchlabel"> Case No</p>
                  {results && results.Case[0].message === "Success" ? (
                    results.Case.map((result, index) => (
                      <a
                      className="dropdown-item"
                      title="Case Details"
                      href={`#/view-casedetails/${result.CaseID}`}
                      target="_blank"
                      style={{ cursor: "pointer" }}
                    >
                      {result.CaseNo === null || result.CaseNo === ""
                        ? ""
                        : result.CaseNo} {result.CaseName === null || result.CaseName === ""
                        ? ""
                        : ` / ${result.CaseName}`} {result.CaseYear === null || result.CaseYear === "0000"
                        ? ""
                        : ` / ${result.CaseYear}`}
                    </a>
                    ))
                  ) : (
                    <a></a>
                  )}
                  
                  <p className="searchlabel"> File No</p>
                  {results && results.FileNo ? (
                    results.FileNo.map((result, index) => (
                      <a
                        className="dropdown-item"
                        title="Case Details"
                        href={`#/view-casedetails/${result.CaseID}`}
                        target="_blank"
                        style={{ cursor: "pointer" }}
                      >
                        {result.FileNo}
                      </a>
                    ))
                  ) : (
                    <a></a>
                  )}

                  <p className="searchlabel"> Client</p>
                  {results && results.Client ? (
                    results.Client.map((result, index) => (
                      <a
                      title="Client Details"
                      href={`#/view-clientdetails/${result.Id}`}
                      className="dropdown-item"
                      target="_blank"
                      style={{ cursor: "pointer" }}
                      >
                        {result.ClientFirstName} {result.ClientSecondName}
                      </a>
                    ))
                  ) : (
                    <a></a>
                  )}
                </div>
                {/* <div className="col-md-3"> */}
                <input
                  type="text"
                  placeholder="Enter your keywords here"
                  className="form-control name headerInput"
                  onChange={handleSearchChange}
                />{" "}
              </div>
              {/* </div> */}
              <button
                type="button"
                className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split searchBtn"
                data-toggle="dropdown"
                aria-haspopup="false"
                aria-expanded="true"
                onClick={() => getFileNo(caseSearch)}
              >
              <FaSearch />

              </button>
              <img
                className="btn btn-primary resetBtn"
                type="reset"
                value="Reset"
                onClick={clearForm}
                src="assets/img/icons/reset.svg"
              />
            </div>
            <div className="boxRight">
              <div className="Box-1">
                <h3 className="upperCase">
                  {localStorage.getItem("CompanyName") &&
                    localStorage
                      .getItem("CompanyName")
                      .charAt(0)
                      +
                      localStorage.getItem("CompanyName").slice(1)}{" "}
                </h3>
                <p> {localStorage.getItem("CompanyCode")}</p>
              </div>
           
              <div className="Box-1">
                <h3 className="upperCase">
                  {localStorage.getItem("name") &&
                    localStorage.getItem("name").charAt(0) +
                      localStorage.getItem("name").slice(1)}
                </h3>

                <p className="upperCase">
                  {localStorage.getItem("RoleName") &&
                    localStorage.getItem("RoleName").charAt(0) +
                      localStorage.getItem("RoleName").slice(1)}
                </p>
                {/* </div> */}
              </div>
              {/* </div> */}
              {/* </div>  */}
              <div className="alertBox">
                <a>
                  <span
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalLogOutM"
                    style={{ cursor: "pointer" }}
                    className="alertSign"
                    title="Logout"
                  >
                    <FaPowerOff />
                  </span>
                </a>
              </div>
              {/* </div> */}
            </div>
          </nav>
        </div>
      </header>

      <div
        className="modal fade sc_modal"
        id="exampleModalLogOutM"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <h4>Logout</h4>
              <p>Are you sure you want to Logout ?</p>
              <ul>
                <li>
                  <a className="btn btn-secondary" data-bs-dismiss="modal">
                    Cancel
                  </a>
                </li>
                <li>
                  <a className="btn btn-danger" onClick={() => handleLogOut()}>
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Header;
