import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useContext } from "react";
import { url } from "../index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatDate1, convertToIndianNumbering } from "../services/api.service";
import html2pdf from "html2pdf.js";
import DocumentTitle from "react-document-title";

const EstimatePDF = () => {
  const baseUrl = useContext(url);
  const { id } = useParams();
  const [FileNo, setFileNo] = useState("");
  const navigate = useNavigate();
  const ViewCDData = new FormData();
  const [optionsCD, setOptionsCD] = useState([]);
  const [estimatesDetail, setEstimatesDetail] = useState([]);
  const formattedDate = new Date().toLocaleDateString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const handlePrint = () => {
    const pdfContent = document.getElementById("pdf-content");
    const pdfOptions = {
      filename: `${FileNo}/Estimate/${formattedDate}.pdf`,
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf(pdfContent, pdfOptions);
  }

  const getCountriesCD = async () => {
    ViewCDData.append("ID", id);
    ViewCDData.append("AdminID", localStorage.getItem("id"));
    try {
      const response = await fetch(
        `${baseUrl}Estimates/PrintEstimateById`,
        {
          method: "POST",

          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
          body: ViewCDData,
        }
      );

      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
      }

      if (data && data.result.Estimate[0].message === "Success") {
        setOptionsCD(data && data.result.Estimate);
        setFileNo(data && data.result.Estimate[0].FileNo);
        setEstimatesDetail(data && data.result.EstimatesDetail);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCountriesCD();
  }, [id]);

  useEffect(() => {
    handlePrint();
  }, [optionsCD]);

  return (
    <DocumentTitle title="PDF | LEGAL CRM">
      <div>
        {optionsCD &&
          optionsCD.map((val, index) => (
            <div className="printWrapper a4" id="pdf-content" key={index}>
              <div className="pagePrint">
                <div className="pageHeader">

              <div className="logoPrint col-md-4">
                    <img
                      src={`https://api.lawyerhunt.in/uploaded/logo/${val.CompanyLogo}`}
                      />
                  </div>
                </div>
                <br />
                <div className="printHeading">
                <h3>ESTIMATE</h3>
                </div>
              </div>

              <div className="invoiceNum">
                <div className="invoiceNumValue">
                <strong>
                    Estimate # : <span>{val.Id}</span>
                  </strong>
                </div>
                <div className="invoiceNumValue">
                <strong>
                    Date : <span>{formatDate1(val.Date)}</span>
                  </strong>
                </div>
              </div>
              <div className="invoiceDetail">
                <div className="detailBox r_pdf">
                <div className="printNames">
                    <strong>Case Details:</strong>
                   {val.FileNo} {val.CaseNo === "" || val.CaseNo === null ? "" : `/ ${val.CaseNo}` } {val.CaseName === "" || val.CaseName === null ? "" : `/ ${val.CaseName}` } {val.CaseYear === "" || val.CaseYear === "0000" ? "" : `/ ${val.CaseYear}` }
                   
                  </div>
                  <div className="printNames">
                    <strong>Court Name:</strong>
                    {val.CourtName}
                  </div>
                  <div className="printNames">
                    <strong>Client Name:</strong>
                    {val.ClientName} {val.ClientlastName}
                  </div>
                  <div className="printNames">
                    <strong>Mobile No.:</strong>
                    <span>{val.ClientMobile}</span>
                  </div>
                  <div className="printNames">
                    <strong>Address:</strong>
                    <span>{val.ClientAddress}</span>
                  </div>
                </div>
              </div>

              <div className="tableBox">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "50px" }}>Sr</th>
                      <th style={{ width: "235px" }}>Description</th>
                      <th style={{ width: "200px" }}>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {estimatesDetail &&
                      estimatesDetail.map((val, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>
                              <b>{val.Description}</b>
                            </td>
                            <td>
                              <span>{val.Amount}</span>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: "430px" }} rowspan="3">
                        <strong>Estimate Amount (In Words) :</strong>
                        <p id="word" className="upperCase">
                        {convertToIndianNumbering(val.EstimateAmount)}
                        </p>
                      </td>
                      <td>
                        <strong>Total:</strong>
                      </td>
                      <td>{val.TotalEstimate}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Discount :</strong>
                      </td>
                      <td>{val.Discount}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Paid :</strong>
                      </td>
                      <td>{val.EstimateAmount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="bankDetailBox">
             
                      <div className="leftDet">
                        <h3>Bank Detail:</h3>
                        <div className="printNames">
                          <strong>Bank Name:</strong>
                          <span>{val.Bank}</span>
                        </div>
                        <div className="printNames">
                          <strong>Branch Name:</strong>
                          <span>{val.Branch}</span>
                        </div>
                        <div className="printNames">
                          <strong>Account Holder's Name:</strong>
                          <span>{val.Beneficiary}</span>
                        </div>
                        <div className="printNames">
                          <strong>Account No.:</strong>
                          <span>{val.AccountNo}</span>
                        </div>
                        <div className="printNames">
                          <strong>IFSC Code.:</strong>
                          <span>{val.IFSCCode}</span>
                        </div>
                     
                      </div>
                  
                <div className="dateandSign">
                  <div className="signature">
                  {val.Counsel === "" || val.Counsel === null ? "-" : val.Counsel}

             
                  </div>
                </div>
              </div>

        
              <div
                style={{
                  display:
                    (val.CompanyFooter1 === null ||
                      val.CompanyFooter1 === "") &&
                    (val.CompanyFooter2 === null ||
                      val.CompanyFooter2 === "") &&
                    (val.CompanyFooter3 === null || val.CompanyFooter3 === "")
                      ? "none"
                      : "block",
                }}
              >
                <hr />
                <div className="footer">
                  {val.CompanyFooter1 === null || val.CompanyFooter1 === "" ? (
                    ""
                  ) : (
                    <div className="footer_invoice">
                      <h4>{val.Name}</h4>
                      <p>{val.CompanyFooter1}</p>
                    </div>
                  )}

                  {val.CompanyFooter2 === null || val.CompanyFooter2 === "" ? (
                    ""
                  ) : (
                    <div className="footer_invoice">
                      <h4>{val.Name}</h4>
                      <p>{val.CompanyFooter2}</p>
                    </div>
                  )}

                  {val.CompanyFooter3 === null || val.CompanyFooter3 === "" ? (
                    ""
                  ) : (
                    <div className="footer_invoice">
                      <h4>{val.Name}</h4>
                      <p>{val.CompanyFooter3}</p>
                    </div>
                  )}
                </div>
              </div>
            
            </div>
       
          ))}
      </div>





    </DocumentTitle>
  );
};

export default EstimatePDF;
