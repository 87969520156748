import React, { useState, useRef } from "react";
import autoTable from "jspdf-autotable";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import jsPDF from "jspdf";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { formatDate1, formateDateTime, withUidApi } from "../services/api.service";
import {
  getClient,
  deleteUser,
  userDetails,
  activeStatus,
  permission,
} from "../services/api.service";
import Preloader from "./Preloader";
import "react-quill/dist/quill.snow.css";
import "quill/dist/quill.snow.css";
import { format } from "date-fns";
import ReactQuill from "react-quill";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, download } from "export-to-csv";
import Select from "react-select";
import { clearForm } from "../services/api.service";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";
import Footer from "./Footer";
const Appointments = () => {

  const [saveButton, setSaveButton] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  //        ---------- CLOSE POPUP VARIABLE --------

  const closeButton = document.querySelector(".btn_popup");
  const closeEdit = document.querySelector(".e_popup");
  const closeView = document.querySelector(".ab");
  const closeDelete = document.querySelector(".mm");

  //        ----------  VARIABLE --------

  const currentDate = new Date().toISOString().split("T")[0];
  const ViewData = new FormData();
  const [countries, setCountries] = useState([]);
  const baseUrl = useContext(url);
  const navigate = useNavigate();
  const [dCaseID, setDCaseID] = useState("");
  const [dCaseName, setDCaseName] = useState("");
  const [dDate, setDDate] = useState("");
  const [dClientID, setDClientID] = useState("");
  const [dSummary, setDSummary] = useState("");
  const [dTime, setDTime] = useState("");
  const [dAssignTo, setDAssignTo] = useState("");
  const [dAppointmentWith, setDAppointmentWith] = useState("");
  const [dIsPending, setDIsPending] = useState("");
  const [dDeatils, setDDeatils] = useState("");
  const [fCaseId, setFCaseId] = useState("");
  const [fFromDate, setFFromDate] = useState("");
  const filterform = new FormData();
  const [fToDate, setFToDate] = useState("");
  const [fClientID, setFClientID] = useState("");
  const [fClientOption, setFClientOption] = useState([]);
  const [isAdd, setIsAdd] = useState("0");
  const [isEdit, setIsEdit] = useState("0");
  const [isView, setIsView] = useState("0");
  const [isDelete, setIsDelete] = useState("0");
  const [isStatus, setIsStatus] = useState("0");
  const [excel, setExcel] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const [loading, setLoading] = useState(true);

  //        ---------- ADD VARIABLE --------

  const [letterTemplatesData, setLetterTemplatesData] = useState([]);
  const [CaseID, setCaseID] = useState("");
  const [ClientID, setClientID] = useState("");
  const [Summary, setSummary] = useState("");
  const [Time, setTime] = useState("");
  const [AssignTo, setAssignTo] = useState("");
  const [AppointmentWith, setAppointmentWith] = useState("");
  const [IsPending, setIsPending] = useState("");
  const [Details, setDetails] = useState("");
  const [Dates, setDates] = useState(currentDate);
  const [delId, setDelId] = useState("");
  const [optionsEvent, setOptionsEvent] = useState([]);
  const [data, setData] = useState([]);
  //        ---------- EDIT VARIABLE --------

  const [editId, setEditId] = useState("");
  const [EditCaseID, setEditCaseID] = useState("");
  const [EditDate, setEditDate] = useState("");
  const [EditClientID, setEditClientID] = useState("");
  const [EditSummary, setEditSummary] = useState("");
  const [EditTime, setEditTime] = useState("");
  const [EditAssignTo, setEditAssignTo] = useState("");
  const [EditAppointmentWith, setEditAppointmentWith] = useState("");
  const [EditIsPending, setEditIsPending] = useState("");
  const [EditDetails, setEditDetails] = useState("");
  const formData = new FormData();
  const editAppointment = new FormData();

  const [open, setOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const handleExportButtonClick = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const handleToggleColumn = (columnName) => {
    const currentIndex = selectedColumns.indexOf(columnName);
    const newSelectedColumns = [...selectedColumns];

    if (currentIndex === -1) {
      newSelectedColumns.push(columnName);
    } else {
      newSelectedColumns.splice(currentIndex, 1);
    }

    setSelectedColumns(newSelectedColumns);
  };

  //        ---------- VIEW  --------

  const getViewLetterTemplate = async () => {
    const [view, data, cdata] = await Promise.all([
      withUidApi("Appoinment/ViewAppoinment"),
      permission(11),
      getClient("Client/ViewClient"),
      getUser(),
      getFileNo(),
      getAppoint(),
      setLoading(true),
    ]);

    if (view === null) {
      setLoading(false);
      setLetterTemplatesData([]);
      setData([]);
    } else {
      if (view === "token") {
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        setLoading(false);
        setLetterTemplatesData(view);
        setData(view);
      }
    }

    const clientData = cdata && cdata.filter((item) => item.Status === "1");

    const Mapped =
      clientData &&
      clientData.map((item) => ({
        value: item.Id,
        label: `${item.FirstName} ${item.LastName}`,
      }));
    setFClientOption(Mapped);

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }

    setIsAdd(data && data.result[0].IsAdd);
    setIsEdit(data && data.result[0].IsEdit);
    setIsView(data && data.result[0].IsView);
    setIsStatus(data && data.result[0].IsStatus);
    setIsDelete(data && data.result[0].IsDelete);
  };

  //        ---------- DELETE   -------

  const DeleteAppoinment = async () => {
    const data = await deleteUser(delId, "Appoinment/DeleteAppoinment");
    if (data === "token") {
      setTimeout(() => {
        closeDelete.click();
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      closeDelete.click();
      filterActive ? filterApi() : getViewLetterTemplate();
    }
  };

  //        ---------- ACTIVE DEACTIVE  --------

  const deActive = async (row) => {
    const data = await activeStatus(row, "Appoinment/AppoinmentStatus");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      filterActive ? filterApi() : getViewLetterTemplate();
    }
  };

  //        ---------- DETAILS --------

  const details = async (id) => {
    const data = await userDetails(id, "Appoinment/ViewAppoinmentById");
    if (data === "token") {
      setTimeout(() => {
        closeView.click();
        navigate("/");
      }, 3000);
    } else {
      setDDeatils(data.result[0].Detail);
      setDIsPending(data.result[0].IsPending);
      setDDate(data.result[0].Date);
      setDCaseID(data.result[0].FileNo);
      setDCaseName(data.result[0].CaseName);
      setDAppointmentWith(data.result[0].AppointmentWith);
      setDAssignTo(data.result[0].Assignto);
      setDClientID(data.result[0].ClientName);
      setDSummary(data.result[0].Summary);
      setDTime(data.result[0].Time);
    }
  };

  //        ---------- DETAILS --------

  const getById = async (id) => {
    const data = await userDetails(id, "Appoinment/ViewAppoinmentById");
    if (data === "token") {
      setTimeout(() => {
        closeEdit.click();
        navigate("/");
      }, 3000);
    } else {
      getClientByCase(data && data.result[0].CaseID);
      setEditId(data && data.result[0].Id);
      setEditDate(data && data.result[0].Date);
      setEditCaseID(data && data.result[0].CaseID);
      setEditAppointmentWith(data && data.result[0].AppointmentWith);
      setEditAssignTo(data && data.result[0].AssignTo);
      setEditClientID(data && data.result[0].ClientID);
      setEditDate(data && data.result[0].Date);
      setEditSummary(data && data.result[0].Summary);
      setEditTime(data && data.result[0].Time);
      setEditIsPending(data && data.result[0].IsPending);
      setEditDetails(data && data.result[0].Detail);
    }
  };

  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  /** Get Appoinment  */
  const CaseAppoint = new FormData();
  const [Appoint, setAppoint] = useState([]);
  const getAppoint = async () => {
    CaseAppoint.append("AdminID", localStorage.getItem("id"));
    try {
      const response = await fetch(`${baseUrl}Appoinment/ViewAppoinment`, {
        method: "POST",
        body: CaseAppoint,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].code == 200) {
        setAppoint(data.result);
        setData(data.result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /** Get File Name  */
  const Case = new FormData();
  const [optionsCase, setOptionsCase] = useState([]);

  const [FileNo, setFileNo] = useState([]);
  const getFileNo = async () => {
    Case.append("AdminID", localStorage.getItem("id"));
    Case.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: Case,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result[0].message === "Success") {
        setFileNo(data.result);

        const Mapped = data.result.map((item) => ({
          value: item.CaseID,
          label: `${item.FileNo} / ${item.CaseName} / ${item.CaseNo} / ${item.CaseYear}`,
        }));

        setOptionsCase(Mapped);
        setOptionsEvent(Mapped);
      }
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setCaseID("");
      setClientID("");
      setCountries([]);
    } else {
      setCaseID(selectedOption.value);
      getClientByCase(selectedOption.value);
    }
  };

  const handleEditCaseId = (selectedOption) => {
    if (selectedOption === null || selectedOption === "") {
      setEditCaseID("");
      setEditClientID("");
      setCountries([]);
    } else {
      setEditCaseID(selectedOption);
      getClientByCase(selectedOption);
    }
  };

  // Get Client


  const getClientByCase = async (caseId) => {
    ViewData.append("AdminID", localStorage.getItem("id"));
    ViewData.append("CaseID", caseId);
    try {
      const response = await fetch(`${baseUrl}Appoinment/ViewClientByCase`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: ViewData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
      }
      
      setCountries(data && data.result);
    } catch (error) {
      console.error(error);
    }
  };

  /** Get User  */
  const Userform = new FormData();
  const [User, setUser] = useState([]);
  const getUser = async () => {
    Userform.append("AdminID", localStorage.getItem("id"));
    try {
      const response = await fetch(`${baseUrl}Task/ViewUserList`, {
        method: "POST",
        body: Userform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].code == 200) {
        setUser(data.result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        ---------- ADD --------

  const submitHandler = async (event) => {
    event.preventDefault();
    setSaveButton(false);
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    if (CaseID && CaseID.length > 0) {
      formData.append("CaseID", CaseID);
    } else {
      formData.append("CaseID", CaseID);
    }

    formData.append("Date", Dates);
    // if (ClientID && ClientID.length > 0) {
    formData.append("ClientID", ClientID);
    // } else {
    // formData.append("ClientID", "");
    // }

    formData.append("Summary", Summary.toUpperCase());
    formData.append("Time", Time);
    formData.append("IsPending", IsPending);
    formData.append("Detail", Details.toUpperCase());
    formData.append("AssignTo", AssignTo);
    formData.append("AppointmentWith", AppointmentWith.toUpperCase());

    try {
      const response = await fetch(`${baseUrl}Appoinment/AddAppoinment`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].message === "Success") {
        toast.success("Appointment added successfully");
        closeButton.click();
        handleClose();
        filterActive ? filterApi() : getViewLetterTemplate();
        clearForm();
        setDates(currentDate);
        setClientID("");
        setCountries([]);
        setCaseID("");
        setSummary("");
        setAssignTo("");
        setAppointmentWith("");
        setIsPending("");
        setDetails("");
        setTime("");
        setSaveButton(true);
      } else {
        toast.error(data.result.message);
        setSaveButton(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        ---------- EDIT --------

  const submitEditHandler = async (event) => {
    event.preventDefault();
    editAppointment.append("ID", editId);
    editAppointment.append("AdminID", localStorage.getItem("id"));
    editAppointment.append("UpdatedBy", localStorage.getItem("UserId"));
    if (EditCaseID && EditCaseID.length > 0) {
      editAppointment.append("CaseID", EditCaseID);
    }

    editAppointment.append("Date", EditDate);
    // if (ClientID && EditClientID.length > 0) {
    editAppointment.append("ClientID", EditClientID);
    // }

    editAppointment.append("Summary", EditSummary.toUpperCase());
    editAppointment.append("Time", EditTime);
    editAppointment.append("IsPending", EditIsPending);
    editAppointment.append("Detail", EditDetails.toUpperCase());
    editAppointment.append("AssignTo", EditAssignTo);
    editAppointment.append("AppointmentWith", EditAppointmentWith.toUpperCase());
    try {
      const response = await fetch(`${baseUrl}Appoinment/EditAppoinment`, {
        method: "POST",
        body: editAppointment,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeEdit.click();
          navigate("/");
        }, 3000);
      }
      if (data.result.length && data.result[0].code == 200) {
        toast.success("Appointment Updated successfully");
        filterActive ? filterApi() : getViewLetterTemplate();
        closeEdit.click();
        clearForm();
        setEditCaseID("");
        setEditAssignTo("");
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const convertTo12HourFormat = (time) => {
    if (!time || typeof time !== "string" || !time.includes(":")) {
      return "";
    }

    const [hours, minutes] = time.split(":");
    let period = "AM";
    let hour = parseInt(hours, 10);

    if (hour >= 12) {
      period = "PM";
      if (hour > 12) {
        hour -= 12;
      }
    }

    return `${hour} : ${minutes} ${period}`;
  };

  // ---------- FILTER API ---------

  const filterApi = async () => {
    setLoading(true);
    filterform.append("AdminID", localStorage.getItem("id"));
    filterform.append("UserID", localStorage.getItem("uId"));
    filterform.append("CaseID", fCaseId);
    filterform.append("ClientID", fClientID);
    filterform.append("From", fFromDate);
    filterform.append("To", fToDate);
    try {
      const response = await fetch(
        `${baseUrl}Appoinment/ViewAppointmentfilter`,
        {
          method: "POST",
          body: filterform,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        }
      );
      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 2000);
      }

      if (
        data &&
        data.result.length &&
        data &&
        data.result[0].message === "Success"
      ) {
        setFilterActive(true);
        setLoading(false);
        setLetterTemplatesData(data.result);
        setData(data.result);
      } else {
        setLoading(false);
        setLetterTemplatesData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterCancel = () => {
    clearForm();
    setCaseID("");
    setFCaseId("");
    setFClientID("");
    setFFromDate("");
    setFToDate("");
    getViewLetterTemplate();
    setFilterActive(false);
  };

  const handleFilterCase = (selectedOption) => {
    if (selectedOption === null) {
      setFCaseId("");
    } else {
      setFCaseId(selectedOption.value);
    }
  };

  const handleFilterClient = (selectedOption) => {
    if (selectedOption === null) {
      setFClientID("");
    } else {
      setFClientID(selectedOption.value);
    }
  };

  //        ---------- TABLE --------

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      header: "Action",
      minSize: "10px",
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      Cell: (row) => {
        return (
          <>
            <div style={{ display: "flex", gap: "15px" }}>
              {isEdit !== "0" && (
                <span
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalLongEdit"
                  onClick={() => getById(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/edit.svg"
                    alt="Edit"
                    title="Edit"
                  />
                </span>
              )}

              <span
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongDetail"
                onClick={() => details(row.row.original.Id)}
              >
                <img
                  src="assets/img/icons/eye.svg"
                  alt="Details"
                  title="Details"
                />
              </span>
              <span>
                <a
                  style={{
                    cursor: "pointer",
                    display: isStatus === "0" ? "none" : "block",
                  }}
                  onClick={() => deActive(row.row.original)}
                >
                  {row.row.original.Status === "1" ? (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Up.svg"
                      title="Status"
                    />
                  ) : (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Down.svg"
                      title="Status"
                    />
                  )}
                </a>
              </span>
              {isDelete !== "0" && (
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalDelete"
                  style={{ cursor: "pointer" }}
                  onClick={() => setDelId(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/delete.svg"
                    alt="Delete"
                    title="Delete"
                  />
                </span>
              )}
            </div>
          </>
        );
      },
    }),

    columnHelper.accessor("Date", {
      header: "Date",
      minSize: "10px",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.Date === null ||
            row.row.original.Date === "0000-00-00"
              ? "-"
              : formatDate1(row.row.original.Date)}
          </div>
        );
      },
    }),

    columnHelper.accessor("FileNo", {
      header: "File No",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.FileNo === null || row.row.original.FileNo === ""
              ? "-"
              : row.row.original.FileNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseNo", {
      header: "Case No",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CaseNo === null || row.row.original.CaseNo === ""
              ? "-"
              : row.row.original.CaseNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("CaseName", {
      header: "Case Name",
      Cell: (row) => {
        return (
          <div style={{ minWidth: "350px" }} className="upperCase">
            <a  title="Case Details"
              href={`#/view-casedetails/${row.row.original.CaseID}`}
              className="visuald_case"
              target="_blank"
              style={{ cursor: "pointer" }}
            >
           
              {row.row.original.CaseName === null ||
              row.row.original.CaseName === ""
                ? "-"
                : row.row.original.CaseName}
            </a>
          </div>
        );
      },
    }),

    columnHelper.accessor("AppointmentWith", {
      header: "Appointment With",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.AppointmentWith === null ||
            row.row.original.AppointmentWith === ""
              ? "-"
              : row.row.original.AppointmentWith}
          </div>
        );
      },
    }),

    columnHelper.accessor("ClientName", {
      header: "Client",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.ClientName}
            <br />
            {row.row.original.MobileNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("Detail", {
      header: "Detail",
      Cell: (row) => {
        return (
          <div className="upperCase"
            style={{
              display: " -webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}
          >
            {row.row.original.Detail === null || row.row.original.Detail === ""
              ? "-"
              : row.row.original.Detail}
          </div>
        );
      },
    }),
    columnHelper.accessor("Summary", {
      header: "Summary",
      Cell: (row) => {
        return (
          <div className="upperCase"
            style={{
              display: " -webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}
          >
            {row.row.original.Summary === null ||
            row.row.original.Summary === ""
              ? "-"
              : row.row.original.Summary}
          </div>
        );
      },
    }),

    columnHelper.accessor("AssignTo", {
      header: "Assign To",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.AssignTo === null ||
            row.row.original.AssignTo === ""
              ? "-"
              : row.row.original.AssignTo}
          </div>
        );
      },
    }),

    columnHelper.accessor("UpdatedBy", {
      header: "Last-Modified",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.UpdatedBy === null ||
            row.row.original.UpdatedBy === ""
              ? "-"
              : row.row.original.UpdatedBy}<br/>
                       {formateDateTime(row.row.original.UpdatedDate)}

          </div>
        );
      },
    }),

    columnHelper.accessor("Status", {
      header: "Status",
      Cell: (row) => {
        return <div>{row.row.original.Status === "1" ? "ACTIVE" : "DEACTIVE"}</div>;
      },
    }),

    columnHelper.accessor("AddedBy", {
      header: "Added By",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.AddedBy === null ||
            row.row.original.AddedBy === ""
              ? "-"
              : row.row.original.AddedBy}<br/>
         {formateDateTime(row.row.original.CreatedDate)}
          </div>
        );
      },
    }),
  ];
  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getViewLetterTemplate();
    }
  }, []);

  const excelData =
    letterTemplatesData &&
    letterTemplatesData.map((obj) => {
      const { Id, code, message, Status, AdminID, ...rest } = obj;
      return rest;
    });

  const handleExportData = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });

    const formattedDate = new Date().toISOString().slice(0, 10); // Get today's date
    const filename = `Appointment_${formattedDate}.csv`;

    const csv = generateCsv(csvConfig)(excelData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const columnsToExport = [
      { id: "serialNo", header: "S No." },
      ...columns.filter((column) => selectedColumns.includes(column.id)),
    ];

    const tableHeaders = columnsToExport.map((c) => c.header);

    const formattedDate = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const filename = `Appointment|LegalCRM__${formattedDate}.pdf`;

    const addWatermarkToFirstPage = () => {
      const watermarkHeight = 20;

      // Calculate positioning to center the watermark on each page

      doc.setFontSize(10);
      doc.text(
        10,
        watermarkHeight + -10,
        `Generated by : ${localStorage.getItem("name")} `
      );
      doc.text(172, watermarkHeight + -10, `Date: ${formattedDate}`);
      doc.setFontSize(16);
      doc.text("Appointment Table", 74, 20);
    };
    const watermarkHeight = 20;

    addWatermarkToFirstPage();

    const tableData = rows.map((row, index) => {
      const formattedDateInPdf = row.original["Date"]
        ? format(new Date(row.original["Date"]), "dd/MM/yyyy")
        : "";

      return [
        { content: (index + 1).toString(), styles: { fontStyle: "bold" } }, // Serial number
        ...columnsToExport.slice(1).map((column) => {
          // Exclude the first column (serial number) when mapping
          if (column.id === "Date") {
            return formattedDateInPdf;
          }
          const value =
            column.id in row.original ? row.original[column.id] : "";
          return value !== null && value !== undefined ? value : "";
        }),
      ];
    });

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY: watermarkHeight + 5,
    });

    // Save the PDF to a Blob
    const pdfBlob = doc.output("blob");

    // Create a blob URL
    const blobUrl = URL.createObjectURL(pdfBlob);

    // Open the URL in a new tab
    window.open(blobUrl, "_blank");

    // Optionally revoke the object URL after opening the new tab
    URL.revokeObjectURL(blobUrl);
    // doc.save(filename);
  };
  // ---------DataTable-----
  const table = useMaterialReactTable({
    columns,
    data: letterTemplatesData,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    enableRowNumbers: true,
    initialState: {
      columnVisibility: {
        AddedBy: false,
        UpdatedBy: false,
        UpdatedDate: false,
        CreatedDate: false,
      },
    },
    state: {
      isLoading: loading,
    },
    muiCircularProgressProps: {
      color: "primary",
      thickness: 5,
      size: 55,
      style: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000,
      },
    },
    muiSkeletonProps: {
      animation: "pulse",
      height: 20,
    },
    muiPaginationProps: {
      rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export Excel
        </Button>

        <Button
        disabled={!table || table.getPrePaginationRowModel().rows.length === 0}
        onClick={handleExportButtonClick}
        startIcon={<FileDownloadIcon />}
      >
        Export PDF
      </Button>
      <Dialog open={open} onClose={handleClose1}>
        <DialogTitle>Select Columns To Export</DialogTitle>
        <DialogContent>
            {columns.map((column) =>
              column.header !== "Action" ? (
                <div key={column.id}>
                  <Checkbox
                    checked={selectedColumns.includes(column.id)}
                    onChange={() => handleToggleColumn(column.id)}
                  />
                  {column.header}
                </div>
              ) : (
                ""
              )
            )}
          </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1}>Cancel</Button>
          <Button onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}>Export</Button>
        </DialogActions>
      </Dialog>
      </Box>
    ),
  });
  return (
    <>
      <DocumentTitle title="APPOINTMENT | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  <div className="pageContent">
                    <div className="PageTitle">
                      <h3>Appointment</h3>

                      <a
                        style={{
                          display: isAdd === "0" ? "none" : "block",
                        }}
                        type="button"
                        className="btn btn-primary btn_client headbtn"
                        data-toggle="modal"
                        data-target="#exampleModalLong"
                      >
                        Add Appointment
                        <img src="assets/img/icons/add.svg" />
                      </a>
                    </div>

                    <div
                      className="filter_type add_wrap"
                      style={{
                        display: isView === "0" ? "none" : "block",
                      }}
                    >
                      <div className="form_flex1 flex_wrap  caseMobile">
                        <div className="form-group1 zIndex1">
                          <label>File No.</label>

                          <Select
                            className="basic-single"
                            placeholder="Please Select "
                            classNamePrefix="select"
                            onChange={handleFilterCase}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={optionsEvent}
                          />
                        </div>
                        <div className="form-group1 zIndex2">
                          <label>Client Name</label>

                          <Select
                            className="basic-single"
                            placeholder="Please Select "
                            classNamePrefix="select"
                            onChange={handleFilterClient}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={fClientOption}
                          />
                        </div>
                        <div className="form-group1">
                          <label>From Date</label>
                          <input
                            // max={currentDate}
                            autoComplete="off"
                            className="name"
                            type="date"
                            value={fFromDate}
                            onChange={(e) => setFFromDate(e.target.value)}
                          />
                        </div>
                        <div className="form-group1">
                          <label>To Date</label>
                          <input
                            autoComplete="off"
                            className="name"
                            type="date"
                            value={fToDate}
                            onChange={(e) => setFToDate(e.target.value)}
                          />
                        </div>
                        <div className="form-group1 caseMobile btnGroup">
                          <div className="btn_main casebtn">
                            <button
                              type="submit"
                              className="btn btn_client"
                              onClick={filterApi}
                            >
                              Search
                            </button>
                            <button
                              type="button"
                              className="btn btn_client"
                              onClick={filterCancel}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {Appoint &&
                    Appoint.length > 0 &&
                    Appoint[0].message === "Data not found" ? (
                      <h3 className="visuald">
                        There are no records to display
                      </h3>
                    ) : (
                      <>
                        <div
                          style={{
                            display: isView === "0" ? "none" : "block",
                          }}
                          className="searDesign"
                        >
                          <MaterialReactTable table={table} />
                        </div>
                      </>
                    )}
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <Footer />
            </div>

            {/*-------- ADD FORM ---------*/}

            <div
              className="modal fade"
              id="exampleModalLong"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
              show={show}
            >
              <div
                className="modal-dialog modal-xl"
                role="document"
                // style={{ maxWidth: "65%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Add Appointment</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="name">File No.<text className="m_star">*</text></label>
                            <Select
                            required
                              className="basic-single"
                              placeholder="Select "
                              classNamePrefix="select"
                              onChange={handleSelectChange}
                              isClearable={true}
                              isSearchable={true}
                              name="color"
                              options={optionsCase}
                            />
                          </div>
                          <div className="form-group">
                            <label>Client Name<text className="m_star">*</text></label>

                            <select
                            required
                              className="name"
                              id="city"
                              onChange={(e) => setClientID(e.target.value)}
                            >
                              <option value="">--Please Select--</option>
                              {countries && countries.length > 0 ? (
                                countries.map((val) => (
                                  <option key={val.Id} value={val.Id}>
                                    {val.FirstName}
                                  </option>
                                ))
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="name">Appointment With </label>
                            <input
                              value={AppointmentWith}
                              type="text"
                              className="name"
                              placeholder="Enter Appointment With"
                              onChange={(e) =>
                                setAppointmentWith(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="name">
                              Date <text className="m_star">*</text>
                            </label>
                            <input
                              required
                              value={Dates}
                              type="Date"
                              className="name"
                              placeholder="Enter Date"
                              onChange={(e) => setDates(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="name">Time </label>
                            <input
                              value={Time}
                              type="Time"
                              className="name"
                              placeholder="Enter Time"
                              onChange={(e) => setTime(e.target.value)}
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="name">
                              Assigned to User <text className="m_star">*</text>
                            </label>
                            <select
                              required
                              className="name"
                              id="city"
                              value={AssignTo}
                              onChange={(e) => setAssignTo(e.target.value)}
                            >
                              <option value="">--Please Select--</option>
                              {User && User.length > 0 ? (
                                User.map((val) => (
                                  <option key={val.id} value={val.Id}>
                                    {val.ContactPersonName}
                                  </option>
                                ))
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="city">Is Pending</label>
                            <select
                              className="name"
                              value={IsPending}
                              onChange={(e) => setIsPending(e.target.value)}
                            >
                              <option value="">--Please Select--</option>
                              <option value="0">PENDING</option>
                        <option value="1">DONE</option>
                            </select>
                          </div>

                          <div className="form-group">
                            <label htmlFor="name">Summary </label>
                            <input
                              value={Summary}
                              type="text"
                              className="name"
                              placeholder="Enter Summary"
                              onChange={(e) => setSummary(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="name">Details </label>
                            <textarea
                              value={Details}
                              type="text"
                              className="name"
                              placeholder="Enter Details"
                              onChange={(e) => setDetails(e.target.value)}
                            />
                          </div>
                        </div>
                        {
                          saveButton ? (
                            <div className="btn_main caseMobile">
                            <button type="submit" className="btn_save">
                              Save
                            </button>
                            <button
                              type="button"
                              data-dismiss="modal"
                              className="btn_save btn_cancle"
                            >
                              Cancel
                            </button>
                          </div>
                          ) : ""
                        }
                     
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- Edit FORM ---------*/}

            <div
              className="modal fade"
              id="exampleModalLongEdit"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
              show={show}
            >
              <div
                className="modal-dialog modal-xl"
                role="document"
                // style={{ maxWidth: "65%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Edit Appointment</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close e_popup btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitEditHandler}>
                        
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="name">File No.<text className="m_star">*</text></label>

                            <select
                            required
                              className="name"
                              id="cars"
                              onChange={(e) => handleEditCaseId(e.target.value)}
                            >
                              <option value="">--Please Select--</option>
                              {FileNo && FileNo.length ? (
                                FileNo.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.CaseID}
                                      selected={val.CaseID === EditCaseID}
                                    >
                                      {val.FileNo && `${val.FileNo} /`}
                                      {val.CaseName && `${val.CaseName} /`}
                                      {val.CaseYear}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>

                          <div className="form-group">
                            <label htmlFor="name">
                              Client Name<text className="m_star">*</text>
                            </label>

                            <select
                            required
                              className="name"
                              id="cars"
                              onChange={(e) => setEditClientID(e.target.value)}
                       
                            >
                              <option value="">--Please Select--</option>
                              {countries && countries.length > 0 ? (
                                countries.map((val) => (
                                  <option
                                    key={val.Id}
                                    value={val.Id}
                                    selected={val.Id === EditClientID}
                                  >
                                    {val.FirstName}
                                  </option>
                                ))
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>

                          <div className="form-group">
                            <label htmlFor="name">Appointment With </label>
                            <input
                              type="text"
                              value={EditAppointmentWith}
                              className="name"
                              placeholder="Enter Appointment With (if case not selected)"
                              onChange={(e) =>
                                setEditAppointmentWith(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="name">
                              Date <text className="m_star">*</text>
                            </label>
                            <input
                              required
                              value={EditDate}
                              type="Date"
                              className="name"
                              placeholder="Enter Date"
                              onChange={(e) => setEditDate(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="name">Time </label>
                            <input
                              type="Time"
                              value={EditTime}
                              className="name"
                              placeholder="Enter Time"
                              onChange={(e) => setEditTime(e.target.value)}
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="name">
                              Assigned to User <text className="m_star">*</text>
                            </label>
                            <select
                              id="city"
                              required
                              onChange={(e) => setEditAssignTo(e.target.value)}
                            >
                              <option>--Please Select--</option>
                              {User && User.length > 0 ? (
                                User.map((val) => (
                                  <option
                                    key={val.id}
                                    value={val.Id}
                                    selected={val.Id === EditAssignTo}
                                  >
                                    {val.ContactPersonName}
                                  </option>
                                ))
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="city">Is Pending</label>
                            <select
                              value={EditIsPending}
                              onChange={(e) => setEditIsPending(e.target.value)}
                            >
                              <option value="">--Please Select--</option>
                              <option value="0">PENDING</option>
                        <option value="1">DONE</option>
                            </select>
                          </div>

                          <div className="form-group">
                            <label htmlFor="name">Summary </label>
                            <input
                              value={EditSummary}
                              type="text"
                              className="name"
                              placeholder="Enter Summary"
                              onChange={(e) => setEditSummary(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="name">Details </label>
                            <textarea
                              value={EditDetails}
                              type="text"
                              className="name"
                              placeholder="Enter Details"
                              onChange={(e) => setEditDetails(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_save btn_cancle"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- DELETE FORM -------*/}

            <div
              className="modal fade sc_modal"
              id="exampleModalDelete"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close mm"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <h4>Delete Row</h4>
                    <p>Are you sure you want to delete this Appointment ?</p>
                    <ul>
                      <li>
                        <a
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </a>
                      </li>
                      <li>
                        <a
                          className="btn btn-danger"
                          onClick={() => DeleteAppoinment(delId)}
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/*-------- Details FORM ---------*/}

            <div
              className="modal fade"
              id="exampleModalLongDetail"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
              show={show}
            >
              <div
                className="modal-dialog modal-xl"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>View Appointment</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close ab btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Case Name &nbsp;: &nbsp;
                            {`${dCaseID} / ${dCaseName}`}
                          </h4>
                        </div>

                        <div className="form-group">
                          <h4>
                            Client &nbsp;: &nbsp;
                            {dClientID == null ? "-" : dClientID}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Date &nbsp;: &nbsp;
                            {dDate === null || dDate === "0000-00-00"
                              ? "-"
                              : formatDate1(dDate)}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Time &nbsp;: &nbsp;
                            {dTime == null ? "-" : convertTo12HourFormat(dTime)}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Assigned to User&nbsp;: &nbsp;
                            {dAssignTo == null ? "-" : dAssignTo}
                          </h4>
                        </div>
                        <div className="form-group">
                          <div>
                            <h4>
                              Is Pending &nbsp;: &nbsp;
                              {dIsPending === "1" ? "DONE" : "PENDING"}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Appointment With &nbsp;: &nbsp;
                            {dAppointmentWith == null ? "-" : dAppointmentWith}
                          </h4>
                        </div>
                        <div className="form-group Comment_break">
                          <h4>
                            Summary &nbsp;: &nbsp;
                            {dSummary == null ? "-" : dSummary}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group Comment_break">
                          <h4>
                            Details&nbsp;: &nbsp;
                            {dDeatils == null ? "-" : dDeatils}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*---------- Toster --------*/}

            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        )}
      </DocumentTitle>
    </>
  );
};

export default Appointments;
