import React from 'react';
import { useNavigate } from "react-router";

const Unauthorized = () => {
  const navigate = useNavigate();

    const goBack = () => {
        navigate("/home");
      };
      
  return (
    <div className="unauthorized-container">
      <div className="parallax-container">
        <div className="parallax-content">
        <h1>Error 401</h1>
          <h1>Unauthorized Access</h1>
          <p>You don't have permission to access this page.</p>
          
            <button className="home-button" onClick={goBack}>HOME</button>
          
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
