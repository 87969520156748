import React, { useState, useRef } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import Select from "react-select";
import { viewData, permission, formatDate1 } from "../services/api.service";
import DocumentTitle from "react-document-title";
import Preloader from "./Preloader";
import Footer from "./Footer";

const localizer = momentLocalizer(moment);

const CalendarPage = () => {
  const currentDate = new Date().toISOString().split("T")[0];

  const baseUrl = useContext(url);
  const [caseLeadData, setCaseLeadData] = useState([]);
  const [isAdd, setIsAdd] = useState("0");
  const [isView, setIsView] = useState("0");

  //---------- ADD EVENT VARIABLE ----------
  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  const closeButton = document.querySelector(".btn_popup");

  const [eventDetails, setEventDetails] = useState("");
  const [isEffective, setIsEffective] = useState("");
  const [isUpcoming, setIsUpcoming] = useState("");
  const [nextDate, setNextDate] = useState("");
  const [caseID, setCaseID] = useState("");
  const [date, setDate] = useState(currentDate);
  //---------- ADD EVENT ----------
  const formData = new FormData();
  const submitHandler = async (event) => {
    event.preventDefault();
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    formData.append("Date", date);
    formData.append("EventDetails", eventDetails);
    formData.append("IsEffective", isEffective);
    formData.append("IsUpcoming", isUpcoming);
    formData.append("NextDate", nextDate);
    formData.append("CaseID", caseID);
    try {
      const response = await fetch(`${baseUrl}Event/AddEvent`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].code == 200) {
        toast.success(" added successfully");
        closeButton.click();
        handleClose();
        getApiData();
        setIsEffective("");
        setCaseID("");
        setDate(currentDate);
        setNextDate("");
        setEventDetails("");
        setIsUpcoming("");
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  const myCustomFormats = {
    dateFormat: "DD/MM/YYYY",
    dayFormat: "DD/MM/YYYY",
    weekdayFormat: "DD",
  };

  // Create an events array (replace with your data)
  const [events, setEvents] = useState([]);
  const [eventsd, setEventsd] = useState([]);

  //---------- VIEW EVENT DATA ----------
  function formatCustomDate(customDate) {
    return moment(customDate, "YYYY-MM-DD").format("MM/DD/YYYY");
  }
  const formattedDate = formatCustomDate();

  //---------- VIEW EVENT DATA ----------
  const form = new FormData();

  const viewEventApi = async () => {
    form.append("AdminID", localStorage.getItem("id"));
    form.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}Event/ViewEvent`, {
        method: "POST",
        body: form,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message === "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }
      setCaseLeadData(data.result);

      if (data && data.result[0].message === "Success") {
        const Mapped = data.result.map((item) => ({
          id: item.CaseID,
          title: `${item.FileNo} / ${item.CaseName} / ${item.CaseNo}`,
          EventDetails: item.EventDetails,
          start: item.Date,
          end: item.Date, 
        }));
        setEvents(Mapped);
      }

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      const formattedEvents =
        data &&
        data.result.map((val) => ({
          id: val.Id,
          EventDetails: val.EventDetails,
          title: `${val.FileNo} / ${val.CaseName} / ${val.CaseNo}`,
          start: val.Date,
          end:
            val.Date,
        }));

      setEventsd(formattedEvents);
    } catch (error) {
      console.error(error);
    }
  };

  const getApiData = async () => {
    const [data] = await Promise.all([permission(22), viewEventApi()]);

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }

    setIsAdd(data && data.result[0].IsAdd);
    setIsView(data && data.result[0].IsView);
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    }

    if (localStorage.getItem("login") == "success") {
      toast.success("Successfully User Login");
      localStorage.removeItem("login");
    }
  }, []);

  /** Get File Name  */
  const Case = new FormData();
  const [optionsEvent, setOptionsEvent] = useState([]); // Initialize options state
  const getFileNo = async () => {
    Case.append("AdminID", localStorage.getItem("id"));
    Case.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: Case,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data = await response.json();
      if (data && data.result[0].message === "Success") {
        const Mapped = data.result.map((item) => ({
          value: item.CaseID,
          label: `${item.FileNo} / ${item.CaseName} / ${item.CaseYear}`,
        }));
        setOptionsEvent(Mapped);
      }
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setCaseID(null);
    } else {
      setCaseID(selectedOption.value);
    }
  };
  const handleClearSelection = (selectedOption) => {
    setCaseID(selectedOption.value);
  };
  useEffect(() => {
    getFileNo();
  }, []);

  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
  };

  const handleSelectSlot = (slotInfo) => {
    setSelectedEvent({
      title: "New Event",
      start: slotInfo.start,
      end: slotInfo.end,
    });
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getApiData();
    }
  }, []);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <>
      <DocumentTitle title="CALENDAR | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  <div className="pageContent">
                    <div className="PageTitle">
                      <h3>Event Calendar</h3>
                      <a
                        style={{
                          display: isAdd === "0" ? "none" : "block",
                        }}
                        type="button"
                        className="btn btn-primary btn_client headbtn"
                        data-toggle="modal"
                        data-target="#exampleModalLong"
                      >
                        Add Event
                        <img src="assets/img/icons/add.svg" />
                      </a>
                    </div>

                    <div
                      style={{
                        display: isView === "0" ? "none" : "block",
                      }}
                      className="searDesign"
                    >
                      <Calendar
                        className="custom-calendar"
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        onSelectEvent={handleSelectEvent}
                        onSelectSlot={handleSelectSlot}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {selectedEvent && (
              <div className="CalendarpageBody">
                <div className="CalendarpageContent">
                  <div className="bankDetailBox">
                    <div className="leftDet">
                      <div className="printNames">
                        <strong>Title:</strong>
                        <span>{selectedEvent.title}</span>
                      </div>
                      <div className="printNames">
                        <strong>Date:</strong>
                        <span>{formatDate1(selectedEvent.start)}</span>
                      </div>
                      <div className="printNames">
                        <strong>Details:</strong>
                        <span>{selectedEvent.EventDetails}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/*-------- ADD EVENT FORM ---------*/}
            <Footer />
            <div
              className="modal fade"
              id="exampleModalLong"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
              show={show}
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }} 
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Add Event</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form " onSubmit={submitHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label for="uname">Date</label>
                            <input
                              autoComplete="off"
                              max={currentDate}
                              className="name"
                              type="date"
                              value={date}
                              onChange={(e) => setDate(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label for="uname">File No.</label>
                            <text className="m_star">*</text>
                            <Select
                              required
                              className="basic-single name"
                              placeholder="Please Select "
                              classNamePrefix="select"
                              onChange={handleSelectChange}
                              isClearable={true} 
                              isSearchable={true} 
                              name="color"
                              options={optionsEvent} 
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <div>
                              <label for="uname">Is Effective </label>
                              <select
                                value={isEffective}
                                onChange={(e) => setIsEffective(e.target.value)}
                              >
                                <option>--Please Select--</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>
                          </div>
                          <div className="form-group">
                            <div>
                              <label for="uname">Is Upcoming </label>
                              <select
                                value={isUpcoming}
                                onChange={(e) => setIsUpcoming(e.target.value)}
                              >
                                <option>--Please Select--</option>
                                <option value="Upcoming">Upcoming</option>
                                <option value="Completed">Completed</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label for="uname">Next Date</label>
                            <input
                              autoComplete="off"
                              className="name"
                              type="date"
                              value={nextDate}
                              onChange={(e) => setNextDate(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label for="uname">Event Details</label>
                            <textarea
                              value={eventDetails}
                              onChange={(e) => setEventDetails(e.target.value)}
                              rows={4}
                              cols={50}
                              placeholder="Enter your text here..."
                            />
                          </div>
                        </div>
                        <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            className="btn_save btn_cancle"
                            data-dismiss="modal"
                            onClick={handleClearSelection}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </DocumentTitle>
    </>
  );
};

export default CalendarPage;
