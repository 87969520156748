import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SidebarLink = styled(Link)`
// display: flex;
// background:#7a0000 !important;
// color: white;
// justify-content: space-between;
// align-items: center;
// padding: 20px;
// list-style: none;
// height: 60px;
// text-decoration: none;
// font-size: 18px;

display: flex;
    background: #ffffff!important;
    color: #810002;
    justify-content: space-between;
    align-items: center;
    padding: 9px 10px;
    list-style: none;
    border-radius: 10px;
    text-decoration: none;
    font-size: 16px;
    margin: 0 0 6px;

;
&:hover {
	background: #f5f8ff !important;
    cursor: pointer;
    color: #7f0000;
}
`;

const SidebarLabel = styled.span`
margin-left: 16px;
`;

const DropdownLink = styled(Link)`
background: #ffffff !important;
padding-left: 3rem;
display: flex;
align-items: center;
text-decoration: none;
padding: 8px 10px;
color: black !important;
font-size: 16px;
border-radius: 11px;
margin: 0 0 2px 27px;
	

&:hover {
	background: #f1f1f1 !important;
    cursor: pointer;
    color: black;
}
`;

const SubMenu = ({ item }) => {
const [subnav, setSubnav] = useState(false);

const showSubnav = () => setSubnav(!subnav);

return (
	<>
	<SidebarLink to={item.path}
	onClick={item.subNav && showSubnav}>
		<div>
		{item.icon}
		<SidebarLabel>{item.title}</SidebarLabel>
		</div>
		<div>
		{item.subNav && subnav
			? item.iconOpened
			: item.subNav
			? item.iconClosed
			: null}
		</div>
	</SidebarLink>
	{subnav &&
		item.subNav.map((item, index) => {
		return (
			<DropdownLink to={item.path} key={index}>
			{item.icon}
			<SidebarLabel>{item.title}</SidebarLabel>
			</DropdownLink>
		);
		})}
	</>
);
};

export default SubMenu;