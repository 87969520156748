import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const ViewClient = createApi({
  reducerPath: "ViewClient",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.lawyerhunt.in/api/Client/ViewClient",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("access_token");
      if (token) {
        headers.set("Authorization", `${token}`);
        headers.set("Accept", "*/*");
      }
    },
  }),
  endpoints: (builder) => ({
    getViewClient: builder.query({
      query: (credentials) => {
        return {
          method: "POST",
          body: credentials,
        };
      },
    }),
  }),
});

export const { useGetViewClientQuery } = ViewClient;
export default ViewClient;
