import React, { useState, useEffect } from "react";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useNavigate } from "react-router";
import { viewData, customStyles, permission } from "../services/api.service";
import DocumentTitle from "react-document-title";
import Preloader from "./Preloader";
import Footer from "./Footer";

const CaseTransfer = () => {
  //        -------- VARIABLE ---------

  const baseUrl = useContext(url);
  const currentDate = new Date().toISOString().split("T")[0];
  const navigate = useNavigate();
  const formData = new FormData();
  const caseForm = new FormData();
  const form = new FormData();

  const [Dates, setDates] = useState(currentDate);
  const [isView, setIsView] = useState("0");
  const [viewUserData, setViewUserData] = useState([]);
  const [counselFrom, setCounselFrom] = useState("");
  const [counselTo, setCounselTo] = useState("");
  const [caseDetailData, setCaseDetailData] = useState([]);
  const [caseDetail, setCaseDetail] = useState([]);
  const [counselToData, setCounselToData] = useState([]);
  const [demoData, setDemoData] = useState([]);

  //        -------- API CASE TRANSFER ---------

  const withUidApi = async (path) => {
    form.append("AdminID", localStorage.getItem("id"));
    form.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}${path}`, {
        method: "POST",
        body: form,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        return "token";
      }

      if (data && data.result.UserList[0].message === "Success") {
        return data.result.UserList;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getUser = async (path) => {
    form.append("AdminID", localStorage.getItem("id"));
    form.append("UserID", "");
    try {
      const response = await fetch(`${baseUrl}${path}`, {
        method: "POST",
        body: form,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        return "token";
      }

      if (data && data.result.UserList[0].message === "Success") {
        return data.result.UserList;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getUserApi = async () => {
    const [userData, data] = await Promise.all([
      getUser("Admin/ViewUser"),
      permission(26),
    ]);

    if (userData === "token") {
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } else {
      const filterCounselData =
        userData && userData.filter((item) => item.Status === "1");

      filterCounselData &&
        filterCounselData.sort((a, b) => a.Name.localeCompare(b.Name));

      setViewUserData(filterCounselData);
    }
    setIsView(data && data.result[0].IsView);
  };

  //        -------- SUBMIT CASE TRANSFER ---------

  const submitHandler = async (event) => {
    event.preventDefault();
    if (counselTo === "") {
      toast.error("Please Select CounselTo");
      return;
    }

    const filter =
      caseDetailData && caseDetailData.filter((item) => item.checkType === "1");

    const counselToPer =
      filter &&
      filter.map((item) =>
        item.CounselPer === ""
          ? demoData && demoData.find((val) => val.Id === item.Id)?.CounselPer
          : item.CounselPer
      );
    const caseId = filter && filter.map((item) => item.Id);

    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    formData.append("Date", Dates);
    formData.append("CounselTo", counselTo);
    formData.append("CounselFrom", counselFrom);
    formData.append("CounselToPer", counselToPer);
    formData.append("CaseID", caseId);

    try {
      const response = await fetch(`${baseUrl}CaseTransfer/AddCaseTransfer`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });

      const data = await response.json();

      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }

      if (data.result.length && data.result[0].code === "200") {
        toast.success("Case Transfer Successfully");
        setDates(currentDate);
        setCaseDetailData([]);
        setCounselToData([]);
        setCounselFrom("");
        setCounselTo("");
        getUserApi();
      } else {
        toast.error(data.result.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        --------CASE TRANSFER VIEW API ---------

  const CaseTransferApi = async (id) => {
    caseForm.append("AdminID", localStorage.getItem("id"));
    caseForm.append("CounselID", id);
    try {
      const response = await fetch(`${baseUrl}CaseTransfer/ViewCaseByUser`, {
        method: "POST",
        body: caseForm,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result.message === "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }

      setCaseDetail(data && data.result);
      setDemoData(data && data.result);
    } catch (error) {
      console.error(error);
    }
  };

  // -------- SELECT COUNSEL FROM -------

  const handleFromCounsel = (value) => {
    if (value === "") {
      setCaseDetailData([]);
      setCounselToData([]);
      setCounselFrom("");
      setCounselTo("");
    } else {
      setCaseDetailData([]);
      setCounselFrom(value);
      CaseTransferApi(value);
      setCounselTo("");

      const data =
        viewUserData && viewUserData.filter((item) => item.Id !== value);
      setCounselToData(data);
    }
  };

  // -------- SELECT COUNSEL TO -------

  const handleToCounsel = (value) => {
    if (value === "") {
      setCounselTo("");
    } else {
      setCounselTo(value);
    }
  };

  const handleCheckBox = (e, index) => {
    let res = [...caseDetailData];
    res[index].checkType = e.target.checked ? "1" : "0";
    setCaseDetailData(res);
  };

  const handlePer = (e, index) => {
    let res = [...caseDetailData];
    res[index].CounselPer = e.target.value;
    setCaseDetailData(res);
  };

  // ------- TABLE --------

  const columnHelper = createMRTColumnHelper();

  const columns = [
    columnHelper.accessor("SerialNumber", {
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      maxSize: "10px",
      enableColumnFilter: false,
      header: "S. No.",
      Cell: (row) => {
        return <div>{row.row.index + 1}</div>;
      },
    }),

    columnHelper.accessor("Check Box", {
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      maxSize: "10px",
      enableColumnFilter: false,
      header: "Check Box",
      enableColumnFilter: false,
      Cell: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            style={{ width: "22px" }}
            type="checkbox"
            onChange={(e) => handleCheckBox(e, row.row.index)}
          />
        </div>
      ),
    }),

    columnHelper.accessor("CaseName", {
      header: "Case Name",
      Cell: (row) => {
        return (
          <div style={{ minWidth: "350px" }} className="upperCase">
            {`${row.row.original.FileNo} / ${row.row.original.CaseName}`}
          </div>
        );
      },
    }),

    columnHelper.accessor("CounselType", {
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      header: "Counsel Type",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CounselType === "1" ? "Counsel" : "CoCounsel"}
          </div>
        );
      },
    }),

    columnHelper.accessor("mm", {
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      header: "Company (%)",
      Cell: (row) => `${row.row.original.CompanyPer} %`,
    }),

    columnHelper.accessor("Case", {
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      header: "Percentage (%)",
      Cell: (row) => {
        return (
          <p style={{ display: "flex", textAlign: "center" }}>
            <input
              style={{
                width: "60px",
                padding: "7px",
                display: row.row.original.checkType === "1" ? "block" : "none",
              }}
              onChange={(e) => handlePer(e, row.row.index)}
              type="text"
              value={row.row.original.CounselPer}
            />
            &nbsp;&nbsp; <span style={{ marginTop: "8px" }}>%</span>
          </p>
        );
      },
    }),
  ];

  const table = useMaterialReactTable({
    columns,
    data: caseDetailData,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "none",
    positionToolbarAlertBanner: "bottom",
    enablePagination: false,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      ></Box>
    ),
  });

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getUserApi();
    }
  }, []);

  useEffect(() => {
    const updateData =
      caseDetail &&
      caseDetail.map((val, index) => {
        return { ...val, checkType: "0" };
      });

    setCaseDetailData(updateData);
  }, [caseDetail]);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <>
      <DocumentTitle title="CASE TRANSFER | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  <div className="pageContent">
                    <div className="PageTitle">
                      <h3>Manage Case Transfer</h3>
                    </div>
                    <form
                      role="form"
                      onSubmit={submitHandler}
                      style={{ display: isView === "1" ? "block" : "none" }}
                    >
                      <div className="caseMobile add_wrap">
                        <div className="form_flex1 flex_wrap caseMobile">
                          {localStorage.getItem("admin") == "true" ? (
                            <div className="form-group1">
                              <label htmlFor="uname">
                                Counsel From
                                <text className="case_transfer">*</text>
                              </label>
                              <select
                                value={counselFrom}
                                required
                                name="cars"
                                id="cars"
                                onChange={(e) =>
                                  handleFromCounsel(e.target.value)
                                }
                              >
                                <option value="">--Please Select--</option>
                                {viewUserData &&
                                  viewUserData.map((val, index) => {
                                    return (
                                      <option key={index} value={val.Id}>
                                        {val.Name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          ) : (
                            <div className="form-group1">
                              <label htmlFor="uname">
                                Counsel From
                                <text className="case_transfer">*</text>
                              </label>
                              <select
                                value={counselFrom}
                                required
                                name="cars"
                                id="cars"
                                onChange={(e) =>
                                  handleFromCounsel(e.target.value)
                                }
                              >
                                <option value="">--Please Select--</option>
                                {viewUserData &&
                                  viewUserData.map((val, index) => {
                                    if (
                                      val.Id === localStorage.getItem("uId")
                                    ) {
                                      return (
                                        <option key={index} value={val.Id}>
                                          {val.Name}
                                        </option>
                                      );
                                    }
                                  })}
                              </select>
                            </div>
                          )}

                          <div className="form-group1">
                            <label htmlFor="uname">
                              Counsel To{" "}
                              <text className="case_transfer">*</text>
                            </label>
                            <select
                              value={counselTo}
                              required
                              name="cars"
                              id="cars"
                              onChange={(e) => handleToCounsel(e.target.value)}
                            >
                              <option value="">--Please Select--</option>
                              {counselToData &&
                                counselToData.map((val, index) => {
                                  return (
                                    <option key={index} value={val.Id}>
                                      {val.Name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div>
                        <MaterialReactTable table={table} />
                      </div>

                      <div
                        className="btn_main"
                        style={{
                          display: caseDetailData.length ? "block" : "none",
                        }}
                      >
                        <button
                          type="submit"
                          className="btn btn-primary btn_client btn_view"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <Footer />
            </div>

            {/*-------- Toster -----------*/}

            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        )}
      </DocumentTitle>
      {/*-------- TOASTER ---------*/}

      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default CaseTransfer;
