import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useNavigate } from "react-router";
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv } from "export-to-csv";
import {
  deleteUser,
  userDetails,
  activeStatus,
  viewRole,
  permission,
  clearForm,
  formateDateTime,
} from "../services/api.service";
import Preloader from "./Preloader";
import { formatDate1 } from "../services/api.service";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";
import autoTable from "jspdf-autotable";
import { format } from "date-fns";
import jsPDF from "jspdf";
import Footer from "./Footer";
import e from "cors";
const ViewUser = () => {
  const closeButton = document.querySelector(".btn_popup");
  const closeView = document.querySelector(".ab");
  const closeEdit = document.querySelector(".e_popup");
  const closeDelete = document.querySelector(".duser");
  const navigate = useNavigate();
  const baseUrl = useContext(url);
  const handleKeyPress = (e) => {
    const charCode = e.which || e.keyCode;
    // Allow only numeric characters and backspace
    if (
      charCode < 48 ||
      charCode > 57 ||
      (charCode !== 8 && e.target.value.length >= 10)
    ) {
      e.preventDefault();
    }
  };

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {

    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);
  //--------- Edit User Variable --------

  const [editUserId, setEditId] = useState();
  const [editUserName, setEditUserName] = useState("");
  const [editEmail, setEditEmail] = useState("");
  const [editRole, setEditRole] = useState("");
  const [editQualification, setEditQualification] = useState("");
  const [editMobileNo, setEditMobileNo] = useState();
  const [editAlternateContact, seteditAlternateContact] = useState();
  const [editAddress, setEditAddress] = useState("");
  const [isAdd, setIsAdd] = useState("0");
  const [isEdit, setIsEdit] = useState("0");
  const [isView, setIsView] = useState("0");
  const [isDelete, setIsDelete] = useState("0");
  const [isStatus, setIsStatus] = useState("0");
  const [excel, setExcel] = useState([]);
  const [dName, setDName] = useState("");
  const [dEmail, setDEmail] = useState();
  const [dMobileNo, setDMobileNo] = useState();
  const [dAddress, setDAddress] = useState("");
  const [dQualification, setDQualification] = useState("");
  const [dRoleName, setDRoleName] = useState("");
  const [dActivateFrom, setDActivateFrom] = useState("");
  const [dActivateTo, setDActivateTo] = useState();
  const [dActivationDate, setDActivationDate] = useState();
  const [dAlternateContact, setDAlternateContact] = useState("");
  const [loading, setLoading] = useState(true);

  //------ Add User Variable --------

  const [delId, setDelId] = useState();
  const [userName, setUserName] = useState("");
  const [mail, setMail] = useState("");
  const [contatNo, setContactNo] = useState("");
  const [AlternateContact, setAlternateContact] = useState("");
  const [address, setAddress] = useState("");
  const [roleId, setRoleId] = useState("");
  const [Qualification, setQualification] = useState("");
  const [password, setPassword] = useState("");
  const [filterUser, setFilterUser] = useState();
  const [active, setActive] = useState(false);
  const [viewRoleData, setViewRoleData] = useState();
  const [viewUserData, setViewUserData] = useState();
  const formData = new FormData();
  const editUser = new FormData();
  const form = new FormData();
  const [data, setData] = useState([]);

  const [open, setOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const handleExportButtonClick = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };

  const handleToggleColumn = (columnName) => {
    const currentIndex = selectedColumns.indexOf(columnName);
    const newSelectedColumns = [...selectedColumns];

    if (currentIndex === -1) {
      newSelectedColumns.push(columnName);
    } else {
      newSelectedColumns.splice(currentIndex, 1);
    }

    setSelectedColumns(newSelectedColumns);
  };
  
  //     ------- VIEW USER DATA -------

  const getViewUser = async () => {
    const [view, data, viewRoleD] = await Promise.all([
      getUser("Admin/ViewUser"),
      permission(16),
      viewRole(),
      setLoading(true),
    ]);

    if (view === null) {
      setLoading(false);
    } else {
      if (view === "token") {
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } else {
        setLoading(false);
        // setViewUserData(view);
        setData(view);
      }
    }

    if (
      data &&
      data.result[0].IsAdd === "0" &&
      data &&
      data.result[0].IsView === "0"
    ) {
      navigate("/unauthorized");
    }
    
    setViewRoleData(viewRoleD);
    setIsAdd(data && data.result[0].IsAdd);
    setIsEdit(data && data.result[0].IsEdit);
    setIsView(data && data.result[0].IsView);
    setIsStatus(data && data.result[0].IsStatus);
    setIsDelete(data && data.result[0].IsDelete);
  };

  //     ------- VIEW USER -------
  /** Get File Name  */
  // By Disha 16-09-23
  const Case = new FormData();
  const [optionsCase, setOptionsCase] = useState([]);

   const getUser = async ( path ) => {
    form.append("AdminID", localStorage.getItem("id"));
    form.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(
        `${baseUrl}${path}`,
        {
          method: "POST",
          body : form,
          headers: {
            "Authorization": `${localStorage.getItem("access_token")}`,
            'Accept': '*/*'
          }
        }
      );
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error('Token expired please login again');
          return "token";  
      }
      
      if (data && data.result.UserList[0].message === "Success") {
        return data.result.UserList
      } else{
        return null
      }
  
    }
    catch (error) {
      console.error(error);
    }
  };
  
  //     ------- DELETE USER -------

  const deleteU = async () => {
    const data = await deleteUser(delId, "Admin/DeleteUserById");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
        closeDelete.click();
      }, 3000);
    }
    if (data === true) {
      closeDelete.click();
      getViewUser();
    
    }
  };

  //     ------- DETAILS -------

  const updateUser = async (id) => {
    const data = await userDetails(id, "Admin/ViewUserById");
    if (data === undefined) {
      setEditId("");
      setEditUserName("");
      setEditEmail("");
      setEditMobileNo("");
      setEditRole("");
      seteditAlternateContact("");
      setEditAddress("");
      setEditQualification("");
    } else {
      setEditId(data && data.result[0].Id);
      setEditUserName(data && data.result[0].UserName);
      setEditEmail(data && data.result[0].Email === null || data.result[0].Email === "" ? "" : data.result[0].Email);
      setEditMobileNo(data && data.result[0].MobileNo);
      setEditRole(data && data.result[0].RoleID);
      seteditAlternateContact(data && data.result[0].AlternateContact);
      setEditAddress(data && data.result[0].Address);
      setEditQualification(data && data.result[0].Qualification);
    }

    if (data === "token") {
      setTimeout(() => {
        closeEdit.click();
        navigate("/");
      }, 3000);
    }
  };

  //     ------- DETAILS -------

  const details = async (id) => {
    const data = await userDetails(id, "Admin/ViewUserById");
    if (data === "token") {
      setTimeout(() => {
        closeView.click();
        navigate("/");
      }, 3000);
    } else {
      setDName(data && data.result[0].UserName);
      setDEmail(data && data.result[0].Email);
      setDMobileNo(data && data.result[0].MobileNo);
      setDRoleName(data && data.result[0].RoleName);
      setDAddress(data && data.result[0].Address);
      setDAlternateContact(data && data.result[0].AlternateContact);
      setDQualification(data && data.result[0].Qualification);
      setDActivateFrom(data && data.result[0].ActivateFrom);
      setDActivateTo(data && data.result[0].ActivateTo);
      setDActivationDate(data && data.result[0].ActivationDate);
    }
  };

  //     ------- USER ACTIVE DEACTIVE -------

  const deActive = async (row) => {
    const data = await activeStatus(row, "Admin/UserStatus");
    if (data === "token") {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
    if (data === true) {
      getViewUser();

    }
  };

  //     ------- ADD USER DATA -------

  const submitHandler = async (event) => {
    event.preventDefault();
    if (userName.length < 2) {
      toast.error("Invalid Name");
    } else {
      formData.append("UserName", userName.toUpperCase());
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (mail !== "") {
      if (emailPattern.test(mail)) {
        formData.append("Mail", mail);
      } else {
        toast.error("Invalid Email");
      }
    }

    if (password.length < 4) {
      toast.error("Invalid Pssword");
    } else {
      formData.append("Password", password);
    }

    formData.append("AlternateContact", AlternateContact);

    if (contatNo.length === 10) {
      formData.append("ContactNo", contatNo);
    } else {
      toast.error("Invalid Mobile No");
    }
    formData.append("Address", address.toUpperCase());
    formData.append("RoleId", roleId);
    formData.append("Qualification", Qualification.toUpperCase());
    formData.append("AdminID", `${localStorage.getItem("id")}`);
    formData.append("AddedBy", `${localStorage.getItem("UserId")}`);
    try {
      const response = await fetch(`${baseUrl}Admin/AddUser`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeButton.click();
          navigate("/");
        }, 3000);
      }
      if (data.result[0].code == 200) {
        toast.success("User added successfully");
        closeButton.click();
        getViewUser();
    
        setQualification("");
        setRoleId("");
        setAddress("");
        setUserName("");
        setAlternateContact("");
        setMail("");
        setPassword("");
        setContactNo("");
      } else {
        toast.error(data.result[0].message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //     ------- EDIT USER DATA -------

  const submitEditHandler = async (event) => {
    event.preventDefault();
    if (editUserName && editUserName.length < 2) {
      toast.error("Invalid Name");
    } else {
      editUser.append("UserName", editUserName.toUpperCase());
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (editEmail !== "") {
      if (emailPattern.test(editEmail)) {
        editUser.append("Mail", editEmail);
      } else {
        toast.error("Invalid Email");
      }
    }

      editUser.append("AlternateContact", editAlternateContact);
  

    if (editMobileNo && editMobileNo.length === 10) {
      editUser.append("ContactNo", editMobileNo);
    } else {
      toast.error("Invalid Mobile No");
    }
    editUser.append("Address", editAddress.toUpperCase());
    editUser.append("RoleId", editRole);
    editUser.append("Qualification", editQualification.toUpperCase());
    editUser.append("AdminID", `${localStorage.getItem("id")}`);
    editUser.append("ID", editUserId);
    editUser.append("UpdatedBy", `${localStorage.getItem("UserId")}`);
    try {
      const response = await fetch(`${baseUrl}Admin/EditUser`, {
        method: "POST",
        body: editUser,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          closeEdit.click();
          navigate("/");
        }, 3000);
      }
      if (data.result[0].code == 200) {
        toast.success("User Updated successfully");
        getViewUser();
    
        closeEdit.click();
      }
    } catch (error) {
      console.error(error);
    }
  };

  //     ------- TABLE -------

  const columnHelper = createMRTColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      header: "Action",
      // minSize: "10px",
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      Cell: (row) => {
        return (
          <>
            <div style={{ display: "flex", gap: "15px" }}>
              {isEdit !== "0" && (
                <span
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalLongEdit"
                  onClick={() => updateUser(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/edit.svg"
                    alt="Edit"
                    title="Edit"
                  />
                </span>
              )}

              <span
                type="button"
                data-toggle="modal"
                data-target="#exampleModalLongDetail"
                onClick={() => details(row.row.original.Id)}
              >
                <img
                  src="assets/img/icons/eye.svg"
                  alt="Details"
                  title="Details"
                />
              </span>
              <span>
                <a
                  style={{
                    cursor: "pointer",
                    display: isStatus === "0" ? "none" : "block",
                  }}
                  onClick={() => deActive(row.row.original)}
                >
                  {row.row.original.Status === "1" ? (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Up.svg"
                      title="Status"
                    />
                  ) : (
                    <img
                      className="thumb_icon"
                      src="assets/img/icons/Down.svg"
                      title="Status"
                    />
                  )}
                </a>
              </span>
              {isDelete !== "0" && (
                <span
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  style={{ cursor: "pointer" }}
                  onClick={() => setDelId(row.row.original.Id)}
                >
                  <img
                    src="assets/img/icons/delete.svg"
                    alt="Delete"
                    title="Delete"
                  />
                </span>
              )}
            </div>
          </>
        );
      },
    }),

    columnHelper.accessor("Name", {
      header: "Name",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Name === null || row.row.original.Name === ""
              ? "-"
              : row.row.original.Name}
          </div>
        );
      },
    }),

    columnHelper.accessor("CompanyName", {
      header: "Company Name",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.CompanyName === null ||
            row.row.original.CompanyName === ""
              ? "-"
              : row.row.original.CompanyName}
          </div>
        );
      },
    }),
    columnHelper.accessor("CompanyCode", {
      header: "Company Code",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.CompanyCode === null ||
            row.row.original.CompanyCode === ""
              ? "-"
              : row.row.original.CompanyCode}
          </div>
        );
      },
    }),
    columnHelper.accessor("MobileNo", {
      header: "Mobile No",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.MobileNo === null ||
            row.row.original.MobileNo === ""
              ? "-"
              : row.row.original.MobileNo}
          </div>
        );
      },
    }),

    columnHelper.accessor("Email", {
      header: "Email",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Email === null || row.row.original.Email === ""
              ? "-"
              : row.row.original.Email}
          </div>
        );
      },
    }),
    columnHelper.accessor("RoleName", {
      header: "Role",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.RoleName === null ||
            row.row.original.RoleName === ""
              ? "-"
              : row.row.original.RoleName}
          </div>
        );
      },
    }),
    columnHelper.accessor("Address", {
      header: "Address",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.Address === null ||
            row.row.original.Address === ""
              ? "-"
              : row.row.original.Address}
          </div>
        );
      },
    }),
    columnHelper.accessor("ActivationDate", {
      header: "Activation Date",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.ActivationDate === null ||
            row.row.original.ActivationDate === ""
              ? "-"
              : formatDate1(row.row.original.ActivationDate)}
          </div>
        );
      },
    }),
    columnHelper.accessor("ActivateTo", {
      header: "Activation To",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.ActivateTo === null ||
            row.row.original.ActivateTo === ""
              ? "-"
              : formatDate1(row.row.original.ActivateTo)}
          </div>
        );
      },
    }),

    columnHelper.accessor("UpdateDate", {
      header: "Updated Date",
      Cell: (row) => {
        return (
          <div>
            {row.row.original.UpdateDate === null ||
            row.row.original.UpdateDate === "0000-00-00"
              ? "-"
              : new Date(row.row.original.UpdateDate).toLocaleDateString(
                  "en-GB"
                )}
          </div>
        );
      },
    }),

    columnHelper.accessor("Status", {
      header: "Status",
      Cell: (row) => {
        return <div>{row.row.original.Status === "1" ? "ACTIVE" : "DEACTIVE"}</div>;
      },
    }),

    columnHelper.accessor("AddedBy", {
      header: "Added By",
      Cell: (row) => {
        return (
          <div className="upperCase">
            {row.row.original.AddedBy === null ||
            row.row.original.AddedBy === ""
              ? "-"
              : row.row.original.AddedBy}
              <br/>
              {formateDateTime(row.row.original.CreatedDate)}
          </div>
        );
      },
    }),
  ];

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getViewUser();
    
    }
  }, []);
  // ---------DataTable-----

  const excelData =
    data &&
    data.map((obj) => {
      const { Id, code, Password, message, Status, AdminID, ...rest } = obj;
      return rest;
    });

  const handleExportData = () => {
    const csvConfig = mkConfig({
      fieldSeparator: ",",
      decimalSeparator: ".",
      useKeysAsHeaders: true,
    });
  const formattedDate = new Date().toISOString().slice(0, 10);
    const filename = `User_${formattedDate}.csv`;

    const csv = generateCsv(csvConfig)(excelData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const columnsToExport = [
      { id: "serialNo", header: "S No." },
      ...columns.filter((column) => selectedColumns.includes(column.id)),
    ];

    const tableHeaders = columnsToExport.map((c) => c.header);

    const formattedDate = new Date().toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const filename = `User_${formattedDate}.pdf`;
    const addWatermarkToFirstPage = () => {
      const watermarkHeight = 20;

      // Calculate positioning to center the watermark on each page

      doc.setFontSize(10);
      doc.text(
        10,
        watermarkHeight + -10,
        `Generated by : ${localStorage.getItem("name")} `
      );
      doc.text(172, watermarkHeight + -10, `Date: ${formattedDate}`);
      doc.setFontSize(16);
      doc.text("User Table", 84, 20);
    };
    const watermarkHeight = 20;

    addWatermarkToFirstPage();
    const tableData = rows.map((row, index) => {
      const formattedDateInPdf = row.original["Date"]
        ? format(new Date(row.original["Date"]), "dd/MM/yyyy")
        : "";

      return [
        { content: (index + 1).toString(), styles: { fontStyle: "bold" } }, // Serial number
        ...columnsToExport.slice(1).map((column) => {
          // Exclude the first column (serial number) when mapping
          if (column.id === "Date") {
            return formattedDateInPdf;
          }

          const value =
            column.id in row.original ? row.original[column.id] : "";
          return value !== null && value !== undefined ? value : "";
        }),
      ];
    });
    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
      startY: watermarkHeight + 5,
    });

    // Add a heading to the table
 
    // Save the PDF to a Blob
    const pdfBlob = doc.output("blob");

    // Create a blob URL
    const blobUrl = URL.createObjectURL(pdfBlob);

    // Open the URL in a new tab
    window.open(blobUrl, "_blank");

    // Optionally revoke the object URL after opening the new tab
    URL.revokeObjectURL(blobUrl);
    // doc.save(filename);
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    initialState: {
      columnVisibility: {
        Status: false,
        AddedBy: false,
        UpdatedBy: false,
        UpdateDate: false,
        UpdatedDate: false,
        CreatedDate: false,
        ActivateFrom: false,
      },
    },
    enableStickyHeader: true,
    enableRowNumbers: true,
    state: {
      isLoading: loading,
    },
    muiCircularProgressProps: {
      color: "primary",
      thickness: 5,
      size: 55,
      style: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000,
      },
    },
    muiSkeletonProps: {
      animation: "pulse",
      height: 20,
    },
    muiPaginationProps: {
      rowsPerPageOptions: ["25", "100", "500", "1000", "5000"],
      showFirstButton: true,
      showLastButton: true,
    },
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
 disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export Excel
        </Button>

        <Button
        disabled={!table || table.getPrePaginationRowModel().rows.length === 0}
        onClick={handleExportButtonClick}
        startIcon={<FileDownloadIcon />}
      >
        Export PDF
      </Button>
      <Dialog open={open} onClose={handleClose1}>
        <DialogTitle>Select Columns To Export</DialogTitle>
        <DialogContent>
            {columns.map((column) =>
              column.header !== "Action" ? (
                <div key={column.id}>
                  <Checkbox
                    checked={selectedColumns.includes(column.id)}
                    onChange={() => handleToggleColumn(column.id)}
                  />
                  {column.header}
                </div>
              ) : (
                ""
              )
            )}
          </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1}>Cancel</Button>
          <Button onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}>Export</Button>
        </DialogActions>
      </Dialog>
      </Box>
    ),
  });

  return (
    <>
      <DocumentTitle title=" USER | LEGAL CRM ">
        {isLoading ? (
          <Preloader />
        ) : (
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  {/* <div className="accountContainer userListContainer"> */}
                  <div className="pageContent">
                    <div className="PageTitle">
                      <h3>View User</h3>

                      <a
                        style={{
                          display: isAdd === "0" ? "none" : "block",
                        }}
                        type="button"
                        className="btn btn-primary btn_client headbtn"
                        data-toggle="modal"
                        data-target="#exampleModalLong"
                      >
                        Add User
                        <img src="assets/img/icons/add.svg" />
                      </a>
                    </div>
                  
                    <div
                      style={{
                        display: isView === "0" ? "none" : "block",
                      }}
                      className="searDesign"
                    >
                      <MaterialReactTable table={table} />
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <Footer/>
            </div>

            {/*------- DELETE USER ------ */}

            <div
              className="modal fade sc_modal"
              id="exampleModal"
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close duser"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <h4>Delete Row</h4>
                    <p>Are you sure you want to delete user ?</p>
                    <ul>
                      <li>
                        <a
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </a>
                      </li>
                      <li>
                        <a
                          className="btn btn-danger"
                          onClick={() => deleteU(delId)}
                        >
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* ------ ADD USER ------*/}

            <div
              className="modal fade"
              id="exampleModalLong"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Add User</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="main_heading">
                      <h2>User </h2>
                    </div>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">
                              Name <text className="m_star">*</text>
                            </label>
                            <input
                              className="name"
                              type="text"
                              placeholder="Enter Name"
                              name="username"
                              value={userName}
                              onChange={(e) => setUserName(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                              className="name"
                              type="email"
                              placeholder="Enter Email"
                              name="email"
                              value={mail}
                              onChange={(e) => setMail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="number">
                              Mobile No. <text className="m_star">*</text>
                            </label>
                            <input
                              className="name"
                              type="text"
                              onKeyPress={handleKeyPress}
                              maxLength={10}
                              placeholder="Enter Mobile No"
                              name="number"
                              value={contatNo}
                              onChange={(e) => setContactNo(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="number">Alternate Mobile No.</label>
                            <input
                              className="name"
                              type="text"
                              onKeyPress={handleKeyPress}
                              maxLength={10}
                              placeholder="Enter Mobile No"
                              name="number"
                              value={AlternateContact}
                              onChange={(e) =>
                                setAlternateContact(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="password">
                              Password <text className="m_star">*</text>
                            </label>
                            <input
                              className="name"
                              type="text"
                              placeholder="Enter Password"
                              name="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              Role <text className="m_star">*</text>
                            </label>
                            <select
                              name="cars"
                              id="cars"
                              value={roleId}
                              onChange={(e) => setRoleId(e.target.value)}
                              required
                            >
                              <option>Role Select--</option>
                              {viewRoleData &&
                                viewRoleData.result.map((val, index) => {
                                  return (
                                    <option key={index} value={val.Id}>
                                      {val.RoleName}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">Qualification</label>
                            <input
                              className="name"
                              type="text"
                              placeholder="Enter Qualification"
                              name="uname"
                              value={Qualification}
                              onChange={(e) => setQualification(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">
                              Address<text className="m_star">*</text>
                            </label>
                            <input
                              className="name"
                              type="text"
                              placeholder="Enter Address"
                              name="uname"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_save btn_cancle"
                            onClick={clearForm}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- EDIT USER --------*/}

            <div
              className="modal fade"
              id="exampleModalLongEdit"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>Edit User</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="e_popup close btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="main_heading">
                      <h2>User</h2>
                    </div>
                    <div className="dashed_popup">
                      <form role="form " onSubmit={submitEditHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="username">
                              Name <text className="m_star">*</text>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Name"
                              name="username"
                              value={editUserName}
                              onChange={(e) => setEditUserName(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                              type="email"
                              placeholder="Enter Email"
                              name="email"
                              value={editEmail}
                              onChange={(e) => setEditEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="number">
                              Mobile No. <text className="m_star">*</text>
                            </label>
                            <input
                              onKeyPress={handleKeyPress}
                              maxLength={10}
                              type="text"
                              placeholder="Enter Mobile No"
                              name="number"
                              value={editMobileNo}
                              onChange={(e) => setEditMobileNo(e.target.value)}
                              required
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="number">
                              {" "}
                              Alternate Mobile No.
                            </label>
                            <input
                              type="text"
                              onKeyPress={handleKeyPress}
                              maxLength={10}
                              placeholder="Enter Mobile No"
                              name="number"
                              value={editAlternateContact}
                              onChange={(e) =>
                                seteditAlternateContact(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Role<text className="m_star">*</text>
                            </label>
                            <select
                              name="cars"
                              id="cars"
                              onChange={(e) => setEditRole(e.target.value)}
                              required
                            >
                              {viewRoleData &&
                                viewRoleData.result.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val.Id}
                                      selected={val.Id == editRole}
                                    >
                                      {val.RoleName}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="uname">Qualification</label>
                            <input
                              type="text"
                              placeholder="Enter Qualification"
                              name="uname"
                              value={editQualification}
                              onChange={(e) =>
                                setEditQualification(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="uname">
                              Address <text className="m_star">*</text>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Address"
                              name="uname"
                              value={editAddress}
                              onChange={(e) => setEditAddress(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="btn_save btn_cancle"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*-------- user Details --------*/}

            <div
              className="modal fade"
              id="exampleModalLongDetail"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-lg"
                role="document"
                // style={{ maxWidth: "50%" }}
              >
                <div className="modal-content">
                  <div className="add_wrap">
                    <div className="main_heading">
                      <h2>User Details</h2>
                    </div>
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close ab btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Name &nbsp;: &nbsp; {dName === null ? "-" : dName}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Email &nbsp;:&nbsp; {dEmail === null ? "-" : dEmail}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Mobile No. &nbsp;: &nbsp;{" "}
                            {dMobileNo === null  ? "-" : dMobileNo}
                        
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Role Name &nbsp;:&nbsp;{" "}
                            {dRoleName === null ? "-" : dRoleName}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Address &nbsp;: &nbsp;{" "}
                            {dAddress === null ? "-" : dAddress}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Qualification &nbsp;:&nbsp;{" "}
                            {dQualification === null ? "-" : dQualification}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Activate From &nbsp;: &nbsp;{" "}
                            {dActivateFrom === null || dActivateFrom === "0000-00-00"
                              ? "-"
                              : formatDate1(dActivateFrom)}{" "}
                          </h4>
                        </div>
                        <div className="form-group">
                          <h4>
                            Activate To &nbsp;:&nbsp;{" "}
                            {dActivateTo === null || dActivateTo === "0000-00-00"
                              ? "-"
                              : formatDate1(dActivateTo)}{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="form_flex caseMobile">
                        <div className="form-group">
                          <h4>
                            Activation Date &nbsp;: &nbsp;{" "}
                            {dActivationDate === null || dActivationDate === "0000-00-00"
                              ? "-"
                              : formatDate1(dActivationDate)}{" "}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*------- TOASTER ------*/}

            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        )}
      </DocumentTitle>
    </>
  );
};

export default ViewUser;
