import React, { useEffect, useState, useContext } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Preloader from "./Preloader";
import DocumentTitle from "react-document-title";
import Footer from "./Footer";
import { url } from "../index";
import { useNavigate, useParams } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import {
    formateDateTime,
  } from "../services/api.service";

const BookedCases = () => {

  const [ caseType, setCaseType ] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const baseUrl = useContext(url);
  const Case1 = new FormData();
  const { id } = useParams();
  const navigate = useNavigate();
  const [casesData, setCasesData] = useState([]);


  const getCaseData1 = async (
    type,
    fCasesGroupBy,
    period,
    ID,
  ) => {
    Case1.append("AdminID", localStorage.getItem("id"));
    Case1.append("UserID", localStorage.getItem("uId"));
    Case1.append("Date", period);
    Case1.append("Type", type);
    Case1.append("GroupBy", fCasesGroupBy);
    Case1.append("ID", ID);

    try {
      const response = await fetch(
        `${baseUrl}Cases/TotalOpenCloseCasesDetail`,
        {
          method: "POST",
          body: Case1,
          headers: {
            Authorization: `${localStorage.getItem("access_token")}`,
            Accept: "*/*",
          },
        }
      );
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }

      if (data && data.result[0].CaseDetailsFlowDetail.length) {
        setCasesData(data && data.result[0].CaseDetailsFlowDetail);
      } else{
        setCasesData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

    const getParamsData = (id) => {  
    const data = id.split("_");
    
    setCaseType(data[0]);
    const type  = data[0];
    const fCasesGroupBy = data[1];
    const Period = data[2];
    const ID = data[3];
  
    getCaseData1(type, fCasesGroupBy, Period, ID)
    };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
   }, []);

  useEffect(() => {
    getParamsData(id);
  }, [id]);

  return (
    <>
      <DocumentTitle title="CASE INFO | LEGAL CRM">
        {isLoading ? (
          <Preloader />
        ) : (
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  <div className="pageContent">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="myCard">
                          <div className="cardHeader">
                            <h4>CASES</h4>
                          </div>

                          <div className="cardBody">
                            <h4>{caseType === "1" ? "BOOKED CASES" : caseType === "2" ? "CLOSE CASE" : "FILED CASE"}</h4>
                            <table className="myTable" style={{border: "1px solid rgb(0, 0, 0)"}}>
                              <thead>
                                <tr>
                                <th>S NO</th>
                                  <th>FILE NO.</th>
                                  <th>CASE NO.</th>
                                  <th>CASE NAME</th>
                                  <th>CLIENT</th>
                                  <th>CREATED BY</th>
                                </tr>
                              </thead>
                              <tbody>
                                {casesData &&
                                  casesData.map((val, index) => {
                                    return (
                                      <tr key={index}>
                                      <td>{index + 1}</td>
                                        <td>{val.FileNo}</td>
                                        <td>{val.CaseNo}</td>
                                        <td>    <a
                                        title="Case Details"
                                                  href={`#/view-casedetails/${val.Id}`}
                                                  className="visuald_case"
                                                  target="_blank"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  {val.CaseName}
                                                </a></td>
                                        <td>{val.ClientName}<br/>
                                        {val.MobileNo}</td>
                                        <td>{val.CreatedBy}<br/>
                                        {formateDateTime(val.CreatedDate)}</td>
                                  
                                      </tr>
                                    );
                                  })}

                                <tr></tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>{" "}
          </div>
        )}
      </DocumentTitle>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />{" "}
    </>
  );
};

export default BookedCases;
