import React, { useState, useRef } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useContext } from "react";
import { url } from "../index";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import {
  getClient,
} from "../services/api.service";
import "react-quill/dist/quill.snow.css";
import "quill/dist/quill.snow.css";
import Select from "react-select";
import { clearForm } from "../services/api.service";
import DocumentTitle from "react-document-title";
import Footer from "./Footer";

const AddAppointment = () => {
  
  //        ----------  VARIABLE --------
  const ViewData = new FormData();
  const [countries, setCountries] = useState([]);
  const [saveButton, setSaveButton] = useState(true);
  const baseUrl = useContext(url);
  const currentDate = new Date().toISOString().split("T")[0];
  const navigate = useNavigate();
  const [fClientOption, setFClientOption] = useState([]);
  const [CaseID, setCaseID] = useState("");
  const [ClientID, setClientID] = useState("");
  const [Summary, setSummary] = useState("");
  const [Time, setTime] = useState("");
  const [AssignTo, setAssignTo] = useState("");
  const [AppointmentWith, setAppointmentWith] = useState("");
  const [IsPending, setIsPending] = useState("");
  const [Details, setDetails] = useState("");
  const [Dates, setDates] = useState(currentDate);
  const formData = new FormData();
  const Case = new FormData();
  const [optionsCase, setOptionsCase] = useState([]);

  //        ---------- VIEW  --------

  const getViewLetterTemplate = async () => {
    const [cdata] = await Promise.all([
      getClient("Client/ViewClient"),
      getUser(),
      getFileNo(),
    ]);

    const clientData = cdata && cdata.filter((item) => item.Status === "1");

    const Mapped =
      clientData &&
      clientData.map((item) => ({
        value: item.Id,
        label: `${item.FirstName} ${item.LastName}`,
      }));

    setFClientOption(Mapped);

  };

  const formRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  const getFileNo = async () => {
    Case.append("AdminID", localStorage.getItem("id"));
    Case.append("UserID", localStorage.getItem("uId"));
    try {
      const response = await fetch(`${baseUrl}Event/GetFilename`, {
        method: "POST",
        body: Case,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();

      if (data && data.result[0].message === "Success") {

        const Mapped = data.result.map((item) => ({
          value: item.CaseID,
          label: `${item.FileNo} / ${item.CaseName} / ${item.CaseNo}`,
        }));

        setOptionsCase(Mapped);
      }
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setCaseID("");
      setClientID("");
      setCountries([]);
    } else {
      setCaseID(selectedOption.value);
      getClientByCase(selectedOption.value);
    }
  };

  const getClientByCase = async (caseId) => {
    ViewData.append("AdminID", localStorage.getItem("id"));
    ViewData.append("CaseID", caseId);
    try {
      const response = await fetch(`${baseUrl}Appoinment/ViewClientByCase`, {
        method: "POST",

        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
        body: ViewData,
      });

      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        navigate("/");
      }
      
      setCountries(data && data.result);
    } catch (error) {
      console.error(error);
    }
  };

  const Userform = new FormData();
  const [User, setUser] = useState([]);
  const getUser = async () => {
    Userform.append("AdminID", localStorage.getItem("id"));
    try {
      const response = await fetch(`${baseUrl}Task/ViewUserList`, {
        method: "POST",
        body: Userform,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
      if (data && data.result[0].message === "Success") {
        setUser(data.result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //        ---------- ADD --------

  const submitHandler = async (event) => {
    event.preventDefault();
    setSaveButton(false);
    formData.append("AdminID", localStorage.getItem("id"));
    formData.append("CreatedBy", localStorage.getItem("UserId"));
    if (CaseID && CaseID.length > 0) {
      formData.append("CaseID", CaseID);
    } else {
      formData.append("CaseID", CaseID);
    }

    formData.append("Date", Dates);
    formData.append("ClientID", ClientID);
    formData.append("Summary", Summary.toUpperCase());
    formData.append("Time", Time);
    formData.append("IsPending", IsPending);
    formData.append("Detail", Details.toUpperCase());
    formData.append("AssignTo", AssignTo);
    formData.append("AppointmentWith", AppointmentWith.toUpperCase());

    try {
      const response = await fetch(`${baseUrl}Appoinment/AddAppoinment`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("access_token")}`,
          Accept: "*/*",
        },
      });
      const data = await response.json();
      if (data && data.result.message == "Session Time Expire.") {
        toast.error("Token expired please login again");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }

      if (data.result.length && data.result[0].message === "Success") {
        toast.success("Appointment added successfully");
        setSaveButton(true);
        handleClose();
        clearForm();
        setDates(currentDate);
        setClientID("");
        setCaseID("");
        setSummary("");
        setAssignTo("");
        setAppointmentWith("");
        setIsPending("");
        setDetails("");
        setTime("");
        setTimeout(() => {
          navigate("/home");
        }, 2000);
      } else {
        toast.error(data.result.message);
        setSaveButton(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("access_token") === null) {
      navigate("/");
    } else {
      window.scrollTo(0, 0);
      getViewLetterTemplate();
    }
  }, []);

  return (
    <>
      <DocumentTitle title="ADD APPOINTMENT | LEGAL CRM">
     
          <div>
            <div className="wraper">
              <Header />
              <div className="mainWraper">
                <Sidebar />
                <div className="pageBody">
                  <div className="pageContent">
                    <div className="PageTitle">
                   <h3>Add Appointment</h3>
                    </div>
                    <div>
                  <div>
                   
                  </div>
                  <div className="popup_modal ">
                    <button
                      type="button"
                      className="close btn_popup"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <div className="dashed_popup">
                      <form role="form" onSubmit={submitHandler}>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="name">File No.<text className="m_star">*</text></label>
                            <Select
                            required
                              className="basic-single"
                              placeholder="Select "
                              classNamePrefix="select"
                              onChange={handleSelectChange}
                              isClearable={true}
                              isSearchable={true}
                              name="color"
                              options={optionsCase}
                            />
                          </div>
                          <div className="form-group">
                            <label>Client Name<text className="m_star">*</text></label>

                            <select
                            required
                              className="name"
                              id="city"
                              onChange={(e) => setClientID(e.target.value)}
                            >
                              <option value="">--Please Select--</option>
                              {countries && countries.length > 0 ? (
                                countries.map((val) => (
                                  <option key={val.Id} value={val.Id}>
                                    {val.FirstName}
                                  </option>
                                ))
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>

                          <div className="form-group">
                            <label htmlFor="name">Appointment With </label>
                            <input
                              value={AppointmentWith}
                              type="text"
                              className="name"
                              placeholder="Enter Appointment With"
                              onChange={(e) =>
                                setAppointmentWith(e.target.value)
                              }
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="name">
                              Date <text className="m_star">*</text>
                            </label>
                            <input
                              required
                              value={Dates}
                              type="Date"
                              className="name"
                              placeholder="Enter Date"
                              onChange={(e) => setDates(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="form_flex caseMobile col-md-9">
                     
                          <div className="form-group">
                            <label htmlFor="name">Time </label>
                            <input
                              value={Time}
                              type="Time"
                              className="name"
                              placeholder="Enter Time"
                              onChange={(e) => setTime(e.target.value)}
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="name">
                              Assigned to User <text className="m_star">*</text>
                            </label>
                            <select
                              required
                              className="name"
                              id="city"
                              value={AssignTo}
                              onChange={(e) => setAssignTo(e.target.value)}
                            >
                              <option value="">--Please Select--</option>
                              {User && User.length > 0 ? (
                                User.map((val) => (
                                  <option key={val.id} value={val.Id}>
                                    {val.ContactPersonName}
                                  </option>
                                ))
                              ) : (
                                <option value="">Loading...</option>
                              )}
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="city">Is Pending</label>
                            <select
                              className="name"
                              value={IsPending}
                              onChange={(e) => setIsPending(e.target.value)}
                            >
                              <option value="">--Please Select--</option>
                              <option value="0">PENDING</option>
                              <option value="1">DONE</option>
                            </select>
                          </div>
                        </div>
                        <div className="form_flex caseMobile">
                          <div className="form-group">
                            <label htmlFor="name">Summary </label>
                            <textarea
                              value={Summary}
                              type="text"
                              className="name"
                              placeholder="Enter Summary"
                              onChange={(e) => setSummary(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="name">Details </label>
                            <textarea
                              value={Details}
                              type="text"
                              className="name"
                              placeholder="Enter Details"
                              onChange={(e) => setDetails(e.target.value)}
                            />
                          </div>
                        </div>
                      {
                        saveButton ? (
                          <div className="btn_main caseMobile">
                          <button type="submit" className="btn_save">
                            Save
                          </button>
                        </div>
                        ) : ""
                      }
                      </form>
                    </div>
                  </div>
                </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>

            {/*---------- Toster --------*/}

            <ToastContainer
              position="top-right"
              autoClose={1000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>

      </DocumentTitle>
    </>
  );
};

export default AddAppointment;